import { Navigate, Route, Routes } from 'react-router-dom'

import { GcodeTemplates } from '../components/commands/GcodeTemplates'

export function CommandsPage() {
  return (
    <Routes>
      <Route path="g-codes" element={<GcodeTemplates />} />
      <Route index element={<Navigate replace to="g-codes" />} />
    </Routes>
  )
}
