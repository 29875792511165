import { Draggable, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { IGroupDetail, IGroupPrinter } from '../../../../api/types/groups'
import { Row } from '../../../bootstrap/Row'
import { getPrinterName } from '../../utils'
import { DropTargets } from './dnd'
import { HEIGHT, Printer } from './Printer'

const Container = styled.div`
  margin: 0 30px 60px;
`

const MIN_HEIGHT = HEIGHT + 2 // + border top & bottom
const List = styled.div<{ $isDraggingOver: boolean }>`
  border: 1px solid black;
  min-height: ${MIN_HEIGHT}px;
  display: flex;
  background: ${(props) => (props.$isDraggingOver ? props.theme.colors.primary : 'none')};
`

type Props = {
  group: IGroupDetail
  printers: IGroupPrinter[]
}

export function VirtualGroup(props: Props) {
  const { group, printers } = props
  const { t } = useTranslation()
  // TODO implement loading placeholder here?
  return (
    <Container>
      <Row noGutters>
        {t('printer.group.occupied', {
          count: group.printer_count
        })}
      </Row>
      <Droppable droppableId={DropTargets.VIRTUAL_GROUP} direction="horizontal">
        {(provided, { isDraggingOver }) => (
          <List ref={provided.innerRef} {...provided.droppableProps} $isDraggingOver={isDraggingOver}>
            {printers.map((printer, i) => (
              <Draggable key={printer.uuid} draggableId={printer.uuid} index={i} shouldRespectForcePress={false}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={provided.draggableProps.style}
                  >
                    <Printer
                      isDragging={snapshot.isDragging}
                      name={getPrinterName(printer)}
                      type={printer.printer_type}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </Container>
  )
}
