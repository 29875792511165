import { useTranslation } from 'react-i18next'

import { useErrorTypeTranslations } from '../hooks/errors/errorTypes'
import { Alert } from './bootstrap/Alert'
import { PaceProgress } from './common/PaceProgress'

// based on useFetchService
function parseError(error: any) {
  return {
    statusCode: error?.code || error?.response?.status,
    statusText: error?.message || error?.response?.statusText
  }
}

type Status = {
  isLoading: boolean
  isError: boolean
  error: any
}

type Props<S> = {
  mutation: S
}

export function MutationStatus<S extends Status>(props: Props<S>) {
  const { isLoading, isError, error } = props.mutation
  const { t } = useTranslation()
  const { ResponseCodeName } = useErrorTypeTranslations()

  const renderError = () => {
    if (!isError) {
      return null
    }

    const parsed = parseError(error)
    if (parsed.statusCode) {
      return (
        <Alert>
          {Object.keys(ResponseCodeName).includes(parsed.statusCode)
            ? ResponseCodeName[parsed.statusCode]
            : parsed.statusText}
        </Alert>
      )
    }

    return <Alert>{t('service.error.unknown')}</Alert>
  }

  return (
    <>
      {isLoading && <PaceProgress height={2} />}
      {renderError()}
    </>
  )
}
