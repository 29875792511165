import { UseQueryResult } from '@tanstack/react-query'
import Pace from 'react-pace-progress'
import styled, { useTheme } from 'styled-components'

type Props = {
  height?: number
}

const PaceContainer = styled.div`
  position: relative;
`

export function shouldShowTabularPace<T, E>(query: UseQueryResult<T, E>) {
  const showPace =
    query.isLoading || // initial query load (mount)
    query.isFetching || // sorting & automatic refresh
    !query.data
  return showPace
}

export function PaceProgress(props: Props) {
  const theme = useTheme()
  const { height = 1 } = props

  return (
    <PaceContainer>
      <Pace color={theme.colors.primary} height={height} />
    </PaceContainer>
  )
}
