import styled, { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  [id^="printer-params-"] .tooltip-inner {
    background: var(--background-body);
    color: var(--color-text);
    min-width: 300px;
    box-shadow: 0 0.5rem 1rem 0.5rem var(--tooltip-shadow);
    padding: 0;
    text-align: left;
    > div {
      padding: 1rem;
    }
  }

  [id^="printer-params-"].tooltip.show {
    opacity: 1;
  }
  
  [id^="printer-params-"] {
    .bs-tooltip-auto[x-placement^="right"], .arrow::before, .bs-tooltip-right .arrow::before {
      border-bottom-color: var(--background-body);
      border-top-color: var(--background-body);
    }
  }
`

export const Parameters = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  gap: 0.25rem 1rem;
`

export const StatusItem = styled.span`
  font-weight: 300;
  font-size: 14px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  text-align: center;
  gap: 0.25rem;

  b {
    font-weight: 700;
    font-size: 13px;
  }
`

export const StatusValue = styled.div<{ noFixedWidth?: boolean; $interactive?: boolean }>`
  display: flex;
  gap: 0.25rem;
  align-items: flex-end;
  min-width: ${(props) => (props.noFixedWidth ? '0' : '9ch')};
  justify-content: center;
  cursor: ${(props) => (props.$interactive ? 'pointer' : 'default')};
`
