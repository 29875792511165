import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { IAllowedFunctionalities } from '../../../api/types/printer'
import { IConnectState } from '../../../api/types/state'
import { useCanControl } from '../../../context/permissionsStore'
import { usePrinter } from '../../../context/printerContext'
import { useErrorHandler } from '../../../hooks/errors/useErrorHandler'
import { usePrinterStorage } from '../../../hooks/storageLocations/usePrinterStorage'
import { getWholeFilePath } from '../../../hooks/storageLocations/useStorageLocationTabs'
import { useFeatureFlags } from '../../../hooks/useFeatureFlags'
import { useLoggedUser } from '../../../hooks/useLoggedUser'
import { useLowMemory } from '../../../hooks/useLowMemory'
import { usePrinterInState } from '../../../hooks/usePrinterInState'
import { Icon } from '../../../icons'
import { useAddJobDownloadQueueService } from '../../../services/useAddJobDownloadQueueService'
import { useAddJobQueueService } from '../../../services/useAddJobQueueService'
import { AdaptiveButton } from '../../common/AdaptiveButton/AdaptiveButton'
import { ErrorModal } from '../../common/ErrorModal'

type Props = {
  path?: string // either path or file info: uploaded, hash, name, teamId
  uploaded?: number
  hash?: string
  name?: string
  position?: number
  onSuccess?: () => void
  teamId?: number
  icon?: Icon
  label?: string
}

export function EnqueueAction({
  path,
  uploaded,
  hash,
  name,
  position = -1, // last
  onSuccess,
  teamId,
  icon,
  label
}: Props) {
  const { t } = useTranslation()
  const printerStorage = usePrinterStorage()
  const user = useLoggedUser()
  const { printer } = usePrinter()
  const { isEnabled } = useFeatureFlags()
  const isInAvailableState = ![IConnectState.UNKNOWN].includes(printer.connect_state)
  const isAvailable = isEnabled(IAllowedFunctionalities.QUEUE) && isEnabled(IAllowedFunctionalities.FILES)
  const canControl = useCanControl(printer.team_id)
  const printerInState = usePrinterInState(printer.connect_state)
  const isLowMemory = useLowMemory(printer.connect_state, printer.uuid)
  const cannotUpload = isLowMemory && !path
  const errorHandler = useErrorHandler()
  const [modal, setModal] = useState({ title: '', body: '', displayed: false })

  const { mutate: copyAndToPrintQueue, isLoading: copyIsLoading } = useAddJobDownloadQueueService(
    printer.uuid,
    onSuccess,
    (error: any) => {
      if (error && error.response) {
        const { title, message } = errorHandler(error.response, error)
        setModal({
          title,
          body:
            error.path && hash ? (
              <>
                {message} (
                <Link style={{ wordWrap: 'break-word' }} to={getWholeFilePath(error.path, printer, hash)}>
                  {error.path}
                </Link>
                )
              </>
            ) : (
              message
            ),
          displayed: true
        })
      }
    }
  )
  const { mutate: toPrintQueue, isLoading: toPrintLoading } = useAddJobQueueService(printer.uuid, onSuccess)

  const isDisabled = (!path && !uploaded) || !isAvailable || !canControl || !isInAvailableState || cannotUpload

  let disabledTooltip = ''
  if (!isAvailable) {
    disabledTooltip = t('printer.tooltip.disabled-functionality')
  } else if (!canControl) {
    disabledTooltip = t('printer.tooltip.rights')
  } else if (!isInAvailableState) {
    disabledTooltip = printerInState
  } else if (cannotUpload) {
    disabledTooltip = t('printer.file.upload.disabled-during-printing')
  } else {
    disabledTooltip = !path
      ? t('gcode-meta.enqueue.not-available-in-link')
      : t('gcode-meta.download.not-available-in-connect')
  }

  if (!printerStorage && !path) {
    return (
      <AdaptiveButton
        icon={icon || 'queueAddIcon'}
        label={label || t('print.add-to-queue')}
        isLoading={false}
        isAvailable
        isDisabled
        disabledTooltip={t('printer.no-storage')}
        trigger={() => {}}
      />
    )
  }

  if (!path) {
    return (
      <>
        <AdaptiveButton
          icon={icon || 'queueAddIcon'}
          trigger={() =>
            copyAndToPrintQueue({
              team_id: teamId || user.default_team_id,
              hash,
              path: `${printerStorage}/${name}`,
              to_print: false,
              to_queue: true
            })
          }
          label={label || t('print.add-to-queue')}
          isLoading={copyIsLoading}
          isAvailable
          isDisabled={isDisabled}
          disabledTooltip={disabledTooltip}
        />

        <ErrorModal
          show={modal.displayed}
          title={modal.title}
          body={modal.body}
          onHide={() => setModal({ ...modal, displayed: false })}
        />
      </>
    )
  }

  return (
    <AdaptiveButton
      icon="queueAddIcon"
      trigger={() =>
        toPrintQueue({
          path,
          position
        })
      }
      label={t('print.add-to-queue')}
      isLoading={toPrintLoading}
      isAvailable
      isDisabled={isDisabled}
      disabledTooltip={disabledTooltip}
    />
  )
}
