import { ReactNode } from 'react'
import styled from 'styled-components'

import { isNonZero } from '../../helpers/std'
import { device } from '../../interfaces/device'

type Props = {
  className?: string
  title: ReactNode
  children?: ReactNode
  height?: number
}

const Container = styled.div<{ $height?: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 15px;
  ${({ $height }) => (isNonZero($height) ? `height: ${$height}px;` : '')}

  @media ${device.sm} {
    padding: 4rem 15px;
  }
`

export function EmptyList(props: Props) {
  return (
    <Container className={`text-center ${props.className}`} $height={props.height}>
      <h2>{props.title}</h2>
      {props.children}
    </Container>
  )
}
