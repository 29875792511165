import * as Sentry from '@sentry/react'
import { i18n } from 'i18next'
import { ReactNode, useEffect } from 'react'
import { I18nextProvider } from 'react-i18next'

import { getEnvironment, Globals } from '../helpers/getEnvironment'

type Props = {
  i18n: i18n
  children: ReactNode
}

export function LocaleProvider(props: Props) {
  const { i18n } = props
  useEffect(() => {
    // Setup onMissingKey handler
    i18n.options.saveMissing = true
    i18n.options.missingKeyHandler = (_lngs, _ns, key) => {
      const environment = getEnvironment(Globals.ENVIRONMENT)
      if (environment === 'PRODUCTION' || environment === 'TEST') {
        // setTimeout is needed to update while not render
        setTimeout(() => Sentry.captureMessage(`Missing translation for key "${key}"`), 50)
      }
    }
  }, [i18n])

  return <I18nextProvider i18n={i18n}>{props.children}</I18nextProvider>
}
