import { IFirmwareFile, IOtherFile, IPrintFile } from '../api/types/file'

export function getNameFromPath(path?: string, options = { noExtension: false }) {
  if (!path) {
    return ''
  }
  const parts = path.split('/')
  const filename = parts[parts.length - 1]

  if (options.noExtension) {
    return filename.substr(0, filename.lastIndexOf('.'))
  }
  return filename
}

export function getStorageFromPath(path?: string) {
  if (!path) {
    return ''
  }
  const parts = path.split('/')
  const storage = parts[1]

  return storage
}

export function getLastFolder(path?: string) {
  if (!path) {
    return ''
  }
  const parts = path.split('/')
  const folder = parts[parts.length - 2]

  return folder
}

export function getLongFileName(file?: IPrintFile | IFirmwareFile | IOtherFile) {
  if (!file) {
    return ''
  }

  return file.display_name || file.name
}
