import { useFocusRing } from '@react-aria/focus'
import { useNumberFormatter } from '@react-aria/i18n'
import { useSlider, useSliderThumb } from '@react-aria/slider'
import { mergeProps } from '@react-aria/utils'
import { VisuallyHidden } from '@react-aria/visually-hidden'
import { SliderState, SliderStateOptions, useSliderState } from '@react-stately/slider'
import { RefObject, useRef } from 'react'

import { identity } from '../../helpers/std'
import { PlainButton } from './PlainButton'
import * as S from './Slider.styled'
import { SvgIcon } from './SvgIcon'

type Props = {
  value: number
  minValue: number
  maxValue: number
  label: string
  disabled?: boolean
  onChange?: (value: number) => void
  onChangeEnd?: (value: number) => void
  formatValue?: (value: number) => string
  onRefresh?: () => void
  refreshTitle?: string
  orientation?: 'vertical' | 'horizontal'
}

// https://react-spectrum.adobe.com/react-aria/useSlider.html

const index = 0 // only one thumb

export function Slider(props: Props) {
  const {
    disabled = false,
    label,
    value,
    minValue,
    maxValue,
    formatValue = identity,
    onChange,
    onChangeEnd,
    onRefresh,
    refreshTitle,
    orientation = 'horizontal'
  } = props
  const trackRef = useRef<HTMLDivElement>(null)
  const numberFormatter = useNumberFormatter()
  const options: Omit<SliderStateOptions<number[]>, 'numberFormatter'> = {
    isDisabled: disabled,
    label,
    minValue,
    maxValue,
    value: [value],
    onChangeEnd: (values) => {
      if (!disabled) {
        onChangeEnd?.(values[index])
      }
    },
    onChange: (values) => {
      if (!disabled) {
        onChange?.(values[index])
      }
    }
  }
  const state = useSliderState({ orientation, numberFormatter, ...options })
  const { groupProps, trackProps, labelProps, outputProps } = useSlider(options, state, trackRef)

  return (
    <S.Group {...groupProps}>
      <div className="d-flex align-items-center">
        <S.Label {...labelProps}>{label}</S.Label>
        <output {...outputProps}>{formatValue(state.values[index])}</output>
        {onRefresh && (
          <PlainButton className="ml-1" onClick={onRefresh}>
            <SvgIcon icon="refreshIcon" size={20} title={refreshTitle} />
          </PlainButton>
        )}
      </div>
      <div className="d-flex align-items-center">
        <S.NoWrap>{formatValue(minValue)}</S.NoWrap>
        <S.TrackContainer {...trackProps} disabled={disabled} ref={trackRef}>
          <S.Track />
          <S.FilledTrack style={{ width: `${state.getThumbPercent(index) * 100}%` }} />
          <Thumb disabled={disabled} index={index} state={state} trackRef={trackRef} />
        </S.TrackContainer>
        <S.NoWrap>{formatValue(maxValue)}</S.NoWrap>
      </div>
    </S.Group>
  )
}

type ThumbProps = {
  disabled: boolean
  index: number
  state: SliderState
  trackRef: RefObject<HTMLDivElement>
}

function Thumb(props: ThumbProps) {
  const { state, trackRef, index, disabled } = props
  const inputRef = useRef(null)
  const { thumbProps, inputProps } = useSliderThumb(
    {
      isDisabled: disabled,
      index,
      trackRef,
      inputRef
    },
    state
  )

  thumbProps.style = {}

  const { focusProps, isFocusVisible } = useFocusRing()
  return (
    <S.ThumbContainer
      style={{
        left: `${state.getThumbPercent(index) * 100}%`
      }}
    >
      <S.Thumb {...thumbProps} $isDragging={state.isThumbDragging(index)} $isFocusVisible={isFocusVisible}>
        <VisuallyHidden>
          <input ref={inputRef} {...mergeProps(inputProps, focusProps)} />
        </VisuallyHidden>
      </S.Thumb>
    </S.ThumbContainer>
  )
}
