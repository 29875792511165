import styled, { css } from 'styled-components'

import { GridMode } from '../../constants/grid'

// https://en.wikipedia.org/wiki/Hexagon#Parameters
const RATIO = 1.1547005

// Cubic gradient needs height to be the odd number (buggy in Chrome)
export const toHeight = (width: number) => {
  const height = Math.round(width / RATIO)
  if (height % 2 === 0) {
    return height
  }
  return height + 1
}

export const Container = styled.div`
  width: 100%;
  overflow-x: auto;
`

export const Row = styled.div<{ $center: boolean }>`
  display: flex;
  ${(props) =>
    props.$center
      ? css`
          ${Hexagon}:first-child {
            margin-left: auto !important;
          }
          ${Hexagon}:last-child {
            margin-right: auto;
          }
        `
      : ''};
`

function getMargin(width: number, margin: number) {
  return css`
    margin-top: ${toHeight(width) / 2 + margin / 2}px;

    margin-left: -${width / 2 / 2 - margin}px;
    margin-right: -${width / 2 / 2 - margin}px;
  `
}

export const Hexagon = styled.div<{ $background?: string; $width: number; $margin: number; $mode: GridMode }>`
  flex-shrink: 0;
  height: ${(props) => toHeight(props.$width)}px;
  width: ${(props) => props.$width}px;
  ${({ $background }) =>
    $background &&
    css`
      background: ${$background};
    `}

  clip-path: polygon(75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%, 25% 0);
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ $margin, $width, $mode }) => {
    if ($mode === GridMode.FIRST_UP) {
      return css`
        &:nth-child(even) {
          ${getMargin($width, $margin)};
        }
      `
    }

    if ($mode === GridMode.FIRST_DOWN) {
      return css`
        &:nth-child(odd) {
          ${getMargin($width, $margin)};

          &:first-child {
            margin-left: 0;
          }
        }
      `
    }
  }}
`
