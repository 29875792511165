import styled from 'styled-components'

export const responsive = {
  desktop: {
    breakpoint: { max: Infinity, min: 1024 },
    items: 5,
    slidesToSlide: 5 // optional, default to 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 420 },
    items: 3,
    slidesToSlide: 3 // optional, default to 1
  },
  mobile: {
    breakpoint: { max: 420, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1
  }
}

export const EmptyList = styled.div`
  text-align: center;
`

export const Wrapper = styled.div`
  padding: 1rem 2rem;

  .react-multi-carousel-list {
    button.react-multiple-carousel__arrow--left {
      left: 0;
    }
    button.react-multiple-carousel__arrow--right {
      right: 0;
    }
  }

  .react-multi-carousel-track {
    margin: 0 auto;
  }
`

export const Title = styled.div`
  margin-top: 1rem;
  font-weight: 500;
`

export const CarouselItem = styled.div`
  cursor: pointer;
  &.selected,
  &:hover {
    img {
      max-width: 100px;
      background-color: var(--color-primary);
      border-radius: 5px;
    }
  }
`
