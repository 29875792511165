import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useApiClient } from '../../../api/react'
import { IEditFileAttrs } from '../../../api/types/file'
import { IAllowedFunctionalities, IPrinter, IPrinterSimpleView } from '../../../api/types/printer'
import { IConnectState } from '../../../api/types/state'
import { useCanControl } from '../../../context/permissionsStore'
import { useErrorHandler } from '../../../hooks/errors/useErrorHandler'
import { usePrinterStorageByUuid } from '../../../hooks/storageLocations/usePrinterStorage'
import { getWholeFilePath } from '../../../hooks/storageLocations/useStorageLocationTabs'
import { useFeatureFlagsByUuid } from '../../../hooks/useFeatureFlags'
import { useLoggedUser } from '../../../hooks/useLoggedUser'
import { usePrinterInState } from '../../../hooks/usePrinterInState'
import { useIsSla } from '../../../hooks/usePrinterType'
import { useAddJobDownloadQueueService } from '../../../services/useAddJobDownloadQueueService'
import { useAddJobQueueService } from '../../../services/useAddJobQueueService'
import { AdaptiveButton } from '../../common/AdaptiveButton/AdaptiveButton'
import { ErrorModal } from '../../common/ErrorModal'
import { RenameFileModal } from '../storage/RenameFileModal'

type Props = {
  uploaded?: number
  hash?: string
  name?: string
  onSuccess?: () => void
  teamId?: number
  label: string
  printer: IPrinterSimpleView
}

export function EnqueueOrCopyAndEnqueueAction({ uploaded, hash, name, onSuccess, teamId, label, printer }: Props) {
  const { t } = useTranslation()
  const { uuid, team_id, connect_state } = printer
  const isSla = useIsSla(printer.printer_type)
  const printerStorage = usePrinterStorageByUuid(uuid)
  const user = useLoggedUser()
  const { isEnabled } = useFeatureFlagsByUuid(uuid)
  const isInAvailableState = ![IConnectState.OFFLINE, IConnectState.UNKNOWN].includes(connect_state)
  const isAvailable = isEnabled(IAllowedFunctionalities.QUEUE) && isEnabled(IAllowedFunctionalities.FILES)
  const canControl = useCanControl(team_id)
  const printerInState = usePrinterInState(connect_state)
  const errorHandler = useErrorHandler()
  const [modal, setModal] = useState({ title: '', body: '', displayed: false })
  const [renameModal, setRenameModal] = useState({ instructions: '', displayed: false })

  // TODO - hack due to missing path
  const api = useApiClient()
  const { data, isLoading } = useQuery([`/file-detail/${teamId}/${uuid}/${hash}`], () => {
    return api.app.files.getFile(Number(teamId), String(hash), uuid)
  })

  const { mutate: copyAndToPrintQueue, isLoading: copyIsLoading } = useAddJobDownloadQueueService(
    uuid,
    onSuccess,
    (error: any) => {
      if (error && error.response) {
        if (
          error.response.status === 400 &&
          (error.code === 'INVALID_FILENAME_LENGTH' || error.code === 'INVALID_FILENAME_CHARS')
        ) {
          errorHandler(error.response, error)
          return setRenameModal({
            displayed: true,
            instructions:
              error.code === 'INVALID_FILENAME_LENGTH'
                ? t('file.rename.instructions.reason-long', 'The file has too long filename, please rename it first.')
                : t(
                    'file.rename.instructions.reason-invalid',
                    'The file name contains invalid characters, please rename it first.'
                  )
          })
        }

        const { title, message } = errorHandler(error.response, error)
        setModal({
          title,
          body:
            error.path && hash ? (
              <>
                {message} (
                <Link style={{ wordWrap: 'break-word' }} to={getWholeFilePath(error.path, printer as IPrinter, hash)}>
                  {error.path}
                </Link>
                )
              </>
            ) : (
              message
            ),
          displayed: true
        })
      }
    }
  )
  const { mutate: toPrintQueue, isLoading: toPrintLoading } = useAddJobQueueService(uuid, onSuccess)

  const isDisabled = !uploaded || !isAvailable || !canControl || !isInAvailableState

  let disabledTooltip = ''
  if (!isAvailable) {
    disabledTooltip = t('printer.tooltip.disabled-functionality')
  } else if (!canControl) {
    disabledTooltip = t('printer.tooltip.rights')
  } else if (!isInAvailableState) {
    disabledTooltip = printerInState
  } else {
    disabledTooltip = t('gcode-meta.download.not-available-in-connect')
  }

  // Button mock
  if (!printerStorage) {
    return (
      <AdaptiveButton
        label={label}
        isLoading={isLoading}
        isAvailable
        isDisabled
        disabledTooltip={t('printer.no-storage')}
        trigger={() => {}}
      />
    )
  }

  const filePath = data?.path

  if (!filePath) {
    return (
      <>
        <AdaptiveButton
          label={label}
          isLoading={isLoading || copyIsLoading}
          isAvailable
          isDisabled={isDisabled}
          disabledTooltip={disabledTooltip}
          trigger={() =>
            copyAndToPrintQueue({
              team_id: teamId || user.default_team_id,
              hash,
              path: `${printerStorage}/${name}`,
              to_print: false,
              to_queue: true
            })
          }
        />

        <ErrorModal
          show={modal.displayed}
          title={modal.title}
          body={modal.body}
          onHide={() => setModal({ ...modal, displayed: false })}
        />

        {renameModal.displayed && (
          <RenameFileModal
            hash={hash}
            teamId={teamId || user.default_team_id}
            name={name || ''}
            instructions={renameModal.instructions}
            maxFilename={printer.max_filename}
            isDev={printer.is_dev}
            isSla={isSla}
            binarySupported={printer.allowed_functionalities?.includes(IAllowedFunctionalities.BGCODE)}
            path={`${printerStorage}/${name}`}
            onSuccess={(params: IEditFileAttrs) => {
              copyAndToPrintQueue({
                team_id: teamId || user.default_team_id,
                hash: params.hash,
                path: `${printerStorage}/${params.name}`,
                to_print: false,
                to_queue: true
              })
              setRenameModal({ ...renameModal, displayed: false })
            }}
            onCancel={() => setRenameModal({ ...renameModal, displayed: false })}
            isFromConnect
          />
        )}
      </>
    )
  }

  return (
    <AdaptiveButton
      label={label}
      isLoading={isLoading || toPrintLoading}
      isAvailable
      isDisabled={isDisabled}
      disabledTooltip={disabledTooltip}
      trigger={() =>
        toPrintQueue({
          path: filePath,
          position: -1 // last
        })
      }
    />
  )
}
