import styled, { css } from 'styled-components'

import { PlainButton } from '../common/PlainButton'
import { Layers } from '../helpers/zIndex'

type Variant = 'sm'

const SIZE_SM = 20
const SIZE = 30
const LINE_HEIGHT_SM = 2
const LINE_HEIGHT = 3

const Button = styled(PlainButton)`
  height: 100%;
  z-index: ${Layers.HAMBURGER};
  align-items: center;
  padding: 0 1rem;
  margin: 0 -1rem;
`

const LinesContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Line = styled.span<{ $active?: boolean; $variant?: Variant }>`
  display: block;
  height: ${(props) => (props.$variant === 'sm' ? LINE_HEIGHT_SM : LINE_HEIGHT)}px;
  width: ${(props) => (props.$variant === 'sm' ? SIZE_SM : SIZE)}px;
  margin-bottom: 5px;

  background: var(--border-primary);
  border-radius: ${LINE_HEIGHT}px;

  transform-origin: 4px 0px;

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    opacity 0.55s ease;

  &:last-of-type {
    margin: 0;
  }

  ${(props) =>
    props.$active &&
    css`
      opacity: 1;
      transform: rotate(45deg) translate(5px, -2px);

      &:nth-last-child(2) {
        transform: rotate(-45deg) translate(-5px, 9px);
      }

      &:last-of-type {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
        transition transform 0.2s;
      }
    `}
`

type Props = {
  variant?: Variant
  onClick: () => void
  active?: boolean
}

export function Hamburger(props: Props) {
  const { active, onClick, variant } = props
  return (
    <Button onClick={onClick}>
      <LinesContainer>
        <Line $variant={variant} $active={active} />
        <Line $variant={variant} $active={active} />
        <Line $variant={variant} $active={active} />
      </LinesContainer>
    </Button>
  )
}
