import { useQuery } from '@tanstack/react-query'
import { useEffect, useRef, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'

import { useApiClient } from '../api/react'
import { Container } from '../components/bootstrap/Container'
import { BreadcrumbItem, Breadcrumbs } from '../components/common/Breadcrumbs'
import { Button } from '../components/common/Button'
import { ButtonLink } from '../components/common/ButtonLink'
import { PaceProgress } from '../components/common/PaceProgress'
import { SvgIcon } from '../components/common/SvgIcon'
import { Submenu } from '../components/helpers/submenu'
import { MutationStatus } from '../components/mutationStatus'
import { StyledForm } from '../components/printers/add/SetupPrinterForm'
import { RequiredMark } from '../components/users/UserForm'
import { useLoggedUser } from '../hooks/useLoggedUser'
import { useTeamName } from '../hooks/useTeamName'
import { useWritableTeams } from '../hooks/useTeams'
import { Icon } from '../icons'
import { useAddPrinterService } from '../services/useAddPrinterService'

const FormControl = styled(Form.Control)`
  &::placeholder {
    font-style: italic;
  }
`

type Props = {
  title: string
  icon: Icon
}

function ResponsePage(props: Props) {
  const { t } = useTranslation()

  return (
    <>
      <Submenu>
        <Container>
          <div className="my-1">
            <Breadcrumbs>
              <BreadcrumbItem to="/add" label={t('printer.add')} clickable />
            </Breadcrumbs>
          </div>
        </Container>
      </Submenu>
      <Container className="mt-5">
        <p className="text-center">
          <SvgIcon icon={props.icon} size="80" />
        </p>
        <h4 className="text-center mb-4">{props.title}</h4>
        <p className="text-center">
          <ButtonLink to="/add-printer">
            <SvgIcon icon="plusIcon" size={18} />
            {t('printer.add-another')}
          </ButtonLink>
        </p>
      </Container>
    </>
  )
}

export function AddPrinterByQrPage() {
  const { t } = useTranslation()
  const { code } = useParams<{ code: string }>()
  const api = useApiClient()
  const navigate = useNavigate()
  const [alreadyRegistered, setAlreadyRegistered] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const [expired, setExpired] = useState(false)
  const [isError, setIsError] = useState(false)

  const { data } = useQuery(
    [`/printer/${code}`],
    () => {
      if (code) {
        return api.app.printers.registerPrinter(code)
      }
    },
    {
      onError: (error: any) => {
        if (error.code === 'NOT_FOUND_REGISTRATION') {
          setNotFound(true)
        }
        if (error.code === 'REGISTRATION_EXPIRED') {
          setExpired(true)
        }
        setIsError(true)
      },
      onSuccess: (response) => {
        if (response?.code === 'REGISTRATION_COMPLETED') {
          setAlreadyRegistered(true)
        }
      }
    }
  )

  useEffect(() => {
    if (data?.printer_type) {
      navigate(`/add-printer/setup/${data.printer_type}`, { replace: true, state: { code: data.code } })
    }
  }, [data])

  if (notFound) {
    return <ResponsePage title={t('printer.add.not-found')} icon="nokIcon" />
  }

  if (alreadyRegistered) {
    return <ResponsePage title={t('printer.already.added')} icon="okIcon" />
  }

  if (expired) {
    return <ResponsePage title={t('printer.add.expired')} icon="nokIcon" />
  }

  if (isError) {
    return <ResponsePage title={t('printer.add.does-not-exist')} icon="nokIcon" />
  }

  return <PaceProgress />
}

export function AddPrinterByQrManualPage() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const theme = useTheme()
  const { writableTeams } = useWritableTeams()
  const [code, setCode] = useState('')
  const [printerName, setPrinterName] = useState('')
  const [printerLocation, setPrinterLocation] = useState('')
  const [validated, setValidated] = useState(false)
  const user = useLoggedUser()
  const { getTeamName } = useTeamName()
  const [teamId, setTeamId] = useState(user.default_team_id)
  const addPrinter = useAddPrinterService((createdPrinter) => {
    navigate(`/printer/${createdPrinter.uuid}`) // printer detail
  })

  const formRef = useRef<HTMLFormElement | null>(null)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!code) {
      if (formRef.current) {
        setValidated(true)
        return formRef.current.dispatchEvent(new Event('submit'))
      }
    } else if (teamId) {
      addPrinter.mutate({
        code,
        name: printerName,
        location: printerLocation,
        team_id: teamId
      })
    }
  }

  return (
    <>
      <Submenu>
        <Container>
          <div className="my-1">
            <Breadcrumbs>
              <BreadcrumbItem to="/add" label={t('printer.add')} clickable />
            </Breadcrumbs>
          </div>
        </Container>
      </Submenu>
      <Container>
        <h4 className="text-center my-4">{t('printer.add-new')}</h4>

        <StyledForm ref={formRef} onSubmit={handleSubmit} validated={validated}>
          <Row className="mb-3">
            <Col>
              <Form.Group>
                <Form.Label>
                  {t('printer.form.code-label')}
                  <RequiredMark>*</RequiredMark>
                </Form.Label>
                <FormControl
                  required
                  value={code}
                  type="text"
                  placeholder={t('printer.form.code-placeholder')}
                  onChange={(e: any) => setCode(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">{validated ? t('forms.required') : ''}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="printerName">
                <Form.Label>{t('printer.form.name-label')}</Form.Label>
                <FormControl
                  value={printerName}
                  onChange={(e: any) => setPrinterName(e.target.value)}
                  placeholder={t('printer.form.name-placeholder')}
                  type="text"
                />
              </Form.Group>

              <Form.Group controlId="printerLocation">
                <Form.Label>{t('printer.form.location-label')}</Form.Label>
                <FormControl
                  value={printerLocation}
                  onChange={(e: any) => setPrinterLocation(e.target.value)}
                  placeholder={t('printer.form.location-placeholder')}
                  type="text"
                />
              </Form.Group>

              {writableTeams.length > 1 && (
                <Form.Group controlId="teamSelect">
                  <Form.Label>
                    {t('printer.group.form.team-label')}
                    <RequiredMark>*</RequiredMark>
                  </Form.Label>
                  <Form.Control
                    required
                    as="select"
                    onChange={(e) => setTeamId(Number(e.currentTarget.value))}
                    value={teamId.toString()}
                  >
                    {writableTeams.map((team) => (
                      <option key={team.id} value={team.id}>
                        {getTeamName(team)}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              )}

              <div className="ml-auto my-4">
                <Button type="submit" block disabled={addPrinter.isLoading}>
                  {t('printer.add-final')}
                  <SvgIcon icon="arrowRightIcon" fill={theme.colors.icons.primary} className="ml-1 mr-0" size="18" />
                </Button>
              </div>

              <MutationStatus mutation={addPrinter} />
            </Col>
          </Row>
        </StyledForm>
      </Container>
    </>
  )
}
