import styled, { css } from 'styled-components'

import { PlainButton } from '../../common/PlainButton'
import { textEllipsis } from '../../helpers/styled'

export const Container = styled.div<{ $height?: string }>`
  min-height: 100px;
  height: ${(props) => (props.$height ? props.$height : '100px')};
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`

export const DropAreaContainer = styled.div`
  height: 100%;
  cursor: pointer;
  border-radius: 2px;
  border: 2px dashed var(--border-secondary);
  background: var(--droparea-background);
`

export const DropAreaInner = styled.div<{ $disabled?: boolean }>`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  ${(props) => {
    if (props.$disabled) {
      return css`
        opacity: 0.5;
        cursor: not-allowed;
      `
    }
  }}
`

export const ProgressBarContainer = styled.div`
  .progress {
    height: 22px;
  }
  .progress-bar {
    background-color: var(--color-primary);
  }
`

export const FileRow = styled.div`
  padding: 0.25rem 0;
  display: flex;
  align-items: center;
  gap: 1rem;
`

export const Col = styled.div<{ $width?: string; $truncate?: boolean }>`
  ${(props) => {
    if (!props.$width) {
      return css`
        flex-basis: 0;
        max-width: 100%;
      `
    }

    return css`
      flex-basis: ${props.$width};
      max-width: ${props.$width};
    `
  }}

  ${(props) => props.$truncate && textEllipsis}
`

export const ButtonAsLink = styled(PlainButton)`
  color: #fa6831;
  text-decoration: underline;
  &:hover {
    color: #fa6831;
    text-decoration: none;
  }
`

export const Input = styled.input`
  display: none;
`
