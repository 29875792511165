import dayjs from 'dayjs'
// eslint-disable-next-line import/no-unresolved
import { TopLevelFormatterParams } from 'echarts/types/dist/shared'
// eslint-disable-next-line import/no-unresolved
import { OptionDataValue } from 'echarts/types/src/util/types'
import { useTranslation } from 'react-i18next'

import { IEvent } from '../../../api/types/event'
import { formatPercents, formatTemperature } from '../../../helpers/formatters'
import { isDefined } from '../../../helpers/std'
import { useLoggedUserPreferences } from '../../../hooks/useLoggedUser'
import { TemperatureUnits } from '../../common/Temperature'
import { Series, Targets } from './constants'

function useTargetTitles() {
  const { t } = useTranslation()

  const targetTitles: {
    key: Targets
    title: string
  }[] = [
    {
      key: Targets.TARGET_BED,
      title: t('telemetry.event-target-bed')
    },
    {
      key: Targets.TARGET_NOZZLE,
      title: t('telemetry.event-target-nozzle')
    },
    {
      key: Targets.FLOW,
      title: t('printer.control.flow')
    },
    {
      key: Targets.MATERIAL,
      title: t('printer.control.material', 'Material')
    },
    {
      key: Targets.SPEED,
      title: t('printer.control.speed', 'Speed')
    }
  ]

  return targetTitles
}

function formatValue(key: Targets, value: number, tempSign: TemperatureUnits) {
  switch (key) {
    case Targets.TARGET_BED:
    case Targets.TARGET_NOZZLE:
      return formatTemperature(tempSign, Number(value))

    case Targets.FLOW:
      return formatPercents(value)

    case Targets.SPEED:
      return formatPercents(value)

    default:
      return value
  }
}

function formatTooltipValue(tempSign: TemperatureUnits, seriesId?: Series, value?: number) {
  if (!isDefined(seriesId) || !isDefined(value)) {
    return ''
  }

  if (seriesId.startsWith(Series.TEMPERATURE_NOZZLE)) return formatTemperature(tempSign, value)

  switch (seriesId) {
    case Series.TEMPERATURE_NOZZLE:
    case Series.TEMPERATURE_BED:
    case Series.TEMP_AMBIENT:
    case Series.TEMP_UV_LED:
    case Series.TEMP_CPU:
      return formatTemperature(tempSign, value)

    case Series.FAN_EXTRUDER:
    case Series.FAN_PRINT:
    case Series.FAN_BLOWER:
    case Series.FAN_UV_LED:
    case Series.FAN_REAR:
      return `${Math.round(value)}/min`

    default:
      return value
  }
}

export function useChartFormatters() {
  const { t } = useTranslation()
  const targetTitles = useTargetTitles()
  const units = useLoggedUserPreferences('units')
  const tempSign = units.temp === TemperatureUnits.CELSIUS ? TemperatureUnits.CELSIUS : TemperatureUnits.FAHRENHEIT

  const getTargetTitle = (titleKey: Targets) => {
    const targetTitle = targetTitles.find((title) => title.key === titleKey)
    return targetTitle?.title || titleKey
  }

  const axisTooltipFormatter = (params: TopLevelFormatterParams) => {
    if (!Array.isArray(params)) {
      return ''
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore wrong types
    const time = dayjs.unix(params[0].axisValue / 1000).format('YYYY/MM/DD HH:mm:ss')
    const lines = params
      .filter((series) => isDefined(series.data))
      .map((series) => {
        const valueEntry = series.data as OptionDataValue[]
        const value = formatTooltipValue(tempSign, series.seriesId as Series, Number(valueEntry[1]))
        return [series.marker, series.seriesName, `<b>${value}</b>`]
          .map((cell, i, cells) => {
            const isFirst = i === 0
            const isLast = i === cells.length - 1
            const padding = !isFirst ? 'padding-left: 5px;' : ''
            const align = isLast ? 'text-align: right;' : ''
            return `<td style="${padding}${align}">${cell}</td>`
          })
          .join('')
      })
      .map((row) => `<tr>${row}</tr>`)
      .join('')
    return `${time}<br/><table>${lines}</table>`
  }

  const getEventBody = (event: IEvent) => {
    switch (event.event) {
      case 'VALUE_CHANGED':
        return `
        <strong>${t('printer.events.value-changed')}</strong>
        <table>        
            ${Object.entries(event.data).reduce((acc, current) => {
              const title = getTargetTitle(current[0] as Targets)
              const value =
                typeof current[1] === 'number'
                  ? formatValue(current[0] as Targets, Number(current[1]), tempSign)
                  : current[1]
              acc += `
                <tr>
                    <td>${title}</td>
                    <td style="text-align: right; padding-left: 5px"><strong>${value}</strong></td>
                </tr>`
              return acc
            }, '')}
        </table>
      `
      case 'STATE_CHANGED':
        return `
        <strong>${t('telemetry.event-state-changed')}</strong><br>
        State: <strong>${event.state}</strong>
      `
    }
  }

  const formatEventTooltip = (event: IEvent) => {
    if (!event.created) {
      return ''
    }

    const datetime = dayjs.unix(event.created).format('YYYY/MM/DD HH:mm:ss')

    return `
    ${datetime}<br />
    ${getEventBody(event) ? getEventBody(event) : JSON.stringify(event)}
  `
  }

  return { axisTooltipFormatter, formatEventTooltip }
}
