import { useEffect, useMemo, useRef, useState } from 'react'
import { TFunction, useTranslation } from 'react-i18next'

import { usePrinter } from '../../../context/printerContext'
import { formatPercents } from '../../../helpers/formatters'
import { useCommandMutation } from '../../../hooks/commands/useCommandMutation'
import { Slider } from '../../common/Slider'

enum SpeedType {
  PRINT = 'PRINT',
  FLOW = 'FLOW'
}

function getSettings(t: TFunction, min?: number) {
  const settings = {
    [SpeedType.FLOW]: {
      command: 'SET_FLOW',
      argName: 'flow',
      range: { min: typeof min === 'undefined' ? 10 : min, max: 150 },
      successMessage: {
        title: t('printer.control.flow'),
        body: t('printer.control.flow.description')
      }
    },
    [SpeedType.PRINT]: {
      command: 'SET_SPEED',
      argName: 'speed',
      range: { min: typeof min === 'undefined' ? 10 : min, max: 200 },
      successMessage: {
        title: t('printer.control.speed'),
        body: t('printer.control.speed.description')
      }
    }
  }
  return settings
}

type Props = {
  type: SpeedType | `${SpeedType}`
  printerValue: number
  label: string
  min?: number
}

export function SpeedSlider(props: Props) {
  const { printerValue, type, label, min } = props
  const { t } = useTranslation()
  const { printer } = usePrinter()
  const config = useMemo(() => getSettings(t, min)[type], [t, type, min])
  const [value, setValue] = useState(printerValue)
  const [prevValue, setPrevValue] = useState(printerValue)
  const ref = useRef(printerValue)

  const mutation = useCommandMutation(
    printer,
    config,
    () => setPrevValue(ref.current),
    () => setValue(prevValue)
  )

  const onChangeEnd = (value: number) => {
    ref.current = value
    mutation.execute({
      [config.argName]: value
    })
  }

  // Sync with backend
  useEffect(() => {
    const { max } = config.range
    setValue(Math.min(printerValue, max))
  }, [printerValue])

  return (
    <Slider
      disabled={mutation.isLoading || !mutation.isAvailable}
      label={label}
      minValue={config.range.min}
      maxValue={config.range.max}
      value={value}
      formatValue={formatPercents}
      onChange={setValue}
      onChangeEnd={onChangeEnd}
      refreshTitle={t('printer.control.restore')}
      onRefresh={
        value !== 100
          ? () => {
              setValue(100)
              onChangeEnd(100)
            }
          : undefined
      }
    />
  )
}
