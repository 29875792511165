import { ICreatedUser, MutateUserData, useMutateUser } from '@prusaconnect/api/services/useUsers'
import { FormikContextType, FormikErrors, FormikHelpers, useFormik } from 'formik'
import Markdown from 'markdown-to-jsx'
import { useState } from 'react'
import { Form, FormControl } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { useToast } from '../../context/toastStore'
import { Alert, Severity } from '../bootstrap/Alert'
import { Container } from '../bootstrap/Container'
import { Button } from '../common/Button'
import { ContainerTitle } from '../common/ContainerTitle'
import { LoadingButton } from '../common/LoadingButton'

const initialValues: MutateUserData = {
  public_name: '',
  username: '',
  email: '',
  first_name: '',
  last_name: ''
}

function isSubmitDisabled<T>(formik: FormikContextType<T>) {
  if (!formik.isValid) {
    return true
  }
  return !!formik.isSubmitting
}

export const RequiredMark = styled.span`
  margin-left: 0.25rem;
  color: var(--color-warning);
`

type Props = {
  user?: MutateUserData
  id?: number
}

export function UserForm(props: Props) {
  const { user } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [createdUser, setCreatedUser] = useState<ICreatedUser | null>()
  const toast = useToast()

  const isEdit = !!user

  const successFn = (response: ICreatedUser) => {
    if ('generated_password' in response) {
      setCreatedUser(response)
    } else {
      toast.add(t('users.toasts.update-user.title'), t('users.toasts.update-user.message'))
      navigate(-1)
    }
  }

  const { mutateUser } = useMutateUser(successFn)

  const formik = useFormik({
    initialValues: isEdit ? user : initialValues,
    validate: (values) => {
      const errors: FormikErrors<MutateUserData> = {}
      if (!values.public_name) {
        errors.public_name = t('forms.required')
      }
      if (!values.username) {
        errors.username = t('forms.required')
      }
      return errors
    },
    onSubmit: (values: MutateUserData | MutateUserData, { setSubmitting }: FormikHelpers<MutateUserData>) => {
      if (isEdit && props.id) {
        mutateUser({ formData: values, userId: props.id })
      } else {
        mutateUser({ formData: values })
      }
      setSubmitting(false)
    }
  })

  if (createdUser) {
    return (
      <Container>
        <Alert severity={Severity.INFO}>
          <Markdown>
            {t('users.user-form.success.message', {
              username: createdUser.username,
              password: createdUser.generated_password
            })}
          </Markdown>
        </Alert>
        <div className="d-flex">
          <Button className="mx-auto mt-3" onClick={() => navigate(-1)}>
            {t('users.user-form.success.back-to-overview')}
          </Button>
        </div>
      </Container>
    )
  }

  return (
    <Container>
      <ContainerTitle>{t('users.user-form.title.new-user')}</ContainerTitle>
      <form onSubmit={formik.handleSubmit}>
        <Form.Group controlId="username">
          <Form.Label>
            {t('users.user-form.username')}
            <RequiredMark>*</RequiredMark>
          </Form.Label>
          <FormControl
            value={formik.values.username}
            onChange={formik.handleChange}
            name="username"
            type="text"
            isInvalid={formik.touched.username && !!formik.errors.username}
            isValid={formik.touched.username && !formik.errors.username}
          />
          <Form.Control.Feedback type="invalid">{formik.errors.username}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="public_name">
          <Form.Label>
            {t('users.user-form.public-name')}
            <RequiredMark>*</RequiredMark>
          </Form.Label>
          <FormControl
            value={formik.values.public_name}
            onChange={formik.handleChange}
            name="public_name"
            type="text"
            isInvalid={formik.touched.public_name && !!formik.errors.public_name}
            isValid={formik.touched.public_name && !formik.errors.public_name}
          />
          <Form.Control.Feedback type="invalid">{formik.errors.public_name}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="first_name">
          <Form.Label>{t('users.user-form.first-name')}</Form.Label>
          <FormControl value={formik.values.first_name} onChange={formik.handleChange} name="first_name" type="text" />
        </Form.Group>

        <Form.Group controlId="last_name">
          <Form.Label>{t('users.user-form.last-name')}</Form.Label>
          <FormControl value={formik.values.last_name} onChange={formik.handleChange} name="last_name" type="text" />
        </Form.Group>

        <Form.Group controlId="email">
          <Form.Label>{t('users.user-form.email')}</Form.Label>
          <FormControl value={formik.values.email} onChange={formik.handleChange} name="email" type="email" />
        </Form.Group>

        <div className="d-flex">
          <Button type="button" onClick={() => navigate(-1)}>
            {t('button.back')}
          </Button>
          <LoadingButton
            className="ml-auto"
            disabled={isSubmitDisabled(formik)}
            isLoading={formik.isSubmitting}
            type="submit"
          >
            {t('button.confirm')}
          </LoadingButton>
        </div>
      </form>
    </Container>
  )
}
