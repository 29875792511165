import { stringify } from 'query-string'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import styled, { useTheme } from 'styled-components'

import { applySort, Order, parseSortBy, SEPARATOR, serializeSortBy, SortItem } from '../../../helpers/sorting'
import { useQueryParams } from '../../../hooks/useQueryParams'
import { PlainButton } from '../../common/PlainButton'
import { SvgIcon } from '../../common/SvgIcon'
import { useFilterHandlers } from '../hooks/useFilterHandlers'

const SortButton = styled(PlainButton)`
  cursor: pointer;
  text-decoration: underline;
  font-size: 12px;
  svg {
    margin-left: 0.3rem;
  }
  :hover {
    color: ${(props) => props.theme.colors.primary};
  }
`

export enum ISortCol {
  STATE = 'state',
  NAME = 'name',
  TYPE = 'type',
  LOCATION = 'location'
}

function useLabels() {
  const { t } = useTranslation()

  return {
    [ISortCol.STATE]: t('printer.sort.state'),
    [ISortCol.NAME]: t('printer.sort.name'),
    [ISortCol.TYPE]: t('printer.sort.type'),
    [ISortCol.LOCATION]: t('printer.sort.location')
  }
}

export const REMAINING_TIME = '+remaining_time'
export const DEFAULT_SORT = `+${ISortCol.NAME}${encodeURIComponent(SEPARATOR)}${REMAINING_TIME}`

function SortIcon({ sortItem }: { sortItem?: SortItem }) {
  if (!sortItem) {
    return <SvgIcon icon="arrowsSortDisabledIcon" size="16" />
  }

  if (sortItem.order === Order.ASC) {
    return <SvgIcon icon="arrowsSortIcon" size="16" />
  }

  return <SvgIcon icon="arrowsSortIcon" size="16" style={{ transform: 'rotateX(180deg)' }} />
}

export function Sorting() {
  const labels = useLabels()
  const theme = useTheme()
  const { resetSort, setFilter } = useFilterHandlers()

  const options = Object.values(ISortCol).map((col) => ({
    label: labels[col],
    value: col
  }))

  const navigate = useNavigate()
  const params = useQueryParams({ decode: false }) // custom decoding of sort_by param
  const sortItems = parseSortBy(params.sort_by ? String(params.sort_by) : '')

  const onSort = (sortKey: string) => {
    const newSortItems = applySort(sortItems, sortKey, true)
    const serialized = serializeSortBy(newSortItems, true)

    setFilter({
      key: 'sort_by',
      value: serialized
    })

    const search = stringify(
      {
        ...params,
        sort_by: serialized || REMAINING_TIME
      },
      { arrayFormat: 'bracket', encode: false }
    )

    navigate({
      search
    })
  }

  const isResetVisible = sortItems.filter((item) => item.key !== 'remaining_time').length > 0

  return (
    <>
      {options.map((option) => (
        <SortButton key={option.value} onClick={() => onSort(option.value)}>
          {option.label}
          <SortIcon sortItem={sortItems.find((item) => item.key === option.value)} />
        </SortButton>
      ))}
      {isResetVisible && (
        // TODO what is this type mismatch? The logic behind clearing the filter is a bit of a mess
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <PlainButton className="d-flex align-items-center" onClick={resetSort}>
          <SvgIcon icon="nokIcon" fill={theme.colors.icons.secondary} size={16} />
        </PlainButton>
      )}
    </>
  )
}
