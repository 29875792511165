import { useTranslation } from 'react-i18next'

import { ButtonLink } from '../common/ButtonLink'
import { EmptyList } from '../common/EmptyList'
import { SvgIcon } from '../common/SvgIcon'

export function NoPrinters() {
  const { t } = useTranslation()
  return (
    <EmptyList title={t('printer.empty-list')}>
      <p>{t('printer.empty-list-note')}</p>
      <p>
        <ButtonLink to="/add-printer/select">
          <SvgIcon icon="plusIcon" size={18} />
          {t('printer.add')}
        </ButtonLink>
      </p>
    </EmptyList>
  )
}
