import { useMutation } from '@tanstack/react-query'
import React, { useEffect, useRef, useState } from 'react'
import { Form, FormControl, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { useApiClient } from '../../../api/react'
import { IEditFileAttrs, IFile } from '../../../api/types/file'
import { useErrorHandler } from '../../../hooks/errors/useErrorHandler'
import { useMaxFolderFileNameLength } from '../../../hooks/useMaxFolderFileNameLength'
import { isSubmitDisabled, useFileNameRules } from '../../../hooks/useValidationRules'
import { Button } from '../../common/Button'
import { LoadingButton } from '../../common/LoadingButton'
import { MutationStatus } from '../../mutationStatus'
import { RenameFileInstructions } from './RenameFileInstructions'

export type IFileFormData = {
  filename: string
}

type Props = {
  name: string
  hash?: string
  teamId?: number
  isLoading?: boolean
  maxFilename?: number
  isDev?: boolean
  path?: string
  instructions?: string
  isSla?: boolean
  isFromConnect?: boolean
  binarySupported?: boolean
  onSave?: (params: IEditFileAttrs) => void
  onSuccess?: (params: IEditFileAttrs) => void
  onCancel: () => void
}

export function RenameFileModal(props: Props) {
  const {
    onSave,
    onCancel,
    onSuccess,
    hash,
    teamId,
    name,
    isLoading,
    maxFilename,
    isDev,
    isSla,
    isFromConnect,
    binarySupported,
    path,
    instructions
  } = props
  const { t } = useTranslation()
  const [fileName, setFileName] = useState(name)
  const [overwrite, setOverwrite] = useState(false)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const api = useApiClient()
  const errorHandler = useErrorHandler()
  const maxLength = useMaxFolderFileNameLength(path, maxFilename, isDev)
  const formik = useFileNameRules(fileName, maxLength, binarySupported, isSla, isFromConnect)

  const renameFile = useMutation<IFile, unknown, IEditFileAttrs & { teamId: number; hash: string }>(
    (params) =>
      api.app.files.updateFile(
        params.teamId,
        params.hash,
        {
          name: params.name
        },
        overwrite
      ),
    {
      onSuccess: (response) => {
        onSuccess?.({ name: `${fileName}`, hash: response.hash })
      },
      onError: (error: any) => {
        errorHandler(error.response, error)
        if (error.code === 'CONFLICT_CONNECT_FILE') {
          setOverwrite(true)
        }
      }
    }
  )

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  const updateName = () => {
    if (teamId && hash) {
      return renameFile.mutate({ name: fileName, teamId, hash })
    }

    if (onSave) {
      onSave({ name: fileName })
    }
  }

  const valueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(event)
    setFileName(event.currentTarget.value)
    setOverwrite(false)
  }

  return (
    <Modal show onHide={onCancel} centered>
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <Modal.Header closeButton>
          <Modal.Title>{t('file.rename.title', 'Rename file')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{instructions}</p>
          {maxFilename && path && <RenameFileInstructions maxFilename={maxFilename} path={path} isDev={isDev} />}
          <Form.Label htmlFor="fileName" className="font-weight-bold">
            {t('file.rename.file-name', 'File name')}
          </Form.Label>
          <Form.Group controlId="fileName">
            <FormControl
              ref={inputRef}
              value={formik.values.filename}
              onChange={valueChange}
              name="filename"
              type="text"
              isInvalid={formik.touched.filename && !!formik.errors.filename}
              isValid={formik.touched.filename && !formik.errors.filename}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.filename}</Form.Control.Feedback>
          </Form.Group>
          {overwrite && <MutationStatus mutation={renameFile} />}
        </Modal.Body>
        <Modal.Footer>
          <Button type="reset" onClick={onCancel} disabled={isLoading}>
            {t('button.cancel')}
          </Button>
          <LoadingButton
            type="submit"
            onClick={updateName}
            disabled={renameFile.isLoading || isSubmitDisabled(formik)}
            isLoading={renameFile.isLoading || formik.isSubmitting}
          >
            {overwrite ? t('button.file.rename.overwrite', 'Rename and overwrite') : t('button.save')}
          </LoadingButton>
        </Modal.Footer>
      </form>
    </Modal>
  )
}
