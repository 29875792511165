import classNames from 'classnames'
import { AriaRole, ButtonHTMLAttributes, ReactNode } from 'react'
import styled, { css } from 'styled-components'

import { PlainButton } from './PlainButton'
import { WithTooltip } from './WithTooltip'

export type Size = 'sm'

function getSizeProps(size?: Size) {
  if (size === 'sm') {
    return css`
      border-radius: 0.2rem;
      font-size: 0.875rem;
      padding: 0.25rem 0.5rem;
    `
  }

  return css`
    border-radius: 0.25rem;
    padding: 0.3em 0.5em;
  `
}

export function getButtonStyle(size?: Size, block?: boolean) {
  return css`
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.5;
    color: var(--color-text);
    border: 1px solid var(--border-primary);
    white-space: nowrap;
    cursor: pointer;

    ${getSizeProps(size)}

    justify-content: center;
    ${block
      ? css`
          display: flex;
          width: 100%;
        `
      : css`
          display: inline-flex;
        `}
    align-items: center;

    transition: all 200ms ease;

    &:not(.disabled):active,
    &:not(.disabled):hover,
    &:not(:disabled):active,
    &:not(:disabled):hover {
      background-color: #343a40;
      border-color: #343a40;
      color: #fff;
    }
    &:not(.disabled):focus,
    &:not(:disabled):focus {
      box-shadow: 0 0 0 0.2rem rgb(52 58 64 / 50%);
    }

    &.disabled,
    &:disabled {
      opacity: 0.65; /* same as bootstrap disabled buttons */
      cursor: not-allowed;
    }

    svg {
      margin-right: 0.25rem;
    }
  `
}

export const StyledButton = styled(PlainButton)<{ $size?: Size; $block?: boolean }>`
  ${(props) => getButtonStyle(props.$size, props.$block)}
`

export type CommonButtonProps<T> = ButtonHTMLAttributes<T> & {
  className?: string
  block?: boolean
  children: ReactNode
  size?: Size
  role?: AriaRole
  disabledTooltip?: string
}

export type Props = CommonButtonProps<HTMLButtonElement>

export const Button = ({ block, children, size, className, ...buttonProps }: Props) => (
  <WithTooltip id="disabled-tooltip" title={buttonProps.disabled && buttonProps.disabledTooltip} placement="top">
    <StyledButton className={classNames('connect-button', className)} $block={block} $size={size} {...buttonProps}>
      {children}
    </StyledButton>
  </WithTooltip>
)
