import { Outlet } from 'react-router-dom'
import styled from 'styled-components'

import { ScrollToTop } from '../components/common/ScrollToTop'
import { Footer } from '../components/Footer'
import { Header } from '../components/header/Header'
import { Toasts } from '../components/helpers/toasts'

const LayoutContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

const Content = styled.div`
  flex-grow: 1;
`

export function Layout() {
  return (
    <LayoutContainer>
      <Header />
      <Content>
        <Outlet />
      </Content>
      <ScrollToTop />
      <Toasts />
      <Footer />
    </LayoutContainer>
  )
}
