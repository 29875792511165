import styled, { css, keyframes } from 'styled-components'

import { THINSP } from '../../helpers/formatters'
import { isNumber } from '../../helpers/std'
import { useLoggedUserPreferences } from '../../hooks/useLoggedUser'
import { convert, getParts, TemperatureUnits } from './Temperature'

const ARROW_UP = '↗'
const ARROW_DOWN = '↘'
const ARROW_LEFT = '→'

enum Pulse {
  INCREASE,
  DECREASE
}

const pulseColors = {
  [Pulse.DECREASE]: '#0072ff',
  [Pulse.INCREASE]: 'red'
}

const pulsate = (color: string) => keyframes`
  0% {
    color: ${color};
  }
  75% {
    color: inherit;
  }
  100% {
    color: ${color};
  }
`

const Container = styled.span<{ $noFixedWidth?: boolean }>`
  display: inline-block;
  min-width: ${(props) => (props.$noFixedWidth ? '0' : '9ch')};
`

const Value = styled.span<{ $pulse: Pulse | null }>`
  text-align: right;

  ${(props) => {
    if (props.$pulse === null) {
      return ''
    }

    const color = pulseColors[props.$pulse]
    return css`
      animation: ${pulsate(color)} 1.5s ease-in-out;
      animation-iteration-count: infinite;
    `
  }}
`

const Arrow = styled.span<{
  $scale?: boolean
}>`
  ${({ $scale }) =>
    $scale &&
    `
    margin: 0 2.5px;
  `}
`

type Props = {
  value?: number
  noFixedWidth?: boolean
  targetValue?: number
}

function getPulse(value: Props['value'], targetValue: Props['targetValue']) {
  if (isNumber(value) && isNumber(targetValue) && targetValue > 0) {
    const current = Math.round(value)
    const target = Math.round(targetValue)
    if (current > target) {
      return Pulse.DECREASE
    }

    if (current < target) {
      return Pulse.INCREASE
    }
  }
  return null
}

export function PulsingTemperature(props: Props) {
  const { value, noFixedWidth, targetValue } = props
  const pulse = getPulse(value, targetValue)

  const units = useLoggedUserPreferences('units')
  const degreesC = getParts(value, targetValue, TemperatureUnits.CELSIUS).join(THINSP)
  const degreesF = getParts(value, targetValue, TemperatureUnits.FAHRENHEIT).join(THINSP)

  const renderArrow = () => {
    const showArrow = isNumber(value) && isNumber(targetValue) && targetValue > 0
    if (!showArrow) {
      return null
    }
    if (pulse === Pulse.DECREASE) {
      return <Arrow $scale>{ARROW_DOWN}</Arrow>
    }
    if (pulse === Pulse.INCREASE) {
      return <Arrow $scale>{ARROW_UP}</Arrow>
    }
    return <Arrow>{ARROW_LEFT}</Arrow>
  }

  const sign = units.temp === TemperatureUnits.CELSIUS ? TemperatureUnits.CELSIUS : TemperatureUnits.FAHRENHEIT

  return (
    <Container $noFixedWidth={noFixedWidth} title={units.temp === TemperatureUnits.CELSIUS ? degreesF : degreesC}>
      {value && (
        <Value $pulse={pulse}>
          {convert(value, sign).toFixed(0)}
          {renderArrow()}
        </Value>
      )}

      {isNumber(targetValue) && targetValue > 0 && <>{convert(targetValue, sign).toFixed(0)}</>}

      {isNumber(value) && (
        <>
          {THINSP}
          {sign}
        </>
      )}
    </Container>
  )
}
