import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const MenuItem = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 0.875rem;

  & button,
  a {
    font-weight: 700;
  }
`

const linkStyle = css`
  font-size: 0.875rem;
  color: var(--color-text) !important;
  display: flex;
  align-items: center;
  height: 100%;
  border-bottom: 1px solid transparent;
  white-space: nowrap;
`

const hoverStyle = css`
  &:hover {
    text-decoration: none;
    color: var(--color-primary) !important;
    border-color: var(--color-primary);
  }
`

export const MenuLinkExternal = styled.a`
  ${linkStyle};
  ${hoverStyle};
`

export const MenuLink = styled(NavLink)<{ $noHover?: boolean }>`
  ${linkStyle};

  ${(props) =>
    !props.$noHover &&
    css`
      // added by NavLink
      &.active {
        border-bottom: 1px solid var(--color-secondary);
      }
      ${hoverStyle};
    `}
`

export const HeaderMenu = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  gap: 2rem;
`
