import { Placement } from '@popperjs/core'
import { MouseEvent, ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { usePopper } from 'react-popper'

import { IPrinterSimpleView } from '../../api/types/printer'
import { isDefined } from '../../helpers/std'
import { useOutsideClick } from '../../hooks/useOutsideClick'
import { Layers } from '../helpers/zIndex'
import { JobLayout } from '../printers/overview/JobLayout'
import { ViewIcons } from '../printers/PrintersOverview/PrintersOverview'
import { getPrinterName } from '../printers/utils'
import { FilamentColorTag } from './FilamentColorTag'
import * as S from './MegaMenu.styled'
import { PrinterIcon } from './PrinterIcon'
import { PrinterStateTag } from './PrinterStateTag'
import { SvgIcon } from './SvgIcon'

type MegaMenuProps = {
  trigger: ReactNode
  children: ReactNode
  gridTemplateColumns: string
  placement?: Placement
  onMenuClick?: (e: MouseEvent) => void
  onTriggerClick?: (e: MouseEvent) => void
}

type MegaMenuItemLinkProps = {
  printer: IPrinterSimpleView
  basic: boolean
}

export function MegaMenuItemLink(props: MegaMenuItemLinkProps) {
  const { printer, basic } = props
  const { t } = useTranslation()

  return (
    <S.RowLink to={`/printer/${printer.uuid}`}>
      <JobLayout.Left>
        <S.ColImg>
          <PrinterIcon type={printer.printer_type} size={100} />
        </S.ColImg>
      </JobLayout.Left>
      <JobLayout.Right>
        <JobLayout.TitleContainer>
          <S.PrinterTitle title={getPrinterName(printer)}>{getPrinterName(printer)}</S.PrinterTitle>
        </JobLayout.TitleContainer>

        <JobLayout.Params>
          <PrinterStateTag state={printer.connect_state} />
        </JobLayout.Params>
        <S.Params>
          {printer.location && (
            <S.Param>
              <S.Label>{t('printer.location')}</S.Label>
              <S.Value>{printer.location}</S.Value>
            </S.Param>
          )}

          <S.Param>
            <S.Label>{t('printer.team')}</S.Label>
            <S.Value>{printer.team_name}</S.Value>
          </S.Param>

          {!basic && (
            <S.Param>
              <S.Label>{t('printer.printer-type')}</S.Label>
              <S.Value>{printer.printer_type_name}</S.Value>
            </S.Param>
          )}

          {printer.filament && (
            <S.Param>
              <S.Label>{t('printer.filament')}</S.Label>
              <S.Value>
                <FilamentColorTag filament={printer.filament} material weight />
              </S.Value>
            </S.Param>
          )}

          {printer.firmware && (
            <S.Param>
              <S.Label>{t('printer.firmware')}</S.Label>
              <S.Value>{printer.firmware}</S.Value>
            </S.Param>
          )}
        </S.Params>
      </JobLayout.Right>
    </S.RowLink>
  )
}

export function MegaMenu(props: MegaMenuProps) {
  const { t } = useTranslation()
  const { trigger, children, gridTemplateColumns, onMenuClick, onTriggerClick, placement = 'bottom-start' } = props
  // https://popper.js.org/react-popper/v2/#example
  const [triggerElement, setTriggerElement] = useState<HTMLElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)

  const { styles, attributes, update } = usePopper(triggerElement, popperElement, {
    placement
  })
  const [open, setOpen] = useState(false)

  const toggleMegaMenu = (visible?: boolean) => {
    setOpen((prev) => {
      if (isDefined(visible)) {
        return visible
      }
      return !prev
    })

    // This ensures the megamenu is properly aligned
    update?.()
  }

  useOutsideClick({ current: popperElement }, (event) => {
    // Don't re-open on trigger click
    if (triggerElement?.contains(event.target as Node)) {
      return
    }

    toggleMegaMenu(false)
  })

  return (
    <>
      <S.ToggleButton
        ref={setTriggerElement}
        onClick={(e) => {
          onTriggerClick?.(e)
          toggleMegaMenu()
        }}
      >
        {trigger}
      </S.ToggleButton>

      <div
        ref={setPopperElement}
        // Items can have Modals, so we need this to be mounted
        style={{ ...styles.popper, zIndex: Layers.MEGAMENU, display: open ? undefined : 'none', width: '100%' }}
        {...attributes.popper}
      >
        <S.Menu
          onClick={(e) => {
            const element = e.target as HTMLElement
            if (!element.dataset?.exceptionElement) {
              toggleMegaMenu(false)
            }
            onMenuClick?.(e)
          }}
        >
          <S.PrinterGrid $gridTemplateColumns={gridTemplateColumns}>
            {children}
            <S.CloseButton type="button" className="close" aria-label={t('button.close')}>
              <SvgIcon icon="nokIcon" size={20} />
            </S.CloseButton>
          </S.PrinterGrid>

          <S.Footer>
            <S.FooterTitle>{t('megamenu.printer.overview')}</S.FooterTitle>
            <ViewIcons />
          </S.Footer>
        </S.Menu>
      </div>
    </>
  )
}
