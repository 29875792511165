import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import styled from 'styled-components'

import { PrinterUuid } from '../../../api/types/printer'
import { usePrinterType } from '../../../hooks/usePrinterType'
import { PrinterIcon } from '../../common/PrinterIcon'
import { DownloadSettingsButton } from '../DownloadSettingsButton'
import { H2, P as P1 } from './styled'

export const P = styled(P1)`
  margin-top: 2em;
`

type RouteInfo = {
  printerType: string
  printerUuid: PrinterUuid
}

export function DownloadSettingsPage() {
  const { t } = useTranslation()
  const params = useParams<RouteInfo>()
  const printerType = usePrinterType(String(params.printerType))
  const printerTypeName = printerType?.name || ''
  const uuid = params.printerUuid

  return (
    <>
      <Row>
        <Col md="auto">
          <PrinterIcon size={200} type={params.printerType} />
        </Col>
        <Col>
          <P>{t('printer.add.selected-printer')}</P>
          <H2>
            {printerTypeName.startsWith('Original Prusa') ? printerTypeName : `Original Prusa ${printerTypeName}`}
          </H2>
        </Col>
      </Row>
      <section>
        <H2>{t('printer.settings.lan')}</H2>
        <p>{t('printer.settings.lan-description')}</p>
        <div className="d-flex justify-content-end">
          <DownloadSettingsButton url={`app/printers/${uuid}/configuration`} />
        </div>
      </section>
    </>
  )
}
