import { ReactNode } from 'react'
import styled from 'styled-components'

import { ThreeDots } from '../../header/DotsDropdown'
import { Dropdown } from '../Dropdown'
import { AdaptiveButtonContext } from './AdaptiveButtonContext'
import { Mode, useRenderMode } from './useRenderMode'

const IconButtons = styled.div`
  display: flex;
  gap: 1rem;
`

type Props = {
  children: ReactNode
  foldable?: boolean
}

export function ActionsRenderer({ children, foldable = true }: Props) {
  const mode = useRenderMode()

  if (mode === Mode.DROPDOWN && foldable) {
    return (
      <AdaptiveButtonContext value="dropdown">
        <Dropdown
          trigger={<ThreeDots size={20} />}
          placement="bottom-end"
          // dropdown in inside <a> tag
          onMenuClick={(e) => e.preventDefault()}
        >
          {children}
        </Dropdown>
      </AdaptiveButtonContext>
    )
  }

  return (
    <AdaptiveButtonContext value="simple">
      <IconButtons>{children}</IconButtons>
    </AdaptiveButtonContext>
  )
}
