import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import Markdown from 'markdown-to-jsx'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import styled, { createGlobalStyle } from 'styled-components'

import { useApiClient } from '../../../api/react'
import { IFileType, IPrinterFilesResponse, IUploadQueueFile, IUploadQueueFirmwareFile } from '../../../api/types/file'
import {
  AvailablePrinterTypes,
  FirmwareFile,
  IAllowedFunctionalities,
  IPrinter,
  IPrinterStorageLocation,
  IPrinterStorageType,
  PrinterFirmwareDecisionMaker,
  PrinterSupportState
} from '../../../api/types/printer'
import { intervals } from '../../../config'
import { useToast } from '../../../context/toastStore'
import { useCommandMutation } from '../../../hooks/commands/useCommandMutation'
import { useErrorHandler } from '../../../hooks/errors/useErrorHandler'
import { useStorageLocations } from '../../../hooks/storageLocations/useStorageLocations'
import { Language } from '../../../interfaces/locale'
import useServiceResponseHandler from '../../../services/useServiceResponseHandler'
import { ConfirmModal } from '../../common/ConfirmModal'
import { SvgIcon } from '../../common/SvgIcon'
import { WithTooltip } from '../../common/WithTooltip'
import { useStyles } from '../filters/styles'
import * as S from '../PrintersOverview/ListView.styled'
import { useFirmware } from './useFirmware'

const StyledIcon = styled(SvgIcon)`
  margin-left: 5px;
  cursor: pointer;
`

const GlobalStyle = createGlobalStyle`
  #current-printer-firmware-stable-version .tooltip-inner {
    background-color: ${({ theme }) => theme.colors.primary};
    border-radius: 10px;
  }

  #current-printer-firmware-stable-version .arrow::before {
    border-right-color: ${({ theme }) => theme.colors.primary};
  }

  .tooltip.show {
    opacity: 1;
  }

  #printer-firmware-modal {
    .installation-description p {
      margin: 0;
    }

    a {
      text-decoration: underline !important;
    }
  }

  #printer-restart-modal {
    a {
      text-decoration: underline !important;
    }
  }
`

const WarningHeading = styled.h6`
  margin-top: 1rem;
  display: flex;
  align-items: center;
`

function UpgradeWarning(props: { type: 'DOWNLOAD' | 'RESTART' }) {
  const { t } = useTranslation()

  const warningBody =
    props.type === 'DOWNLOAD'
      ? t('printer.firmware.confirm.body.warning-description')
      : t('printer.firmware.confirm.body.warning-restart-description')

  return (
    <div>
      <WarningHeading>
        <SvgIcon icon="warningIcon" /> {t('printer.firmware.confirm.body.warning-heading')}
      </WarningHeading>
      <p>
        <Markdown>{warningBody}</Markdown>
      </p>
    </div>
  )
}

type OptionType = {
  label: string
  value: FirmwareFile['hash']
}

export function FirmwareUpdate(props: IPrinter) {
  const printer = props
  const {
    showFirmwareAvailable,
    showRestartConfirm,
    showProcessingTransfer,
    showTransferIsInQueue,
    defaultFirmwareDataInterval,
    setShowRestartConfirm,
    setShowFirmwareAvailable,
    setShowProcessingTransfer,
    setShowTransferIsInQueue,
    setDefaultFirmwareDataInterval
  } = useFirmware()

  const { t } = useTranslation()
  const api = useApiClient()
  const errorHandler = useErrorHandler()
  const { displaySuccess } = useServiceResponseHandler()
  const toast = useToast()
  const queryClient = useQueryClient()
  const [option, setOption] = useState<OptionType>()
  const selectStyles = useStyles<OptionType>()

  const enabledFirmwareUpdate =
    printer.allowed_functionalities?.includes(IAllowedFunctionalities.FIRMWARE_UPDATE) &&
    printer.decision_maker === PrinterFirmwareDecisionMaker.FIRMWARE &&
    [PrinterSupportState.OUTDATED, PrinterSupportState.UNSUPPORTED].includes(printer.support.state)

  function getUsbMountpoint(storageLocations: IPrinterStorageLocation[]) {
    return storageLocations.find((location) => location.type === IPrinterStorageType.USB)?.mountpoint
  }

  function countExistingFirmwareFiles(printerFiles: IPrinterFilesResponse) {
    return printerFiles.files.filter((file) => file.type === IFileType.FIRMWARE).length
  }

  useEffect(() => {
    queryClient.invalidateQueries(['PRINTER_FIRMWARE_CHECK_FILES', printer.uuid])
  }, [showRestartConfirm, showFirmwareAvailable])

  const { data: lastStableFirmwareData } = useQuery(
    [`/printers/${printer.uuid}/firmware`, option?.value],
    () => {
      if (enabledFirmwareUpdate) {
        return api.app.printers.getPrinterLastStableFirmware(printer.uuid, { hash: option?.value })
      }
    },
    {
      enabled: enabledFirmwareUpdate,
      refetchInterval: defaultFirmwareDataInterval
    }
  )

  const { data: availableFws } = useQuery(
    [`/app/firmwares`],
    () => api.app.printers.getAvailableFirmwares({ printer_uuid: printer.uuid }),
    {
      enabled: showFirmwareAvailable || !!option,
      refetchInterval: defaultFirmwareDataInterval
    }
  )

  const { locations: printerStorageLocations } = useStorageLocations(printer)

  const { data: printerFiles } = useQuery(
    ['PRINTER_FIRMWARE_CHECK_FILES', printer.uuid],
    () =>
      api.app.files.getPrinterFiles(
        printer.uuid,
        { file_type: IFileType.FIRMWARE },
        getUsbMountpoint(printerStorageLocations)
      ),
    { enabled: !!printerStorageLocations && !!getUsbMountpoint(printerStorageLocations) }
  )

  const upgradeNotice =
    !lastStableFirmwareData?.path && !(lastStableFirmwareData?.transferring || lastStableFirmwareData?.planned)
  const firmwarePlannedNotice = Boolean(lastStableFirmwareData?.planned)
  const firmwareTransferringNotice = Boolean(lastStableFirmwareData?.transferring)
  const firmwareTransferredNotice =
    lastStableFirmwareData?.path && !(lastStableFirmwareData?.transferring || lastStableFirmwareData?.planned)

  const deleteFileFromQueueMutation = useMutation(
    (plannedTransferId: number) => api.app.transfers.deleteTransfer(printer.uuid, plannedTransferId),
    {
      onSuccess: () => {
        displaySuccess(t('printer.queue.deleted.title'), t('printer.queue.deleted.body'))
      }
    }
  )

  const {
    mutate: addNewFirmwareToDownloadQueue,
    isLoading: isAddNewFirmwareToDownloadQueueLoading,
    isSuccess: isAddNewFirmwareToDownloadQueueSuccess
  } = useMutation(
    (firmwareFileInfo: IUploadQueueFirmwareFile) =>
      api.app.transfers.addToPrinterDownloadQueue(printer.uuid, firmwareFileInfo as IUploadQueueFile),
    {
      onSuccess: () => {
        toast.add(
          t('printer.firmware.download-action.success-title'),
          t('printer.firmware.download-action.success-body')
        )
      },
      onError: (error: Error) => {
        errorHandler(error)
      }
    }
  )

  const restartPrinterMutation = useCommandMutation(
    printer,
    {
      command: 'UPGRADE'
    },
    () => null,
    () => {
      toast.add(
        t('printer.firmware.restart-printer-action.error-title'),
        t('printer.firmware.restart-printer-action.error-body')
      )
    }
  )

  // TODO is not necessarily current transfer
  const stopTransferMutation = useCommandMutation(
    printer,
    {
      command: 'STOP_TRANSFER'
    },
    () => null,
    () => {
      toast.add(
        t('printer.firmware.restart-printer-action.error-title'),
        t('printer.firmware.restart-printer-action.error-body')
      )
    }
  )

  const firmwareDownloadWarning = printerFiles && countExistingFirmwareFiles(printerFiles) > 0
  const firmwareUpgradeWarning = printerFiles && countExistingFirmwareFiles(printerFiles) > 1

  let englishFw: OptionType | undefined
  const options = availableFws?.files
    .filter((file) => {
      return file.printer_type === printer.printer_type && file.meta?.version === printer.support.stable
    })
    .map((file: any) => {
      if (file.meta?.language === Language.cs) {
        englishFw = file
      }
      return {
        label: file.display_name,
        value: file.hash
      }
    })

  if (englishFw) {
    options?.unshift({ label: englishFw.label, value: englishFw.value })
  } else if (options && options.length > 0) {
    options.unshift({ label: `MINI_english_firmware_${printer.support.stable}.bbf`, value: options[0].value })
  }

  const renderModal = () => (
    <>
      {showFirmwareAvailable && (
        <ConfirmModal
          id="printer-firmware-modal"
          title={t('printer.firmware.confirm.title.firmware-available')}
          body={
            <>
              <p>
                <Markdown>
                  {t('printer.firmware.confirm.body.notice', {
                    version: printer.support.stable,
                    link: printer.support.release_url
                  })}
                </Markdown>
              </p>

              {printer.printer_type === AvailablePrinterTypes.Original_Prusa_MINI && (
                <Select
                  className="mb-4"
                  options={options}
                  value={options?.find((opt) => opt.value === option?.value)}
                  onChange={(newOption) => {
                    setOption(newOption as OptionType)
                  }}
                  styles={selectStyles}
                  placeholder={t('placeholder.select.language', 'Select firmware language')}
                  noOptionsMessage={({ inputValue }) => (!inputValue ? t('select.no-options') : t('select.no-results'))}
                />
              )}

              <h6>{t('printer.firmware.confirm.body.installation')}</h6>
              <div className="installation-description">
                <p>{t('printer.firmware.confirm.body.installation-description-first')}</p>
                <p>{t('printer.firmware.confirm.body.installation-description-second')}</p>
              </div>

              {firmwareDownloadWarning && <UpgradeWarning type="DOWNLOAD" />}
            </>
          }
          confirmBtnIcon="downloadIcon"
          confirmBtnText={t('printer.firmware.confirm.transfer')}
          isDisabled={printer.printer_type === AvailablePrinterTypes.Original_Prusa_MINI && !option}
          onCancel={() => setShowFirmwareAvailable(false)}
          onConfirm={() => {
            setShowFirmwareAvailable(false)
            if (lastStableFirmwareData) {
              addNewFirmwareToDownloadQueue({
                team_id: lastStableFirmwareData.team_id,
                hash: lastStableFirmwareData.hash,
                path: `/usb/${lastStableFirmwareData.display_name}`
              } as IUploadQueueFirmwareFile)
            }
          }}
        />
      )}
      {showTransferIsInQueue && (
        <ConfirmModal
          id="printer-firmware-queued-transfer-modal"
          title={t('printer.firmware.confirm.title.firmware-available')}
          isLoading={isAddNewFirmwareToDownloadQueueLoading}
          body={t('printer.firmware.confirm.body.firmware-in-queue')}
          cancelBtnText={t('printer.firmware.confirm.abort')}
          confirmBtnText={t('printer.firmware.confirm.continue')}
          onCancel={() => {
            setShowTransferIsInQueue(false)
            if (typeof lastStableFirmwareData?.planned?.id === 'number') {
              deleteFileFromQueueMutation.mutate(lastStableFirmwareData?.planned?.id)
            }
          }}
          onConfirm={() => {
            setShowTransferIsInQueue(false)
          }}
        />
      )}
      {showProcessingTransfer && (
        <ConfirmModal
          id="printer-firmware-transferring-modal"
          title={t('printer.firmware.confirm.title.firmware-available')}
          isLoading={isAddNewFirmwareToDownloadQueueLoading}
          body={t('printer.firmware.confirm.body.transferring')}
          cancelBtnText={t('printer.firmware.confirm.abort')}
          confirmBtnText={t('printer.firmware.confirm.continue')}
          onCancel={() => {
            stopTransferMutation.execute({})
            setShowProcessingTransfer(false)
          }}
          onConfirm={() => {
            setShowProcessingTransfer(false)
          }}
        />
      )}
      {showRestartConfirm && (
        <ConfirmModal
          id="printer-restart-modal"
          title={t('printer.firmware.confirm.title.firmware-available')}
          isLoading={restartPrinterMutation.isLoading}
          body={
            <>
              <p>
                <Markdown>
                  {t('printer.firmware.confirm.body.restart-description-first', {
                    version: printer.support.stable,
                    link: printer.support.release_url
                  })}
                </Markdown>
              </p>
              <p>{t('printer.firmware.confirm.body.restart-description-second')}</p>

              {firmwareUpgradeWarning && <UpgradeWarning type="RESTART" />}
            </>
          }
          confirmBtnIcon="restartIcon"
          cancelBtnText={t('printer.firmware.confirm.no')}
          confirmBtnText={t('printer.firmware.confirm.yes')}
          onCancel={() => setShowRestartConfirm(false)}
          onConfirm={() => {
            restartPrinterMutation.execute({})
            setShowRestartConfirm(false)
          }}
        />
      )}
    </>
  )

  const renderIconTooltipTitle = () => {
    if (printer.firmware === '5.0.0+12061') {
      return t(
        'printer.firmware.new-version-notice.not-allowed',
        'An automatic update from firmware version 5.0.0+12061 is not available, please update manually.'
      )
    }
    if (upgradeNotice) {
      return t('printer.firmware.new-version-notice', {
        version: printer.support.stable
      })
    }
    if (firmwarePlannedNotice) {
      return t('printer.firmware.new-version-notice.planned', {
        version: printer.support.stable
      })
    }
    if (firmwareTransferringNotice) {
      return t('printer.firmware.new-version-notice.transferring')
    }
    if (firmwareTransferredNotice && restartPrinterMutation.isAvailable) {
      return t('printer.firmware.new-version-notice.transferred')
    }
    if (firmwareTransferredNotice && !restartPrinterMutation.isAvailable) {
      return t(
        'printer.firmware.new-version-notice.transferred.restart-not-available',
        'New firmware has been transferred to the printer. To restart the printer, it should be in one of the following states: IDLE, READY, FINISHED, or STOPPED.'
      )
    }
  }

  const renderIcon = () => {
    if (printer.firmware === '5.0.0+12061') {
      return <StyledIcon size={18} icon="noRestartIcon" />
    }

    if (upgradeNotice) {
      return (
        <StyledIcon size={18} icon="upgradeIcon" onClick={() => setShowFirmwareAvailable((prevState) => !prevState)} />
      )
    }

    if (firmwarePlannedNotice) {
      return (
        <StyledIcon size={18} icon="downloadIcon" onClick={() => setShowTransferIsInQueue((prevState) => !prevState)} />
      )
    }

    if (firmwareTransferringNotice) {
      return (
        <StyledIcon
          size={18}
          icon="downloadIcon"
          onClick={() => setShowProcessingTransfer((prevState) => !prevState)}
        />
      )
    }

    if (firmwareTransferredNotice && restartPrinterMutation.isAvailable) {
      return (
        <StyledIcon size={18} icon="restartIcon" onClick={() => setShowRestartConfirm((prevState) => !prevState)} />
      )
    }

    if (firmwareTransferredNotice && !restartPrinterMutation.isAvailable) {
      return <StyledIcon size={18} icon="noRestartIcon" />
    }
  }

  useEffect(() => {
    if (upgradeNotice || firmwarePlannedNotice || firmwareTransferredNotice) {
      return setDefaultFirmwareDataInterval(intervals.transfersPolling)
    }
    if (firmwareTransferringNotice || stopTransferMutation.isSuccess || isAddNewFirmwareToDownloadQueueSuccess) {
      return setDefaultFirmwareDataInterval(2000)
    }
  }, [
    firmwarePlannedNotice,
    firmwareTransferredNotice,
    firmwareTransferringNotice,
    isAddNewFirmwareToDownloadQueueLoading,
    isAddNewFirmwareToDownloadQueueSuccess,
    setDefaultFirmwareDataInterval,
    stopTransferMutation.isLoading,
    stopTransferMutation.isSuccess,
    upgradeNotice
  ])

  if (!enabledFirmwareUpdate) {
    return null
  }

  return (
    <>
      <GlobalStyle />
      <div>
        <S.Label>{t('printer.firmware')}</S.Label>
        <WithTooltip id="current-printer-firmware-stable-version" title={renderIconTooltipTitle()} placement="right">
          <S.Value>
            {printer.support.current} {renderIcon()}
          </S.Value>
        </WithTooltip>
      </div>
      {renderModal()}
    </>
  )
}
