import { DropResult } from 'react-beautiful-dnd'

import { PrinterUuid } from '../../../../api/types/printer'
import { IVector3 } from '../../../../api/types/vector'

const CELL_PREFIX = `dropable-cell-`

function serializeVector(vec: IVector3) {
  const { x, y, z } = vec
  return `${x}-${y}-${z}`
}

export function getDroppableId(position: IVector3) {
  return `${CELL_PREFIX}${serializeVector(position)}`
}

export function getDraggableId<PrinterLike extends { uuid: PrinterUuid }>(printer: PrinterLike) {
  return printer.uuid
}

export function parseCellPosition(droppableId: string): IVector3 | null {
  const p = droppableId.match(/(\d+)-(\d+)-(\d+)/)
  if (!p) {
    return null
  }
  const [, x, y, z] = p
  return { x: Number(x), y: Number(y), z: Number(z) }
}

export enum DropTargets {
  AVAILABLE_ITEMS = 'available-printers',
  VIRTUAL_GROUP = 'virtual-group',
  CELL = 'cell'
}

function sourceIsDestination(source: DropResult['source'], destination: DropResult['destination']) {
  return source.droppableId === destination?.droppableId
}

export enum DropAction {
  REMOVE_FROM_GROUP = 'REMOVE_FROM_GROUP',
  ADD_TO_GRID = 'ADD_TO_GRID',
  CHANGE_POSITION = 'CHANGE_POSITION',
  ADD_TO_VIRTUAL_GROUP = 'ADD_TO_VIRTUAL_GROUP'
}

type DropActions =
  | null
  | {
      type: DropAction.REMOVE_FROM_GROUP
      printerUuid: PrinterUuid
    }
  | {
      type: DropAction.ADD_TO_GRID
      position: IVector3
      printerUuid: PrinterUuid
    }
  | {
      type: DropAction.ADD_TO_VIRTUAL_GROUP
      printerUuid: PrinterUuid
    }
  | {
      type: DropAction.CHANGE_POSITION
      position: IVector3
      printerUuid: PrinterUuid
    }

export function getAction(dropResult: DropResult): DropActions {
  // No action
  if (!dropResult.destination || sourceIsDestination(dropResult.source, dropResult.destination)) {
    return null
  }

  const printerUuid = dropResult.draggableId
  // Remove from grid
  if (dropResult.destination.droppableId === DropTargets.AVAILABLE_ITEMS) {
    return {
      type: DropAction.REMOVE_FROM_GROUP,
      printerUuid
    }
  }

  // Add to virtual group, no specific order here
  if (dropResult.destination.droppableId === DropTargets.VIRTUAL_GROUP) {
    return {
      type: DropAction.ADD_TO_VIRTUAL_GROUP,
      printerUuid
    }
  }

  // Add or change position now
  const position = parseCellPosition(dropResult.destination.droppableId)
  if (!position) {
    // Cannot parse - do nothing
    return null
  }

  // Add to grid
  if (dropResult.source.droppableId === DropTargets.AVAILABLE_ITEMS) {
    return {
      type: DropAction.ADD_TO_GRID,
      position,
      printerUuid
    }
  }

  // Changes between grid
  return {
    type: DropAction.CHANGE_POSITION,
    position,
    printerUuid
  }
}
