import { useInfiniteQuery, useQuery } from '@tanstack/react-query'

import { useApiClient } from '../../../../api/react'
import { PrinterUuid } from '../../../../api/types/printer'
import { intervals } from '../../../../config'

export function getFolderQueryKey(printerUuid: PrinterUuid, path: string) {
  return ['folder-content', printerUuid, path]
}

const filesPerPage = 20

export function useFolderFiles(printerUuid: PrinterUuid, path: string, enabled = true) {
  const api = useApiClient()

  return useInfiniteQuery(
    getFolderQueryKey(printerUuid, path),
    ({ pageParam = { limit: filesPerPage } }) => api.app.files.getPrinterFiles(printerUuid, pageParam, path),
    {
      refetchInterval: intervals.printerFilesPolling,
      enabled,
      getNextPageParam: (lastPage, allPages) => {
        const loaded = allPages.flatMap((page) => page.files).length
        if (loaded < lastPage.pager.total) {
          return { limit: filesPerPage, offset: lastPage.pager.offset + filesPerPage }
        }
        return undefined
      }
    }
  )
}

export function useAllPrinterFiles(printerUuid: PrinterUuid, enabled = true) {
  const api = useApiClient()

  return useQuery(
    ['printer-files', printerUuid],
    () => {
      return api.app.files.getPrinterFiles(printerUuid)
    },
    {
      refetchInterval: intervals.printerFilesPolling,
      keepPreviousData: true,
      enabled
    }
  )
}
