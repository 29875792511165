import { createContext, useContext } from 'react'

import type { ApiClient } from './apiClient'

export const ApiClientContext = createContext<ApiClient | null>(null)

export function useApiClient(): ApiClient {
  const apiClientContext = useContext(ApiClientContext)
  if (!apiClientContext) {
    throw new Error(
      'API Client is missing in React context, you probably forgot to wrap your component with <ApiClientContext.Provider>'
    )
  }
  return apiClientContext
}
