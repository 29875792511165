import { IAllowedFunctionalities, PrinterUuid } from '../api/types/printer'
import { IConnectState } from '../api/types/state'
import { useFeatureFlagsByUuid } from './useFeatureFlags'

// Temporary workaround for MK4 with Input Shaper (FW >= 5.0.0)
export function useLowMemory(connectState: IConnectState, printerUuid: PrinterUuid) {
  const { isEnabled } = useFeatureFlagsByUuid(printerUuid)
  const hasInputShaper = isEnabled(IAllowedFunctionalities.LOW_MEMORY)

  return hasInputShaper && (connectState === IConnectState.PRINTING || connectState === IConnectState.PAUSED)
}
