import i18next, { InitOptions } from 'i18next'
import ChainedBackend from 'i18next-chained-backend'
import HttpApi, { HttpBackendOptions } from 'i18next-http-backend'
import resourcesToBackend from 'i18next-resources-to-backend'

import { Language } from './interfaces/locale'

const isDevelopment = import.meta.env.DEV

/**
 * We'll transform the data to turn prusalator-specific plurals into a flat, i18next-compatible structure
 */
function parseResourceFile(data: string) {
  const sourceData = JSON.parse(data)
  return Object.entries(sourceData).reduce((acc: any, item: any) => {
    const [key, value] = item

    // Simple string value - business as usual
    if (typeof value === 'string') {
      acc[key] = value
      return acc
    }

    // Plural value - flatten it
    for (const pluralEntry of Object.entries(value)) {
      const [pluralKey, pluralValue] = pluralEntry
      acc[`${key}_${pluralKey}`] = pluralValue
    }

    return acc
  }, {})
}

export function initI18n(language: Language) {
  const options: InitOptions = {
    backend: {
      backends: [
        HttpApi,
        resourcesToBackend((lang, _namespace, callback) => {
          import(`../translations/prusalator/${lang}.json`)
            .then((resource) => callback(null, parseResourceFile(JSON.stringify(resource.default))))
            .catch((error) => callback(error, null))
        })
      ],
      backendOptions: [
        {
          loadPath: '/locale/{lng}',
          parse: parseResourceFile
        }
      ],
      cacheHitMode: 'refreshAndUpdateStore'
    },
    lng: language,
    debug: isDevelopment,
    interpolation: {
      prefix: '{',
      suffix: '}',
      escapeValue: false // react already protects against xss
    },
    nsSeparator: false,
    keySeparator: false,
    fallbackLng: 'en',
    returnEmptyString: false
  }

  return i18next.use(ChainedBackend).init<HttpBackendOptions>(options)
}

export function getInstance() {
  return i18next
}
