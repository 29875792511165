import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { useApiClient } from '../api/react'
import { IPrinterSimpleView } from '../api/types/printer'
import useServiceResponseHandler from './useServiceResponseHandler'

export function useAddPrinterService(onSuccess?: (printer: IPrinterSimpleView) => void) {
  const { displaySuccess } = useServiceResponseHandler()
  const { t } = useTranslation()
  const api = useApiClient()

  return useMutation(api.app.printers.addPrinter, {
    onSuccess: (result) => {
      onSuccess?.(result)

      displaySuccess(t('service.printer.added.title'), t('service.printer.added.body', { name: result.name }))
    }
  })
}
