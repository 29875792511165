import { Route, Routes } from 'react-router-dom'

import { Group } from '../components/printers/groups/group'
import { GroupsOverview } from '../components/printers/groups/overview'

export function GroupsPage() {
  return (
    <Routes>
      <Route path=":groupId" element={<Group />} />
      <Route index element={<GroupsOverview />} />
    </Routes>
  )
}
