import { PrinterUuid } from '../../../../api/types/printer'

export function getFolderUrl(path: string) {
  const p = encodeURIComponent(path.replace(/^\//, ''))
  return `?path=/${p}`
}

export function getTeamFolderUrl(id: number) {
  return `?team=${id}`
}

export function getPrinterFileUrl(printerUuid: PrinterUuid, teamId: number, storage?: string, hash?: string) {
  if (!hash || !storage) return '#'
  return `/printer/${printerUuid}/storage/teams/${teamId}/${storage}/files/${hash}`
}

export function getConnectFileUrl(teamId: number, hash?: string) {
  if (!hash) return '#'
  return `/storage/connect/teams/${teamId}/${hash}`
}

export function getFolderFromFilepath(path: string) {
  return path.split('/').slice(0, -1).join('/')
}
