import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useApiClient } from '../../api/react'
import { config } from '../../config'
import { ConfirmModal } from '../common/ConfirmModal'

export function FrontendVersionWatchdog() {
  const { t } = useTranslation()
  const api = useApiClient()
  const version = config.appVersion
  const { data } = useQuery(['/version.json'], () => api.general.version(), { refetchInterval: 60000 })
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [isIgnored, setIsIgnored] = useState(false)

  if (data && data?.version !== version && !showConfirmModal && !isIgnored) {
    setShowConfirmModal(true)
  }

  return (
    <>
      {showConfirmModal ? (
        <ConfirmModal
          title={t('version-watchdog.new-version-available', 'There is a new Prusa Connect version available')}
          body={t(
            'version-watchdog.dialog-body',
            'Continuing to use the current version might cause the app to be unstable. Reload to get the latest features!'
          )}
          confirmBtnText={t('version-watchdog.reload', 'Reload app')}
          onCancel={() => {
            setShowConfirmModal(false)
            setIsIgnored(true)
          }}
          onConfirm={() => window.location.reload()}
        />
      ) : null}
    </>
  )
}
