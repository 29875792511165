import { CSSProperties, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Transition, TransitionStatus } from 'react-transition-group'
import styled from 'styled-components'

import { getEnvironment, Globals } from '../../helpers/getEnvironment'
import { useGroupFilterData } from '../../hooks/useGroupFilterData'
import { useIsAdmin } from '../../hooks/useLoggedUser'
import { usePrintersCount } from '../../hooks/usePrintersCount'
import { usePrintersUrl } from '../../hooks/usePrintersUrl'
import { useTeams } from '../../hooks/useTeams'
import { useNotificationsService } from '../../services/useNotificationsService'
import { BetaLabel } from '../common/Label'
import { SvgIcon } from '../common/SvgIcon'
import * as S from './Drawer.styled'
import { Hamburger } from './Hamburger'
import { getExternalLinks } from './HeaderMenu'

const transitionStyles: {
  [key in TransitionStatus]?: CSSProperties
} = {
  exiting: {
    transform: 'translateX(-100%)'
  },
  exited: {
    transform: 'translateX(-100%)'
  },
  entering: {
    transform: 'translateX(0%)'
  },
  entered: {
    transform: 'translateX(0%)'
  }
}

const defaultStyle = {
  transform: 'translateX(-100%)'
}

function getStyle(state: TransitionStatus) {
  return transitionStyles[state]
}

const NotificationsItemContent = styled.div`
  display: flex;
  justify-content: space-between;

  .notifications-count {
    background-color: var(--color-primary);
    border-radius: 2rem;
    padding: 0 0.4rem;
    color: white;
    text-align: center;
  }
`

function PrintersLink() {
  const { t } = useTranslation()
  const { data, isLoading } = usePrintersCount()
  const printersUrl = usePrintersUrl()

  // Wait for data
  if (!data || isLoading) {
    return null
  }

  const {
    pager: { total }
  } = data

  return <>{total > 1 && <S.ItemLink to={printersUrl}>{t('menu.printers')}</S.ItemLink>}</>
}

export function Drawer() {
  const { t } = useTranslation()
  const [visible, setIsVisible] = useState(false)
  const { unseenCount } = useNotificationsService()
  const displayUnseenCount = unseenCount > 99 ? '99+' : unseenCount
  const isAdmin = useIsAdmin()
  const { groupList } = useGroupFilterData()
  const teams = useTeams()
  const externalLinks = getExternalLinks(t)

  return (
    <>
      <Hamburger active={visible} onClick={() => setIsVisible((prev) => !prev)} />
      {visible && <S.Backdrop onClick={() => setIsVisible(false)} />}
      <Transition
        in={visible}
        timeout={S.TRANSITION_SPEED}
        unmountOnExit
        mountOnEnter
        // https://github.com/reactjs/react-transition-group/issues/223#issuecomment-334748429
        // triggers reflow & ensures correct opening
        onEnter={(node: HTMLElement) => node.offsetHeight}
      >
        {(state) => (
          <S.Drawer
            onClick={() => setIsVisible(false)}
            style={{
              ...defaultStyle,
              ...getStyle(state)
            }}
          >
            {!AFS_ENABLED && <PrintersLink />}
            {AFS_ENABLED && (
              <>
                <S.ItemLink to="/jobs">{t('menu.jobs', 'Jobs')}</S.ItemLink>
                <S.ItemLink to="/storage/connect">{t('menu.files', 'Files')}</S.ItemLink>
                <S.ItemLink to="/history">{t('menu.history', 'History')}</S.ItemLink>
              </>
            )}
            {!AFS_ENABLED && (
              <>
                <S.ItemLink to="/storage/connect">{t('menu.connect-files')}</S.ItemLink>

                {externalLinks.map((link, i) => (
                  <S.MenuLinkExternal key={i} href={link.href} target="_blank">
                    {link.label}
                  </S.MenuLinkExternal>
                ))}
              </>
            )}
            <S.ActionButton to="/add-printer">
              <SvgIcon size={14} icon="plusIcon" />
              {t('printer.add')}
            </S.ActionButton>
            {(teams.length > 1 || groupList.length > 0 || getEnvironment(Globals.ENVIRONMENT) === 'dev' || isAdmin) && (
              <S.Separator />
            )}
            {(teams.length > 1 || isAdmin) && (
              <S.ItemLink to="/teams">
                {t('menu.teams')}
                <BetaLabel />
              </S.ItemLink>
            )}
            {/* Filaments are hidden for now but the link should work */}
            {/* <S.ItemLink to="/filaments">{t('menu.filaments')}</S.ItemLink> */}
            {(groupList.length > 0 || isAdmin) && (
              <S.ItemLink to="/groups">
                {t('menu.groups')}
                <BetaLabel />
              </S.ItemLink>
            )}
            {(getEnvironment(Globals.ENVIRONMENT) === 'dev' || isAdmin) && (
              <S.ItemLink to="/commands/g-codes">{t('menu.commands')}</S.ItemLink>
            )}
            <S.Separator />
            <S.ItemLink to="/notifications">
              <NotificationsItemContent>
                <div className="description">{t('notifications.header', 'Notifications')}</div>
                {unseenCount > 0 && <div className="notifications-count">{displayUnseenCount}</div>}
              </NotificationsItemContent>
            </S.ItemLink>
            <S.ItemSubLink to="/notifications/settings">{t('notifications.settings')}</S.ItemSubLink>
          </S.Drawer>
        )}
      </Transition>
    </>
  )
}
