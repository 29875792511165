import styled from 'styled-components'

import { SvgIcon } from './SvgIcon'

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  transition: 0.5s ease;
  background-color: rgba(0, 0, 0, 0);
  .overlay-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

export const DragAndDropContainer = styled.div<{ $isDragging?: boolean }>`
  position: relative;
  ${({ $isDragging }) =>
    $isDragging &&
    `
    z-index: 1;
    .overlay {
      background-color: rgba(0, 0, 0, 0.5);
    }
  `}
`

export function DragAndDropOverlay() {
  return (
    <Overlay className="overlay">
      <SvgIcon icon="rowsActiveIcon" size={34} className="overlay-content" />
    </Overlay>
  )
}
