import { useEffect, useState } from 'react'

import { IPrinterDetailJobInfo } from '../../../api/types/job'
import { IConnectState } from '../../../api/types/state'
import { getCurrentUnixtimestamp } from '../../../helpers/timestamp'
import { useInterval } from '../../../helpers/useInterval'

export function useCurrentJob(state: IConnectState, jobInfo?: IPrinterDetailJobInfo) {
  const [timePrinting, setTimePrinting] = useState(jobInfo?.time_printing || 0)
  const [timeRemaining, setTimeRemaining] = useState(jobInfo?.time_remaining || 0)
  const [printStarted, setPrintStarted] = useState(getCurrentUnixtimestamp() - (jobInfo?.time_printing || 0))
  const [estimatedEnd, setEstimatedEnd] = useState(
    jobInfo?.time_remaining ? getCurrentUnixtimestamp() + (jobInfo?.time_remaining || 0) : 0
  )

  useInterval(() => {
    if (state === IConnectState.PRINTING) {
      setTimePrinting(timePrinting + 1)
      if (timeRemaining > 0) {
        setTimeRemaining(timeRemaining - 1)
      }
    }
  }, 1000)

  useEffect(() => {
    setTimePrinting(jobInfo?.time_printing || 0)
    setPrintStarted(getCurrentUnixtimestamp() - (jobInfo?.time_printing || 0))
  }, [jobInfo?.time_printing])

  useEffect(() => {
    setTimeRemaining(jobInfo?.time_remaining || 0)
    setEstimatedEnd(jobInfo?.time_remaining ? getCurrentUnixtimestamp() + (jobInfo?.time_remaining || 0) : 0)
  }, [jobInfo?.time_remaining])

  return {
    timePrinting,
    timeRemaining,
    printStarted,
    estimatedEnd
  }
}
