import styled, { css } from 'styled-components'

const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
`

const SwitchWrapper = styled.div<{ $position: string }>`
  padding: 0.2rem;
  border-radius: 2rem;
  width: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.$position};
  transition: all 0.3s ease;
  align-content: center;
  ${(props) => {
    if (props.$position === 'left') {
      return css`
        background: var(--neutral-200);
      `
    }

    if (props.$position === 'center') {
      return css`
        background: var(--color-primary);
      `
    }

    return css`
      background: var(--neutral-300);
    `
  }}
`

const SwitchHandle = styled.div`
  background: white;
  width: 1rem;
  height: 1rem;
  border-radius: 2rem;
`

export type TogglePosition = 'left' | 'center' | 'right'

type Props = {
  position: TogglePosition
  label: string
  onClick?: () => void
}

export function ThreeWaySwitch({ position = 'left', label, onClick }: Props) {
  return (
    <Button onClick={onClick}>
      <SwitchWrapper $position={position}>
        <SwitchHandle />
      </SwitchWrapper>
      <span>{label}</span>
    </Button>
  )
}
