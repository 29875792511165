import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import Markdown from 'markdown-to-jsx'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { useTheme } from 'styled-components'

import { useApiClient } from '../api/react'
import { IInvitationErrorResponse, IRights, ITeamsInvitation } from '../api/types/team'
import { getUserName } from '../api/types/user'
import { Button } from '../components/common/Button'
import { PaceProgress, shouldShowTabularPace } from '../components/common/PaceProgress'
import { SvgIcon } from '../components/common/SvgIcon'
import { useAuthState } from '../context/authStore'
import { useUserResolver } from '../Contexts'
import { useErrorHandler } from '../hooks/errors/useErrorHandler'
import { useRedirectUrl } from '../hooks/useRedirectUrl'
import { useTeamName } from '../hooks/useTeamName'
import { InvitationAcceptedPage } from './InvitationAcceptedPage'
import { InvitationDeclinedPage } from './InvitationDeclinedPage'
import { InvitationExpiredPage } from './InvitationExpiredPage'
import { InvitationLeavingPage } from './InvitationLeavingPage'
import { InvitationInvalid } from './InvitationPage'
import { InvitationWelcomePage } from './InvitationWelcomePage'

type RouteInfo = {
  code?: string
}

const rights = {
  rights_ro: 'READ',
  rights_use: 'USE',
  rights_rw: 'WRITE'
}

export function InvitationDetailPage() {
  const api = useApiClient()
  const params = useParams<RouteInfo>()
  const { t } = useTranslation()
  const theme = useTheme()
  const errorHandler = useErrorHandler()
  const [confirmIsShown, setConfirmIsShown] = useState(false)
  const [declineIsShown, setDeclineIsShown] = useState(false)
  const { getTeamName } = useTeamName()
  const { refetchUserData } = useUserResolver()
  const queryClient = useQueryClient()
  const { isAuthenticated } = useAuthState()
  const { setRedirectUrl } = useRedirectUrl()
  const navigate = useNavigate()

  const queryResult = useQuery(
    [`/users/invitation/${params.code}`],
    () => api.app.teams.getUserInvitation(params.code || ''),
    { refetchOnWindowFocus: false }
  )

  useEffect(() => {
    setDeclineIsShown(false)
    setConfirmIsShown(false)
  }, [params.code])

  const { mutate: acceptInvitation } = useMutation(() => api.app.teams.acceptInvitationToTeam(params.code || ''), {
    onSuccess: () => {
      refetchUserData()
      setConfirmIsShown(true)
      queryClient.invalidateQueries({ queryKey: ['/printers-to-megamenu'] })
    },
    onError: (error) => {
      errorHandler(error)
    }
  })

  const { mutate: declineInvitation } = useMutation(() => api.app.teams.declineInvitationToTeam(params.code || ''), {
    onSuccess: () => {
      setDeclineIsShown(true)
    },
    onError: (error) => {
      errorHandler(error)
    }
  })

  if (!queryResult.data && queryResult.error) {
    const result = queryResult as IInvitationErrorResponse
    const { code, invitor, team_name } = result.error

    switch (code) {
      case 'INVITATION_ALREADY_ACCEPTED':
        return <InvitationAcceptedPage username={invitor} team={team_name} />
      case 'INVITATION_ALREADY_REFUSED':
        return <InvitationDeclinedPage username={invitor} team={team_name} />
      case 'INVITATION_EXPIRED':
        return <InvitationExpiredPage username={invitor} team={team_name} />
      default:
        return <InvitationInvalid />
    }
  }

  if (declineIsShown) {
    return <InvitationLeavingPage />
  }

  if (!queryResult.data || shouldShowTabularPace(queryResult)) {
    return <PaceProgress />
  }

  const { invitation } = queryResult.data
  const username = getUserName(invitation.user)
  const team = getTeamName(invitation.team)

  if (confirmIsShown) {
    return <InvitationWelcomePage team={team} />
  }

  const permissionsList = Object.keys(invitation)
    .filter((entry) => Object.keys(rights).includes(entry) && invitation[entry as keyof ITeamsInvitation] === true)
    .map((entry) => rights[entry as keyof IRights])

  const permissions = permissionsList.reduce((acc, current, index) => {
    if (permissionsList.length === 1 || index === 0) {
      return current
    }

    return acc + (index + 1 === permissionsList.length ? ` ${t('and')} ` : ', ') + current
  }, '')

  const login = () => {
    const { pathname, search } = window.location
    const redirectUrl = `${pathname}${search}`
    setRedirectUrl(redirectUrl)
    navigate('/login', { replace: true })
  }

  const accept = (): void => {
    if (!isAuthenticated) {
      login()
    } else {
      acceptInvitation()
    }
  }

  const decline = (): void => {
    if (!isAuthenticated) {
      login()
    } else {
      declineInvitation()
    }
  }

  return (
    <div className="text-center mt-5">
      <h1 className="h4 font-weight-light">{t('team.invitation.heading')}</h1>
      <h3 className="font-weight-normal lead text-lg mx-2 mt-4">
        <Markdown>{t('team.invitation.hello-invite', { user: username, team })}</Markdown>
      </h3>
      <h3 className="font-weight-normal text-lg mt-2 mb-3">{t('team.invitation.permissions', { permissions })}</h3>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <Button type="button" onClick={decline} className="mb-3">
              <SvgIcon icon="nokIcon" size="18" fill={theme.colors.icons.primary} />
              {t('team.invitation.decline')}
            </Button>
          </div>
          <div className="col-md-6">
            <Button type="button" onClick={accept}>
              <SvgIcon icon="okIcon" size="18" />
              {t('team.invitation.accept')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
