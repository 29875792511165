import { lazy, ReactNode, useEffect } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router'
import styled, { css } from 'styled-components'

import { usePermissions } from '../../../context/permissionsStore'
import { usePrinter } from '../../../context/printerContext'
import { getEnvironment, Globals } from '../../../helpers/getEnvironment'
import { useIsAdmin } from '../../../hooks/useLoggedUser'
import { useIsSla } from '../../../hooks/usePrinterType'
import { Container } from '../../bootstrap/Container'
import ScrollContainer from '../../common/ScrollContainer'
import { Suspense } from '../../common/Suspense'
import { InlineBlock, ScrollableTabs, Tab, Tabs } from '../../common/Tabs'
import { WithTooltip } from '../../common/WithTooltip'
import { DocumentTitle } from '../../header/DocumentTitle'
import { usePrinterNavigation } from '../../printerNavigation'
import { getPrinterName } from '../utils'
import { Meatballs } from './Meatballs'

const FileDetailPage = lazy(() => import('../storage/FileDetailPage'))
const JobsPage = lazy(() => import('./JobsPage'))
const JobDetailPage = lazy(() => import('./JobDetailPage'))
const TransfersDetailPage = lazy(() => import('./TransfersDetailPage'))
const PlannedJobDetailPage = lazy(() => import('./PlannedJobDetailPage'))
const CommandPage = lazy(() => import('./CommandPage'))
const ControlPage = lazy(() => import('./ControlPage'))
const SlaControlPage = lazy(() => import('./SlaControlPage'))
const DashboardPage = lazy(() => import('./DashboardPage'))
const CamerasPage = lazy(() => import('./CamerasPage'))
const FilamentPage = lazy(() => import('./FilamentPage'))
const LogsPage = lazy(() => import('./LogsPage'))
const FilesPage = lazy(() => import('./FilesPage'))
const TransfersPage = lazy(() => import('./TransfersPage'))
const QueuePage = lazy(() => import('./QueuePage'))
const SettingsPage = lazy(() => import('./SettingsPage'))
const StatisticsPage = lazy(() => import('./StatisticsPage'))
const TelemetryTab = lazy(() => import('./TelemetryTab'))

const FadeIn = styled.div`
  margin-bottom: 3rem; /* due to MobileFooter, Pager and Footer */
`

const StyledContainer = styled(Container)`
  position: relative;
`
const Inline = styled.span<{ $active?: boolean }>`
  position: relative;
  ${(props) =>
    props.$active &&
    css`
      &::after {
        position: absolute;
        left: 0;
        bottom: -0.7rem;
        width: 100%;
        border-bottom: 2px solid var(--color-primary);
        content: '';
      }
      border-bottom: 2px solid transparent;
    `}
`

export function PageContent({ children }: { children: ReactNode }) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <Suspense>
      <FadeIn className="fade-in">{children}</FadeIn>
    </Suspense>
  )
}

function useRoutes() {
  const {
    printer: { printer_type }
  } = usePrinter()
  const isSla = useIsSla(printer_type)

  return [
    {
      path: 'dashboard',
      component: <DashboardPage />
    },
    {
      path: 'queue/:jobId',
      component: <PlannedJobDetailPage />
    },
    {
      path: 'queue',
      component: <QueuePage />
    },
    {
      path: 'jobs/:jobId',
      component: <JobDetailPage />
    },
    {
      path: 'jobs',
      component: <JobsPage />
    },
    {
      path: 'storage/teams/:teamId/:storage/files/:hash',
      component: <FileDetailPage />
    },
    {
      path: 'storage/*',
      component: <FilesPage />
    },
    {
      path: 'transfers/:transferId',
      component: <TransfersDetailPage />
    },
    {
      path: 'transfers',
      component: <TransfersPage />
    },
    isSla
      ? {
          path: 'control',
          component: <SlaControlPage />
        }
      : {
          path: 'control',
          component: <ControlPage />
        },
    {
      path: 'cameras/*',
      component: <CamerasPage />
    },
    {
      path: 'command',
      component: <CommandPage />
    },
    {
      path: 'logs',
      component: <LogsPage />
    },
    {
      path: 'filament/*',
      component: <FilamentPage />
    },
    {
      path: 'settings',
      component: <SettingsPage />
    },
    {
      path: 'statistics',
      component: <StatisticsPage />
    },
    {
      path: 'telemetry',
      component: <TelemetryTab />
    }
  ]
}

export function PrinterTabs() {
  const isAdmin = useIsAdmin()
  const { printer } = usePrinter()
  const { uuid } = printer
  const permissions = usePermissions(printer.team_id)
  const printerRoutes = usePrinterNavigation(printer, permissions)
  const { pathname } = useLocation()
  const disabledPrinterNavItems = printerRoutes.filter((item) => 'disabled' in item && item.disabled)
  const routes = useRoutes()
  const activeTab = printerRoutes.find((item) => pathname.startsWith(`/printer/${uuid}/${item.to}`))

  const printerName = getPrinterName(printer)
  const progress = printer?.job_info?.progress !== undefined ? `${printer.job_info.progress}% ` : ''
  const documentTitle = `${progress}${printerName} ${activeTab?.title?.toLowerCase() ?? ''}`

  useEffect(() => {
    // This is way how to change document title, when tab is inactive, DOM is not changing in inactive tab
    document.title = `${documentTitle} - Prusa Connect`
  }, [documentTitle])

  return (
    <>
      {printer?.name && activeTab?.title && <DocumentTitle content={documentTitle} />}
      <StyledContainer>
        <ScrollableTabs textAlign="left">
          <ScrollContainer background="var(--background-body)" noPadding>
            <InlineBlock>
              <Tabs>
                {printerRoutes.map((item, i) => {
                  const url = `/printer/${uuid}/${item.to}`
                  if (item.disabled) {
                    return (
                      <WithTooltip
                        key={item.title}
                        id={`disabled-tooltip-${i}`}
                        title={item.disabledTooltip}
                        placement="top"
                      >
                        <span>
                          <Tab to="#" $disabled>
                            {item.title}
                          </Tab>
                        </span>
                      </WithTooltip>
                    )
                  }
                  return (
                    <Tab key={item.title} to={url} style={{ ...(i === 0 && { paddingLeft: '0' }) }}>
                      <Inline $active={activeTab === item}>{item.title}</Inline>
                    </Tab>
                  )
                })}
              </Tabs>
            </InlineBlock>
          </ScrollContainer>
        </ScrollableTabs>
        {(getEnvironment(Globals.ENVIRONMENT) === 'dev' || isAdmin) && <Meatballs />}
      </StyledContainer>

      <Routes>
        {routes
          .filter((route) =>
            disabledPrinterNavItems.length
              ? !disabledPrinterNavItems.some((item) => 'to' in item && route.path.includes(item.to))
              : route
          )
          .map((route, i) => (
            <Route key={i} path={route.path} element={<PageContent>{route.component}</PageContent>} />
          ))}
        <Route path="*" element={<Navigate replace to={printerRoutes.filter((item) => !item.disabled)[0].to} />} />
      </Routes>
    </>
  )
}

export default PrinterTabs
