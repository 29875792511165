import { useTranslation } from 'react-i18next'

import { usePrinter } from '../../../context/printerContext'
import { useLoggedUserPreferences } from '../../../hooks/useLoggedUser'
import { Temperature } from '../../common/Temperature'
import * as S from './ControlPanel.styled'

export const MultitoolStatus = () => {
  const { t } = useTranslation()
  const { printer } = usePrinter()
  const units = useLoggedUserPreferences('units')

  return (
    <S.Section>
      {printer.slot
        ? Object.values(printer.slot.slots).map((slot, index) => (
            <S.Param key={index}>
              <span>
                {t('printer.params.tool', 'Tool')} {index + 1}
              </span>
              <Temperature valueA={slot.temp} unit={units.temp} />

              {slot.material}
            </S.Param>
          ))
        : null}
    </S.Section>
  )
}
