import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { convertDistance, DistanceUnits, inch, mm, THINSP } from '../../helpers/formatters'
import { isNumber } from '../../helpers/std'
import { useLoggedUserPreferences } from '../../hooks/useLoggedUser'

const Span = styled.span<{ noFixedWidth?: boolean }>`
  min-width: ${(props) => (props.noFixedWidth ? '0' : '9ch')};
  display: inline-block;
`

type Props = {
  value: number
  na?: boolean
  noFixedWidth?: boolean
}

export function Distance({ value, na = false, noFixedWidth = false }: Props) {
  const { t } = useTranslation()
  const units = useLoggedUserPreferences('units')

  const inches = `${convertDistance(value, DistanceUnits.INCH)}${THINSP}${inch}`
  const milimeters = `${convertDistance(value, DistanceUnits.MM)}${THINSP}${mm}`

  if (isNumber(value)) {
    return (
      <Span
        title={units.dimensions === DistanceUnits.MM ? inches : milimeters}
        className="nbsp"
        noFixedWidth={noFixedWidth}
      >
        {units.dimensions === DistanceUnits.MM ? milimeters : inches}
      </Span>
    )
  }

  if (na) {
    return <span className="nbsp">{t('not-available')}</span>
  }

  return null
}
