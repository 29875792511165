import classNames from 'classnames'
import { AnchorHTMLAttributes } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { CommonButtonProps, getButtonStyle, Size } from './Button'
import { WithTooltip } from './WithTooltip'

type Props = CommonButtonProps<HTMLAnchorElement> & {
  to: string
}

type StyledProps = { $size?: Size; $block?: boolean }

const StyledLink = styled(Link)<StyledProps>`
  ${(props) => getButtonStyle(props.$size, props.$block)}
`

const StyledAnchor = styled.a<StyledProps>`
  ${(props) => getButtonStyle(props.$size, props.$block)}
`

type AnchorProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  href?: string
  disabled?: boolean
  disabledTooltip?: string
}

export function ButtonAnchor(props: AnchorProps & StyledProps) {
  if (props.disabled) {
    return (
      <WithTooltip id="download-tooltip" title={props.disabledTooltip} placement="top">
        <span style={{ cursor: 'not-allowed', display: 'inherit' }}>
          <StyledAnchor
            style={{ pointerEvents: 'none' }}
            className={classNames(props.className, {
              disabled: props.disabled
            })}
            {...props}
          />
        </span>
      </WithTooltip>
    )
  }

  return <StyledAnchor {...props} />
}

export function ButtonLink(props: Props) {
  const { to, size, block, className, ...buttonProps } = props
  return (
    <StyledLink
      $size={size}
      $block={block}
      onClick={buttonProps.disabled ? (e) => e.preventDefault() : undefined}
      to={to}
      className={classNames('connect-button', className, {
        disabled: buttonProps.disabled
      })}
      {...buttonProps}
    />
  )
}
