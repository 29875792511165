import { useBreakpoint } from './useBreakpoint'

export function useInitAppziFeedback() {
  const isSmOrLarge = useBreakpoint('sm')
  const isMobile = !isSmOrLarge

  if (!isMobile) {
    const script = document.createElement('script')
    document.head.appendChild(script)
    script.src = 'https://w.appzi.io/w.js?token=xlpiB'
  }
}
