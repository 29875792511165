import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const Wrapper = styled.div<{
  disabled?: boolean
}>`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.4;
    pointer-events: none;
  `}
`

const Slider = styled.span<{
  toggle?: boolean
  $isLoading?: boolean
}>`
  position: absolute;
  cursor: ${(props) => (props.$isLoading ? 'wait' : 'pointer')};
  top: 0;
  left: 1px;
  right: 0;
  bottom: 0;
  background-color: #d5d5d5;
  transition: 0.4s;
  border-radius: 22px;

  ${({ toggle }) =>
    toggle
      ? `
    background-color: #797979;

    &:before {
      position: absolute;
      content: '';
      height: 16px;
      width: 16px;
      left: 20px;
      bottom: 3px;
      background-color: white;
      transition: 0.2s;
      border-radius: 50%;
    }  
  `
      : `
    &:before {
      position: absolute;
      content: '';
      height: 16px;
      width: 16px;
      left: 3px;
      bottom: 3px;
      background-color: white;
      transition: 0.2s;
      border-radius: 50%;
    }
  `}
`

const StyledInput = styled.div<{
  isChecked?: boolean
}>`
  opacity: 0;
  width: 0;
  height: 0;

  ${({ isChecked }) =>
    isChecked &&
    `
    ${Slider} {
      background-color: #2196f3;
    }

    ${Slider}:before {
      transform: translateX(26px);
    }

    &:focus {
      ${Slider} {
        box-shadow: 0 0 1px #2196f3;
      }
    }
  `}
`

type IProps = {
  id?: string
  onClick?: (e: any) => void
  enabled?: boolean
  title?: string
  isLoading?: boolean
  disabled?: boolean
  showStates?: boolean
}

export const SwitchButton = memo(({ id, onClick, enabled, disabled, title, isLoading, showStates }: IProps) => {
  const { t } = useTranslation()

  return (
    <div className="d-flex">
      <Wrapper onClick={onClick} id={id} title={title} disabled={disabled}>
        <StyledInput isChecked={enabled} />
        <Slider toggle={enabled} $isLoading={isLoading} />
      </Wrapper>
      {showStates && (
        <div className="ml-2">{enabled ? t('switch-button.on', 'On') : t('switch-button.off', 'Off')}</div>
      )}
    </div>
  )
})
