import { TFunction } from 'i18next'
import { memo, ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { components } from 'react-select'
import Creatable from 'react-select/creatable'

import { IVector3 } from '../../../api/types/vector'
import { THINSP, toMilimeters } from '../../../helpers/formatters'
import { useLoggedUserPreferences } from '../../../hooks/useLoggedUser'
import { Axis } from '../../common/Axis'
import { useStyles } from '../filters/styles'

export type MoveValue = Partial<IVector3> & {
  absolute?: boolean
}

enum AxisEnum {
  X = 'x',
  Y = 'y',
  Z = 'z'
}

type Axis = AxisEnum | `${AxisEnum}`

type Option = {
  label: ReactElement
  value: MoveValue
  __isNew__?: boolean // added internally by <Creatable /> to a new value
}

type OptionGroup = {
  label: string
  options: Option[]
}

function getOption(t: TFunction, axis: Axis): OptionGroup[] {
  const steps = [1, 10, 100] // in milimeters
  const options = [
    {
      label: t('printer.control.move.home'),
      options: [
        {
          label: <Axis value={0} decimals={0} />,
          value: { [axis]: 0, absolute: true }
        }
      ]
    },
    {
      label: axis === AxisEnum.X ? '→' : '↑',
      options: steps.map((step) => ({ label: <Axis value={step} decimals={2} />, value: { [axis]: step } }))
    },
    {
      label: axis === AxisEnum.X ? '←' : '↓',
      options: steps.map((step) => ({ label: <Axis value={-step} decimals={2} />, value: { [axis]: -step } }))
    }
  ]

  return options
}

type Props = {
  isDisabled?: boolean
  axis: Axis
  placeholder: React.ReactNode
  onChange: (value: MoveValue) => void
}

// wrapped with memo and needs stable onChange fn otherwise the open dropdown won't work properly
export const MoveDropdown = memo((props: Props) => {
  const { axis, isDisabled, placeholder, onChange } = props
  const { t } = useTranslation()
  const [value, setValue] = useState<Option | null>(null)

  const units = useLoggedUserPreferences('units')

  const options = getOption(t, axis)

  // disabled is also the loading state
  // Clear input when the loading is finished
  useEffect(() => {
    if (!isDisabled) {
      setValue(null)
    }
  }, [isDisabled])

  return (
    <Creatable<Option>
      value={value}
      isDisabled={isDisabled}
      isClearable
      backspaceRemovesValue
      placeholder={placeholder}
      formatCreateLabel={(distance) =>
        t('printer.control.move.set-position-to', { position: `${distance}${THINSP}${units.dimensions}` })
      }
      options={options}
      menuPlacement="auto"
      styles={useStyles<Option>()}
      onChange={(selected) => {
        if (selected) {
          // react-select internally adds __isNew__
          // eslint-disable-next-line no-underscore-dangle
          if (selected.__isNew__) {
            onChange({
              [axis]: toMilimeters(Number(selected.value)).toFixed(0),
              absolute: true
            })
          } else {
            onChange(selected.value)
            setValue(selected)
          }
        } else {
          setValue(null)
        }
      }}
      components={{
        // eslint-disable-next-line react/no-unstable-nested-components
        Input: (props) => (
          <components.Input
            {...props}
            onChange={(e) => {
              // allow only numbers
              const { value } = e.currentTarget
              const n = value.replace(/\D/g, '')
              e.currentTarget.value = n
              props.onChange?.(e)
            }}
          />
        )
      }}
    />
  )
})
