import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { useApiClient } from '../../../api/react'
import useServiceResponseHandler from '../../../services/useServiceResponseHandler'
import { Button } from '../../common/Button'
import { SvgIcon } from '../../common/SvgIcon'

type Props = {
  id: number
  name: string
  description: string
  teamId: number
  isEditable: boolean
  changeEditable: (e: boolean) => void
  discardChanges: () => void
  update: () => void
}

export function EditGroupButton(props: Props) {
  const { t } = useTranslation()
  const { displaySuccess } = useServiceResponseHandler()
  const api = useApiClient()

  const { mutate: saveChanges } = useMutation(
    () =>
      api.app.printerGroups.editGroup(props.id, {
        name: props.name,
        description: props.description,
        team_id: props.teamId
      }),
    {
      onSuccess: () => {
        props.changeEditable(!props.isEditable)
        displaySuccess(
          t('service.printer.group.edited.title'),
          t('service.printer.group.edited.body', { name: props.name })
        )
        props.update()
      },
      onError: () => {
        props.discardChanges()
      }
    }
  )

  const changeEditableMode = () => {
    props.changeEditable(!props.isEditable)
  }

  return (
    <>
      <Button
        size="sm"
        onClick={() => {
          if (props.isEditable) {
            props.discardChanges()
          }
          changeEditableMode()
        }}
      >
        <SvgIcon icon={props.isEditable ? 'cancelIcon' : 'editIcon'} size={16} />
        {props.isEditable ? t('button.cancel') : t('button.edit')}
      </Button>
      {props.isEditable && (
        <Button size="sm" onClick={() => saveChanges()}>
          <SvgIcon icon="saveIcon" size="16" />
          {t('button.save')}
        </Button>
      )}
    </>
  )
}
