import { convert as convertTemp, TemperatureUnits } from '../components/common/Temperature'

export const THINSP = '\u2009'

export enum DistanceUnits {
  MM = 'mm',
  INCH = 'in'
}

export const mm = 'mm'
export const meter = 'm'
export const inch = 'in' // ″

export const toInches = (mm: number): number => mm / 25.4
export const toMilimeters = (inches: number): number => inches * 25.4

export function convertDistance(value: number, sign: DistanceUnits, decimals?: number) {
  if (sign === DistanceUnits.INCH) {
    return toInches(value).toFixed(decimals || decimals === 0 ? decimals : 4)
  }
  return value
}

const formatNumberDefaults = {
  fractionDigits: 2,
  optionalDecimals: true
}

export function formatNumber(value: number, locale: string, options: Partial<typeof formatNumberDefaults> = {}) {
  const opt = { ...formatNumberDefaults, ...options }
  const formatted = value.toFixed(opt.fractionDigits)

  if (opt.optionalDecimals) {
    return Number(formatted).toLocaleString(locale)
  }
  return Number(formatted).toLocaleString(locale)
}

const formatPercentsDefaults = { fractionDigits: 2, optionalDecimals: true }

export function formatPercents(value: number, options: Partial<typeof formatPercentsDefaults> = {}) {
  const opt = { ...formatPercentsDefaults, ...options }
  let formatted = value.toFixed(opt.fractionDigits)

  const isNan = Number.isNaN(Number(formatted))
  formatted = isNan ? '100' : formatted

  const isInfinite = !Number.isFinite(Number(formatted))
  formatted = isInfinite ? '100' : formatted

  if (opt.optionalDecimals) {
    return `${Number(formatted)}${THINSP}%` // removes trailing zeroes
  }
  return `${formatted}${THINSP}%`
}

export function formatTemperature(sign: TemperatureUnits, value?: number) {
  if (value === undefined) {
    return ''
  }

  return `${convertTemp(value, sign).toFixed(0)}${THINSP}${sign}`
}

export function formatLength(
  unit: DistanceUnits,
  locale: string,
  value?: number,
  toMeters?: boolean,
  decimals?: number
) {
  if (value === undefined) {
    return ''
  }

  const inches = convertDistance(value, DistanceUnits.INCH, decimals)
  const milimeters = convertDistance(value, DistanceUnits.MM, decimals)

  let length = unit === DistanceUnits.INCH ? inches : milimeters
  length = toMeters ? Number(length) / 1000 : length
  let sign = unit === DistanceUnits.INCH ? inch : mm
  if (toMeters) {
    sign = unit === DistanceUnits.INCH ? inch : meter // TODO foot? ... FilamentInfo
  }

  return `${length.toLocaleString(locale)}${THINSP}${sign}`
}

export function formatVolume(unit: DistanceUnits, locale: string, value?: number) {
  if (value === undefined) {
    return ''
  }

  if (unit === DistanceUnits.INCH) {
    const formatted = formatNumber(value / 16387.064, locale, { fractionDigits: 3 })
    return `${formatted}${THINSP}in³`
  }

  const formatted = formatNumber(value, locale, { fractionDigits: 3 })
  return `${formatted}${THINSP}mm³`
}

// https://stackoverflow.com/a/39906526
// https://wiki.ubuntu.com/UnitsPolicy
export function formatSize(size: number, base = 1_000) {
  const units = base !== 1024 ? ['b', 'kB', 'MB', 'GB', 'TB'] : ['b', 'KiB', 'MiB', 'GiB', 'TiB']

  let l = 0
  let n = size

  while (n >= base && ++l) {
    n /= base
  }

  if (n < 0) {
    return Math.abs(n).toFixed(n < 10 && l > 0 ? 1 : 0) + THINSP + units[l]
  }

  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + THINSP + units[l]
}
