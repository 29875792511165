import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { IGcodeMetadata } from '../api/types/metadata'
import { extractGcodeMetadata, extractSlMetadata } from '../helpers/metadata'
import { useLoggedUserPreferences } from '../hooks/useLoggedUser'
import { Label, SectionTitle, Value } from './common/Parameters'

type Props = {
  metadata: IGcodeMetadata
  isSla: boolean
}

export function GcodeMetadataParameters(props: Props) {
  const { metadata, isSla } = props
  const { t, i18n } = useTranslation()
  const units = useLoggedUserPreferences('units')

  const sections = isSla
    ? extractSlMetadata(t, i18n.language, metadata)
    : extractGcodeMetadata(t, i18n.language, metadata, units)
  return (
    <>
      {sections.map((section) => {
        if (section.params.length === 0) {
          return null
        }
        return (
          <Fragment key={section.label}>
            <SectionTitle>{section.label}</SectionTitle>
            {section.params.map((param, i) => (
              <Fragment key={i}>
                <Label>{param.label}</Label>
                <Value>{param.value}</Value>
              </Fragment>
            ))}
          </Fragment>
        )
      })}
    </>
  )
}
