import { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router'
import styled from 'styled-components'

import { IGroupDetail, IGroupPattern } from '../../../api/types/groups'
import { usePrintersStateContext } from '../../../context/printersStateContext'
import { SEPARATOR } from '../../../helpers/sorting'
import { useGroupFilterData } from '../../../hooks/useGroupFilterData'
import { useQueryParams } from '../../../hooks/useQueryParams'
import { useTeams } from '../../../hooks/useTeams'
import { Icon } from '../../../icons'
import { PrintersViewEnum } from '../../../interfaces/view'
import { Row } from '../../bootstrap/Row'
import { AddNewPrinterButton } from '../../common/AddNewPrinterButton'
import { DocumentTitle } from '../../header/DocumentTitle'
import { Group as SubmenuGroup, GroupTitle, ViewItem } from '../../helpers/submenu'
import { Filters } from '../Filters'
import { DEFAULT_SORT, ISortCol } from '../filters/Sorting'
import { useFilterHandlers } from '../hooks/useFilterHandlers'
import { usePrintersQueryState } from '../hooks/usePrintersQueryState'
import { SortBy } from '../SortBy'
import { CardsView } from './CardsView'
import { GridView } from './GridView'
import { ListView } from './ListView'
import { QueueView } from './QueueView'

const FilterRow = styled(Container)`
  background: var(--background-body);
  padding: 1.5em 0;
`

const Group = styled(SubmenuGroup)`
  ${GroupTitle} {
    padding: 0;
  }
`

const initialViews = [PrintersViewEnum.TABLE, PrintersViewEnum.CARDS, PrintersViewEnum.QUEUE]

function getViewIcon(view: PrintersViewEnum, group?: IGroupDetail): Icon {
  switch (view) {
    case PrintersViewEnum.TABLE:
      return 'rowsIcon'
    case PrintersViewEnum.GRID:
      if (group?.size?.pattern === IGroupPattern.SHIFTED) {
        return 'honeycombIcon'
      }
      return 'gridIcon'

    case PrintersViewEnum.QUEUE:
      return 'queueIcon'
    case PrintersViewEnum.CARDS:
      return 'cardsViewIcon'
  }
}

export function ViewIcons() {
  const location = useLocation()
  const { filter, group, setView } = usePrintersStateContext()
  const { groupId } = usePrintersQueryState()
  const supportsGridView = !!filter.groupId && groupId
  const views = supportsGridView ? [...initialViews, PrintersViewEnum.GRID] : initialViews

  const baseUrl = '/printers'

  return (
    <>
      {views.map((view) => (
        <ViewItem
          key={view}
          to={`${baseUrl}/${view}${location.search}`}
          onClick={() => setView(view)}
          icon={getViewIcon(view, group)}
          active={location.pathname === `${baseUrl}/${view}`}
        />
      ))}
    </>
  )
}

export function PrintersOverview() {
  const params = useParams<{ view: string }>()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { reset } = useFilterHandlers()
  const { filter } = usePrintersStateContext()
  const { groupId } = usePrintersQueryState()
  const supportsGridView = !!filter.groupId && groupId
  const queryParams = useQueryParams({ decode: false }) // custom decoding of sort_by param

  const teams = useTeams()
  const { groupList, isLoading } = useGroupFilterData()

  useEffect(() => {
    if (!queryParams.sort_by) {
      const delimiter = window.location.search.indexOf('?') === -1 ? '?' : '&'
      navigate(
        {
          search: `${window.location.search}${delimiter}sort_by=+${ISortCol.STATE}${encodeURIComponent(
            SEPARATOR
          )}${DEFAULT_SORT}`
        },
        { replace: true }
      )
    }
  }, [])

  useEffect(() => {
    // grid view only for groups
    if (!supportsGridView && params.view === PrintersViewEnum.GRID) {
      navigate(
        `/printers/${PrintersViewEnum.TABLE}?sort_by=+${ISortCol.STATE}${encodeURIComponent(SEPARATOR)}${DEFAULT_SORT}`
      )
    }
  }, [supportsGridView, params.view, navigate])

  useEffect(() => {
    const isNonexistentGroup =
      !isLoading && filter.groupId && !groupList.some((memberGroup) => memberGroup.id === filter.groupId)
    const isNonexistentTeam = filter.teamId && !teams.some((team) => team.id === filter.teamId)

    if (isNonexistentGroup || isNonexistentTeam) {
      reset()
    }
  }, [groupList, teams, isLoading, filter, navigate, reset])

  const renderContent = () => {
    if (params.view === PrintersViewEnum.QUEUE) {
      return <QueueView />
    }
    if (params.view === PrintersViewEnum.GRID) {
      return <GridView />
    }
    if (params.view === PrintersViewEnum.CARDS) {
      return <CardsView />
    }
    return <ListView />
  }

  return (
    <>
      <DocumentTitle content={t('menu.printers')} />
      <FilterRow>
        <Row noGutters className="justify-content-between align-items-center">
          <div className="d-flex flex-grow-1 flex-wrap gap-2">
            <Group title={t('groups.printer.view')}>
              <ViewIcons />
            </Group>

            <Group title={t('groups.printer.filters')}>
              <Filters />
            </Group>

            <Group title={t('groups.printer.sort-by')} className="flex-wrap">
              <SortBy />
              <AddNewPrinterButton />
            </Group>
          </div>
        </Row>
      </FilterRow>
      {renderContent()}
    </>
  )
}
