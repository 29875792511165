import { faChevronLeft, faChevronRight, faChevronsLeft, faChevronsRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect } from 'react'
import { Pagination as BootstrapPagination } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'

import { IPager } from '../../api/types/pager'
import { useQueryParams } from '../../hooks/useQueryParams'

const Label = styled.div`
  align-self: center;
  margin-left: 0.5rem;
  font-weight: 400;
  font-size: 0.6875rem;
  color: #808080;
`

type Item = {
  number: number
  active: boolean
}

type Props = {
  pager?: IPager
  setOffset: (offset: number) => void
}

export function Pagination(props: Props) {
  const { t } = useTranslation()
  const adjacent = 3
  const offset: number = props.pager?.offset ? props.pager?.offset : 0
  const limit: number = props.pager?.limit ? props.pager?.limit : 1
  const total: number = props.pager?.total ? props.pager?.total : 0
  const [searchParams, setSearchParams] = useSearchParams()

  const { page: pageInUrl } = useQueryParams()

  const pages: number = (total - (total % limit)) / limit + (total % limit === 0 ? 0 : 1)
  const actualPage: number = pageInUrl ? Number(pageInUrl) : offset / limit + 1

  const toActualPage: number = actualPage - 1
  const toLastPage: number = pages - actualPage

  const items: Item[] = []
  for (let i = 1; i <= pages; i++) {
    items.push({ number: i, active: actualPage === i })
  }

  useEffect(() => {
    if (pages > 1 && !pageInUrl) {
      searchParams.set('page', actualPage.toString())
      setSearchParams(searchParams, { replace: true })
    }
  }, [])

  useEffect(() => {
    if (pages > 1 && pageInUrl) {
      props.setOffset(actualPage * limit - limit)
    }
  }, [searchParams.get('page')])

  const onClick = (page: number) => {
    props.setOffset(page * limit - limit)
    searchParams.set('page', page.toString())
    setSearchParams(searchParams)
  }

  const renderTotal = () => {
    return (
      <Label>
        {t('pager.items-total-count', '{count} items were found', {
          count: total
        })}
      </Label>
    )
  }

  if (pages <= 1) {
    return renderTotal()
  }

  return (
    <>
      <BootstrapPagination>
        <BootstrapPagination.First disabled={actualPage === 1} onClick={() => onClick(1)}>
          <FontAwesomeIcon icon={faChevronsLeft} />
        </BootstrapPagination.First>
        <BootstrapPagination.Prev disabled={actualPage === 1} onClick={() => onClick(actualPage - 1)}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </BootstrapPagination.Prev>

        {items
          .filter((item) => item.number === 1)
          .map((item, i) => (
            <BootstrapPagination.Item key={i} active={item.active} onClick={() => onClick(item.number)}>
              {item.number}
            </BootstrapPagination.Item>
          ))}

        {toActualPage > adjacent && <BootstrapPagination.Ellipsis />}

        {items
          .filter(
            (item) =>
              item.number > 1 &&
              item.number >= actualPage - 1 &&
              item.number <= actualPage + 1 &&
              item.number < items.length
          )
          .map((item, i) => (
            <BootstrapPagination.Item key={i} active={item.active} onClick={() => onClick(item.number)}>
              {item.number}
            </BootstrapPagination.Item>
          ))}

        {toLastPage > adjacent && <BootstrapPagination.Ellipsis disabled />}

        {items
          .filter((item) => item.number === items.length)
          .map((item, i) => (
            <BootstrapPagination.Item key={i} active={item.active} onClick={() => onClick(item.number)}>
              {item.number}
            </BootstrapPagination.Item>
          ))}

        <BootstrapPagination.Next disabled={actualPage === pages} onClick={() => onClick(actualPage + 1)}>
          <FontAwesomeIcon icon={faChevronRight} />
        </BootstrapPagination.Next>
        <BootstrapPagination.Last disabled={actualPage === pages} onClick={() => onClick(pages)}>
          <FontAwesomeIcon icon={faChevronsRight} />
        </BootstrapPagination.Last>
      </BootstrapPagination>
      {renderTotal()}
    </>
  )
}
