import { isObject } from 'formik'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { IQueryError } from '../../api/types/errors'
import { useToast } from '../../context/toastStore'
import { useErrorTypeTranslations } from './errorTypes'

// known error codes https://dev.connect.prusa3d.com/docs/api_values#error-codes

export function useErrorHandler() {
  const toast = useToast()
  const { t } = useTranslation()
  const { ResponseCodeName, ResponseStatusMessage } = useErrorTypeTranslations()

  return useCallback(
    (error: any, payload?: IQueryError, showToast = true) => {
      const errorCode = payload && typeof payload.code === 'string' ? payload.code : error?.code
      const errorMessage = payload && typeof payload.message === 'string' ? payload.message : error?.message
      const status = isObject(error) && isObject(error.response) && error.response.status
      const statusText = isObject(error) && isObject(error.response) && error.response.statusText
      let title = status ? `HTTP Error ${status}` : t('toasts.general-error.title')
      let message = errorMessage || statusText || t('toasts.general-error.text')

      title = ResponseCodeName[status] /* || ResponseCodeName[errorCode] */ || title
      message = ResponseStatusMessage[statusText] || ResponseStatusMessage[errorCode] || message

      if (showToast) {
        toast.add(title, message)
      }
      return { title, message }
    },
    [t, toast, ResponseStatusMessage, ResponseCodeName]
  )
}
