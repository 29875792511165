import { stringify } from 'query-string'

import { AfsGroupJob } from '../../types/afs'
import {
  IAddJobQueue,
  IJob,
  IJobMoveRequest,
  IJobsQuery,
  IJobsResponse,
  IOptionalCondition,
  IPlannedJob,
  IPrinterJobs,
  IQueueRequest,
  IQueueResponse,
  IStoppedPrintReasonPayload,
  IStoppedPrintReasonResponse
} from '../../types/job'
import { IPagerQuery } from '../../types/pager'
import { PrinterUuid } from '../../types/printer'
import { NoContent204, RequestFactory } from '../../types/sdk'

export function createModule(rf: RequestFactory) {
  return {
    addJobToQueue: (printerUuid: PrinterUuid, job: IAddJobQueue) => rf.post(`/app/printers/${printerUuid}/queue`, job),

    getJobs: (printerUuid: PrinterUuid, query: IJobsQuery) =>
      rf.get<IJobsResponse>(`/app/printers/${printerUuid}/jobs?${stringify(query)}`),

    getJob: (printerUuid: PrinterUuid, jobId: number) => rf.get<IJob>(`/app/printers/${printerUuid}/jobs/${jobId}`),

    moveJob: (printerUuid: PrinterUuid, data: IJobMoveRequest) =>
      rf.put<NoContent204>(`/app/printers/${printerUuid}/queue`, data),

    deleteJob: (printerUuid: PrinterUuid, jobId: number) =>
      rf.delete<NoContent204>(`/app/printers/${printerUuid}/queue/${jobId}`),

    getPrinterQueue: (printerUuid: PrinterUuid, query: IPagerQuery = {}) =>
      rf.get<IPrinterJobs>(`/app/printers/${printerUuid}/queue?${stringify(query)}`),

    getPlannedJob: (printerUuid: PrinterUuid, jobId: number) =>
      rf.get<IPlannedJob>(`/app/printers/${printerUuid}/queue/${jobId}`),

    getQueue: (params: IQueueRequest = {}) => rf.get<IQueueResponse>(`/app/queue?${stringify(params)}`),

    clearQueue: (printerUuid: PrinterUuid) => rf.delete<NoContent204>(`/app/printers/${printerUuid}/queue`),

    getPrintersJobs: (params: IQueueRequest = {}) => rf.get<IJobsResponse>(`/app/jobs?${stringify(params)}`),

    updateJobConditions: (printerUuid: PrinterUuid, jobId: number, data: IOptionalCondition) =>
      rf.put<IPlannedJob>(`/app/printers/${printerUuid}/queue/${jobId}`, data),

    editJobEndReason: (printerUuid: string, jobId: number, reason: IStoppedPrintReasonPayload) =>
      rf.patch<IStoppedPrintReasonResponse>(`/app/printers/${printerUuid}/jobs/${jobId}`, reason),

    createAfsJob: (groupId: number, data: AfsGroupJob) =>
      rf.post<IStoppedPrintReasonResponse>(`/app/printers/groups/${groupId}/afs-jobs`, data)
  }
}
