import { IGroupSimpleView } from './groups'
import { IPager } from './pager'
import { IConnectState } from './state'
import { ITeam } from './team'

export enum IAgentType {
  HARVESTING = 'HARVESTING',
  PLANNING = 'PLANNING',
  CONTROLLING = 'CONTROLLING'
}

export enum IAgentCommand {
  ENABLE_AUTO_HARVEST = 'ENABLE_AUTO_HARVEST',
  DISABLE_AUTO_HARVEST = 'DISABLE_AUTO_HARVEST',
  HARVEST_PRINTER = 'HARVEST_PRINTER'
}

export type IAgent = {
  id: number
  name: string
  agent_type: IAgentType
  team: ITeam
  group?: IGroupSimpleView
  state: IConnectState
}

export type IAgentsResponse = {
  agents: IAgent[]
  pager: IPager
}
