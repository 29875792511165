import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { useApiClient } from '../../../api/react'
import { IDeleteFilesRequestBody } from '../../../api/types/file'
import { useRefetch } from '../../../context/refetchContext'
import { useToast } from '../../../context/toastStore'
import { StorageLocationSlug } from '../../../hooks/storageLocations/useStorageLocationTabs'
import { useIsAdmin } from '../../../hooks/useLoggedUser'
import { useWritableTeams } from '../../../hooks/useTeams'
import { AdaptiveButton } from '../../common/AdaptiveButton/AdaptiveButton'
import { WithConfirm } from '../../common/ConfirmModal'

type Props = {
  onSuccess?: (storageType: StorageLocationSlug) => void
  printerTeamId: number
  label: string
  fileHashes: string[]
}

export function DeleteCloudFilesAction(props: Props) {
  const { onSuccess, printerTeamId, label, fileHashes } = props
  const { t } = useTranslation()
  const api = useApiClient()
  const toast = useToast()
  const refetch = useRefetch()
  const { writableTeams } = useWritableTeams()
  const isAdmin = useIsAdmin()
  const isInWritableTeam = isAdmin || writableTeams.findIndex((team) => team.id === printerTeamId) !== -1

  const body: IDeleteFilesRequestBody = { delete_all: false, hashes: fileHashes }
  const { mutate, isLoading } = useMutation(() => api.app.files.deleteFiles(printerTeamId, body), {
    onSuccess: () => {
      refetch()
      toast.add(t('printer.storage.delete-files.title'), t('printer.storage.delete-files.message'))
      onSuccess?.(StorageLocationSlug.CONNECT)
    }
  })

  return (
    <WithConfirm>
      {(confirm) => (
        <AdaptiveButton
          icon="deleteIcon"
          label={label}
          className="delete-file"
          isLoading={isLoading}
          isAvailable
          isDisabled={!isInWritableTeam}
          disabledTooltip={t('printer.tooltip.rights')}
          trigger={confirm(mutate)}
        />
      )}
    </WithConfirm>
  )
}
