import { createBrowserRouter, redirect } from 'react-router-dom'

import { ErrorBoundary } from './components/errors/ErrorBoundary'
import { Contexts } from './Contexts'
import { Layout } from './pages/Layout'
import { PrivateRoute, Root } from './pages/Root'

export const router = createBrowserRouter([
  {
    Component: Contexts,
    ErrorBoundary,
    children: [
      {
        Component: Layout,
        ErrorBoundary,
        children: [
          {
            Component: PrivateRoute,
            ErrorBoundary,
            children: [
              {
                path: 'teams',
                children: [
                  {
                    index: true,
                    lazy: () => import('./pages/TeamsPage')
                  },
                  {
                    path: ':teamId',
                    lazy: () => import('./pages/TeamDetailPage'),
                    children: [
                      {
                        index: true,
                        loader: () => redirect('members')
                      },
                      {
                        path: 'members',
                        ErrorBoundary,
                        lazy: () => import('./pages/team/Members')
                      },
                      {
                        path: 'printers',
                        ErrorBoundary,
                        lazy: () => import('./pages/team/Printers')
                      }
                    ]
                  }
                ]
              },
              {
                path: 'preferences',
                lazy: () => import('./pages/PreferencesPage'),
                children: [
                  {
                    index: true,
                    loader: () => redirect('date-time') // date-time
                  },
                  {
                    path: 'date-time',
                    lazy: () => import('./pages/preferences/DateTimeSettings')
                  },
                  {
                    path: 'units',
                    lazy: () => import('./pages/preferences/Units')
                  },
                  {
                    path: 'status-bar',
                    lazy: () => import('./pages/preferences/StatusBar')
                  },
                  {
                    path: 'dark-mode',
                    lazy: () => import('./pages/preferences/DarkMode')
                  },
                  {
                    path: 'printers',
                    lazy: () => import('./pages/preferences/printers/Layout'),
                    children: [
                      {
                        index: true,
                        loader: () => redirect('table')
                      },
                      {
                        path: 'table',
                        lazy: () => import('./pages/preferences/printers/Table')
                      },
                      {
                        path: 'queue',
                        lazy: () => import('./pages/preferences/printers/Queue')
                      },
                      {
                        path: 'cards',
                        lazy: () => import('./pages/preferences/printers/Cards')
                      }
                    ]
                  },
                  {
                    path: 'files',
                    lazy: () => import('./pages/preferences/PrinterFiles')
                  },
                  {
                    path: 'jobs',
                    lazy: () => import('./pages/preferences/PrinterJobs')
                  }
                ]
              }
            ]
          }
        ]
      },

      // Redirects
      {
        path: 'cs',
        loader: () => redirect('/')
      },
      {
        path: 'de',
        loader: () => redirect('/')
      },
      {
        path: 'fr',
        loader: () => redirect('/')
      },
      {
        path: 'it',
        loader: () => redirect('/')
      },
      {
        path: 'pl',
        loader: () => redirect('/')
      },
      {
        path: 'es',
        loader: () => redirect('/')
      },

      // Match all other pages with legacy router
      {
        path: '*',
        Component: Root
      }
    ]
  }
])
