import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { useParams } from 'react-router'
import { useTheme } from 'styled-components'

import { useApiClient } from '../api/react'
import { IAgent } from '../api/types/agents'
import { AddCommandToQueue } from '../components/agents/AddCommandToQueue'
import { ContainerTitle } from '../components/common/ContainerTitle'
import { EmptyList } from '../components/common/EmptyList'
import { PaceProgress } from '../components/common/PaceProgress'
import { Suspense } from '../components/common/Suspense'
import { PageContainer } from '../components/helpers/styled'

const ReactJson = React.lazy(() => import('react-json-view'))

function Content({ agent }: { agent: IAgent }) {
  const theme = useTheme()

  return (
    <PageContainer>
      <ContainerTitle>Agent detail</ContainerTitle>

      <div className="my-1">
        <AddCommandToQueue agentId={agent.id} groupId={agent.group?.id} />
      </div>

      <Suspense>
        <ReactJson
          src={agent}
          name={false}
          iconStyle="square"
          enableClipboard={false}
          displayObjectSize={false}
          displayDataTypes={false}
          collapseStringsAfterLength={128}
          theme={theme.isDark ? 'monokai' : 'rjv-default'}
        />
      </Suspense>
    </PageContainer>
  )
}

export function AgentDetailPage() {
  const params = useParams<{ agentId: string }>()
  const agentId = Number(params.agentId)

  const api = useApiClient()
  const query = useQuery([`/agent/${agentId}`], () => api.app.agents.getAgent(agentId))

  if (query.isLoading) {
    return <PaceProgress />
  }

  if (!query.data) {
    return <EmptyList title="Agent not found" />
  }

  return <Content agent={query.data} />
}
