import { memo, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { components } from 'react-select'
import Creatable from 'react-select/creatable'
import styled from 'styled-components'

import { Material } from '../../../api/types/filament'
import { IPrinterFilamentDetail } from '../../../api/types/printer'
import { formatTemperature, THINSP } from '../../../helpers/formatters'
import { useLoggedUserPreferences } from '../../../hooks/useLoggedUser'
import { PlainButton } from '../../common/PlainButton'
import { convert, TemperatureUnits, toCelsius } from '../../common/Temperature'
import { useStyles } from '../filters/styles'

enum Type {
  NOZZLE = 'nozzle',
  BED = 'bed'
}

const Button = styled(PlainButton)`
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
`

type Props = {
  disabled: boolean
  filament?: IPrinterFilamentDetail
  onChange: (temperature: number) => void
  type: Type | `${Type}`
}

type Option = {
  label: string
  value: number
}

// In degrees Celsius
const presets = [
  {
    material: Material.PLA,
    nozzle: 215,
    bed: 60
  },
  {
    material: Material.PETG,
    nozzle: 230,
    bed: 85
  },
  {
    material: Material.ASA,
    nozzle: 260,
    bed: 100
  },
  {
    material: Material.ABS,
    nozzle: 255,
    bed: 100
  },
  {
    material: Material.PC,
    nozzle: 275,
    bed: 100
  },
  {
    material: Material.FLEX,
    nozzle: 240,
    bed: 50
  },
  {
    material: Material.HIPS,
    nozzle: 220,
    bed: 100
  },
  {
    material: Material.PP,
    nozzle: 240,
    bed: 100
  }
]

function formatOption(material: Material, temperature: number, sign: TemperatureUnits) {
  return {
    label: `${material} ${formatTemperature(sign, temperature)}`,
    value: convert(temperature, sign)
  }
}

export const TemperatureDropdown = memo((props: Props) => {
  const { onChange, type, filament, disabled } = props
  const { t } = useTranslation()
  const [value, setValue] = useState<Option | null>(null)
  const units = useLoggedUserPreferences('units')
  const tempSign = units.temp === TemperatureUnits.CELSIUS ? TemperatureUnits.CELSIUS : TemperatureUnits.FAHRENHEIT

  const placeholder =
    type === Type.NOZZLE
      ? t('printer.control.temperatures.set-nozzle-temperature')
      : t('printer.control.temperatures.set-bed-temperature')

  const options = useMemo(() => {
    const key = type === Type.NOZZLE ? 'nozzle' : 'bed'
    const presetOptions = presets.map((preset) => formatOption(preset.material, preset[key], tempSign))

    const groups = [
      {
        label: t('printer.control.temperatures.presets'),
        options: presetOptions
      }
    ]

    if (
      filament &&
      ((type === Type.NOZZLE && filament.nozzle_temperature) || (type === Type.BED && filament.bed_temperature))
    ) {
      groups.unshift({
        label: t('printer.control.temperatures.current-filament'),
        options: [
          formatOption(
            filament.material,
            type === Type.NOZZLE ? filament.nozzle_temperature : filament.bed_temperature,
            tempSign
          )
        ]
      })
    }

    return groups
  }, [t, type, filament])

  return (
    <Creatable
      value={value}
      isClearable
      isDisabled={disabled}
      placeholder={placeholder}
      formatCreateLabel={(temperature) =>
        t('printer.control.temperatures.set-temperature', {
          temperature: `${temperature}${THINSP}${tempSign}`
        })
      }
      options={options}
      menuPlacement="auto"
      styles={useStyles<Option>()}
      onChange={(selected) => {
        // custom value is string
        const value = selected ? Number(selected.value) : 0
        setValue(selected)
        onChange(units.temp === TemperatureUnits.CELSIUS ? value : Number(toCelsius(value).toFixed(0)))
      }}
      components={{
        // eslint-disable-next-line react/no-unstable-nested-components
        Input: (props) => (
          <components.Input
            {...props}
            onChange={(e) => {
              // allow only numbers
              const { value } = e.currentTarget
              const n = value.replace(/\D/g, '')
              e.currentTarget.value = n
              props.onChange?.(e)
            }}
          />
        ),
        // eslint-disable-next-line react/no-unstable-nested-components
        MenuList: ({ children, ...menuListProps }) => (
          <components.MenuList {...menuListProps}>
            {children}

            <Button
              $expand
              onClick={() => {
                menuListProps.selectProps.onMenuClose()
                setValue(null)
                onChange(0)
              }}
            >
              {t('printer.control.temperatures.cooldown')}
            </Button>
          </components.MenuList>
        )
      }}
    />
  )
})
