import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'

import { useApiClient } from '../../../api/react'
import { IPrinter } from '../../../api/types/printer'
import { IConnectState } from '../../../api/types/state'
import { intervals } from '../../../config'
import { useBreakpoint } from '../../../helpers/useBreakpoint'
import { useLoggedUserPreferences } from '../../../hooks/useLoggedUser'
import { useIsFdm } from '../../../hooks/usePrinterType'
import { PrinterIcon } from '../../common/PrinterIcon'
import { PrinterStateTag } from '../../common/PrinterStateTag'
import ScrollContainer from '../../common/ScrollContainer'
import { WithTooltip } from '../../common/WithTooltip'
import { OUTAGE_MESSAGE_ID } from '../../header/Header'
import { HEADER_HEIGHT } from '../../header/Header.styled'
import { Layers } from '../../helpers/zIndex'
import { Title } from '../../jobs/JobTitle'
import { useUpdateUserPreferences } from '../../preferences/helpers'
import { StatusBarPosition } from '../../preferences/types'
import { IncidentIcon } from '../IncidentIcon'
import { PrinterBasicInfo } from '../PrinterBasicInfo'
import { PrinterParams } from '../PrinterParams'
import { SetPrinterReadyButton, SetReadyButtonModal, useSetPrinterReadyModal } from '../SetPrinterReadyButton'
import { SlaPrinterParams } from '../SlaPrinterParams'
import { getPrinterName } from '../utils'
import { CONTAINER_MARGIN_BOTTOM } from './JobLayout'

export const Row = styled.div<{ top: number }>`
  background-color: var(--current-printer-background);
  color: var(--current-printer-badge-text);

  position: sticky;
  top: ${({ top }) => top}px;
  z-index: ${Layers.PRINTER_HEADER};
`

const RelativeContainer = styled.div`
  position: relative;
`

export const PrinterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  min-width: 0;
  margin-bottom: ${CONTAINER_MARGIN_BOTTOM}px;
`

const Left = styled(Link)`
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-color: var(--color-primary);
`

const Right = styled.div<{ simple?: boolean; mobile?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
  user-select: none;
  gap: 10px;

  ${({ simple, mobile }) =>
    simple &&
    `
      flex-direction: row;
      align-items: center;
      gap: 20px;
      ${mobile && `justify-content: space-between; gap: 10px;`}
  `}
`

const TitleContainer = styled(Link)`
  display: flex;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
  min-width: 3rem;
`

const PrinterTitle = styled(Title)`
  color: white !important;
  font-size: 1.3rem;
  margin-top: 0;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
`

const ScrollableContainer = styled(ScrollContainer)`
  margin: 0 -0.7rem;
  padding: 0 0.7rem;
  width: auto;
  flex: 1;
`

const RightTop = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

const RightBottom = styled.div`
  display: flex;
  gap: 20px;
  align-items: end;
`

const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: auto;
`

const SwitchButton = styled.div`
  position: absolute;
  cursor: pointer;
  height: 3px;
  width: 26px;
  background: gray;

  &:hover {
    background: ${({ theme }) => theme.colors.primary};
  }

  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: 3px;

  ::before {
    content: '';
    position: absolute;
    left: -7px;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: -3px;
    height: 12px;
    width: 40px;
  }
`

type CurrentPrinterContentsProps = {
  printer: IPrinter
  isSimpleHeaderMode: boolean
  isMobile: boolean
  onSetPrinterReady: () => void
  printerParamExists: boolean
}

type SimpleModeProps = Omit<CurrentPrinterContentsProps, 'isSimpleHeaderMode' | 'printerParamExists'> & {
  queueJobsCount?: number
}

type FullModeProps = Omit<CurrentPrinterContentsProps, 'isSimpleHeaderMode' | 'isMobile'> & {
  queueJobsCount?: number
}

const SimpleMode = ({ printer, isMobile, onSetPrinterReady, queueJobsCount }: SimpleModeProps) => {
  const theme = useTheme()

  return (
    <Right simple mobile={isMobile}>
      <TitleContainer to={`/printer/${printer.uuid}`}>
        <PrinterTitle title={getPrinterName(printer)}>{getPrinterName(printer)}</PrinterTitle>
      </TitleContainer>
      <PrinterStateTag state={printer.connect_state} inverse={!theme.isDark} collapsible />
      {!isMobile && (
        <ScrollableContainer color="white">
          <PrinterBasicInfo {...printer} />
        </ScrollableContainer>
      )}
      <Buttons>
        <SetPrinterReadyButton
          onSetPrinterReady={onSetPrinterReady}
          printer={printer}
          plannedJobsCount={queueJobsCount}
          color="white"
          borderColor="white"
        />
        {printer.connect_state === IConnectState.OFFLINE && <IncidentIcon />}
      </Buttons>
    </Right>
  )
}

const FullMode = ({ printer, onSetPrinterReady, queueJobsCount, printerParamExists }: FullModeProps) => {
  const theme = useTheme()
  const isFdm = useIsFdm(printer.printer_type)

  return (
    <Right>
      <RightTop>
        <TitleContainer to={`/printer/${printer.uuid}`}>
          <PrinterTitle title={getPrinterName(printer)}>{getPrinterName(printer)}</PrinterTitle>
        </TitleContainer>
        <PrinterStateTag state={printer.connect_state} inverse={!theme.isDark} collapsible />
        <ScrollableContainer color="white">
          <PrinterBasicInfo {...printer} />
        </ScrollableContainer>
        <Buttons>
          {!printerParamExists && (
            <SetPrinterReadyButton
              onSetPrinterReady={onSetPrinterReady}
              printer={printer}
              plannedJobsCount={queueJobsCount}
              color="white"
              borderColor="white"
            />
          )}
          <IncidentIcon />
        </Buttons>
      </RightTop>
      {printerParamExists && (
        <RightBottom>
          <ScrollableContainer color="white">
            {isFdm ? <PrinterParams {...printer} inverseColor /> : <SlaPrinterParams {...printer} inverseColor />}
          </ScrollableContainer>
          <Buttons>
            <SetPrinterReadyButton
              onSetPrinterReady={onSetPrinterReady}
              printer={printer}
              plannedJobsCount={queueJobsCount}
              color="white"
              borderColor="white"
            />
          </Buttons>
        </RightBottom>
      )}
    </Right>
  )
}

const CurrentPrinterContents = ({
  printer,
  isSimpleHeaderMode,
  isMobile,
  printerParamExists,
  onSetPrinterReady
}: CurrentPrinterContentsProps) => {
  const api = useApiClient()

  const query = {
    offset: 0,
    limit: 0
  }

  const { data } = useQuery(
    [`/queue/${printer.uuid}/${JSON.stringify(query)}`],
    () => api.app.jobs.getPrinterQueue(printer.uuid, query),
    {
      refetchInterval: intervals.jobsPolling
    }
  )

  return isSimpleHeaderMode || isMobile ? (
    <SimpleMode
      printer={printer}
      isMobile={isMobile}
      onSetPrinterReady={onSetPrinterReady}
      queueJobsCount={data?.pager.total}
    />
  ) : (
    <FullMode
      printer={printer}
      printerParamExists={printerParamExists}
      queueJobsCount={data?.pager.total}
      onSetPrinterReady={onSetPrinterReady}
    />
  )
}

export function CurrentPrinter(props: IPrinter) {
  const isMobile = !useBreakpoint('sm')
  const preferences = useLoggedUserPreferences()
  const { update } = useUpdateUserPreferences()
  const { status_bar } = preferences
  const isSimpleHeaderMode = status_bar.position === StatusBarPosition.BOTTOM
  const { showSetPrinterReadyModal, onCancel, onSetPrinterReady } = useSetPrinterReadyModal()
  const [topShift, setTopShift] = useState<number>(HEADER_HEIGHT)

  const printerParamExists = Boolean(
    props.temp || props.filament || Number.isInteger(props.speed) || Number.isInteger(props.axis_z)
  )
  const iconSize =
    isMobile || isSimpleHeaderMode || (!printerParamExists && props.connect_state === IConnectState.OFFLINE) ? 60 : 110

  useEffect(() => {
    const outageMessageElHeight = document.getElementById(OUTAGE_MESSAGE_ID)?.offsetHeight
    if (typeof outageMessageElHeight === 'number') {
      setTopShift(outageMessageElHeight + HEADER_HEIGHT)
    }
  }, [])

  const toggleHeaderMode = () => {
    const statusBar = { ...status_bar }
    statusBar.position =
      statusBar.position === StatusBarPosition.BOTTOM ? StatusBarPosition.TOP : StatusBarPosition.BOTTOM

    update({ preferences: { ...preferences, status_bar: statusBar } })
  }

  return (
    <Row top={topShift}>
      <RelativeContainer>
        <PrinterContainer className="container">
          <WithTooltip id={props.printer_type} title={props.printer_type_name} placement="bottom">
            <Left to={`/printer/${props.uuid}`}>
              <PrinterIcon type={props.printer_type} slots={props.slots} size={iconSize} />
            </Left>
          </WithTooltip>
          <CurrentPrinterContents
            printer={props}
            isMobile={isMobile}
            isSimpleHeaderMode={isSimpleHeaderMode}
            onSetPrinterReady={onSetPrinterReady}
            printerParamExists={printerParamExists}
          />
        </PrinterContainer>
        {!isMobile && props.connect_state !== IConnectState.OFFLINE && (
          <SwitchButton onClick={() => toggleHeaderMode()} />
        )}
      </RelativeContainer>
      {showSetPrinterReadyModal && <SetReadyButtonModal onCancel={onCancel} printer={props} />}
    </Row>
  )
}
