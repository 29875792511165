import styled from 'styled-components'

const EmptyCell = styled.div`
  height: 100%;
  width: 100%;

  ${({ theme }) =>
    `background-image: linear-gradient(135deg, var(--background-body) 40%, ${theme.colors.primary} 40%, ${theme.colors.primary} 50%, var(--background-body) 50%, var(--background-body) 90%, ${theme.colors.primary} 90%, ${theme.colors.primary} 100%);`}
  background-size: 7.07px 7.07px;
`

export function GridEmptyCell() {
  return <EmptyCell />
}
