import ms from 'ms'

// eslint-disable-next-line import/no-relative-packages
import { version } from '../../package.json'
import appPackage from '../package.json'
import { GridMode } from './constants/grid'

const { name } = appPackage
const appName: string = name || ''
const appVersion = import.meta.env.VITE_RELEASE_VERSION || `${version}-${import.meta.env.VITE_VCS_REF}`

export enum Environment {
  PRODUCTION = 'production',
  TEST = 'test',
  DEV = 'dev',
  UNKNOWN = 'unknown'
}

export function getEnvironment(host: string): Environment {
  switch (host) {
    case 'connect.prusa3d.com':
      return Environment.PRODUCTION
    case 'test.connect.prusa3d.com':
      return Environment.TEST
    case 'dev.connect.prusa3d.com':
      return Environment.DEV
    default:
      return Environment.UNKNOWN
  }
}

export const config = {
  appName,
  appVersion,
  environment: getEnvironment(window.location.host),
  isProductionBuild: process.env.NODE_ENV === 'production',
  hostname: '/',
  sentry_dsn: 'https://aa766a6ce5634264989fd1f573469f25@sentry.prusa3d.com/13'
}

export const intervals = {
  printersPolling: ms('10s'),
  printerGroupsPolling: ms('10s'),
  groupQueuesPolling: ms('10s'),
  printerPolling: ms('5s'),
  printerPollingInactive: ms('1m'), // Update printer data in inactive tab every 60 seconds
  printerCloudFilesPolling: ms('10s'),
  printerFilesPolling: ms('10s'),
  teamsListPolling: ms('15m'),
  userInvitations: ms('10s'),
  commandQueuePolling: ms('5s'),
  eventsPolling: ms('10s'),
  jobsPolling: ms('10s'),
  loginCheckPolling: ms('10m'),
  transfersPolling: ms('10s'),
  cameraListPolling: ms('15s'),
  activeCameraPolling: ms('10s'),
  cameraThumbnailPolling: ms('10s')
}

export const DEFAULT_GRID_MODE = GridMode.FIRST_DOWN
