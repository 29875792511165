import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useApiClient } from '../../../api/react'
import { IPlannedJob, IPrinterDetailJobInfo, IQueue, IQueueRequest } from '../../../api/types/job'
import { PrinterUuid } from '../../../api/types/printer'
import { intervals } from '../../../config'
import { formatPercents } from '../../../helpers/formatters'
import { isDefined, isNumber, range } from '../../../helpers/std'
import { getJobDisplayPath, getJobTitle } from '../../../hooks/useJobTitle'
import { Button } from '../../common/Button'
import { LongContentAutoScroll } from '../../common/LongContentAutoScroll'
import { PaceProgress } from '../../common/PaceProgress'
import { Pager } from '../../common/Pager'
import { PrinterIcon } from '../../common/PrinterIcon'
import { usePrintersQueryState } from '../hooks/usePrintersQueryState'
import { NoPrinters } from '../NoPrinters'
import { checkOptionalConditions } from '../SinglePrinterQueues'
import { getPrinterName } from '../utils'
import * as S from './QueueView.styled'

export const VISIBLE_HOUR_OFFSET = 2
const PRINTER_ICON_WIDTH = 42

export function SingleQueue({
  queue,
  currentLeft,
  isFirstPrinter,
  showGradient
}: {
  queue: IQueue
  currentLeft: number
  isFirstPrinter: boolean
  showGradient: boolean
}) {
  const { t } = useTranslation()

  const now = dayjs()
  const currentHour = now.hour()
  const timelineStart = dayjs()
    .startOf('day')
    .set('hour', currentHour - VISIBLE_HOUR_OFFSET)
    .set('minute', 0)

  const startHour = currentHour - VISIBLE_HOUR_OFFSET
  const endHour = startHour + 24
  const hoursRange = range(startHour, endHour).map((hour) => hour % 24)

  const getJobDimensions = (start?: number, end?: number) => {
    if (!start) {
      return null
    }
    const startObj = dayjs(start * 1000)
    const endObj = end ? dayjs(end * 1000) : dayjs().endOf('day') // use the end of the day if end is missing
    const diff = endObj.diff(startObj) / 1000
    const left = (startObj.diff(timelineStart) / 1000 / 60) * S.MINUTE_WIDTH
    const width = (diff / 60) * S.MINUTE_WIDTH
    return {
      left,
      width
    }
  }

  const renderCurrentJob = (job?: IPrinterDetailJobInfo) => {
    if (!job || !job.start) {
      return null
    }
    const jobTimeRemaining = job.time_remaining === -1 ? 3.156e7 : job.time_remaining
    const dimensions = getJobDimensions(
      job.start,
      isDefined(jobTimeRemaining) ? now.unix() + job.time_remaining : undefined
    )
    if (!dimensions) {
      return null
    }
    const { left, width } = dimensions
    const content = [getJobTitle(t, job)]
    if (isNumber(job.progress)) {
      content.push(formatPercents(job.progress))
    }
    return (
      <S.Job style={{ width, left }}>
        <S.JobContent title={getJobDisplayPath(job)} $active>
          {content.filter((c) => c).join(' | ')}
        </S.JobContent>
      </S.Job>
    )
  }

  const renderDay = (jobs: IPlannedJob[], printerUuid: PrinterUuid) => {
    return jobs.map((job) => {
      const dimensions = getJobDimensions(job.planned_start, job.planned_end)
      if (!dimensions) {
        return null
      }
      const { left, width } = dimensions
      const { conditions: c } = job
      const isPrintAllowed = checkOptionalConditions(c) && c.file_in_cache.satisfied && c.printer_ready?.satisfied

      return (
        <S.JobLink key={job.id} style={{ width, left }} to={`/printer/${printerUuid}/queue/${job.id}`}>
          <S.JobContent title={getJobDisplayPath(job)} $isPrintAllowed={isPrintAllowed}>
            {getJobTitle(t, job)}
          </S.JobContent>
        </S.JobLink>
      )
    })
  }

  return (
    <>
      <S.CurrentTimeLine style={{ left: currentLeft }} />
      <S.PrinterName title={getPrinterName(queue.printer)} $isFirstPrinter={isFirstPrinter}>
        <LongContentAutoScroll>
          <PrinterIcon
            type={queue.printer.printer_type}
            size={PRINTER_ICON_WIDTH}
            title={queue.printer.printer_type_name}
          />
          <Link to={`/printer/${queue.printer.uuid}/queue`}>{getPrinterName(queue.printer)}</Link>
        </LongContentAutoScroll>
      </S.PrinterName>
      <S.Gradient $visible={showGradient} />
      <S.Day $isFirstPrinter={isFirstPrinter}>
        {isFirstPrinter && (
          <S.TimeWrapper>
            <div className="d-flex">
              {hoursRange.map((hour, i) => {
                return (
                  <Fragment key={i}>
                    <S.TimeHeader>{hour}:00</S.TimeHeader>
                    <S.TimeHeader>{hour}:30</S.TimeHeader>
                  </Fragment>
                )
              })}
            </div>
            <div className="d-flex">
              {hoursRange.map((_, i) => (
                <S.TimeMark key={i} />
              ))}
            </div>
          </S.TimeWrapper>
        )}
        <S.DayLine />
        {renderCurrentJob(queue.job_info)}
        {renderDay(queue.planned_jobs, queue.printer.uuid)}
      </S.Day>
    </>
  )
}

export function QueueView() {
  const [autoScroll, setAutoScroll] = useState(true)
  const containerRef = useRef<HTMLDivElement>(null)
  const [dragX, setDragX] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)
  const [isDragging, setIsDragging] = useState(false)
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(50)
  const { t } = useTranslation()
  const [showGradient, setShowGradient] = useState(false)

  const now = dayjs()
  const currentHour = now.hour()
  const timelineStart = dayjs()
    .startOf('day')
    .set('hour', currentHour - VISIBLE_HOUR_OFFSET)
    .set('minute', 0)

  const currentLeft = Math.round((now.diff(timelineStart) / 1000 / 60) * S.MINUTE_WIDTH + S.PRINTER_NAME_WIDTH)

  const api = useApiClient()
  const { teamId, groupId, states } = usePrintersQueryState()
  const query: IQueueRequest = {
    team_id: teamId,
    group_id: groupId,
    state_include: states,
    offset,
    limit
  }
  const { data } = useQuery([`/queue/${JSON.stringify(query)}`], () => api.app.jobs.getQueue(query), {
    refetchInterval: intervals.groupQueuesPolling
  })

  const scrollToNow = useCallback(() => {
    containerRef.current?.scrollTo({ left: currentLeft - S.PRINTER_NAME_WIDTH - S.SCROLL_OFFSET })
  }, [currentLeft])

  const areDataAvailable = !!data

  // Enable auto scroll when group is changed
  useEffect(() => {
    setAutoScroll(true)
  }, [groupId])

  // Scoll if autoscroll enabled
  useEffect(() => {
    if (areDataAvailable && autoScroll) {
      scrollToNow()
      // scrollToNow triggers onScroll and disables autoscroll, this will enable auto scroll again
      window.requestAnimationFrame(() => {
        setAutoScroll(true)
      })
    }
  }, [autoScroll, scrollToNow, areDataAvailable])

  if (!data) {
    return <PaceProgress />
  }

  if (data.pager.total === 0) {
    return <NoPrinters />
  }

  return (
    <>
      <div className="mt-2 mx-3 d-flex align-items-center">
        <Button size="sm" onClick={() => scrollToNow()}>
          {t('queue.scroll-to-now')}
        </Button>
        {autoScroll ? (
          <small className="ml-1">{t('queue.auto-scroll-enabled')}</small>
        ) : (
          <Button size="sm" className="ml-1" onClick={() => setAutoScroll(true)}>
            {t('queue.enable-auto-scroll')}
          </Button>
        )}
      </div>
      <S.ColumnTitleContainer>
        <S.ColumnTitle>{t('queue.printers')}</S.ColumnTitle>
        <S.ColumnTitle>{t('queue.printing-jobs')}</S.ColumnTitle>
      </S.ColumnTitleContainer>
      <S.QueueContainer
        ref={containerRef}
        onTouchStart={() => setAutoScroll(false)}
        onScroll={() => {
          setAutoScroll(false)
          setShowGradient((containerRef.current?.scrollLeft || 0) > 0)
        }}
        onMouseDown={(event) => {
          if (!containerRef.current) {
            return
          }
          setAutoScroll(false)
          setIsDragging(true)
          setDragX(event.pageX - containerRef.current.offsetLeft)
          setScrollLeft(containerRef.current.scrollLeft)
        }}
        onMouseMove={(event) => {
          if (!containerRef.current) {
            return
          }
          if (!isDragging) {
            return
          }
          const x = event.pageX - containerRef.current.offsetLeft
          const walk = x - dragX
          containerRef.current.scrollTo({ left: scrollLeft - walk })
        }}
        onMouseUp={() => setIsDragging(false)}
      >
        <S.CurrentTimeLine style={{ left: currentLeft }} />
        {data.queues.map((queue, i) => (
          <SingleQueue
            key={queue.printer.uuid}
            queue={queue}
            currentLeft={currentLeft}
            isFirstPrinter={i === 0}
            showGradient={showGradient}
          />
        ))}
        <S.Gradient $visible={showGradient} />
      </S.QueueContainer>

      <Container fluid>
        <Pager pager={data.pager} setOffset={setOffset} setLimit={setLimit} />
      </Container>
    </>
  )
}
