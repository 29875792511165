import { RefObject, useEffect } from 'react'

function isRefOrDescendant<T extends HTMLElement>(ref: RefObject<T>, event: Event) {
  return !ref?.current || ref.current.contains(event.target as Node)
}

function shouldTriggerOutsideClick<T extends HTMLElement>(ref: RefObject<T> | RefObject<T>[], event: Event) {
  if (Array.isArray(ref)) {
    return ref.some((refEl) => !isRefOrDescendant(refEl, event))
  }

  return !isRefOrDescendant(ref, event)
}

export function useOutsideClick<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T> | RefObject<T>[],
  handler: (event: Event | KeyboardEvent) => void
): void {
  useEffect(() => {
    const listener = (event: Event) => {
      if (!shouldTriggerOutsideClick(ref, event)) {
        return
      }

      handler(event)
    }

    document.addEventListener(`mousedown`, listener)
    document.addEventListener(`touchstart`, listener)
    document.addEventListener(`keyup`, listener)

    return () => {
      document.removeEventListener(`mousedown`, listener)
      document.removeEventListener(`touchstart`, listener)
      document.removeEventListener(`keyup`, listener)
    }

    // Reload only if ref or handler changes
  }, [ref, handler])
}
