import { lazy } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Routes } from 'react-router'

import { Suspense } from '../components/common/Suspense'
import { SimpleError } from '../components/errors/SimpleError'
import { PageContainer } from '../components/helpers/styled'
import { useLoggedUser } from '../hooks/useLoggedUser'

const AddUserPage = lazy(() => import('../components/users/AddUserPage'))
const EditUserPage = lazy(() => import('../components/users/EditUserPage'))
const OverviewPage = lazy(() => import('../components/users/OverviewPage'))

export function UsersPage() {
  const user = useLoggedUser()
  const { t } = useTranslation()

  if (!user.is_admin) {
    return <SimpleError statusCode={403} statusMessage={t('error.403')} />
  }

  return (
    <>
      {/* TODO refactor since it's here only for the white space */}
      <PageContainer />

      <Routes>
        <Route
          path="edit/:userId"
          element={
            <Suspense>
              <EditUserPage />
            </Suspense>
          }
        />
        <Route
          path="add"
          element={
            <Suspense>
              <AddUserPage />
            </Suspense>
          }
        />
        <Route
          index
          element={
            <Suspense>
              <OverviewPage />
            </Suspense>
          }
        />
      </Routes>
    </>
  )
}
