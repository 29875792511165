import { Placement } from '@popperjs/core'
import { MouseEvent, ReactNode, useEffect, useState } from 'react'
import { usePopper } from 'react-popper'

import { isDefined } from '../../helpers/std'
import { useOutsideClick } from '../../hooks/useOutsideClick'
import { Layers } from '../helpers/zIndex'
import * as S from './Dropdown.styled'
import { PlainButton } from './PlainButton'

export const DropdownItem = S.Item
export const DropdownItemLink = S.Link
export const DropdownItemButton = S.Button

type DropdownProps = {
  trigger: ReactNode
  children: ReactNode
  placement?: Placement
  onMenuClick?: (e: MouseEvent) => void
  onTriggerClick?: (e: MouseEvent) => void
  onDropdownOpen?: () => void
  isDisabled?: boolean
}

export function Dropdown(props: DropdownProps) {
  const {
    trigger,
    children,
    onMenuClick,
    onTriggerClick,
    onDropdownOpen,
    placement = 'bottom-start',
    isDisabled
  } = props
  // https://popper.js.org/react-popper/v2/#example
  const [triggerElement, setTriggerElement] = useState<HTMLElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)

  const { styles, attributes, update } = usePopper(triggerElement, popperElement, {
    placement
  })
  const [open, setOpen] = useState(false)

  useEffect(() => {
    update?.()
  }, [children])

  useEffect(() => {
    if (onDropdownOpen && open === true) {
      onDropdownOpen()
    }
  }, [open])

  const toggleDropdown = (visible?: boolean) => {
    setOpen((prev) => {
      if (isDefined(visible)) {
        return visible
      }
      return !prev
    })

    // This ensures the dropdown is properly aligned
    update?.()
  }

  useOutsideClick({ current: popperElement }, (event) => {
    // Don't re-open on trigger click
    if (triggerElement?.contains(event.target as Node)) {
      return
    }

    toggleDropdown(false)
  })

  return (
    <>
      <PlainButton
        ref={setTriggerElement}
        onClick={(e) => {
          e.preventDefault()
          onTriggerClick?.(e)
          toggleDropdown()
        }}
        disabled={isDisabled}
      >
        {trigger}
      </PlainButton>

      <div
        ref={setPopperElement}
        // Items can have Modals, so we need this to be mounted
        style={{ ...styles.popper, zIndex: Layers.DROPDOWN, display: open ? undefined : 'none' }}
        {...attributes.popper}
      >
        <S.Menu
          onClick={(e) => {
            const element = e.target as HTMLElement
            if (!element.dataset?.exceptionElement) {
              toggleDropdown(false)
            }
            onMenuClick?.(e)
          }}
        >
          {children}
        </S.Menu>
      </div>
    </>
  )
}
