import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Material } from '../../../api/types/filament'
import { IPrinterSimpleView } from '../../../api/types/printer'
import { useCanControl } from '../../../context/permissionsStore'
import { useCommandMutation } from '../../../hooks/commands/useCommandMutation'
import { useErrorHandler } from '../../../hooks/errors/useErrorHandler'
import { useMismatchedFilamentModal } from '../../../hooks/useMismatchedFilamentModal'
import { usePrinterInState } from '../../../hooks/usePrinterInState'
import { useIsSla } from '../../../hooks/usePrinterType'
import { AdaptiveButton } from '../../common/AdaptiveButton/AdaptiveButton'
import { ConfirmModal } from '../../common/ConfirmModal'
import { ConfirmPrintModal } from './ConfirmPrintModal'

export enum PrintVariant {
  PRINT,
  REPRINT
}

type Props = {
  printer: IPrinterSimpleView
  variant: PrintVariant
  path?: string
  onSuccess?: () => void
  withConfirm?: boolean
  filamentType?: Material
  label?: string
}

export function StartPrintAction({ printer, path, onSuccess, variant, withConfirm, label, filamentType }: Props) {
  const isSla = useIsSla(printer.printer_type)
  const { t } = useTranslation()
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const { triggerFilamentModal, filamentModal, resetFilamentModal, differentFilaments } = useMismatchedFilamentModal(
    printer.filament?.material,
    filamentType
  )
  const errorHandler = useErrorHandler()
  const printerInState = usePrinterInState(printer.connect_state)
  const canControl = useCanControl(printer.team_id)

  const { execute, isLoading, isAvailable } = useCommandMutation(printer, {
    command: 'START_PRINT',
    successMessage: {
      title: t('printer.actions.start-print.success.title'),
      body: t('printer.actions.start-print.success.body')
    }
  })

  const title =
    variant === PrintVariant.PRINT ? t('printer.actions.start-print.label') : t('printer.actions.start-reprint.label')
  const icon = variant === PrintVariant.PRINT ? 'startIcon' : 'reprintColorIcon'

  const startPrint = useCallback(() => {
    execute(
      { path },
      {
        onSuccess: () => {
          setShowConfirmModal(false)
          onSuccess?.()
        },
        onError: (error: any) => {
          setShowConfirmModal(false)
          errorHandler(error.response, error)
        }
      }
    )
  }, [errorHandler, execute, onSuccess, path])

  const trigger = useCallback(() => {
    if (differentFilaments) {
      return triggerFilamentModal()
    }

    if (withConfirm) {
      setShowConfirmModal(true)
    } else {
      startPrint()
    }
  }, [differentFilaments, startPrint, triggerFilamentModal, withConfirm])

  let disabledTooltip = ''
  if (!canControl) {
    disabledTooltip = t('printer.tooltip.rights')
  } else if (!isAvailable) {
    disabledTooltip = printerInState
  } else {
    disabledTooltip = t('gcode-meta.enqueue.not-available-in-link')
  }

  return (
    <>
      <AdaptiveButton
        icon={icon}
        trigger={trigger}
        label={label || title}
        isLoading={isLoading}
        isAvailable
        isDisabled={!path || !isAvailable || !canControl}
        disabledTooltip={disabledTooltip}
      />

      {filamentModal.show && (
        <ConfirmModal
          title={t('printer.actions.start-print.confirm.title.wrong-filament')}
          body={t('printer.actions.start-print.confirm.body.wrong-filament')}
          onCancel={() => {
            resetFilamentModal()
          }}
          onConfirm={() => {
            startPrint()
            resetFilamentModal()
          }}
        />
      )}

      {showConfirmModal && (
        <ConfirmPrintModal
          isLoading={isLoading}
          icon={icon}
          isSla={isSla}
          onCancel={() => {
            resetFilamentModal()
            setShowConfirmModal(false)
          }}
          onConfirm={startPrint}
        />
      )}
    </>
  )
}
