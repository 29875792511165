import { faChevronUp } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div<{ bottomOffset: number }>`
  position: fixed;
  cursor: pointer;
  right: 1rem;
  bottom: ${({ bottomOffset }) => (bottomOffset ? `${bottomOffset}px` : '1rem')};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: grey;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  z-index: 99;

  :hover {
    background-color: var(--color-primary);
    transition: background 400ms;
  }
`

export type ScrollToTopProps = {
  bottomOffset?: number
  smooth?: boolean
}

export function ScrollToTop({ bottomOffset = 55, smooth = true }: ScrollToTopProps) {
  const [visible, setVisible] = useState(false)

  const onScroll = () => {
    if (window.scrollY === 0) {
      setVisible(false)
    } else {
      setVisible(true)
    }
  }
  useEffect(() => {
    document.addEventListener('scroll', onScroll)

    return () => document.removeEventListener('scroll', onScroll)
  }, [])

  function handleBackToTop() {
    if (smooth) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    } else {
      document.documentElement.scrollTop = 0
    }
  }

  return visible ? (
    <div onClick={handleBackToTop}>
      <Wrapper bottomOffset={bottomOffset}>
        <FontAwesomeIcon icon={faChevronUp} color="white" size="lg" />
      </Wrapper>
    </div>
  ) : null
}
