import { Time } from '../../../helpers/time'
import * as S from './styled'

export function Timestamp({ timestamp }: { timestamp: number }) {
  return (
    <S.Timestamp className="ml-auto">
      <Time unixTimestamp={timestamp} />
    </S.Timestamp>
  )
}
