import { stringify } from 'query-string'

import { ICreatedFilament, IFilament, IFilamentsRequestQuery, IFilamentsResponse } from '../../types/filament'
import { RequestFactory } from '../../types/sdk'

export function createModule(rf: RequestFactory) {
  return {
    getFilaments: (query: IFilamentsRequestQuery = {}) =>
      rf.get<IFilamentsResponse>(`/app/filaments?${stringify(query)}`),

    getFilament: (filamentId: number) => rf.get<ICreatedFilament>(`/app/filaments/${filamentId}`),

    createFilament: (filament: IFilament) => rf.post<ICreatedFilament>('/app/filaments', filament),

    modifyFilament: (filamentId: number, filament: IFilament) => rf.put(`/app/filaments/${filamentId}`, filament),

    deleteFilament: (filamentId: number) => rf.delete(`/app/filaments/${filamentId}`)
  }
}
