import { scaleLinear } from 'd3-scale'
import { CSSProperties, MouseEvent, useCallback, useState } from 'react'
import useMeasure, { RectReadOnly } from 'react-use-measure'

import { AvailablePrinterTypes } from '../../../api/types/printer'
import { IVector2, IVector3 } from '../../../api/types/vector'
import { Distance } from '../../helpers/distance'
import * as S from './Bed.styled'
import { BedXL } from './BedXL'
import { DefaultBed } from './DefaultBed'
import { MiniBed } from './MiniBed'

type Props = {
  printerType: string
  disabled?: boolean
  currentPosition: IVector3
  pendingPosition?: IVector2
  onClick: (position: IVector2) => void
}

const wrapperStyle: { [key: string]: string | number } = { position: 'relative', pointerEvents: 'none' }

// Calculate grid overlay size
const miniGridOverlaySize = {
  zeroX: 398.41, // grid X offset inside SVG
  zeroY: 760.21 // grid Y offset inside SVG
}
const defaultOverlaySize = {
  zeroX: 18.11,
  zeroY: 159.4
}

const XLGridOverlaySize = {
  zeroX: 300.41,
  zeroY: 1480
}

function getOverlayPosition(boundsWidth: number, printerTypeName?: string) {
  let viewboxWidth: number
  if (printerTypeName === AvailablePrinterTypes.Original_Prusa_MINI) viewboxWidth = 17948.41
  else if (printerTypeName === AvailablePrinterTypes.Original_Prusa_XL) viewboxWidth = 36499.908
  else viewboxWidth = 2991

  const scale = boundsWidth / viewboxWidth

  if (printerTypeName === AvailablePrinterTypes.Original_Prusa_MINI) {
    return {
      top: miniGridOverlaySize.zeroY * scale,
      left: miniGridOverlaySize.zeroX * scale
    }
  }
  if (printerTypeName === AvailablePrinterTypes.Original_Prusa_XL) {
    return {
      top: XLGridOverlaySize.zeroY * scale,
      left: XLGridOverlaySize.zeroX * scale
    }
  }
  return {
    top: defaultOverlaySize.zeroY * scale,
    left: defaultOverlaySize.zeroX * scale
  }
}

const printersMaxWidthHeight = {
  DEFAULT: {
    MAX_WIDTH: 250,
    MAX_HEIGHT: 210
  },
  [AvailablePrinterTypes.Original_Prusa_MINI]: {
    MAX_WIDTH: 180,
    MAX_HEIGHT: 180
  },
  [AvailablePrinterTypes.Original_Prusa_XL]: {
    MAX_WIDTH: 360,
    MAX_HEIGHT: 360
  }
}

function getMaxWidthAndHeight(printerType: string) {
  if (printerType === AvailablePrinterTypes.Original_Prusa_MINI)
    return printersMaxWidthHeight[AvailablePrinterTypes.Original_Prusa_MINI]
  if (printerType === AvailablePrinterTypes.Original_Prusa_XL)
    return printersMaxWidthHeight[AvailablePrinterTypes.Original_Prusa_XL]
  return printersMaxWidthHeight.DEFAULT
}

function getRealXYPosition(size: ISize, bounds: RectReadOnly, e: MouseEvent): IVector2 {
  const { top, left, width, height } = bounds
  const posX = e.clientX - left
  const posY = e.clientY - top

  // Create scales and scale to the real dimensions
  const xScale = scaleLinear().domain([0, width]).range([0, size.MAX_WIDTH])
  const yScale = scaleLinear().domain([0, height]).range([0, size.MAX_HEIGHT])

  const scaledX = xScale(posX)
  const scaledY = yScale(posY)

  // Round values to nearest multiple of 10
  const roundedX = Math.round(scaledX / 10) * 10
  const roundedY = Math.round(scaledY / 10) * 10

  // Transpose to real world coordinates
  const realX = roundedX
  const realY = Math.abs(size.MAX_HEIGHT - roundedY)
  return {
    x: realX,
    y: realY
  }
}

type ISize = {
  MAX_WIDTH: number
  MAX_HEIGHT: number
}

// Translate world coordinates to relative
function getMarkerPosition(size: ISize, position: IVector2, offset: IVector2 = { x: 0, y: 0 }) {
  return {
    left: `calc(${(position.x / size.MAX_WIDTH) * 100}%  - ${S.MARKER_SIZE / 2}px + ${offset.x}px)`,
    top: `calc(${(1 - position.y / size.MAX_HEIGHT) * 100}% - ${S.MARKER_SIZE / 2}px + ${offset.y}px)`
  }
}

export const Bed = (props: Props) => {
  const { onClick, disabled, currentPosition, pendingPosition, printerType } = props
  const [bedAreaRef, bounds] = useMeasure({ scroll: true })
  const [hoverXY, setHoverXY] = useState<IVector2>()
  const [isOver, setIsOver] = useState(false)
  const size = getMaxWidthAndHeight(printerType)

  // TODO add popper for current position?
  const onMove = useCallback(
    (e: MouseEvent) => {
      if (!disabled) {
        const pos = getRealXYPosition(size, bounds, e)
        setHoverXY(pos)
      }
    },
    [bounds, disabled, size]
  )

  const onGridClick = useCallback(
    (e: MouseEvent) => {
      if (!disabled) {
        const pos = getRealXYPosition(size, bounds, e)
        onClick(pos)
      }
    },
    [bounds, disabled, onClick, size]
  )

  const onBedPointerEnter = useCallback(() => setIsOver(true), [])
  const onBedPointerLeave = useCallback(() => setIsOver(false), [])

  const bedOverlayStyle: CSSProperties = {
    height: bounds.height,
    width: bounds.width,
    background: disabled ? 'rgba(165, 165, 165, 0.4)' : undefined,
    position: 'absolute',
    ...getOverlayPosition(bounds.width, printerType)
  }

  const renderHoverMarker = () => {
    if (!isOver || !hoverXY) {
      return null
    }
    const markerPosition = getMarkerPosition(size, hoverXY)

    return (
      <>
        <S.Marker className="fade-in" style={markerPosition}>
          <S.MarkerInner />
        </S.Marker>
        <S.MarkerLabel style={getMarkerPosition(size, hoverXY, { x: S.MARKER_SIZE + S.MARKER_SIZE / 8, y: 0 })}>
          <S.MarkerAxisLabel>X</S.MarkerAxisLabel>
          <Distance value={hoverXY.x} />
          <S.MarkerSeparator />
          <S.MarkerAxisLabel>Y</S.MarkerAxisLabel>
          <Distance value={hoverXY.y} />
        </S.MarkerLabel>
      </>
    )
  }

  const renderBed = () => {
    if (printerType === AvailablePrinterTypes.Original_Prusa_MINI) {
      return (
        <MiniBed
          bedAreaRef={bedAreaRef}
          onMouseMove={onMove}
          onPointerEnter={onBedPointerEnter}
          onPointerLeave={onBedPointerLeave}
          onClick={onGridClick}
        />
      )
    }

    if (printerType === AvailablePrinterTypes.Original_Prusa_XL) {
      return (
        <BedXL
          bedAreaRef={bedAreaRef}
          onMouseMove={onMove}
          onPointerEnter={onBedPointerEnter}
          onPointerLeave={onBedPointerLeave}
          onClick={onGridClick}
        />
      )
    }

    return (
      <DefaultBed
        bedAreaRef={bedAreaRef}
        onMouseMove={onMove}
        onPointerEnter={onBedPointerEnter}
        onPointerLeave={onBedPointerLeave}
        onClick={onGridClick}
      />
    )
  }

  return (
    <div style={wrapperStyle}>
      <div style={bedOverlayStyle}>
        <S.MarkerContainer>
          <S.Marker className="fade-in" style={getMarkerPosition(size, currentPosition)}>
            <S.MarkerInner />
          </S.Marker>
          {pendingPosition && (
            <S.Marker className="fade-in" style={getMarkerPosition(size, pendingPosition)}>
              <S.MarkerInner $animated />
            </S.Marker>
          )}
          {renderHoverMarker()}
        </S.MarkerContainer>
      </div>
      <div className="bed-container">{renderBed()}</div>
    </div>
  )
}
