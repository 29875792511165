import { ITreeFolder } from '../../../../api/types/file'
import { SvgIcon } from '../../../common/SvgIcon'
import { FolderRowLink } from '../../overview/JobLayout'
import * as S from './styled'
import { Timestamp } from './Timestamp'
import { getTeamFolderUrl } from './utils'

type Props = {
  folder: ITreeFolder
}

export function TeamFolderRow(props: Props) {
  const { folder } = props

  return (
    <FolderRowLink className="align-items-center" to={getTeamFolderUrl(folder.id)}>
      <S.ImageContainer>
        <SvgIcon size={60} title={folder.name} icon="folderTeamIcon" />
      </S.ImageContainer>
      <S.FolderContent>
        <div className="d-flex align-items-center">
          <S.ItemName>{folder.name}</S.ItemName>
        </div>
        <S.FolderDescription>{folder.m_timestamp && <Timestamp timestamp={folder.m_timestamp} />}</S.FolderDescription>
      </S.FolderContent>
    </FolderRowLink>
  )
}
