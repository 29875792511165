import { useTranslation } from 'react-i18next'

import { WithTooltip } from '../common/WithTooltip'

export function UncertaintySign({ printerSpeed }: { printerSpeed?: number }) {
  const { t } = useTranslation()

  if (!printerSpeed || printerSpeed === 100) {
    return null
  }

  return (
    <WithTooltip
      id="time-remaining-speed-tooltip"
      title={t('remaining-time.estimated-due-to-speed', { speed: printerSpeed })}
      placement="top"
    >
      <span>&nbsp;?</span>
    </WithTooltip>
  )
}
