import { useTranslation } from 'react-i18next'

import { IFileDetailResponse } from '../../../../api/types/file'
import { getLongFileName } from '../../../../helpers/files'
import { DeleteCloudFileAction } from '../../actions/DeleteCloudFileAction'
import { DownloadFileAction } from '../../actions/DownloadFileAction'
import { RenameFileAction } from '../../actions/RenameFileAction'
import { StartPrintFromCloudAction } from '../../actions/StartPrintFromCloudAction'

type Props = {
  file: IFileDetailResponse
  onDelete?: () => void
  onRename?: () => void
  teamId: number | string
  basicOptions?: boolean
}

export function ConnectFileActions({ file, onDelete, onRename, basicOptions, teamId }: Props) {
  const { t } = useTranslation()
  const fileName = getLongFileName(file)

  return (
    <>
      <StartPrintFromCloudAction file={file} label={t('printer.actions.start-print.label')} teamId={Number(teamId)} />

      <StartPrintFromCloudAction
        file={file}
        label={t('printer.actions.add-to-queue.label')}
        toQueue
        teamId={Number(teamId)}
      />

      {!basicOptions && (
        <>
          <DownloadFileAction
            teamId={file.team_id}
            hash={file.hash}
            name={fileName}
            uploaded={file.uploaded}
            size={file.size}
          />

          {onRename ? (
            <RenameFileAction teamId={file.team_id} hash={file.hash} name={fileName} onSuccess={onRename} />
          ) : null}

          <DeleteCloudFileAction
            file={file}
            label={t('button.delete')}
            hash={file.hash}
            teamId={file.team_id}
            name={fileName}
            onSuccess={onDelete}
            printerTeamId={Number(teamId)}
          />
        </>
      )}
    </>
  )
}
