import { PrinterUuid } from '../../api/types/printer'
import { usePrinter } from '../../context/printerContext'
import { useStorageLocations, useStorageLocationsByUUid } from './useStorageLocations'

export function usePrinterStorage() {
  const { printer } = usePrinter()
  const { locations } = useStorageLocations(printer)
  const root = locations[0] // first is primary
  return root?.mountpoint || ''
}

export function usePrinterStorageByUuid(printerUuid: PrinterUuid) {
  const { locations } = useStorageLocationsByUUid(printerUuid)
  const root = locations[0] // first is primary
  return root?.mountpoint || ''
}
