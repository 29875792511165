import styled from 'styled-components'

export const Board = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1rem 0 3rem;
`

export const ColumnTitle = styled.h2`
  font-size: 1rem;
  text-align: center;
`

export const ColumnContent = styled.div<{ $lastColumn?: boolean }>`
  display: flex;
  flex-direction: column;
  border: 1px solid var(--border-secondary);
  border-right-width: ${(p) => (p.$lastColumn ? '1px' : '0')};
  padding: 0.7rem 1rem;
  gap: 0.7rem;
  flex: 1 0 100%;
  min-height: 20rem;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 0;

  :last-child ${ColumnContent} {
    border-right-width: 1px;
  }
`
