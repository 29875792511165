import { stringify, StringifyOptions } from 'query-string'
import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router'

import { IConnectState } from '../../../api/types/state'
import { usePrintersStateContext } from '../../../context/printersStateContext'
import { useQueryParams } from '../../../hooks/useQueryParams'
import { REMAINING_TIME } from '../filters/Sorting'

type StateFilter = { key: 'states'; value: IConnectState[] }
type TeamFilter = { key: 'teamId'; value?: number | string }
type GroupFilter = { key: 'groupId'; value?: number | string }
type SortBy = { key: 'sort_by'; value?: string }

export type FilterParams = StateFilter | TeamFilter | GroupFilter | SortBy

const STRINGIFY_OPTIONS: StringifyOptions = {
  arrayFormat: 'bracket',
  encode: false
}

// Stores filter values to the URL and to the context, so we can create links with filter as well
export function useFilterHandlers() {
  const navigate = useNavigate()
  const query = useQueryParams({ decode: false })
  const { pathname } = useLocation()
  const {
    setFilter: setContextFilter,
    resetFilter: resetContextFilter,
    resetSort: resetContextSort
  } = usePrintersStateContext()

  const setFilter = useCallback(
    (params: FilterParams) => {
      const newQuery = { ...query, [params.key]: params.value }
      setContextFilter(params)
      navigate(`${pathname}?${stringify(newQuery, STRINGIFY_OPTIONS)}`, { replace: true })
    },
    [navigate, pathname, query, setContextFilter]
  )

  const reset = useCallback(() => {
    const newQuery = { ...query, states: undefined, teamId: undefined, groupId: undefined }
    resetContextFilter()
    navigate(`${pathname}?${stringify(newQuery, STRINGIFY_OPTIONS)}`, { replace: true })
  }, [navigate, pathname, query, resetContextFilter])

  const resetSort = useCallback(
    (params: FilterParams) => {
      const newQuery = { ...query, sort_by: REMAINING_TIME }
      resetContextSort(params)
      navigate(`${pathname}?${stringify(newQuery, STRINGIFY_OPTIONS)}`, { replace: true })
    },
    [navigate, pathname, query, resetContextSort]
  )

  return {
    setFilter,
    reset,
    resetSort
  }
}
