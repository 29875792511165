import { useTranslation } from 'react-i18next'

import { IJobState, states } from '../../api/types/job'
import { Tag } from './Tag'

export function useLabels() {
  const { t } = useTranslation()

  return {
    [IJobState.PRINTING]: t('jobs.state.printing'),
    [IJobState.PAUSED]: t('jobs.state.paused'),
    [IJobState.FIN_STOPPED]: t('jobs.state.fin-stopped'),
    [IJobState.FIN_ERROR]: t('jobs.state.fin-error'),
    [IJobState.FIN_OK]: t('jobs.state.fin-ok'),
    [IJobState.UNKNOWN]: t('jobs.state.unknown')
  }
}

type Props = {
  state: IJobState
  inverse?: boolean
}

export function JobStateTag(props: Props) {
  const { state, inverse = false } = props
  const labels = useLabels()

  const id = labels[state] || labels[IJobState.UNKNOWN]
  const jobStateProperties = states.find((s) => s.state === state)
  const backgroundColor = jobStateProperties?.primaryColor

  return <Tag title={id} baseColor={backgroundColor} inverse={inverse} />
}
