import { IToast } from '../interfaces/toast'

export enum ToastActionTypes {
  ADD = 'ADD',
  REMOVE = 'REMOVE'
}

type AddToastAction = {
  type: ToastActionTypes.ADD
  toast: IToast
}

type RemoveToastAction = {
  type: ToastActionTypes.REMOVE
  toastId: string
}

export type ToastAction = AddToastAction | RemoveToastAction

export const addAction = (toast: IToast): AddToastAction => ({
  type: ToastActionTypes.ADD,
  toast
})

export const removeAction = (toastId: string): RemoveToastAction => ({
  type: ToastActionTypes.REMOVE,
  toastId
})
