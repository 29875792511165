// eslint-disable-next-line import/no-unresolved
import '@prusaconnect/ui/dist/style.css'
import './css/main.scss'

import createApiClient from '@prusaconnect/api/client/apiClient'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

import { createApiClient as createOldApiClient } from './api/createClient'
import { App } from './App'
import { config } from './config'
import { initializeSentry } from './helpers/initializeSentry'
import { getInstance, initI18n } from './i18n'
import { getIntialLanguage } from './interfaces/locale'

const language = getIntialLanguage()

const oldApiClient = createOldApiClient(config.hostname)
const apiClient = createApiClient(config.hostname)

if (!AFS_ENABLED) {
  initializeSentry()
}

// Init language
initI18n(language).then(() => {
  const i18n = getInstance()
  const container = document.getElementById('root')
  const root = createRoot(container as HTMLElement)
  root.render(
    <StrictMode>
      <App i18n={i18n} oldApiClient={oldApiClient} apiClient={apiClient} />
    </StrictMode>
  )
})
