import { ReactNode } from 'react'
import styled from 'styled-components'

import { SvgIcon } from '../common/SvgIcon'

const Toggle = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 0.875rem;
  &:hover {
    color: var(--color-primary);
  }

  svg:last-child {
    height: 12px;
    margin-left: 0.25rem;
  }
`

type Props = { children?: ReactNode; className?: string }

export function NavigationDropdownToggle(props: Props) {
  return (
    <Toggle className={props.className}>
      {props.children}
      <SvgIcon icon="arrowDownIcon" size={24} />
    </Toggle>
  )
}
