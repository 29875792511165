import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { Form } from 'react-bootstrap'

import { useApiClient } from '../../../../api/react'
import { IEditFileAttrs } from '../../../../api/types/file'
import { useErrorHandler } from '../../../../hooks/errors/useErrorHandler'
import { useMaxFolderFileNameLength } from '../../../../hooks/useMaxFolderFileNameLength'
import { useFileNameRules } from '../../../../hooks/useValidationRules'
import { EditableString } from '../../settings/base/EditableInput'

type Props = {
  value: string
  teamId: number
  hash: string
  onSuccess?: () => void
}

export function EditableFileName(props: Props) {
  const { value, teamId, hash, onSuccess } = props
  const [overwrite, setOverwrite] = useState(false)
  const api = useApiClient()
  const errorHandler = useErrorHandler()
  const maxLength = useMaxFolderFileNameLength()
  const formik = useFileNameRules(value, maxLength, true, true, true)

  const renameFile = useMutation(
    (params: IEditFileAttrs) =>
      api.app.files.updateFile(
        teamId,
        hash,
        {
          name: params.name
        },
        overwrite
      ),
    {
      onSuccess: () => {
        onSuccess?.()
      },
      onError: (error: any) => {
        errorHandler(error.response, error)
        if (error.code === 'CONFLICT_CONNECT_FILE') {
          setOverwrite(true)
        }
      }
    }
  )

  const save = (value: string) => renameFile.mutate({ name: value })

  const valueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(event)
  }

  return (
    <div className="w-full">
      <EditableString
        value={value || ''}
        isLoading={renameFile.isLoading || formik.isSubmitting}
        isSuccess={renameFile.isSuccess}
        onSave={save}
        onCancel={() => setOverwrite(false)}
        overwrite={overwrite}
        mutation={overwrite ? renameFile : undefined}
        onChange={(event) => {
          setOverwrite(false)
          valueChange(event)
        }}
        name="filename"
      />
      <Form.Control.Feedback type="invalid">{formik.errors.filename}</Form.Control.Feedback>
    </div>
  )
}
