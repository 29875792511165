import { useConfig } from '@prusaconnect/api/services/useConfig'
import Markdown from 'markdown-to-jsx'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useTheme } from 'styled-components'

import { INotification, INotificationName } from '../../api/types/notifications'
import { getJobHash } from '../../helpers/jobs'
import { ITimetype } from '../../interfaces/time'
import { FileHashPreview } from '../common/FileHashPreview'
import { SvgIcon } from '../common/SvgIcon'
import { EmptyAvatar } from '../helpers/Avatar'
import { Time } from '../helpers/time'
import { NotificationIconStyled, NotificationsHeader, StyledNotification } from './Notifications.styled'
import { useNotificationBody } from './useNotificationBody'

enum INotificationType {
  JOB,
  PRINTER,
  CONNECT,
  INCIDENT,
  NOTIFICATION,
  TRANSFER
}

function getNotificationType(name: INotificationName): INotificationType {
  switch (name) {
    case INotificationName.JOB_FINISHED:
    case INotificationName.PRINTER_PAUSED:
    case INotificationName.PRINTER_PRINTING:
    case INotificationName.PRINTER_RESUMED:
      return INotificationType.JOB
    case INotificationName.FILAMENT_CHANGE_IN:
    case INotificationName.FW_UPDATE:
    case INotificationName.LINK_UPDATE:
    case INotificationName.PRINTER_ATTENTION:
    case INotificationName.PRINTER_ERROR:
      return INotificationType.PRINTER
    case INotificationName.INCIDENT_OFFLINE:
    case INotificationName.INCIDENT_OTHER:
      return INotificationType.INCIDENT
    case INotificationName.INVITATION:
      return INotificationType.CONNECT
    case INotificationName.TEST_NOTIFICATION:
      return INotificationType.NOTIFICATION
    case INotificationName.TRANSFER_ABORTED:
      return INotificationType.TRANSFER
  }
}

function getNotificationLink(notification: INotification) {
  const jobId = notification.data?.job_id
  const uuid = notification.data?.printer_uuid
  const notificationType = getNotificationType(notification.name)

  switch (notificationType) {
    case INotificationType.JOB:
      if (!jobId || !uuid) {
        return ''
      }
      return `/printer/${uuid}/jobs/${jobId}`
    case INotificationType.PRINTER:
      if (!uuid) {
        return ''
      }
      return `/printer/${uuid}`
    case INotificationType.CONNECT:
      if (notification.name === INotificationName.INVITATION && notification.data?.invitation_code) {
        return `/invitation/${notification.data?.invitation_code}`
      }
      return ''
    case INotificationType.INCIDENT:
      if (!uuid) {
        return ''
      }
      return `/printer/${uuid}`
    case INotificationType.NOTIFICATION:
      return '/notifications/settings'
    case INotificationType.TRANSFER:
      if (!uuid) {
        return ''
      }
      return `/printer/${uuid}/transfers`
    default:
      return ''
  }
}

function countUnreadNotifications(notifications: INotification[]) {
  return notifications.filter((notification) => !notification.read).length
}

function NotificationIcon({ notification }: { notification: INotification }) {
  const type = getNotificationType(notification.name)
  const config = useConfig()
  const theme = useTheme()

  if (type === INotificationType.JOB && notification.data?.preview_url) {
    return <NotificationIconStyled url={notification.data?.preview_url} />
  }

  if (type === INotificationType.JOB && notification.data) {
    return (
      <NotificationIconStyled>
        <FileHashPreview hash={getJobHash(notification.data)} />
      </NotificationIconStyled>
    )
  }

  if (
    notification.name === INotificationName.PRINTER_ATTENTION ||
    notification.name === INotificationName.INCIDENT_OTHER
  ) {
    return (
      <NotificationIconStyled>
        <SvgIcon icon={theme.isDark ? 'attentionInverseIcon' : 'attentionIcon'} size={30} />
      </NotificationIconStyled>
    )
  }

  if (notification.name === INotificationName.INCIDENT_OFFLINE) {
    return (
      <NotificationIconStyled>
        <SvgIcon icon="noWifiIconIcon" size={30} />
      </NotificationIconStyled>
    )
  }

  if (notification.name === INotificationName.INVITATION && config) {
    return (
      <NotificationIconStyled url={`${config.auth.avatar_server_url}${notification.data?.invitor_avatar}`}>
        {!notification.data?.invitor_avatar && <EmptyAvatar />}
      </NotificationIconStyled>
    )
  }

  if (notification.name === INotificationName.FW_UPDATE) {
    return (
      <NotificationIconStyled>
        <SvgIcon icon="firmwareIcon" size={30} />
      </NotificationIconStyled>
    )
  }

  if (notification.name === INotificationName.LINK_UPDATE) {
    return (
      <NotificationIconStyled>
        <SvgIcon icon={theme.isDark ? 'linkMicroSdInverseIcon' : 'linkMicroSdIcon'} size={30} />
      </NotificationIconStyled>
    )
  }

  if (notification.name === INotificationName.FILAMENT_CHANGE_IN) {
    return (
      <NotificationIconStyled>
        <SvgIcon icon={theme.isDark ? 'spoolWhiteColorIcon' : 'spoolColorIcon'} size={30} />
      </NotificationIconStyled>
    )
  }

  if (notification.name === INotificationName.TRANSFER_ABORTED) {
    return (
      <NotificationIconStyled>
        <SvgIcon icon="downloadArrowColorIcon" size={30} />
      </NotificationIconStyled>
    )
  }

  if (notification.name === INotificationName.PRINTER_ERROR) {
    return (
      <NotificationIconStyled>
        <SvgIcon icon={theme.isDark ? 'stateErrorInverseIcon' : 'stateErrorIcon'} size={30} />
      </NotificationIconStyled>
    )
  }
  return <NotificationIconStyled />
}

function NotificationRow({ notification, setRead }: { notification: INotification; setRead: (id: number) => void }) {
  const notificationBody = useNotificationBody(notification)

  return notificationBody ? (
    <Link
      to={getNotificationLink(notification)}
      state={{
        incidentModalIsOpened: getNotificationType(notification.name) === INotificationType.INCIDENT
      }}
      onClick={() => setRead(notification.id)}
    >
      <StyledNotification read={!!notification.read}>
        <NotificationIcon notification={notification} />
        <div className="notification-body">
          <Markdown>{notificationBody}</Markdown>{' '}
          <span style={{ opacity: 0.4 }}>
            <Time unixTimestamp={notification.created} type={ITimetype.DISTANCE_TO_NOW} />
          </span>
        </div>
      </StyledNotification>
    </Link>
  ) : null
}

type Props = {
  notifications: INotification[]
  setRead: (id: number) => void
  setAllRead: () => void
}

export function Notifications({ notifications, setRead, setAllRead }: Props) {
  const { t } = useTranslation()

  return (
    <>
      <NotificationsHeader>
        <h2>{t('notifications.header', 'Notifications')}</h2>
        <div className="button-container">
          <button
            type="button"
            className={`set-all-read ${countUnreadNotifications(notifications) === 0 && 'disabled'}`}
            data-exception-element="true"
            onClick={() => setAllRead()}
          >
            {t('notifications.mark-all-read', 'Mark All Read')}
          </button>
          <button type="button">
            <Link to="/notifications/settings">{t('notifications.settings', 'Settings')}</Link>
          </button>
        </div>
      </NotificationsHeader>

      {notifications.map((notification) => (
        <NotificationRow key={notification.id} notification={notification} setRead={setRead} />
      ))}
    </>
  )
}
