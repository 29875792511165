import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { IPrinterSimpleView, IPrinterTransfer, TransferType } from '../../api/types/printer'
import { IConnectState } from '../../api/types/state'
import { ContainerTitle } from '../common/ContainerTitle'
import { CONTAINER_MARGIN_BOTTOM } from './overview/JobLayout'
import { TransferRow } from './TransferRow'

type Props = {
  printerTransfer?: IPrinterTransfer
  printer: IPrinterSimpleView
  printerName?: boolean
}

export const TitleLink = styled(Link)`
  font-size: 0.6875rem;
  text-transform: uppercase;
  color: var(--color-text);
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`

const Row = styled.div`
  margin-bottom: ${CONTAINER_MARGIN_BOTTOM}px;
`

const ContentHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export function CurrentTransfer(props: Props) {
  const { t } = useTranslation()
  const location = useLocation()
  const { printerTransfer, printer, printerName } = props

  if (
    !printerTransfer ||
    printerTransfer.type === TransferType.NO_TRANSFER ||
    printer.connect_state === IConnectState.OFFLINE
  ) {
    return null
  }

  return (
    <>
      <ContentHeading>
        <ContainerTitle>{t('printer.file.current-transfer')}</ContainerTitle>
        {!location.pathname.endsWith(`/transfers`) && (
          <TitleLink to={`/printer/${printer.uuid}/transfers`} title={t('transfers.manager')} className="text-right">
            {t('transfers.manager')}
          </TitleLink>
        )}
      </ContentHeading>
      <Row>
        <TransferRow printerTransfer={printerTransfer} isCurrentTransfer printer={printer} printerName={printerName} />
      </Row>
    </>
  )
}
