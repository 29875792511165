import styled from 'styled-components'

import { Label as ParamsLabel, Value as ParamsValue } from '../overview/styled'

export const Label = styled(ParamsLabel)`
  display: block;
  :first-letter {
    text-transform: uppercase;
  }
`

export const Value = styled(ParamsValue)``
