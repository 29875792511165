import { useTranslation } from 'react-i18next'

import { NotificationStatus } from '../../../api/types/notifications'
import { SvgIcon } from '../../common/SvgIcon'
import { WithTooltip } from '../../common/WithTooltip'
import * as S from './NotificationsTabs.styled'

export function EndpointStatus(props: { status?: NotificationStatus }) {
  const { t } = useTranslation()

  return (
    <S.StatusCol>
      {props.status === 'NOK' && (
        <WithTooltip id="endpoint-nok" title={t('notifications.settings.invalid-endpoint')} placement="top">
          <div title={t('notifications.settings.invalid-endpoint')}>
            <SvgIcon icon="warningIcon" size={35} />
          </div>
        </WithTooltip>
      )}
      {props.status === 'OK' && <SvgIcon icon="okIcon" size={35} />}
    </S.StatusCol>
  )
}
