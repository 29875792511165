import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import { IGroupPattern } from '../../../../api/types/groups'
import { IGrid } from '../../../../interfaces/grid'
import { SvgIcon } from '../../../common/SvgIcon'
import { ICON_SIZE, Img } from '../addGroupForm'
import { DimensionSelectorOverlay } from './dimensionSelectorOverlay'

type Props = {
  highlighted: boolean
  id: string
  onClick?: () => void
  onSelected: (size: IGrid) => void
  pattern: IGroupPattern
  selectedSize: IGrid
  showSizePopup: boolean
  style?: React.CSSProperties
}

export function DimensionSelector(props: Props) {
  const [show, setShow] = useState(false)
  const iconRef = React.useRef<HTMLDivElement>(null)
  const { t } = useTranslation()
  const { colors } = useTheme()

  const iconClick = () => {
    setShow(!show)
    if (props.onClick) props.onClick()
  }

  const icon = props.pattern === IGroupPattern.SHIFTED ? 'gridShiftedIcon' : 'gridIcon'
  return (
    <>
      <Img ref={iconRef} draggable={false} $highlighted={props.highlighted} onClick={iconClick} style={props.style}>
        <SvgIcon
          icon={icon}
          fill={colors.primary}
          size={ICON_SIZE}
          title={
            props.pattern === IGroupPattern.SHIFTED
              ? t('printer.group.pattern.shifted-grid')
              : t('printer.group.pattern.grid')
          }
        />
      </Img>

      <DimensionSelectorOverlay
        iconRef={iconRef.current}
        id={props.id}
        onClose={() => setShow(false)}
        onSelected={props.onSelected}
        pattern={props.pattern}
        selectedSize={props.selectedSize}
        show={show}
        showSizePopup={props.showSizePopup}
      />
    </>
  )
}
