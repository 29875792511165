import Markdown from 'markdown-to-jsx'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import { SvgIcon } from '../components/common/SvgIcon'

const ICON_SIZE = 64

export function InvitationDeclinedPage(props: { username: string; team: string }) {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <div className="text-center mt-5">
      <h1 className="h4 font-weight-light">{t('team.invitation.heading')}</h1>
      <SvgIcon icon="nokIcon" size={ICON_SIZE} fill={theme.colors.icons.primary} className="mt-4" />
      <h3 className="font-weight-normal lead text-lg mx-2 mt-4">
        <Markdown>{t('team.invitation.declined', { user: props.username, team: props.team })}</Markdown>
      </h3>
    </div>
  )
}
