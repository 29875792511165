import { Form } from 'react-bootstrap'
import styled from 'styled-components'

import { textEllipsis } from '../../helpers/styled'

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--color-text);
  border-top: 1px solid var(--border-secondary);
  border-bottom: 1px solid var(--border-secondary);
  margin-bottom: 0.5rem;
  padding: 0 1rem;
  min-height: 50px;
  flex-wrap: wrap;
`

export const Fulltext = styled.div<{ $empty?: boolean }>`
  display: flex;
  input {
    ${textEllipsis};
  }

  ${({ $empty }) =>
    $empty
      ? `
      #suggester {
        display: none !important;
      }
    `
      : `input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0;
      }`}
`

export const FormGroup = styled(Form.Group)``

export const Right = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  > div:not(:last-child) {
    margin-right: 2rem !important;
  }
`

export const Children = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`

export const ClearButton = styled.span`
  display: flex;
  align-items: center;
  color: var(--color-text);
  padding: 0.375rem 0.5rem;
  border: 1px solid var(--border-secondary);
  border-radius: 0 0.25rem 0.25rem 0;
  border-left: 0;
  cursor: pointer;
`
