import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { IJob, IPlannedJob, IPrinterDetailJobInfo } from '../../api/types/job'
import { getJobDisplayPath, useJobTitle } from '../../hooks/useJobTitle'
import { textEllipsis } from '../helpers/styled'

const titleStyle = css`
  flex: 1;
  min-width: 0;
  ${textEllipsis};
  font-size: 1.125rem;
  font-weight: 700;
  color: var(--color-text);
  margin-top: 10px;
`

const TitleLink = styled(Link)`
  ${titleStyle}
`

export const Title = styled.div`
  ${titleStyle}
`

type Props = {
  job: IPlannedJob | IJob | IPrinterDetailJobInfo
  to?: string
}

export function JobTitle(props: Props) {
  const { job, to } = props

  const title = useJobTitle(job)

  if (to && job.id) {
    return (
      <TitleLink to={to} title={getJobDisplayPath(job)}>
        {title}
      </TitleLink>
    )
  }

  return <Title title={getJobDisplayPath(job)}>{title}</Title>
}
