import { IPager } from './pager'

export const MAX_TEMPLATE_HEIGHT = 10

export enum GcodeArgTypes {
  STRING = 'string',
  NUMBER = 'number',
  INTEGER = 'integer',
  BOOLEAN = 'boolean'
}

export type GcodeArgs = {
  type: GcodeArgTypes
  name: string
  default?: string
  unit?: string
  description?: string
}

export function isGcodeArg(value: unknown): value is GcodeArgTypes {
  return Object.values(GcodeArgTypes).includes(value as GcodeArgTypes)
}

export type MultiSelectOption = {
  value: string
  label: string
}

export type IGcodeTemplateFromResponse = {
  id: number
  printer_types: string[]
  printer_type_name?: string
  name: string
  team_id: number
  created?: number
  description: string
  template: string
  args: GcodeArgs[]
}

export type IGcodeTemplate = {
  id: number
  printer_types: string[]
  printer_type_name?: string
  name: string
  team_id: number
  created?: number
  description: string
  template: string
  args: GcodeArgs[]
}

export type INewGcodeTemplate = Omit<IGcodeTemplateFromResponse, 'id'>

export type IQuery = {
  printer_type?: string
  offset?: number
  limit?: number
}

export type IGcodeTemplatesResponse = {
  gcodes: IGcodeTemplateFromResponse[]
  pager?: IPager
}
