import { ReactNode } from 'react'

import { Container } from '../components/bootstrap/Container'
import { Game } from '../components/game/Game'
import { useBreakpoint } from '../helpers/useBreakpoint'

const PlainContainer = ({ children }: { children: ReactNode }) => <div>{children}</div>

export function GamePage() {
  const isSmOrLarge = useBreakpoint('sm')
  const Wrapper = isSmOrLarge ? Container : PlainContainer
  return (
    <Wrapper>
      <Game />
    </Wrapper>
  )
}
