import { useState } from 'react'
import { useTranslation } from 'react-i18next'

// import { Link } from 'react-router-dom'
import { formatPercents, formatSize } from '../../../helpers/formatters'
import { isDefined } from '../../../helpers/std'
import { ProgressBar } from '../../common/ProgressBar'
import * as S from './UsedStorageInfo.styled'

type Props = {
  className?: string
  free: number
  total?: number
  expand?: boolean
  isTeamInfo?: boolean
}

export function UsedStorageInfo(props: Props) {
  const { total, free, isTeamInfo = false } = props
  const { t } = useTranslation()
  const [showPercents, setShowPercents] = useState(true)

  const renderBar = () => {
    if (!isDefined(total)) {
      return null
    }
    let used = total - free
    // Avoid overflow
    if (used > total) {
      used = total
    }

    return (
      <S.Bar $expand={props.expand} onClick={() => setShowPercents((prev) => !prev)}>
        <ProgressBar
          now={used}
          max={total}
          label={showPercents ? formatPercents((used / total) * 100) : formatSize(used)}
        />
      </S.Bar>
    )
  }

  const renderInfo = () => {
    if (!isDefined(total)) {
      return t('printer.file.free-space-info', {
        free: formatSize(free)
      })
    }

    const used = total - free

    if (free < 0) {
      return t('printer.file.space-used', {
        free: formatSize(used)
      })
    }

    return t('printer.file.complete-space-info', {
      used: formatSize(used),
      free: formatSize(free),
      total: formatSize(total)
    })
  }

  return (
    <S.Container className={props.className} $isTeamInfo={isTeamInfo}>
      {isTeamInfo && (
        <S.Title>
          {t('storage.quota', 'Storage quota')}
          {/* <Link to="#">{t('upgrade', 'Upgrade')}</Link> */}
        </S.Title>
      )}
      {renderBar()}
      <S.Info>{renderInfo()}</S.Info>
    </S.Container>
  )
}
