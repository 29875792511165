import { IAgent, IAgentCommand, IAgentsResponse } from '../../types/agents'
import { IKwargs } from '../../types/commandQueue'
import { NoContent204, RequestFactory } from '../../types/sdk'

export function createModule(rf: RequestFactory) {
  return {
    getAgent: (agentId: number) => rf.get<IAgent>(`/app/agents/${agentId}`),

    getAgents: () => rf.get<IAgentsResponse>(`/app/agents`),

    addCommandToQueue: (agentId: number, command: IAgentCommand, kwargs: IKwargs = {}) =>
      rf.post<NoContent204>(`/app/agents/${agentId}/commands`, { command, kwargs })
  }
}
