import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { useApiClient } from '../../../api/react'
import { useCanControl } from '../../../context/permissionsStore'
import { useUploadContext } from '../../../context/uploadContext'
import useServiceResponseHandler from '../../../services/useServiceResponseHandler'
import { AdaptiveButton } from '../../common/AdaptiveButton/AdaptiveButton'
import { WithConfirm } from '../../common/ConfirmModal'

type Props = {
  uploadId: number
  teamId: number
  uniqueContextId: string
  onSuccess?: () => void
}

export function RemoveUploadAction(props: Props) {
  const { uploadId, teamId, uniqueContextId, onSuccess } = props
  const { t } = useTranslation()
  const { displaySuccess } = useServiceResponseHandler()
  const api = useApiClient()
  const canControl = useCanControl(teamId)
  const { remove } = useUploadContext(uniqueContextId)

  const { mutate, isLoading } = useMutation(() => api.app.transfers.abortUpload(uploadId), {
    onSuccess: () => {
      displaySuccess(t('transfers.queue.deleted.title'), t('transfers.queue.deleted.body'))
      onSuccess?.()

      remove(uploadId)
    }
  })

  return (
    <WithConfirm title={t('transfers.queue.delete.title')} body={t('transfers.queue.delete.body')}>
      {(confirm) => (
        <AdaptiveButton
          icon="nokIcon"
          trigger={confirm(mutate)}
          label={t('button.delete')}
          isLoading={isLoading}
          isAvailable
          isDisabled={!canControl}
          disabledTooltip={t('printer.tooltip.rights')}
        />
      )}
    </WithConfirm>
  )
}
