import { THINSP } from '../../helpers/formatters'
import { isNumber } from '../../helpers/std'

export const toFahrenheit = (c: number): number => (9 / 5) * c + 32
export const toCelsius = (f: number): number => (5 / 9) * (f - 32)
export const CELSIUS_SIGN = '°C'
export const FAHRENHEIT_SIGN = '°F'

export enum TemperatureUnits {
  CELSIUS = '°C',
  FAHRENHEIT = '°F'
}

type Props = {
  valueA?: number
  valueB?: number
  showZero?: boolean
  unit: TemperatureUnits
}

export function convert(value: number, sign: TemperatureUnits) {
  if (sign === TemperatureUnits.FAHRENHEIT) {
    return toFahrenheit(value)
  }
  return value
}

export function getParts(
  valueA: number | undefined,
  valueB: number | undefined,
  sign: TemperatureUnits,
  showZero?: boolean
) {
  const parts = []
  if (valueA || (showZero && isNumber(valueA))) {
    parts.push(convert(valueA, sign).toFixed(0))
  }
  if (valueA && valueB) {
    parts.push('/')
  }
  if (valueB || (showZero && isNumber(valueB))) {
    parts.push(convert(valueB, sign).toFixed(0))
  }
  if (parts.length > 0) {
    parts.push(sign)
  }
  return parts
}

/**
 * Component for displaying up to 2 temperature values
 * Example:
 *  1°C
 *  1/2°C
 */
export function Temperature(props: Props) {
  const { valueA, valueB, showZero, unit } = props

  const degreesCelsius = getParts(valueA, valueB, TemperatureUnits.CELSIUS, showZero).join(THINSP)
  const degreesFahrenheit = getParts(valueA, valueB, TemperatureUnits.FAHRENHEIT, showZero).join(THINSP)

  return (
    <span title={unit === TemperatureUnits.CELSIUS ? degreesFahrenheit : degreesCelsius}>
      {unit === TemperatureUnits.CELSIUS ? degreesCelsius : degreesFahrenheit}
    </span>
  )
}
