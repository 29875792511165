import { useConfig } from '@prusaconnect/api/services/useConfig'
import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'

import { AuthBackends } from '../api/types/config'
import { ButtonLink } from '../components/common/ButtonLink'

const backendsConfig = {
  [AuthBackends.CONNECT]: {
    to: '/login/connect',
    label: 'Connect'
  },
  [AuthBackends.PRUSA_AUTH]: {
    to: '/login/prusa-auth',
    label: 'Prusa Auth'
  }
}

export function LoginPage() {
  const config = useConfig()
  const { t } = useTranslation()

  if (!config) {
    // TODO show some error page since the app can't work without config
    return null
  }

  const { backends } = config.auth

  if (backends.length === 1) {
    const backend = backends[0]
    if (backend === AuthBackends.CONNECT) {
      return <Navigate to="/login/connect" replace />
    }

    if (backend === AuthBackends.PRUSA_AUTH) {
      return <Navigate to="/login/prusa-auth" replace />
    }

    // TODO unknown backend, show some error message?
    return null
  }

  return (
    <div className="form-signin">
      <h1 className="h3 text-center mb-4 font-weight-normal">{t('login.title')}</h1>
      {backends.map((backend) => {
        const backendKey = backend as keyof typeof backendsConfig
        const backendConfig = backendsConfig[backendKey]
        return (
          <ButtonLink key={backend} className="mt-2" to={backendConfig.to} block>
            {backendConfig.label}
          </ButtonLink>
        )
      })}
    </div>
  )
}
