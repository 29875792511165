import { useTranslation } from 'react-i18next'

import { usePrinter } from '../../../context/printerContext'
import { useCommandMutation } from '../../../hooks/commands/useCommandMutation'
import { LoadingButtonOutlined } from '../../common/LoadingButtonOutlined'
import { SvgIcon } from '../../common/SvgIcon'

export function HomeZButton() {
  const { t } = useTranslation()
  const { printer } = usePrinter()
  const homeMutation = useCommandMutation(printer, {
    command: 'HOME',
    successMessage: {
      title: t('printer.control.homeZ'),
      body: t('printer.control.homeZ.description')
    }
  })

  return (
    <LoadingButtonOutlined
      onClick={() => homeMutation.execute({ axis: 'Z' })}
      isLoading={homeMutation.isLoading}
      disabled={homeMutation.isLoading || !homeMutation.isAvailable}
    >
      <SvgIcon icon="homeColorIcon" size="24" className="m-0" />
    </LoadingButtonOutlined>
  )
}
