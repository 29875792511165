import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { usePrinter } from '../../../context/printerContext'
import { useCommandMutation } from '../../../hooks/commands/useCommandMutation'
import { usePrinterInState } from '../../../hooks/usePrinterInState'
import { PulsingTemperature } from '../../common/PulsingTemperature'
import { WithTooltip } from '../../common/WithTooltip'
import * as S from './ControlPanel.styled'
import { TemperatureDropdown } from './TemperatureDropdown'

const Section = styled.div`
  & + & {
    margin-top: 1rem;
  }
`

export function NozzleTemperature() {
  const { t } = useTranslation()
  const { printer } = usePrinter()
  const printerInState = usePrinterInState(printer.connect_state)

  const {
    execute: setNozzleTemperature,
    isAvailable: isNozzleTempAvailable,
    isLoading: isNozzleTempLoading
  } = useCommandMutation(printer, {
    command: 'SET_NOZZLE_TEMPERATURE',
    successMessage: {
      title: t('printer.control.nozzle'),
      body: t('printer.control.nozzle.description')
    }
  })

  const changeNozzleTemperature = useCallback(
    (temperature: number) => {
      setNozzleTemperature({ nozzle_temperature: temperature })
    },
    [setNozzleTemperature]
  )

  const renderNozzleDropdown = () => {
    return (
      <TemperatureDropdown
        type="nozzle"
        disabled={!isNozzleTempAvailable || isNozzleTempLoading}
        filament={printer.filament}
        onChange={changeNozzleTemperature}
      />
    )
  }

  return (
    <Section>
      <S.Param>
        <span>{t('printer.nozzle')}</span>
        {printer.temp ? (
          <PulsingTemperature value={printer.temp.temp_nozzle} targetValue={printer.temp.target_nozzle} />
        ) : (
          <span>--</span>
        )}
      </S.Param>
      {!isNozzleTempAvailable ? (
        <WithTooltip id="nozzle-tooltip" title={printerInState} placement="top">
          <div>{renderNozzleDropdown()}</div>
        </WithTooltip>
      ) : (
        renderNozzleDropdown()
      )}
    </Section>
  )
}

export function HeatbedTemperature() {
  const { t } = useTranslation()
  const { printer } = usePrinter()
  const printerInState = usePrinterInState(printer.connect_state)

  const {
    execute: setBedTemperature,
    isAvailable: isBedTempAvailable,
    isLoading: isBedTempLoading
  } = useCommandMutation(printer, {
    command: 'SET_HEATBED_TEMPERATURE',
    successMessage: {
      title: t('printer.control.heatedbed'),
      body: t('printer.control.heatedbed.description')
    }
  })

  const changeBedTemperature = useCallback(
    (temperature: number) => {
      setBedTemperature({ bed_temperature: temperature })
    },
    [setBedTemperature]
  )

  const renderBedDropdown = () => {
    return (
      <TemperatureDropdown
        type="bed"
        disabled={!isBedTempAvailable || isBedTempLoading}
        filament={printer.filament}
        onChange={changeBedTemperature}
      />
    )
  }

  return (
    <Section>
      <S.Param>
        <span>{t('printer.heatbed')}</span>
        {printer.temp ? (
          <PulsingTemperature value={printer.temp.temp_bed} targetValue={printer.temp.target_bed} />
        ) : (
          <span>--</span>
        )}
      </S.Param>
      {!isBedTempAvailable ? (
        <WithTooltip id="bed-tooltip" title={printerInState} placement="top">
          <div>{renderBedDropdown()}</div>
        </WithTooltip>
      ) : (
        renderBedDropdown()
      )}
    </Section>
  )
}

export function Temperatures() {
  return (
    <>
      <NozzleTemperature />
      <HeatbedTemperature />
    </>
  )
}
