import { CancelablePromise, ExtraHeaders, HttpMethod, RequestFactory } from './types/sdk'

// https://github.com/github/fetch#sending-cookies
const fetchOptions: RequestInit = {
  credentials: 'include'
}

function isAbortError(error: any): boolean {
  return !!(error && error.name === 'AbortError')
}

function sanitizeResponse(response: Response) {
  if (response.ok) {
    if (response.headers.get('content-type')?.includes('application/json')) {
      return response.json()
    }
    if (response.headers.get('content-type')?.match(/image\/jpe?g/)) {
      return response
    }
    return response.text()
  }

  return response.json().then((error) => {
    error.response = response
    throw error
  })
}

export function createRequestFactory(baseUrl = ''): RequestFactory {
  const makeRequest = <TReponse, TBody extends {}>(
    method: RequestInit['method'],
    url: string,
    data?: TBody,
    extraHeaders?: ExtraHeaders
  ): CancelablePromise<TReponse> => {
    const controller = new AbortController()
    const signal = controller.signal
    let canceled = false

    const isFormData = data instanceof FormData

    const headers = new Headers()
    headers.set('Accept', 'application/json')

    if (!isFormData) {
      headers.set('Content-type', 'application/json')
    }

    const accessToken = localStorage.getItem('auth.accessToken')
    if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`)
    }

    if (extraHeaders) {
      Object.entries(extraHeaders).forEach(([key, value]) => {
        headers.set(key, value.toString())
      })
    }

    const body = isFormData ? data : JSON.stringify(data)

    const promise = fetch(`${baseUrl}${url}`, {
      method,
      body,
      ...fetchOptions,
      headers,
      signal
    })
      .then((response) => {
        if (canceled) {
          return Promise.reject({ isCanceled: true })
        }
        return sanitizeResponse(response)
      })
      .catch((error) => {
        if (canceled || isAbortError(error)) {
          return Promise.reject({ isCanceled: true })
        }
        throw error
      })

    // https://react-query.tanstack.com/guides/query-cancellation#using-fetch
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    promise.cancel = () => {
      if (canceled) {
        return
      }
      canceled = true
      controller.abort()
    }

    return promise as CancelablePromise<TReponse>
  }

  return {
    makeRequest,

    get: (url: string, headers?: ExtraHeaders) => makeRequest(HttpMethod.GET, url, undefined, headers),

    post: <TReponse, TBody extends {}>(url: string, body?: TBody, headers?: ExtraHeaders) =>
      makeRequest<TReponse, TBody>(HttpMethod.POST, url, body, headers),

    put: <TReponse, TBody extends {}>(url: string, body?: TBody, headers?: ExtraHeaders) =>
      makeRequest<TReponse, TBody>(HttpMethod.PUT, url, body, headers),

    patch: <TReponse, TBody extends {}>(url: string, body?: TBody, headers?: ExtraHeaders) =>
      makeRequest<TReponse, TBody>(HttpMethod.PATCH, url, body, headers),

    delete: <TBody extends {}>(url: string, body?: TBody, headers?: ExtraHeaders) =>
      makeRequest(HttpMethod.DELETE, url, body, headers)
  }
}
