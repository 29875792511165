import styled from 'styled-components'

const HEIGHT = '1.5em'

export const Wrapper = styled.div`
  min-width: 100px;
  width: 100%;
  background-color: var(--background-primary);
  height: ${HEIGHT};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

type WidthProps = { $width: number }

export const Now = styled.div.attrs<WidthProps>((props) => ({
  style: { width: `${props.$width}%` }
}))<WidthProps>`
  position: absolute;
  inset: 0;
  transition: width 0.5s ease-in-out;
  height: ${HEIGHT};
  background-color: var(--color-primary);
`

export const Label = styled.div`
  font-size: 0.9rem;
  font-weight: 500;
  position: relative;
`
