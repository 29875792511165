import styled from 'styled-components'

export const Label = styled.div`
  font-size: 0.6875rem;
  font-weight: 400;
  white-space: nowrap;
  display: flex;
  align-items: center;
  svg {
    margin-right: 0.25rem;
    flex-shrink: 0;
  }
`

export const Value = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
  white-space: nowrap;
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.25rem;
    flex-shrink: 0;
  }
`
