import { Route, Routes } from 'react-router'

import { AddFilament } from '../components/filaments/AddFilament'
import { EditFilament } from '../components/filaments/EditFilament'
import { FilamentsTable } from '../components/filaments/FilamentsTable'
import { PageContainer } from '../components/helpers/styled'

export function FilamentsPage() {
  return (
    <>
      {/* TODO refactor since it's here only for the white space */}
      <PageContainer />

      <Routes>
        <Route path="edit/:filamentId" element={<EditFilament />} />
        <Route path="add" element={<AddFilament />} />
        <Route index element={<FilamentsTable />} />
      </Routes>
    </>
  )
}
