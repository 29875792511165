import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

import { AfsJob } from '../api/types/afs'
import { IJob, IPlannedJob, IPrinterDetailJobInfo } from '../api/types/job'
import { getNameFromPath } from '../helpers/files'
import { getJobHash } from '../helpers/jobs'

export function getJobTitle(t: TFunction, job: IPlannedJob | IJob | IPrinterDetailJobInfo | AfsJob) {
  if ('display_name' in job && job.display_name) {
    return job.display_name
  }

  if ('file' in job) {
    if (job.file?.display_name) {
      return job.file.display_name
    }

    if (job.file?.name) {
      return job.file.name
    }
  }

  if (('display_path' in job && job.display_path) || ('path' in job && job.path)) {
    return getNameFromPath(job.display_path || job.path)
  }

  if (job.id) {
    return `${t(`jobs.print-job`)} #${job.id}`
  }

  if ('origin_id' in job && job.origin_id) {
    return `${t(`jobs.print-job`)} #${job.origin_id}`
  }

  return `${t(`jobs.print-job`)} "${getJobHash(job)}"`
}

export function useJobTitle(job: IPlannedJob | IJob | IPrinterDetailJobInfo | AfsJob) {
  const { t } = useTranslation()
  return getJobTitle(t, job)
}

export function getJobDisplayPath(job: IPlannedJob | IJob | IPrinterDetailJobInfo) {
  if (job.file?.display_path) {
    return job.file.display_path
  }

  return job.file?.path
}
