import { Language } from '../../interfaces/locale'
import { IFilament, Material } from './filament'
import { IPrintFile } from './file'
import { IGroupSimpleView } from './groups'
import { IPrinterDetailJobInfo } from './job'
import { INetworkInfo } from './networkInfo'
import { IPager } from './pager'
import { IConnectState } from './state'
import { IUser } from './user'
import { IVector3 } from './vector'

export type PrinterUuid = string

export type IPrinterCode = string

export type IRegistrationPrinter = {
  code: IPrinterCode
  created: number
  printer_type: string
  sn: string
}

export enum AvailablePrinterTypes {
  'Original_Prusa_i3_MK2.5' = '1.2.5',
  'Original_Prusa_i3_MK2.5S' = '1.2.6',
  Original_Prusa_i3_MK3 = '1.3.0',
  Original_Prusa_i3_MK3S = '1.3.1',
  'Original_Prusa_i3_MK3.5' = '1.3.5',
  'Original_Prusa_i3_MK3.9' = '1.3.9',
  Original_Prusa_i3_MK4 = '1.4.0',
  Original_Prusa_MINI = '2.1.0',
  Original_Prusa_XL = '3.1.0',
  Original_Prusa_iX = '4.1.0',
  Original_Prusa_SL1 = '5.1.0',
  Original_Prusa_SL1S_SPEED = '5.1.1',
  Original_Prusa_Medical_One = '5.2.0',
  Trilab_DeltiQ_2 = '6.2.0',
  Trilab_DeltiQ_2_Plus = '6.2.1',
  Trilab_AzteQ = '7.1.0',
  Trilab_AzteQ_Industrial = '7.2.0',
  Trilab_AzteQ_Industrial_Plus = '7.2.1'
}

export enum AvailableOtherDeviceTypes {
  'Original_Prusa_EXTRACTOR' = '253.1.0',
  'Original_Prusa_HARVESTER' = '252.1.0',
  'Original_Prusa_SHELF' = '251.1.0'
}

export const printersWithIniFile = [
  AvailablePrinterTypes.Original_Prusa_SL1,
  AvailablePrinterTypes.Original_Prusa_SL1S_SPEED,
  AvailablePrinterTypes.Original_Prusa_Medical_One,
  AvailablePrinterTypes.Trilab_DeltiQ_2,
  AvailablePrinterTypes.Trilab_DeltiQ_2_Plus,
  AvailablePrinterTypes.Trilab_AzteQ,
  AvailablePrinterTypes.Trilab_AzteQ_Industrial,
  AvailablePrinterTypes.Trilab_AzteQ_Industrial_Plus
]

export const buddyPrinters = [
  AvailablePrinterTypes.Original_Prusa_MINI,
  AvailablePrinterTypes.Original_Prusa_iX,
  AvailablePrinterTypes['Original_Prusa_i3_MK3.5'],
  AvailablePrinterTypes['Original_Prusa_i3_MK3.9'],
  AvailablePrinterTypes.Original_Prusa_i3_MK4,
  AvailablePrinterTypes.Original_Prusa_XL
]

export type IAddPrinterRequestParams = {
  name?: string
  location?: string
  code?: IPrinterCode
  team_id?: number
  printer_type?: string
  settings?: {
    network?: INetworkInfo
  }
}

type IPrinterGroupInfo = {
  id: number
  name: string
  description?: string
  farm: boolean
  position: {
    id: number
    logical_position: IVector3
    absolute_position?: IVector3
  }
  size: IVector3
}

export type IDraggablePrinter = IPrinterSimpleView & {
  isDisabled: boolean
  disabledTooltip: string
}

export type IPrinterSimpleView = {
  uuid: PrinterUuid
  name?: string
  created: number
  location?: string
  printer_type: string
  printer_type_name: string
  printer_type_compatible: string[]
  connect_state: IConnectState
  speed?: number
  temp?: ITemp
  axis_z?: number
  firmware?: string
  last_file_tree_change?: number
  last_info_event?: number
  last_online?: number
  team_id: number
  team_name: string
  group_info?: IPrinterGroupInfo // information about physical group (only in one physical group)
  job_info?: IPrinterDetailJobInfo
  filament?: IPrinterFilamentDetail
  nozzle_diameter?: number
  is_beta: boolean
  job_queue_count?: number
  is_dev?: boolean
  allowed_functionalities?: IAllowedFunctionalities
  max_filename: number
  slots?: number
  slot?: IMMUPrinterDetail
}

export type IMMUPrinterDetail = {
  active: 0 | 1 | 2 | 3 | 4 | 5
  slots: Record<'1' | '2' | '3' | '4' | '5', ISlotDetail>
}

export type ISlotDetail = {
  temp: number
  material: string
}

export enum IAllowedFunctionalities {
  FILAMENT = 'filament',
  FILES = 'files',
  QUEUE = 'queue',
  MBL = 'mbl',
  LOW_MEMORY = 'low_memory',
  FIRMWARE_UPDATE = 'fw_update',
  BGCODE = 'bgcode',
  FIRMWARE_UPDATE_WITH_PATH = 'fw_update_path'
}

export type IMeshBedLevelingPoint = [number, number, number]

export type IIncident = {
  created: number
  type: 'SN' | 'FINGERPRINT'
  value: string // serial number or fingerprint
}

export type IFlags = {
  diverging_origin_id: boolean
  incident?: IIncident[]
}
type IFarmSettings = {
  monitoring: boolean
  auto_printing: boolean
  auto_harvesting: boolean
}

export enum PrinterSupportState {
  SUPPORTED = 'supported',
  OUTDATED = 'outdated',
  UNSUPPORTED = 'unsupported'
}

export enum PrinterSupportRelease {
  STABLE = 'stable',
  DEV = 'dev',
  BETA = 'beta'
}

type PrinterSupport = {
  release_url?: string
  current?: string
  state: PrinterSupportState
  release?: PrinterSupportRelease
  stable?: string
  unsupported?: string
  prerelease?: string
}

export enum PrinterFirmwareDecisionMaker {
  PRUSA_LINK = 'prusa_link', // for default printers (MK3 etc)
  FIRMWARE = 'firmware' // for Buddy printers (Mk4, XL etc)
}

type Sheet = {
  name: string
  z_offset: number
}

export type ISlaPrinter = {
  device_hash_qr?: boolean
  auto_off?: boolean
  cover_check?: boolean
  resin_sensor?: boolean
  fan_rear?: number
  target_fan_rear?: number
  cover_closed?: boolean
  current_layer?: number
}

export type IShelfDevice = {
  stacks: {
    state: 'printed_sheet' | 'printed_obj' | 'no_print'
    sheet_id: string
  }[][]
}

export type IPrinter = IPrinterSimpleView &
  ISlaPrinter &
  IShelfDevice & {
    active_sheet?: number
    sheet_settings?: Sheet[]
    sn?: string
    allowed_functionalities?: IAllowedFunctionalities[]
    rights_r: boolean
    rights_w: boolean
    rights_u: boolean
    settings?: {
      network?: INetworkInfo
    }
    decision_maker: PrinterFirmwareDecisionMaker // links to support object
    support: PrinterSupport
    groups?: IGroupSimpleView[] // virtual and physical groups where the printer is
    filament?: IPrinterFilamentDetail
    transfer?: IPrinterTransfer
    network_info?: INetworkInfo
    prusalink_api_key?: string
    prusaconnect_api_key?: string
    ts_info?: number
    axis_y?: number
    axis_x?: number
    flow?: number
    state_reason?: string
    appendix?: boolean
    sdk?: string
    prusa_link?: string
    mbl_points?: IMeshBedLevelingPoint[]
    disabled?: IFarmSettings
    manipulated_by?: PrinterUuid
    flags?: IFlags
    owner?: IUser
  }

export enum TransferType {
  NO_TRANSFER = 'NO_TRANSFER',
  FROM_WEB = 'FROM_WEB',
  FROM_CONNECT = 'FROM_CONNECT',
  FROM_PRINTER = 'FROM_PRINTER',
  FROM_SLICER = 'FROM_SLICER',
  FROM_CLIENT = 'FROM_CLIENT',
  TO_CONNECT = 'TO_CONNECT',
  TO_CLIENT = 'TO_CLIENT'
}

export type IPrinterTransfer = {
  type?: TransferType
  path?: string
  display_path?: string
  display_name?: string
  url?: string
  start?: number
  progress: number
  to_select?: boolean
  to_print?: boolean
  time_remaining?: number
  transferred?: number
  size?: number
  team_name?: string
  hash?: string
  id?: number
  team_id?: number
  file?: IPrintFile
  preview_url?: string
}

export type ITemp = {
  temp_nozzle?: number
  temp_bed?: number
  target_nozzle?: number
  target_bed?: number
}

export type IJobInfo = {
  id?: number
  path?: string
  hash?: string
  progress?: number
  start?: number // timestamp
  end?: number // timestamp
  time_printing?: number // sec
  time_estimated?: number // sec
  time_extracting?: number // sec
}

export type IPrintersQuery = {
  group_id?: number
  team_id?: number
  state_include?: IConnectState[]
  state_exclude?: IConnectState[]
  offset?: number
  limit?: number
  sort_by?: string
}

export type IAddNewPrinter = {
  name: string
  farm: boolean
  location: string
  position: IPosition
  code: IPrinterCode
}

type IDns = [string, string, string]

export type IEditPrinter = {
  name?: string
  location?: string
  team_id?: number
  settings?: {
    network?: {
      hostname?: string
      dns4?: IDns
      dns6?: IDns
    }
  }
  is_beta?: boolean
  auto_harvesting?: boolean
  auto_printing?: boolean
  monitoring?: boolean
} & ISlaPrinter

export type IPosition = {
  x: number
  y: number
}

type ParameterRange = {
  min: number
  max: number
}

export type IPrinterType = {
  id: string
  type: number
  version: number
  subversion: number
  is_manipulator: boolean
  name: string
  description?: string
  functionalities: {
    public: boolean
    prusa_link?: string
    firmware?: string
    functionality: IAllowedFunctionalities
  }[]
  parameters: {
    min_temp_nozzle_e?: number
    temp_bed?: ParameterRange
    extrusion?: ParameterRange
    feedrate_e?: ParameterRange
    feedrate_x?: ParameterRange
    feedrate_y?: ParameterRange
    feedrate_z?: ParameterRange
    position_x?: ParameterRange
    position_y?: ParameterRange
    position_z?: ParameterRange
    print_flow?: ParameterRange
    print_speed?: ParameterRange
    temp_nozzle?: ParameterRange
  }
}

export type IPrinterTypes = IPrinterType[]

// Used for pairing filament & printer
export type IPrinterFilament = {
  filament_id: number
  weight: number
  full_weight: number
  color: string
}

// Response in PrinterDetail
export type IPrinterFilamentDetail = IFilament & {
  /** ID to the catalogue (can be undefined because filament could be already deleted) */
  id?: number
  /** Remaining weight in g */
  weight?: number
  /** Remaining length in m */
  length?: number

  full_weight?: number

  color: string
}

export type IPrintersResponse = {
  printers: IPrinterSimpleView[]
  pager: IPager
}

export enum IPrinterStorageType {
  LINK = 'LINK',
  SD_CARD = 'SD_CARD',
  USB = 'USB',
  LOCAL = 'LOCAL',
  OTHER = 'OTHER'
}

export type IPrinterStorageLocation = {
  name: string
  type: IPrinterStorageType
  mountpoint: string
  file_count: number
  free_space?: number
  total_space?: number
  read_only: boolean
  m_timestamp?: number
}

export type IStoragesResponse = {
  storages: IPrinterStorageLocation[]
}

/** Types for single printer statistics */

export type IPrinterDateRangeQuery = {
  from: number
  to: number
}

export type IPrinterMaterialConsumpRes = {
  name: string
  uuid: string
  data: {
    name: Material
    value: number
  }[]
} & IPrinterDateRangeQuery

export type IPrinterPlannedTasksFrequencyRes = {
  xAxis: number[]
  series: {
    name: string
    uuid: string
    data: number[][]
  }
} & IPrinterDateRangeQuery

export enum OverallPrinting {
  printing = 'printing',
  not_printing = 'not_printing'
}

export type IPrinterOverallPrintTimeRes = {
  uuid: string
  name: string
  data: {
    name: OverallPrinting | string
    value: number
  }[]
} & IPrinterDateRangeQuery

export enum PrinterJobsStates {
  FIN_OK = 'FIN_OK',
  FIN_STOPPED = 'FIN_STOPPED',
  FIN_ERROR = 'FIN_ERROR',
  UNKNOWN = 'UNKNOWN'
}

type serie = {
  name: string
  data: number[]
}

export type IPrinterJobsEndStatesRes = {
  uuid: string
  xAxis: string[]
  series:
    | serie
    | {
        name: string
        data: number[]
      }[]
} & IPrinterDateRangeQuery

/* ------------------------------ UPDATE PRINTER FIRMWARE ------------------------------ */

export enum PlannedFirmwareHistorySource {
  CONNECT_USER = 'CONNECT_USER',
  CONNECT_AGENT = 'CONNECT_AGENT',
  CONNECT_DEVICE = 'CONNECT_DEVICE',
  SLICER = 'SLICER',
  UNKNOWN = 'UNKNOWN',
  USER = 'USER'
}

export enum PlannedFirmwareState {
  PLANNED = 'PLANNED',
  PROCESSED = 'PROCESSED',
  DELETED = 'DELETED'
}

export enum PlannedFirmwareAbortReason {
  FILE_ALREADY_EXISTS = 'FILE_ALREADY_EXISTS',
  FILE_NOT_FOUND = 'FILE_NOT_FOUND',
  INVALID_FILE_PATH = 'INVALID_FILE_PATH',
  NO_STORAGE_SPACE = 'NO_STORAGE_SPACE',
  READ_ONLY = 'READ_ONLY',
  FILE_NOT_IN_PRINTER = 'FILE_NOT_IN_PRINTER',
  NOT_FOUND_STORAGE = 'NOT_FOUND_STORAGE',
  PRINTER_UNAVAILABLE = 'PRINTER_UNAVAILABLE'
}

export enum FirmwareTransferringType {
  NO_TRANSFER = 'NO_TRANSFER',
  FROM_WEB = 'FROM_WEB',
  FROM_CONNECT = 'FROM_CONNECT',
  FROM_PRINTER = 'FROM_PRINTER',
  FROM_SLICER = 'FROM_SLICER',
  FROM_CLIENT = 'FROM_CLIENT',
  TO_CONNECT = 'TO_CONNECT',
  TO_CLIENT = 'TO_CLIENT'
}

export type FirmwareType = 'FIRMWARE'

export enum FirmwareSyncSource {
  PRINTER = 'PRINTER',
  NAME = 'NAME',
  USER = 'USER',
  CONNECT = 'CONNECT'
}

type FirmwareSyncedBy = {
  device_type?: string // x.x.x
  firmware?: string
  prusalink?: string
}

export type IPrinterLastStableFirmware = {
  planned?: {
    id?: number
    hash?: string
    team_id?: number
    team_name?: string
    sort_order?: number
    path: string
    source: PlannedFirmwareHistorySource
    source_info?: {
      username?: string
      first_name?: string
      last_name?: string
      public_name: string // If is not set, {first_name} {last_name}' will be used.
      avatar: string
    }
    state?: PlannedFirmwareState
    abort_reason?: PlannedFirmwareAbortReason
  }
  transferring?: {
    type?: FirmwareTransferringType
    path?: string
    url?: string
    start?: number
    progress: number
    time_remaining?: number
    transferred?: number
    size?: number
    team_name?: string
    preview_url?: string
    preview_mimetype: string
    to_print?: boolean
  }
  path?: string
  uploaded?: number
  printer_type?: string
  meta?: {
    version?: string
    printer_type: number
    printer_version: number
  }
  type: FirmwareType
  hash: string
  display_name: string // full name of a file
  display_path?: string
  name?: string // TODO: DELETE - can be shortened name retrieved from printer
  size?: number
  team_id: number
  sync?: {
    synced_by: FirmwareSyncedBy
    synced?: number
    source?: FirmwareSyncSource
  }
}

export type IPrinterParticularVersionFirmware = IPrinterLastStableFirmware

export type IPrinterParticularFirmwareByVersionQuery = {
  hash: string
}

/* ------------------------------ GET ALL PRINTER FIRMWARES ------------------------------ */

export type FirmwareFileType = 'FIRMWARE'

export type FirmwareFileMeta = {
  version: string
  suffix?: string
  build_no?: string
  release?: PrinterSupportRelease
  state?: PrinterSupportState
  language?: Language
}

export type FirmwareFile = {
  printer_type?: string
  meta?: FirmwareFileMeta
  type: FirmwareFileType
  release_url?: string
  hash: string
  display_name?: string
  size?: number
  team_id: number
  sync?: {
    synced_by: FirmwareSyncedBy
    synced?: number
    source?: FirmwareSyncSource
  }
  uploaded: number
  uploaded_id?: number
}

export type IPrinterAvailableFirmwaresQuery = {
  printer_uuid: PrinterUuid
}

export type IPrinterAvailableFirmwaresResponse = {
  files: FirmwareFile[]
}
