import { useMutation, useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { Col, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { useApiClient } from '../../api/react'
import { IAgentCommand } from '../../api/types/agents'
import { IKwargs } from '../../api/types/commandQueue'
import { useToast } from '../../context/toastStore'
import { Row } from '../bootstrap/Row'
import { LoadingButton } from '../common/LoadingButton'
import { PaceProgress } from '../common/PaceProgress'
import { MutationStatus } from '../mutationStatus'
import { getPrinterName } from '../printers/utils'

type Props = {
  agentId: number
  groupId?: number
}

type MutationParams = {
  command: IAgentCommand
  kwargs?: IKwargs
}

export function AddCommandToQueue(props: Props) {
  const { agentId, groupId } = props
  const { t } = useTranslation()
  const [command, setCommand] = useState<IAgentCommand>()
  const [printerUuid, setPrinterUuid] = useState('')
  const api = useApiClient()
  const toast = useToast()
  const mutation = useMutation(
    (params: MutationParams) => api.app.agents.addCommandToQueue(agentId, params.command, params.kwargs),
    {
      onSuccess: () => {
        toast.add(t('command.sent'), t('command.sent.success', { command }))
      }
    }
  )

  const hasGroup = groupId !== undefined

  const { data } = useQuery(
    [`/group/${groupId}/printers`],
    () => {
      if (hasGroup) {
        return api.app.printerGroups.getGroupPrinters(groupId)
      }
    },
    {
      enabled: hasGroup
    }
  )

  const onSubmit = () => {
    if (command) {
      const kwargs = printerUuid ? { printer_uuid: printerUuid } : undefined
      mutation.mutate({ command, kwargs })
    }
  }

  if (!data && hasGroup) {
    return <PaceProgress />
  }

  return (
    <>
      <Row>
        <Col>
          <Form.Control
            as="select"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setCommand(e.currentTarget.value as IAgentCommand)}
            value={command}
            required
          >
            <option value="">- select command -</option>
            {Object.keys(IAgentCommand).map((command) => (
              <option key={command} value={command}>
                {command}
              </option>
            ))}
          </Form.Control>
        </Col>
        {data && (
          <Col>
            <Form.Control
              as="select"
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setPrinterUuid(e.currentTarget.value)}
              value={printerUuid}
              required
            >
              <option value="">- select printer -</option>
              {data.printers.map((printer) => (
                <option key={printer.uuid} value={printer.uuid}>
                  {getPrinterName(printer)}
                </option>
              ))}
            </Form.Control>
          </Col>
        )}
        <Col>
          <LoadingButton disabled={mutation.isLoading || !command} isLoading={mutation.isLoading} onClick={onSubmit}>
            Send command
          </LoadingButton>
        </Col>
      </Row>

      <MutationStatus mutation={mutation} />
    </>
  )
}
