import React, { createContext, ReactNode, useContext } from 'react'
import { SizeMe } from 'react-sizeme'

import { DEFAULT_GRID_MODE } from '../../config'
import { GridMode } from '../../constants/grid'
import { chunks } from '../../helpers/std'
import * as S from './HexagonalGrid.styled'

// https://www.omnicalculator.com/math/hexagon#circumradius-and-inradius
function getShortDiagonal(width: number) {
  const side = width / 2
  return Math.sqrt(3) * side
}

/**
 * Width of one item
 *
 *       x
 *   <-------->
 *   |______  |
 *   /      \ |
 *  /|       \|
 * / |        \
 * + |        +
 * \ |        /
 *  \|       /|
 *   |______/ |
 */

function calculateItemsPerRow(gridWidth: number, hexagonWidth: number, margin: number) {
  const diagonal = getShortDiagonal(hexagonWidth)
  const side = hexagonWidth / 2
  const x = Math.sqrt(side ** 2 - (diagonal / 2) ** 2)
  const itemWidth = hexagonWidth - x + margin

  const itemsPerRow = Math.floor((gridWidth - x) / itemWidth)
  return itemsPerRow
}

type GridProps = {
  children: ReactNode
  itemsPerRow: number
}

export function Grid(props: GridProps) {
  const { itemsPerRow } = props
  const ctx = useContext(SizeContext)

  const children = React.Children.toArray(props.children)

  const groups = chunks(children, itemsPerRow)

  // inline style to prevent generating many CSS classes
  const marginTop = -(S.toHeight(ctx.itemWidth) / 2 - ctx.margin / 2)
  return (
    <>
      {groups.map((group, i) => (
        <S.Row key={i} style={i > 0 ? { marginTop } : undefined} $center={ctx.fixedGridSize}>
          {group}
        </S.Row>
      ))}
    </>
  )
}

export type Props = {
  children: ReactNode
  itemWidth: number
  margin?: number
  mode?: GridMode
  cols?: number
  fixedGridSize?: boolean
}

const SizeContext = createContext({
  itemWidth: 160,
  margin: 10,
  mode: DEFAULT_GRID_MODE,
  fixedGridSize: false
}) // placeholder values

export function HexagonalGrid(props: Props) {
  const { margin = 10, children, cols, itemWidth, mode = DEFAULT_GRID_MODE, fixedGridSize = false } = props
  const ctx = { itemWidth, margin, mode, fixedGridSize }
  if (cols) {
    return (
      <SizeContext.Provider value={ctx}>
        <S.Container>
          <Grid itemsPerRow={cols}>{children}</Grid>
        </S.Container>
      </SizeContext.Provider>
    )
  }
  return (
    <SizeContext.Provider value={ctx}>
      <SizeMe>
        {({ size }) => {
          if (!size.width) {
            return <S.Container />
          }
          const itemsPerRow = calculateItemsPerRow(size.width, itemWidth, margin)
          return <S.Container>{size.width && <Grid itemsPerRow={itemsPerRow}>{children}</Grid>}</S.Container>
        }}
      </SizeMe>
    </SizeContext.Provider>
  )
}

type GridItemProps = {
  children: ReactNode
  background?: string
}

export function HexagonalGridItem(props: GridItemProps) {
  const ctx = useContext(SizeContext)
  return (
    <S.Hexagon $mode={ctx.mode} $margin={ctx.margin} $width={ctx.itemWidth} $background={props.background}>
      {props.children}
    </S.Hexagon>
  )
}
