import { Container } from 'react-bootstrap'
import styled, { css } from 'styled-components'

import { GridMode } from '../../../../constants/grid'
import { device } from '../../../../interfaces/device'

const MARGIN_SHIFT = 62

export const Column = styled.div<{ $mode: GridMode; $shifted?: boolean }>`
  width: auto;
  ${({ $mode, $shifted }) => {
    if (!$shifted) {
      return ''
    }

    if ($mode === GridMode.FIRST_UP) {
      return css`
        &:nth-child(even) {
          margin-top: ${MARGIN_SHIFT}px;
        }
      `
    }
    return css`
      &:nth-child(odd) {
        margin-top: ${MARGIN_SHIFT}px;
      }
    `
  }}
`

export const ScrollableWrapper = styled(Container)`
  padding: 0 30px;
  width: 100%;
  overflow-x: auto;
  display: flex;
  flex-wrap: nowrap;

  @media ${device.md} {
    width: auto;
  }
`
