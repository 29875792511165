import { ReactNode, Suspense as ReactSuspense } from 'react'

import { PaceProgress } from './PaceProgress'

type Props = {
  children: ReactNode
}

export function Suspense({ children }: Props) {
  return <ReactSuspense fallback={<PaceProgress />}>{children}</ReactSuspense>
}
