import { useMutation } from '@tanstack/react-query'
import { useCallback } from 'react'

import { useApiClient } from '../api/react'
import { PrinterUuid } from '../api/types/printer'
import { IVector3 } from '../api/types/vector'

type Options = {
  onSuccess: () => void
  onError: (e?: any) => void
}

type PrinterGroupParams = {
  printerUuid: PrinterUuid
  groupId: number
}

type PositionParams = {
  position: IVector3
}

export function useGroupHandler(options: Options) {
  const api = useApiClient()
  const { mutate: addPrinterToGroup, isLoading: isAdding } = useMutation<
    unknown,
    unknown,
    PrinterGroupParams & Partial<PositionParams>
  >(
    (params) =>
      api.app.printerGroups.addPrinterToGroup(params.printerUuid, params.groupId, { position: params.position }),
    {
      onSuccess: () => options.onSuccess(),
      onError: (e) => options.onError(e)
    }
  )

  const { mutate: changePrinterAttributesInGroup, isLoading: isChanging } = useMutation<
    unknown,
    unknown,
    PrinterGroupParams & PositionParams
  >(
    (params) =>
      api.app.printerGroups.changePrinterAttributesInGroup(params.printerUuid, params.groupId, {
        position: params.position
      }),
    {
      onSuccess: () => options.onSuccess(),
      onError: (e) => options.onError(e)
    }
  )

  const { mutate: removePrinterFromGroup } = useMutation<unknown, unknown, PrinterGroupParams>(
    (params) => api.app.printerGroups.removePrinterFromGroup(params.printerUuid, params.groupId),
    {
      onSuccess: () => options.onSuccess(),
      onError: (e) => options.onError(e)
    }
  )

  const addPrinterToPhysicalGroup = useCallback(
    (params: PrinterGroupParams & PositionParams) => addPrinterToGroup(params),
    [addPrinterToGroup]
  )

  return {
    addPrinterToGroup,
    addPrinterToPhysicalGroup,
    changePrinterAttributesInGroup,
    isChanging,
    isAdding,
    removePrinterFromGroup
  }
}
