import styled from 'styled-components'

import { device } from '../../interfaces/device'
import { SvgIcon } from '../common/SvgIcon'

export const CameraDetailContainer = styled.div`
  margin: 1rem auto;
  font-weight: 400;
`

export const MainImageContainer = styled.div`
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  background-color: var(--background-secondary);

  .image-contents {
    position: relative;
    display: inline-block;
  }

  img {
    max-width: 100%;
    max-height: 40rem;
    height: auto;

    &.stale {
      filter: grayscale() opacity(25%) blur(2px);
    }
  }

  .stale-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    font-weight: 700;
  }

  button,
  a {
    width: 2.2rem;
    height: 2.2rem;
    border: none;
    padding: 0;
    background-color: var(--background-body);
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: var(--color-primary-faded);
    }
  }

  .expand {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }

  .button-container {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
`

export const EmptyImage = styled.div`
  width: 100%;
  aspect-ratio: 1.7;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  font-size: 1.3rem;
  font-weight: bold;
  color: var(--color-light);
`

export const CameraMetadata = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
  align-items: flex-end;
  justify-content: space-between;

  @media ${device.md} {
    gap: 2rem;
  }

  .camera-name {
    font-size: 1.3rem;
    font-weight: 700;
  }

  .label {
    font-size: 0.8rem;
    color: #757575;
  }

  .camera-settings {
    display: block;
    flex: auto 1;
    text-align: right;

    button {
      border: none;
      background: transparent;
      text-transform: uppercase;
      text-decoration: underline;
      color: #4d4d4d;
      font-size: 0.8rem;
      padding: 0;

      &:hover {
        text-decoration: none;
      }
    }

    .remove {
      color: #980b0b;
    }
  }
`

export const RefetchIndicator = styled(SvgIcon)`
  position: absolute;
  left: 1rem;
  top: 1rem;
`

export const SnapshotSwitchContainer = styled.div`
  position: absolute;
  left: 1rem;
  bottom: 1rem;
  height: 2rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0.5rem;
  border-radius: 0.2rem;
`

export const FilmRollContainer = styled.div`
  display: flex;
  gap: 1rem;
  overflow-x: scroll;
  align-items: center;

  button {
    border: none;
    padding: 0;
    display: block;
    position: relative;
    width: 10rem;
    height: 7rem;
    background-size: cover;
    background-position: center;

    &.active:after {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: inset 0 0 0 0.2rem var(--color-primary);
    }
  }
`

export const ToggleLabel = styled.span`
  color: #fff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
`
