import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

import * as S from './Breadcrumbs.styled'

type BreadcrumbItemProps = {
  to: string
  label: string
  clickable: boolean
}

export function BreadcrumbItem(props: BreadcrumbItemProps) {
  const { to, label, clickable } = props
  if (clickable) {
    return (
      <S.Item>
        <Link to={to}>{label}</Link>
      </S.Item>
    )
  }
  return (
    <S.Item>
      <div>{label}</div>
    </S.Item>
  )
}

type Props = {
  children: ReactNode
}

export function Breadcrumbs(props: Props) {
  const { children } = props
  return <S.Container aria-label="breadcrumbs">{children}</S.Container>
}
