import styled from 'styled-components'

export const Container = styled.nav`
  font-size: 0.9em;
  display: flex;
  align-items: center;
  font-weight: 400;
  color: var(--breadcrumbs-text);
  gap: 0.5em;
  flex-wrap: wrap;
`

export const Item = styled.div`
  display: flex;
  font-size: 0.9em;
  a {
    white-space: nowrap;
    display: flex;
    height: 100%;
    width: 100%;
    font-weight: 500;
    color: var(--breadcrumbs-text) !important;
    &:hover {
      text-decoration: underline;
    }
  }

  & + &:before {
    display: block;
    padding-right: 0.5rem;
    color: var(--breadcrumbs-separator);
    content: '/';
    &:hover {
      text-decoration: none;
    }
  }
`
