import styled, { css, keyframes } from 'styled-components'

export const MARKER_SIZE = 30

const ripple = keyframes`
  0% {transform:scale(0.5); }
  50% {transform:scale(1.25); opacity:1;}
  75% {transform:scale(1.5); opacity:0.7;}
  100% {transform:scale(2.5); opacity:0;}
`

// Container for world coordinates x=[0, 250] y=[0,210]
export const MarkerContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`

export const Marker = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${MARKER_SIZE}px;
  width: ${MARKER_SIZE}px;
  z-index: 1;
  :after {
    content: '';
    position: absolute;
    border-radius: 50%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    background: var(--color-primary);
    opacity: 0.3;
  }
`

type IMarkerInnerProps = { $animated?: boolean }

export const MarkerInner = styled.div<IMarkerInnerProps>`
  z-index: 3;
  border-radius: 50%;
  height: ${MARKER_SIZE / 2}px;
  width: ${MARKER_SIZE / 2}px;
  background: ${({ theme }) => theme.colors.primary};

  animation: ${({ $animated }) =>
    $animated &&
    css`
      ${ripple} 1s ease out infinite
    `};
`

export const MarkerLabel = styled.div`
  position: absolute;
  z-index: 1;
  font-size: 0.85rem;
  display: inline-flex;
  gap: 0.25rem;
  align-items: center;
  padding: 4px;
  border-radius: 4px;
  background: white;
  color: var(--neutral-500);
`

export const MarkerAxisLabel = styled.div`
  font-weight: 700;
`
export const MarkerSeparator = styled.div`
  height: 0.8rem;
  width: 1px;
  background: var(--border-secondary);
`
