import { useConfig } from '@prusaconnect/api/services/useConfig'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import { useApiClient } from '../../api/react'
import { getUserName, IOrigin } from '../../api/types/user'
import { useAuthState } from '../../context/authStore'
import { ColorMode, useColorMode } from '../../context/ThemeModeContext'
import { getEnvironment, Globals } from '../../helpers/getEnvironment'
import { useDevice } from '../../helpers/useDevice'
import { useGroupFilterData } from '../../hooks/useGroupFilterData'
import { useIsAdmin } from '../../hooks/useLoggedUser'
import { usePrusaAuthEditUrl } from '../../hooks/usePrusaAuthUrl'
import { useLogoutService } from '../../services/useLogoutService'
import { Dropdown, DropdownItem, DropdownItemButton, DropdownItemLink } from '../common/Dropdown'
import { BetaLabel } from '../common/Label'
import { SvgIcon } from '../common/SvgIcon'
import { ThreeWaySwitch, TogglePosition } from '../common/ThreeWaySwitch'
import { textEllipsis } from '../helpers/styled'
import { useStorageQuota } from '../printers/storage/FileManager/useCloudFiles'
import { UsedStorageInfo } from '../printers/storage/UsedStorageInfo'
import { User } from '../User'
import { LoginDropdown } from './LoginDropdown'

const Trigger = styled.span`
  display: flex;
  align-items: center;
`

const Span = styled.span`
  margin-left: 5px;
  max-width: 215px;
  font-size: 0.875rem;
  ${textEllipsis}
`

const Separator = styled.div`
  height: 0px;
  margin: 0.5rem 0;
  border-top: 1px solid var(--border-secondary);
`

const DropdownItemToggle = styled(DropdownItemButton)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.2rem 0.75rem;
  gap: 1rem;
`

const SpoolContainer = styled.div`
  display: flex;
  justify-content: center;
`

const InvitationsItemContent = styled.div`
  margin-left: 0.5rem;

  .invitations-count {
    background-color: var(--color-primary);
    border-radius: 2rem;
    padding: 0 0.4rem;
    color: white;
    text-align: center;
    font-size: 0.7rem;
    font-weight: bold;
    height: 1rem;
  }
`

const StorageUsage = styled.div`
  height: 3.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

export function DarkModeSwitcher({ userPreferredMode }: { userPreferredMode: ColorMode | null }) {
  const { t } = useTranslation()
  let label: string
  let togglePosition: TogglePosition = 'center'

  if (!userPreferredMode) {
    label = t('menu.dark-mode.auto', 'Auto')
  } else {
    const isDark = userPreferredMode === ColorMode.DARK
    label = isDark ? t('menu.dark-mode.enabled', 'On') : t('menu.dark-mode.disabled', 'Off')
    togglePosition = isDark ? 'right' : 'left'
  }

  return <ThreeWaySwitch position={togglePosition} label={label} />
}

export function UserDropdown() {
  const { t } = useTranslation()
  const authState = useAuthState()
  const config = useConfig()
  const { logoutUser } = useLogoutService()
  const prusaAuthEditUrl = usePrusaAuthEditUrl()
  const { lg } = useDevice()
  const theme = useTheme()
  const [fill, setFill] = useState(theme.colors.secondary)
  const isAdmin = useIsAdmin()
  const { groupList } = useGroupFilterData()
  const isDeveloperOrAdmin = getEnvironment(Globals.ENVIRONMENT) === 'dev' || isAdmin
  const colorModeContext = useColorMode()

  const api = useApiClient()
  const invitations = useQuery(['invitations'], () => api.app.users.getInvitationsForUser())
  const invitationsCount = invitations.data?.invitations.length || 0

  const storageQuota = useStorageQuota(authState.user.default_team_id)

  if (config && authState.isAuthenticated) {
    return (
      <Dropdown
        placement="bottom-end"
        onDropdownOpen={() => {
          storageQuota.refetch()
          invitations.refetch()
        }}
        trigger={
          <>
            {authState.user ? (
              <Trigger
                onMouseEnter={() => setFill(theme.colors.primary)}
                onMouseLeave={() => setFill(theme.colors.secondary)}
              >
                <User size={30} user={authState.user} avatarServerUrl={config.auth.avatar_server_url || ''} />{' '}
                {lg && <Span style={{ color: fill }}>{getUserName(authState.user)}</Span>}
              </Trigger>
            ) : (
              ''
            )}
          </>
        }
      >
        <DropdownItemLink to="/profile/detail">
          <strong>{getUserName(authState.user)}</strong>
        </DropdownItemLink>

        <Separator />

        <StorageUsage>
          {storageQuota.total ? (
            <UsedStorageInfo free={storageQuota.free} total={storageQuota.total} expand isTeamInfo />
          ) : (
            <SpoolContainer>
              <SvgIcon size={30} icon="loadingWheel4SIcon" />
            </SpoolContainer>
          )}
        </StorageUsage>

        <Separator />

        <DropdownItemToggle onClick={colorModeContext.iterateUserPreference}>
          {t('menu.dark-mode', 'Dark mode')}
          <DarkModeSwitcher userPreferredMode={colorModeContext.userPreferredMode} />
        </DropdownItemToggle>

        <Separator />

        {invitations.data ? (
          <DropdownItemLink to="/invitations" className="justify-content-between">
            {t('menu.invitations', 'My invitations')}
            <InvitationsItemContent>
              {invitationsCount > 0 && <div className="invitations-count">{invitationsCount}</div>}
            </InvitationsItemContent>
          </DropdownItemLink>
        ) : (
          <SpoolContainer>
            <SvgIcon size={30} icon="loadingWheel4SIcon" />
          </SpoolContainer>
        )}

        <Separator />

        <DropdownItemLink to="/profile/detail">{t('menu.profile')}</DropdownItemLink>
        {authState.user.origin === IOrigin.CONNECT && (
          <DropdownItemLink to="/profile/password-change">{t('menu.password-change')}</DropdownItemLink>
        )}
        {authState.user.origin === IOrigin.PRUSA_AUTH && (
          <DropdownItem as="a" href={prusaAuthEditUrl}>
            {t('menu.manage-account')}
          </DropdownItem>
        )}

        <DropdownItemLink to="/preferences">
          {t('menu.preferences', 'Preferences')}
          <BetaLabel />
        </DropdownItemLink>
        <DropdownItemButton onClick={() => logoutUser()}>{t('menu.log-out')}</DropdownItemButton>

        <Separator />

        <DropdownItemLink to="/add-printer/select">{t('printer.add-new')}</DropdownItemLink>

        <Separator />

        {isAdmin && <DropdownItemLink to="/users">{t('menu.users')}</DropdownItemLink>}
        {AFS_ENABLED && <DropdownItemLink to="/agents">{t('menu.agents', 'Agents')}</DropdownItemLink>}

        <DropdownItemLink to="/teams">{t('menu.teams')}</DropdownItemLink>

        {/* Filaments are hidden for now but the link should work */}
        {/* <DropdownItemLink to="/filaments">{t('menu.filaments')}</DropdownItemLink> */}
        {(groupList.length > 0 || isAdmin) && (
          <DropdownItemLink to="/groups">
            {t('menu.groups')}
            <BetaLabel />
          </DropdownItemLink>
        )}
        {isDeveloperOrAdmin && <DropdownItemLink to="/commands/g-codes">{t('menu.commands')}</DropdownItemLink>}
      </Dropdown>
    )
  }

  return <LoginDropdown />
}
