import { useMutation } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useApiClient } from '../../api/react'
import { IFlags } from '../../api/types/printer'
import { Button } from '../common/Button'

const getHoursMinutesAndSeconds = (timestamp: number) => {
  const dayjsTimestamp = dayjs.unix(timestamp)
  return dayjsTimestamp.format('HH:mm:ss')
}

const getDayMonthAndYear = (timestamp: number) => {
  const dayjsTimestamp = dayjs.unix(timestamp)
  return dayjsTimestamp.format('DD.MM.YYYY')
}

const Wrapper = styled.div<{
  mt?: boolean
}>`
  ${({ mt }) => mt && 'margin-top: 1rem;'}
`

const Paragraph = styled.p<{
  noMargin?: boolean
}>`
  ${({ noMargin }) => noMargin && 'margin: 0px'}
`

const ListItem = styled.li`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  list-style-position: inside;
`

const ButtonContainer = styled.div<{
  spaceAround?: boolean
}>`
  display: flex;
  justify-content: end;

  ${({ spaceAround }) =>
    spaceAround &&
    `
    justify-content: space-around;
  `}
`

type Props = {
  uuid: string
  incidents: IFlags['incident']
}

export function Incident({ uuid, incidents }: Props) {
  const api = useApiClient()
  const { t } = useTranslation()
  const { mutate: deleteFingerprint } = useMutation((uuid: string) => api.app.printers.deletePrinterFingerprint(uuid))
  const { mutate: unsetPrinterFlag } = useMutation(({ uuid, flagName }: { uuid: string; flagName: string }) =>
    api.app.printers.unsetPrinterFlag(uuid, flagName)
  )
  const fingerprintChanges = incidents?.filter((incident) => incident.type === 'FINGERPRINT')
  const serialNumberChanges = incidents?.filter((incident) => incident.type === 'SN')

  return (
    <div>
      {!fingerprintChanges?.length && !serialNumberChanges?.length && <p>{t('printer.incidents.none')}</p>}
      {!!fingerprintChanges?.length && (
        <Wrapper>
          <Paragraph>{t('printer.incident.fingerprint')}</Paragraph>
          <Paragraph noMargin>{t('printer.incident.fingerprint.history')}</Paragraph>
          <ul>
            {fingerprintChanges.map((fp) => (
              <ListItem key={fp.value}>
                {getHoursMinutesAndSeconds(fp.created)} {getDayMonthAndYear(fp.created)} - {fp.value}
              </ListItem>
            ))}
          </ul>
          <ButtonContainer spaceAround={!serialNumberChanges?.length}>
            {!serialNumberChanges?.length && (
              <Button onClick={() => deleteFingerprint(uuid)}>{t('printer.incident.sn.reset')}</Button>
            )}
            <Button onClick={() => unsetPrinterFlag({ uuid, flagName: 'incident' })}>
              {t('printer.incident.fingerprint.reset')}
            </Button>
          </ButtonContainer>
        </Wrapper>
      )}
      {!!serialNumberChanges?.length && (
        <Wrapper mt={!!fingerprintChanges?.length}>
          <Paragraph>{t('printer.incident.sn')}</Paragraph>
          <Paragraph noMargin>{t('printer.incident.sn.history')}</Paragraph>
          <ul>
            {serialNumberChanges.map((sn) => (
              <ListItem key={sn.value}>
                {getHoursMinutesAndSeconds(sn.created)} {getDayMonthAndYear(sn.created)} - {sn.value}
              </ListItem>
            ))}
          </ul>
          <ButtonContainer>
            <Button onClick={() => deleteFingerprint(uuid)}>{t('printer.incident.sn.reset')}</Button>
          </ButtonContainer>
        </Wrapper>
      )}
    </div>
  )
}
