import React, { useState } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import styled, { createGlobalStyle } from 'styled-components'

import { isDefined } from '../../../helpers/std'
import { Button } from '../../common/Button'
import { SvgIcon } from '../../common/SvgIcon'

const IconButton = styled(Button)<{ $left?: boolean }>`
  border: 1px solid var(--border-secondary);
  border-radius: 0 0.25rem 0.25rem 0;
  ${({ $left }) => ($left ? `border-right: 0; border-radius: 0.25rem 0 0 0.25rem;` : `border-left: 0;`)}
  svg {
    margin-right: 0;
  }
`

const GlobalStyles = createGlobalStyle`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
`

type Props = {
  value: number
  label?: string
  min?: number
  max?: number
  onChange?: (value: number) => void
}

export function NumberIncrementer(props: Props) {
  const { value: initialValue, label, min, max, onChange } = props
  const [value, setValue] = useState(initialValue || 0)

  const increment = () => {
    setValue((prevValue) => {
      let nextValue = (prevValue || 0) + 1
      if (isDefined(max) && max < nextValue) {
        nextValue = max
      }
      onChange?.(nextValue)
      return nextValue
    })
  }

  const decrement = () => {
    setValue((prevValue) => {
      let nextValue = (prevValue || 0) - 1
      if (isDefined(min) && min > nextValue) {
        nextValue = min
      }
      onChange?.(nextValue)
      return nextValue
    })
  }

  const valueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(e.currentTarget.value)
    setValue(newValue)
    onChange?.(newValue)
  }

  return (
    <Form.Group>
      {label && <Form.Label>{label}</Form.Label>}
      <InputGroup className="d-flex flew-nowrap">
        <IconButton type="button" onClick={decrement} $left data-operation="decrement">
          <SvgIcon icon="minusIcon" size="16" />
        </IconButton>
        <GlobalStyles />
        <Form.Control type="number" value={value} onChange={valueChange} min={min} max={max} />
        <IconButton type="button" onClick={increment} data-operation="increment">
          <SvgIcon icon="plusIcon" size="16" />
        </IconButton>
      </InputGroup>
    </Form.Group>
  )
}
