import { useConfig } from '@prusaconnect/api/services/useConfig'
import Markdown from 'markdown-to-jsx'
import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useAuthState } from '../../context/authStore'
import { ButtonAnchor } from '../common/ButtonLink'

const Title = styled.h2`
  font-size: 18px;
`

const Body = styled.div`
  font-size: 14px;
`

export const TWO_FACTOR_AUTH_LS = '2FA_MODAL_SHOWN'

export const TwoFactorAuthModal = () => {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)
  const { user, isAuthenticated } = useAuthState()
  const config = useConfig()

  useEffect(() => {
    try {
      const wasModalShown: boolean = JSON.parse(localStorage.getItem(TWO_FACTOR_AUTH_LS) || 'false')
      const termsAndConditionsSigned = user.conditions_signed
      if (
        termsAndConditionsSigned &&
        typeof user.otp_enabled === 'boolean' &&
        !user.otp_enabled &&
        !wasModalShown &&
        isAuthenticated
      ) {
        setShowModal(true)
      }
    } catch (error) {
      console.warn(`Invalid value for ${TWO_FACTOR_AUTH_LS} property in LocalStorage`)
    }
  }, [isAuthenticated, user])

  if (!config) {
    return null
  }
  const { auth } = config

  return (
    <Modal
      show={showModal}
      onHide={() => {
        setShowModal(false)
        localStorage.setItem(TWO_FACTOR_AUTH_LS, 'true')
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <Title>{t('2fa.title', '2-Step Verification recommended')}</Title>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Body>
          <p>{t('2fa.intro', 'We strongly suggest to use 2-step verification as an extra layer of protection.')}</p>
          <p>
            {t(
              '2fa.main',
              '2-Step Verification offers significant advantages in online security and reduces the risk of unauthorized access even if the password is compromised.'
            )}
          </p>
          <p>
            <Markdown>
              {t(
                '2fa.final',
                'More information how to setup 2-Step verification is available at our <a target="_blank" href="https://help.prusa3d.com/article/prusaaccount-2-step-verification_230402">help</a>.'
              )}
            </Markdown>
          </p>
        </Body>
      </Modal.Body>
      <Modal.Footer>
        <ButtonAnchor target="_blank" href={`${auth.server_url}/two-factor`}>
          {t('2fa.button.set', 'Set 2-Step Verification')}
        </ButtonAnchor>
      </Modal.Footer>
    </Modal>
  )
}
