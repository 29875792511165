import 'echarts-gl'

import { TooltipComponent, VisualMapComponent } from 'echarts/components'
import * as echarts from 'echarts/core'
import { ECharts } from 'echarts/core'
import { uniq } from 'lodash-es'
import { memo, useEffect, useRef } from 'react'
import styled, { useTheme } from 'styled-components'

import { IMeshBedLevelingPoint } from '../../api/types/printer'
import { convertDistance, DistanceUnits, THINSP } from '../../helpers/formatters'
import { useLoggedUserPreferences } from '../../hooks/useLoggedUser'
import { darkTheme, lightTheme } from '../../theme/echartsThemes'

echarts.use([TooltipComponent, VisualMapComponent])

export const DEFAULT_BED_WIDTH_MAX_VALUE = 250
export const DEFAULT_BED_HEIGHT_MAX_VALUE = 210

const generateOption = (data: IMeshBedLevelingPoint[], borderExtrems: IMblBorderExtrems, sign: DistanceUnits) => ({
  tooltip: {},
  visualMap: {
    show: false,
    dimension: 2,
    min: 0,
    max: 1,
    inRange: {
      color: [
        '#313695',
        '#4575b4',
        '#74add1',
        '#abd9e9',
        '#e0f3f8',
        '#ffffbf',
        '#fee090',
        '#fdae61',
        '#f46d43',
        '#d73027',
        '#a50026'
      ]
    }
  },
  xAxis3D: {
    type: 'value',
    min: 0,
    max: borderExtrems.xMax,
    interval: borderExtrems.yMax / 2
  },
  yAxis3D: {
    type: 'value',
    min: 0,
    max: borderExtrems.yMax,
    interval: borderExtrems.yMax / 2
  },
  zAxis3D: {
    type: 'value',
    min: -1,
    max: 1,
    axisLabel: {
      margin: 3
    }
  },
  grid3D: {
    boxHeight: 25,
    viewControl: {
      zoomSensitivity: 0
    }
  },
  series: [
    {
      type: 'surface',
      // Calculates the amount of rows/columns based on unique X, Y coordinates
      dataShape: [...Array(2)].map((_val, index) => uniq(data.map((point) => point[index])).length),
      tooltip: {
        formatter: (params: any) => {
          const x = `${convertDistance(params.data[0], sign)}${THINSP}${sign}`
          const y = `${convertDistance(params.data[1], sign)}${THINSP}${sign}`
          const z = `${convertDistance(params.data[2], sign)}${THINSP}${sign}`
          return `${params.marker} <br/>x: ${x} <br/> y: ${y} <br/>z: ${z}`
        }
      },
      data
    }
  ]
})

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`

const ChartsContainer = styled.div`
  width: 100%;
  height: 100%;
`

type IMblBorderExtrems = {
  xMax: number
  yMax: number
}

type IProps = {
  data: IMeshBedLevelingPoint[]
  borderExtrems: IMblBorderExtrems
}

export const MeshBedLeveling = memo(({ data, borderExtrems }: IProps) => {
  const myChart = useRef<ECharts | null>(null)
  const chartRef = useRef<HTMLDivElement>(null)
  const theme = useTheme()
  const units = useLoggedUserPreferences('units')

  const handleScroll = () => {
    const scrollContainer = document.querySelector('#charts-container')
    const canvasElement = scrollContainer?.querySelector('canvas')
    if (canvasElement) {
      canvasElement.onwheel = (event) => {
        window.scrollBy({
          top: event.deltaY
        })
      }
    }
  }

  useEffect(() => {
    if (chartRef.current) {
      echarts.registerTheme('dark-theme', darkTheme)
      echarts.registerTheme('light-theme', lightTheme)
      myChart.current = echarts.init(chartRef.current, theme.isDark ? 'dark-theme' : 'light-theme')
      window.onresize = () => {
        // asynchronous hack
        setTimeout(() => myChart.current?.resize())
      }
    }
  }, [])

  useEffect(() => {
    if (myChart.current) {
      myChart.current.setOption(generateOption(data, borderExtrems, units.dimensions))

      handleScroll()
    }
  }, [borderExtrems, data])

  useEffect(() => {
    if (myChart.current) {
      myChart.current.setOption(theme.isDark ? darkTheme : lightTheme)
    }
  }, [theme.isDark])

  return (
    <Wrapper>
      <ChartsContainer id="charts-container" ref={chartRef} />
    </Wrapper>
  )
})

export default MeshBedLeveling
