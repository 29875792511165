import styled, { css } from 'styled-components'

import { disabledFilter } from '../../helpers/styled'

export const Group = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  touch-action: none;
  font-size: 0.9rem;
  display: flex;
  flex-direction: column;
`

export const Label = styled.label`
  text-transform: uppercase;
  font-weight: 600;
  margin: 0 0.5rem 0 0;
`

const TRACK_WIDTH = 4
const THUMB_BORDER = 2
const THUMB_WIDTH = 24
const TRACK_CONTAINER_WIDTH = THUMB_WIDTH + THUMB_BORDER * 2

export const TrackContainer = styled.div<{ disabled?: boolean }>`
  position: relative;
  flex: 1;
  width: ${TRACK_CONTAINER_WIDTH}px;
  margin: 100px auto 0.5rem;
  ${(props) => props.disabled && 'cursor: not-allowed;'};
  ${(props) => disabledFilter(props.disabled)}
  transition: filter 100ms linear;
`

const trackBaseCss = css`
  position: absolute;
  height: 100%;
  width: ${TRACK_WIDTH}px;
  border-radius: ${TRACK_WIDTH / 2}px;
  left: ${TRACK_CONTAINER_WIDTH / 2 - TRACK_WIDTH / 2}px;
`

export const Track = styled.div`
  ${trackBaseCss};
  background-color: #d3d3d3;
`

export const FilledTrack = styled.div`
  ${trackBaseCss};
  bottom: 0;
  background-color: var(--color-primary);
`

export const ThumbContainer = styled.div`
  position: absolute;
  transform: translateY(-100%);
  left: ${TRACK_CONTAINER_WIDTH / 2 - THUMB_WIDTH / 2}px;
`

export const Thumb = styled.div<{ $isFocusVisible?: boolean; $isDragging?: boolean }>`
  width: ${THUMB_WIDTH}px;
  cursor: pointer;
`

export const ThumbInner = styled.div`
  border: ${THUMB_BORDER}px solid var(--icon-secondary);
  background-color: var(--dropdown-background);

  svg:nth-child(2) {
    margin: -4px 0;
  }
`

export const Output = styled.output`
  text-align: center;
  margin: 0.5rem 0;
`
