import { getFolderFromFilepath } from '../components/printers/storage/FileManager/utils'

export function useMaxFolderFileNameLength(path?: string, maxFilename?: number, isDev?: boolean) {
  // dev printers for testing
  if (isDev) {
    return Infinity
  }

  const maxLength = maxFilename || 248 // PrusaLink-based printers and Connect files
  const availableLength = maxLength - `${getFolderFromFilepath(path || '')}/`.length

  return availableLength
}
