import { useTranslation } from 'react-i18next'

import { getState, IConnectState } from '../../api/types/state'
import { Tag } from './Tag'

export function translateLabel(t: Function, label?: IConnectState) {
  switch (label) {
    case IConnectState.MANIPULATING:
      return t('printer.state.manipulated')
    case IConnectState.UNKNOWN:
      return t('printer.state.unknown')
    case IConnectState.OFFLINE:
      return t('printer.state.offline')
    case IConnectState.IDLE:
      return t('printer.state.idle')
    case IConnectState.READY:
      return t('printer.state.ready')
    case IConnectState.BUSY:
      return t('printer.state.busy')
    case IConnectState.PRINTING:
      return t('printer.state.printing')
    case IConnectState.PAUSED:
      return t('printer.state.paused')
    case IConnectState.STOPPED:
      return t('printer.state.stopped')
    case IConnectState.FINISHED:
      return t('printer.state.finished')
    case IConnectState.ERROR:
      return t('printer.state.error')
    case IConnectState.ATTENTION:
      return t('printer.state.attention')
    case IConnectState.POUR_IN_RESIN:
      return t('printer.state.pour-in-resin', 'pour in resin')
    case IConnectState.REFILL:
      return t('printer.state.refill', 'refill')
  }
  return t('printer.state.unknown')
}

type Props = {
  state: IConnectState
  tooltip?: string
  baseColor?: string
  inverse?: boolean
  collapsible?: boolean
}

export function PrinterStateTag(props: Props) {
  const { state, tooltip, baseColor, inverse, collapsible } = props
  const { t } = useTranslation()

  const stateConfig = getState(state)
  const backgroundColor = baseColor || stateConfig.baseColor

  const infoTooltip =
    state === IConnectState.ATTENTION
      ? t('printer.attention.tooltip', `Please check the printer's screen for more info.`)
      : tooltip

  return (
    <Tag
      title={translateLabel(t, state)}
      tooltip={infoTooltip}
      baseColor={backgroundColor}
      collapsible={collapsible}
      inverse={inverse}
    />
  )
}
