import styled, { css } from 'styled-components'

/**
 * <button> element without any styles for better accessibility
 */
export const PlainButton = styled.button<{ $expand?: boolean; disabled?: boolean }>`
  background-color: inherit;
  border: none;
  padding: 0;
  margin: 0;
  white-space: nowrap;
  text-align: center;
  justify-content: center;
  display: inline-flex;
  align-items: center;
  color: var(--color-text);

  // TODO remove with tailwindcss since it's in its reset.css
  svg {
    display: inline-block;
    vertical-align: middle;
  }

  ${(props) =>
    props.$expand &&
    css`
      width: 100%;
    `}

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      svg {
        opacity: 0.35;
      }
    `}
`
