import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { IPrinterSimpleView, IPrinterTransfer } from '../../api/types/printer'
import { ITransferFile } from '../../api/types/transfer'
import { getNameFromPath } from '../../helpers/files'
import { formatSize } from '../../helpers/formatters'
import { isDefined, isNumber } from '../../helpers/std'
import { getCurrentUnixtimestamp } from '../../helpers/timestamp'
import { ITimetype } from '../../interfaces/time'
import { FileHashPreview } from '../common/FileHashPreview'
import { FilePreview } from '../common/FilePreview'
import ScrollContainer from '../common/ScrollContainer'
import { Percents } from '../helpers/percents'
import { ellipsis } from '../helpers/string'
import { Time } from '../helpers/time'
import { YesOrNo } from '../helpers/yesOrNo'
import { CurrentTransferActions } from './CurrentTransferActions'
import { TransferActions } from './TransferActions'
import * as S from './TransferRow.styled'
import { getPrinterName } from './utils'

const ScrollableContainer = styled(ScrollContainer)`
  margin: 0 -0.7rem;
  padding: 0 0.7rem;
`

type Props = {
  printerTransfer: IPrinterTransfer | ITransferFile
  isCurrentTransfer?: boolean
  refetch?: () => void
  printer: IPrinterSimpleView
  printerName?: boolean
}

export function TransferRow(props: Props) {
  const { isCurrentTransfer, refetch, printerTransfer, printer, printerName } = props
  const { t } = useTranslation()

  if (!printerTransfer) {
    return null
  }

  const fileSize = printerTransfer.size || printerTransfer.file?.size
  const previewUrl = printerTransfer.preview_url || printerTransfer.file?.preview_url

  return (
    <S.Row>
      <S.Layout>
        <S.Left>
          <S.ColImg>
            {previewUrl ? (
              <FilePreview previewUrl={previewUrl} maxWidth="100px" />
            ) : (
              <FileHashPreview hash={printerTransfer.file?.hash || printerTransfer.hash || ''} width="100px" />
            )}
          </S.ColImg>
          <S.PreviewProgress $hasPreview={!!previewUrl}>
            <Percents value={printerTransfer.progress || 0} />
          </S.PreviewProgress>
        </S.Left>
        <S.Right $progress={printerTransfer.progress || 0}>
          <S.TitleContainer>
            <S.ProgressTitle>
              {ellipsis(
                printerTransfer.display_name || getNameFromPath(printerTransfer.display_path || printerTransfer.path),
                130
              )}
            </S.ProgressTitle>
            {isCurrentTransfer && (
              <S.ButtonIcons>
                <CurrentTransferActions printer={printer} />
              </S.ButtonIcons>
            )}
            {!isCurrentTransfer && isDefined(printerTransfer.id) && (
              <S.ButtonIcons>
                <TransferActions fileId={printerTransfer.id} refetch={refetch} />
              </S.ButtonIcons>
            )}
          </S.TitleContainer>

          <ScrollableContainer background="transparent">
            <S.FileAttributes>
              {printerName && (
                <S.Item>
                  <S.FileLabel>{t('printer.file.printer-name')}</S.FileLabel>
                  <S.Value>{getPrinterName(printer)}</S.Value>
                </S.Item>
              )}

              {isNumber(fileSize) && fileSize >= 0 && (
                <S.Item>
                  <S.FileLabel>{t('printer.file.transfer-progress.size')}</S.FileLabel>
                  <S.Value>
                    <S.Size>{formatSize(fileSize)}</S.Size>
                  </S.Value>
                </S.Item>
              )}

              {isNumber(printerTransfer.start) && (
                <S.Item>
                  <S.FileLabel>{t('printer.file.transfer-progress.start-time', 'Transfer start')}</S.FileLabel>
                  <S.Value>
                    <Time unixTimestamp={printerTransfer.start} />
                  </S.Value>
                </S.Item>
              )}

              {isNumber(printerTransfer.time_remaining) && (
                <S.Item>
                  <S.FileLabel>{t('printer.file.transfer-progress.time_remaining', 'Remaining time')}</S.FileLabel>
                  <S.Value>
                    <Time unixTimestamp={printerTransfer.time_remaining} type={ITimetype.COUNTDOWN} />
                  </S.Value>
                </S.Item>
              )}

              {isNumber(printerTransfer.time_remaining) && (
                <S.Item>
                  <S.FileLabel>{t('printer.file.transfer-progress.estimated_end', 'Estimated end')}</S.FileLabel>
                  <S.Value>
                    <Time unixTimestamp={getCurrentUnixtimestamp() + printerTransfer.time_remaining} />
                  </S.Value>
                </S.Item>
              )}

              {isDefined(printerTransfer.to_print) && (
                <S.Item>
                  <S.FileLabel>{t('printer.file.transfer-progress.to-print')}</S.FileLabel>
                  <S.Value>
                    <YesOrNo value={printerTransfer.to_print} />
                  </S.Value>
                </S.Item>
              )}
            </S.FileAttributes>
          </ScrollableContainer>
        </S.Right>
      </S.Layout>
    </S.Row>
  )
}
