import { ReactNode } from 'react'
import styled from 'styled-components'

import { AdaptiveButtonContext } from './AdaptiveButtonContext'

const Div = styled.div`
  .download-file {
    width: 100%;
  }
`

type Props = {
  children: ReactNode
}

export function ContextMenuRenderer({ children }: Props) {
  return (
    <AdaptiveButtonContext value="dropdown">
      <Div>{children}</Div>
    </AdaptiveButtonContext>
  )
}
