import { useTranslation } from 'react-i18next'

import { IJobState } from '../../api/types/job'
import { INotification, INotificationName, INotificationTransferAbortedReason } from '../../api/types/notifications'
import { escapeMarkdownCharacters } from '../../helpers/escapeMarkdownCharacters'
import { formatDuration } from '../helpers/time'

function trimFileName(name: string) {
  const NAME_LENGTH = 25
  if (name.length > NAME_LENGTH) {
    return `${name.substring(0, NAME_LENGTH)}${String.fromCharCode(0x2026)}`
  }

  return name
}

function useReasons() {
  const { t } = useTranslation()

  return {
    [INotificationTransferAbortedReason.NOT_FOUND_FILE]: t('transfer.aborted.reason.file-not-found'),
    [INotificationTransferAbortedReason.NOT_FOUND_STORAGE]: t('transfer.aborted.reason.storage-not-found'),
    [INotificationTransferAbortedReason.NOT_FOUND_FILE_IN_PRINTER]: t(
      'transfer.aborted.reason.file-not-found-in-printer'
    ),
    [INotificationTransferAbortedReason.INVALID_FILE_PATH]: t('transfer.aborted.reason.invalid-file-path'),
    [INotificationTransferAbortedReason.FORBIDDEN_MAX_STORAGE_LIMIT]: t(
      'transfer.aborted.reason.forbidden-max-storage-limit'
    ),
    [INotificationTransferAbortedReason.FORBIDDEN_READ_ONLY]: t('transfer.aborted.reason.forbidden-readonly')
  }
}

export function useNotificationBody(notification: INotification) {
  const { t } = useTranslation()
  const fileName = notification.data?.job_name ? escapeMarkdownCharacters(trimFileName(notification.data.job_name)) : ''
  const printerName = notification.data?.printer_name ? escapeMarkdownCharacters(notification.data.printer_name) : ''
  const firmware = notification.data?.firmware ? escapeMarkdownCharacters(notification.data.firmware) : ''
  const prusaLink = notification.data?.prusa_link ? escapeMarkdownCharacters(notification.data.prusa_link) : ''
  // Older notifications with only string filament_change_in value
  const timeString = notification.data?.filament_change_in
    ? escapeMarkdownCharacters(notification.data.filament_change_in)
    : ''
  const time = notification.data?.filament_change_in_sec
    ? formatDuration(t, notification.data.filament_change_in_sec, true)
    : 0

  const reasons = useReasons()

  if (notification.name === INotificationName.JOB_FINISHED && fileName && printerName) {
    if (notification.data?.job_state === IJobState.FIN_STOPPED) {
      return t('notifications.text.job-stopped', '**{printerName}**: file **{fileName}** has stopped printing', {
        printerName,
        fileName
      })
    }

    if (notification.data?.job_state === IJobState.FIN_ERROR) {
      return t('notifications.text.job-error', '**{printerName}**: file **{fileName}** has failed', {
        printerName,
        fileName
      })
    }

    // State is most likely FIN_OK
    return t('notifications.text.job-finished', '**{printerName}**: file **{fileName}** has finished printing', {
      printerName,
      fileName
    })
  }

  if (notification.name === INotificationName.PRINTER_PRINTING && fileName && printerName) {
    return t('notifications.text.job-printing', '**{printerName}**: file **{fileName}** is printing', {
      printerName,
      fileName
    })
  }

  if (notification.name === INotificationName.PRINTER_RESUMED && fileName && printerName) {
    return t('notifications.text.job-resumed', '**{printerName}**: file **{fileName}** has resumed printing', {
      printerName,
      fileName
    })
  }

  if (notification.name === INotificationName.PRINTER_PAUSED && fileName && printerName) {
    return t('notifications.text.job-paused', '**{printerName}**: file **{fileName}** is paused', {
      printerName,
      fileName
    })
  }

  if (notification.name === INotificationName.PRINTER_ATTENTION && printerName) {
    return t('notifications.text.printer-attention', '**{printerName}**: the printer needs your attention.', {
      printerName
    })
  }

  if (notification.name === INotificationName.INCIDENT_OFFLINE && printerName) {
    return t(
      'notifications.text.printer-incident-offline',
      `**{printerName}**: The printer has a problem identifying with Connect. If you have replaced the printer's motherboard or Rapsberry PI expansion board, or performed a factory reset on the printer it must be approved. Approve this change here.`,
      { printerName }
    )
  }

  if (notification.name === INotificationName.INCIDENT_OTHER && printerName) {
    return t(
      'notifications.text.printer-incident-other',
      '**{printerName}**: The printer has a problem identifying with Connect. Your settings need to be updated.',
      { printerName }
    )
  }

  if (notification.name === INotificationName.INVITATION) {
    return t('notifications.text.invitation', '**{userName}** has invited you to the **{teamName}** team.', {
      userName: notification.data?.invitor_name,
      teamName: notification.data?.team_name
    })
  }

  if (notification.name === INotificationName.FILAMENT_CHANGE_IN && fileName && printerName) {
    if (time || timeString) {
      return t(
        'notifications.text.printer-filament-change-in-time',
        '**{printerName}**: Filament needs to be replaced in **{time}** while printing the job **{fileName}**.',
        { printerName, fileName, time: time || timeString }
      )
    }
    return t(
      'notifications.text.printer-filament-change-in-a-while',
      '**{printerName}**: Filament needs to be replaced in a while during printing the job **{fileName}**.',
      { printerName, fileName }
    )
  }

  if (notification.name === INotificationName.FW_UPDATE && printerName && firmware) {
    return t(
      'notifications.text.printer-fw-update',
      `**{printerName}**: Printer's firmware **{firmware}** was updated successfully.`,
      { printerName, firmware }
    )
  }

  if (notification.name === INotificationName.LINK_UPDATE && printerName && prusaLink) {
    return t(
      'notifications.text.printer-link-update',
      `**{printerName}**: Printer's PrusaLink **{prusaLink}** was updated successfully.`,
      { printerName, prusaLink }
    )
  }

  if (notification.name === INotificationName.PRINTER_ERROR && printerName) {
    return t('notifications.text.printer-error', '**{printerName}**: An error occurred in the printer.', {
      printerName
    })
  }

  if (notification.name === INotificationName.TEST_NOTIFICATION) {
    return t(
      'notifications.text.test-notification',
      'Hi, I am your first notification, thank you for using them. You can adjust the sending of notifications according to your wishes.'
    )
  }

  if (notification.name === INotificationName.TRANSFER_ABORTED && printerName) {
    if (notification.data?.reason) {
      const reason = reasons[notification.data.reason] || notification.data.reason

      return t(
        'notifications.text.printer-transfer-aborted.not-found-file',
        '**{printerName}**: Transfer was aborted because of an error: **{reason}**.',
        { printerName, reason }
      )
    }

    return t(
      'notifications.text.printer-transfer-aborted',
      '**{printerName}**: Transfer was aborted because of an error.',
      { printerName }
    )
  }

  return notification.text // Fallback
}
