import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { ReactNode } from 'react'

type Props = {
  id: string | number
  children: ReactNode
  styles?: React.CSSProperties
}

export function SortableItem({ id, children, styles }: Props) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id })

  const customTransform = transform
    ? {
        ...transform,
        scaleY: 1
      }
    : transform

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(customTransform),
    transition,
    pointerEvents: isDragging ? 'none' : 'auto',
    ...styles
  }

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </div>
  )
}
