import { useState } from 'react'

import { intervals } from '../../../config'
import { parseSortBy, serializeSortBy } from '../../../helpers/sorting'
import { useQueryParams } from '../../../hooks/useQueryParams'
import { usePrintersService } from '../../../services/usePrintersService'
import { Container } from '../../bootstrap/Container'
import { PaceProgress } from '../../common/PaceProgress'
import { Pager } from '../../common/Pager'
import { usePrintersQueryState } from '../hooks/usePrintersQueryState'
import { CardItem } from './CardItem'
import { Cards } from './CardItem.styled'

export function CardsView() {
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(50)
  const params = useQueryParams({ decode: false }) // custom decoding of sort_by param
  const { teamId, groupId, states } = usePrintersQueryState()
  const sortItems = parseSortBy(params.sort_by ? String(params.sort_by) : '')

  const queryResult = usePrintersService(
    {
      team_id: teamId,
      group_id: groupId,
      state_include: states,
      sort_by: serializeSortBy(sortItems),
      offset,
      limit
    },
    {
      refetchInterval: intervals.printersPolling,
      keepPreviousData: true
    }
  )
  const { isLoading, data } = queryResult

  return (
    <>
      {isLoading && <PaceProgress />}
      {data && (
        <Cards>
          {data.printers.map((printer, i) => (
            <CardItem key={i} printer={printer} />
          ))}
        </Cards>
      )}

      <Container className="mb-2">
        {data && <Pager pager={data.pager} setOffset={setOffset} setLimit={setLimit} />}
      </Container>
    </>
  )
}
