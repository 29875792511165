import { useTranslation } from 'react-i18next'

import { ButtonLink } from './ButtonLink'
import { SvgIcon } from './SvgIcon'

export function AddNewPrinterButton() {
  const { t } = useTranslation()

  return (
    <ButtonLink to="/add-printer">
      <SvgIcon size={14} icon="plusIcon" />
      {t('printer.add')}
    </ButtonLink>
  )
}
