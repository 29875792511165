import { ReactElement, ReactNode } from 'react'
import { OverlayTrigger, OverlayTriggerProps, Tooltip } from 'react-bootstrap'

import { isDefined } from '../../helpers/std'

type Placement = 'left' | 'right' | 'top' | 'bottom'
type Trigger = 'hover' | 'click' | 'focus'

type Props = {
  id?: string
  children: ReactElement
  title?: ReactNode
  placement?: Placement
  trigger?: Trigger | Trigger[] // default ['hover', 'focus']
  showPopover?: boolean
}

export function WithTooltip(props: Props): JSX.Element {
  const { children, title, trigger, showPopover, placement = 'top' } = props

  if (!title) {
    return children
  }

  return (
    <OverlayTrigger
      trigger={trigger}
      rootClose
      show={isDefined(showPopover) ? showPopover : undefined}
      overlay={
        <Tooltip id={props.id || 'plain-tooltip'} placement={placement}>
          {title}
        </Tooltip>
      }
      placement={placement}
    >
      {children as OverlayTriggerProps['children']}
    </OverlayTrigger>
  )
}
