import Markdown from 'markdown-to-jsx'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const Box = styled.div`
  max-width: 768px;
  padding: 0 15px;
  margin: 0 auto;
  font-size: 0.875rem;
`

export function RequirementsPage() {
  const { t } = useTranslation()

  return (
    <Box className="mt-5">
      <Markdown>{t('minimal-requirements.text')}</Markdown>
    </Box>
  )
}
