import { ReactElement } from 'react'
import styled from 'styled-components'

const DataItems = styled.div`
  font-weight: 400;
  display: flex;
  gap: 1rem;
  align-items: center;
`

const DataItem = styled.div`
  display: flex;
  flex-direction: column;
`

const Label = styled.div`
  font-size: 0.75rem;
  color: var(--color-light);
  white-space: nowrap;
  :first-letter {
    text-transform: uppercase;
  }
`

const Value = styled.div`
  font-size: 0.9rem;
  white-space: nowrap;
`

export type DataItem = {
  label: string
  value: string | number | ReactElement
}

type Props = {
  dataItems: DataItem[]
}

export function DataItemList(props: Props) {
  return (
    <DataItems>
      {props.dataItems?.map((item) => (
        <DataItem key={item.label}>
          <Label>{item.label}</Label>
          <Value>{item.value}</Value>
        </DataItem>
      ))}
    </DataItems>
  )
}
