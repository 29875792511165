import Markdown from 'markdown-to-jsx'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { Button } from '../components/common/Button'
import { SvgIcon } from '../components/common/SvgIcon'

const ICON_SIZE = 64

export function InvitationWelcomePage(props: { team: string }) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <div className="text-center mt-5">
      <h1 className="h4 font-weight-light">{t('team.invitation.heading')}</h1>
      <SvgIcon icon="okIcon" size={ICON_SIZE} className="mt-4" />
      <h3 className="font-weight-normal lead text-lg mx-2 mt-4 mb-3">
        <Markdown>{t('team.invitation.welcome', { team: props.team })}</Markdown>
      </h3>
      <h3 className="font-weight-normal text-lg mx-2">
        {`${t('team.invitation.invited')},`}
        <p>{`${t('happy-printing')}.`}</p>
      </h3>
      <Button type="button" onClick={() => navigate('/printers')} className="mb-3">
        {t('team.invitation.printers', 'Printers overview')}
      </Button>
    </div>
  )
}
