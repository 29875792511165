import type { SeverityLevel } from '@sentry/react'

type IException = {
  name: string
  severity: SeverityLevel
  source: 'service' | 'intl'
  data: any
}

export class IntlException implements IException {
  readonly name = 'INTL'
  readonly severity = 'warning'
  readonly source = 'intl'
  time = new Date()

  constructor(
    public data: {
      lang: string
      message: string
    }
  ) {}
}

type IRequest = {
  // TODO: add or remove parameters as needed
  body?: BodyInit
  headers?: HeadersInit
  keepalive?: boolean
  method?: string
  mode?: RequestMode
}

type IResponse = {
  // TODO: add or remove parameters as needed
  headers?: Headers
  ok: boolean
  status: number
  type: ResponseType
  url: string
  redirected: boolean
}

export class ServiceHttpException implements IException {
  readonly name = 'SERVICE_HTTP_ERROR'
  readonly severity = 'log'
  readonly source = 'service'
  time = new Date()

  constructor(
    public data: {
      url: string
      request: IRequest
      response: IResponse
      payload?: any
    }
  ) {}
}

export class ServiceException implements IException {
  readonly name = 'SERVICE_ERROR'
  readonly severity = 'error'
  readonly source = 'service'
  time = new Date()

  constructor(
    public data: {
      url: string
      request: IRequest
      response?: IResponse
      error: Error
    }
  ) {}
}

export type Exception = IntlException | ServiceException | ServiceHttpException
