import React, { FormEvent, useEffect, useState } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { THINSP } from '../../../../helpers/formatters'
import { isDefined } from '../../../../helpers/std'
import { Button } from '../../../common/Button'
import { SvgIcon } from '../../../common/SvgIcon'
import { MutationStatus } from '../../../mutationStatus'

type Status = {
  isLoading: boolean
  isError: boolean
  error: any
}

type Props<S> = {
  onSave: (value: string) => void
  onCancel?: () => void
  isLoading: boolean
  isSuccess: boolean
  value: string | number
  overwrite?: boolean
  mutation?: S
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  name?: string
  type?: string
  unit?: string
  min?: number
  max?: number
}

export function EditableString<S extends Status>(props: Props<S>) {
  return <EditableInput {...props} />
}
export function EditableNumber<S extends Status>(props: Props<S>) {
  return <EditableInput {...props} />
}

export function EditableInput<S extends Status>(props: Props<S>) {
  const { isSuccess, isLoading, mutation, onChange, unit, min, max, type = 'text', overwrite = false } = props
  const [isEditing, setIsEditing] = useState(false)
  const [value, setValue] = useState(props.value)
  const { t } = useTranslation()

  const valueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.currentTarget.value)
    onChange?.(event)
  }

  const cancel = () => {
    setValue(props.value)
    setIsEditing(false)
    props.onCancel?.()
  }

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    props.onSave(value.toString())
  }

  useEffect(() => {
    if (isSuccess) {
      setIsEditing(false)
    }
  }, [isSuccess])

  useEffect(() => {
    if (!isEditing) {
      setValue(props.value)
    }
  }, [isEditing, props.value])

  useEffect(() => {
    if (isEditing) {
      const onEscape = (e: KeyboardEvent) => {
        if (e.code === 'Escape') {
          setIsEditing(false)
        }
      }
      document.addEventListener('keydown', onEscape)
      return () => document.removeEventListener('keydown', onEscape)
    }
    return () => null
  }, [isEditing])

  if (!isEditing) {
    return (
      <>
        {value}
        {isDefined(value) && unit && `${THINSP}${unit}`}
        <SvgIcon
          icon="editIcon"
          className="cursor-pointer"
          size="20"
          onClick={() => setIsEditing(true)}
          style={{ marginLeft: value ? 10 : 0 }}
        />
      </>
    )
  }

  return (
    <form className="w-full" onSubmit={onSubmit}>
      <InputGroup size="sm">
        <Form.Control
          type={type}
          min={min}
          max={max}
          name={props.name}
          value={value}
          onChange={valueChange}
          disabled={isLoading}
          autoFocus
          style={{ width: '100%' }}
        />
        <Button onClick={cancel} type="reset" disabled={isLoading}>
          <SvgIcon icon="cancelIcon" size="16" />
          {t('button.cancel')}
        </Button>
        <Button type="submit" disabled={isLoading}>
          <SvgIcon icon="saveIcon" size="16" />
          {overwrite ? t('button.file.rename.overwrite', 'Rename and overwrite') : t('button.save')}
        </Button>
      </InputGroup>
      {mutation && <MutationStatus mutation={mutation} />}
    </form>
  )
}
