import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IConnectState, isPrintingState } from '../../api/types/state'
import { SvgIcon } from '../common/SvgIcon'
import { SwitchButton } from '../common/SwitchButton'
import { WithTooltip } from '../common/WithTooltip'
import { Time } from '../helpers/time'
import * as S from './CameraDetail.styled'
import { SnapshotSwitchContainer, ToggleLabel } from './CameraDetail.styled'

export const STALE_TIME_PRINTING = 300 // 5 minutes
export const STALE_TIME_IDLE = 3600 // 1 hour

export type CameraData = {
  cameraName: string
  cameraId: number
  snapshotUrls?: string[]
  thumbnail?: string
  isRefetching: boolean
  isLoading: boolean
  timestamp?: number
}

type Props = {
  cameras: CameraData[]
  connectState: IConnectState
  setActiveCameraId?: (id: number) => void
  expandImage?: () => void
  uploadToAi?: (snapshotUrl: string) => void
  fadeStaleImage?: boolean
}

function getInitialSnapshotUrl(camera: CameraData) {
  const snapshotUrls = camera.snapshotUrls || []
  if (snapshotUrls.length > 0) {
    return snapshotUrls[0]
  }
}

export function SnapshotSwitcher(props: {
  selectedSnapshotIndex: number
  setSelectedSnapshotIndex: (index: number) => void
}) {
  const { t } = useTranslation()
  const isEnabled = Boolean(props.selectedSnapshotIndex)
  const switchSnapshot = () => {
    const updatedIndex = Number(!isEnabled)
    props.setSelectedSnapshotIndex(updatedIndex)
  }

  return (
    <SnapshotSwitchContainer>
      <ToggleLabel>{t('printer.cameras.snapshot.first', 'First')}</ToggleLabel>
      <SwitchButton
        enabled={isEnabled}
        onClick={switchSnapshot}
        title={t('printer.camera.toggle-snapshot', 'Toggle first/last snapshot')}
      />
      <ToggleLabel>{t('printer.cameras.snapshot.last', 'Last')}</ToggleLabel>
    </SnapshotSwitchContainer>
  )
}

export function CameraDetail(props: Props) {
  const [currentCameraId, setCurrentCameraId] = useState(props.cameras[0].cameraId)
  const currentCamera = props.cameras.find((camera) => camera.cameraId === currentCameraId) || props.cameras[0]
  const { t } = useTranslation()

  const activeCameraSnapshots = currentCamera.snapshotUrls?.reduce((acc: string[], currentValue) => {
    if (acc.length === 0) {
      acc.push(currentValue)
    } else {
      acc[1] = currentValue
    }
    return acc
  }, [])

  // First/last snapshot toggle
  const [selectedSnapshotIndex, setSelectedSnapshotIndex] = useState(
    activeCameraSnapshots && activeCameraSnapshots.length > 0 ? activeCameraSnapshots.length - 1 : 0
  )

  const currentSnapshotUrl = activeCameraSnapshots ? activeCameraSnapshots[selectedSnapshotIndex] : ''
  const { cameraName, isRefetching, isLoading, timestamp } = currentCamera

  const isPrinting = isPrintingState(props.connectState)
  const staleTime = isPrinting ? STALE_TIME_PRINTING : STALE_TIME_IDLE
  const isStale = timestamp ? Date.now() / 1000 - timestamp > staleTime : false

  const isMultiSnapshot = activeCameraSnapshots ? activeCameraSnapshots.length > 1 : false

  const snapshotAltText = isMultiSnapshot
    ? `${
        selectedSnapshotIndex === 0
          ? t('printer.cameras.snapshot.first', 'First')
          : t('printer.cameras.snapshot.last', 'Last')
      } ${t('printer.cameras.snapshot', 'snapshot')}`
    : `snapshot-${cameraName}-${timestamp}`

  const renderImageContents = () => {
    if (isLoading) {
      return (
        <S.EmptyImage>
          <SvgIcon icon="noCameraIcon" size={80} />
          <span>{t('printer.camera.snapshot-loading', 'Loading Camera...')}</span>
        </S.EmptyImage>
      )
    }

    if (activeCameraSnapshots?.length !== undefined && activeCameraSnapshots.length <= 0) {
      return (
        <S.EmptyImage>
          <SvgIcon icon="noCameraIcon" size={80} />
          <span>{t('printer.camera.snapshot-not-available', 'The snapshot is not available')}</span>
        </S.EmptyImage>
      )
    }

    return (
      <div className="image-contents">
        <img
          src={currentSnapshotUrl}
          alt={snapshotAltText}
          className={classNames({ stale: props.fadeStaleImage && isStale })}
        />

        {props.fadeStaleImage && isStale && (
          <div className="stale-overlay">
            {staleTime === 300
              ? t('printer.camera.stale-image-printing', 'This image is more than 5 minutes old')
              : t('printer.camera.stale-image-idle', 'This image is more than 1 hour old')}
          </div>
        )}

        {props.expandImage && (
          <button
            type="button"
            className="expand"
            onClick={props.expandImage}
            title={t('printer.camera.expand-snapshot', 'Expand snapshot')}
          >
            <SvgIcon icon="expandIcon" />
          </button>
        )}

        <div className="button-container">
          {props.uploadToAi && (
            <WithTooltip
              id="upload-to-ai"
              title={t('printer.camera.upload-to-ai', 'Upload for AI analysis')}
              placement="top"
            >
              <button type="button" onClick={() => (props.uploadToAi ? props.uploadToAi(currentSnapshotUrl) : null)}>
                <SvgIcon icon="aiIcon" />
              </button>
            </WithTooltip>
          )}

          <WithTooltip
            id="download-snapshot"
            title={t('printer.camera.download-snapshot', 'Download snapshot')}
            placement="top"
          >
            <a
              type="button"
              className="download"
              href={currentSnapshotUrl}
              download={`snapshot-${cameraName}-${timestamp}.jpg`}
              title={t('printer.camera.download-photo')}
            >
              <SvgIcon icon="downloadBlackIcon" />
            </a>
          </WithTooltip>
        </div>

        {isRefetching && <S.RefetchIndicator icon="cameraRefreshIcon" size={32} />}

        {isMultiSnapshot && (
          <SnapshotSwitcher
            selectedSnapshotIndex={selectedSnapshotIndex}
            setSelectedSnapshotIndex={setSelectedSnapshotIndex}
          />
        )}
      </div>
    )
  }

  return (
    <S.CameraDetailContainer>
      <S.CameraMetadata>
        <div className="camera-name">
          <div>{cameraName || t('printer.cameras.empty-camera-name')}</div>
        </div>
        {timestamp ? (
          <div className="snapshot-time">
            <div className="label">{t('printer.camera.snapshot-time')}</div>
            <div>
              <Time unixTimestamp={timestamp} />
            </div>
          </div>
        ) : null}
      </S.CameraMetadata>

      <S.MainImageContainer>{renderImageContents()}</S.MainImageContainer>

      {props.cameras.length > 1 && (
        <S.FilmRollContainer>
          {props.cameras.map((camera) => (
            <button
              type="button"
              key={camera.cameraId}
              className={camera.cameraId === currentCamera.cameraId ? 'active' : ''}
              onClick={() => {
                if (props.setActiveCameraId) {
                  props.setActiveCameraId(camera.cameraId)
                }
                setCurrentCameraId(camera.cameraId)
              }}
              title={camera.cameraName}
              style={{
                backgroundImage: `url(${camera.thumbnail})`
              }}
            >
              {!getInitialSnapshotUrl(camera) && <SvgIcon icon="noCameraIcon" size={40} />}
            </button>
          ))}
        </S.FilmRollContainer>
      )}
    </S.CameraDetailContainer>
  )
}
