import { useTranslation } from 'react-i18next'

import { IGroupDetail, IGroupPrinter } from '../../../../api/types/groups'
import { areVectorsSame } from '../../../../api/types/vector'
import { DEFAULT_GRID_MODE } from '../../../../config'
import { GridMode } from '../../../../constants/grid'
import { range } from '../../../../helpers/std'
import { IGrid } from '../../../../interfaces/grid'
import { Row } from '../../../bootstrap/Row'
import { Cell } from './Cell'
import * as S from './Grid.styled'
import { NonpositionedPrinter } from './NonpositionedPrinter'

type Props = {
  group: IGroupDetail
  printers: IGroupPrinter[]
  size: IGrid
  shifted?: boolean
  mode?: GridMode
}

/**
 * Grid
 * +-----+-----+
 * | x=1 | x=2 |
 * | z=3 | z=3 |
 * +-----+-----+
 * |     |     |
 * |     |     |
 * +-----+-----+
 * | x=1 |     |
 * | z=1 |     |
 * +-----+-----+
 */

/**
 * Shifted grid
 *       +-----+
 *       | x=2 |
 * +-----| z=3 |
 * | x=1 +-----+
 * | z=3 | x=2 |
 * +-----| z=2 |
 * | x=1 +-----+
 * | z=2 | x=2 |
 * +-----| z=1 |
 * | x=1 +-----+
 * | z=1 |
 * +-----+
 */

export function Grid(props: Props) {
  const { group, printers, shifted, mode = DEFAULT_GRID_MODE, size } = props
  const { t } = useTranslation()
  const nonPositionedPrinters = printers.filter((p) => !p.group_info?.position)

  return (
    <>
      <Row noGutters style={{ padding: '0 30px' }}>
        {t('printer.group.occupied-sized', {
          occupied: group.printer_count,
          left: size.row * size.col - group.printer_count
        })}
      </Row>
      <S.ScrollableWrapper fluid>
        {range(0, size.col)
          .map((col) => col + 1)
          .map((x) => (
            <S.Column key={x} $shifted={shifted} $mode={mode}>
              {range(size.row - 1, -1, -1)
                .map((row) => row + 1)
                .map((z) => (
                  <Cell
                    key={`${x},${z}`}
                    position={{ x, y: 1, z }}
                    printer={printers.find((p) => areVectorsSame(p.group_info?.position, { x, y: 1, z }))}
                  />
                ))}
            </S.Column>
          ))}
        {nonPositionedPrinters.map((printer) => (
          <NonpositionedPrinter key={printer.uuid} printer={printer} />
        ))}
      </S.ScrollableWrapper>
    </>
  )
}
