import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import Markdown from 'markdown-to-jsx'
import React, { useEffect, useMemo, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Route, Routes, useNavigate, useParams } from 'react-router'

import { useApiClient } from '../../../api/react'
import {
  IChannelNotification,
  INotificationChannelsSettingsQuery,
  INotificationSettings,
  INotificationSettingsQuery,
  ITeamSettings,
  NotificationMediumLayerTypes
} from '../../../api/types/notifications'
import { useToast } from '../../../context/toastStore'
import { useErrorHandler } from '../../../hooks/errors/useErrorHandler'
import { useLoggedUser } from '../../../hooks/useLoggedUser'
import { usePushNotificationService } from '../../../services/usePushNotificationService'
import { ConfirmModal } from '../../common/ConfirmModal'
import { EmptyList } from '../../common/EmptyList'
import { PaceProgress } from '../../common/PaceProgress'
import { SwitchButton } from '../../common/SwitchButton'
import { ChannelsTabs, TeamsTabs } from '../../common/Tabs'
import { ResponsiveTable, Th } from '../../helpers/responsiveTable'
import { PageContainer } from '../../helpers/styled'
import { EndpointStatus } from './EndpointStatus'
import { MediumChatId } from './MediumChatId'
import { MediumDeleteButton } from './MediumDeleteButton'
import { MediumEndpoint } from './MediumEndpoint'
import { MediumToken } from './MediumToken'
import { NotificationChannelRow } from './NotificationChannelRow'
import * as S from './NotificationsTabs.styled'

const GET_NOTIFICATION_SETTINGS_KEY = 'get-notification-settings'

function getTelegramEndpoint(token: string) {
  return `https://api.telegram.org/bot${token}/sendMessage`
}

function useTranslations() {
  const { t } = useTranslation()

  const mediumChannels: { [key: string]: string } = {
    browser: t('notifications.settings.medium.browser'),
    telegram: t('notifications.settings.medium.telegram'),
    slack: t('notifications.settings.medium.slack'),
    discord: t('notifications.settings.medium.discord'),
    email: t('notifications.settings.medium.email')
  }

  return { mediumChannels }
}

// Index route to redirect from /notifications/settings to first available team tab eg. /notifications/settings/:teamId
function Index() {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const data = queryClient.getQueryData<INotificationChannelsSettingsQuery>([GET_NOTIFICATION_SETTINGS_KEY])

  useEffect(() => {
    if (data) {
      const firstTab = data.teams[0]
      navigate(`${firstTab.id.toString()}`, { replace: true })
    }
  }, [navigate, data])

  return <PaceProgress />
}

// Index route to redirect from /notifications/settings/:teamId to first available channel tab eg. /notifications/settings/:teamId/email
function SubIndex() {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const data = queryClient.getQueryData<INotificationChannelsSettingsQuery>([GET_NOTIFICATION_SETTINGS_KEY])

  useEffect(() => {
    if (data) {
      const firstTab = data.teams[0]
      navigate(Object.keys(firstTab.severity)[0], { replace: true })
    }
  }, [navigate, data])

  return <PaceProgress />
}

function TeamsChannelsSettings({
  teams,
  teamId,
  media
}: {
  teams: ITeamSettings[]
  teamId: number
  media: INotificationSettings
}) {
  const { t } = useTranslation()
  const { mediumChannels } = useTranslations()
  const api = useApiClient()
  const { isSubscribed } = usePushNotificationService()
  const { mutate, isLoading } = useMutation((params: IChannelNotification) =>
    api.app.notifications.editTeamSettings(teamId, params)
  )
  const queryClient = useQueryClient()

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: [GET_NOTIFICATION_SETTINGS_KEY] })
  }, [isSubscribed, queryClient])

  const active = teams.find((team) => team.id === teamId)

  if (!active) {
    return null
  }

  const doMutate = (params: IChannelNotification, index: number) => {
    const settings = { ...active.severity }
    Object.entries(settings).forEach((entry, i) => {
      if (i === index) {
        settings[entry[0]] = params[entry[0]]
      }
    })

    mutate(settings, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [GET_NOTIFICATION_SETTINGS_KEY] })
      }
    })
  }

  return (
    <S.Row>
      <S.SetupTable breakpoint="xs">
        <thead>
          <tr>
            <Th />
            <Th>{t('notifications.table.info')}</Th>
            <Th>{t('notifications.table.attention')}</Th>
            <Th>{t('notifications.table.error')}</Th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(active.severity).map((notification, index) => {
            const channelName = notification[0]
            const hasSettings = channelName in media
            const enabled = channelName === 'browser' || hasSettings
            if (channelName !== 'email') {
              // TODO - remove condition
              return (
                <NotificationChannelRow
                  key={channelName}
                  name={channelName}
                  title={mediumChannels[channelName]}
                  severities={notification[1]}
                  isLoading={isLoading}
                  onClick={(params) => doMutate(params, index)}
                  enabled={enabled}
                />
              )
            }
            return null
          })}
        </tbody>
      </S.SetupTable>
    </S.Row>
  )
}

function UserChannelsSettings({
  active,
  media
}: {
  active: NotificationMediumLayerTypes
  media: INotificationSettings
}) {
  const { t } = useTranslation()
  const { mediumChannels } = useTranslations()
  const user = useLoggedUser()
  const api = useApiClient()
  const errorHandler = useErrorHandler()
  const toast = useToast()
  const [endpoint, setEndpoint] = useState('')
  const { isSubscribed, isSupported, unsubscribe, handleNotificationRequest } = usePushNotificationService()
  const [chatId, setChatId] = useState('')
  const [showUnblockModal, setShowUnblockModal] = useState(false)
  const queryClient = useQueryClient()

  const mutation = useMutation(
    (params: INotificationSettingsQuery) => api.app.notifications.addMediumEndpoint(params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [GET_NOTIFICATION_SETTINGS_KEY] }).then(() => {
          setEndpoint('')
          setChatId('')
          toast.add(
            t('notifications.settings'),
            t('notifications.settings.success', { channel: mediumChannels[active] })
          )
        })
      },
      onError: (error: any) => {
        errorHandler(error.response, error)
      }
    }
  )

  const onSubmit = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault()

    if (active === 'browser') {
      return
    }

    const body: INotificationSettingsQuery = {
      medium: active as NotificationMediumLayerTypes,
      endpoint
    }
    if (active === 'telegram') {
      body.chat_id = chatId
      body.endpoint = getTelegramEndpoint(endpoint)
    }

    mutation.mutate(body)
  }

  const { mutate: deleteMedium } = useMutation((id: number) => api.app.notifications.deleteUserChannelSettings(id), {
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: [GET_NOTIFICATION_SETTINGS_KEY] }).then(() => {
        setEndpoint('')
        setChatId('')
        toast.add(t('notifications.medium'), t('notifications.medium.delete.success'))
      })
    },
    onError: (error: any) => {
      errorHandler(error.response, error)
    }
  })

  const handleSubscription = async () => {
    if (!('Notification' in window)) {
      return
    }

    switch (Notification.permission) {
      case 'denied':
        return setShowUnblockModal(true)
      case 'granted':
      case 'default':
        return handleNotificationRequest()
    }
  }

  const toggleSwitchButton = () => {
    if (!isSubscribed) {
      handleSubscription()
    } else {
      unsubscribe()
    }
  }

  const renderEndpoints = () => {
    return (
      <>
        {media[active]?.map((medium) => (
          <tr key={medium.endpoint}>
            <EndpointStatus status={medium.status} />
            <S.FirstCol>
              <MediumEndpoint readonly value={medium.endpoint} />
            </S.FirstCol>
            <S.ButtonsCol>
              <S.Buttons>
                <MediumDeleteButton
                  bodyText={t('notifications.medium.delete.confirm-body')}
                  btnText={t('button.delete')}
                  deleteMedium={() => deleteMedium(medium.id)}
                />
              </S.Buttons>
            </S.ButtonsCol>
          </tr>
        ))}
        <tr>
          <S.StatusCol />
          <S.FirstCol>
            <MediumEndpoint value={endpoint} onChange={(e) => setEndpoint(e.currentTarget.value)} />
          </S.FirstCol>
          <S.ButtonsCol>
            <S.Buttons>
              <S.LoadingSaveButton
                type="submit"
                className="ml-auto"
                disabled={mutation.isLoading}
                isLoading={mutation.isLoading}
              >
                {t('button.save-new')}
              </S.LoadingSaveButton>
            </S.Buttons>
          </S.ButtonsCol>
        </tr>
      </>
    )
  }

  const renderEndpointsWithChatIds = () => {
    return (
      <>
        {media[active]?.map((medium) => (
          <tr key={medium.endpoint}>
            <EndpointStatus status={medium.status} />
            <S.FirstCol>
              <MediumToken value={medium.endpoint} readonly />
            </S.FirstCol>
            <S.SecondCol>
              <MediumChatId value={medium.chat_id} readonly />
            </S.SecondCol>
            <S.ButtonsCol>
              <S.Buttons>
                <MediumDeleteButton
                  bodyText={t('notifications.medium.delete.confirm-body')}
                  btnText={t('button.delete')}
                  deleteMedium={() => deleteMedium(medium.id)}
                />
              </S.Buttons>
            </S.ButtonsCol>
          </tr>
        ))}
        <tr>
          <S.StatusCol />
          <S.FirstCol>
            <MediumToken value={endpoint} onChange={(e) => setEndpoint(e.currentTarget.value)} />
          </S.FirstCol>
          <S.SecondCol>
            <MediumChatId value={chatId} onChange={(e) => setChatId(e.currentTarget.value)} />
          </S.SecondCol>
          <S.ButtonsCol>
            <S.Buttons>
              <S.LoadingSaveButton
                type="submit"
                className="ml-auto"
                disabled={mutation.isLoading}
                isLoading={mutation.isLoading}
              >
                {t('button.save-new')}
              </S.LoadingSaveButton>
            </S.Buttons>
          </S.ButtonsCol>
        </tr>
      </>
    )
  }

  const pushNotificationStatusText = useMemo(() => {
    if (!isSupported) {
      return t('notifications.settings.browser.not-supported')
    }

    return isSubscribed ? t('notifications.settings.browser.on') : t('notifications.settings.browser.off')
  }, [t, isSupported, isSubscribed])

  let columnLabel = ''

  switch (active) {
    case 'telegram':
      columnLabel = t('notifications.medium.token-label', 'API token')
      break
    case 'slack':
    case 'discord':
      columnLabel = t('notifications.medium.endpoint-label')
      break
  }

  return (
    <>
      <S.Row>
        <S.ChannelForm onSubmit={onSubmit}>
          <ResponsiveTable borderless breakpoint="sm" className="w-auto">
            <thead>
              <tr>
                <Th />
                <Th>{columnLabel}</Th>
                {active === 'telegram' && <Th>{t('notifications.medium.chat-id-label')}</Th>}
                <Th />
              </tr>
            </thead>
            <tbody>
              {(active === 'slack' || active === 'discord') && renderEndpoints()}
              {active === 'telegram' && renderEndpointsWithChatIds()}
              {active === 'browser' && (
                <tr>
                  <S.Value>
                    <SwitchButton onClick={toggleSwitchButton} enabled={isSubscribed} />
                  </S.Value>
                  <S.Label>{pushNotificationStatusText}</S.Label>
                </tr>
              )}
              {active === 'email' && (
                <tr>
                  <S.FirstCol>
                    <Form.Group controlId="email">
                      <Form.Label>{t('notifications.medium.email-label')}</Form.Label>
                      <Form.Control value={user.email} readOnly />
                    </Form.Group>
                  </S.FirstCol>
                </tr>
              )}
            </tbody>
          </ResponsiveTable>
        </S.ChannelForm>
      </S.Row>
      {showUnblockModal && (
        <ConfirmModal
          onCancel={() => setShowUnblockModal(false)}
          onConfirm={() => setShowUnblockModal(false)}
          title={t('notifications.blocked-title')}
          body={t('notifications.blocked-body')}
          noChoice
        />
      )}
    </>
  )
}

function ChannelsContent({
  teams,
  channelType,
  teamId,
  media
}: {
  teams: ITeamSettings[]
  channelType: string
  teamId: number
  media: INotificationSettings
}) {
  const { t } = useTranslation()
  const { mediumChannels } = useTranslations()

  // First team notifications
  const channels = Object.keys(teams[0].severity)
  const channelsNames = channels
    // TODO - remove filter
    .filter((name) => name !== 'email')
    .map((name) => {
      return { title: mediumChannels[name], name }
    })
  const active: string = channels.find((tab) => tab === channelType) || ''

  return (
    <>
      <section>
        {teams.length > 1 && (
          <>
            <S.H2>{t('notifications.settings.sending.setup')}</S.H2>
            <TeamsTabs tabs={teams} active={teamId} />
          </>
        )}
        <TeamsChannelsSettings teams={teams} teamId={teamId} media={media} />
      </section>
      <section>
        <S.H2>{t('notifications.settings.channels.setup')}</S.H2>
        <ChannelsTabs tabs={channelsNames} active={active} />
        <UserChannelsSettings key={active} active={active as NotificationMediumLayerTypes} media={media} />
      </section>
    </>
  )
}

function TabsSections({ teams, media }: { teams: ITeamSettings[]; media: INotificationSettings }) {
  const queryParams = useParams<{ '*': string; teamId: string }>()
  const teamId = Number(queryParams.teamId)
  const channelType = queryParams['*']

  if (!teamId) {
    return null
  }

  return (
    <Routes>
      <Route index element={<SubIndex />} />
      <Route
        path=":channelType"
        element={<ChannelsContent teams={teams} media={media} channelType={channelType || ''} teamId={teamId} />}
      />
    </Routes>
  )
}

export function NotificationsTabs() {
  const { t } = useTranslation()
  const api = useApiClient()

  const { data, isError, isSuccess } = useQuery([GET_NOTIFICATION_SETTINGS_KEY], () =>
    api.app.notifications.getChannelsSettings()
  )

  const renderContent = () => {
    if (isError) {
      return <EmptyList title={t('notifications.settings.cannot-load-channels-settings')} />
    }

    if (isSuccess && data && data.teams.length > 0 && data.media) {
      return <TabsSections teams={data.teams} media={data.media} />
    }

    // Still loading
    return <PaceProgress />
  }

  return (
    <PageContainer>
      <S.H1>{t('notifications.settings.title')}</S.H1>
      <S.P>
        <Markdown>{t('notifications.settings.description')}</Markdown>
      </S.P>
      <Routes>
        <Route index element={<Index />} />
        <Route path=":teamId/*" element={renderContent()} />
      </Routes>
    </PageContainer>
  )
}
