import styled from 'styled-components'

import { IGroupPattern } from '../../../api/types/groups'
import { IPrintersQuery } from '../../../api/types/printer'
import { areVectorsSame } from '../../../api/types/vector'
import { intervals } from '../../../config'
import { usePrintersStateContext } from '../../../context/printersStateContext'
import { range } from '../../../helpers/std'
import { useFullGroupDetail } from '../../../hooks/useGroupDetail'
import { HexagonalGrid, HexagonalGridItem } from '../../common/HexagonalGrid'
import { PaceProgress } from '../../common/PaceProgress'
import { GridEmptyCell } from '../grid/GridEmptyCell'
import { GridPrinter } from '../grid/GridPrinter'
import { NormalGrid } from '../grid/NormalGrid'
import { usePrintersQueryState } from '../hooks/usePrintersQueryState'
import { NoPrinters } from '../NoPrinters'

const HEX_WIDTH = 172
const MARGIN = 10

const GridContainer = styled.div`
  margin: 2rem 1rem 1rem;
`
const NonPositionedPrinter = styled.div`
  margin: 1rem 0;
`

type QueryWithGroup = IPrintersQuery & { group_id: number }

function Content({ query }: { query: QueryWithGroup }) {
  const includedStates = query.state_include || []
  const { setGroup } = usePrintersStateContext()
  const { data } = useFullGroupDetail(query.group_id, includedStates, {
    refetchInterval: intervals.printersPolling,
    onSuccess: (response) => setGroup(response.group)
  })

  if (!data) {
    return <PaceProgress />
  }

  const {
    group: { size },
    groupPrinters: { printers }
  } = data

  if (printers.length === 0) {
    return <NoPrinters />
  }

  const nonPositionedPrinters = printers.filter((p) => !p.group_info?.position)

  // Virtual group with no size -> regular grid
  if (!size) {
    return (
      <NormalGrid>
        {printers.map((printer) => (
          <GridPrinter key={printer.uuid} printer={printer} />
        ))}
      </NormalGrid>
    )
  }

  // Shifted farm -> hexagonal grid
  if (size.pattern === IGroupPattern.SHIFTED) {
    return (
      <>
        <HexagonalGrid fixedGridSize cols={size.x} itemWidth={HEX_WIDTH} margin={MARGIN}>
          {range(size.z, 0, -1).map((z) =>
            range(1, size.x + 1).map((x) => {
              const printer = printers.find((printer) => areVectorsSame(printer.group_info?.position, { x, y: 1, z }))
              if (printer) {
                return (
                  <HexagonalGridItem key={printer.uuid}>
                    <GridPrinter printer={printer} />
                  </HexagonalGridItem>
                )
              }
              return (
                <HexagonalGridItem key={`${x},${z}`}>
                  <GridEmptyCell />
                </HexagonalGridItem>
              )
            })
          )}
        </HexagonalGrid>
        {nonPositionedPrinters.map((p) => (
          <NonPositionedPrinter key={p.uuid}>
            <GridPrinter printer={p} />
          </NonPositionedPrinter>
        ))}
      </>
    )
  }

  // Regular sized grid
  return (
    <>
      <NormalGrid $cols={size.x}>
        {range(size.z, 0, -1).map((z) =>
          range(1, size.x + 1).map((x) => {
            const printer = printers.find((printer) => areVectorsSame(printer.group_info?.position, { x, y: 1, z }))
            if (printer) {
              return <GridPrinter key={printer.uuid} printer={printer} />
            }
            return <GridEmptyCell key={`${x},${z}`} />
          })
        )}
      </NormalGrid>
      {nonPositionedPrinters.map((p) => (
        <NonPositionedPrinter key={p.uuid}>
          <GridPrinter printer={p} />
        </NonPositionedPrinter>
      ))}
    </>
  )
}

export function GridView() {
  const { groupId, teamId, states } = usePrintersQueryState()

  if (!groupId) {
    return null
  }

  const query: QueryWithGroup = {
    state_include: states,
    team_id: teamId,
    group_id: groupId
  }

  return (
    <GridContainer>
      <Content query={query} />
    </GridContainer>
  )
}
