type Props = {
  statusCode: number
  statusMessage: string
}

export function SimpleError(props: Props) {
  return (
    <div className="d-flex justify-content-center align-items-center mt-5">
      <h1 className="mr-3 pr-3 align-top border-right inline-block align-content-center">{props.statusCode}</h1>
      <div className="inline-block align-middle">
        <h2 className="font-weight-normal lead">{props.statusMessage}</h2>
      </div>
    </div>
  )
}
