import styled from 'styled-components'

import { IPrinter } from '../../api/types/printer'
import { useIsFdm } from '../../hooks/usePrinterType'
import { Submenu } from '../helpers/submenu'
import { IncidentIcon } from './IncidentIcon'
import { PrinterParams } from './PrinterParams'
import { SlaPrinterParams } from './SlaPrinterParams'
import { ScrollableContainer } from './storage/FileManager/styled'

const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  box-sizing: border-box;
  padding: 0 15px; // same as <Container>
  user-select: none;
`

const StyledScrollContainer = styled(ScrollableContainer)`
  width: auto;
`

export function FooterTopBar(props: IPrinter) {
  const isFdm = useIsFdm(props.printer_type)

  return (
    <Submenu>
      <FooterContainer className="container">
        <StyledScrollContainer>
          {isFdm ? <PrinterParams {...props} footer /> : <SlaPrinterParams {...props} />}
        </StyledScrollContainer>
        <IncidentIcon />
      </FooterContainer>
    </Submenu>
  )
}
