import { CSSProperties } from 'styled-components'

import { AvailablePrinterTypes } from '../../api/types/printer'
import IconIx from '../../svg/icons/printers/ix.svg'
import IconSlMedicalOne from '../../svg/icons/printers/m1.svg'
import IconMini from '../../svg/icons/printers/mini.svg'
import IconMk3 from '../../svg/icons/printers/mk3.svg'
import IconMk3s from '../../svg/icons/printers/mk3s.svg'
import IconMk3sMmu3 from '../../svg/icons/printers/mk3smmu3.svg'
import IconMk4 from '../../svg/icons/printers/mk4.svg'
import IconMk25 from '../../svg/icons/printers/mk25.svg'
import IconMk25s from '../../svg/icons/printers/mk25s.svg'
import IconMk35 from '../../svg/icons/printers/mk35.svg'
import IconMk39 from '../../svg/icons/printers/mk39.svg'
import IconSl1 from '../../svg/icons/printers/sl1.svg'
import IconTrilabDeltiq2 from '../../svg/icons/printers/trilab_deltiq_2.svg'
import IconTrilabDeltiq2Plus from '../../svg/icons/printers/trilab_deltiq_2_plus.svg'
import Unknown from '../../svg/icons/printers/unknown.svg'
import IconXl from '../../svg/icons/printers/xl.svg'
import IconXl2Tools from '../../svg/icons/printers/xlmultitool2.svg'
import IconXl3Tools from '../../svg/icons/printers/xlmultitool3.svg'
import IconXl4Tools from '../../svg/icons/printers/xlmultitool4.svg'
import IconXl5Tools from '../../svg/icons/printers/xlmultitool5.svg'

type Props = {
  size?: number
  type?: string
  title?: string
  style?: CSSProperties
  slots?: number
}

export function getUrl(type?: string, slots?: number) {
  // MK3 and MK4 MMU icons
  if (slots) {
    switch (type) {
      case AvailablePrinterTypes.Original_Prusa_i3_MK3:
      case AvailablePrinterTypes.Original_Prusa_i3_MK3S:
      case AvailablePrinterTypes['Original_Prusa_i3_MK3.5']:
      case AvailablePrinterTypes['Original_Prusa_i3_MK3.9']:
        return IconMk3sMmu3
      // case AvailablePrinterTypes.Original_Prusa_i3_MK4:
      //   return IconMk4Mmu3
      case AvailablePrinterTypes.Original_Prusa_XL:
        switch (slots) {
          case 2:
            return IconXl2Tools
          case 3:
            return IconXl3Tools
          case 4:
            return IconXl4Tools
          case 5:
            return IconXl5Tools
        }
    }
  }

  switch (type) {
    case '1.2.5':
      return IconMk25
    case '1.2.6':
      return IconMk25s
    case '1.3.0':
      return IconMk3
    case '1.3.1':
      return IconMk3s
    case '1.3.5':
      return IconMk35
    case '1.3.9':
      return IconMk39
    case '1.4.0':
      return IconMk4
    case '2.1.0':
      return IconMini
    case '3.1.0':
      return IconXl
    case '4.1.0':
      return IconIx
    case '5.1.0':
    case '5.1.1': // TODO other svg
      return IconSl1
    case '5.2.0':
      return IconSlMedicalOne
    case '6.2.0':
      return IconTrilabDeltiq2
    case '6.2.1':
      return IconTrilabDeltiq2Plus
    case '7.1.0':
      return Unknown
    case '7.2.0':
      return Unknown
    case '7.2.1':
      return Unknown
  }

  return Unknown
}

export function PrinterIcon({ type, size, title, style, slots }: Props) {
  const src = getUrl(type, slots)

  return <img style={style} src={src} width={size} height={size} alt={title} />
}
