import { useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query'

import { useApiClient } from '../api/react'
import { ICamera, ICameraOrigin } from '../api/types/cameras'
import { PrinterUuid } from '../api/types/printer'
import { QueryKeys } from '../components/printers/utils'
import { intervals } from '../config'

export function usePrinterCamerasService(printerUuid: PrinterUuid) {
  const api = useApiClient()

  const {
    data: cameraList,
    isLoading,
    refetch
  } = useQuery([QueryKeys.CAMERA_LIST_QUERY, printerUuid], () => api.app.cameras.getPrinterCameras(printerUuid), {
    refetchInterval: intervals.cameraListPolling
  })

  const registerMutation = useMutation(
    (origin?: ICameraOrigin) => api.app.cameras.registerCamera(printerUuid, origin),
    {
      onSuccess: () => refetch()
    }
  )

  const removeMutation = useMutation((cameraId: number) => api.app.cameras.removeCamera(cameraId), {
    onSuccess: () => refetch()
  })

  const updateMutation = useMutation(
    (variables: { cameraId: number; data: ICamera }) =>
      api.app.cameras.updateCamera(variables.cameraId, variables.data),
    { onSuccess: () => refetch() }
  )

  const registeredCameras = cameraList?.cameras.filter((camera) => camera.registered) || []

  return {
    registerCamera: registerMutation,
    removeCamera: removeMutation,
    updateCamera: updateMutation,
    cameraList,
    registeredCameras,
    isLoading
  }
}

export function useCurrentSnapshots(cameraIdList: number[]) {
  const { getLatestThumbnail, getLatestSnapshot } = useApiClient().app.cameras

  const thumbnails = useQueries({
    queries: cameraIdList.map((cameraId) => ({
      queryKey: [QueryKeys.THUMBNAIL_QUERY, cameraId],
      queryFn: () => getLatestThumbnail(cameraId),
      refetchInterval: intervals.cameraThumbnailPolling
    }))
  })

  const fullSnapshots = useQueries({
    queries: cameraIdList.map((cameraId) => ({
      queryKey: [QueryKeys.SNAPSHOT_QUERY, cameraId],
      queryFn: () => getLatestSnapshot(cameraId)
    }))
  })

  return {
    thumbnails,
    fullSnapshots
  }
}

export function usePolling(cameraIdList: number[]) {
  const queryClient = useQueryClient()

  return {
    updateCurrentCamera: (currentCameraId: number) => {
      cameraIdList.forEach((cameraId) => {
        queryClient.setQueryDefaults([QueryKeys.SNAPSHOT_QUERY, cameraId], {
          refetchInterval: false
        })
      })

      queryClient.setQueryDefaults([QueryKeys.SNAPSHOT_QUERY, currentCameraId], {
        refetchInterval: intervals.activeCameraPolling
      })

      queryClient.invalidateQueries([QueryKeys.SNAPSHOT_QUERY, currentCameraId])
    }
  }
}
