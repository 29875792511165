import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { useApiClient } from '../api/react'
import { ForbiddenErrors, IQueryError } from '../api/types/errors'
import { ContainerTitle } from '../components/common/ContainerTitle'
import { PaceProgress } from '../components/common/PaceProgress'
import { SimpleError } from '../components/errors/SimpleError'
import { ResponsiveTable } from '../components/helpers/responsiveTable'
import { PageContainer } from '../components/helpers/styled'

const Tr = styled.tr`
  td {
    cursor: pointer;
    color: var(--color-primary);
  }

  &:hover td {
    color: var(--color-secondary);
  }
`

export function AgentsListPage() {
  const api = useApiClient()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { data, isError, error } = useQuery(['/agents'], () => api.app.agents.getAgents())

  if (!data) {
    return <PaceProgress />
  }

  if (isError) {
    const queryError = error as IQueryError | undefined

    if (queryError?.code === ForbiddenErrors.FORBIDDEN_FARM_ONLY) {
      return <SimpleError statusCode={403} statusMessage={t('error.403')} />
    }
  }

  const handleRowClick = (agentId: number) => {
    navigate(`/agents/${agentId}`)
  }

  return (
    <PageContainer>
      <ContainerTitle>Agents</ContainerTitle>
      <ResponsiveTable>
        <thead>
          <tr>
            <th>#</th>
            <th>Agent</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {data.agents.map((agent) => (
            <Tr key={agent.id} onClick={() => handleRowClick(agent.id)}>
              <td>{agent.id}</td>
              <td>{agent.name}</td>
            </Tr>
          ))}
        </tbody>
      </ResponsiveTable>
    </PageContainer>
  )
}
