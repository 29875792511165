import { stringify } from 'query-string'

import { DEFAULT_SORT, ISortCol } from '../components/printers/filters/Sorting'
import { usePrintersStateContext } from '../context/printersStateContext'
import { SEPARATOR } from '../helpers/sorting'

export function usePrintersUrl() {
  const { filter, view } = usePrintersStateContext()
  const serialized = filter.sort_by

  const printersUrl = `/printers/${view}?${stringify(
    {
      ...filter,
      sort_by: serialized || `+${ISortCol.STATE}${encodeURIComponent(SEPARATOR)}${DEFAULT_SORT}`
    },
    { arrayFormat: 'bracket', encode: false }
  )}`

  return printersUrl
}
