import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { textEllipsis } from '../../helpers/styled'

export const Header = styled.div<{ $background: string }>`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.$background};
  font-size: 1rem;
  font-weight: 700;
  padding: 0.5rem 0.5rem;
`

export const Name = styled.div`
  ${textEllipsis}
`

export const Container = styled(Link)<{
  $background?: string
  $progress?: number
  $hoverColor?: string
  $progressColor?: string
  $isTransparent?: boolean
}>`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  background-color: var(--card-item-background);

  background-color: ${(props) => props.$background};
  background: linear-gradient(
    to right,
    ${(props) => props.$progressColor} ${(props) => props.$progress || 0}%,
    ${(props) => props.$background} 0%
  );

  opacity: ${(props) => (props.$isTransparent ? 0.5 : 1)};

  color: var(--color-secondary);
  &:hover {
    color: var(--color-secondary);
    .card-header {
      background-color: ${(props) => props.$hoverColor};
    }
  }
`

export const PrinterType = styled.div`
  font-weight: 500;
  font-size: 0.75rem;
`
export const Firmware = styled.div`
  font-weight: 500;
  font-size: 0.8125rem;
`

export const Content = styled.div`
  padding: 0.5rem;
  min-height: 150px;
`

export const Params = styled.div`
  align-self: flex-start;
  width: 100%;
  display: grid;
  gap: 0.25rem 0.5rem;
  grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
`

const paramLabelStyle = css`
  font-size: 0.8125rem;
  color: var(--color-light);
  font-weight: 300;
  text-transform: uppercase;
`

export const ParamLabel = styled.div`
  ${paramLabelStyle}
`

export const ParamValue = styled.div<{ $large?: boolean }>`
  font-size: ${(props) => (props.$large ? '2rem' : '1rem')};
  font-weight: 500;
`

export const FileTitleControls = styled.div`
  ${paramLabelStyle}
  display: flex;
  justify-content: space-between;
`

export const Cards = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(320px, 375px));
  gap: 1rem;
  padding: 1rem;
`

export const NoJobInfo = styled.div`
  font-weight: 500;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const JobContainer = styled.div<{ $background?: string }>``
