export enum Globals {
  VAPID_PUBLIC_KEY = 'VAPID_PUBLIC_KEY',
  ENVIRONMENT = 'ENVIRONMENT',
  OUTAGE_MESSAGE = 'OUTAGE_MESSAGE',
  AI_DETECTOR_API_URL = 'AI_DETECTOR_API_URL'
}

type customWindow = {
  [Globals.VAPID_PUBLIC_KEY]?: string
  [Globals.ENVIRONMENT]?: string
  [Globals.OUTAGE_MESSAGE]?: string
  [Globals.AI_DETECTOR_API_URL]?: string
} & Window

declare const global: customWindow

export function getEnvironment(globalName: Globals) {
  try {
    return global[globalName]
  } catch (error) {
    return null
  }
}
