import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Board } from './Board'

const Score = styled.div`
  text-align: center;
  margin-top: 10px;
`

const How = styled.div`
  text-align: center;
`

export function isTouchEnabled() {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.maxTouchPoints > 0
}

const touchEnabled = isTouchEnabled()

export function Game() {
  const gameAreaRef = useRef<HTMLDivElement>(null)
  const [score, setScore] = useState<number>(0)
  const [highscore, setHighscore] = useState<number>(0)
  const [gameStarted, setGameStarted] = useState<boolean>(false)

  const { t } = useTranslation()

  useEffect(() => {
    const gameAreaCurrent = gameAreaRef.current
    if (gameAreaCurrent?.offsetParent?.lastElementChild && touchEnabled) {
      gameAreaCurrent.offsetParent.lastElementChild.classList.add('d-none')
    }

    return () => {
      gameAreaCurrent?.offsetParent?.lastElementChild?.classList.remove('d-none')
    }
  }, [])

  useEffect(() => {
    const gameAreaCurrent = gameAreaRef.current
    if (gameAreaCurrent?.offsetParent && touchEnabled) {
      gameAreaRef?.current?.offsetParent?.classList.add('position-fixed', 'w-100', 'overflow-hidden')
    }

    return () => {
      if (gameAreaCurrent?.offsetParent && touchEnabled) {
        gameAreaCurrent?.offsetParent?.classList.remove('position-fixed', 'w-100', 'overflow-hidden')
      }
    }
  }, [])

  const updateScore = useCallback((currentScore: number) => {
    setScore(currentScore)
    setHighscore((highscore) => (currentScore > highscore ? currentScore : highscore))
  }, [])

  if (gameStarted && gameAreaRef.current?.offsetParent && touchEnabled) {
    gameAreaRef?.current?.offsetParent?.classList.add('position-fixed', 'w-100', 'overflow-hidden')
  }

  return (
    <div ref={gameAreaRef}>
      <How>
        <h2>{t('game.intro')}</h2>
        {t('game.instructions')}
      </How>
      <Score>
        {t('game.score')} <b>{score}</b> | {t('game.highscore')} <b>{highscore}</b>
      </Score>
      <Board updateScore={updateScore} setGameStarted={setGameStarted} gameStarted={gameStarted} />
    </div>
  )
}
