import { ISupportedCommandsResponse } from '../../types/commandQueue'
import { PrinterUuid } from '../../types/printer'
import { RequestFactory } from '../../types/sdk'

export function createModule(rf: RequestFactory) {
  return {
    getSupportedCommandsByPrinterType: (printerType: string) =>
      rf.get<ISupportedCommandsResponse>(`/app/commands/${printerType}`),

    getSupportedCommandsByUuid: (printerUuid: PrinterUuid) =>
      rf.get<ISupportedCommandsResponse>(`/app/printers/${printerUuid}/supported-commands`)
  }
}
