export enum Order {
  ASC = '+',
  DESC = '-'
}

export const SEPARATOR = ','

export type SortItem = {
  order: Order
  key: string
}
const sortTimeItem = { key: 'remaining_time', order: Order.ASC }

export function parseSortBy(sortBy: string): SortItem[] {
  if (!sortBy) {
    return []
  }

  return sortBy.split(encodeURIComponent(SEPARATOR)).map((part) => {
    const order = part.includes('+') ? Order.ASC : Order.DESC
    const key = part.replace(/[-+]/, '') // remove +-
    return {
      order,
      key
    }
  })
}

export function serializeSortBy(items: SortItem[], encode?: boolean) {
  if (items.length === 0) {
    return undefined
  }
  const separator = encode ? encodeURIComponent(SEPARATOR) : SEPARATOR
  return items.map((item) => `${item.order}${item.key}`).join(separator)
}

function createSortItems(sortItems: SortItem[], sortKey: string): SortItem[] {
  const prev = sortItems.find((item) => item.key === sortKey)

  // Missing -> first time ASC sort
  if (!prev) {
    return [...sortItems, { key: sortKey, order: Order.ASC }]
  }

  // ASC -> DESC
  if (prev.order === Order.ASC) {
    return sortItems.map((item) => {
      if (item.key !== sortKey) {
        return item
      }
      return { key: sortKey, order: Order.DESC }
    })
  }

  // DESC -> remove
  return sortItems.filter((item) => item.key !== sortKey)
}

export function applySort(sortItems: SortItem[], sortKey: string, keepPrevious = false): SortItem[] {
  const items = createSortItems(sortItems, sortKey).filter((item) => item.key !== sortTimeItem.key)

  if (keepPrevious) {
    return [...items, sortTimeItem]
  }
  return [...items.filter((item) => item.key === sortKey), sortTimeItem] // remaining time always at the end
}

export function getSortOrder(sortItems: SortItem[], sortKey: string) {
  return sortItems.find((item) => item.key === sortKey)?.order
}
