import styled from 'styled-components'

import { Sorting } from './filters/Sorting'

const FiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
`

const Filter = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.9rem;
`

const Div = styled.div`
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`

export function SortBy() {
  return (
    <div className="d-flex flex-grow-1 flex-wrap align-items-center mr-3">
      <FiltersContainer>
        <Filter>
          <Div>
            <Sorting />
          </Div>
        </Filter>
      </FiltersContainer>
    </div>
  )
}
