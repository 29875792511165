import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

type Props = {
  value?: string
  readonly?: boolean
  onChange?: (e: any) => void
}

export function MediumChatId(props: Props) {
  const { value, readonly, onChange } = props
  const { t } = useTranslation()

  return (
    <Form.Control
      value={value}
      onChange={onChange}
      placeholder={t('notifications.medium.chat-id-placeholder')}
      type="text"
      required={!readonly}
      readOnly={readonly}
    />
  )
}
