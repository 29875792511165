import styled from 'styled-components'

import { PlainButton } from '../../common/PlainButton'

export const Container = styled.div<{
  $isTeamInfo: boolean
}>`
  margin: 0.5rem 0.75rem;
  font-size: 0.8rem;

  ${({ $isTeamInfo }) =>
    !$isTeamInfo &&
    `
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    gap: 0.5rem;
    margin: 0.5rem 0;
    font-size: 1rem;
    > :first-child {
      margin-left: auto;
    }
  `}
`

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  a {
    text-decoration: underline;
  }
`

export const Info = styled.div`
  white-space: nowrap;
`

export const Bar = styled(PlainButton)<{
  expand?: boolean
}>`
  width: 30%;
  display: flex;

  ${({ $expand }) =>
    $expand &&
    `
    width: 100%;
  `}
`
