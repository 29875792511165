import styled from 'styled-components'

import { Layers } from '../helpers/zIndex'

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: rgb(0, 0, 255, 0.25);
  padding: 0 4px;
  font-size: 12px;
  z-index: ${Layers.PROXY};
`

export function ProxyTarget() {
  const proxyTarget = PROXY_TARGET
  if (typeof proxyTarget === 'boolean') {
    return null
  }

  const url = new URL(proxyTarget)
  let target = url.hostname
  if (url.port) {
    target += `:${url.port}`
  }
  return <Container title="Proxy target">{target}</Container>
}
