import { useQuery } from '@tanstack/react-query'

import { useApiClient } from '../../api/react'
import { IPrinter, PrinterUuid } from '../../api/types/printer'

export function useStorageLocations(printer: IPrinter) {
  const api = useApiClient()

  const { data, isSuccess, isError, isLoading, refetch } = useQuery([`${printer.uuid}/storages`], () =>
    api.app.printers.getPrinterStorageLocations(printer.uuid)
  )

  return {
    locations: data?.storages || [],
    isSuccess,
    isError,
    isLoading,
    refetch
  }
}

export function useStorageLocationsByUUid(printerUuid: PrinterUuid) {
  const api = useApiClient()

  const { data, isSuccess, isError, isLoading } = useQuery([`${printerUuid}/storages`], () =>
    api.app.printers.getPrinterStorageLocations(printerUuid)
  )

  return {
    locations: data?.storages || [],
    isSuccess,
    isError,
    isLoading
  }
}
