import { ICamera, ICameraOrigin, ICameraResponse, ISnapshot } from '../../types/cameras'
import { PrinterUuid } from '../../types/printer'
import { NoContent204, RequestFactory } from '../../types/sdk'

async function getObjectUrl(url: string, rf: RequestFactory): Promise<ISnapshot> {
  const response = await rf.get<Response>(url)
  const dateString = response.headers.get('last-modified')
  const timestamp = new Date(dateString || '').getTime() / 1000

  const responseCopy = response.clone()
  const blob = await responseCopy.blob()
  const file = new File([blob], 'snapshot.jpg', { type: 'image/jpeg' })
  const snapshotUrl = URL.createObjectURL(blob)

  return {
    snapshotUrl,
    file,
    downloadUrl: url,
    timestamp
  }
}

/**
 * @param snapshotPath path to the snapshot returned by the API, e.g. "/app/snapshots/camera_393_1676993794"
 * @return transformed path, e.g. "/thumbnail/camera/393/1676993794"
 */
export function getThumbnailUrlFromSnapshot(snapshotPath: string): string {
  const matched = snapshotPath.match(/.*\/camera_(\d+)_(\d+)/)
  if (!Array.isArray(matched)) {
    return ''
  }

  const [, cameraId, timestamp] = matched
  return `/thumbnail/camera/${cameraId}/${timestamp}`
}

export function getLatestThumbnailUrl(cameraId: number) {
  return `/thumbnail/camera/${cameraId}`
}

export function getLatestSnapshotUrl(cameraId: number) {
  return `/app/cameras/${cameraId}/snapshots/last`
}

export function createModule(rf: RequestFactory) {
  return {
    getAllCameras: (limit = 100) => rf.get<ICameraResponse>(`/app/cameras?limit=${limit.toString()}`),
    getPrinterCameras: (printerUuid: PrinterUuid) => rf.get<ICameraResponse>(`/app/printers/${printerUuid}/cameras`),
    registerCamera: (printerUuid: PrinterUuid, origin?: ICameraOrigin) => {
      let url = `/app/printers/${printerUuid}/camera`
      if (origin) {
        url += `?origin=${origin}`
      }
      return rf.post<NoContent204>(url)
    },
    removeCamera: (cameraId: number) => rf.delete<NoContent204>(`/app/cameras/${cameraId}`),
    updateCamera: (cameraId: number, data: ICamera) => rf.put<ICamera>(`/app/cameras/${cameraId}`, data),
    getLatestSnapshot: (cameraId: number) => getObjectUrl(getLatestSnapshotUrl(cameraId), rf),
    getLatestThumbnail: (cameraId: number) => getObjectUrl(getLatestThumbnailUrl(cameraId), rf),
    getSnapshotFromPath: (snapshotPath: string) => getObjectUrl(snapshotPath, rf),
    getThumbnailFromSnapshotUrl: (snapshotPath: string) => getObjectUrl(getThumbnailUrlFromSnapshot(snapshotPath), rf)
  }
}
