import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { useApiClient } from '../api/react'
import { IQueryError } from '../api/types/errors'
import { IUploadQueueFile } from '../api/types/file'
import { PrinterUuid } from '../api/types/printer'
import { useToast } from '../context/toastStore'

export function useAddJobDownloadQueueService(
  printerUuid: PrinterUuid,
  onSuccess?: () => void,
  onError?: (error: any) => void
) {
  const toast = useToast()
  const { t } = useTranslation()
  const api = useApiClient()

  return useMutation(
    (fileInfo: IUploadQueueFile) => api.app.transfers.addToPrinterDownloadQueue(printerUuid, fileInfo),
    {
      onSuccess: () => {
        toast.add(t('printer.actions.start-print.success.title'), t('printer.actions.start-print.success.body'))
        onSuccess?.()
      },
      onError: (error: IQueryError) => {
        onError?.(error)
      }
    }
  )
}
