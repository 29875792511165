import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AdaptiveButton } from '../../common/AdaptiveButton/AdaptiveButton'
import { RenameFileModal } from '../storage/RenameFileModal'

type Props = {
  teamId: number
  hash: string
  name: string
  onSuccess: () => void
}

export function RenameFileAction(props: Props) {
  const { teamId, hash, name, onSuccess } = props
  const [showModal, setShowModal] = useState(false)
  const { t } = useTranslation()

  return (
    <>
      <AdaptiveButton
        icon="editIcon"
        label={t('button.rename', 'Rename file')}
        isLoading={false}
        isAvailable
        trigger={() => setShowModal(true)}
        isDisabled={false}
        disabledTooltip={t('gcode-meta.download.not-available-in-connect')}
      />

      {showModal && (
        <RenameFileModal
          hash={hash}
          teamId={teamId}
          name={name}
          onCancel={() => setShowModal(false)}
          onSuccess={onSuccess}
          isFromConnect
        />
      )}
    </>
  )
}
