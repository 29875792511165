import { Material } from '../../../api/types/filament'
import { ICloudFile, IFileType, ITreeChild } from '../../../api/types/file'
import { getStorageFromPath } from '../../../helpers/files'
import { IFilesFilter } from './AllCloudFiles'

export type IStorageFilters = {
  availableMaterials: Material[]
  availablePrinterTypes: string[]
  availableNozzles: number[]
  availableLayers: number[]
  availableFileTypes: IFileType[]
  storages: string[]
}

export function getAvailableFilters(files?: ICloudFile[] | ITreeChild[]): IStorageFilters {
  const availableMaterials: Material[] = []
  const availablePrinterTypes: string[] = []
  const availableNozzles: number[] = []
  const availableLayers: number[] = []
  const availableFileTypes: IFileType[] = []
  const storages: string[] = []

  files?.forEach((file) => {
    if (file.meta?.filament_type && !availableMaterials.includes(file.meta.filament_type)) {
      availableMaterials.push(file.meta.filament_type)
    }
    if (file.meta?.printer_model && !availablePrinterTypes.includes(file.meta.printer_model)) {
      availablePrinterTypes.push(file.meta.printer_model)
    }
    if (file.meta?.nozzle_diameter && !availableNozzles.includes(file.meta.nozzle_diameter)) {
      availableNozzles.push(file.meta.nozzle_diameter)
    }
    if (file.meta?.layer_height && !availableLayers.includes(file.meta.layer_height)) {
      availableLayers.push(file.meta.layer_height)
    }
    if (!availableFileTypes.includes(file.type) && file.type !== IFileType.FOLDER) {
      availableFileTypes.push(file.type)
    }
    if (file.path && !storages.includes(getStorageFromPath(file.path))) {
      storages.push(getStorageFromPath(file.path))
    }
  })

  // sort due to remain order when files change
  return {
    availableMaterials: availableMaterials.sort(),
    availablePrinterTypes: availablePrinterTypes.sort(),
    availableNozzles: availableNozzles.sort(),
    availableLayers: availableLayers.sort(),
    availableFileTypes: availableFileTypes.sort(),
    storages: storages.sort()
  }
}

export function sortFiles(filteredFiles: ICloudFile[] | ITreeChild[], sort: string) {
  let result = [...filteredFiles]

  if (sort) {
    switch (sort) {
      case 'latest':
        result = result.sort((a, b) => (b.m_timestamp || b.uploaded || 0) - (a.m_timestamp || a.uploaded || 0))
        break
      case 'oldest':
        result = result.sort((a, b) => (a.m_timestamp || a.uploaded || 0) - (b.m_timestamp || b.uploaded || 0))
        break
      case 'A-Z':
        result = result.sort((a, b) => (a.display_name || a.name).localeCompare(b.display_name || b.name))
        break
      case 'Z-A':
        result = result.sort((a, b) => (b.display_name || b.name).localeCompare(a.display_name || a.name))
        break
      case 'biggest':
        result = result.sort((a, b) => {
          const sizeA = a.size || 0
          const sizeB = b.size || 0

          if (sizeA === 0 && sizeB === 0) {
            return (a.display_name || a.name).localeCompare(b.display_name || b.name) // Sort by file name if both have no size
          }

          if (sizeA === 0) {
            return -1
          }

          if (sizeB === 0) {
            return 1
          }

          if (sizeA !== sizeB) {
            return sizeB - sizeA // Sort by size if both have an size
          }

          return (a.display_name || a.name).localeCompare(b.display_name || b.name) // Sort by file name if both have the same size
        })
        break
      case 'smallest':
        result = result.sort((a, b) => {
          const sizeA = a.size || 0
          const sizeB = b.size || 0

          if (sizeA === 0 && sizeB === 0) {
            return (a.display_name || a.name).localeCompare(b.display_name || b.name) // Sort by file name if both have no size
          }

          if (sizeA === 0) {
            return -1
          }

          if (sizeB === 0) {
            return 1
          }

          if (sizeA !== sizeB) {
            return sizeA - sizeB // Sort by size if both have an size
          }

          return (a.display_name || a.name).localeCompare(b.display_name || b.name) // Sort by file name if both have the same size
        })
        break
    }
  }
  return result
}

export function filterFiles(filteredFiles: ICloudFile[] | ITreeChild[], filters?: IFilesFilter) {
  if (!filters) {
    return filteredFiles
  }

  const { materialFilter, printerTypeFilter, layerFilter, nozzleFilter, fileTypeFilter, storages } = filters
  let result = [...filteredFiles]

  if (materialFilter.length > 0) {
    result = result.filter((f) => materialFilter.includes(f.meta?.filament_type) || !f.meta?.filament_type)
  }
  if (printerTypeFilter && printerTypeFilter?.length > 0) {
    result = result.filter((f) => printerTypeFilter.includes(f.meta?.printer_model) || !f.meta?.printer_model)
  }
  if (layerFilter) {
    result = result.filter((f) => f.meta?.layer_height === layerFilter || !f.meta?.layer_height)
  }
  if (nozzleFilter) {
    result = result.filter((f) => f.meta?.nozzle_diameter === nozzleFilter || !f.meta?.nozzle_diameter)
  }
  if (fileTypeFilter) {
    result = result.filter((f) => f.type === fileTypeFilter || f.type === IFileType.FOLDER)
  }
  if (storages && storages?.length > 0) {
    result = result.filter((f) => storages.includes(getStorageFromPath(f.path)))
  }

  return result
}

export function getSelectedFiles(
  files: ICloudFile[],
  prevSelected: ICloudFile[],
  shiftPressed: boolean,
  checked: boolean,
  file: ICloudFile,
  index: number
) {
  if (shiftPressed) {
    window.getSelection()?.removeAllRanges()

    // if no line selected yet, select every line from the first one to the clicked one
    if (prevSelected.length === 0) {
      return files.slice(0, index + 1)
    }
    // if one selected already, select every line from this selected to the clicked one (downwards or upwards)
    if (prevSelected.length === 1) {
      const startIdx = files.findIndex((file) => file.hash === prevSelected[0].hash)
      return files.slice(Math.min(startIdx, index), Math.max(startIdx, index) + 1)
    }
    // if multiple lines selected already, add lines to these selected (downwards or upwards)
    if (prevSelected.length > 1) {
      const lastSelectedIdx = files.map((file) => file.hash).lastIndexOf(prevSelected[prevSelected.length - 1].hash)

      const firstSelectedIdx = files.findIndex((file) =>
        prevSelected.some((selectedFile) => selectedFile.hash === file.hash)
      )

      if (lastSelectedIdx < index) {
        return files.slice(firstSelectedIdx, index + 1)
      }
      return files.slice(index, lastSelectedIdx + 1)
    }
  } else if (!checked) {
    return prevSelected.filter((item) => item.hash !== file.hash)
  } else {
    return [...prevSelected, file]
  }
}

export function purgeFilters(filters: IFilesFilter, availableFilters: IStorageFilters) {
  return {
    fileTypeFilter:
      availableFilters.availableFileTypes.indexOf(filters.fileTypeFilter) === -1
        ? IFileType.EMPTY
        : filters.fileTypeFilter,
    layerFilter: availableFilters.availableLayers.indexOf(filters.layerFilter) === -1 ? 0 : filters.layerFilter,
    nozzleFilter: availableFilters.availableNozzles.indexOf(filters.nozzleFilter) === -1 ? 0 : filters.nozzleFilter,
    materialFilter: filters.materialFilter.filter((f) => availableFilters.availableMaterials.includes(f)),
    printerTypeFilter: filters.printerTypeFilter?.filter((f) => availableFilters.availablePrinterTypes.includes(f)),
    storages: filters.storages?.filter((f) => availableFilters.storages.includes(f))
  }
}
