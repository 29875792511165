import styled from 'styled-components'

type CommandLine = {
  commands: string[]
  comment: string
}

type ICommand = {
  cmd: string
}

type LineNumber = {
  lineNumber: number
}

const handleColorType = (cmd: string): string => {
  if (cmd.startsWith('G0')) {
    return 'red'
  }
  if (cmd.startsWith('G1')) {
    return 'green'
  }
  if (cmd.startsWith('M')) {
    return 'blue'
  }
  if (cmd.startsWith('S')) {
    return 'blue'
  }
  if (cmd.startsWith('F')) {
    return 'blue'
  }
  if (cmd.startsWith('T')) {
    return 'blue'
  }
  return 'var(--color-text)'
}

const Command = styled.span<ICommand>`
  color: ${({ cmd }) => handleColorType(cmd)};
  &:after {
    content: ' ';
  }
`

const Comment = styled.span`
  color: var(--color-light);
  font-style: italic;
`

const Line = styled.div<LineNumber>`
  &:before {
    content: ${({ lineNumber }) => (lineNumber < 10 ? `'0${lineNumber} '` : `'${lineNumber} '`)};
    color: var(--color-text);
  }
  &:after {
    content: ' \u00B6';
    color: var(--color-text);
  }
`

type IProps = {
  gcode: string
}

export function GcodeHighlighter(props: IProps) {
  const parceLine = (line: string): CommandLine => {
    if (line.indexOf(';') > 0) {
      const parts = line.split(';')
      return {
        commands: parts.length > 1 ? parts[0].split(/\s+/) : [],
        comment: parts.length > 1 ? parts[1] : ''
      }
    }
    return {
      commands: line.split(/\s+/),
      comment: ''
    }
  }
  const lines = props.gcode.split(/(?:\r\n|\r|\n)/g)
  const parcedLines: CommandLine[] = []

  lines.forEach((line) => {
    parcedLines.push(parceLine(line))
  })

  return (
    <>
      {parcedLines.map((line, i) => (
        <Line key={i} lineNumber={i + 1}>
          {line.commands.length > 0 &&
            line.commands.map((cmd, ii) => (
              <Command key={ii} cmd={cmd}>
                {cmd}
              </Command>
            ))}
          {line.comment.length > 0 && <Comment>{` ; ${line.comment}`}</Comment>}
        </Line>
      ))}
    </>
  )
}
