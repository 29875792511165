import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { WithConfirm } from '../../common/ConfirmModal'
import { SvgIcon } from '../../common/SvgIcon'

type Props = {
  deleteMedium: () => void
  btnText: string
  bodyText: string
}

export function MediumDeleteButton(props: Props) {
  const { t } = useTranslation()

  return (
    <WithConfirm title={t('notifications.medium.delete.confirm-title')} body={props.bodyText}>
      {(confirm) => (
        <Button variant="outline-dark" size="sm" onClick={confirm(() => props.deleteMedium())}>
          <SvgIcon icon="deleteIcon" size="16" />
          {props.btnText}
        </Button>
      )}
    </WithConfirm>
  )
}
