import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const CONTAINER_MARGIN_BOTTOM = 30

export const JobList = styled.div<{ $disabledSelection?: boolean }>`
  ${({ $disabledSelection }) =>
    $disabledSelection &&
    `-webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */`}

  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
`

const Container = styled.div`
  background: var(--background-primary);
`

export const Left = styled.div`
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`

export const Right = styled.div`
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
`

export const RowLink = styled(Link)<{ $noLink?: boolean }>`
  display: flex;
  padding: 0;
  padding-right: 1rem;
  gap: 1rem;
  min-width: 0;
  color: var(--color-text);
  border-bottom: 1px solid var(--border-secondary);
  &:hover {
    background: var(--job-background-hover);
    color: var(--color-text);

    ${Right} {
      background: var(--job-background-hover);
    }
  }

  &:hover .scroll-arrows button:before {
    background: transparent;
  }
  &:hover .scroll-arrows button:after {
    background: transparent;
  }

  ${({ $noLink }) =>
    $noLink &&
    `
    cursor: default;
  `}
`

export const FolderRowLink = styled(RowLink)<{ $noLink?: boolean; $syncing?: boolean }>`
  border: none;
  height: 100px;
  border-bottom: 1px solid var(--border-secondary);
  position: relative;
  ${({ $syncing }) =>
    $syncing &&
    `
    opacity: 0.5;
    pointer-events: none;
  `}
`

export const ParentRowLink = styled(RowLink)<{ $noLink?: boolean }>`
  border: none;
`

export const Params = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
`

export const Param = styled.div``

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`

const ButtonIcons = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 10px;
`

export const JobLayout = {
  Container,
  RowLink,
  Params,
  Param,
  Left,
  Right,
  ButtonIcons,
  TitleContainer
}
