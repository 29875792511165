import Markdown from 'markdown-to-jsx'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useUpdateProfile } from '../api/services/useAuth'
import { useAuthState } from '../context/authStore'
import { useUserResolver } from '../Contexts'
import { useLogoutService } from '../services/useLogoutService'
import { ConfirmModal } from './common/ConfirmModal'

const Title = styled.h2`
  font-size: 18px;
`

const Body = styled.div`
  font-size: 14px;

  a {
    text-decoration: underline;
  }
`

export const TermsAndConditionsModal = () => {
  const { t } = useTranslation()
  const { user, isAuthenticated } = useAuthState()
  const { logoutUser } = useLogoutService()
  const { refetchUserData } = useUserResolver()
  const [showModal, setShowModal] = useState(false)
  const { update: updateProfile } = useUpdateProfile(refetchUserData)

  useEffect(() => {
    if (typeof user.conditions_signed === 'boolean' && !user.conditions_signed && isAuthenticated) {
      setShowModal(true)
    }
  }, [user.conditions_signed, isAuthenticated])

  return (
    <ConfirmModal
      show={showModal}
      id="terms-and-condition-modal"
      title={<Title>{t('terms-and-conditions-privacy-policy', 'Terms and Conditions and Privacy Policy')}</Title>}
      body={
        <Body>
          <p>
            <Markdown>
              {t(
                'terms-and-conditions-privacy-policy.intro',
                'Before you continue using our services, we kindly ask you to review and agree to our <a target="_blank" href="https://www.prusa3d.com/page/prusa-connect-prusa-link-terms-and-conditions_233705/">Terms and Conditions</a> and <a target="_blank" href="https://www.prusa3d.com/page/prusa-connect-privacy-policy_233713">Privacy Policy</a>.'
              )}
            </Markdown>
          </p>
          <p>
            {t(
              'terms-and-conditions-privacy-policy.main',
              'By proceeding with the use of our platform, you acknowledge that you have read, understood, and accepted the terms outlined in our Terms and Conditions, which govern the use of our website and services.'
            )}
          </p>
          <p>
            {t(
              'terms-and-conditions-privacy-policy.final',
              'Your trust is essential to us, and we assure you that your information will be handled securely and in accordance with applicable laws.'
            )}
          </p>
        </Body>
      }
      confirmBtnText={t('terms-and-conditions-privacy-policy.button.confirm', 'I Agree')}
      onCancel={() => {
        setShowModal(false)
        logoutUser()
      }}
      onConfirm={() => {
        updateProfile({ conditions_signed: true })
        setShowModal(false)
      }}
    />
  )
}
