import createClient from 'openapi-fetch'

import type { paths } from '../schema/schema'

export function createBaseClient(baseUrl: string) {
  return createClient<paths>({ baseUrl })
}

type ApiResponse<T> = {
  data?: T
  error?: any
  response: Response
}

class ApiRequestError extends Error {
  response: Response
  constructor(response: Response) {
    super(response.statusText)
    this.name = 'ApiRequestError'
    this.response = response
  }
}

export default function createApiClient(basePath = '') {
  const baseUrl = basePath.replace(/\/$/, '') // remove trailing /
  const apiClient = createBaseClient(baseUrl)

  type Client = typeof apiClient
  type ApiCall<T> = (client: Client) => Promise<ApiResponse<T>>

  return async function performRequest<T>(apiCall: ApiCall<T>): Promise<T> {
    const response = await apiCall(apiClient)

    if (response.error) {
      throw new ApiRequestError(response.response)
    }

    if (response.data === null || response.data === undefined) {
      throw new ApiRequestError(response.response)
    }

    return response.data
  }
}

export type ApiClient = ReturnType<typeof createApiClient>
