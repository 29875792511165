import { useTranslation } from 'react-i18next'
import Select from 'react-select'

import { IConnectState } from '../../../api/types/state'
import { translateLabel } from '../../common/PrinterStateTag'
import { useFilterHandlers } from '../hooks/useFilterHandlers'
import { usePrintersQueryState } from '../hooks/usePrintersQueryState'
import { useStyles } from './styles'

type Option = {
  value: IConnectState
  label: string
}

export function StateFilter() {
  const { setFilter } = useFilterHandlers()
  const { states } = usePrintersQueryState()
  const { t } = useTranslation()

  const options = Object.values(IConnectState).map((state) => ({
    label: translateLabel(t, state).toUpperCase(),
    value: state
  }))

  return (
    <Select
      value={options.filter((opt) => states?.includes(opt.value))}
      options={options}
      onChange={(options) => {
        const value = options.map((opt) => opt.value)
        setFilter({
          key: 'states',
          value
        })
      }}
      placeholder={t('printer.state')}
      isMulti
      isClearable
      styles={useStyles<Option, true>()}
    />
  )
}
