import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IUploadedTransferFile } from '../../../api/types/transfer'
import { PaceProgress } from '../../common/PaceProgress'
import { SvgIcon } from '../../common/SvgIcon'
import { QueuedTransfer } from '../QueuedTransfer'
import { UploadActions } from '../UploadActions'
import { getTeamUploadsId } from './AllCloudFiles'
import { useAllCloudFiles, useConnectUploads } from './FileManager/useCloudFiles'
import { Right, TransfersTitle } from './TransfersList'

export function CloudTransfersList({ teamId }: { teamId: number }) {
  const { t } = useTranslation()
  const [isDisplayed, setIsDisplayed] = useState(true)
  const { data: connectUploads } = useConnectUploads(teamId, getTeamUploadsId(teamId))
  const { refetch } = useAllCloudFiles(teamId)

  if (!connectUploads) {
    return <PaceProgress />
  }

  if (connectUploads.uploads.length === 0) {
    return null
  }

  return (
    <>
      <TransfersTitle>
        {t('current-transfers', 'Current transfers')}

        <Right className="text-right">
          {!isDisplayed && (
            <span>{t('current-transfers.running', { count: connectUploads?.uploads.length || 0 })}</span>
          )}
          <SvgIcon
            icon={isDisplayed ? 'arrowUpIcon' : 'arrowDownIcon'}
            size={25}
            style={{ opacity: '0.35', cursor: 'pointer' }}
            onClick={() => setIsDisplayed(!isDisplayed)}
          />
        </Right>
      </TransfersTitle>
      {isDisplayed && (
        <>
          {connectUploads?.uploads.map((upload) => (
            <QueuedTransfer
              key={upload.id}
              printerTransfer={
                {
                  size: upload.size,
                  display_name: upload.name,
                  total: upload.progress,
                  transferred: (upload.progress / 100) * upload.size
                } as IUploadedTransferFile
              }
              isUploadedToConnect
              isToCloudOnly
              actionButtons={
                <UploadActions
                  uploadId={upload.id}
                  refetch={refetch}
                  foldable={false}
                  teamId={teamId}
                  uniqueContextId={getTeamUploadsId(teamId)}
                />
              }
            />
          ))}
        </>
      )}
    </>
  )
}
