import { DragEndEvent, DragStartEvent, UniqueIdentifier } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useApiClient } from '../../../api/react'
import { IConnectState } from '../../../api/types/state'
import { IUploadedTransferFile } from '../../../api/types/transfer'
import { intervals } from '../../../config'
import { usePrinter } from '../../../context/printerContext'
import { isDefined } from '../../../helpers/std'
import { DragAndDropContainer, DragAndDropOverlay } from '../../common/DragAndDropOverlay'
import { PaceProgress } from '../../common/PaceProgress'
import { SvgIcon } from '../../common/SvgIcon'
import { CustomDndContext } from '../../dnd/CustomDndContext'
import { SortableItem } from '../../dnd/SortableItem'
import { TitleLink } from '../CurrentTransfer'
import { CurrentTransferActions } from '../CurrentTransferActions'
import { QueuedTransfer } from '../QueuedTransfer'
import * as QueuedTransferStyle from '../QueuedTransfer.styled'
import { TransferActions } from '../TransferActions'
import { UploadActions } from '../UploadActions'
import { useConnectUploads } from './FileManager/useCloudFiles'

export const TransfersTitle = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: default;
`

export const Right = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

export const TransferList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
`

export function TransfersList({ teamId }: { teamId: number }) {
  const { printer } = usePrinter()
  const { t } = useTranslation()
  const [isDisplayed, setIsDisplayed] = useState(true)

  const api = useApiClient()
  const { data, refetch } = useQuery(
    [`/printers/${printer.uuid}}`],
    () => api.app.transfers.getPrinterQueue(printer.uuid),
    {
      refetchInterval: intervals.transfersPolling,
      keepPreviousData: true
    }
  )

  const [draggingItemId, setDraggingItemId] = useState<UniqueIdentifier | null>()

  const { data: connectUploads } = useConnectUploads(teamId, printer.uuid)

  const [files, setFiles] = useState(data?.files || [])

  useEffect(() => {
    setFiles(data?.files || [])
  }, [data])

  const { mutate: reorderTransfer } = useMutation(
    (params: { position: number; fileId: number }) =>
      api.app.transfers.moveTransfer(printer.uuid, {
        position: params.position,
        file_id: params.fileId
      }),
    {
      onSuccess: () => refetch(),
      onError: () => refetch() // TODO nefunguje
    }
  )

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event
    setDraggingItemId(active.id)
  }

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event
    if (typeof active.id === 'number' && typeof over?.id === 'number' && active.id !== over.id) {
      const activeJobIndex = files.findIndex((el) => el.id === active.id)
      const endJobIndex = files.findIndex((el) => el.id === over.id)
      const items = arrayMove(files, activeJobIndex, endJobIndex)
      setFiles(items)
      reorderTransfer({ position: endJobIndex, fileId: active.id })
    }
    setDraggingItemId(null)
  }

  if (!data) {
    return <PaceProgress />
  }

  if (files.length === 0 && !printer.transfer && connectUploads?.uploads.length === 0) {
    return null
  }

  const transferFiles = files.filter((queuedFile) => queuedFile.hash !== printer.transfer?.hash)

  let transfersCount = transferFiles.length
  if (printer.transfer) transfersCount++
  if (connectUploads?.uploads.length) transfersCount += connectUploads.uploads.length

  if (printer.connect_state === IConnectState.OFFLINE) {
    return null
  }

  return (
    <>
      <TransfersTitle>
        <span>{t('current-transfers', 'Current transfers')}</span>

        <Right className="text-right">
          <TitleLink to={`/printer/${printer.uuid}/transfers`} title={t('transfers.historical', 'Transfer history')}>
            {t('transfers.historical', 'Transfer history')}
          </TitleLink>

          {!isDisplayed && <span>{t('current-transfers.running', { count: transfersCount })}</span>}
          <SvgIcon
            icon={isDisplayed ? 'arrowUpIcon' : 'arrowDownIcon'}
            size={25}
            style={{ opacity: '0.35', cursor: 'pointer' }}
            onClick={() => setIsDisplayed(!isDisplayed)}
          />
        </Right>
      </TransfersTitle>
      {isDisplayed && (
        <>
          {/* current transfer from printer detail */}
          {printer.transfer && (
            <QueuedTransfer
              printerTransfer={printer.transfer}
              isCurrentTransfer
              actionButtons={
                <QueuedTransferStyle.ButtonIcons>
                  <CurrentTransferActions printer={printer} foldable={false} />
                </QueuedTransferStyle.ButtonIcons>
              }
            />
          )}
          {/* printer queue */}
          <CustomDndContext
            modifiers={[restrictToVerticalAxis]}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
          >
            <TransferList data-no-dnd="true">
              <SortableContext items={transferFiles} strategy={verticalListSortingStrategy}>
                {transferFiles.map((file) => (
                  <DragAndDropContainer key={file.id} $isDragging={draggingItemId === file.id}>
                    <SortableItem id={file.id}>
                      <QueuedTransfer
                        printerTransfer={file}
                        actionButtons={
                          isDefined(file.id) && (
                            <QueuedTransferStyle.ButtonIcons>
                              <TransferActions fileId={file.id} refetch={refetch} foldable={false} />
                            </QueuedTransferStyle.ButtonIcons>
                          )
                        }
                      />
                      {draggingItemId === file.id && <DragAndDropOverlay />}
                    </SortableItem>
                  </DragAndDropContainer>
                ))}
              </SortableContext>
            </TransferList>
          </CustomDndContext>
          {/* connect ongoing uploads */}
          {connectUploads?.uploads.map((upload) => (
            <QueuedTransfer
              key={upload.id}
              printerTransfer={
                {
                  size: upload.size,
                  display_name: upload.name,
                  total: upload.progress,
                  transferred: (upload.progress / 100) * upload.size
                } as IUploadedTransferFile
              }
              isUploadedToConnect
              isToCloudOnly={!upload.path}
              actionButtons={
                <UploadActions
                  uploadId={upload.id}
                  refetch={refetch}
                  foldable={false}
                  teamId={teamId}
                  uniqueContextId={printer.uuid}
                />
              }
            />
          ))}
        </>
      )}
    </>
  )
}

export default TransfersList
