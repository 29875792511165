import { MouseSensor, PointerSensor } from '@dnd-kit/core'
import { MouseEvent, PointerEvent } from 'react'

// To prevent triggering the drag event on Interactive elements add data-no-dnd="true" to an element wrapping the target element
export class CustomPointerSensor extends PointerSensor {
  static activators = [
    {
      eventName: 'onPointerDown' as const,
      handler: ({ nativeEvent: event }: PointerEvent) => {
        if (!event.isPrimary || event.button !== 0 || shouldHandleEvent(event.target)) {
          return false
        }

        return true
      }
    }
  ]
}

export class CustomMouseSensor extends MouseSensor {
  static activators = [
    {
      eventName: 'onMouseDown' as const,
      handler: ({ nativeEvent: event }: MouseEvent) => {
        if (event.button !== 0 || shouldHandleEvent(event.target)) {
          return shouldHandleEvent(event.target)
        }
        return true
      }
    }
  ]
}

function shouldHandleEvent(element: EventTarget | null) {
  let cur = element

  while (cur && cur instanceof HTMLElement) {
    if (cur.dataset && cur.dataset.noDnd) {
      return false
    }
    cur = cur.parentElement
  }

  return true
}
