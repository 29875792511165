import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { IPrinterTransfer } from '../../api/types/printer'
import { ITransferFile, IUploadedTransferFile } from '../../api/types/transfer'
import { getNameFromPath } from '../../helpers/files'
import { formatSize } from '../../helpers/formatters'
import { ellipsis } from '../helpers/string'
import * as S from './QueuedTransfer.styled'

type Props = {
  printerTransfer: (IPrinterTransfer | ITransferFile | IUploadedTransferFile) & { total?: number }
  isCurrentTransfer?: boolean
  isUploadedToConnect?: boolean
  isToCloudOnly?: boolean
  actionButtons?: ReactNode
}

export function QueuedTransfer(props: Props) {
  const { isCurrentTransfer, printerTransfer, isUploadedToConnect, isToCloudOnly, actionButtons } = props
  const { t } = useTranslation()

  if (!printerTransfer) {
    return null
  }

  const { size: fileSize, transferred } = printerTransfer

  return (
    <S.Container>
      <S.TitleContainer>
        <S.FileTitle>
          {ellipsis(
            printerTransfer.display_name || getNameFromPath(printerTransfer.display_path || printerTransfer.path),
            130
          )}
        </S.FileTitle>
        {actionButtons}
      </S.TitleContainer>

      <S.QueuedTransfer>
        <S.HalfQueueToConnect hidable={!isToCloudOnly}>
          <S.ProgressDescription>
            {isUploadedToConnect
              ? `${t(
                  'printer.file.transfer-progress.to-connect.in-progress',
                  'Transferring file to the Prusa Connect is in progress'
                )} | ${transferred ? formatSize(transferred) : '?'} - ${fileSize ? formatSize(fileSize) : '?'}`
              : t(
                  'printer.file.transfer-progress.to-connect.success',
                  'File transfer to Prusa Connect completed successfully'
                )}
          </S.ProgressDescription>
          <S.Progress progress={isUploadedToConnect ? printerTransfer.total : 100} title={printerTransfer.path} />
        </S.HalfQueueToConnect>

        {!isToCloudOnly && (
          <S.HalfQueue>
            <S.ProgressDescription>
              {!printerTransfer.progress
                ? t('printer.file.transfer-progress.to-printer.waiting', 'Waiting...')
                : `${t(
                    'printer.file.transfer-progress.to-printer.progress',
                    'Transfering file to the printer is in progress'
                  )} | ${transferred ? formatSize(transferred) : '?'} - ${fileSize ? formatSize(fileSize) : '?'}`}
            </S.ProgressDescription>
            <S.ProgressToPrinter
              progress={printerTransfer.progress || 0}
              title={printerTransfer.path}
              current={isCurrentTransfer}
            />
          </S.HalfQueue>
        )}
      </S.QueuedTransfer>
    </S.Container>
  )
}
