import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { useApiClient } from '../api/react'
import { IQueryError } from '../api/types/errors'
import { IAddJobQueue } from '../api/types/job'
import { PrinterUuid } from '../api/types/printer'
import { useToast } from '../context/toastStore'
import { useErrorHandler } from '../hooks/errors/useErrorHandler'

export function useAddJobQueueService(printerUuid: PrinterUuid, onSuccess?: () => void) {
  const toast = useToast()
  const { t } = useTranslation()
  const api = useApiClient()
  const errorHandler = useErrorHandler()

  return useMutation((params: IAddJobQueue) => api.app.jobs.addJobToQueue(printerUuid, params), {
    onSuccess: () => {
      toast.add(t('print.add-to-queue'), t('print.add-to-queue.description'))
      onSuccess?.()
    },
    onError: (error: IQueryError) => {
      errorHandler(error.response, error)
    }
  })
}
