import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'
import { Col as BootstrapCol, Container, Row as BootstrapRow } from 'react-bootstrap'
import { TFunction, useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { useApiClient } from '../../../api/react'
import { IQueryError } from '../../../api/types/errors'
import { IEditGroupRequestBody, IGroupDetail, IGroupPattern, IGroupSize } from '../../../api/types/groups'
import { IDraggablePrinter } from '../../../api/types/printer'
import { IVector3 } from '../../../api/types/vector'
import { DndContextProvider } from '../../../context/dndContext'
import { teamToPermissions } from '../../../context/permissionsStore'
import { useErrorHandler } from '../../../hooks/errors/useErrorHandler'
import { FullGroupDetail, getFullGroupDetailKey, useFullGroupDetail } from '../../../hooks/useGroupDetail'
import { useGroupHandler } from '../../../hooks/useGroupHandler'
import { useGroupName } from '../../../hooks/useGroupName'
import { useIsAdmin } from '../../../hooks/useLoggedUser'
import { useTeamName } from '../../../hooks/useTeamName'
import { useTeams } from '../../../hooks/useTeams'
import { IGrid } from '../../../interfaces/grid'
import { AvailablePrinters } from './group/AvailablePrinters'
import { DropAction, getAction } from './group/dnd'
import { Grid } from './group/Grid'
import { InvalidGroupSizeModal } from './group/invalidGroupSizeModal'
import { VirtualGroup } from './group/VirtualGroup'
import { DimensionSelector } from './selectors/dimensionSelector'

const Col = styled(BootstrapCol)`
  flex: 1 1 auto;
  width: auto;
`

const H2 = styled.h2`
  font-weight: 700;
  font-size: 15px;
`

const Row = styled(BootstrapRow)`
  margin: 0;
  align-items: center;
`

export const getPatternMessage = (t: TFunction, size?: IGroupSize) => {
  switch (size?.pattern) {
    case IGroupPattern.GRID:
      return t('printer.group.pattern.grid')
    case IGroupPattern.SHIFTED:
      return t('printer.group.pattern.shifted-grid')
    default:
      return t('printer.group.pattern.virtual')
  }
}

export const getDimensionMessage = (t: TFunction, printersCount: number, size?: IGroupSize) => {
  switch (size?.pattern) {
    case IGroupPattern.GRID:
      return t('printer.group.dimension.grid', { rows: size?.z, columns: size?.x })
    case IGroupPattern.SHIFTED:
      return t('printer.group.dimension.shifted-grid', { rows: size?.z, columns: size?.x })
    default:
      return t('printer.group.dimension.virtual', { printers: printersCount })
  }
}

export const gridToGroupSize = (grid: IGrid, pattern: IGroupPattern): IGroupSize => {
  return { x: grid.col, y: 1, z: grid.row, pattern }
}

export const groupSizeToGrid = (size: IGroupSize | undefined): IGrid => {
  return { col: size?.x || 1, row: size?.z || 1 }
}

export function Group() {
  const params = useParams<{ groupId?: string }>()
  const groupId = Number(params.groupId)
  const { t } = useTranslation()
  const api = useApiClient()
  const queryClient = useQueryClient()
  const teams = useTeams()
  const [loadingPosition, setLoadingPosition] = useState<IVector3 | null>(null)
  const { getTeamName } = useTeamName()
  const { getGroupName } = useGroupName()
  const errorHandler = useErrorHandler()
  const isAdmin = useIsAdmin()

  const { data, refetch: refetchGroup } = useFullGroupDetail(groupId, [], {
    onSettled: () => {
      setLoadingPosition(null)
    }
  })

  const { mutate: editGroup } = useMutation(
    (params: IEditGroupRequestBody) => api.app.printerGroups.editGroup(groupId, params),
    {
      onSuccess: (group) => {
        queryClient.setQueryData<FullGroupDetail[] | undefined>([getFullGroupDetailKey(groupId)], (previous) => {
          if (!previous) {
            return undefined
          }
          return {
            ...previous,
            group
          }
        })
        refetchGroup()
        refetchPrinters()
      },
      onError: (error: IQueryError) => errorHandler(error.response, error)
    }
  )

  // Workaround for paging
  const { data: printers, refetch: refetchPrinters } = useQuery(['/printers-for-dnd'], () =>
    // Get first 20
    api.app.printers.getPrinters({ limit: 20 }).then((first) => {
      const total = first.pager?.total || 0
      const firstLength = first.printers.length

      // No more printers -> end
      if (total - firstLength <= 0) {
        return first.printers
      }

      // Get the rest
      return api.app.printers
        .getPrinters({ offset: firstLength, limit: total - firstLength })
        .then((rest) => [...first.printers, ...rest.printers])
    })
  )

  const { addPrinterToGroup, addPrinterToPhysicalGroup, changePrinterAttributesInGroup, removePrinterFromGroup } =
    useGroupHandler({
      onSuccess: () => {
        refetchGroup()
        refetchPrinters()
      },
      onError: (error: any) => {
        errorHandler(error.response, error)
        setLoadingPosition(null)
      }
    })

  const [modal, setModal] = useState<null | 'invalidGroupSize'>(null)

  if (!data || !printers) {
    return null
  }

  const { group, groupPrinters } = data

  const tryEditGroupSize = (group: IGroupDetail, size: IGroupSize) => {
    if (group.size) {
      // Check the boundaries for the sized group
      const maxUsedX = group.full_positions ? Math.max(...group.full_positions.map((pos) => pos.x)) : null
      const maxUsedZ = group.full_positions ? Math.max(...group.full_positions.map((pos) => pos.z)) : null
      if (maxUsedZ !== null && maxUsedX !== null) {
        if (maxUsedX > size.x || maxUsedZ > size.z) {
          setModal('invalidGroupSize')
          return
        }
      }
    }

    editGroup({
      name: group.name,
      description: group.description,
      team_id: group.team_id,
      size
    })
  }

  const isVirtualGroup = group.size?.pattern !== IGroupPattern.GRID && group.size?.pattern !== IGroupPattern.SHIFTED
  const groupPrintersIds = groupPrinters.printers.map((printer) => printer.uuid) // printers already assigned to this (virtual) group

  const availablePrinters: IDraggablePrinter[] = (printers as IDraggablePrinter[]).map((printer) => {
    let isDisabled = false
    let tooltip = ''

    // disable printers from different team
    if (!isVirtualGroup) {
      isDisabled = printer.team_id !== group.team_id
      tooltip = t('available-printers.tooltip.different-team')
    }

    // disable printers already assigned to any physical group
    if (!isVirtualGroup && printer.group_info) {
      isDisabled = true
      tooltip = t('available-printers.tooltip.already-assigned')
    }

    // disable printers already assigned to this virtual group
    if (isVirtualGroup && groupPrintersIds.includes(printer.uuid)) {
      isDisabled = true
      tooltip = t('available-printers.tooltip.already-assigned-to-this-virtual-group')
    }

    // disable printers without write permission
    const team = teams.find((team) => team.id === printer.team_id)
    const permissions = teamToPermissions(team)
    if (!permissions.canWrite && !isAdmin) {
      isDisabled = true
      tooltip = t('available-printers.tooltip.write-permissions')
    }

    printer.isDisabled = isDisabled
    printer.disabledTooltip = tooltip

    return printer
  })

  const onDragEnd = (result: DropResult) => {
    const action = getAction(result)
    if (!action) {
      return
    }

    if (action.type === DropAction.ADD_TO_GRID) {
      addPrinterToPhysicalGroup({ printerUuid: action.printerUuid, groupId, position: action.position })
      setLoadingPosition(action.position)
    }
    if (action.type === DropAction.ADD_TO_VIRTUAL_GROUP) {
      addPrinterToGroup({ printerUuid: action.printerUuid, groupId })
    }
    if (action.type === DropAction.CHANGE_POSITION) {
      changePrinterAttributesInGroup({ printerUuid: action.printerUuid, groupId, position: action.position })
      setLoadingPosition(action.position)
    }
    if (action.type === DropAction.REMOVE_FROM_GROUP) {
      removePrinterFromGroup({ printerUuid: action.printerUuid, groupId })
    }
  }

  return (
    <>
      <section id="groupinfo">
        <Container fluid>
          <Row>
            <Col>
              {t('table-column.group-name')}
              <H2>{getGroupName(group)}</H2>
            </Col>
            <Col>
              {t('table-column.description')}
              <H2>{group.description}</H2>
            </Col>
            <Col>
              {t('table-column.team')}
              <H2>{getTeamName(teams.find((team) => team.id === group.team_id))}</H2>
            </Col>
            <Col>
              <Row>
                {group.size !== undefined &&
                  (group.size.pattern === IGroupPattern.SHIFTED ? (
                    <DimensionSelector
                      pattern={IGroupPattern.SHIFTED}
                      id="sgds"
                      highlighted
                      selectedSize={groupSizeToGrid(group.size)}
                      showSizePopup={false}
                      onSelected={(grid) => tryEditGroupSize(group, gridToGroupSize(grid, IGroupPattern.SHIFTED))}
                    />
                  ) : (
                    <DimensionSelector
                      pattern={IGroupPattern.GRID}
                      id="gds"
                      highlighted
                      selectedSize={groupSizeToGrid(group.size)}
                      showSizePopup={false}
                      onSelected={(grid) => tryEditGroupSize(group, gridToGroupSize(grid, IGroupPattern.GRID))}
                    />
                  ))}
                <Col>
                  {t('table-column.pattern')}
                  <H2>{getPatternMessage(t, group.size)}</H2>
                </Col>
              </Row>
            </Col>
            <Col>
              {t('table-column.dimension')}
              <H2>{getDimensionMessage(t, group.printer_count, group.size)}</H2>
            </Col>
            <Col style={{ flexGrow: 10 }} />
          </Row>
        </Container>
      </section>

      <DndContextProvider loadingPosition={loadingPosition}>
        <DragDropContext onDragEnd={onDragEnd}>
          <section id="group">
            {group.size ? (
              <Grid
                group={group}
                printers={groupPrinters.printers}
                size={{ row: group.size.z, col: group.size.x }}
                shifted={group.size.pattern === IGroupPattern.SHIFTED}
              />
            ) : (
              <VirtualGroup group={group} printers={groupPrinters.printers} />
            )}
          </section>

          <AvailablePrinters printers={availablePrinters} />
        </DragDropContext>
      </DndContextProvider>
      <InvalidGroupSizeModal show={modal === 'invalidGroupSize'} onHide={() => setModal(null)} />
    </>
  )
}
