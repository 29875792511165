export const lightTheme = {
  backgroundColor: '#ffffff',
  textColorShow: false,
  legend: {
    textStyle: {
      color: '#212529'
    },
    icon: 'circle'
  },
  valueAxis: {
    splitLine: {
      lineStyle: {
        color: '#e5e5e5'
      }
    }
  },
  title: {
    textStyle: {
      color: '#212529'
    },
    subtextStyle: {
      color: '#212529'
    }
  }
}

export const darkTheme = {
  darkMode: true,
  backgroundColor: '#1C1E21',
  textColorShow: false,
  legend: {
    textStyle: {
      color: '#ffffff'
    },
    icon: 'circle'
  },
  valueAxis: {
    splitLine: {
      lineStyle: {
        color: '#4e4e4e'
      }
    }
  },
  title: {
    textStyle: {
      color: '#ffffff'
    },
    subtextStyle: {
      color: '#ffffff'
    }
  },
  tooltip: {
    backgroundColor: '#212529',
    borderColor: '#4e4e4e',
    borderWidth: 1,
    textStyle: {
      color: '#ffffff'
    }
  }
}
