import { useTranslation } from 'react-i18next'

// eslint-disable-next-line import/no-relative-packages
import pkg from '../../../package.json'
// import { useLanguage } from '../hooks/useLanguage'
import { useInfoService } from '../services/useInfoService'
import { SvgIcon } from './common/SvgIcon'
import { WithTooltip } from './common/WithTooltip'
import * as S from './Footer.styled'

const ICON_SIZE = 24

export function Footer() {
  const { t } = useTranslation()
  const data = useInfoService()
  // const { language } = useLanguage()
  // const baseUrl = 'https://blog.prusaprinters.org/'

  const renderVersions = () => {
    const { version } = pkg
    const versions: { label: string; value: string }[] = []
    const releaseVersion = import.meta.env.VITE_RELEASE_VERSION || `${version}-${import.meta.env.VITE_VCS_REF}`

    if (releaseVersion) {
      versions.push({ label: 'FE v.', value: releaseVersion })
    }

    if (data) {
      const { version } = data
      versions.push({ label: 'BE v.', value: version.string })

      if (data.database_version?.script_count) {
        versions.push({ label: 'DB v.', value: data.database_version.script_count.toString() })
      }
    }

    return (
      <S.Versions>
        {versions.map((version) => (
          <div key={version.label}>
            <small>{version.label}</small>
            {version.value}
          </div>
        ))}
      </S.Versions>
    )
  }

  return (
    <S.Footer>
      <S.StyledContainer>
        <S.ColLeft>
          <S.Name>PRUSA CONNECT</S.Name>
        </S.ColLeft>
        <S.ColCenter>
          <WithTooltip
            id="footer-version-tooltip"
            title={<S.Builds className="text-muted">{renderVersions()}</S.Builds>}
            placement="top"
          >
            <S.Span className="text-muted">{new Date().getFullYear()} &copy; Prusa Research a.s.</S.Span>
          </WithTooltip>
          <S.Delimeter />
          {/*
        <S.A
          target="_blank"
          href={`${baseUrl}${language.toLocaleLowerCase()}/terms-of-use-of-services/`}
          className="text-muted"
        >
          {t('footer.terms-of-use')}
        </S.A>
        <S.A
          target="_blank"
          href={`${baseUrl}${language.toLocaleLowerCase()}/supplementary-terms-of-the-prusaprinters-service/`}
          className="text-muted"
        >
          {t('footer.supplementary-terms')}
        </S.A>
        <S.A target="_blank" href={`${baseUrl}${language.toLocaleLowerCase()}/privacy-policy/`} className="text-muted">
          {t('footer.privacy-policy')}
        </S.A> */}
          <S.A className="text-muted ot-sdk-show-settings">{t('footer.cookies-consent')}</S.A>
          <S.A href="/minimal-requirements" className="text-muted">
            {t('footer.minimal-requirements')}
          </S.A>
        </S.ColCenter>
        <S.ColRight>
          <S.IconLink target="_blank" href="https://www.facebook.com/PrusaResearch/" rel="noreferrer">
            <SvgIcon icon="socialFbIcon" size={ICON_SIZE} />
          </S.IconLink>
          <S.IconLink target="_blank" href="https://twitter.com/prusa3d" rel="noreferrer">
            <SvgIcon icon="socialTwitterIcon" size={ICON_SIZE} />
          </S.IconLink>
          <S.IconLink target="_blank" href="https://www.instagram.com/josefprusa/" rel="noreferrer">
            <SvgIcon icon="socialInstagramIcon" size={ICON_SIZE} />
          </S.IconLink>
        </S.ColRight>
      </S.StyledContainer>
    </S.Footer>
  )
}
