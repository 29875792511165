import { ReactNode } from 'react'
import styled from 'styled-components'

import { device } from '../../interfaces/device'

export type ParameterPair = {
  label: string
  value: ReactNode
}

export const Parameters = styled.div`
  display: grid;

  gap: 0.5rem 1rem;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  @media ${device.lg} {
    grid-template-columns: auto 1fr;
  }
`

export const SectionTitle = styled.div`
  font-weight: 700;
  font-size: 15px;
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 0.25rem;
  margin-top: 1rem;
  grid-column: span 2 / span 2;
`

export const Label = styled.div`
  color: var(--color-light);
  text-align: right;
  font-size: 14px;
  font-weight: 500;
`

export const Value = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  word-break: break-word;
`

export const ImageWrapper = styled.div`
  max-width: 380px;
`
