import { useTranslation } from 'react-i18next'

import { ActionsRenderer } from '../../../common/AdaptiveButton/ActionsRenderer'
// import { DownloadFileAction } from '../../actions/DownloadFileAction'
// import { EnqueueAction } from '../../actions/EnqueueAction'
import { DeleteCloudFilesAction } from '../../actions/DeleteCloudFilesAction'

type Props = {
  onDelete?: () => void
  printerTeamId: number
  fileHashes: string[]
}

export function GeneralBulkFileActions(props: Props) {
  const { onDelete, printerTeamId, fileHashes } = props
  const { t } = useTranslation()

  return (
    <ActionsRenderer>
      {/* <EnqueueAction fileHashes={fileHashes}>{renderer}</EnqueueAction> */}
      <DeleteCloudFilesAction
        label={t('button.delete')}
        fileHashes={fileHashes}
        onSuccess={onDelete}
        printerTeamId={printerTeamId}
      />
      {/* <DownloadFileAction fileHashes={fileHashes}>{renderer}</DownloadFileAction> */}
    </ActionsRenderer>
  )
}
