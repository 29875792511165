import Markdown from 'markdown-to-jsx'
import { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import Collapse from 'react-bootstrap/Collapse'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { usePrinterType } from '../../../hooks/usePrinterType'
import { PrinterIcon } from '../../common/PrinterIcon'
import { SvgIcon } from '../../common/SvgIcon'
import { SetupPrinterForm } from './SetupPrinterForm'
import { H2, P as P1 } from './styled'

const Toggle = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
  color: #797979;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 0.5rem;
  padding-bottom: 0.2rem;
  cursor: pointer;
  &:hover {
    color: var(--color-primary);
  }

  svg:last-child {
    height: 12px;
    margin-left: 0.25rem;
  }
`

export const P = styled(P1)`
  margin-top: 2em;
`

export const ColDisabled = styled(Col)`
  color: #c9c9c9;
  font-style: italic;
`

const HelpText = styled.div`
  margin: 30px 0px;
`

type RouteInfo = {
  printerType: string
}

const useInstructions = (): { [key: string]: any } => {
  const { t } = useTranslation()

  return {
    basic: {
      '1.2.5': t('printer.setup.1.2.5.instructions.basic'),
      '1.2.6': t('printer.setup.1.2.6.instructions.basic'),
      '1.3.0': t('printer.setup.1.3.0.instructions.basic'),
      '1.3.1': t('printer.setup.1.3.1.instructions.basic'),
      '1.3.5': t(
        'printer.setup.1.3.5.instructions.basic',
        'If the Original Prusa MK3.5 printer is connected to the internet you could add a printer to Prusa Connect from a menu <i>Settings > Network > Prusa Connect > Add printer to Connect</i>.\n\n Scan the QR code or type the Code manually below.'
      ),
      '1.3.9': t(
        'printer.setup.1.3.9.instructions.basic',
        'If the Original Prusa MK3.9 printer is connected to the internet you could add a printer to Prusa Connect from a menu <i>Settings > Network > Prusa Connect > Add printer to Connect</i>.\n\n Scan the QR code or type the Code manually below.'
      ),
      '1.4.0': t('printer.setup.1.4.0.instructions.basic'),
      '2.1.0': t('printer.setup.2.1.0.instructions.basic'),
      '3.1.0': t('printer.setup.3.1.0.instructions.basic'),
      '4.1.0': t('printer.setup.4.1.0.instructions.basic'),
      '5.1.0': t('printer.setup.5.1.0.instructions.basic'),
      '5.1.1': t(
        'printer.setup.5.1.1.instructions.basic',
        `Choose a name and a location for your printer and download the generated settings file onto a USB flash drive, which you'll connect to the printer. After setting up internet connection on your printer, go to <i>Settings > Load settings from file</i> and enable the connection in <i>Settings > Network > Prusa Connect</i>.`
      ),
      '5.2.0': t(
        'printer.setup.5.2.0.instructions.basic',
        `Choose a name and a location for your printer and download the generated settings file onto a USB flash drive, which you'll connect to the printer. After setting up internet connection on your printer, go to <i>Settings > Load settings from file</i> and enable the connection in <i>Settings > Network > Prusa Connect</i>.`
      ),
      '6.2.0': t('printer.setup.6.2.0.instructions.basic'),
      '6.2.1': t('printer.setup.6.2.1.instructions.basic')
    },
    advanced: {
      '1.3.0': t('printer.setup.1.3.0.instructions.advanced'),
      '1.3.1': t('printer.setup.1.3.1.instructions.advanced')
    }
  }
}

type Props = {
  stepIndex: number
  setStepIndex: (stepIndex: number) => void
  setPrinterType: (printerType: string) => void
}

export function SetupPrinterPage(props: Props) {
  const params = useParams<RouteInfo>()
  const { t } = useTranslation()
  const instructions = useInstructions()
  const [open, setOpen] = useState(false)

  useEffect(() => {
    props.setStepIndex(props.stepIndex)
    props.setPrinterType(String(params.printerType))
  }, [props, params])

  const printerType = usePrinterType(String(params.printerType))
  const printerTypeName = printerType?.name || ''
  const selectedPrinterName = printerTypeName.startsWith('Original Prusa')
    ? printerTypeName
    : `Original Prusa ${printerTypeName}`

  return (
    <>
      <Row>
        <Col md="auto">
          <PrinterIcon size={200} type={params.printerType} />
        </Col>
        <Col>
          <P>{t('printer.add.selected-printer')}</P>
          <H2>{selectedPrinterName}</H2>
        </Col>
      </Row>

      {params.printerType && instructions.basic[params.printerType] && (
        <HelpText>
          <Markdown>{instructions.basic[params.printerType]}</Markdown>

          {instructions.advanced[params.printerType] && (
            <>
              <Toggle onClick={() => setOpen(!open)}>
                {t('printer.add.advanced-configuration')}
                <SvgIcon icon="arrowDownIcon" size={24} fill="#797979" />
              </Toggle>
              <Collapse in={open}>
                <div id="example-collapse-text">
                  <Markdown>{instructions.advanced[params.printerType]}</Markdown>
                </div>
              </Collapse>
            </>
          )}
        </HelpText>
      )}
      <SetupPrinterForm stepIndex={props.stepIndex} printerType={printerType?.id} printerTypeName={printerType?.name} />
    </>
  )
}
