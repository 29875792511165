import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useUpdateProfile } from '../api/services/useAuth'
import { useAuthState } from '../context/authStore'
import { Language, saveLanguage } from '../interfaces/locale'

export function useLanguage() {
  const { isAuthenticated } = useAuthState()
  const { i18n } = useTranslation()
  const { language } = i18n

  const { update } = useUpdateProfile()

  const changeLanguage = useCallback(
    (selectedLanguage: Language) => {
      i18n.changeLanguage(selectedLanguage)
      saveLanguage(selectedLanguage)
      if (isAuthenticated) {
        update({ language: selectedLanguage })
      }
    },
    [i18n, update, isAuthenticated]
  )

  return useMemo(
    () => ({
      language,
      changeLanguage
    }),
    [changeLanguage, language]
  )
}
