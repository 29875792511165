import styled from 'styled-components'

import { isNumber } from '../../helpers/std'
import { FileHashPreview } from '../common/FileHashPreview'
import { FilePreview } from '../common/FilePreview'

type Size = number | `${number}%` | 'auto'

type Props = {
  previewUrl?: string
  hashForThumbnail: string
  size?: Size
  alt?: string
}

const DEFAULT_SIZE = 100

const Placeholder = styled.div<{ $size: Size }>`
  height: ${(props) => (isNumber(props.$size) ? `${props.$size}px` : `${props.$size}`)};
  width: ${(props) => (isNumber(props.$size) ? `${props.$size}px` : `${props.$size}`)};
`

export function JobPreview(props: Props) {
  const { size = DEFAULT_SIZE, previewUrl, hashForThumbnail, alt } = props

  return (
    <Placeholder $size={size}>
      {previewUrl ? <FilePreview previewUrl={previewUrl} alt={alt} /> : <FileHashPreview hash={hashForThumbnail} />}
    </Placeholder>
  )
}
