import { useTranslation } from 'react-i18next'
import Select from 'react-select'

import { useTeamName } from '../../../hooks/useTeamName'
import { useTeams } from '../../../hooks/useTeams'
import { Filter } from '../Filters'
import { useFilterHandlers } from '../hooks/useFilterHandlers'
import { usePrintersQueryState } from '../hooks/usePrintersQueryState'
import { useStyles } from './styles'

type OptionType = {
  label: string
  value: number
}

export type FilterProps = {
  isClearable?: boolean
}

export function TeamFilter({ isClearable }: FilterProps) {
  const { setFilter } = useFilterHandlers()
  const teams = useTeams()
  const { teamId } = usePrintersQueryState()
  const { getTeamName } = useTeamName()
  const { t } = useTranslation()
  const styles = useStyles<OptionType>()

  if (teams.length <= 1) {
    return null
  }

  const options = teams.map((team) => ({ label: getTeamName(team), value: team.id }))

  return (
    <Filter>
      <Select
        hideSelectedOptions
        value={options.find((opt) => opt.value === teamId) || null}
        placeholder={t('printer.team')}
        isClearable={isClearable}
        options={options}
        onChange={(newOption) => {
          const value = newOption?.value
          setFilter({
            key: 'teamId',
            value
          })
        }}
        styles={styles}
      />
    </Filter>
  )
}
