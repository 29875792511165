export enum IConnectState {
  MANIPULATING = 'MANIPULATING',
  UNKNOWN = 'UNKNOWN',
  OFFLINE = 'OFFLINE',
  IDLE = 'IDLE',
  READY = 'READY',
  BUSY = 'BUSY',
  PRINTING = 'PRINTING',
  PAUSED = 'PAUSED',
  STOPPED = 'STOPPED',
  FINISHED = 'FINISHED',
  ERROR = 'ERROR',
  ATTENTION = 'ATTENTION',
  POUR_IN_RESIN = 'POUR_IN_RESIN',
  REFILL = 'REFILL'
}

export enum IStateBaseColor {
  UNKNOWN = '#9DAABD',
  OFFLINE = '#ADADAD',
  IDLE = '#FDDC71',
  READY = '#92C78C',
  BUSY = '#D5A585',
  PRINTING = '#7DA7D9',
  PAUSED = '#C396C4',
  STOPPED = '#F8795F',
  FINISHED = '#A1EA70',
  ERROR = '#DD7B7D',
  ATTENTION = '#F59C66',
  MANIPULATING = '#D5A585',
  POUR_IN_RESIN = '#5FE7F8',
  REFILL = '#5FE7F8'
}

export type IStateProperties = {
  state: IConnectState
  baseColor: string
  default: boolean
}

const UNKNOWN_STATE: IStateProperties = {
  state: IConnectState.UNKNOWN,
  baseColor: IStateBaseColor.UNKNOWN,
  default: false
}

export const states: IStateProperties[] = [
  UNKNOWN_STATE,
  {
    state: IConnectState.OFFLINE,
    baseColor: IStateBaseColor.OFFLINE,
    default: true
  },
  {
    state: IConnectState.IDLE,
    baseColor: IStateBaseColor.IDLE,
    default: false
  },
  {
    state: IConnectState.READY,
    baseColor: IStateBaseColor.READY,
    default: false
  },
  {
    state: IConnectState.PRINTING,
    baseColor: IStateBaseColor.PRINTING,
    default: false
  },
  {
    state: IConnectState.PAUSED,
    baseColor: IStateBaseColor.PAUSED,
    default: false
  },
  {
    state: IConnectState.STOPPED,
    baseColor: IStateBaseColor.STOPPED,
    default: false
  },
  {
    state: IConnectState.FINISHED,
    baseColor: IStateBaseColor.FINISHED,
    default: false
  },
  {
    state: IConnectState.ERROR,
    baseColor: IStateBaseColor.ERROR,
    default: false
  },
  {
    state: IConnectState.BUSY,
    baseColor: IStateBaseColor.BUSY,
    default: false
  },
  {
    state: IConnectState.ATTENTION,
    baseColor: IStateBaseColor.ATTENTION,
    default: false
  },
  {
    state: IConnectState.MANIPULATING,
    baseColor: IStateBaseColor.MANIPULATING,
    default: false
  },
  {
    state: IConnectState.POUR_IN_RESIN,
    baseColor: IStateBaseColor.POUR_IN_RESIN,
    default: false
  },
  {
    state: IConnectState.REFILL,
    baseColor: IStateBaseColor.REFILL,
    default: false
  }
]

export const isActiveState = (state: IConnectState) =>
  state !== IConnectState.UNKNOWN && state !== IConnectState.OFFLINE

export const isPrintingState = (state: IConnectState) =>
  [IConnectState.PRINTING, IConnectState.PAUSED, IConnectState.ATTENTION].includes(state)

export function getState(state: IConnectState) {
  return states.find((s) => s.state === state) || UNKNOWN_STATE
}

export function isState(possible: string): possible is IConnectState {
  return Object.values(IConnectState).includes(possible as IConnectState)
}
