import styled from 'styled-components'

export const SimpleList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
`

export const SimpleListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`
