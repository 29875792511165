import { useFocusRing } from '@react-aria/focus'
import { useNumberFormatter } from '@react-aria/i18n'
import { useSlider, useSliderThumb } from '@react-aria/slider'
import { mergeProps } from '@react-aria/utils'
import { VisuallyHidden } from '@react-aria/visually-hidden'
import { SliderState, SliderStateOptions, useSliderState } from '@react-stately/slider'
import { RefObject, useEffect, useRef, useState } from 'react'

// This is not a regular icon with viewbox
import { ReactComponent as Nozzle } from '../../../svg/nozzle.svg'
import { Axis } from '../../common/Axis'
import { SvgIcon } from '../../common/SvgIcon'
import * as S from './ZAxisSlider.styled'

type Props = {
  positionParams?: {
    max: number
    min: number
  }
  value: number
  disabled?: boolean
  onChangeEnd: (value: number) => void
}

// https://react-spectrum.adobe.com/react-aria/useSlider.html

const index = 0 // only one thumb

const orientation = 'vertical' as const

export function ZAxisSlider(props: Props) {
  const { disabled = false, onChangeEnd, value } = props
  const trackRef = useRef<HTMLDivElement>(null)
  const numberFormatter = useNumberFormatter()
  const [currentValue, setCurrentValue] = useState(value)

  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  const options: Omit<SliderStateOptions<number[]>, 'numberFormatter'> = {
    isDisabled: disabled,
    minValue: props.positionParams?.min,
    maxValue: props.positionParams?.max,
    orientation,
    value: [currentValue],
    onChangeEnd: (values) => {
      if (!disabled) {
        onChangeEnd(values[index])
      }
    },
    onChange: (values) => {
      if (!disabled) {
        setCurrentValue(values[index])
      }
    }
  }
  const state: SliderState = useSliderState({ numberFormatter, ...options })
  const { groupProps, trackProps, labelProps, outputProps } = useSlider(
    {
      ...options,
      'aria-label': 'Z'
    },
    state,
    trackRef
  )

  let height = state.getThumbPercent(index)
  height = height < 0 ? 0 : height

  return (
    <S.Group {...groupProps}>
      <S.TrackContainer {...trackProps} disabled={disabled} ref={trackRef}>
        <S.Track />
        <S.FilledTrack style={{ height: `${height * 100}%` }} />
        <Thumb disabled={disabled} index={index} state={state} trackRef={trackRef} />
      </S.TrackContainer>
      <S.Label {...labelProps} />
      <S.Output {...outputProps}>
        <Axis value={state.values[index]} />
      </S.Output>
    </S.Group>
  )
}

type ThumbProps = {
  disabled: boolean
  index: number
  state: SliderState
  trackRef: RefObject<HTMLDivElement>
}

function Thumb(props: ThumbProps) {
  const { state, trackRef, index, disabled } = props
  const inputRef = useRef(null)
  const { thumbProps, inputProps } = useSliderThumb(
    {
      orientation,
      isDisabled: disabled,
      index,
      trackRef,
      inputRef
    },
    state
  )

  thumbProps.style = {}

  const { focusProps, isFocusVisible } = useFocusRing()
  return (
    <S.ThumbContainer
      style={{
        // Flip the percentage so that 0% is at the bottom and 100% is at the top.
        top: `${(1 - state.getThumbPercent(index)) * 100}%`
      }}
    >
      <S.Thumb {...thumbProps} $isDragging={state.isThumbDragging(index)} $isFocusVisible={isFocusVisible}>
        <div className="d-flex flex-column">
          <S.ThumbInner className="d-flex flex-column">
            <SvgIcon icon="arrowUpIcon" size={20} />
            <SvgIcon icon="rowsActiveIcon" size={20} />
            <SvgIcon icon="arrowDownIcon" size={20} />
          </S.ThumbInner>
          <Nozzle />
        </div>
        <VisuallyHidden>
          <input ref={inputRef} {...mergeProps(inputProps, focusProps)} />
        </VisuallyHidden>
      </S.Thumb>
    </S.ThumbContainer>
  )
}
