const defaultTheme = {
  name: 'default',
  isDark: false,
  colors: {
    primary: 'var(--color-primary)', // orange
    secondary: 'var(--color-secondary)', // black
    light: 'var(--color-light)', // gray
    navigation: 'var(--color-navigation)',
    warning: 'var(--color-warning)',
    backgrounds: {
      body: 'var(--background-body)',
      primary: 'var(--background-primary)', // gray
      secondary: 'var(--background-secondary)' // gray
    },
    borders: {
      primary: 'var(--border-primary)',
      secondary: 'var(--border-secondary)'
    },
    icons: {
      primary: 'var(--icon-primary)',
      secondary: 'var(--icon-secondary)'
    },
    jobRow: {
      border: 'var(--job-border)',
      backgrounds: {
        hover: 'var(--job-background-hover)',
        withIssues: 'var(--job-background-with-issues)',
        hoverWithIssues: 'var(--job-background-with-issues-hover)'
      }
    },
    text: 'var(--color-text)'
  }
}

export type DefaultThemeType = typeof defaultTheme
export { defaultTheme }
