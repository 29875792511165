import { ActionsRenderer } from '../common/AdaptiveButton/ActionsRenderer'
import { RemoveUploadAction } from './actions/RemoveUploadAction'

type Props = {
  uploadId: number
  teamId: number
  refetch?: () => void
  foldable?: boolean
  uniqueContextId: string
}

export function UploadActions({ uploadId, teamId, uniqueContextId, refetch, foldable }: Props) {
  return (
    <ActionsRenderer foldable={foldable}>
      <RemoveUploadAction uploadId={uploadId} onSuccess={refetch} teamId={teamId} uniqueContextId={uniqueContextId} />
    </ActionsRenderer>
  )
}
