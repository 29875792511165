import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { Col, Form, FormControl, InputGroup } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { useApiClient } from '../../api/react'
import { ICreatedFilament, IFilament, Material } from '../../api/types/filament'
import { useToast } from '../../context/toastStore'
import { chunks } from '../../helpers/std'
import { useLoggedUser, useLoggedUserPreferences } from '../../hooks/useLoggedUser'
import { useTeamName } from '../../hooks/useTeamName'
import { useWritableTeams } from '../../hooks/useTeams'
import { Container } from '../bootstrap/Container'
import { Button } from '../common/Button'
import { ContainerTitle } from '../common/ContainerTitle'
import { LoadingButton } from '../common/LoadingButton'
import { TemperatureUnits } from '../common/Temperature'

type Props = {
  filament?: ICreatedFilament
}

export function FilamentForm(props: Props) {
  const api = useApiClient()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const toast = useToast()
  const { writableTeams } = useWritableTeams()
  const user = useLoggedUser()
  const { getTeamName } = useTeamName()
  const units = useLoggedUserPreferences('units')
  const tempSign = units.temp === TemperatureUnits.CELSIUS ? TemperatureUnits.CELSIUS : TemperatureUnits.FAHRENHEIT

  const existingFilament = props.filament
  const isEdit = !!existingFilament

  const [filament, setFilament] = useState(() => {
    if (isEdit) {
      return {
        team_id: existingFilament.team_id,
        density: (existingFilament.density / 1000).toString(),
        bed_temperature: existingFilament.bed_temperature.toString(),
        nozzle_temperature: existingFilament.nozzle_temperature.toString(),
        manufacturer: existingFilament.manufacturer?.toString() || '',
        spool_weight: existingFilament.spool_weight?.toString() || '',
        material: existingFilament.material
      }
    }

    return {
      team_id: user.default_team_id,
      density: '',
      bed_temperature: '',
      nozzle_temperature: '',
      manufacturer: '',
      spool_weight: '',
      material: Material.PLA
    }
  })

  // TODO use formik & add input validation
  const { mutate, isLoading } = useMutation(
    () => {
      const data = {
        ...filament,
        bed_temperature: Number(filament.bed_temperature),
        nozzle_temperature: Number(filament.nozzle_temperature),
        density: Number(filament.density.replace(',', '.')) * 1000,
        spool_weight: Number(filament.spool_weight.replace(',', '.'))
      }
      if (isEdit) {
        return api.app.filaments.modifyFilament(existingFilament.id, data)
      }
      return api.app.filaments.createFilament(data)
    },
    {
      onSuccess: () => {
        toast.add(t('filaments.toasts.filamentSavedTitle'), t('filaments.toasts.filamentSavedText'))
        navigate(-1)
        // TODO refresh table?
      }
    }
  )

  const onChange = (value: string | number, prop: keyof IFilament) => {
    setFilament((prevValue) => ({
      ...prevValue,
      [prop]: value
    }))
  }

  const inputs = [
    <Form.Group>
      <Form.Label>{t('filaments.table.team')}</Form.Label>
      <FormControl
        as="select"
        value={filament.team_id}
        onChange={(e) => onChange(Number(e.currentTarget.value), 'team_id')}
      >
        {writableTeams.map((team) => (
          <option key={team.id} value={team.id}>
            {getTeamName(team)}
          </option>
        ))}
      </FormControl>
    </Form.Group>,
    <Form.Group controlId="filament.manufacturer">
      <Form.Label>{t('filaments.table.manufacturer')}</Form.Label>
      <FormControl
        value={filament.manufacturer}
        onChange={(e) => onChange(e.currentTarget.value, 'manufacturer')}
        type="text"
      />
    </Form.Group>,
    <Form.Group>
      <Form.Label>{t('filaments.table.spoolWeight')}</Form.Label>
      <InputGroup>
        <FormControl
          value={filament.spool_weight}
          onChange={(e) => onChange(e.currentTarget.value, 'spool_weight')}
          type="text"
        />
        <InputGroup.Append>
          <InputGroup.Text>g</InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
    </Form.Group>,
    <Form.Group>
      <Form.Label>{t('filaments.table.bedTemperature')}</Form.Label>
      <InputGroup>
        <FormControl
          value={filament.bed_temperature} // TODO format TemperatureUnits.CELSIUS / TemperatureUnits.FAHRENHEIT
          onChange={(e) => onChange(e.currentTarget.value, 'bed_temperature')}
          type="text"
        />
        <InputGroup.Append>
          <InputGroup.Text>{tempSign}</InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
    </Form.Group>,
    <Form.Group>
      <Form.Label>{t('filaments.table.nozzleTemperature')}</Form.Label>
      <InputGroup>
        <FormControl
          value={filament.nozzle_temperature} // TODO format TemperatureUnits.CELSIUS / TemperatureUnits.FAHRENHEIT
          onChange={(e) => onChange(e.currentTarget.value, 'nozzle_temperature')}
          type="text"
        />
        <InputGroup.Append>
          <InputGroup.Text>{tempSign}</InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
    </Form.Group>,
    <Form.Group>
      <Form.Label>{t('filaments.table.density')}</Form.Label>
      <InputGroup>
        <FormControl
          value={filament.density}
          onChange={(e) => onChange(e.currentTarget.value, 'density')}
          type="text"
        />
        <InputGroup.Append>
          <InputGroup.Text>
            g/cm<sup>3</sup>
          </InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
    </Form.Group>,
    <Form.Group>
      <Form.Label>{t('filaments.table.material')}</Form.Label>
      <FormControl as="select" value={filament.material} onChange={(e) => onChange(e.currentTarget.value, 'material')}>
        {Object.values(Material)
          .sort((a, b) => a.localeCompare(b))
          .map((material) => (
            <option key={material} value={material}>
              {material}
            </option>
          ))}
      </FormControl>
    </Form.Group>
  ]

  return (
    <Container>
      <ContainerTitle>{isEdit ? t('filaments.table.editFilament') : t('filaments.table.newFilament')}</ContainerTitle>
      {chunks(inputs, 3).map((row, i) => (
        <Form.Row key={i}>
          {row.map((input, j) => (
            <Col sm={4} key={j}>
              {input}
            </Col>
          ))}
        </Form.Row>
      ))}

      <div className="d-flex">
        <Button onClick={() => navigate(-1)}>{t('button.back')}</Button>

        <LoadingButton className="ml-auto" isLoading={isLoading} onClick={() => mutate()}>
          {t('button.save')}
        </LoadingButton>
      </div>
    </Container>
  )
}
