import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import { IPrinterSimpleView } from '../../../api/types/printer'
import { convertDistance } from '../../../helpers/formatters'
import { useCommandMutation } from '../../../hooks/commands/useCommandMutation'
import { useLoggedUserPreferences } from '../../../hooks/useLoggedUser'
import { Button } from '../../common/Button'
import { LoadingButtonOutlined } from '../../common/LoadingButtonOutlined'
import { SvgIcon } from '../../common/SvgIcon'

const feedrateExtrudeRetract = 100

export const Step = styled(Button)<{ $active?: boolean }>`
  width: 3rem;
  margin: 0.25rem;
  color: white;

  ${(props) =>
    props.$active
      ? css`
          background-color: #fa6831;
          border-color: #fa6831;
        `
      : css`
          background-color: #6c757d;
          border-color: #6c757d;
        `}
`

type Props = {
  tempIsTooLow: boolean
  printer: IPrinterSimpleView
}

export function ControlExtruder(props: Props) {
  const units = useLoggedUserPreferences('units')

  const extruderRetractSteps = [
    { title: convertDistance(1, units.dimensions, 2), value: 1 },
    { title: convertDistance(5, units.dimensions, 2), value: 5 },
    { title: convertDistance(10, units.dimensions, 2), value: 10 }
  ]
  const extruderExtrudeSteps = [
    { title: convertDistance(10, units.dimensions, 1), value: 10 },
    { title: convertDistance(50, units.dimensions, 1), value: 50 },
    { title: convertDistance(100, units.dimensions, 1), value: 100 }
  ]

  const [currentExtrudeStep, setCurrentExtrudeStep] = useState(extruderExtrudeSteps[1].value)
  const [currentRetractStep, setCurrentRetractStep] = useState(extruderRetractSteps[1].value)
  const { t } = useTranslation()

  const extrudeMutation = useCommandMutation(props.printer, {
    command: 'MOVE_E',
    successMessage: {
      title: t('printer.control.extrude'),
      body: t('printer.control.extrude.description')
    }
  })
  const retractMutation = useCommandMutation(props.printer, {
    command: 'MOVE_E',
    successMessage: {
      title: t('printer.control.retract'),
      body: t('printer.control.retract.description')
    }
  })

  const isLoading = retractMutation.isLoading || extrudeMutation.isLoading
  const isAvailable = retractMutation.isAvailable && retractMutation.isAvailable
  const isDisabled = isLoading || !isAvailable || props.tempIsTooLow
  return (
    <div className="d-flex flex-column align-items-start">
      <div>
        <div>
          {t('printer.control.retract.btn')} {`[${units.dimensions}]`}
        </div>
        <div className="d-flex align-items-center">
          {extruderRetractSteps.map((step, i) => (
            <Step key={i} onClick={() => setCurrentRetractStep(step.value)} $active={step.value === currentRetractStep}>
              {step.title}
            </Step>
          ))}
          <LoadingButtonOutlined
            isLoading={isLoading}
            disabled={isDisabled}
            onClick={() => retractMutation.execute({ feedrate: feedrateExtrudeRetract, length: -currentRetractStep })}
          >
            <SvgIcon icon="retractIcon" size="20" />
            {t('printer.control.retract.btn')}
          </LoadingButtonOutlined>
        </div>
      </div>
      <div>
        <div>
          {t('printer.control.extrude.btn')} {`[${units.dimensions}]`}
        </div>
        <div className="d-flex align-items-center">
          {extruderExtrudeSteps.map((step, i) => (
            <Step key={i} onClick={() => setCurrentExtrudeStep(step.value)} $active={step.value === currentExtrudeStep}>
              {step.title}
            </Step>
          ))}
          <LoadingButtonOutlined
            isLoading={isLoading}
            disabled={isDisabled}
            onClick={() => extrudeMutation.execute({ feedrate: feedrateExtrudeRetract, length: currentExtrudeStep })}
          >
            <SvgIcon icon="nozzleColorIcon" size="20" />
            {t('printer.control.extrude.btn')}
          </LoadingButtonOutlined>
        </div>
      </div>
    </div>
  )
}
