import { useTranslation } from 'react-i18next'

import { Button } from '../../common/Button'
import { SvgIcon } from '../../common/SvgIcon'

type IProps = {
  onClick: () => void
}

export function DeclineInvitationButton({ onClick }: IProps) {
  const { t } = useTranslation()
  return (
    <Button onClick={() => onClick()}>
      <SvgIcon icon="nokIcon" />
      {t('team.invitation.decline')}
    </Button>
  )
}
