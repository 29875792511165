import React, { useCallback, useEffect, useRef, useState } from 'react'
import useMeasure from 'react-use-measure'

import { usePrevious } from '../../hooks/usePrevious'
import * as S from './LongContentAutoScroll.styled'

type Props = {
  speed?: number // pixels per second
  delay?: number // ms
  gradientWidth?: number | string // number of pixels or string with unit ('16px', '10%', ...)
  children: React.ReactNode
}

export function LongContentAutoScroll(props: Props) {
  const { speed = 20, delay = 2000, gradientWidth = 10 } = props

  const [containerRef, containerBounds] = useMeasure({ scroll: true })
  const [textRef, textBounds] = useMeasure({ scroll: true })

  const [offsetX, setOffsetX] = useState(0)
  const [isAnimating, setIsAnimating] = useState(false)

  const totalWidth = textBounds.width
  const visibleWidth = containerBounds.width
  const prevVisibleWidth = usePrevious(visibleWidth)
  const animationRef = useRef(0)

  const invisibleArea = totalWidth - visibleWidth
  const shouldScroll = invisibleArea > 0

  const duration = shouldScroll ? Math.round((invisibleArea / speed) * 1000) : 0

  const triggerAnimation = useCallback(
    () => setOffsetX((prevMargin) => (prevMargin === 0 ? -invisibleArea : 0)),
    [invisibleArea]
  )

  const scheduleAnimation = useCallback(() => {
    // Clear previous timeout
    clearTimeout(animationRef.current)
    setIsAnimating(false)
    if (!shouldScroll) {
      return
    }

    // Schedule animation
    animationRef.current = window.setTimeout(() => {
      setIsAnimating(true)
      triggerAnimation()
    }, delay)
  }, [shouldScroll, delay, triggerAnimation])

  // Schedule initial animation
  useEffect(() => {
    scheduleAnimation()
    return () => clearTimeout(animationRef.current)
  }, [scheduleAnimation])

  // Reset on container resize
  useEffect(() => {
    if (prevVisibleWidth !== visibleWidth && visibleWidth > 0) {
      setIsAnimating(false)
      setOffsetX(0)
      scheduleAnimation()
    }
  }, [prevVisibleWidth, visibleWidth, scheduleAnimation])

  return (
    <S.Container ref={containerRef}>
      <S.Gradient
        $direction={S.GradientDirection.LEFT}
        $visible={shouldScroll && (offsetX !== 0 || isAnimating)}
        $width={gradientWidth}
      >
        <S.Gradient
          $direction={S.GradientDirection.RIGHT}
          $visible={shouldScroll && (offsetX === 0 || isAnimating)}
          $width={gradientWidth}
        >
          <S.ScrollText
            ref={textRef}
            onTransitionEnd={scheduleAnimation}
            $isAnimating={isAnimating}
            $duration={duration}
            $offsetX={offsetX}
          >
            {props.children}
          </S.ScrollText>
        </S.Gradient>
      </S.Gradient>
    </S.Container>
  )
}
