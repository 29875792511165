export function comparePrinters<PrinterLike extends { name?: string; printer_type_name: string }>(
  p1: PrinterLike,
  p2: PrinterLike,
  compareBy: 'name' | 'type'
) {
  let order = 0
  p1.name = p1.name || ''
  p2.name = p2.name || ''

  switch (compareBy) {
    case 'name':
      order = p1.name.localeCompare(p2.name)
      break

    case 'type':
      if (p1.printer_type_name) {
        if (p2.printer_type_name) {
          // compare by type
          order = p1.printer_type_name.localeCompare(p2.printer_type_name)
          if (order === 0) {
            // then by name
            order = p1.name.localeCompare(p2.name)
          }
        } else {
          // printer 1 have type, printer 2 don't have type
          order = 1
        }
      } else if (p2.printer_type_name) {
        // printer 1 don't have type, printer 2 have type,
        order = -1
      } else {
        // both printers don't have type => compare by name
        order = p1.name.localeCompare(p2.name)
      }
      break
  }
  return order
}
