import { useMediaQuery } from 'react-responsive'

import { device } from '../interfaces/device'

export function useDevice() {
  return {
    /** From extra small devices. */
    xs: useMediaQuery({ query: device.xs }),
    /** From small devices */
    sm: useMediaQuery({ query: device.sm }),
    /** From medium devices */
    md: useMediaQuery({ query: device.md }),
    /** From large devices */
    lg: useMediaQuery({ query: device.lg }),
    /** From extra large devices */
    xl: useMediaQuery({ query: device.xl }),
    /** Only touch devices */
    touchDevice: useMediaQuery({ query: device.touchDevice })
  }
}
