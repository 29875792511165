import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import styled from 'styled-components'

import { Icon } from '../../icons'
import { SvgIcon } from '../common/SvgIcon'

const StyledTooltip = styled(Tooltip)<{ placement: 'left' | 'right' | 'top' | 'bottom' }>`
  .arrow::before {
    ${(props) => `border-${props.placement}-color: #F8651B`}; /* Arrow color */
  }
  .tooltip-inner {
    background: #f8651b;
    color: white;
  }
`

type Props = {
  className?: string
  iconSize?: number
  tooltip?: React.ReactNode
  tooltipPlacement?: 'left' | 'right' | 'top' | 'bottom'
  variant: 'warning' | 'info'
}

function getIcon(variant: Props['variant']): Icon {
  if (variant === 'warning') {
    return 'warningIcon'
  }
  return 'questionIcon'
}

const ICON_SIZE = 20

export function TooltipIcon(props: Props) {
  const { iconSize = ICON_SIZE } = props
  const icon = getIcon(props.variant)

  if (props.tooltip) {
    return (
      <OverlayTrigger
        overlay={
          <StyledTooltip id="warning" placement={props.tooltipPlacement || 'bottom'}>
            {props.tooltip}
          </StyledTooltip>
        }
        placement={props.tooltipPlacement || 'bottom'}
      >
        <span>
          <SvgIcon icon={icon} size={iconSize} className={props.className} />
        </span>
      </OverlayTrigger>
    )
  }

  return <SvgIcon icon={icon} size={iconSize} className={props.className} />
}
