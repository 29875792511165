import { Navigate, Route, Routes } from 'react-router-dom'

import { PrintersOverview } from '../components/printers/PrintersOverview/PrintersOverview'
import { usePrintersCount } from '../hooks/usePrintersCount'
import { usePrintersUrl } from '../hooks/usePrintersUrl'

export function PrintersCountCheck({ children }: { children: JSX.Element }) {
  const { data, isLoading } = usePrintersCount()

  // Wait for data
  if (!data || isLoading) {
    return null
  }

  const {
    pager: { total },
    printers
  } = data

  if (total === 0) {
    return <Navigate replace to="../../add-printer?no-printers=1" />
  }
  if (total === 1) {
    return <Navigate replace to={`/printer/${printers[0].uuid}/overview`} />
  }

  return children
}

export function PrintersPage() {
  const printersUrl = usePrintersUrl()

  return (
    <Routes>
      <Route
        path=":view"
        element={
          <PrintersCountCheck>
            <PrintersOverview />
          </PrintersCountCheck>
        }
      />
      <Route index element={<Navigate replace to={printersUrl} />} />
    </Routes>
  )
}
