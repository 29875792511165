import dayjs from 'dayjs'

import { IDataSeries, ITelemetryData, ITelemetryPager, ITelemetryResponse } from '../../../api/types/telemetry'
import { formatNumber, THINSP } from '../../../helpers/formatters'
import { range } from '../../../helpers/std'

const reg = new RegExp(`${THINSP}`, 'g')

export type TimestampedValue = [string, number]

export type TransformmedData = Record<keyof IDataSeries, TimestampedValue[]>

export function getTimestampedValues(data: ITelemetryData, pager: ITelemetryPager): TimestampedValue[] {
  const arr = range(0, pager.count)

  const timestamps = arr.map((i) => pager.from + pager.granularity * i)

  return timestamps.reduce((acc: TimestampedValue[], current, index) => {
    if (!data || data[index] === null || data[index] === undefined) {
      return acc
    }

    const value = data[index] as number
    const dateString = dayjs.unix(current).toISOString()

    acc.push([dateString, value])
    return acc
  }, [])
}

export function interpolateValues(series: ITelemetryData, locale: string): ITelemetryData {
  const defaultValue: ITelemetryData = []
  return series.reduce((acc, currentValue, currentIndex) => {
    const prev = acc[currentIndex - 1]
    if (currentValue === null && prev !== null) {
      acc[currentIndex] = prev ? Number(formatNumber(prev, locale, { fractionDigits: 0 }).replace(reg, '')) : prev
    } else {
      acc[currentIndex] = currentValue
        ? Number(formatNumber(currentValue, locale, { fractionDigits: 0 }).replace(reg, ''))
        : currentValue
    }

    return acc
  }, defaultValue)
}

export function getTransformedTelemetryData(telemetry: ITelemetryResponse, locale: string): TransformmedData {
  return Object.entries(telemetry.data_series).reduce((acc, current) => {
    const [dataName, series] = current
    const key = dataName as keyof IDataSeries
    const interpolatedValues = interpolateValues(series, locale)
    acc[key] = getTimestampedValues(interpolatedValues, telemetry.pager)
    return acc
  }, {} as TransformmedData)
}

// windowSize - number of data points from which average is calculated
export function calculateSimpleMovingAverage(windowSize: number, data?: TimestampedValue[]) {
  if (!data || !data.length) {
    return
  }
  const movingAverages = []

  for (let currentValueIndex = 0; currentValueIndex < windowSize - 1; currentValueIndex++) {
    let sum = 0
    for (let g = 0; g < currentValueIndex + 1; g++) {
      if (data[g] && typeof data[g][1] === 'number') {
        sum += data[g][1]
      }
    }
    const average = sum / (currentValueIndex + 1)
    if (data[currentValueIndex] && typeof data[currentValueIndex][0] === 'number') {
      movingAverages.push([data[currentValueIndex][0], average] as TimestampedValue)
    }
  }

  for (let i = windowSize - 1; i < data.length; i++) {
    let sum = 0
    for (let j = i; j > i - windowSize; j--) {
      sum += data[j][1]
    }
    const average = sum / windowSize
    movingAverages.push([data[i][0], average] as TimestampedValue)
  }

  return movingAverages
}
