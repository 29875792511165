import Markdown from 'markdown-to-jsx'
import { useTranslation } from 'react-i18next'

import { ConfirmModal } from './ConfirmModal'

type Props = {
  show: boolean
  onHide: () => void
  title: string
  body: string
}

export function ErrorModal(props: Props) {
  const { t } = useTranslation()
  const ok = () => {
    props.onHide()
  }

  if (props.show) {
    return (
      <ConfirmModal
        onCancel={props.onHide}
        onConfirm={ok}
        title={props.title}
        body={<Markdown>{props.body}</Markdown>}
        confirmBtnText={t('button.ok')}
        noChoice
      />
    )
  }

  return null
}
