type Replacement = [RegExp, string]

const replacements: Replacement[] = [
  [/\*/g, '\\*'],
  [/#/g, '\\#'],
  [/\//g, '\\/'],
  [/\(/g, '\\('],
  [/\)/g, '\\)'],
  [/\[/g, '\\['],
  [/]/g, '\\]'],
  [/</g, '&lt;'],
  [/>/g, '&gt;'],
  [/_/g, '\\_']
]

export function escapeMarkdownCharacters(input: string) {
  return replacements.reduce((result, replacement) => {
    const [regex, replacementCharacter] = replacement
    return result.replace(regex, replacementCharacter)
  }, input)
}
