import styled, { css } from 'styled-components'

import { textEllipsis } from '../helpers/styled'

export const Layout = styled.div`
  display: flex;
  min-width: 0;
`

export const Left = styled.div`
  position: relative;
`

export const ColImg = styled.div`
  width: 100px;
  height: 100px;
`

export const Right = styled.div<{ $progress?: number }>`
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;

  padding: 0 1rem;
  background-color: var(--progress-background-primary);
  background: linear-gradient(
    to right,
    var(--progress-background-primary) ${(props) => props.$progress || 0}%,
    var(--progress-background-secondary) 0%
  );
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
  width: 100%;
`

export const ButtonIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  margin-top: 10px;
`

export const FileAttributes = styled.div`
  display: flex;
  gap: 1.5rem;
  display: flex;
  margin-top: 1rem;
`

export const Item = styled.div`
  white-space: nowrap;
`

export const labelStyle = css`
  color: #7e7e7e;
  font-size: 0.6875rem;
  white-space: nowrap;
`

export const FileLabel = styled.div`
  ${labelStyle}
`

export const Value = styled.div`
  font-size: 0.875rem;
`

export const Row = styled.div`
  background-color: var(--background-secondary);
`

export const ProgressTitle = styled.div`
  flex: 1;
  min-width: 0;
  ${textEllipsis};
  font-size: 1.125rem;
  font-weight: 700;
  color: var(--color-text);
  margin-top: 10px;
`

export const Size = styled.div``

export const PreviewProgress = styled.div<{ $hasPreview?: boolean }>`
  color: ${(props) => (!props.$hasPreview && !props.theme.isDark ? 'black' : 'white')};
  font-weight: 700;
  font-size: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`
