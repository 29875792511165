import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const Span = styled.span`
  display: inline-block;
`

type Props = {
  value?: boolean
}

export function YesOrNo(props: Props) {
  const { t } = useTranslation()
  return <Span>{props.value ? t('yes') : t('no')}</Span>
}
