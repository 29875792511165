import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'

import { PrinterDetailPage } from '../components/printers/PrinterDetailPage'
import { useAuthState } from '../context/authStore'
import { useRedirectUrl } from '../hooks/useRedirectUrl'
import { AddPrinterByQrManualPage, AddPrinterByQrPage } from './AddPrinterByQrPage'
import { AddPrinterPage } from './AddPrinterPage'
import { AfsHistoryPage } from './AfsHistoryPage'
import { AfsJobDetailPage } from './AfsJobDetailPage'
import { AfsJobsPage } from './AfsJobsPage'
import { AgentDetailPage } from './AgentDetailPage'
import { AgentsListPage } from './AgentsListPage'
import { CommandsPage } from './CommandsPage'
import { ConnectStoragePage } from './ConnectStoragePage'
import { ErrorPage } from './ErrorPage'
import { FilamentsPage } from './FilamentsPage'
import { GamePage } from './GamePage'
import { GroupsPage } from './GroupsPage'
import { InvitationPage } from './InvitationPage'
import { InvitationsPage } from './InvitationsPage'
import { Layout } from './Layout'
import { LoginFormPage } from './LoginFormPage'
import { LoginPage } from './LoginPage'
import { NotificationsPage } from './NotificationsPage'
// import { PrintersDashboardPage } from './PrintersDashboardPage'
import { PrintersPage } from './PrintersPage'
import { ProfilePage } from './ProfilePage'
import { PrusaAuthRedirectPage } from './PrusaAuthRedirectPage'
import { RequirementsPage } from './RequirementsPage'
import { SlicerPage } from './SlicerPage'
import { UsersPage } from './UsersPage'

// Redirect to / if user is NOT logged
export function PrivateRoute() {
  const { isAuthenticated } = useAuthState()
  const { setRedirectUrl, removeRedirectUrl } = useRedirectUrl()

  const { pathname, search } = window.location
  useEffect(() => {
    // store redirect url for later
    if (!isAuthenticated) {
      const redirectUrl = `${pathname}${search}`
      setRedirectUrl(redirectUrl)
    } else {
      // already authenticated -> reset
      removeRedirectUrl()
    }
  }, [isAuthenticated, pathname, removeRedirectUrl, search, setRedirectUrl])

  if (isAuthenticated) {
    return <Outlet />
  }

  return <Navigate replace to="/login" />
}

// Redirect to /printers if user is logged
function GuestOnlyRoute() {
  const { isAuthenticated } = useAuthState()
  const { redirectUrl, removeRedirectUrl } = useRedirectUrl()

  useEffect(() => {
    if (isAuthenticated) {
      removeRedirectUrl()
    }
  }, [isAuthenticated, removeRedirectUrl])

  if (isAuthenticated) {
    return <Navigate replace to={redirectUrl || '/printers'} />
  }

  return <Outlet />
}

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

export function Root() {
  return (
    <SentryRoutes>
      <Route element={<Layout />}>
        <Route element={<PrivateRoute />}>
          {AFS_ENABLED && (
            <>
              <Route path="/jobs/:jobId" element={<AfsJobDetailPage />} />
              <Route path="/jobs" element={<AfsJobsPage />} />
              <Route path="/history/:jobId" element={<AfsJobDetailPage />} />
              <Route path="/history" element={<AfsHistoryPage />} />
            </>
          )}
          <Route path="/agents/:agentId" element={<AgentDetailPage />} />
          <Route path="/agents" element={<AgentsListPage />} />
          {/* <Route path="/printers/overview/*" element={<PrintersDashboardPage />} /> */}
          <Route path="/printers/*" element={<PrintersPage />} />
          <Route path="/printer/:printerUuid/*" element={<PrinterDetailPage />} />
          <Route path="/add-printer/*" element={<AddPrinterPage />} />
          <Route path="/groups/*" element={<GroupsPage />} />
          <Route path="/commands/*" element={<CommandsPage />} />
          <Route path="/profile/*" element={<ProfilePage />} />
          <Route path="/filaments/*" element={<FilamentsPage />} />
          <Route path="/users/*" element={<UsersPage />} />
          <Route path="/invitations" element={<InvitationsPage />} />
          <Route path="/notifications/*" element={<NotificationsPage />} />
          <Route path="/storage/connect/*" element={<ConnectStoragePage />} />
          <Route path="/add/:code/" element={<AddPrinterByQrPage />} />
          <Route path="/add/" element={<AddPrinterByQrManualPage />} />
        </Route>
        <Route element={<GuestOnlyRoute />}>
          <Route path="/login/prusa-auth" element={<PrusaAuthRedirectPage />} />
          <Route path="/login/connect" element={<LoginFormPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/" element={<LoginPage />} />
        </Route>
        {/* <Route path="/roadmap" element={<RoadmapPage />} /> */}
        <Route path="/game" element={<GamePage />} />
        <Route path="/error/*" element={<ErrorPage />} />
        <Route path="/invitation/*" element={<InvitationPage />} />
        <Route path="/minimal-requirements" element={<RequirementsPage />} />
        <Route path="/prusa-slicer/printers" element={<SlicerPage />} />

        <Route path="*" element={<Navigate replace to="/error/404" />} />
      </Route>
    </SentryRoutes>
  )
}
