// AUTOGENERATED, DO NOT EDIT MANUALLY
// @see tooling/icon-generator

import { ReactComponent as agentIcon } from './svg/icons/agent.svg'
import { ReactComponent as aiIcon } from './svg/icons/ai.svg'
import { ReactComponent as arrowDrawIcon } from './svg/icons/arrow_draw.svg'
import { ReactComponent as arrowBottomIcon } from './svg/icons/arrow-bottom.svg'
import { ReactComponent as arrowDownIcon } from './svg/icons/arrow-down.svg'
import { ReactComponent as arrowLeftIcon } from './svg/icons/arrow-left.svg'
import { ReactComponent as arrowRightIcon } from './svg/icons/arrow-right.svg'
import { ReactComponent as arrowTopIcon } from './svg/icons/arrow-top.svg'
import { ReactComponent as arrowUpIcon } from './svg/icons/arrow-up.svg'
import { ReactComponent as arrowsReorderIcon } from './svg/icons/arrows-reorder.svg'
import { ReactComponent as arrowsSortIcon } from './svg/icons/arrows-sort.svg'
import { ReactComponent as arrowsSortDisabledIcon } from './svg/icons/arrows-sort-disabled.svg'
import { ReactComponent as attentionIcon } from './svg/icons/attention.svg'
import { ReactComponent as attentionInverseIcon } from './svg/icons/attention-inverse.svg'
import { ReactComponent as calendarIcon } from './svg/icons/calendar.svg'
import { ReactComponent as cameraIcon } from './svg/icons/camera.svg'
import { ReactComponent as cameraRefreshIcon } from './svg/icons/camera-refresh.svg'
import { ReactComponent as cancelIcon } from './svg/icons/cancel.svg'
import { ReactComponent as cardsViewIcon } from './svg/icons/cards-view.svg'
import { ReactComponent as cloudIconIcon } from './svg/icons/cloud-icon.svg'
import { ReactComponent as commandColorIcon } from './svg/icons/command_color.svg'
import { ReactComponent as controlColorIcon } from './svg/icons/control_color.svg'
import { ReactComponent as copiedIcon } from './svg/icons/copied.svg'
import { ReactComponent as copyIcon } from './svg/icons/copy.svg'
import { ReactComponent as coverIconIcon } from './svg/icons/cover-icon.svg'
import { ReactComponent as coverIconWhiteIcon } from './svg/icons/cover-icon-white.svg'
import { ReactComponent as darkLightModeIcon } from './svg/icons/dark-light-mode.svg'
import { ReactComponent as deleteIcon } from './svg/icons/delete.svg'
import { ReactComponent as disableSteppersIcon } from './svg/icons/disable-steppers.svg'
import { ReactComponent as dislikeIcon } from './svg/icons/dislike.svg'
import { ReactComponent as dotsIcon } from './svg/icons/dots.svg'
import { ReactComponent as downloadIcon } from './svg/icons/download.svg'
import { ReactComponent as downloadArrowIcon } from './svg/icons/download_arrow.svg'
import { ReactComponent as downloadArrowColorIcon } from './svg/icons/download_arrow_color.svg'
import { ReactComponent as downloadBlackIcon } from './svg/icons/download-black.svg'
import { ReactComponent as editIcon } from './svg/icons/edit.svg'
import { ReactComponent as estimatedEndIcon } from './svg/icons/estimated_end.svg'
import { ReactComponent as expandIcon } from './svg/icons/expand.svg'
import { ReactComponent as exposureTimesIcon } from './svg/icons/exposure-times.svg'
import { ReactComponent as fastDownIconIcon } from './svg/icons/fast-down-icon.svg'
import { ReactComponent as fastUpIconIcon } from './svg/icons/fast-up-icon.svg'
import { ReactComponent as filesColorIcon } from './svg/icons/files_color.svg'
import { ReactComponent as filesStorageIcon } from './svg/icons/files_storage.svg'
import { ReactComponent as firmwareIcon } from './svg/icons/firmware.svg'
import { ReactComponent as flashIcon } from './svg/icons/flash.svg'
import { ReactComponent as flashColorIcon } from './svg/icons/flash-color.svg'
import { ReactComponent as folderIcon } from './svg/icons/folder.svg'
import { ReactComponent as folderOpenedIcon } from './svg/icons/folder_opened.svg'
import { ReactComponent as folderUpIcon } from './svg/icons/folder_up.svg'
import { ReactComponent as folderCreateIcon } from './svg/icons/folder-create.svg'
import { ReactComponent as folderOutlineIconIcon } from './svg/icons/folder-outline-icon.svg'
import { ReactComponent as folderTeamIcon } from './svg/icons/folder-team.svg'
import { ReactComponent as gridIcon } from './svg/icons/grid.svg'
import { ReactComponent as gridShiftedIcon } from './svg/icons/grid-shifted.svg'
import { ReactComponent as gridVirtualIcon } from './svg/icons/grid-virtual.svg'
import { ReactComponent as handPhoneQrIconIcon } from './svg/icons/hand-phone-qr-icon.svg'
import { ReactComponent as heatedBedColorIcon } from './svg/icons/heated_bed_color.svg'
import { ReactComponent as heatedBedWhiteColorIcon } from './svg/icons/heated_bed_white_color.svg'
import { ReactComponent as homeIcon } from './svg/icons/home.svg'
import { ReactComponent as homeColorIcon } from './svg/icons/home_color.svg'
import { ReactComponent as homePlatformIcon } from './svg/icons/home-platform.svg'
import { ReactComponent as homeResinTankIcon } from './svg/icons/home-resin-tank.svg'
import { ReactComponent as honeycombIcon } from './svg/icons/honeycomb.svg'
import { ReactComponent as honeycombActiveIcon } from './svg/icons/honeycomb_active.svg'
import { ReactComponent as infoIcon } from './svg/icons/info.svg'
import { ReactComponent as infoInverseIcon } from './svg/icons/info-inverse.svg'
import { ReactComponent as jobDetailIcon } from './svg/icons/job_detail.svg'
import { ReactComponent as likeIcon } from './svg/icons/like.svg'
import { ReactComponent as linkMicroSdIcon } from './svg/icons/link-micro-sd.svg'
import { ReactComponent as linkMicroSdInverseIcon } from './svg/icons/link-micro-sd-inverse.svg'
import { ReactComponent as listViewIconIcon } from './svg/icons/list-view-icon.svg'
import { ReactComponent as loadingWheel4SIcon } from './svg/icons/loading-wheel-4s.svg'
import { ReactComponent as logsColorIcon } from './svg/icons/logs_color.svg'
import { ReactComponent as meshBedLevelingIcon } from './svg/icons/mesh_bed_leveling.svg'
import { ReactComponent as microSdCardIcon } from './svg/icons/micro-sd-card.svg'
import { ReactComponent as minusIcon } from './svg/icons/minus.svg'
import { ReactComponent as noCameraIcon } from './svg/icons/no-camera.svg'
import { ReactComponent as noRestartIcon } from './svg/icons/no-restart.svg'
import { ReactComponent as noWifiIconIcon } from './svg/icons/no-wifi-icon.svg'
import { ReactComponent as nokIcon } from './svg/icons/nok.svg'
import { ReactComponent as notificationIcon } from './svg/icons/notification.svg'
import { ReactComponent as notificationsSettingsIcon } from './svg/icons/notifications-settings.svg'
import { ReactComponent as nozzleIcon } from './svg/icons/nozzle.svg'
import { ReactComponent as nozzleColorIcon } from './svg/icons/nozzle_color.svg'
import { ReactComponent as nozzleWhiteColorIcon } from './svg/icons/nozzle_white_color.svg'
import { ReactComponent as nozzleWhiteIcon } from './svg/icons/nozzleWhite.svg'
import { ReactComponent as okIcon } from './svg/icons/ok.svg'
import { ReactComponent as paperPlaneIcon } from './svg/icons/paper_plane.svg'
import { ReactComponent as pasteIcon } from './svg/icons/paste.svg'
import { ReactComponent as pauseIcon } from './svg/icons/pause.svg'
import { ReactComponent as pauseCurrentIcon } from './svg/icons/pause-current.svg'
import { ReactComponent as planeIcon } from './svg/icons/plane.svg'
import { ReactComponent as plusIcon } from './svg/icons/plus.svg'
import { ReactComponent as printFinishedIcon } from './svg/icons/print_finished.svg'
import { ReactComponent as printStartedIcon } from './svg/icons/print_started.svg'
import { ReactComponent as printerHeaderNotPreparedIcon } from './svg/icons/printer_header_not_prepared.svg'
import { ReactComponent as printerHeaderPauseIcon } from './svg/icons/printer_header_pause.svg'
import { ReactComponent as printerHeaderPlayIcon } from './svg/icons/printer_header_play.svg'
import { ReactComponent as printerHeaderPreparedIcon } from './svg/icons/printer_header_prepared.svg'
import { ReactComponent as printerHeaderStopIcon } from './svg/icons/printer_header_stop.svg'
import { ReactComponent as printerOverviewIcon } from './svg/icons/printer_overview.svg'
import { ReactComponent as printerOverviewColorIcon } from './svg/icons/printer_overview_color.svg'
import { ReactComponent as printingTimeIcon } from './svg/icons/printing_time.svg'
import { ReactComponent as prusalinkFaviconIcon } from './svg/icons/prusalink_favicon.svg'
import { ReactComponent as prusaslicerIcon } from './svg/icons/prusaslicer.svg'
import { ReactComponent as qrCodeIcon } from './svg/icons/qr-code.svg'
import { ReactComponent as questionIcon } from './svg/icons/question.svg'
import { ReactComponent as queueIcon } from './svg/icons/queue.svg'
import { ReactComponent as queueAddIcon } from './svg/icons/queue_add.svg'
import { ReactComponent as queueColorIcon } from './svg/icons/queue_color.svg'
import { ReactComponent as recentPrintsIcon } from './svg/icons/recent_prints.svg'
import { ReactComponent as refillColorIcon } from './svg/icons/refill_color.svg'
import { ReactComponent as refillColorWhiteIcon } from './svg/icons/refill_color_white.svg'
import { ReactComponent as refreshIcon } from './svg/icons/refresh.svg'
import { ReactComponent as remainingTimeIcon } from './svg/icons/remaining_time.svg'
import { ReactComponent as remoteUrlIcon } from './svg/icons/remote_url.svg'
import { ReactComponent as renewIcon } from './svg/icons/renew.svg'
import { ReactComponent as reprintColorIcon } from './svg/icons/reprint_color.svg'
import { ReactComponent as resetPrinterIcon } from './svg/icons/reset-printer.svg'
import { ReactComponent as restartIcon } from './svg/icons/restart.svg'
import { ReactComponent as retractIcon } from './svg/icons/retract.svg'
import { ReactComponent as rowsIcon } from './svg/icons/rows.svg'
import { ReactComponent as rowsActiveIcon } from './svg/icons/rows_active.svg'
import { ReactComponent as saveIcon } from './svg/icons/save.svg'
import { ReactComponent as sdCardIcon } from './svg/icons/sd-card.svg'
import { ReactComponent as settingsIcon } from './svg/icons/settings.svg'
import { ReactComponent as settingsColorIcon } from './svg/icons/settings_color.svg'
import { ReactComponent as sidemenuToggleIcon } from './svg/icons/sidemenu-toggle.svg'
import { ReactComponent as socialFbIcon } from './svg/icons/social_fb.svg'
import { ReactComponent as socialInstagramIcon } from './svg/icons/social_instagram.svg'
import { ReactComponent as socialTwitterIcon } from './svg/icons/social_twitter.svg'
import { ReactComponent as speedColorIcon } from './svg/icons/speed_color.svg'
import { ReactComponent as speedColorWhiteIcon } from './svg/icons/speed_color_white.svg'
import { ReactComponent as spoolColorIcon } from './svg/icons/spool_color.svg'
import { ReactComponent as spoolWhiteColorIcon } from './svg/icons/spool_white_color.svg'
import { ReactComponent as startIcon } from './svg/icons/start.svg'
import { ReactComponent as startInvertedIcon } from './svg/icons/start-inverted.svg'
import { ReactComponent as stateAttentionIcon } from './svg/icons/state-attention.svg'
import { ReactComponent as stateBusyIcon } from './svg/icons/state-busy.svg'
import { ReactComponent as stateDoneIcon } from './svg/icons/state-done.svg'
import { ReactComponent as stateErrorIcon } from './svg/icons/state-error.svg'
import { ReactComponent as stateErrorInverseIcon } from './svg/icons/state-error-inverse.svg'
import { ReactComponent as stateHarvestIcon } from './svg/icons/state-harvest.svg'
import { ReactComponent as stateIdleIcon } from './svg/icons/state-idle.svg'
import { ReactComponent as stateOfflineIcon } from './svg/icons/state-offline.svg'
import { ReactComponent as statePausedIcon } from './svg/icons/state-paused.svg'
import { ReactComponent as statePreparedIcon } from './svg/icons/state-prepared.svg'
import { ReactComponent as statePreparedInvertedIcon } from './svg/icons/state-prepared-inverted.svg'
import { ReactComponent as statePrintingIcon } from './svg/icons/state-printing.svg'
import { ReactComponent as stateServiceIcon } from './svg/icons/state-service.svg'
import { ReactComponent as stateStoppedIcon } from './svg/icons/state-stopped.svg'
import { ReactComponent as stateUnknownIcon } from './svg/icons/state-unknown.svg'
import { ReactComponent as statsColorIcon } from './svg/icons/stats_color.svg'
import { ReactComponent as stopIcon } from './svg/icons/stop.svg'
import { ReactComponent as stopCurrentIcon } from './svg/icons/stop-current.svg'
import { ReactComponent as teamHeadsIconIcon } from './svg/icons/team-heads-icon.svg'
import { ReactComponent as telemetryIcon } from './svg/icons/telemetry.svg'
import { ReactComponent as temperatureColorIcon } from './svg/icons/temperature_color.svg'
import { ReactComponent as upgradeIcon } from './svg/icons/upgrade.svg'
import { ReactComponent as uploadArrowIcon } from './svg/icons/upload_arrow.svg'
import { ReactComponent as usbIcon } from './svg/icons/usb.svg'
import { ReactComponent as warningIcon } from './svg/icons/warning.svg'
import { ReactComponent as zAxisColorIcon } from './svg/icons/z_axis_color.svg'
import { ReactComponent as zAxisColorWhiteIcon } from './svg/icons/z_axis_color_white.svg'

export const Icons = {
  agentIcon,
  aiIcon,
  arrowBottomIcon,
  arrowDownIcon,
  arrowLeftIcon,
  arrowRightIcon,
  arrowTopIcon,
  arrowUpIcon,
  arrowsReorderIcon,
  arrowsSortDisabledIcon,
  arrowsSortIcon,
  arrowDrawIcon,
  attentionInverseIcon,
  attentionIcon,
  calendarIcon,
  cameraRefreshIcon,
  cameraIcon,
  cancelIcon,
  cardsViewIcon,
  cloudIconIcon,
  commandColorIcon,
  controlColorIcon,
  copiedIcon,
  copyIcon,
  coverIconWhiteIcon,
  coverIconIcon,
  darkLightModeIcon,
  deleteIcon,
  disableSteppersIcon,
  dislikeIcon,
  dotsIcon,
  downloadBlackIcon,
  downloadIcon,
  downloadArrowIcon,
  downloadArrowColorIcon,
  editIcon,
  estimatedEndIcon,
  expandIcon,
  exposureTimesIcon,
  fastDownIconIcon,
  fastUpIconIcon,
  filesColorIcon,
  filesStorageIcon,
  firmwareIcon,
  flashColorIcon,
  flashIcon,
  folderCreateIcon,
  folderOutlineIconIcon,
  folderTeamIcon,
  folderIcon,
  folderOpenedIcon,
  folderUpIcon,
  gridShiftedIcon,
  gridVirtualIcon,
  gridIcon,
  handPhoneQrIconIcon,
  heatedBedColorIcon,
  heatedBedWhiteColorIcon,
  homePlatformIcon,
  homeResinTankIcon,
  homeIcon,
  homeColorIcon,
  honeycombIcon,
  honeycombActiveIcon,
  infoInverseIcon,
  infoIcon,
  jobDetailIcon,
  likeIcon,
  linkMicroSdInverseIcon,
  linkMicroSdIcon,
  listViewIconIcon,
  loadingWheel4SIcon,
  logsColorIcon,
  meshBedLevelingIcon,
  microSdCardIcon,
  minusIcon,
  noCameraIcon,
  noRestartIcon,
  noWifiIconIcon,
  nokIcon,
  notificationIcon,
  notificationsSettingsIcon,
  nozzleIcon,
  nozzleWhiteIcon,
  nozzleColorIcon,
  nozzleWhiteColorIcon,
  okIcon,
  paperPlaneIcon,
  pasteIcon,
  pauseCurrentIcon,
  pauseIcon,
  planeIcon,
  plusIcon,
  printerHeaderNotPreparedIcon,
  printerHeaderPauseIcon,
  printerHeaderPlayIcon,
  printerHeaderPreparedIcon,
  printerHeaderStopIcon,
  printerOverviewIcon,
  printerOverviewColorIcon,
  printingTimeIcon,
  printFinishedIcon,
  printStartedIcon,
  prusalinkFaviconIcon,
  prusaslicerIcon,
  qrCodeIcon,
  questionIcon,
  queueIcon,
  queueAddIcon,
  queueColorIcon,
  recentPrintsIcon,
  refillColorIcon,
  refillColorWhiteIcon,
  refreshIcon,
  remainingTimeIcon,
  remoteUrlIcon,
  renewIcon,
  reprintColorIcon,
  resetPrinterIcon,
  restartIcon,
  retractIcon,
  rowsIcon,
  rowsActiveIcon,
  saveIcon,
  sdCardIcon,
  settingsIcon,
  settingsColorIcon,
  sidemenuToggleIcon,
  socialFbIcon,
  socialInstagramIcon,
  socialTwitterIcon,
  speedColorIcon,
  speedColorWhiteIcon,
  spoolColorIcon,
  spoolWhiteColorIcon,
  startInvertedIcon,
  startIcon,
  stateAttentionIcon,
  stateBusyIcon,
  stateDoneIcon,
  stateErrorInverseIcon,
  stateErrorIcon,
  stateHarvestIcon,
  stateIdleIcon,
  stateOfflineIcon,
  statePausedIcon,
  statePreparedInvertedIcon,
  statePreparedIcon,
  statePrintingIcon,
  stateServiceIcon,
  stateStoppedIcon,
  stateUnknownIcon,
  statsColorIcon,
  stopCurrentIcon,
  stopIcon,
  teamHeadsIconIcon,
  telemetryIcon,
  temperatureColorIcon,
  upgradeIcon,
  uploadArrowIcon,
  usbIcon,
  warningIcon,
  zAxisColorIcon,
  zAxisColorWhiteIcon
}

export type Icon = keyof typeof Icons
