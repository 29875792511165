import { createContext, useContext } from 'react'

type AdaptiveButtonType = 'simple' | 'dropdown' | 'icon'

const Context = createContext<AdaptiveButtonType>('simple')

// TODO make simple context default
export const useAdaptiveButtonContext = () => useContext(Context)

export const AdaptiveButtonContext = Context.Provider
