import { useQuery, useQueryClient } from '@tanstack/react-query'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import { useApiClient } from '../../api/react'
import { IPrinter, IPrinterSimpleView } from '../../api/types/printer'
import { IConnectState } from '../../api/types/state'
import { intervals } from '../../config'
import { useCanControl } from '../../context/permissionsStore'
import { useDevice } from '../../helpers/useDevice'
import { useCommandMutation } from '../../hooks/commands/useCommandMutation'
import { useSupportedCommandByUuid } from '../../hooks/commands/useSupportedCommands'
import { usePrinterInState } from '../../hooks/usePrinterInState'
import { useIsSla } from '../../hooks/usePrinterType'
import { getPrinterQueryKey } from '../../services/usePrinterService'
import { ConfirmModal } from '../common/ConfirmModal'
import { LoadingButtonOutlined } from '../common/LoadingButtonOutlined'
import { SvgIcon } from '../common/SvgIcon'
import { countLabel } from '../notifications/Notifications.styled'

const StyledButton = styled(LoadingButtonOutlined)<{ $color?: string; lg: boolean; borderColor?: string }>`
  position: relative;
  display: flex;
  white-space: nowrap;
  svg {
    margin-right: ${({ lg }) => (lg ? '0.25rem;' : '0;')};
  }

  ${({ borderColor }) => `border: 1px solid ${borderColor};`}

  ${({ $color }) =>
    $color &&
    `
    color: ${$color};
  `}

  .planned-jobs-count {
    ${countLabel}
  }
`

export function useSetPrinterReadyModal() {
  const [showSetPrinterReadyModal, setShowStartPrintModal] = useState(false)

  const onSetPrinterReady = useCallback(() => {
    setShowStartPrintModal(true)
  }, [])

  const onCancel = useCallback(() => {
    setShowStartPrintModal(false)
  }, [])

  return {
    showSetPrinterReadyModal,
    onSetPrinterReady,
    onCancel
  }
}

type ModalProps = {
  onCancel: () => void
  printer: IPrinterSimpleView
}

const SET_PRINTER_READY = 'SET_PRINTER_READY'
const getCommandSetReadyMutationKey = (uuid: string) => [SET_PRINTER_READY, uuid]

export const SetReadyButtonModal = (props: ModalProps) => {
  const { t } = useTranslation()
  const { onCancel, printer } = props
  const isSla = useIsSla(printer.printer_type)

  const queryClient = useQueryClient()

  const setReadyMutation = useCommandMutation(
    printer,
    {
      command: SET_PRINTER_READY,
      mutationKey: getCommandSetReadyMutationKey(props.printer.uuid),
      // to ensure on every request, that there will be a new state, cause it is used in set ready button
      cacheTime: 0
    },
    () => {
      const currentPrinterData = queryClient.getQueryData<IPrinter>([getPrinterQueryKey(props.printer.uuid)])
      queryClient.setQueryData([getPrinterQueryKey(props.printer.uuid)], {
        ...currentPrinterData,
        connect_state: IConnectState.READY,
        state: IConnectState.READY
      })
      onCancel()
    }
  )

  const query = {
    offset: 0,
    limit: 1
  }

  const api = useApiClient()
  const { isLoading } = useQuery(
    [`/queue/${printer.uuid}/${JSON.stringify(query)}`],
    () => api.app.jobs.getPrinterQueue(printer.uuid, query),
    {
      refetchInterval: intervals.jobsPolling,
      cacheTime: 0 // dont cache, always get new data
    }
  )

  // prevent modals from switching each other
  if (isLoading) {
    return null
  }

  return (
    <ConfirmModal
      isLoading={setReadyMutation.isLoading}
      title={t('printer.queue.set-prepared.confirm-title')}
      body={
        isSla
          ? t('printer.queue.set-prepared-sla.confirm-body', 'Is the printer ready?')
          : t('printer.queue.set-prepared.confirm-body')
      }
      onCancel={onCancel}
      onConfirm={(e) => {
        e?.preventDefault()
        setReadyMutation.execute(undefined)
      }}
    />
  )
}

type SetPrinterReadyButtonProps = {
  printer: IPrinterSimpleView
  plannedJobsCount?: number
  color?: string
  borderColor?: string
  onSetPrinterReady: () => void
}

export const SetPrinterReadyButton = React.memo(
  ({ printer, plannedJobsCount, color, borderColor, onSetPrinterReady }: SetPrinterReadyButtonProps) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const printerInState = usePrinterInState(printer.connect_state)
    const canControl = useCanControl(printer.team_id)
    const [readyMutationStatus, setReadyMutationStatus] = useState<string>()
    const { lg } = useDevice()
    const queryClient = useQueryClient()
    const abortSetReadyMutation = useCommandMutation(printer, {
      command: 'CANCEL_PRINTER_READY'
    })

    const isSetPrinterReadyEnabled = Boolean(useSupportedCommandByUuid('SET_PRINTER_READY', printer))
    const isSetPrinterNotReadyEnabled = abortSetReadyMutation.isAvailable
    const buttonDisabled = !(isSetPrinterNotReadyEnabled || isSetPrinterReadyEnabled)
    const isSetReadyMutationLoading = readyMutationStatus === 'loading'
    useEffect(() => {
      queryClient.getMutationCache().subscribe(({ mutation }) => {
        const mutationKey = mutation?.options.mutationKey
        if (mutationKey?.includes(SET_PRINTER_READY) && mutationKey?.includes(printer.uuid)) {
          setReadyMutationStatus(mutation?.state.status)
        }
      })
    }, [printer.uuid, queryClient])

    const onButtonClick = isSetPrinterNotReadyEnabled
      ? async () => {
          await abortSetReadyMutation.executeAsync({})
          queryClient.refetchQueries([getPrinterQueryKey(printer.uuid)])
        }
      : () => onSetPrinterReady()

    let disabledTooltip = ''
    if (abortSetReadyMutation.isLoading) {
      disabledTooltip = t('loading')
    } else if (!canControl) {
      disabledTooltip = t('printer.tooltip.rights')
    } else if (buttonDisabled) {
      disabledTooltip = printerInState
    }

    return (
      <StyledButton
        disabled={isSetReadyMutationLoading || abortSetReadyMutation.isLoading || !canControl || buttonDisabled}
        disabledTooltip={disabledTooltip}
        isLoading={isSetReadyMutationLoading || abortSetReadyMutation.isLoading}
        onClick={onButtonClick}
        $color={color}
        borderColor={borderColor}
        lg={lg}
      >
        {typeof plannedJobsCount === 'number' && plannedJobsCount > 0 && (
          <span className="planned-jobs-count" data-testid="planned-jobs-count">
            {plannedJobsCount}
          </span>
        )}
        <SvgIcon
          icon={isSetPrinterNotReadyEnabled ? 'printerHeaderNotPreparedIcon' : 'printerHeaderPreparedIcon'}
          size={25}
          fill={theme.colors.primary}
        />
        {lg &&
          (isSetPrinterNotReadyEnabled
            ? t('printer.queue.set-not-prepared.button-label')
            : t('printer.queue.set-prepared.button-label'))}
      </StyledButton>
    )
  }
)
