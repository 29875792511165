import { useMutation } from '@tanstack/react-query'

import { useApiClient } from '../api/react'
import { useAuthActions } from '../context/authStore'

export function useLogoutService() {
  const auth = useAuthActions()

  const api = useApiClient()
  const { mutate: logoutUser } = useMutation(() => api.general.logout(), {
    onSuccess: ({ redirect_url }) => {
      if (!redirect_url) {
        // No redirect URL = no oAuth. We'll need to update the application state manually
        auth.logout()
        return
      }

      try {
        /* 
        The logout process is handled by an external service.
        We'll add a return URL and perform the redirect.
        */
        const urlObject = new URL(redirect_url)
        urlObject.searchParams.set('next', window.location.origin)
        window.location.href = urlObject.toString()
      } catch (error) {
        // Invalid URL received. Try to logout manually.
        auth.logout()
      }
    }
  })

  return {
    logoutUser
  }
}
