import { useMutation } from '@tanstack/react-query'
import Markdown from 'markdown-to-jsx'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useApiClient } from '../../../api/react'
import useServiceResponseHandler from '../../../services/useServiceResponseHandler'
import { Button } from '../../common/Button'
import { ConfirmModal } from '../../common/ConfirmModal'
import { SvgIcon } from '../../common/SvgIcon'

type Props = {
  id: number
  name: string
  update: () => void
  disabled: boolean
}

export function RemoveGroup(props: Props) {
  const [show, setShow] = useState(false)
  const { displaySuccess } = useServiceResponseHandler()
  const { t } = useTranslation()
  const api = useApiClient()

  const { mutate: removeGroup } = useMutation(() => api.app.printerGroups.deleteGroup(props.id), {
    onSuccess: () => {
      displaySuccess(
        t('service.printer.group.deleted.title'),
        t('service.printer.group.deleted.body', { name: props.name })
      )
      setShow(false)
      props.update()
    }
  })

  const cancelRemove = () => {
    setShow(false)
  }

  const onRemoveClick = () => {
    setShow(true)
  }

  return (
    <>
      <Button size="sm" disabled={props.disabled} onClick={onRemoveClick}>
        <SvgIcon icon="deleteIcon" size={16} />
        {t('button.delete')}
      </Button>
      {show && (
        <ConfirmModal
          onCancel={cancelRemove}
          onConfirm={() => removeGroup()}
          title={t('printer.group.remove-title')}
          body={<Markdown>{t('printer.group.remove-body', { name: props.name })}</Markdown>}
          confirmBtnText={t('printer.group.remove-button')}
        />
      )}
    </>
  )
}
