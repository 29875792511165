import styled, { css } from 'styled-components'

export enum PreviewSize {
  LARGE = 20,
  SMALL = 14
}

type Props = {
  hex?: string
  className?: string
  size?: PreviewSize
}

// https://stripesgenerator.com/
const StyledPreview = styled.div<{ $background?: string; $size: number }>`
  border: 1px solid black;
  height: ${(props) => props.$size}px;
  width: ${(props) => props.$size}px;
  ${(props) =>
    props.$background
      ? css`
          background: ${props.$background};
        `
      : css`
          background-image: linear-gradient(
            135deg,
            #ffffff 30%,
            var(--color-primary) 30%,
            var(--color-primary) 50%,
            #ffffff 50%,
            #ffffff 80%,
            var(--color-primary) 80%,
            var(--color-primary) 100%
          );
          background-size: 5px 5px;
        `}
`

export function ColorPreview(props: Props) {
  const { size = PreviewSize.LARGE } = props
  return <StyledPreview className={props.className} $background={props.hex} $size={size} />
}
