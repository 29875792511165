import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query'

import { useApiClient } from '../api/react'
import { INotification } from '../api/types/notifications'

export function useNotificationsService() {
  const api = useApiClient()
  const notificationsPerPage = 20

  const { data: unseenCountData, refetch: refetchUnseen } = useQuery(
    ['/notifications/unseen'],
    api.app.notifications.getUnseen,
    {
      refetchInterval: 10000
    }
  )

  const { data, refetch, isLoading, isFetching, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ['/notifications'],
    api.app.notifications.getNotifications,
    {
      getNextPageParam: (lastPage, pages) => {
        if (!lastPage || !pages) {
          return
        }
        const totalPages = Math.ceil(lastPage.pager.total / notificationsPerPage)
        const hasNextPage = pages.length < totalPages
        return hasNextPage ? lastPage.pager.offset + notificationsPerPage : undefined
      },
      onSuccess: () => refetchUnseen(),
      refetchOnWindowFocus: false,
      enabled: false
    }
  )
  const notifications =
    data?.pages?.reduce((acc: INotification[], current) => {
      if (!current.notifications) {
        return acc
      }
      acc = [...acc, ...current.notifications]
      return acc
    }, []) || []

  const { mutate: setAllRead } = useMutation(() => api.app.notifications.setAllRead(), {
    onSuccess: () => refetch()
  })

  const { mutate: setRead } = useMutation((id: number) => api.app.notifications.setRead(id), {
    onSuccess: () => refetch()
  })

  return {
    notifications,
    unseenCount: unseenCountData?.unseen || 0,
    setRead,
    setAllRead,
    isLoading,
    isFetching,
    refetch,
    fetchNextPage,
    hasNextPage
  }
}
