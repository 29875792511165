import { useMediaQuery } from 'react-responsive'

import { Breakpoint } from '../interfaces/breakpoints'
import { device } from '../interfaces/device'

function getQuery(size: Breakpoint) {
  switch (size) {
    case 'xs':
      return device.xs
    case 'sm':
      return device.sm
    case 'md':
      return device.md
    case 'lg':
      return device.lg
    case 'tablet':
      return device.tablet
    case 'xl':
      return device.xl
    case 'xxl':
      return device.xxl
    default:
      return undefined
  }
}

export function useBreakpoint(size: Breakpoint) {
  return useMediaQuery({ query: getQuery(size) })
}
