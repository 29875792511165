import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { AvailablePrinterTypes } from '../../../api/types/printer'
import { useFdmPrinterType, useSlaPrinterTypes } from '../../../hooks/usePrinterType'
import { useQueryParams } from '../../../hooks/useQueryParams'
import fdm from '../../../img/printers/fdm.png'
import sla from '../../../img/printers/sla.png'
import { PrinterIcon } from '../../common/PrinterIcon'
import { ESHOP_URL } from '../../header/HeaderMenu'
import { H2, P } from './styled'

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 180px);
  row-gap: 1rem;
`

const PrinterLink = styled(Link)`
  display: block;
`

const Tile = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 180px;
  height: 100%;
  min-height: 180px;
  border-radius: 4px;
  text-decoration: none !important;

  span {
    font-size: 0.8em;
    margin-top: auto;
    text-align: center;
    padding-bottom: 0.3em;
    color: ${(props) => (props.theme.name === 'dark' ? 'var(--neutral-700)' : 'var(--neutral-400)')};
    text-decoration: none;
  }

  &:hover {
    background-color: #585858;

    span {
      color: white;
      text-decoration: none;
    }
  }
`

const Image = styled.div<{ $bgUrl: string }>`
  background: url(${(props) => props.$bgUrl}) center center no-repeat;
  background-size: contain;
  flex: 1;
`

type Props = {
  stepIndex: number
  setStepIndex: (stepIndex: number) => void
}

export function SelectPrinterPage(props: Props) {
  const { t } = useTranslation()
  const params = useQueryParams()
  const slaItems = useSlaPrinterTypes()
  const fdmItems = useFdmPrinterType()
  useEffect(() => {
    props.setStepIndex(props.stepIndex)
  }, [props])

  const supportedPrinterTypes: string[] = Object.values(AvailablePrinterTypes)

  return (
    <>
      <P>
        {params['no-printers'] && t('printer.add.no-printer-yet')} {t('printer.add.select-printer')}
      </P>
      <H2>{t('printer.add.fdm-printers')}</H2>
      <Row>
        {fdmItems
          .filter((item) => !item.is_manipulator)
          .filter((type) => supportedPrinterTypes.indexOf(type.id) !== -1)
          .map((item) => (
            <PrinterLink key={item.id} to={`../setup/${item.id}`}>
              <Tile>
                <PrinterIcon type={item.id} />
                <span>{item.name}</span>
              </Tile>
            </PrinterLink>
          ))}
        {fdmItems.length > 0 && (
          <a href={ESHOP_URL} target="_blank" rel="noreferrer">
            <Tile>
              <Image $bgUrl={fdm} />
              <span>{t('printer.add.buy-fdm-printer')}</span>
            </Tile>
          </a>
        )}
      </Row>

      {slaItems.length > 0 && (
        <>
          <H2>{t('printer.add.sla-printers')}</H2>
          <Row>
            {slaItems
              .filter((item) => !item.is_manipulator)
              .filter((type) => supportedPrinterTypes.indexOf(type.id) !== -1)
              .map((item) => (
                <Link key={item.id} to={`../setup/${item.id}`}>
                  <Tile>
                    <PrinterIcon type={item.id} />
                    <span>{item.name}</span>
                  </Tile>
                </Link>
              ))}
            <a href={ESHOP_URL} target="_blank" rel="noreferrer">
              <Tile>
                <Image $bgUrl={sla} />
                <span>{t('printer.add.buy-sla-printer')}</span>
              </Tile>
            </a>
          </Row>
        </>
      )}
    </>
  )
}
