export enum PrintersViewEnum {
  TABLE = 'table',
  GRID = 'grid',
  QUEUE = 'queue',
  CARDS = 'cards'
}

export enum PrintersPreviewEnum {
  TABLE = 'table',
  CARDS = 'cards',
  QUEUE = 'queue'
}
