import { ReactNode, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IPrinterSimpleView } from '../../../api/types/printer'
import { useCanControl } from '../../../context/permissionsStore'
import { useToast } from '../../../context/toastStore'
import { useCommandMutation } from '../../../hooks/commands/useCommandMutation'
import { dispatchPrintStoppedEvent } from '../../../hooks/useStoppedPrint'
import { Icon } from '../../../icons'
import { AdaptiveButton } from '../../common/AdaptiveButton/AdaptiveButton'
import { ConfirmModal } from '../../common/ConfirmModal'
import { translateLabel } from '../../common/PrinterStateTag'

type Config = {
  label: string
  icon: Icon
  command: string
  successMessage: {
    title: string
    body: string
  }
  confirm: {
    title: string
    body: string
    icon: Icon
  }
}

type PublicProps = {
  onSuccess?: () => void
  withConfirm?: boolean
  iconNode?: ReactNode
  printer: IPrinterSimpleView
}

type Props = PublicProps & {
  config: Config
}

type CommandError = {
  code: string
  message: string
}

function PrintControlAction({ onSuccess, withConfirm, iconNode, printer, config }: Props) {
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const toast = useToast()
  const canControl = useCanControl(printer.team_id)
  const { t } = useTranslation()

  const connectState = translateLabel(t, printer.connect_state)
  const isStopCommand = config.command === 'STOP_PRINT'

  const { execute, isLoading, isAvailable } = useCommandMutation(printer, {
    icon: config.icon,
    label: config.label,
    command: config.command,
    successMessage: {
      title: config.successMessage.title,
      body: config.successMessage.body
    }
  })

  const startAction = useCallback(() => {
    execute(
      {},
      {
        onSuccess: () => {
          if (isStopCommand) {
            dispatchPrintStoppedEvent(printer.uuid, printer.printer_type, printer?.job_info?.id)
          }
          setShowConfirmModal(false)
          onSuccess?.()
        },
        onError: (error) => {
          const errorObj = error as CommandError
          setShowConfirmModal(false)
          toast.add(errorObj.code, errorObj.message)
        }
      }
    )
  }, [toast, execute, onSuccess])

  const trigger = useCallback(() => {
    if (withConfirm) {
      setShowConfirmModal(true)
    } else {
      startAction()
    }
  }, [startAction, withConfirm])

  let disabledTooltip = ''
  if (!canControl) {
    disabledTooltip = t('printer.tooltip.rights')
  } else if (isLoading) {
    disabledTooltip = t('printer.command.processing')
  } else {
    disabledTooltip = `${t('printer.overview.currentFile.in-state')} ${connectState.toUpperCase()}`
  }

  return (
    <>
      <AdaptiveButton
        icon={config.icon}
        iconNode={iconNode}
        trigger={trigger}
        label={config.label}
        isLoading={isLoading}
        isAvailable={isAvailable}
        isDisabled={isLoading || !isAvailable || !canControl}
        disabledTooltip={disabledTooltip}
      />

      {showConfirmModal && (
        <ConfirmModal
          isLoading={isLoading}
          title={config.confirm.title}
          body={config.confirm.body}
          confirmBtnIcon={config.confirm.icon}
          confirmBtnText={config.label}
          onCancel={() => {
            setShowConfirmModal(false)
          }}
          onConfirm={() => {
            startAction()
          }}
        />
      )}
    </>
  )
}

export const ResumePrintAction = (props: PublicProps) => {
  const { t } = useTranslation()

  return (
    <PrintControlAction
      {...props}
      config={{
        command: 'RESUME_PRINT',
        label: t('printer.actions.resume-print.label'),
        icon: 'startIcon',
        confirm: {
          title: t('printer.actions.resume-print.confirm.title'),
          body: t('printer.actions.resume-print.confirm.body'),
          icon: 'startIcon'
        },
        successMessage: {
          title: t('printer.actions.resume-print.success.title'),
          body: t('printer.actions.resume-print.success.body')
        }
      }}
    />
  )
}

export const PausePrintAction = (props: PublicProps) => {
  const { t } = useTranslation()

  return (
    <PrintControlAction
      {...props}
      config={{
        command: 'PAUSE_PRINT',
        label: t('printer.actions.pause-print.label'),
        icon: 'pauseCurrentIcon',
        confirm: {
          title: t('printer.actions.pause-print.confirm.title'),
          body: t('printer.actions.pause-print.confirm.body'),
          icon: 'pauseCurrentIcon'
        },
        successMessage: {
          title: t('printer.actions.pause-print.success.title'),
          body: t('printer.actions.pause-print.success.body')
        }
      }}
    />
  )
}

export const StopPrintAction = (props: PublicProps) => {
  const { t } = useTranslation()

  return (
    <PrintControlAction
      {...props}
      config={{
        command: 'STOP_PRINT',
        label: t('printer.actions.stop-print.label'),
        icon: 'stopCurrentIcon',
        confirm: {
          title: t('printer.actions.stop-print.confirm.title'),
          body: t('printer.actions.stop-print.confirm.body'),
          icon: 'stopCurrentIcon'
        },
        successMessage: {
          title: t('printer.actions.stop-print.success.title'),
          body: t('printer.actions.stop-print.success.body')
        }
      }}
    />
  )
}

export const SetPrinterPreparedHeaderAction = (props: PublicProps) => {
  const { t } = useTranslation()

  return (
    <PrintControlAction
      {...props}
      config={{
        command: 'SET_PRINTER_READY',
        label: t('printer.queue.set-prepared.success-title'),
        icon: 'statePreparedIcon',
        confirm: {
          title: t('printer.queue.set-prepared.confirm-title'),
          body: t('printer.queue.set-prepared.confirm-body'),
          icon: 'statePreparedIcon'
        },
        successMessage: {
          title: t('printer.queue.set-prepared.success-title'),
          body: t('printer.queue.set-prepared.success-body')
        }
      }}
    />
  )
}
