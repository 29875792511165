import { MouseEvent, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'

import { identity } from '../../helpers/std'
import { Icon } from '../../icons'
import { Button } from './Button'
import { LoadingButtonOutlined } from './LoadingButtonOutlined'
import { SvgIcon } from './SvgIcon'

export type ConfirmModalProps = {
  id?: string
  onCancel: () => void
  onConfirm: (e: MouseEvent<HTMLButtonElement>) => void
  isLoading?: boolean
  isDisabled?: boolean
  title?: ReactNode
  body?: ReactNode
  confirmBtnText?: ReactNode
  cancelBtnText?: ReactNode
  confirmBtnIcon?: Icon
  cancelBtnIcon?: Icon
  noChoice?: boolean
  showConfirmIcon?: boolean
  show?: boolean
  modalSize?: 'sm' | 'lg' | 'xl'
}

export function ConfirmModal(props: ConfirmModalProps) {
  const { t } = useTranslation()
  const {
    id,
    onCancel,
    onConfirm,
    showConfirmIcon = true,
    isLoading,
    isDisabled,
    title = t('confirmModal.title'),
    body = t('confirmModal.text'),
    confirmBtnText = t('button.confirm'),
    cancelBtnText = t('button.cancel'),
    confirmBtnIcon = 'okIcon',
    cancelBtnIcon,
    noChoice = false,
    show = true,
    modalSize
  } = props

  // confirm on enter
  useEffect(() => {
    const onEnter = (e: KeyboardEvent) => {
      if (e.code === 'Enter') {
        const event = e as any
        onConfirm(event)
      }
    }
    document.addEventListener('keyup', onEnter)
    return () => document.removeEventListener('keyup', onEnter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const modalContainer = document.getElementById('modals')
  if (!modalContainer) {
    throw new Error('Modal container #modals not found! Check your component tree')
  }

  return createPortal(
    <Modal id={id} show={show} onHide={onCancel} centered size={modalSize}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <>
          <LoadingButtonOutlined isLoading={isLoading} disabled={isLoading || isDisabled} onClick={onConfirm}>
            {showConfirmIcon && <SvgIcon icon={confirmBtnIcon} size={18} />}
            {confirmBtnText}
          </LoadingButtonOutlined>
          {!noChoice && (
            <Button onClick={onCancel} size="sm" disabled={isLoading}>
              {cancelBtnIcon && <SvgIcon icon={cancelBtnIcon} size={16} />}
              {cancelBtnText}
            </Button>
          )}
        </>
      </Modal.Footer>
    </Modal>,
    modalContainer
  )
}

type WithConfirmProps = {
  title?: string
  body?: string
  children: (callback: Function) => void
}

export function WithConfirm(props: WithConfirmProps) {
  const ref = useRef<Function>(identity)
  const [showModal, setShowModal] = useState(false)

  const confirm = useCallback(
    (cb: Function) => () => {
      setShowModal(true)
      ref.current = cb
    },
    []
  )

  return (
    <>
      {props.children(confirm)}
      {showModal && (
        <ConfirmModal
          title={props.title}
          body={props.body}
          onCancel={() => {
            setShowModal(false)
          }}
          onConfirm={() => {
            ref.current()
            setShowModal(false)
          }}
        />
      )}
    </>
  )
}
