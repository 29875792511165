import { Button, Row as BootstrapRow } from 'react-bootstrap'
import styled from 'styled-components'

import { device } from '../../../../interfaces/device'
import { PlainButton } from '../../../common/PlainButton'
import { HEIGHT } from './Printer'

export const BottomPanel = styled.div`
  width: 100%;
  height: auto;
  border-top: 1px solid darkgray;
  border-bottom: 1px solid darkgray;
  margin-top: 40px;

  @media ${device.md} {
    margin-top: 0;
  }

  .highlighted > div {
    background: #fa6831;
  }
`

export const Filters = styled.div`
  background: var(--background-body);
  transition: 0.6s;
  overflow: hidden;
  padding: 0 15px;
`

export const FiltersButton = styled(Button)`
  border: 0;
  position: absolute;
  top: -36px;
  right: 0;
`

export const Printers = styled.div`
  display: flex;
  width: 100%;
  overflow: auto hidden;
`

export const PrintersRow = styled.div<{ $isDraggingOver: boolean }>`
  position: relative;
  display: flex;
  width: 100%;
  background: ${(props) => (props.$isDraggingOver ? 'var(--color-primary)' : 'var(--available-printers-background)')};
  align-items: center;
  min-height: ${HEIGHT}px;
`

export const Row = styled(BootstrapRow)`
  margin: 0;
`

export const ScrollArrow = styled(PlainButton)<{ $flipped?: boolean }>`
  cursor: pointer;
  ${({ $flipped }) => ($flipped ? 'transform: rotateY(180deg);' : '')}
`
