import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ChangeEvent } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
  max-width: 1rem;
  min-width: 1rem;
  height: 1rem;
`

const CheckboxInput = styled.input.attrs({
  type: 'checkbox'
})`
  -webkit-appearance: none;
  appearance: none;

  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 0.15em;
  border: 1px solid ${({ theme }) => theme.colors.light};
  outline: none;
  cursor: pointer;

  :hover {
    border: 1px solid ${({ theme }) => theme.colors.secondary};
  }

  :checked {
    background-color: ${({ theme }) => theme.colors.primary};
    border: none;
  }
`

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 0.1rem;
  left: 0.2rem;
  pointer-events: none;
`

type CustomCheckboxProps = {
  id?: string
  name: string
  checked: boolean
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
}

export const CustomCheckbox = ({ id, name, checked, onChange, disabled }: CustomCheckboxProps) => (
  <Wrapper>
    <CheckboxInput id={id} name={name} checked={checked} onChange={onChange} disabled={disabled} />
    {checked && <StyledFontAwesomeIcon icon={faCheck} color="white" size="sm" />}
  </Wrapper>
)
