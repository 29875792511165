import styled from 'styled-components'

import { THINSP } from '../../helpers/formatters'

const Span = styled.span`
  width: 5ch;
  display: inline-block;
`

const PERCENT_SYMBOL = '%'

type IProps = {
  value: number | undefined | null | false
  fractionDigits?: number
}

export function Percents({ value, fractionDigits = 0 }: IProps) {
  return (
    <Span className="nbsp">
      {value !== undefined && Number(value).toFixed(fractionDigits)}
      {THINSP}
      {PERCENT_SYMBOL}
    </Span>
  )
}
