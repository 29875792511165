import { createContext, ReactNode, useCallback, useContext, useState } from 'react'

import { IGroupDetail } from '../api/types/groups'
import { IConnectState } from '../api/types/state'
import { useUpdateUserPreferences } from '../components/preferences/helpers'
import { FilterParams } from '../components/printers/hooks/useFilterHandlers'
import { usePrintersQueryState } from '../components/printers/hooks/usePrintersQueryState'
import { useLoggedUserPreferences } from '../hooks/useLoggedUser'
import { PrintersViewEnum } from '../interfaces/view'

type Filter = {
  states?: IConnectState[]
  groupId?: number
  teamId?: number
  sort_by?: string
}

const defaultFilter: Filter = {}

const PrintersStateContext = createContext<{
  filter: Filter
  view: PrintersViewEnum
  group?: IGroupDetail
  setGroup: (group?: IGroupDetail) => void
  setFilter: (params: FilterParams) => void
  resetFilter: () => void
  resetSort: (params: FilterParams) => void
  setView: (view: PrintersViewEnum) => void
}>({
  setGroup: () => null,
  filter: defaultFilter,
  setFilter: () => null,
  resetFilter: () => null,
  resetSort: () => null,
  view: PrintersViewEnum.TABLE,
  setView: () => null
})

export function usePrintersStateContext() {
  return useContext(PrintersStateContext)
}

export function PrintersStateContextProvider({ children }: { children: ReactNode }) {
  const preferences = useLoggedUserPreferences()
  const { printers } = preferences
  const view = printers.mode
  const { update } = useUpdateUserPreferences()
  const { ...queryFilter } = usePrintersQueryState()
  const [filter, setFilterInternal] = useState<Filter>(queryFilter)
  const [group, setGroup] = useState<IGroupDetail>()

  const setFilter = useCallback((params: FilterParams) => {
    setFilterInternal((prev) => ({
      ...prev,
      [params.key]: params.value
    }))
  }, [])

  const resetFilter = useCallback(() => setFilterInternal((prev) => ({ ...defaultFilter, sort_by: prev.sort_by })), [])

  const resetSort = useCallback(
    (params: FilterParams) =>
      setFilterInternal((prev) => ({
        ...prev,
        [params.key]: params.value,
        sort_by: ''
      })),
    []
  )

  const setView = useCallback((newPreferredView: PrintersViewEnum) => {
    const printerPref = { ...printers }
    printerPref.mode = newPreferredView
    update({ preferences: { ...preferences, printers: printerPref } })
  }, [])

  return (
    <PrintersStateContext.Provider
      value={{ setFilter, resetFilter, resetSort, filter, view, setView, group, setGroup }}
    >
      {children}
    </PrintersStateContext.Provider>
  )
}
