import { Link as RouterLink } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { ButtonLink } from '../common/ButtonLink'
import { PlainButton } from '../common/PlainButton'
import { Layers } from '../helpers/zIndex'
import { HEADER_HEIGHT, TOPBAR_HEIGHT } from './Header.styled'

export const TRANSITION_SPEED = 200

export const Drawer = styled.aside`
  position: fixed;
  z-index: ${Layers.DRAWER};
  top: 0;
  left: 0;
  padding-top: ${HEADER_HEIGHT + TOPBAR_HEIGHT}px;

  background: var(--background-body);
  height: 100%;
  transition: transform ${TRANSITION_SPEED}ms ease-out 0ms;
  max-width: 250px;
  width: calc(100% - 100px);
`

export const Backdrop = styled.div`
  position: fixed;
  z-index: ${Layers.BACKDROP};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
`

const itemCss = css`
  font-weight: 700;
  font-size: 0.85rem;
  display: block;
  width: 100%;
  color: var(--color-text);
  cursor: pointer;
  padding: 0.5rem 1rem;
`

export const ItemLink = styled(RouterLink)`
  ${itemCss}
`

export const ItemSubLink = styled(RouterLink)`
  ${itemCss}
  padding: 0.5rem 2rem;
`

export const MenuLinkExternal = styled.a`
  ${itemCss}
`

export const ActionButton = styled(ButtonLink)`
  margin: 0.5rem 1rem;
  font-size: 0.85rem;
  line-height: 1.25;
`

export const ItemButton = styled(PlainButton)<{ $active: boolean }>`
  ${itemCss}
  text-align: left;

  ${({ $active }) =>
    $active &&
    `
    color: var(--color-primary);
  `}
`

export const Separator = styled.div`
  height: 0px;
  margin: 0.5rem 0;
  border-top: 1px solid var(--border-secondary);
`
