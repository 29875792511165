import Markdown from 'markdown-to-jsx'
import { useCallback, useContext, useEffect } from 'react'
import { Toast as BootstrapToast } from 'react-bootstrap'
import styled from 'styled-components'

import { ToastStateContext, useToast } from '../../context/toastStore'
import { delay } from '../../helpers/std'
import { IToast } from '../../interfaces/toast'

const ToastsContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 20px;
  z-index: 1100;
`

export function Toasts() {
  const { toastState } = useContext(ToastStateContext)
  const toastActions = useToast()

  if (toastState.length === 0) {
    return null
  }

  return (
    <ToastsContainer>
      {toastState.map((toast) => (
        <Toast key={toast.toastId} {...toast} remove={toastActions.remove} />
      ))}
    </ToastsContainer>
  )
}

type IProps = {
  remove: (toastId: string) => void
} & IToast

const TOAST_TTL = 5000

function Toast(props: IProps) {
  const { remove, toastId } = props

  const onClose = useCallback(() => {
    if (toastId) {
      remove(toastId)
    }
  }, [remove, toastId])

  useEffect(() => {
    const { promise, cancel } = delay(TOAST_TTL)
    promise.then(() => onClose())

    return () => cancel()
  }, [onClose])

  return (
    <BootstrapToast onClose={() => onClose()}>
      <BootstrapToast.Header>
        <strong className="mr-auto">{props.title}</strong>
      </BootstrapToast.Header>
      <BootstrapToast.Body
        className={props.severity ? `bg-${props.severity.toString().toLowerCase()}` : ''}
        style={{ wordBreak: 'break-word' }}
      >
        <Markdown>{(props.body || '').toString()}</Markdown>
      </BootstrapToast.Body>
    </BootstrapToast>
  )
}
