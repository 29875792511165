import * as Sentry from '@sentry/react' // TODO: consider using store for all needed contexts
import { useQuery } from '@tanstack/react-query'

import { useApiClient } from '../api/react'
import { config } from '../config'

export function useInfoService() {
  const api = useApiClient()
  const { data } = useQuery(['/info'], () => api.general.getInfo(), {
    onSuccess: (data) => {
      Sentry.setContext('version', {
        FE: `${config.appVersion}`,
        BE: `${data.version.string}`,
        DB: `${data.database_version?.script_count}`
      })
    }
  })

  return data
}
