import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { IFileDetailResponse } from '../api/types/file'
import { getLongFileName } from '../helpers/files'
import { formatSize } from '../helpers/formatters'
import { Label, Value } from './common/Parameters'
import { EditableFileName } from './printers/storage/FileManager/EditableFileName'

const NoWrapValue = styled(Value)`
  .input-group {
    flex-wrap: nowrap;
  }
`

type Props = {
  file: IFileDetailResponse
  teamId: number
}

export function FileDataParameters(props: Props) {
  const { file, teamId } = props
  const navigate = useNavigate()
  const { t } = useTranslation()
  const lfn = getLongFileName(file)
  const editable = !file.path

  return (
    <>
      {lfn && (
        <>
          <Label>{t('file-data.name')}</Label>
          <NoWrapValue>
            {editable ? (
              <EditableFileName value={lfn} teamId={teamId} hash={file.hash} onSuccess={() => navigate(-1)} />
            ) : (
              lfn
            )}
          </NoWrapValue>
        </>
      )}
      {(file.display_path || file.path) && (
        <>
          <Label>{t('file-data.path')}</Label>
          <Value>{file.display_path || file.path}</Value>
        </>
      )}
      {file.size && (
        <>
          <Label>{t('file-data.size')}</Label>
          <Value>{formatSize(file.size)}</Value>
        </>
      )}
    </>
  )
}
