import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { useApiClient } from '../../api/react'
import { getUserName } from '../../api/types/user'
import { useAuthActions } from '../../context/authStore'
import { useRedirectUrl } from '../../hooks/useRedirectUrl'
import useServiceResponseHandler from '../../services/useServiceResponseHandler'
import { Button } from '../common/Button'
import { PaceProgress } from '../common/PaceProgress'
import { MutationStatus } from '../mutationStatus'

const Div = styled.div`
  display: flex;
`

const Eye = styled.span`
  display: flex;
  align-items: center;
  color: #495057;
  padding: 0.375rem 0.75rem;
  border: 1px solid var(--border-secondary);
  border-radius: 0 0.25rem 0.25rem 0;
  border-left: 0;
  cursor: pointer;
`

const PasswordInput = styled(Form.Control)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  &::-ms-clear,
  &::-ms-reveal {
    display: none;
  }
`

export function LoginForm() {
  const { t } = useTranslation()
  const api = useApiClient()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [passVisible, setPassVisible] = useState(false)
  const { displaySuccess } = useServiceResponseHandler()
  const navigate = useNavigate()
  const auth = useAuthActions()
  const { redirectUrl } = useRedirectUrl()

  const mutation = useMutation(() => api.general.login(username, password), {
    onSuccess: (response) => {
      auth.login(response.user)
      displaySuccess(t('service.login.title'), t('service.login.body', { name: getUserName(response.user) }))

      const to = redirectUrl || '/printers'
      navigate(to, { replace: true })
    }
  })

  const usernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.currentTarget.value)
  }

  const passwordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.currentTarget.value)
  }

  const handleLoginSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    mutation.mutate()
  }

  return (
    <>
      {mutation.isLoading && <PaceProgress />}

      <form onSubmit={handleLoginSubmit} className="form-signin">
        <h1 className="h3 text-center mb-4 font-weight-normal">{t('login.title')}</h1>

        <Form.Group controlId="username" bsPrefix="form-label-group">
          <Form.Control
            value={username}
            onChange={usernameChange}
            type="text"
            placeholder={t('login.username-placeholder')}
            autoComplete="username"
            required
          />
          <Form.Label>{t('login.username-label')}</Form.Label>
        </Form.Group>

        <Form.Group controlId="password" className="form-label-group" style={{ display: 'flex' }}>
          <PasswordInput
            value={password}
            onChange={passwordChange}
            type={passVisible ? 'text' : 'password'}
            placeholder={t('login.password-placeholder')}
            autoComplete="current-password"
            required
          />
          <Div>
            <Eye onClick={() => setPassVisible(!passVisible)}>
              <FontAwesomeIcon icon={passVisible ? faEyeSlash : faEye} style={{ width: '1.5rem' }} />
            </Eye>
          </Div>
          <Form.Label>{t('login.password-label')}</Form.Label>
        </Form.Group>

        <Form.Group controlId="remember" className="form-label-group">
          <Form.Check type="checkbox" label={t('login.remember-me')} />
        </Form.Group>
        <Button type="submit" block disabled={mutation.isLoading}>
          {t('login.submit-label')}
        </Button>

        <div className="mt-2">
          <MutationStatus mutation={mutation} />
        </div>
      </form>
    </>
  )
}
