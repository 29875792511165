import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import { SvgIcon } from './SvgIcon'

const IconContainer = styled.div<{ $readonly?: boolean; $isLoading?: boolean }>`
  cursor: ${(props) => (props.$isLoading ? 'wait' : props.$readonly ? 'inherit' : 'pointer')};
  opacity: ${(props) => (props.$readonly ? '0.5' : '1')};
  user-select: none;
`

type Props = {
  checked: boolean
  readonly?: boolean
  isLoading?: boolean
  disableToggle?: boolean
  onChange?: (checked: boolean) => void
  onClick?: () => void
  titleChecked?: string
  titleUnchecked?: string
}

const ICON_SIZE = 14

export function Checkbox(props: Props) {
  const { t } = useTranslation()
  const [isChecked, setIsChecked] = useState(props.checked)

  useEffect(() => {
    setIsChecked(props.checked)
  }, [props.checked])

  const handleClick = () => {
    if (!props.readonly && !props.isLoading) {
      if (props.onClick) props.onClick()
      if (!props.disableToggle) toggle()
    }
  }

  const toggle = () => {
    const value = !isChecked
    setIsChecked(value)
    if (props.onChange) {
      props.onChange(value)
    }
  }

  const theme = useTheme()
  const icon = isChecked ? 'okIcon' : 'nokIcon'
  const filling = isChecked ? theme.colors.icons.primary : theme.colors.icons.secondary

  const titleChecked = props.titleChecked || t('team.member-rights.checked')
  const titleUnchecked = props.titleUnchecked || t('team.member-rights.unchecked')

  return (
    <IconContainer $readonly={props.readonly} $isLoading={props.isLoading}>
      <SvgIcon
        icon={icon}
        fill={filling}
        size={ICON_SIZE}
        title={isChecked ? titleChecked : titleUnchecked}
        onClick={handleClick}
      />
    </IconContainer>
  )
}
