import { find } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import { IFileDetailResponse, IFileType, isTreeFile, ITreeFile } from '../../../../api/types/file'
import { IGcodeMetadata } from '../../../../api/types/metadata'
import { usePrinter } from '../../../../context/printerContext'
import { getLongFileName, getStorageFromPath } from '../../../../helpers/files'
import { usePrinterStorage } from '../../../../hooks/storageLocations/usePrinterStorage'
import { useStorageLocations } from '../../../../hooks/storageLocations/useStorageLocations'
import { LoadingButtonOutlined } from '../../../common/LoadingButtonOutlined'
import { DeleteCloudFileAction } from '../../actions/DeleteCloudFileAction'
import { DeleteTreeFileAction } from '../../actions/DeleteTreeFileAction'
import { DownloadFileAction } from '../../actions/DownloadFileAction'
import { EnqueueAction } from '../../actions/EnqueueAction'
import { StartCopyAndPrintAction } from '../../actions/StartCopyAndPrintAction'
import { PrintVariant, StartPrintAction } from '../../actions/StartPrintAction'

type Props = {
  file: IFileDetailResponse
  basicOptions?: boolean
  onDelete?: () => void
}

export function PrinterFileActions({ file, basicOptions, onDelete }: Props) {
  const { printer } = usePrinter()
  const { t } = useTranslation()
  const printerStorage = usePrinterStorage()
  const { teamId } = useParams<{ teamId: string }>()
  const { locations } = useStorageLocations(printer)
  const fileName = getLongFileName(file)
  const metadata = file?.meta as IGcodeMetadata

  if (file.type !== IFileType.PRINT_FILE) {
    // typecheck - should not happen
    return null
  }

  const renderStartPrint = () => {
    if (!isTreeFile(file) && !!printerStorage) {
      return (
        <StartCopyAndPrintAction
          teamId={file.team_id}
          hash={file.hash}
          name={file.name} // sfn
          printerStorage={printerStorage}
          uploaded={file.uploaded}
          printer={printer}
          filamentType={metadata?.filament_type}
          withConfirm
        />
      )
    }

    if (isTreeFile(file)) {
      return (
        <StartPrintAction
          filamentType={metadata?.filament_type}
          printer={printer}
          variant={PrintVariant.PRINT}
          path={file.path}
          withConfirm
        />
      )
    }

    return (
      <LoadingButtonOutlined disabled disabledTooltip={t('printer.no-storage', 'Printer has no storage loaded')}>
        {t('printer.actions.start-print.label')}
      </LoadingButtonOutlined>
    )
  }

  const isReadOnly = (file: ITreeFile) => {
    const storageName = getStorageFromPath(file.path)
    const storage = find(locations, ['name', storageName])
    return file.read_only || storage?.read_only
  }

  return (
    <>
      {renderStartPrint()}

      <EnqueueAction
        path={file.path}
        uploaded={file.uploaded}
        hash={file.hash}
        name={file.name} // sfn
        teamId={file.team_id}
      />

      {!basicOptions && (
        <>
          <DownloadFileAction
            teamId={file.team_id}
            hash={file.hash}
            name={fileName}
            uploaded={file.uploaded}
            size={file.size}
          />

          {file.path ? (
            <DeleteTreeFileAction
              file={file}
              path={file.path}
              filename={fileName}
              readOnly={isReadOnly(file as ITreeFile)}
              onSuccess={onDelete}
            />
          ) : (
            <DeleteCloudFileAction
              file={file}
              label={t('button.delete.from.connect')}
              hash={file.hash}
              teamId={file.team_id}
              name={fileName}
              printerTeamId={Number(teamId)}
              onSuccess={onDelete}
            />
          )}
        </>
      )}
    </>
  )
}
