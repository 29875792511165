import { Material } from './filament'
import { IPager, IPagerQuery } from './pager'
import { IPrinter } from './printer'
import { IConnectState } from './state'
import { IVector3 } from './vector'

// /app/printers/groups
export type IGroupOverview = {
  created: number
  description?: string
  farm: boolean
  id: number
  name: string
  printer_count: number
  size?: IGroupSize
  team_id: number
}

// /app/printers/groups/{group_id}
export type IGroupDetail = IGroupOverview & {
  full_positions?: IVector3[]
}

type IGroupInfo = {
  enabled: boolean
  position?: IVector3
}

export type IGroupPrinter = IPrinter & { group_info?: IGroupInfo }

// /app/printers/groups/{group_id}/printers
export type IGroupPrintersResponse = {
  printers: IGroupPrinter[]
  pager: IPager
  group: IGroupOverview
}

export type IGroupPrintersRequest = IPagerQuery & {
  state_include?: IConnectState[]
}

// Used in a printer's detail
export type IGroupSimpleView = {
  id: number
  name: string
  description?: string
  farm: boolean
  size?: IGroupSize
}

export enum IGroupPattern {
  GRID = 'grid',
  SHIFTED = 'shifted'
}

export type IGroupSize = IVector3 & {
  pattern: IGroupPattern
}

export type IGroupsResponse = {
  groups: IGroupOverview[]
  pager?: IPager
}

export type IPrinterInGroupAttributes = {
  position?: IVector3
}

// PUT /app/printers/groups/{groupId}
export type IEditGroupRequestBody = {
  team_id: number
  name: string
  description?: string
  farm?: boolean
  size?: IVector3
}

/* ------------------------------ GROUP PRINTERS - STATISTICS ------------------------------ */

export type IGroupDateRangeQuery = {
  from: number
  to: number
}

export type GroupJobsEndStates = 'FIN_OK' | 'FIN_STOPPED' | 'FIN_ERROR' | 'UNKNOWN'

export type OverallPrinting = 'printing' | 'not_printing'

export type IGroupMaterialConsumpRes = {
  printers: {
    name: string
    uuid: string
    data: {
      name: Material
      value: number
    }[]
  }[]
} & IGroupDateRangeQuery

export type IGroupPlannedTasksFrequencyRes = {
  xAxis: number[]
  series: {
    uuid: string
    name: string
    data: [number, number][]
  }[]
} & IGroupDateRangeQuery

export type IGroupOverallPrintTimeRes = {
  printers: {
    name: string
    uuid: string
    data: {
      name: OverallPrinting
      value: number
    }[]
  }[]
} & IGroupDateRangeQuery

export type GroupJobsEndStateSerie = {
  name: GroupJobsEndStates
  data: number[]
}

export type IGroupJobsEndStatesRes = {
  xAxis: string[]
  time_shift: string
  printers: {
    name: string
    uuid: string
    series: GroupJobsEndStateSerie[]
  }[]
} & IGroupDateRangeQuery
