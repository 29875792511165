import { useQuery } from '@tanstack/react-query'
import ms from 'ms'

import { useApiClient } from '../api/react'

export function getBoardName(printerType: string) {
  if (printerType.match(/^1.(2|3).\d$/)) {
    return 'Einsy RAMBO'
  }

  if (printerType.match(/^1.4.\d$/)) {
    return 'xBuddy'
  }

  if (printerType.match(/^2.1.\d$/)) {
    return 'Buddy'
  }

  if (printerType.match(/^3.1.\d$/)) {
    return 'XLBuddy'
  }

  return ''
}

export function usePrinterTypes() {
  const api = useApiClient()
  const { data } = useQuery(['/printer-types'], () => api.app.printers.getTypes(), { cacheTime: ms('1day') })
  return data?.printer_types || []
}

export function usePrinterType(printerTypeId?: string) {
  const printer_types = usePrinterTypes()
  return printer_types.find((printerType) => printerType.id === printerTypeId)
}

export function useFdmPrinterType() {
  const printer_types = usePrinterTypes()
  return printer_types.filter((printerType) => printerType.type !== 5)
}

export function useSlaPrinterTypes() {
  const printer_types = usePrinterTypes()
  return printer_types.filter((printerType) => printerType.type === 5)
}

export function useIsSla(printerType: string) {
  const slaItems = useSlaPrinterTypes()
  const isSla = !!slaItems.find((item) => {
    return item.id === printerType
  })
  return isSla
}

export function useIsFdm(printerType: string) {
  const fdmItems = useFdmPrinterType()
  const isFdm = !!fdmItems.find((item) => {
    return item.id === printerType
  })
  return isFdm
}
