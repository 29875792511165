import { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { PrinterUuid } from '../../../api/types/printer'
import { ButtonLink } from '../../common/ButtonLink'
import { SvgIcon } from '../../common/SvgIcon'

const Headline = styled.p`
  font-weight: 700;
  font-size: 1.5em;
  color: var(--color-navigation);
`

const P = styled.p`
  line-height: 2.5em;
`

type RouteInfo = {
  printerType: string
  printerUuid: PrinterUuid
}

type Props = {
  stepIndex: number
  setStepIndex: (stepIndex: number) => void
}

export function DonePage(props: Props) {
  const params = useParams<RouteInfo>()
  const { t } = useTranslation()

  useEffect(() => {
    props.setStepIndex(props.stepIndex)
  }, [props])

  return (
    <>
      <p className="text-center">
        <SvgIcon icon="okIcon" size="80" />
      </p>
      <Headline className="text-center">{t('printer.add.added')}</Headline>
      <P className="text-center">
        <Trans
          i18nKey="printer.add.navigate-to-printer"
          components={{
            detailLink: <Link to={`/printer/${params.printerUuid}`} />
          }}
        />
        <br />
        {t('printer.add.or')}
        <br />
        <ButtonLink to="..">
          <SvgIcon icon="plusIcon" size={18} />
          {t('printer.add-another')}
        </ButtonLink>
      </P>
    </>
  )
}
