import { Link as RouterLink } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { disabledFilter } from '../helpers/styled'
import { PlainButton } from './PlainButton'

const getItemStyle = (disabled?: boolean) => css`
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  ${disabledFilter(!!disabled)};
  cursor: ${disabled ? 'not-allowed' : 'pointer'};
  * {
    pointer-events: ${disabled ? 'none' : 'auto'};
  }
  color: var(--color-text);
  font-weight: 400;

  &:hover {
    color: var(--color-text);
    background: var(--background-primary);
  }

  svg {
    height: 16px;
    width: 16px;
    margin-right: 0.5rem;
  }
`

export const Menu = styled.div`
  background-clip: padding-box;
  background-color: var(--dropdown-background);
  border: 1px solid var(--dropdown-border);
  border-radius: 0.25rem;
  color: var(--color-secondary);
  box-shadow: 0 0 0.5rem var(--neutral-200);
  font-size: 1rem;
  left: 0;
  list-style: none;
  margin: 0.125rem -1px 0;
  min-width: 10rem;
  padding: 0.5rem 0;
  text-align: left;
  z-index: 1000;
`

export const Item = styled.div<{ disabled?: boolean }>`
  ${(props) => getItemStyle(props.disabled)}
`

export const Link = styled(RouterLink)<{ disabled?: boolean }>`
  ${(props) => getItemStyle(props.disabled)}
`

export const Button = styled(PlainButton)<{ disabled?: boolean }>`
  ${(props) => getItemStyle(props.disabled)}
  width: 100%;
  justify-content: left;
`
