import {
  IChannelNotification,
  INotification,
  INotificationChannelsSettingsQuery,
  INotificationSettingsQuery,
  INotificationsResponse,
  IUnseenNotifications
} from '../../types/notifications'
import { NoContent204, RequestFactory } from '../../types/sdk'

export function createModule(rf: RequestFactory) {
  const monthAgo = Math.floor((Date.now() - 2629800000) / 1000)

  return {
    getNotifications: ({ pageParam = 0, limit = 20, from = monthAgo }) =>
      rf.get<INotificationsResponse>(`/app/notifications?limit=${limit}&offset=${pageParam}&from=${from}`),

    getUnseen: () => rf.get<IUnseenNotifications>('/app/notifications/unseen'),

    setRead: (id: number) => rf.patch<INotification>(`/app/notifications/${id}`, { read: true }),

    setAllRead: () => rf.post<NoContent204>('/app/notifications'),

    getChannelsSettings: () => rf.get<INotificationChannelsSettingsQuery>('/app/media'),

    editTeamSettings: (teamId: number, settings: IChannelNotification) =>
      rf.put<NoContent204>(`/app/teams/${teamId}/severity`, settings),

    addMediumEndpoint: (settings: INotificationSettingsQuery) => rf.post<NoContent204>(`/app/media`, settings),

    deleteUserChannelSettings: (id: number) => rf.delete<NoContent204>(`/app/media/${id}`)
  }
}
