import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { IGroupOverview } from '../../../api/types/groups'
import { useGroupName } from '../../../hooks/useGroupName'
import { useTeamName } from '../../../hooks/useTeamName'
import { useTeams, useWritableTeams } from '../../../hooks/useTeams'
import { ButtonGroup } from '../../common/ButtonGroup'
import { ButtonLink } from '../../common/ButtonLink'
import { Td } from '../../helpers/responsiveTable'
import { EditGroupButton } from './EditGroupButton'
import { getDimensionMessage, getPatternMessage } from './group'
import { RemoveGroup } from './RemoveGroup'

const GroupTableRow = styled.tr`
  border-bottom: 10px solid var(--background-body);
  color: var(--color-text);

  &:hover {
    background-color: var(--table-row-background-hover);
  }

  td.bold {
    font-weight: 700;
    padding-left: 0;
  }
`

type Props = {
  group: IGroupOverview
  update: () => void
}

export function GroupsRow(props: Props) {
  const { group } = props
  const { t } = useTranslation()
  const teams = useTeams()
  const [isEditable, setIsEditable] = useState(false)
  const [name, setName] = useState(group.name)
  const [teamId, setTeamId] = useState(group.team_id)
  const [description, setDescription] = useState(group.description)
  const { writableTeams } = useWritableTeams()
  const { getTeamName } = useTeamName()
  const { getGroupName } = useGroupName()

  const changeEditable = (e: boolean) => setIsEditable(e)

  const nameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.currentTarget.value)
  }

  const descriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(e.currentTarget.value)
  }

  const teamChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTeamId(Number(e.currentTarget.value))
  }

  const discardChanges = () => {
    setName(group.name)
    setDescription(group.description)
    setTeamId(group.team_id)
  }

  return (
    <GroupTableRow className={isEditable ? 'table-active' : ''}>
      <Td>
        {!isEditable && <b>{name || getGroupName(group)}</b>}
        {isEditable && <Form.Control value={name} onChange={nameChange} type="text" required />}
      </Td>
      <Td>
        {!isEditable && description}
        {isEditable && <Form.Control value={description} onChange={descriptionChange} type="text" />}
      </Td>
      <Td>
        {!isEditable ? (
          getTeamName(teams.find((team) => group.team_id === team.id))
        ) : (
          <Form.Control as="select" onChange={teamChange} value={teamId.toString()}>
            <option value={0} hidden />
            {writableTeams.map((team) => (
              <option key={team.id} value={team.id}>
                {getTeamName(team)}
              </option>
            ))}
          </Form.Control>
        )}
      </Td>
      <Td>{getPatternMessage(t, group.size)}</Td>
      <Td>{getDimensionMessage(t, group.printer_count || 0, group.size)}</Td>
      <Td>
        <ButtonGroup>
          <EditGroupButton
            id={group.id}
            name={name}
            description={description || ''}
            teamId={teamId}
            update={props.update}
            isEditable={isEditable}
            changeEditable={changeEditable}
            discardChanges={discardChanges}
          />
          <RemoveGroup
            id={group.id}
            name={getGroupName(group)}
            disabled={group.printer_count !== 0}
            update={props.update}
          />
          <ButtonLink to={`/groups/${group.id}`} size="sm">
            {t('printers.manage')}
          </ButtonLink>
        </ButtonGroup>
      </Td>
    </GroupTableRow>
  )
}
