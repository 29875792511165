import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { useApiClient } from '../../api/react'
import { GcodeArgs, MultiSelectOption } from '../../api/types/gcode'
import { useErrorHandler } from '../../hooks/errors/useErrorHandler'
import { ErrorModal } from '../common/ErrorModal'
import { SvgIcon } from '../common/SvgIcon'

type Props = {
  id: number
  isEditable: boolean
  changeEditable: (editable: boolean) => void
  onEdit: () => void
  description: string
  template: string
  args: GcodeArgs[]
  teamId: number
  printerTypes: MultiSelectOption[]
  name: string
}

export function EditGcodeTemplateForm(props: Props) {
  const { t } = useTranslation()
  const errorHandler = useErrorHandler()

  const changeEditableMode = () => props.changeEditable(!props.isEditable)
  const api = useApiClient()
  const [modal, setModal] = useState({ title: '', body: '', displayed: false })

  const { mutate: saveChanges } = useMutation(
    () =>
      api.app.gCodes.modifyGCodeTemplate(props.id, {
        id: props.id,
        description: props.description,
        template: props.template,
        args: props.args,
        team_id: props.teamId,
        printer_types: props.printerTypes.map((type) => type.value),
        name: props.name
      }),
    {
      onSuccess: () => {
        changeEditableMode()
        props.onEdit()
      },
      onError: (error: any) => {
        const { title, message } = errorHandler(error.response, error)
        setModal({ title, body: message, displayed: true })
      }
    }
  )

  return (
    <>
      <Button variant="outline-dark" size="sm" onClick={changeEditableMode}>
        <SvgIcon icon={props.isEditable ? 'cancelIcon' : 'editIcon'} size={16} />
        {props.isEditable ? t('button.cancel') : t('button.edit')}
      </Button>
      {props.isEditable && (
        <Button variant="outline-dark" size="sm" onClick={() => saveChanges()}>
          <SvgIcon icon="saveIcon" size="16" />
          {t('button.save')}
        </Button>
      )}
      <ErrorModal
        show={modal.displayed}
        title={modal.title}
        body={modal.body}
        onHide={() => setModal({ ...modal, displayed: false })}
      />
    </>
  )
}
