import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IFileDetailResponse } from '../../../api/types/file'
import { useCanControl } from '../../../context/permissionsStore'
import { usePrinter } from '../../../context/printerContext'
import { StorageLocationSlug } from '../../../hooks/storageLocations/useStorageLocationTabs'
import { useLowMemory } from '../../../hooks/useLowMemory'
import { AdaptiveButton } from '../../common/AdaptiveButton/AdaptiveButton'
import { useFileDelete } from '../storage/FileManager/useFileDelete'
import { DeleteFileWithPreviewModal } from './DeleteFileWithPreviewModal'

type Props = {
  file: IFileDetailResponse
  path: string
  filename: string
  onSuccess?: (storageType: StorageLocationSlug) => void
  readOnly?: boolean
}

export function DeleteTreeFileAction(props: Props) {
  const { file, path, filename, onSuccess, readOnly } = props
  const [showModal, setShowModal] = useState(false)
  const { printer } = usePrinter()
  const { t } = useTranslation()
  const { mutate, isLoading } = useFileDelete(printer.uuid, path, filename, onSuccess)
  const canControl = useCanControl(printer.team_id)
  const isLowMemory = useLowMemory(printer.connect_state, printer.uuid)
  const isDisabled = !!readOnly || !canControl || isLowMemory

  let disabledTooltip = ''
  if (readOnly) {
    disabledTooltip = t('file.readonly')
  } else if (!canControl) {
    disabledTooltip = t('printer.tooltip.rights')
  } else if (isLowMemory) {
    disabledTooltip = t('printer.file.delete.disabled-during-printing')
  }

  return (
    <>
      <AdaptiveButton
        icon="deleteIcon"
        label={t('button.delete.from-link')}
        className="delete-file"
        isLoading={isLoading}
        isAvailable
        isDisabled={isDisabled}
        disabledTooltip={disabledTooltip}
        trigger={() => setShowModal(true)}
      />

      {showModal && <DeleteFileWithPreviewModal file={file} onClose={() => setShowModal(false)} onConfirm={mutate} />}
    </>
  )
}
