import { getUserDefaultPreferences, intersectionWithExtras } from '../components/preferences/helpers'
import { ConnectStateEnum, PrinterFamily } from '../components/preferences/types'
import { useAuthState } from '../context/authStore'
import { PrintersPreviewEnum } from '../interfaces/view'

export function useLoggedUser() {
  const authState = useAuthState()
  return authState.user
}

export function useIsAdmin() {
  const user = useLoggedUser()
  const isAdmin = !!user.is_admin

  return isAdmin
}

export function useLoggedUserPreferences(sectionName?: string) {
  const user = useLoggedUser()
  const defaultPreferences: any = getUserDefaultPreferences()

  if (sectionName) {
    const userSectionPreferences = user.preferences?.[sectionName]
    const defaultSectionPreferences = defaultPreferences[sectionName]

    return userSectionPreferences || defaultSectionPreferences
  }
  return user.preferences || defaultPreferences
}

export function useSavedUserPreferences(sectionName?: string) {
  const user = useLoggedUser()

  if (sectionName) {
    return user.preferences?.[sectionName]
  }
  return user.preferences
}

export function useLoggedUserSettings(
  sectionName: 'status_bar' | 'printers' | 'files' | 'jobs',
  family: PrinterFamily,
  view?: PrintersPreviewEnum,
  state?: ConnectStateEnum
) {
  const user = useLoggedUser()
  const defaultPreferences = getUserDefaultPreferences()

  if (sectionName === 'printers' && view && state) {
    return intersectionWithExtras(
      user.preferences?.[sectionName].params[family][view][state],
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      defaultPreferences[sectionName].params[family][view][state]
    )
  }

  if (sectionName === 'files' && state) {
    return intersectionWithExtras(
      user.preferences?.[sectionName].params[family][state],
      defaultPreferences[sectionName].params[family][state]
    )
  }

  return intersectionWithExtras(
    user.preferences?.[sectionName].params[family],
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    defaultPreferences[sectionName].params[family]
  )
}
