import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { useApiClient } from '../../../../api/react'
import { PrinterUuid } from '../../../../api/types/printer'
import { useToast } from '../../../../context/toastStore'
import { isCommandTimeout } from '../../../../helpers/responses'
import { useErrorHandler } from '../../../../hooks/errors/useErrorHandler'
import { StorageLocationSlug } from '../../../../hooks/storageLocations/useStorageLocationTabs'
import useServiceResponseHandler from '../../../../services/useServiceResponseHandler'
import { getFolderQueryKey } from './useFolderFiles'
import { getFolderFromFilepath } from './utils'

export function useFileDelete(
  printerUuid: PrinterUuid,
  path: string,
  filename: string,
  onSuccess?: (storageType: StorageLocationSlug) => void
) {
  const api = useApiClient()
  const toast = useToast()
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { displayHttpError } = useServiceResponseHandler()
  const errorHandler = useErrorHandler()

  return useMutation(
    () =>
      api.app.commandQueue.addCommandSync(printerUuid, {
        command: 'DELETE_FILE',
        kwargs: { path }
      }),
    {
      onSuccess: (response) => {
        if (isCommandTimeout(response)) {
          return displayHttpError(response as any)
        }
        // Refetch files for current folder
        const folderPath = getFolderFromFilepath(path)
        const queryKey = getFolderQueryKey(printerUuid, folderPath)
        queryClient.refetchQueries(queryKey)

        // TODO remove after Buddy bootloader can handle multiple firmware files on USB drive
        queryClient.invalidateQueries(['PRINTER_FIRMWARE_CHECK_FILES', printerUuid])

        toast.add(t('printer.storage.delete-file.title'), t('printer.storage.delete-file.message', { filename }))

        onSuccess?.(StorageLocationSlug.PRUSA_LINK)
      },
      onError: (error: Error) => {
        errorHandler(error)
      }
    }
  )
}
