import { capitalize } from 'lodash-es'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { device } from '../../../interfaces/device'
import { Button } from '../../common/Button'
import { SvgIcon } from '../../common/SvgIcon'

const ModalContent = styled.div`
  display: grid;
  gap: 2rem;
  align-items: top;

  @media ${device.sm} {
    grid-template-columns: 1fr 1fr;
  }
`

const FullWidthButton = styled(Button)`
  width: 100%;
  margin-bottom: 10px;
`

const Column = styled.div``

type Props = {
  handleUpload: (flashAfterTransfer: boolean) => void
  close: () => void
}

export function ConfirmFirmwareFlashModal(props: Props) {
  const { t } = useTranslation()
  const { handleUpload, close } = props

  return (
    <Modal show onHide={close} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t('printer.firmware.flash-after-upload.title', 'Flash after transfer?')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t('printer.firmware.flash-after-upload.body', 'Do you want to flash firmware after upload to printer?')}</p>
        <ModalContent>
          <Column>
            <FullWidthButton type="submit" onClick={() => handleUpload(true)}>
              <SvgIcon icon="flashColorIcon" />
              {capitalize(t('yes'))}
            </FullWidthButton>
          </Column>
          <Column>
            <FullWidthButton type="submit" onClick={() => handleUpload(false)}>
              {capitalize(t('no'))}
            </FullWidthButton>
          </Column>
        </ModalContent>
      </Modal.Body>
    </Modal>
  )
}
