import { useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { AfsJobPriority } from '../../../../api/types/afs'
import { Material } from '../../../../api/types/filament'
import { IFileDetailResponse, IPrintFile } from '../../../../api/types/file'
import { IGcodeMetadata } from '../../../../api/types/metadata'
import { AdaptiveButton } from '../../../common/AdaptiveButton/AdaptiveButton'
import { RequiredMark } from '../../../users/UserForm'
import { NumberIncrementer } from '../../control/NumberIncrementer'
import { PrintFileRow } from '../../storage/FileManager/PrintFileRow'
import { CreatePrintJobAction } from './CreatePrintJobAction'

const Row = styled.div`
  align-items: baseline;
`

const Fieldset = styled.fieldset`
  display: block;
  flex: none;
  margin-left: 2px;
  margin-right: 2px;
  padding: 0.35em 0.75em 0.625em 0.75em;
  border: 1px groove var(--border-secondary);
  cursor: default;

  legend {
    width: unset;
    font-size: 1rem;
    white-space: nowrap;
    margin-bottom: 0.2rem;
  }

  .form-control {
    height: calc(1.5em + 0.5rem);
  }

  .form-group {
    margin-bottom: 0;
  }
`

function useLabels() {
  const { t } = useTranslation()

  return {
    [AfsJobPriority.LOWEST]: t('afs.job.priority.lowest', 'LOWEST'),
    [AfsJobPriority.LOW]: t('afs.job.priority.low', 'LOW'),
    [AfsJobPriority.MEDIUM]: t('afs.job.priority.medium', 'MEDIUM'),
    [AfsJobPriority.HIGH]: t('afs.job.priority.high', 'HIGH'),
    [AfsJobPriority.HIGHEST]: t('afs.job.priority.highest', 'HIGHEST')
  }
}

type ModalProps = {
  file: IPrintFile
  onClose: () => void
}

function CreateFarmPrintJobModal(props: ModalProps) {
  const { file, onClose } = props
  const { t } = useTranslation()
  const metadata = file.meta as IGcodeMetadata
  const [material /* , setMaterial */] = useState<Material>(metadata.filament_type || Material.PLA) // TODO required arg
  const [color, setColor] = useState('')
  const [priority, setPriority] = useState(AfsJobPriority.MEDIUM)
  const [nrOfCopies, setNrOfCopies] = useState<number>(1)
  const labels = useLabels()

  return (
    <Modal show onHide={onClose} centered size="xl">
      <Modal.Header closeButton>
        <Modal.Title>{t('farm.job.create.title', 'Create new job')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ pointerEvents: 'none' }}>
          <PrintFileRow file={file as IPrintFile} printerTeamId={-1} />
        </div>
        <Row className="row">
          <div className="col-lg-3 col-sm-12">
            <Fieldset role="listbox" aria-labelledby="fulnumber-of-copiesltext">
              <legend id="number-of-copies">
                {t('farm.job.number-of-copies', 'Number of copies')}
                <RequiredMark>*</RequiredMark>
              </legend>
              <NumberIncrementer value={nrOfCopies} min={1} max={20} onChange={(copies) => setNrOfCopies(copies)} />
            </Fieldset>
          </div>
          <div className="col-lg-3 col-sm-12">
            <Fieldset role="listbox" aria-labelledby="priority">
              <legend id="priority">
                {t('farm.job.priority', 'Priority')}
                <RequiredMark>*</RequiredMark>
              </legend>
              <Form.Control
                as="select"
                onChange={(e) => setPriority(e.currentTarget.value as AfsJobPriority)}
                value={priority}
                required
              >
                {Object.values(AfsJobPriority).map((p) => (
                  <option key={p} value={p}>
                    {labels[p]}
                  </option>
                ))}
              </Form.Control>
            </Fieldset>
          </div>
          <div className="col-lg-3 col-sm-12">
            <Fieldset role="listbox" aria-labelledby="color">
              <legend id="color">{t('farm.job.color', 'Color')}</legend>
              <Form.Control type="text" name="color" onChange={(e) => setColor(e.currentTarget.value)} />
            </Fieldset>
          </div>
          <div className="col-lg-3 col-sm-12">
            <Fieldset role="listbox" aria-labelledby="material">
              <legend id="material">
                {t('farm.job.material', 'Material')}
                <RequiredMark>*</RequiredMark>
              </legend>
              <Form.Control
                as="select"
                // onChange={(e) => setMaterial(e.currentTarget.value)}
                value={material}
                required
                disabled
              >
                <option value="" />
                {Object.keys(Material).map((m) => (
                  <option key={m} value={m}>
                    {m}
                  </option>
                ))}
              </Form.Control>
            </Fieldset>
          </div>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <CreatePrintJobAction
          file={file}
          label={t('farm.actions.create-job.label', 'Create job')}
          color={color}
          material={material}
          copies={nrOfCopies}
          priority={priority}
          disabled={!material}
          onSuccess={onClose}
        />
      </Modal.Footer>
    </Modal>
  )
}

type Props = {
  file: IFileDetailResponse
  label: string
  toQueue?: boolean
}

export function CreateFarmPrintAction({ file, toQueue, label }: Props) {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <AdaptiveButton
        icon={toQueue ? 'queueAddIcon' : 'startIcon'}
        label={label}
        isLoading={false}
        isAvailable
        trigger={() => setShowModal(true)}
        isDisabled={!file.uploaded}
        disabledTooltip={t('gcode-meta.download.not-available-in-connect')}
      />
      {showModal && <CreateFarmPrintJobModal file={file as IPrintFile} onClose={() => setShowModal(false)} />}
    </>
  )
}
