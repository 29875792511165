import { ReactNode } from 'react'
import styled, { useTheme } from 'styled-components'

import { getState, IConnectState } from '../../api/types/state'
import { device } from '../../interfaces/device'
import { ColorScheme, getColorScheme } from '../../theme/getColorScheme'
import { PlainButton } from './PlainButton'
import { WithTooltip } from './WithTooltip'

const TagContainer = styled.div<{ $colorScheme: ColorScheme }>`
  background-color: ${(props) => props.$colorScheme.backgroundSecondary.background};
  ${(props) =>
    props.$colorScheme.border.background ? `border-left: 0.3rem solid ${props.$colorScheme.border.background};` : ''}
  font-size: 1rem;
  text-align: center;
  display: inline-flex;
  text-transform: uppercase;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;

  ${(props) =>
    props.$colorScheme.backgroundSecondary.text ? `color: ${props.$colorScheme.backgroundSecondary.text};` : ''}
`

const Title = styled.span<{ $small?: boolean; collapsible?: boolean }>`
  padding: ${(props) => (props.$small ? '0px' : '2px')} 1rem;
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  min-width: 8rem;
  vertical-align: middle;

  ${({ collapsible, $small }) =>
    collapsible &&
    `
    @media ${device.ltSm} {
      min-width: initial;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    @media ${device.ltXs} {
      padding: ${$small ? '0px' : '2px'} 10px;
      max-width: 4.8rem;
    }
  `}
`

const ContentWrapper = styled.span`
  text-align: center;
  flex: 1;
`

const CloseButton = styled(PlainButton)`
  border-left: 1px solid var(--border-primary);
  font-weight: bold;
  padding: 0 2px;

  &:hover {
    color: var(--color-primary);
  }
`

const Container = styled.div`
  ${TagContainer} + ${TagContainer} {
    border-left: none;
  }
`

type Props = {
  title: string
  baseColor?: string
  inverse?: boolean
  tooltip?: string
  small?: boolean
  collapsible?: boolean
  onClose?: () => void
}

export function Tag(props: Props) {
  const { onClose, baseColor, tooltip, title, small = false, collapsible, inverse = false } = props
  const theme = useTheme()

  const isDark = inverse ? !theme.isDark : theme.isDark

  const renderInner = () => (
    <ContentWrapper>
      <Title $small={small} collapsible={collapsible}>
        {title}
      </Title>
      {onClose && <CloseButton onClick={onClose}>{String.fromCharCode(215)}</CloseButton>}
    </ContentWrapper>
  )

  const renderContent = () => {
    if (tooltip) {
      return (
        <WithTooltip id="tag-tooltip" title={tooltip} placement="top">
          {renderInner()}
        </WithTooltip>
      )
    }

    return renderInner()
  }

  const colorScheme = baseColor
    ? getColorScheme(baseColor, isDark)
    : getColorScheme(getState(IConnectState.UNKNOWN).baseColor, isDark)

  return <TagContainer $colorScheme={colorScheme}>{renderContent()}</TagContainer>
}

export function TagsContainer({ children }: { children: ReactNode }) {
  return <Container>{children}</Container>
}
