export enum ChartColors {
  NOZZLE_TEMPERATURE = 'orange',
  BED_TEMPERATURE = 'green',
  FAN_PRINT_SPEED = 'blue',
  FAN_EXTRUDER_SPEED = 'red',
  TEMP_AMBIENT = 'red',
  TEMP_UV_LED = 'orange',
  TEMP_CPU = 'lightskyblue',
  FAN_BLOWER = 'blue',
  FAN_UV_LED = 'green',
  FAN_REAR = 'coral'
}

export enum YAxis {
  TEMPERATURE = 'TEMPERATURE',
  FAN_SPEED = 'FAN_SPEED'
}

export enum Series {
  TEMPERATURE_NOZZLE = 'temp_nozzle',
  TEMPERATURE_BED = 'temp_bed',
  FAN_EXTRUDER = 'fan_extruder',
  FAN_PRINT = 'fan_print',
  TEMP_AMBIENT = 'temp_ambient',
  TEMP_UV_LED = 'temp_uv_led',
  TEMP_CPU = 'temp_cpu',
  FAN_BLOWER = 'fan_blower',
  FAN_UV_LED = 'fan_uv_led',
  FAN_REAR = 'fan_rear'
}

export enum Targets {
  TARGET_BED = 'target_bed',
  TARGET_NOZZLE = 'target_nozzle',
  FLOW = 'flow',
  MATERIAL = 'material',
  SPEED = 'speed'
}
