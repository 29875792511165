import { createContext, ReactNode, useContext } from 'react'

export type RefetchFn = () => Promise<unknown> | (() => void)

const RefetchContext = createContext<RefetchFn>(() => Promise.resolve())

export function useRefetch() {
  const context = useContext(RefetchContext)
  if (!context) {
    throw new Error(
      `Refetch context is missing, you probably forgot to wrap your component with the <RefetchContextProvider />`
    )
  }

  return context
}

export function RefetchContextProvider({ children, refetch }: { children: ReactNode; refetch: RefetchFn }) {
  return <RefetchContext.Provider value={refetch}>{children}</RefetchContext.Provider>
}
