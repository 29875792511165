import { Pagination } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'

const Label = styled.div`
  align-self: center;
  margin-right: 0.5rem;
  font-weight: 400;
  font-size: 0.6875rem;
  color: #808080;
`

type Item = {
  limit: number
  active: boolean
}

type Props = {
  limit?: number
  setOffset: (offset: number) => void
  setLimit: (limit: number) => void
  itemsPerPage?: [number, number, number]
}

export function Limitation({ limit, setOffset, setLimit, itemsPerPage = [25, 50, 100] }: Props) {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const minItemsPerPage = itemsPerPage[0]
  const middleItemsPerPage = itemsPerPage[1]
  const maxItemsPerPage = itemsPerPage[2]
  const items: Item[] = [
    { limit: minItemsPerPage, active: typeof limit !== 'undefined' ? limit === minItemsPerPage : false },
    { limit: middleItemsPerPage, active: typeof limit !== 'undefined' ? limit === middleItemsPerPage : false },
    { limit: maxItemsPerPage, active: typeof limit !== 'undefined' ? limit === maxItemsPerPage : false }
  ]

  const onClick = (limit: number) => {
    setOffset(0)
    searchParams.set('page', '1')
    setSearchParams(searchParams)
    setLimit(limit)
  }

  return (
    <>
      <Label>{t('pager.items-per-page')}</Label>
      <Pagination>
        {items.map((item, i) => (
          <Pagination.Item key={i} active={item.active} onClick={() => onClick(item.limit)}>
            {item.limit}
          </Pagination.Item>
        ))}
      </Pagination>
    </>
  )
}
