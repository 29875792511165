import { toSvg } from 'jdenticon'
import { memo } from 'react'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'

type Props = {
  hash?: string
  height?: number
  width?: string
}

const FOLDED_HEIGHT = 90

function getSvg(hash: string, height: number) {
  return toSvg(hash, height, {
    hues: [20],
    lightness: {
      color: [0.76, 0.89],
      grayscale: [0.76, 0.9]
    },
    saturation: {
      color: 0.0,
      grayscale: 0.0
    }
  })
}

const Wrapper = styled.div<{ width?: string }>`
  svg {
    height: 100%;
    width: ${(props) => (props.width ? props.width : '100%')};
  }
`

/**
 * Renders a placeholder if real image is missing
 */
export const FileHashPreview = memo((props: Props) => {
  const { height, width } = props
  const hash = props.hash || uuid()
  const html = getSvg(hash, height || FOLDED_HEIGHT)

  // eslint-disable-next-line react/no-danger
  return <Wrapper dangerouslySetInnerHTML={{ __html: html }} width={width} />
})
