import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IVector2 } from '../../../api/types/vector'
import { usePrinter } from '../../../context/printerContext'
import { useToast } from '../../../context/toastStore'
import { isCommandTimeout } from '../../../helpers/responses'
import { isNumber } from '../../../helpers/std'
import { useCommandMutation } from '../../../hooks/commands/useCommandMutation'
import { Bed } from './Bed'

type IProps = {
  feedrate: number
}

export const ControlBed = ({ feedrate }: IProps) => {
  const { printer } = usePrinter()
  const { axis_x, axis_y, axis_z, printer_type } = printer

  const toast = useToast()
  const { t } = useTranslation()
  const [pendingXYPosition, setPendingXYPosition] = useState<IVector2>()
  const [xMovePending, setXMovePending] = useState(false)
  const [yMovePending, setYMovePending] = useState(false)
  const xyMoveIsPending = xMovePending || yMovePending

  const moveXYmutation = useCommandMutation(printer, {
    command: 'MOVE'
  })

  const onError = () => {
    // Unlock on error
    setXMovePending(false)
    setYMovePending(false)
  }

  const move = useCallback(
    (x?: number, y?: number) => {
      const moveObj = { feedrate, ...(x && y ? { x, y } : x && !y ? { x } : !x && y && { y }) }
      moveXYmutation.execute(moveObj, {
        onSuccess: (response) => {
          // Unlock if timeout happens
          if (isCommandTimeout(response)) {
            setXMovePending(false)
            setYMovePending(false)
          } else if (x && y) {
            toast.add(t('printer.control.moveX'), t('printer.control.moveX.description', { step: Math.round(x) }))
            toast.add(t('printer.control.moveY'), t('printer.control.moveY.description', { step: Math.round(y) }))
          } else if (x && !y)
            toast.add(t('printer.control.moveX'), t('printer.control.moveX.description', { step: Math.round(x) }))
          else if (!x && y)
            toast.add(t('printer.control.moveY'), t('printer.control.moveY.description', { step: Math.round(y) }))
        },
        onError
      })
    },
    [feedrate, moveXYmutation, t, toast]
  )

  const onBedClick = useCallback(
    (pos: IVector2) => {
      if (isNumber(axis_x) && isNumber(axis_y)) {
        const diffX = pos.x - axis_x
        const diffY = pos.y - axis_y

        if (diffX !== 0 && diffY !== 0) {
          move(diffX, diffY)
          setXMovePending(true)
          setYMovePending(true)
          return setPendingXYPosition(pos)
        }

        if (diffX !== 0) {
          move(diffX)
          // Lock X
          setXMovePending(true)
        }

        if (diffY !== 0) {
          move(undefined, diffY)
          // Lock Y
          setYMovePending(true)
        }

        setPendingXYPosition(pos)
      }
    },
    [axis_x, axis_y, move]
  )

  // Unlock XY positions on change
  useEffect(() => {
    setXMovePending(false)
  }, [axis_x])
  useEffect(() => {
    setYMovePending(false)
  }, [axis_y])

  // Cleanup pending position if the printer moved
  // We can't compare position since the printer can send whatever back :/
  useEffect(() => {
    if (!xyMoveIsPending) {
      setPendingXYPosition(undefined)
    }
  }, [axis_x, axis_y, pendingXYPosition, xyMoveIsPending])

  const isAvailable = moveXYmutation.isAvailable
  const isDisabled = xyMoveIsPending || !isAvailable
  const currentPosition = { x: axis_x || 0, y: axis_y || 0, z: axis_z || 0 }
  return (
    <Bed
      printerType={printer_type}
      disabled={isDisabled}
      currentPosition={currentPosition}
      pendingPosition={pendingXYPosition}
      onClick={onBedClick}
    />
  )
}
