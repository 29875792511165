import { ErrorBoundary } from '@sentry/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { IJob, IJobState } from '../../../api/types/job'
import { IGcodeMetadata } from '../../../api/types/metadata'
import { usePrinter } from '../../../context/printerContext'
import { getLongFileName } from '../../../helpers/files'
import { getJobHash } from '../../../helpers/jobs'
import { useBreakpoint } from '../../../helpers/useBreakpoint'
import { getWholeFilePath } from '../../../hooks/storageLocations/useStorageLocationTabs'
import { getJobDisplayPath, getJobTitle } from '../../../hooks/useJobTitle'
import { useIsSla } from '../../../hooks/usePrinterType'
import { CamerasView } from '../../camera/CamerasView'
import { AdaptiveButtonContext } from '../../common/AdaptiveButton/AdaptiveButtonContext'
import { JobStateTag } from '../../common/JobStateTag'
import { ImageWrapper, Label, Parameters, SectionTitle, Value } from '../../common/Parameters'
import { GcodeMetadataParameters } from '../../GcodeMetadataParameters'
import { JobPreview } from '../../jobs/JobPreview'
import { JobTimeParameters } from '../../jobs/JobTimeParameters'
import { DownloadFileAction } from '../actions/DownloadFileAction'
import { EnqueueAction } from '../actions/EnqueueAction'
import { StopPrintAction } from '../actions/PrintControlActions'
import { PrintVariant, StartPrintAction } from '../actions/StartPrintAction'
import { JobCameraSnapshots } from './JobCameraSnapshots'
import { JobCancelObjects } from './JobCancelObject'
import { JobMeshBedLeveling } from './JobMeshBedLeveling'
import { JobTelemetryChart } from './JobTelemetryChart'

type Props = {
  job: IJob
}

export const ButtonContainer = styled.div<{
  toColumn: boolean
}>`
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  flex-wrap: wrap;

  ${({ toColumn }) =>
    toColumn &&
    `
    display: grid;
    grid-auto-columns: 1fr;
  `}
`

type IControlButtons = {
  job: IJob
}

const ControlButtons = React.memo(({ job }: IControlButtons) => {
  const isMdOrLarge = useBreakpoint('md')
  const { printer } = usePrinter()
  const metadata = job.file?.meta as IGcodeMetadata

  return (
    <AdaptiveButtonContext value="simple">
      <ButtonContainer className="mt-2" toColumn={!isMdOrLarge}>
        <StartPrintAction
          filamentType={metadata?.filament_type}
          printer={printer}
          variant={PrintVariant.PRINT}
          withConfirm
          path={job.file?.path}
        />

        <EnqueueAction path={job.file?.path} />
        {job.state === IJobState.PRINTING && <StopPrintAction withConfirm printer={printer} />}
        {job.file && (
          <DownloadFileAction
            teamId={job.file.team_id}
            hash={job.file.hash}
            name={getLongFileName(job.file)}
            uploaded={job.file.uploaded}
            size={job.file.size}
          />
        )}
      </ButtonContainer>
    </AdaptiveButtonContext>
  )
})

function CamerasComponent({ job }: { job: IJob }) {
  const { t } = useTranslation()

  if (job.cameras && [IJobState.PRINTING, IJobState.PAUSED].includes(job.state)) {
    return <CamerasView />
  }

  if (job.cameras) {
    return (
      <>
        <SectionTitle>{t('jobs.cameras', 'Camera snapshots')}</SectionTitle>
        <JobCameraSnapshots jobCameras={job.cameras} />
      </>
    )
  }

  return null
}

export const JobDetail = ({ job }: Props) => {
  const { t } = useTranslation()
  const { printer } = usePrinter()
  const { file } = job
  const isSla = useIsSla(printer.printer_type)
  const metadata = job.file?.meta as IGcodeMetadata

  return (
    <>
      <Parameters>
        <>
          <Label>{t('jobs.name')}</Label>
          <Value>{getJobTitle(t, job)}</Value>
        </>

        <Label>{t('jobs.path')}</Label>
        <Value>
          {job.file?.path ? (
            <Link to={getWholeFilePath(job.file.path, printer, getJobHash(job))}>{getJobDisplayPath(job)}</Link>
          ) : (
            getJobDisplayPath(job)
          )}
        </Value>

        {job.source_info?.public_name && (
          <>
            <Label>{t('jobs.public-name')}</Label>
            <Value>{job.source_info.public_name}</Value>
          </>
        )}

        <Label>{t('jobs.state')}</Label>
        <Value>
          <JobStateTag state={job.state} />
        </Value>

        <JobTimeParameters start={job.start} end={job.end} estimated={metadata?.estimated_print_time} />

        <ImageWrapper className="col-start-2">
          <JobPreview size="auto" previewUrl={file?.preview_url} hashForThumbnail={getJobHash(job)} />
        </ImageWrapper>

        {job.cancelable && job.cancelable.objects.length > 0 && <JobCancelObjects job={job} />}

        {file?.meta && <GcodeMetadataParameters metadata={file.meta} isSla={isSla} />}
      </Parameters>

      <ControlButtons job={job} />

      <CamerasComponent job={job} />

      {job.id && job.start && job.end && (
        <ErrorBoundary>
          <JobTelemetryChart
            jobId={job.id}
            start={job.start}
            end={job.end}
            isPrinting={job.state === IJobState.PRINTING}
          />
        </ErrorBoundary>
      )}

      {job.mbl_points && <JobMeshBedLeveling mblData={job.mbl_points} />}
    </>
  )
}
