import { useMutation, useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Carousel from 'react-multi-carousel'
import { useSearchParams } from 'react-router-dom'
import { useLocalStorage } from 'react-use'

import { useApiClient } from '../../../api/react'
import { Fws, IFileData, IPrintFile } from '../../../api/types/file'
import { IAllowedFunctionalities, IPrinterSimpleView, IPrintersQuery } from '../../../api/types/printer'
import { IConnectState } from '../../../api/types/state'
import { intervals } from '../../../config'
import { useToast } from '../../../context/toastStore'
import { parseSortBy, serializeSortBy } from '../../../helpers/sorting'
import { useErrorHandler } from '../../../hooks/errors/useErrorHandler'
import { useDownloadingFirmware, useFlashFirmware } from '../../../hooks/useFlashFirmware'
import { useInfinitePrintersService } from '../../../services/usePrintersService'
import { AdaptiveButton } from '../../common/AdaptiveButton/AdaptiveButton'
import { Button } from '../../common/Button'
import { LoadingButtonOutlined } from '../../common/LoadingButtonOutlined'
import { PaceProgress } from '../../common/PaceProgress'
import { Printer } from '../groups/group/Printer'
import { PrintFile } from '../storage/FileManager/PrintFile'
import { getPrinterName } from '../utils'
import { CarouselItem, EmptyList, responsive, Title, Wrapper } from './carouselSettings'

type IDisabledPrinter = IPrinterSimpleView & { isDisabled?: boolean }

function comparePrinters(p1: IDisabledPrinter, p2: IDisabledPrinter, printerType?: string) {
  p1.isDisabled = printerType ? p1.printer_type !== printerType : false
  p2.isDisabled = printerType ? p2.printer_type !== printerType : false

  if (p1.isDisabled && !p2.isDisabled) return 1
  if (!p1.isDisabled && p2.isDisabled) return -1

  return 0
}

type ButtonProps = {
  file: IFileData
  printer: IPrinterSimpleView
  closeModal: () => void
}

function FlashOrCopyButton({ file, printer, closeModal }: ButtonProps) {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const teamId = searchParams.get('team')
  const toast = useToast()
  const errorHandler = useErrorHandler()
  const [downloadingFw, setDownloadedFw] = useLocalStorage<Fws>('firmware.downloading', {})

  // hack due to missing path
  const { app } = useApiClient()
  const { data: printerFile, isLoading: gettingPrinterFile } = useQuery(
    [`/file-detail/${teamId}/${printer.uuid}/${file.hash}`],
    () => {
      return app.files.getFile(Number(teamId), file.hash, printer.uuid)
    },
    {
      refetchInterval: downloadingFw?.[printer.uuid]?.hash ? intervals.transfersPolling : undefined
    }
  )

  const { execute: installFirmwareToPrinter } = useFlashFirmware(printer)

  const { mutate: copyToPrinterStorage, isLoading: isAddFirmwareToPrinterDownloadQueueLoading } = useMutation(
    () =>
      app.transfers.addToPrinterDownloadQueue(printer.uuid, {
        team_id: file.team_id,
        hash: file.hash,
        path: `/usb/${file.name}` // TODO primary storage?
      }),
    {
      onSuccess: () => {
        toast.add(
          t('printer.firmware.download-action.success-title'),
          t('printer.firmware.download-action.success-body')
        )
      },
      onError: (error: Error) => {
        setDownloadedFw({ ...downloadingFw, [printer.uuid]: null })
        errorHandler(error)
      }
    }
  )

  const hash = downloadingFw?.[printer.uuid]?.hash || ''
  const { data: getParticularFirmwareByVersion } = useDownloadingFirmware(printer.uuid, hash, Number(teamId))

  const isBeingDownloaded = !!getParticularFirmwareByVersion?.transferring || !!getParticularFirmwareByVersion?.planned

  const handleInstall = (path: string) => {
    closeModal()
    installFirmwareToPrinter({ path })
  }

  const handleCopy = () => {
    closeModal()
    setDownloadedFw({ ...downloadingFw, [printer.uuid]: file })
    copyToPrinterStorage()
  }

  return (
    <AdaptiveButton
      icon={printerFile?.path ? 'flashColorIcon' : 'downloadIcon'}
      label={
        printerFile?.path
          ? t('printer.actions.select-printer.flash', 'Flash!')
          : t('printer.actions.select-printer.transfer', 'Transfer first')
      }
      isLoading={gettingPrinterFile || isAddFirmwareToPrinterDownloadQueueLoading || isBeingDownloaded}
      isAvailable
      trigger={() => (printerFile?.path ? handleInstall(printerFile.path) : handleCopy())}
    />
  )
}

const isIncompatible = (printer: IPrinterSimpleView, fwFile: IFileData, t: Function) => {
  const supportsFlashFromPath = printer.allowed_functionalities?.includes(
    IAllowedFunctionalities.FIRMWARE_UPDATE_WITH_PATH
  )
  const isNotCompatible = fwFile.printer_type && printer.printer_type !== fwFile.printer_type

  const isDisabled = !supportsFlashFromPath || isNotCompatible

  let tooltip = ''
  if (!supportsFlashFromPath) {
    tooltip = t('printer.tooltip.disabled-functionality')
  } else if (isNotCompatible) {
    tooltip = t('printer.not-compatible-fw-file', 'The firmware file is not compatible with the printer')
  }

  return { isDisabled, tooltip }
}

function FlashPrinterModal(props: { file: IFileData; onClose: () => void }) {
  const { file, onClose } = props
  const { t } = useTranslation()
  const [selectedPrinter, setSelectedPrinter] = useState<IPrinterSimpleView | null>(null)
  const sortItems = parseSortBy('+state')

  const query: IPrintersQuery = {
    state_include: [IConnectState.IDLE, IConnectState.READY, IConnectState.STOPPED, IConnectState.FINISHED], // executable_from_state for FLASH command
    sort_by: serializeSortBy(sortItems),
    offset: 0,
    limit: 20
  }

  const { data, isLoading, hasNextPage, fetchNextPage } = useInfinitePrintersService(query)
  const printers = (data?.pages.flatMap((response) => response.printers) || []).sort((a, b) =>
    comparePrinters(a, b, file.printer_type)
  )

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage()
    }
  }, [hasNextPage])

  useEffect(() => {
    if (!printers.find((p) => p.uuid === selectedPrinter?.uuid)) {
      setSelectedPrinter(null)
    }
  }, [printers])

  const handleChange = (printerUuid: string) => {
    setSelectedPrinter(printers.find((p) => p.uuid === printerUuid) || null)
  }

  return (
    <Modal show onHide={onClose} centered size="xl">
      <Modal.Header closeButton>
        <Modal.Title>{t('file.to-flash.title', 'Select the printer to flash')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PrintFile file={file as IPrintFile} />
        <Title>{t('printer.group.available-printers-header')}</Title>
        {isLoading ? (
          <PaceProgress />
        ) : (
          <Wrapper>
            {printers.length > 0 ? (
              <Carousel responsive={responsive}>
                {printers.map((printer) => {
                  const { isDisabled, tooltip } = isIncompatible(printer, file, t)
                  return (
                    <CarouselItem
                      key={printer.uuid}
                      onClick={() => {
                        if (!isDisabled) handleChange(printer.uuid)
                      }}
                      className={selectedPrinter?.uuid === printer.uuid ? 'selected' : ''}
                    >
                      <Printer
                        name={getPrinterName(printer)}
                        type={printer.printer_type}
                        isDisabled={!!isDisabled}
                        disabledTooltip={tooltip}
                      />
                    </CarouselItem>
                  )
                })}
              </Carousel>
            ) : (
              <EmptyList>
                <strong>{t('no-printer-available.title')}</strong>
              </EmptyList>
            )}
          </Wrapper>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button type="reset" onClick={onClose}>
          {t('button.cancel')}
        </Button>
        {selectedPrinter ? (
          <FlashOrCopyButton printer={selectedPrinter} file={file} closeModal={onClose} />
        ) : (
          <LoadingButtonOutlined disabled disabledTooltip={t('printer.not-selected')}>
            {t('printer.actions.select-printer.label')}
          </LoadingButtonOutlined>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export function FlashFirmwareFromCloudAction(props: { file: IFileData }) {
  const { file } = props
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <AdaptiveButton
        icon="flashColorIcon"
        label={t('firmware.flash-printer', 'Flash printer')}
        isLoading={false}
        isAvailable
        trigger={() => setShowModal(true)}
      />

      {showModal && <FlashPrinterModal file={file} onClose={() => setShowModal(false)} />}
    </>
  )
}
