import { MouseEvent, ReactNode, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useOutsideClick } from '../../../../hooks/useOutsideClick'
import { Menu } from '../../../common/Dropdown.styled'

const DropdownMenu = styled(Menu)`
  position: fixed;
  background: var(--background-body);
  box-shadow: ${(props) => (props.theme.isDark ? 'none' : '0px 2px 10px #999999')};
  z-index: 99;
`

type Props = {
  children: ReactNode
  items: ReactNode | (() => ReactNode)
}

export function WithContextMenu({ children, items }: Props) {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)
  const nodeRef = useRef<HTMLInputElement>(null)
  const content = typeof items === 'function' ? items() : items

  useOutsideClick(nodeRef, (event) => {
    if (nodeRef.current?.contains(event.target as Node)) {
      return
    }

    setVisible(false)
  })

  const handleScroll = () => {
    setVisible(false)
  }

  const handleKeyUp = (event: KeyboardEvent) => {
    if (event.code === 'Escape') {
      setVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener('scroll', handleScroll)
    document.addEventListener('keyup', handleKeyUp)

    return () => {
      document.removeEventListener('scroll', handleScroll)
      document.removeEventListener('keyup', handleKeyUp)
    }
  }, [])

  const handleContextMenu = (event: MouseEvent) => {
    event.preventDefault()

    if (!content) {
      return
    }

    setVisible(true)

    const clickX = event.clientX
    const clickY = event.clientY
    const screenW = window.innerWidth
    const screenH = window.innerHeight
    const rootW = nodeRef?.current?.offsetWidth || 0
    const rootH = nodeRef?.current?.offsetHeight || 0

    const right = screenW - clickX > rootW
    const left = !right
    const top = screenH - clickY > rootH
    const bottom = !top

    if (!nodeRef.current) {
      return null
    }

    if (right) {
      nodeRef.current.style.left = `${clickX}px`
    }

    if (left) {
      nodeRef.current.style.left = `${clickX - rootW}px`
    }

    if (top) {
      nodeRef.current.style.top = `${clickY}px`
    }

    if (bottom) {
      nodeRef.current.style.top = `${clickY - rootH}px`
    }
  }

  return (
    <div title={t('context-menu.tooltip', 'Right-click for more options')} onContextMenu={handleContextMenu}>
      {children}

      <DropdownMenu
        ref={nodeRef}
        style={{ visibility: visible ? 'visible' : 'hidden' }}
        onClick={() => setVisible(false)}
        role="menu"
        aria-label={t('context-menu.aria-label', 'Context Menu')}
      >
        {content}
      </DropdownMenu>
    </div>
  )
}
