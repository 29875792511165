import { useTranslation } from 'react-i18next'

import { IPrinterSimpleView } from '../../../api/types/printer'
import { useCanControl } from '../../../context/permissionsStore'
import { useCommandMutation } from '../../../hooks/commands/useCommandMutation'
import { usePrinterInState } from '../../../hooks/usePrinterInState'
import { AdaptiveButton } from '../../common/AdaptiveButton/AdaptiveButton'
import { WithConfirm } from '../../common/ConfirmModal'

type Props = {
  printer: IPrinterSimpleView
}

export function RemoveCurrentTransferAction({ printer }: Props) {
  const { t } = useTranslation()
  const canControl = useCanControl(printer.team_id)
  const printerInState = usePrinterInState(printer.connect_state)

  const { execute, isLoading, isAvailable } = useCommandMutation(printer, {
    command: 'STOP_TRANSFER',
    successMessage: {
      title: t('transfers.actions.stop-transfer.success.title'),
      body: t('transfers.actions.stop-transfer.success.body')
    }
  })

  return (
    <WithConfirm title={t('transfers.queue.stop.title')} body={t('transfers.queue.stop.body')}>
      {(confirm) => (
        <AdaptiveButton
          icon="nokIcon"
          trigger={confirm(execute)}
          label={t('transfers.queue.stop.title')}
          isLoading={isLoading}
          isAvailable={isAvailable}
          isDisabled={!isAvailable || !canControl}
          disabledTooltip={!canControl ? t('printer.tooltip.rights') : printerInState}
        />
      )}
    </WithConfirm>
  )
}
