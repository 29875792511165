import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { useApiClient } from '../api/react'
import { intervals } from '../config'
import { useAuthActions } from '../context/authStore'
import { saveLanguage } from '../interfaces/locale'
import useServiceResponseHandler from './useServiceResponseHandler'

export function useLoginPing() {
  const { displayHttpError } = useServiceResponseHandler()

  const { i18n } = useTranslation()
  const { language } = i18n
  const auth = useAuthActions()

  const api = useApiClient()
  return useQuery(['/login-info'], () => api.general.getLogin(), {
    refetchOnWindowFocus: false,
    onSuccess: (response) => {
      const { user } = response
      auth.login(user)

      if (user.language !== language) {
        i18n.changeLanguage(user.language)
        saveLanguage(user.language)
      }
    },
    onError: (error: any) => {
      if (error && error.response && error.response.status !== 401 && error.response.status !== 403) {
        displayHttpError(error.response)
      }
      auth.logout()
    },
    refetchInterval: intervals.loginCheckPolling,
    refetchIntervalInBackground: true
  })
}
