import { stringify } from 'query-string'

import { PrinterUuid } from '../../types/printer'
import { RequestFactory } from '../../types/sdk'
import { ITelemetryQuery, ITelemetryResponse } from '../../types/telemetry'

export function createModule(rf: RequestFactory) {
  return {
    getData: (printerUuid: PrinterUuid, query: ITelemetryQuery = {}) =>
      rf.get<ITelemetryResponse>(`/app/printers/${printerUuid}/telemetry?${stringify(query)}`)
  }
}
