export type IQueryError = {
  response?: {
    status: number
    statusText: string
  }
  message?: string
  code?: string
}

export enum NotFoundErrors {
  NOT_FOUND_ENDPOINT = 'NOT_FOUND_ENDPOINT',
  NOT_FOUND_PRINTER = 'NOT_FOUND_PRINTER'
}

export enum ForbiddenErrors {
  FORBIDDEN_FARM_ONLY = 'FORBIDDEN_FARM_ONLY',
  FORBIDDEN_ADMIN_ONLY = 'FORBIDDEN_ADMIN_ONLY'
}
