import { useTranslation } from 'react-i18next'

import { useMaxFolderFileNameLength } from '../../../hooks/useMaxFolderFileNameLength'

type Props = {
  maxFilename: number
  path: string
  isDev?: boolean
}

export function RenameFileInstructions(props: Props) {
  const { path, maxFilename, isDev } = props
  const { t } = useTranslation()
  const length = useMaxFolderFileNameLength(path, maxFilename, isDev)

  return (
    <p>
      {t('file.rename.instructions.filename-requirements', {
        defaultValue: `The file name can be {number} characters long and must not contain invalid characters ('?', '"', '%', '¯', '°', '#', 'ˇ', '\\' or '/')`,
        number: length
      })}
    </p>
  )
}
