import { useQuery } from '@tanstack/react-query'

import { useApiClient } from '../api/react'

export function useGroups() {
  const { app } = useApiClient()

  return useQuery(['groups'], () => app.printerGroups.getGroups({ limit: 1000 }), {
    networkMode: 'offlineFirst'
  })
}

export function useAfsGroupId() {
  // const groupQuery = useGroups()
  // const firstGroup = groupQuery.data?.groups?.[0]
  // just for FormNext id = 8 :)
  return 8 // firstGroup?.id || 1 // TODO
}
