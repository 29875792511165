import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'

import { useApiClient } from '../api/react'
import { NotFoundErrors } from '../api/types/errors'
import { PrinterUuid } from '../api/types/printer'
import { intervals } from '../config'
import { usePageVisibility } from '../hooks/usePageVisibility'

export type IQueryError = {
  code: NotFoundErrors
  message: string
}

export function getPrinterQueryKey(printerUuid: PrinterUuid) {
  return `/printer/${printerUuid}`
}

export function usePrinterService(printerUuid: PrinterUuid) {
  const api = useApiClient()
  const [stopped, setStopped] = useState(false)
  const visibility = usePageVisibility()

  return useQuery([getPrinterQueryKey(printerUuid)], () => api.app.printers.getPrinter(printerUuid), {
    refetchInterval: stopped ? 0 : visibility ? intervals.printerPolling : intervals.printerPollingInactive,
    refetchIntervalInBackground: true,
    onError: (error: IQueryError) => {
      const staticErrors = [NotFoundErrors.NOT_FOUND_PRINTER, NotFoundErrors.NOT_FOUND_ENDPOINT]
      if (staticErrors.includes(error.code)) {
        setStopped(true)
      }
    }
  })
}
