/* eslint-disable prefer-template */
import 'dayjs/locale/en-gb'
import 'dayjs/locale/de'
import 'dayjs/locale/cs'
import 'dayjs/locale/es'
import 'dayjs/locale/fr'
import 'dayjs/locale/it'
import 'dayjs/locale/pl'

import { formatDistanceToNowStrict } from 'date-fns'
import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'
import duration from 'dayjs/plugin/duration'
import isToday from 'dayjs/plugin/isToday'
import isTomorrow from 'dayjs/plugin/isTomorrow'
import isYesterday from 'dayjs/plugin/isYesterday'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { THINSP } from '../../helpers/formatters'
import { isNumber } from '../../helpers/std'
import { useLoggedUserPreferences } from '../../hooks/useLoggedUser'
import { device } from '../../interfaces/device'
import { Language } from '../../interfaces/locale'
import { IDateFormat, IDateTimeFormat, IDateTimeFullFormat, ITimeFormat, ITimetype } from '../../interfaces/time'
import { TimeFormat } from '../preferences/types'

type Props = {
  unixTimestamp: number | undefined | null
  type?: ITimetype
  format?: IDateFormat | IDateTimeFormat
  verbally?: boolean
  onlyTimeIfToday?: boolean
  className?: string
  noSeconds?: boolean
  wrap?: boolean
}

const Span = styled.span<{ $wrap?: boolean }>`
  @media ${device.md} {
    white-space: ${({ $wrap }) => ($wrap ? 'wrap' : 'nowrap')};
  }
`

dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)
dayjs.extend(calendar)
dayjs.extend(duration)
dayjs.extend(isToday)
dayjs.extend(isTomorrow)
dayjs.extend(isYesterday)

// TODO refactor using date-fns

const MONTH_LIMIT_DAYS = 30

export const formatDuration = (t: Function, timestamp: number, noSeconds?: boolean) => {
  const duration = dayjs.duration(timestamp, 'seconds')

  const days = duration.days() ? `${duration.days()}${THINSP}${t('time.day-short')}` : ''
  const hours =
    duration.hours() || (days && duration.minutes())
      ? `${days ? ' ' : ''}${duration.hours()}${THINSP}${t('time.hour-short')}`
      : ''
  const minutes =
    duration.minutes() || (hours && !noSeconds && duration.seconds())
      ? `${hours ? ' ' : ''}${duration.minutes()}${THINSP}${t('time.minute-short')}`
      : ''
  let s = days + hours + minutes
  if (timestamp < 60) {
    s = `< 1${THINSP}${t('time.less-than-1-minute')}`
  } else if (!noSeconds) {
    const seconds = duration.seconds()
      ? `${minutes ? ' ' : ''}${duration.seconds()}${THINSP}${t('time.second-short')}`
      : ''
    s += seconds
  }
  return s
}

function findKeyByValue(obj: typeof IDateTimeFormat, value: string) {
  return Object.keys(obj).find((key) => obj[key as keyof typeof IDateTimeFormat] === value)
}

const getLocale = () => {
  const browserLocale = navigator.language.toLowerCase()
  return browserLocale !== 'en-gb' ? 'en' : browserLocale
}

export function Time(props: Props) {
  const { t, i18n } = useTranslation()
  const dateTime = useLoggedUserPreferences('date_time')
  const locale = i18n.language === Language.en ? dateTime.locale || getLocale() : i18n.language

  const preferredDateFormat: IDateFormat = dateTime.date_format
  const dateTimeFormat = `${preferredDateFormat} ${props.noSeconds ? ITimeFormat.LT : ITimeFormat.LTS}`
  const halfDayFormat = dateTime.time_format === TimeFormat.HALF

  // add "F" at the end of IDateTimeFormat and create IDateTimeFullFormat in case of 24-hour format
  const customFormat = halfDayFormat
    ? dateTimeFormat
    : IDateTimeFullFormat[`${findKeyByValue(IDateTimeFormat, dateTimeFormat)}F` as keyof typeof IDateTimeFullFormat]

  if (!isNumber(props.unixTimestamp)) {
    return null
  }

  const localizedTimestamp = dayjs.unix(props.unixTimestamp).locale(locale)
  const localizedDuration = dayjs.duration(props.unixTimestamp, 'seconds').locale(locale)

  const formatDistanceToNow = (timestamp: number) => {
    return formatDistanceToNowStrict(new Date(timestamp * 1000), {
      addSuffix: true,
      locale: {
        formatDistance: (token, value) => {
          switch (token) {
            case 'xSeconds':
              return `${value}${THINSP}${t('time.second-short')}`
            case 'xMinutes':
              return `${value}${THINSP}${t('time.minute-short')}`
            case 'xHours':
              return `${value}${THINSP}${t('time.hour-short')}`
            case 'xDays':
              return `${value}${THINSP}${t('time.day-short')}`
            case 'xMonths':
              return `${value}${THINSP}${t('time.month-short')}`
            default:
              return `${value}`
          }
        }
      }
    })
  }

  const getTimeFormat = () => {
    if (halfDayFormat) {
      return props.noSeconds ? ITimeFormat.LT : ITimeFormat.LTS
    }
    return props.noSeconds ? IDateTimeFullFormat.LTF : IDateTimeFullFormat.LTSF
  }

  return (
    <>
      {props.type === ITimetype.DATETIME && (
        // TODO need to be refactored
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {!props.verbally &&
          (localizedTimestamp.isToday() || localizedTimestamp.isTomorrow() || localizedTimestamp.isYesterday()) ? (
            <Span className={props.className} $wrap={props.wrap}>
              {localizedTimestamp.format(
                props.onlyTimeIfToday && localizedTimestamp.isToday() ? getTimeFormat() : customFormat
              )}
            </Span>
          ) : (
            <Span className={props.className} $wrap={props.wrap}>
              {props.verbally
                ? dayjs(localizedTimestamp).calendar(undefined, {
                    lastDay: `[${t('time.yesterday-at')}] ${getTimeFormat()}`,
                    sameDay: `[${t('time.today-at')}] ${getTimeFormat()}`,
                    nextDay: `[${t('time.tomorrow-at')}] ${getTimeFormat()}`,
                    lastWeek: customFormat,
                    nextWeek: customFormat,
                    sameElse: customFormat
                  })
                : dayjs(localizedTimestamp).format(customFormat)}
            </Span>
          )}
        </>
      )}

      {props.type === ITimetype.TIME && (
        <Span className={props.className} $wrap={props.wrap}>
          {localizedTimestamp.format(getTimeFormat())}
        </Span>
      )}

      {props.type === ITimetype.DATE && (
        <Span className={props.className} $wrap={props.wrap}>
          {localizedTimestamp.format(props.format)}
        </Span>
      )}

      {props.type === ITimetype.DURATION && (
        <Span className={props.className} $wrap={props.wrap}>
          {formatDuration(t, props.unixTimestamp, props.noSeconds)}
        </Span>
      )}

      {props.type === ITimetype.DISTANCE_TO_NOW && (
        <Span className={props.className} $wrap={props.wrap}>
          {formatDistanceToNow(props.unixTimestamp)}
        </Span>
      )}

      {props.type === ITimetype.COUNTDOWN && (
        // TODO need to be refactored
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {!props.noSeconds && (
            <Span className={props.className} $wrap={props.wrap}>
              {`
                                        ${
                                          localizedDuration.days() > 0
                                            ? localizedDuration.days() + THINSP + t('time.day-short') + ' '
                                            : ''
                                        }
                                        ${
                                          localizedDuration.hours() > 0
                                            ? localizedDuration.hours() + THINSP + t('time.hour-short') + ' '
                                            : ''
                                        }
                                        ${
                                          localizedDuration.minutes() > 0
                                            ? localizedDuration.minutes() + THINSP + t('time.minute-short') + ' '
                                            : ''
                                        }
                                        ${
                                          localizedDuration.seconds() > 0
                                            ? localizedDuration.seconds() + THINSP + t('time.second-short') + ' '
                                            : ''
                                        }
                                    `}
            </Span>
          )}
          {props.noSeconds && props.unixTimestamp < 60 && props.unixTimestamp >= 0 && (
            <Span className={props.className} $wrap={props.wrap}>{`< 1${THINSP}${t('time.less-than-1-minute')}`}</Span>
          )}
          {props.noSeconds && (props.unixTimestamp >= 60 || props.unixTimestamp === -1) && (
            <Span className={props.className} $wrap={props.wrap}>
              {`                        
                                        ${props.unixTimestamp === -1 ? t('time.not-available') : ''}
                                        ${
                                          localizedDuration.asDays() <= MONTH_LIMIT_DAYS && localizedDuration.days() > 0
                                            ? localizedDuration.days() + THINSP + t('time.day-short') + ' '
                                            : ''
                                        }
                                        ${
                                          localizedDuration.hours() > 0
                                            ? localizedDuration.hours() + THINSP + t('time.hour-short') + ' '
                                            : ''
                                        }
                                        ${
                                          localizedDuration.minutes() > 0
                                            ? localizedDuration.minutes() + THINSP + t('time.minute-short') + ' '
                                            : ''
                                        }
                                        ${
                                          !localizedDuration.days() &&
                                          !localizedDuration.hours() &&
                                          !localizedDuration.minutes() &&
                                          localizedDuration.seconds() > 0
                                            ? localizedDuration.seconds() + THINSP + t('time.second-short') + ' '
                                            : ''
                                        }
                                    `}
            </Span>
          )}
        </>
      )}
    </>
  )
}

Time.defaultProps = {
  type: ITimetype.DATETIME,
  format: IDateTimeFormat.lll,
  verbally: true,
  onlyTimeIfToday: false,
  noSeconds: false
}
