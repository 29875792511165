import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useApiClient } from '../api/react'
import { IPrinterSimpleView, PrinterUuid } from '../api/types/printer'
import { intervals } from '../config'
import { useToast } from '../context/toastStore'
import { useCommandMutation } from './commands/useCommandMutation'

export function useFlashFirmware(printer: IPrinterSimpleView) {
  const toast = useToast()
  const { t } = useTranslation()

  const { execute, isAvailable } = useCommandMutation(
    printer,
    {
      command: 'FLASH'
    },
    () => {
      // onSuccess
      toast.add(
        t('printer.firmware.being-installed.title', 'Firmware flash'),
        t('printer.firmware.being-installed.body', 'Firmware is being installed to the printer')
      )
    },
    () => {
      // onError
      toast.add(
        t('printer.firmware.restart-printer-action.error-title'),
        t('printer.firmware.restart-printer-action.error-body')
      )
    }
  )

  return { execute, isAvailable }
}

const uploadedFwEventName = 'FIRMWARE_UPLOADED_CUSTOM_EVENT'

export const dispatchFwUploadedEvent = (printer: IPrinterSimpleView, hash: string, teamId: number) => {
  const uploadedFwEvent = new CustomEvent(uploadedFwEventName, {
    detail: {
      printer,
      hash,
      teamId
    }
  })
  window.dispatchEvent(uploadedFwEvent)
}

export function useUploadedFirmwareToFlash() {
  const [hash, setHash] = useState<string>()
  const [printer, setPrinter] = useState<IPrinterSimpleView>()
  const [teamId, setTeamId] = useState()

  const fwUploadedEventHandler = (e: Event) => {
    const event = e as CustomEvent
    if (event.detail.printer && event.detail.hash) {
      setPrinter(event.detail.printer)
      setHash(event.detail.hash)
      setTeamId(event.detail.teamId)
    }
  }

  useEffect(() => {
    window.addEventListener(uploadedFwEventName, fwUploadedEventHandler)

    return () => {
      return window.removeEventListener(uploadedFwEventName, fwUploadedEventHandler)
    }
  }, [])

  const stopDispatching = () => {
    setPrinter(undefined)
    setHash(undefined)
    setTeamId(undefined)
  }

  return {
    printer,
    hash,
    teamId,
    stopDispatching
  }
}

export const useDownloadingFirmware = (printerUuid: PrinterUuid, hash: string, teamId?: number) => {
  const api = useApiClient()
  const query = { hash, team_id: teamId }

  return useQuery(
    [`/printers/${printerUuid}/firmware/${JSON.stringify(query)}`],
    () => {
      return api.app.printers.getPrinterFirmwareByVersion(printerUuid, query)
    },
    {
      enabled: Boolean(hash),
      refetchInterval: intervals.transfersPolling
    }
  )
}
