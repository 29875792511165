import { useMutation } from '@tanstack/react-query'

import { useApiClient } from '../../api/react'
import { IKwargs } from '../../api/types/commandQueue'
import { IPrinterSimpleView } from '../../api/types/printer'
import { usePermissions } from '../../context/permissionsStore'
import { useToast } from '../../context/toastStore'
import { Icon } from '../../icons'
import { useErrorHandler } from '../errors/useErrorHandler'
import { useIsAdmin } from '../useLoggedUser'
import { useSupportedCommandByUuid } from './useSupportedCommands'

type Options = {
  icon?: Icon
  label?: string
  command: string
  successMessage?: {
    title: string
    body: string
  }
  permissions?: {
    read?: boolean
    write?: boolean
    use?: boolean
  }
  mutationKey?: string[]
  cacheTime?: number
}

export type CommandMutation = ReturnType<typeof useCommandMutation>

export function useCommandMutation(
  printer: IPrinterSimpleView,
  options: Options,
  onSuccess?: () => void,
  onError?: (e: any) => void
) {
  const { uuid, connect_state, team_id } = printer
  const toast = useToast()
  const permissions = usePermissions(team_id)
  const isAdmin = useIsAdmin()

  const command = useSupportedCommandByUuid(options.command, printer)

  const getIsAvailable = () => {
    if (!command) {
      return false
    }

    // Check for printer's state
    if (command.executable_from_state && !command.executable_from_state.includes(connect_state)) {
      return false
    }

    // No permissions specified -> allow
    if (!options.permissions) {
      return true
    }

    // Check for permission
    const read = options.permissions.read ? permissions.canRead : true
    const write = options.permissions.write ? permissions.canWrite || isAdmin : true
    const use = options.permissions.use ? permissions.canUse : true

    return read && write && use
  }

  const isAvailable = getIsAvailable()
  const api = useApiClient()
  const errorHandler = useErrorHandler()

  const {
    mutate: execute,
    mutateAsync: executeAsync,
    isLoading,
    isSuccess
  } = useMutation(
    (kwargs?: IKwargs) => {
      if (isAvailable) {
        return api.app.commandQueue.addCommandSync(uuid, {
          command: options.command,
          kwargs
        })
      }

      return Promise.resolve(undefined)
    },
    {
      onSuccess: () => {
        if (options.successMessage) {
          const { title, body } = options.successMessage
          toast.add(title, body)
        }
        onSuccess?.()
      },
      onError: (error: any) => {
        onError?.(error)
        errorHandler(error.response, error)
      },
      ...(options.mutationKey ? { mutationKey: options.mutationKey } : []),
      cacheTime: options.cacheTime
    }
  )

  return {
    icon: options.icon,
    label: options.label,
    isAvailable,
    execute,
    executeAsync,
    isSuccess,
    isLoading,
    commandName: options.command
  }
}
