import { ReactElement } from 'react'
import { TFunction } from 'react-i18next'

import { IGcodeMetadata, ISlMetadata } from '../api/types/metadata'
import { TemperatureUnits } from '../components/common/Temperature'
import { Weight } from '../components/common/Weight'
import { Time } from '../components/helpers/time'
import { ITimetype } from '../interfaces/time'
import { DistanceUnits, formatLength, formatNumber, formatTemperature, formatVolume, THINSP } from './formatters'

type MetadataSections = { label: string; params: { label: string; value?: string | number | ReactElement }[] }[]

export function extractGcodeMetadata(
  t: TFunction,
  locale: string,
  meta: IGcodeMetadata,
  units: { temp: TemperatureUnits; dimensions: DistanceUnits }
): MetadataSections {
  const printerSection = {
    label: t('gcode-meta.printer'),
    params: [
      {
        label: t('gcode-meta.printer-model'),
        value: meta.printer_model
      }
    ]
  }

  const materialSection = {
    label: t('gcode-meta.material'),
    params: [
      { label: t('gcode-meta.filament-type'), value: meta.filament_type },
      {
        label: t('gcode-meta.filament-used'),
        value: [
          <Weight value={meta.filament_used_g} fractionDigits={3} />,
          formatLength(units.dimensions, locale, meta.filament_used_mm),
          formatVolume(units.dimensions, locale, meta.filament_used_mm3)
        ]
          .filter((value) => value)
          .join(' / ')
      },
      /* {
        label: t('gcode-meta.filament-cost'),
        value: meta.filament_cost
      }, */
      {
        label: t('gcode-meta.bed-temperature'),
        value: formatTemperature(units.temp, meta.bed_temperature)
      },
      {
        label: t('gcode-meta.nozzle-temperature'),
        value: formatTemperature(units.temp, meta.temperature)
      }
    ]
  }

  const printingQuality = {
    label: t('gcode-meta.print-settings'),
    params: [
      {
        label: t('gcode-meta.nozzle-diameter'),
        value: formatLength(units.dimensions, locale, meta.nozzle_diameter)
      },
      {
        label: t('gcode-meta.layer-height'),
        value: formatLength(units.dimensions, locale, meta.layer_height)
      },
      {
        label: t('gcode-meta.fill-density'),
        value: meta.fill_density
      },
      {
        label: t('gcode-meta.support-material'),
        value: meta.support_material ? t('yes') : t('no')
      }
    ]
  }

  return [printerSection, materialSection, printingQuality]
}

export function extractSlMetadata(t: TFunction, locale: string, meta: ISlMetadata): MetadataSections {
  const printerSection = {
    label: t('gcode-meta.printer'),
    params: [
      {
        label: t('gcode-meta.printer-model'),
        value: meta.printer_model
      }
    ]
  }

  const materialSection = {
    label: t('gcode-meta.material'),
    params: [
      {
        label: t('gcode-meta.material'),
        value: meta.material
      }
    ]
  }

  const printingQuality = {
    label: t('gcode-meta.print-settings'),
    params: [
      {
        label: t('gcode-meta.exposure_time_first', 'Exposure time – first layer'),
        value: meta.exposure_time_first ? `${formatNumber(meta.exposure_time_first / 1000, locale)}${THINSP}s` : ''
      },
      {
        label: t('gcode-meta.exposure-time', 'Exposure time'),
        value: meta.exposure_time ? `${formatNumber(meta.exposure_time / 1000, locale)}${THINSP}s` : ''
      },
      {
        label: t('gcode-meta.estimated_print_time', 'Estimated print time'),
        value: <Time unixTimestamp={meta.estimated_print_time} type={ITimetype.DURATION} noSeconds />
      }
    ]
  }

  return [printerSection, materialSection, printingQuality]
}
