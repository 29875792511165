import { useEffect } from 'react'

import { usePrusaAuthUrl } from '../hooks/usePrusaAuthUrl'

export function PrusaAuthRedirectPage() {
  const prusaAuthUrl = usePrusaAuthUrl()

  useEffect(() => {
    if (prusaAuthUrl) {
      window.location.href = prusaAuthUrl.href
    }
  }, [prusaAuthUrl])

  return null
}
