import { useConfig } from '@prusaconnect/api/services/useConfig'

export function useMaxUploadSize() {
  const config = useConfig()

  let maxUploadSize = 0 // bytes

  if (config?.auth?.max_upload_size) {
    maxUploadSize = config.auth.max_upload_size
  }

  return maxUploadSize
}
