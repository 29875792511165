import { useState } from 'react'

import { intervals } from '../../../config'

export function useFirmware() {
  const [showFirmwareAvailable, setShowFirmwareAvailable] = useState(false)
  const [showRestartConfirm, setShowRestartConfirm] = useState(false)
  const [showProcessingTransfer, setShowProcessingTransfer] = useState(false)
  const [showTransferIsInQueue, setShowTransferIsInQueue] = useState(false)
  const [defaultFirmwareDataInterval, setDefaultFirmwareDataInterval] = useState(intervals.transfersPolling)

  return {
    showFirmwareAvailable,
    showRestartConfirm,
    showProcessingTransfer,
    showTransferIsInQueue,
    defaultFirmwareDataInterval,
    setShowRestartConfirm,
    setShowFirmwareAvailable,
    setShowProcessingTransfer,
    setShowTransferIsInQueue,
    setDefaultFirmwareDataInterval
  }
}
