import { ReactNode } from 'react'
import { Container as BootstrapContainer } from 'react-bootstrap'

type Props = {
  Ref?: any
  children: ReactNode
  className?: string
}

export function Container(props: Props) {
  return (
    <BootstrapContainer ref={props.Ref} className={props.className}>
      {props.children}
    </BootstrapContainer>
  )
}
