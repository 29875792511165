import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import styled from 'styled-components'

import { useApiClient } from '../api/react'
import { ListItemContainer, PrinterList } from '../components/printers/PrintersOverview/ListView'
import { PrinterListItem } from '../components/printers/PrintersOverview/PrinterListItem'

const Center = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
`

const setAccessToken = (token: string, callback: Function) => {
  localStorage.setItem('auth.accessToken', token)
  callback()
}

export function SlicerPage() {
  const api = useApiClient()
  const { data, refetch } = useQuery(['/slicer/printers'], () => api.app.printers.getSlicerPrinters())

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.setAccessToken = (token) => setAccessToken(token, refetch)
  }, [])

  if (!data) {
    return <Center>Log in</Center>
  }

  return (
    <Container>
      <PrinterList>
        {data &&
          data.printers.map((printer) => {
            return (
              <ListItemContainer key={printer.uuid}>
                <PrinterListItem printer={printer} />
              </ListItemContainer>
            )
          })}
      </PrinterList>
    </Container>
  )
}
