import { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import { ConfirmModal } from '../../common/ConfirmModal'
import { SvgIcon } from '../../common/SvgIcon'

type Props = {
  onConfirm?: () => void
  onCancel?: (e?: MouseEvent) => void
  isLoading?: boolean
}

export function PourInResinModal(props: Props) {
  const { t } = useTranslation()
  const { isLoading } = props
  const theme = useTheme()
  const isDark = theme.name === 'dark'

  const ok = () => {
    props.onConfirm?.()
  }

  const cancel = () => {
    props.onCancel?.()
  }

  return (
    <ConfirmModal
      isLoading={isLoading}
      title={t('printer.actions.pour-in-resin.title', 'Pour in resin and continue')}
      body={
        <>
          <SvgIcon
            icon={isDark ? 'refillColorWhiteIcon' : 'refillColorIcon'}
            size={22}
            title={t('printer.cover_closed', 'Cover state')}
          />{' '}
          {t('printer.actions.pour-in-resin.body', 'Fill the tank with resin and resume print.')}
        </>
      }
      confirmBtnIcon="startIcon"
      confirmBtnText={t('printer.actions.pour-in-resin.label', 'Resume')}
      onCancel={cancel}
      onConfirm={ok}
    />
  )
}
