import classNames from 'classnames'
import React, { ReactNode, useContext, useEffect, useRef, useState } from 'react'
import { Table, TableProps } from 'react-bootstrap'
import styled from 'styled-components'

import { useBreakpoint } from '../../helpers/useBreakpoint'
import { Breakpoint } from '../../interfaces/breakpoints'
import { textEllipsis } from './styled'

const TableContainer = styled.div`
  overflow-x: auto;
  width: 100%;
`

const StyledTable = styled(Table)`
  margin: 0 auto;

  th {
    border: 0;
  }
  td {
    vertical-align: middle;
  }

  &.collapsed {
    table,
    thead,
    tbody,
    th,
    tr {
      display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      border: none;
      border-bottom: ${(props) => (props.borderless ? 'none' : '1px solid var(--border-secondary)')};
    }

    tr:first-child {
      border-top: ${(props) => (props.borderless ? 'none' : '1px solid var(--border-secondary)')};
    }

    td {
      border: 0;
      border-bottom: ${(props) => (props.borderless ? 'none' : '1px solid var(--border-primary)')};
    }
  }
`

const StyledTd = styled.td<IStyledTdProps>`
  ${textEllipsis}
  text-align: ${(props) => props.align || 'inherit'};
  font-weight: 400;
  font-size: 0.875rem;
  &.collapsed {
    text-align: left;

    /* Behave  like a "row" */
    position: relative;
    display: block;

    &[label] {
      padding-left: 45%;
    }

    /* Hide empty elements */
    &:empty {
      display: ${(props) => (props.noHide ? 'block' : 'none')};
    }

    /* Add labels like a table header */
    &[label]::before {
      position: absolute;
      left: 6px;
      max-width: 45%;
      vertical-align: middle;

      font-size: ${(props) => props.labelStyle?.fontSize || 'inherit'};
      font-weight: ${(props) => props.labelStyle?.fontWeight || 'bold'};

      content: attr(label);
    }

    /* Resize buttons */
    div[role='group'] {
      width: 100%;
    }
    .btn {
      width: 100%;
      font-size: 0.8em;
    }
  }
`

export const Th = styled.th`
  font-weight: 400;
  font-size: 1rem;
`

type Context = {
  collapsed: boolean
}

const TableContext = React.createContext<Context>({
  collapsed: false
})

export type ITableProps = {
  breakpoint?: Breakpoint
  children: ReactNode
} & TableProps

export function ResponsiveTable(props: ITableProps) {
  const breakpoint = useBreakpoint(props.breakpoint || 'md')

  return (
    <TableContainer>
      <StyledTable
        {...props}
        className={classNames(props.className, {
          collapsed: !breakpoint
        })}
      >
        <TableContext.Provider value={{ collapsed: !breakpoint }}>{props.children}</TableContext.Provider>
      </StyledTable>
    </TableContainer>
  )
}

type IStyledTdProps = {
  label?: string
  labelStyle?: React.CSSProperties
  noHide?: boolean
} & React.TdHTMLAttributes<HTMLTableCellElement>

type ITdProps = {
  noLabel?: boolean
} & IStyledTdProps

export function Td(props: ITdProps) {
  const ref = useRef<HTMLTableCellElement>(null)
  const [label, setLabel] = useState<string | undefined>(props.label)
  const ctx = useContext(TableContext)

  useEffect(() => {
    // Scan for label
    if (ref.current && ctx.collapsed) {
      if (props.noLabel) {
        ref.current.removeAttribute('label')
      } else if (!label) {
        const table = ref.current.closest('table')
        if (table) {
          const thead = table.tHead
          if (thead) {
            const ths = thead.getElementsByTagName('th')
            const index = ref.current.cellIndex
            if (index < ths.length) {
              const th = ths.item(index)
              if (th && th.innerText) {
                setLabel(th.innerText)
              }
            }
          }
        }
      }
    }
  }, [ref, props.noLabel, ctx.collapsed]) // eslint-disable-line

  return (
    <StyledTd
      ref={ref}
      label={label}
      {...props}
      className={classNames(props.className, {
        collapsed: ctx.collapsed
      })}
    >
      {props.children}
    </StyledTd>
  )
}
