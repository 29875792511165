import { useTranslation } from 'react-i18next'

import { ITeam } from '../api/types/team'

export function useTeamName() {
  const { t } = useTranslation()

  const getTeamName = (team?: ITeam): string => {
    if (!team) {
      return ''
    }

    return team.name || t('team.default-name', { id: team.id })
  }

  return { getTeamName }
}
