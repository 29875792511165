import { Form } from 'react-bootstrap'
import styled from 'styled-components'

import { device } from '../../../interfaces/device'
import { LoadingButton } from '../../common/LoadingButton'
import { ResponsiveTable } from '../../helpers/responsiveTable'

export const Row = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
`

export const SetupTable = styled(ResponsiveTable)`
  width: auto;
  @media ${device.ltSm} {
    width: 100%;
  }
  margin-bottom: 0;
`

export const ChannelForm = styled(Form)`
  margin-bottom: 1.5rem;
  width: 100%;
`

export const H1 = styled.h1`
  font-weight: 700;
  font-size: 1.25rem;
  padding-bottom: 2px;
  display: flex;
  align-items: center;
`

export const H2 = styled.h2`
  font-weight: 700;
  font-size: 1.125rem;
  display: flex;
  align-items: center;
`

export const LoadingSaveButton = styled(LoadingButton)`
  font-size: 14px;
  margin-right: 5px;
`

export const Buttons = styled.div``

export const Label = styled.td``
export const Value = styled.td`
  display: flex;
  align-items: center;
`

export const StatusCol = styled.td`
  width: 25px;
`

export const FirstCol = styled.td`
  min-width: 350px;
`

export const SecondCol = styled.td`
  min-width: 150px;
`

export const ButtonsCol = styled.td`
  min-width: 300px;
`

export const P = styled.p`
  font-weight: 300;
  font-size: 0.875rem;
`
