import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { usePrinter } from '../../../context/printerContext'
// import { ButtonLink } from '../../common/ButtonLink'
import { Button } from '../../common/Button'
import { ContainerTitle } from '../../common/ContainerTitle'
import { FilamentColorTag } from '../../common/FilamentColorTag'
import { SvgIcon } from '../../common/SvgIcon'
import { WithTooltip } from '../../common/WithTooltip'

const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`
const Span = styled.span`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 5px;
`

type Props = {
  onUnloadClick?: () => void
}

export function LoadUnloadFilament(props: Props) {
  const { printer } = usePrinter()
  const { filament } = printer
  const { t } = useTranslation()
  const navigate = useNavigate()

  // const isLoadEnabled = !filament
  const isUnloadEnabled = !!filament

  const handleUnload = () => {
    props.onUnloadClick?.()
    navigate('filament/unload-filament')
  }

  return (
    <>
      <ContainerTitle>{t('printer.filament.unload', 'Unload filament')}</ContainerTitle>
      <Span>
        {t('printer.filament.loaded-filament')}
        <FilamentColorTag filament={filament} material weight />
      </Span>
      <Buttons>
        {/* <WithTooltip id="disabled-load" title={!isLoadEnabled && t('printer.filament.load-disabled')} placement="top">
          <ButtonLink disabled={!isLoadEnabled} to="filament/load-filament">
            <SvgIcon icon="spoolColorIcon" size={20} />
            {t('printer.filament.loadFilament')}
          </ButtonLink>
        </WithTooltip> */}

        <WithTooltip
          id="disabled-unload"
          title={!isUnloadEnabled && t('printer.filament.unload-disabled')}
          placement="top"
        >
          <Button disabled={!isUnloadEnabled} onClick={handleUnload}>
            <SvgIcon icon="spoolColorIcon" size={20} />
            {t('printer.filament.unloadFilament')}
          </Button>
        </WithTooltip>
      </Buttons>
    </>
  )
}
