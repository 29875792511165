import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { config } from '../../../config'
import { useDownloadContext } from '../../../context/downloadContext'
import { useToast } from '../../../context/toastStore'
import { THINSP } from '../../../helpers/formatters'
import { delay, isDefined } from '../../../helpers/std'
import { AdaptiveButton } from '../../common/AdaptiveButton/AdaptiveButton'
import { SvgIcon } from '../../common/SvgIcon'

const Wrapper = styled.span`
  position: relative;
  display: inline-flex;
`

const Icon = styled.span<{ $isDownloading: boolean }>`
  opacity: ${(props) => (props.$isDownloading ? 0.2 : 1)};
  display: inline-flex;
  width: 100%;
  > * {
    width: 100%;
    button {
      width: 100%;
    }
  }
`

const Percents = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 700;
  white-space: nowrap;
  display: flex;
  width: 50px;
  justify-content: end;
  align-items: center;
  font-size: 0.75rem;
`

type Props = {
  teamId: number
  hash: string
  name: string
  uploaded?: number
  size?: number
}

export function DownloadFileAction({ teamId, hash, name, uploaded, size }: Props) {
  const { t } = useTranslation()
  const toast = useToast()
  const { downloadedFiles, setFile, resetFile } = useDownloadContext()

  const download = (e?: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>) => {
    e?.preventDefault()
    toast.add(t('download.startedTitle'), t('download.startedText', { filename: name }))

    const abort = () => {
      resetFile(hash)
      request.abort()
    }

    const request = new XMLHttpRequest()
    setFile(hash, { progress: 0, abort }) // to prevent the button from being pressed repeatedly

    request.responseType = 'blob'
    request.open('get', `${config.hostname}app/teams/${teamId}/files/${hash}/raw`, true)
    request.withCredentials = true
    request.send()

    request.onreadystatechange = () => {
      if (request.readyState === 4 && request.status === 200) {
        const url = window.URL.createObjectURL(request.response)
        const a = document.createElement('a')
        a.download = name
        a.href = url
        a.click()

        const { promise } = delay(5000) // ms
        promise.then(() => resetFile(hash))

        URL.revokeObjectURL(url)
      }
    }

    request.onprogress = (e) => {
      const percentsComplete = size ? Math.floor((e.loaded / size) * 100) : 0
      setFile(hash, { progress: percentsComplete, abort })
    }

    request.onerror = () => {
      const { promise } = delay(5000) // ms
      promise.then(() => resetFile(hash))
    }
  }

  const downloadProgress = downloadedFiles[hash]?.progress

  return (
    <Wrapper className="download-file">
      <Icon $isDownloading={!!downloadProgress}>
        <AdaptiveButton
          icon="downloadBlackIcon"
          label={t('button.download')}
          isLoading={!!downloadProgress}
          isAvailable
          trigger={download}
          isDisabled={!uploaded || isDefined(downloadProgress)}
          disabledTooltip={t('gcode-meta.download.not-available-in-connect')}
        />
      </Icon>
      {isDefined(downloadProgress) ? (
        <Percents>
          {`${downloadProgress}${THINSP}%`}
          <SvgIcon
            icon="nokIcon"
            size={18}
            style={{ cursor: 'pointer' }}
            onClick={() => downloadedFiles[hash].abort()}
          />
        </Percents>
      ) : null}
    </Wrapper>
  )
}
