import { IFilamentColor, Material } from '../api/types/filament'
import { uniqBy } from '../helpers/std'

// PREGENERATED WITH "node tools/parseColorsFromCSV.mjs"
export const FILAMENT_COLORS: { [key in Material]?: IFilamentColor[] } = {
  ASA: [
    { name: 'Jet Black', hex: '#000000' },
    { name: 'Natural', hex: '#F9F3DB' },
    { name: 'Prusa Galaxy Black', hex: '#292929' },
    { name: 'Prusa Orange', hex: '#FF6900' },
    { name: 'Signal White', hex: '#ECECE7' }
  ],
  PETG: [
    { name: 'Anthracite Grey', hex: '#383E42' },
    { name: 'Carmine Red', hex: '#661400' },
    { name: 'Clear', hex: '#9B9895' },
    { name: 'Chalky Blue', hex: '#6093AC' },
    { name: 'Jet Black', hex: '#000000' },
    { name: 'Mango Yellow', hex: '#F7B500' },
    { name: 'Neon Green', hex: '#53CC00' },
    { name: 'Ocean Blue', hex: '#00414B' },
    { name: 'Prusa Galaxy Black', hex: '#292929' },
    { name: 'Prusa Orange', hex: '#FF6900' },
    { name: 'Signal White', hex: '#ECECE7' },
    { name: 'Ultramarine Blue', hex: '#1A0076' },
    { name: 'Urban Grey', hex: '#808071' },
    { name: 'Yellow Gold', hex: '#767B00' },
    { name: 'Jungle Green', hex: '#0F9659' },
    { name: 'Lipstick Red', hex: '#FF0000' }
  ],
  PLA: [
    { name: 'Army Green', hex: '#344F1B' },
    { name: 'Azure Blue', hex: '#34B7D0' },
    { name: 'Galaxy Purple', hex: '#3B1563' },
    { name: 'Galaxy Silver', hex: '#A4A0A0' },
    { name: 'Gentleman´s Grey', hex: '#012022' },
    { name: 'Jet Black', hex: '#000000' },
    { name: 'Lime Green', hex: '#96AC44' },
    { name: 'Lipstick Red', hex: '#FF0000' },
    { name: 'Ms. Pink', hex: '#FF49F4' },
    { name: 'Mystic Brown', hex: '#4A3131' },
    { name: 'Mystic Green', hex: '#475433' },
    { name: 'Oh My Gold', hex: '#DAAF38' },
    { name: 'Opal Green', hex: '#2A5538' },
    { name: 'Pearl Mouse', hex: '#868463' },
    { name: 'Pineapple Yellow', hex: '#FAFA00' },
    { name: 'Prusa Galaxy Black', hex: '#292929' },
    { name: 'Prusa Orange', hex: '#FF6900' },
    { name: 'Royal Blue', hex: '#3C659E' },
    { name: 'Vanilla White', hex: '#D5C9B5' },
    { name: 'Viva La Bronze', hex: '#B98C3F' }
  ],
  PVB: [
    { name: 'Blue', hex: '#2941BD' },
    { name: 'Green', hex: '#51CC00' },
    { name: 'Natural', hex: '#FFFFFF' },
    { name: 'Orange', hex: '#FF6900' },
    { name: 'Smoky', hex: '#C0B3B3' },
    { name: 'Yellow', hex: '#F6FB38' }
  ]
}

export const KNOWN_COLORS = uniqBy(Object.values(FILAMENT_COLORS).flat(), (item) => item.hex)
