import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { ITeamSettings } from '../../api/types/notifications'
import { useTeamName } from '../../hooks/useTeamName'
import { disabledFilter } from '../helpers/styled'
import ScrollContainer from './ScrollContainer'

export const ScrollableTabs = styled.div<{ textAlign?: string }>`
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid var(--border-secondary);
  text-align: ${({ textAlign }) => textAlign || 'center'};
`

export const Tabs = styled.div`
  display: flex;
  user-select: none;
`

export const InlineBlock = styled.div`
  display: inline-block;
  text-align: center;
`

export const LeftShiftedTabs = styled.div`
  display: inline-block;
  text-align: left;
`

export const Tab = styled(Link)<{ $active?: boolean; $disabled?: boolean }>`
  font-weight: 700;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  color: var(--color-secondary);
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  white-space: nowrap;

  svg {
    margin-right: 0.5rem;
  }

  ${(props) =>
    props.$active &&
    css`
      border-bottom: 2px solid var(--color-primary);
    `}

  ${(props) =>
    props.$disabled &&
    css`
      pointer-events: none;
      cursor: not-allowed;
      ${disabledFilter()};
    `}
`

type Props = {
  active: number
  tabs: ITeamSettings[]
}

export function TeamsTabs(props: Props) {
  const { active, tabs } = props
  const { getTeamName } = useTeamName()

  return (
    <ScrollableTabs>
      <ScrollContainer background="var(--background-body)">
        <InlineBlock>
          <Tabs>
            {tabs.map((tab) => (
              <Tab key={tab.id} to={`../../${tab.id}`} $active={active === tab.id}>
                {getTeamName(tab)}
              </Tab>
            ))}
          </Tabs>
        </InlineBlock>
      </ScrollContainer>
    </ScrollableTabs>
  )
}

type IChannel = {
  name: string
  title: string
}

export function ChannelsTabs(props: { active: string; tabs: IChannel[] }) {
  const { active, tabs } = props

  return (
    <ScrollableTabs>
      <ScrollContainer background="var(--background-body)">
        <InlineBlock>
          <Tabs>
            {tabs.map((tab) => (
              <Tab key={tab.name} to={`../${tab.name}`} $active={active === tab.name}>
                {tab.title}
              </Tab>
            ))}
          </Tabs>
        </InlineBlock>
      </ScrollContainer>
    </ScrollableTabs>
  )
}
