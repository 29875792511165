import { useRef } from 'react'
import { Button as BootstrapButton, ButtonProps } from 'react-bootstrap'
import styled, { css, keyframes } from 'styled-components'

import { useInterval } from '../../helpers/useInterval'

type Props = ButtonProps & {
  isLoading?: boolean
  disabled?: boolean
}

const ripple = keyframes`
  to {
    transform: scale(4);
    opacity: 0;
  }
`

const Button = styled(BootstrapButton)<{ $isLoading?: boolean }>`
  position: relative;
  transition: background 400ms;
  overflow: hidden;

  ${(props) =>
    props.$isLoading &&
    css`
      user-select: none;
      cursor: default !important;
    `}

  .ripple {
    position: absolute;
    transform: scale(1);
    animation: ${ripple} 1000ms linear;
    background-color: rgba(255, 255, 255, 0.7);
  }
`

export function LoadingButton(props: Props) {
  const { isLoading, disabled, children, ...buttonProps } = props
  const buttonRef = useRef<HTMLButtonElement>()

  const createRipple = () => {
    const button = buttonRef.current
    if (!button) {
      return
    }

    const circle = document.createElement('span')

    circle.style.width = `${button.clientWidth}px`
    circle.style.height = `${button.clientHeight}px`
    circle.style.left = `${-button.clientWidth}px`
    circle.style.top = '0px'
    circle.classList.add('ripple')
    const ripple = button.getElementsByClassName('ripple')[0]

    if (ripple) {
      ripple.remove()
    }

    button.appendChild(circle)
  }

  useInterval(() => createRipple(), isLoading ? 1000 : null)

  return (
    <Button
      variant="secondary"
      $isLoading={isLoading}
      disabled={isLoading || disabled}
      {...buttonProps}
      ref={buttonRef}
    >
      {children}
    </Button>
  )
}
