import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

import { DEFAULT_GRID_MODE } from '../../../../config'
import { GridMode } from '../../../../constants/grid'
import { range } from '../../../../helpers/std'
import { IGrid } from '../../../../interfaces/grid'
import { PlainButton } from '../../../common/PlainButton'

const Cell = styled.div<{ $highlighted?: boolean }>`
  background: transparent;
  border: 1px solid lightgray;
  width: 12px;
  height: 12px;
  margin: 2px;

  ${(props) =>
    props.$highlighted &&
    css`
      border-color: #fa6831;
    `}
`

const Col = styled.div<{ $mode?: GridMode }>`
  display: flex;
  flex-direction: column;
  ${({ $mode }) => {
    if ($mode === GridMode.FIRST_UP) {
      return css`
        &:nth-child(even) {
          margin-top: 7px;
        }
      `
    }

    if ($mode === GridMode.FIRST_DOWN) {
      return css`
        &:nth-child(odd) {
          margin-top: 7px;
        }
      `
    }
    return ''
  }}
`

type Props = {
  size: IGrid
  shifted?: boolean
  initial?: IGrid
  onSelected: (pos: IGrid) => void
  onMouseOver?: (pos: IGrid) => void
  mode?: GridMode
}

export function GridSelector(props: Props) {
  const { mode = DEFAULT_GRID_MODE, shifted, size } = props
  const [selectedPos, setSelectedPos] = useState<IGrid>({ row: 1, col: 1 })

  const onMouseOver = (pos: IGrid) => {
    setSelectedPos(pos)
    if (props.onMouseOver) {
      props.onMouseOver(pos)
    }
  }

  const onSelected = (pos: IGrid) => props.onSelected(pos)

  useEffect(() => {
    if (props.initial) {
      setSelectedPos({
        row: props.initial.row,
        col: props.initial.col
      })
    }
  }, [props.initial, props.size])

  return (
    <div className="d-flex">
      {range(0, size.col)
        .map((col) => col + 1)
        .map((x) => (
          <Col $mode={shifted ? mode : undefined} key={x}>
            {range(0, size.row)
              .map((row) => row + 1)
              .map((z) => (
                <PlainButton
                  key={`${x},${z}`}
                  onMouseEnter={() => onMouseOver({ row: z, col: x })}
                  onClick={() => onSelected({ row: z, col: x })}
                >
                  <Cell $highlighted={!(selectedPos.row < z || selectedPos.col < x)} />
                </PlainButton>
              ))}
          </Col>
        ))}
    </div>
  )
}
