import React, { ReactNode, useRef } from 'react'

import { exhaustiveGuard } from '../../../helpers/exhaustiveGuard'
import { Icon } from '../../../icons'
import { DropdownItemButton } from '../Dropdown'
import { LoadingButtonOutlined } from '../LoadingButtonOutlined'
import { PlainButton } from '../PlainButton'
import { SvgIcon } from '../SvgIcon'
import { WithTooltip } from '../WithTooltip'
import { useAdaptiveButtonContext } from './AdaptiveButtonContext'
import { Mode, useRenderMode } from './useRenderMode'

export type ActionProps = {
  trigger: (e?: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>) => void
  label: string
  icon?: Icon
  className?: string
  isAvailable: boolean
  isLoading: boolean
  isDisabled?: boolean
  disabledTooltip?: string
  iconNode?: ReactNode
  showLabel?: boolean
}

export function AdaptiveButton(props: ActionProps) {
  const context = useAdaptiveButtonContext()

  switch (context) {
    case 'simple':
      return (
        <LoadingButtonOutlined {...props} disabled={props.isLoading || props.isDisabled} onClick={props.trigger}>
          {props.label}
        </LoadingButtonOutlined>
      )
    case 'dropdown':
      return <DropdownButton {...props} />
    case 'icon':
      return <IconButton {...props} />
    default:
      return exhaustiveGuard(context)
  }
}

function DropdownButton(actionProps: ActionProps) {
  const renderMode = useRenderMode()
  if (!actionProps.isAvailable) {
    return null
  }

  const getTitle = () => {
    if (renderMode === Mode.DROPDOWN) {
      return undefined
    }
    return actionProps.isDisabled ? actionProps.disabledTooltip : actionProps.label
  }

  return (
    <WithTooltip id={actionProps.label} title={getTitle()}>
      <DropdownItemButton
        onClick={!actionProps.isDisabled ? actionProps.trigger : () => {}}
        disabled={!!actionProps.isDisabled}
        className={actionProps.className}
        role="menuitem"
        tabIndex={0}
      >
        {actionProps.icon && <SvgIcon icon={actionProps.icon} />}
        {actionProps.label}
      </DropdownItemButton>
    </WithTooltip>
  )
}

function IconButton(actionProps: ActionProps) {
  const buttonRef = useRef<HTMLButtonElement | null>(null)

  if (!actionProps.isAvailable) {
    return null
  }

  return (
    <WithTooltip
      id={actionProps.label}
      title={actionProps.isDisabled ? actionProps.disabledTooltip : actionProps.label}
    >
      <PlainButton
        className={actionProps.className}
        disabled={!!actionProps.isDisabled}
        onClick={(e) => {
          actionProps.trigger(e)
          buttonRef.current?.blur()
        }}
        ref={buttonRef}
      >
        {actionProps.icon && <SvgIcon size={25} icon={actionProps.icon} />}
        {actionProps.showLabel && actionProps.label}
      </PlainButton>
    </WithTooltip>
  )
}
