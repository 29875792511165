import type { ApiClient } from '@prusaconnect/api/client/apiClient'
import { ApiClientContext } from '@prusaconnect/api/client/apiClientContext'
import * as Sentry from '@sentry/react'
import type { i18n } from 'i18next'
import { HelmetProvider } from 'react-helmet-async'
import { RouterProvider } from 'react-router-dom'

import { createApiClient as createOldApiClient } from './api/createClient'
import { ApiContextProvider as OldApiContextProvider } from './api/react'
import { QueryClientProvider } from './context/QueryClientProvider'
import { ToastsProvider } from './context/toastStore'
import { useInitAppziFeedback } from './helpers/useInitAppziFeedback'
import { ExceptionPage } from './pages/ExceptionPage'
import { LocaleProvider } from './providers/localeProvider'
import { router } from './router'

type Props = {
  i18n: i18n
  oldApiClient: ReturnType<typeof createOldApiClient>
  apiClient: ApiClient
}

export function App(props: Props) {
  useInitAppziFeedback()

  return (
    <LocaleProvider i18n={props.i18n}>
      <Sentry.ErrorBoundary fallback={<ExceptionPage />}>
        <OldApiContextProvider apiClient={props.oldApiClient}>
          <ApiClientContext.Provider value={props.apiClient}>
            <QueryClientProvider>
              <ToastsProvider>
                <HelmetProvider>
                  <RouterProvider router={router} />
                </HelmetProvider>
              </ToastsProvider>
            </QueryClientProvider>
          </ApiClientContext.Provider>
        </OldApiContextProvider>
      </Sentry.ErrorBoundary>
    </LocaleProvider>
  )
}
