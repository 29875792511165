import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'

import { useLoggedUser } from '../../../hooks/useLoggedUser'
import { usePrinterType } from '../../../hooks/usePrinterType'
import { useTeamName } from '../../../hooks/useTeamName'
import { useWritableTeams } from '../../../hooks/useTeams'
import { useAddPrinterService } from '../../../services/useAddPrinterService'
import { Button } from '../../common/Button'
import { PrinterIcon } from '../../common/PrinterIcon'
import { SvgIcon } from '../../common/SvgIcon'
import { MutationStatus } from '../../mutationStatus'
import { RequiredMark } from '../../users/UserForm'
import { StyledForm } from './SetupPrinterForm'
import { GrayText, H2, P as P1 } from './styled'

export const P = styled(P1)`
  margin-top: 2em;
`

const FormControl = styled(Form.Control)`
  &::placeholder {
    font-style: italic;
  }
`

type RouteInfo = {
  printerType: string
  code: string
  name?: string
  location?: string
}

function usePrinterDetails() {
  const [searchParams] = useSearchParams()
  const params = useParams<RouteInfo>()

  const printerName = searchParams.get('name') || params.name
  const printerLocation = searchParams.get('location') || params.location

  return {
    type: params.printerType,
    name: printerName ? String(printerName).replace(/~/g, ' ') : undefined,
    location: printerLocation ? String(printerLocation).replace(/~/g, ' ') : undefined,
    code: String(params.code)
  }
}

type Props = {
  stepIndex: number
  setStepIndex: (stepIndex: number) => void
  setPrinterType: (printerType: string) => void
}

export function ConnectPrinterPage(props: Props) {
  const params = useParams<RouteInfo>()
  const { t } = useTranslation()
  const { name, location, code, type } = usePrinterDetails()
  const { getTeamName } = useTeamName()
  const user = useLoggedUser()
  const theme = useTheme()
  const [printerCode, setPrinterCode] = useState(code || '')
  const [printerName, setPrinterName] = useState(name || '')
  const [printerLocation, setPrinterLocation] = useState(location || '')
  const [teamId, setTeamId] = useState(user.default_team_id)

  const navigate = useNavigate()
  const addPrinter = useAddPrinterService((createdPrinter) => {
    navigate(`/printer/${createdPrinter.uuid}`) // printer detail
  })

  const printerType = usePrinterType(String(type))

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    addPrinter.mutate({
      name: printerName,
      location: printerLocation,
      printer_type: String(type),
      code: printerCode,
      team_id: teamId
    })
  }

  useEffect(() => {
    if (user.default_team_id) setTeamId(user.default_team_id)
  }, [user])

  useEffect(() => {
    props.setStepIndex(props.stepIndex)
  }, [props])

  useEffect(() => {
    props.setPrinterType(String(params.printerType))
  }, [params])

  const { writableTeams } = useWritableTeams()

  useEffect(() => {
    if (writableTeams.length <= 1) {
      // add printer and navigate to detail
      addPrinter.mutate({
        name: printerName,
        location: printerLocation,
        printer_type: String(type),
        code: printerCode,
        team_id: teamId
      })
    }
  }, [])

  return (
    <>
      <Row>
        <Col md="auto">
          <PrinterIcon size={200} type={type} />
        </Col>
        <Col>
          <P>{t('printer.add.connecting-printer')}</P>
          <H2>{printerType?.name}</H2>
        </Col>
      </Row>
      <StyledForm onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="printerCode">
              <Form.Label>
                {t('printer.form.code-label')}
                <RequiredMark>*</RequiredMark>
              </Form.Label>
              <FormControl
                required
                value={printerCode}
                onChange={(e: any) => setPrinterCode(e.target.value)}
                placeholder={t('printer.form.code-placeholder')}
                type="text"
              />
            </Form.Group>

            <Form.Group controlId="printerName">
              <Form.Label>
                {t('printer.form.name-label')}
                <GrayText> ({t('printer.form.optional')})</GrayText>
              </Form.Label>
              <FormControl
                value={printerName}
                onChange={(e: any) => setPrinterName(e.target.value)}
                placeholder={printerType?.name || t('printer.form.optional')}
                type="text"
              />
            </Form.Group>

            <Form.Group controlId="printerLocation">
              <Form.Label>
                {t('printer.form.location-label')}
                <GrayText> ({t('printer.form.optional')})</GrayText>
              </Form.Label>
              <FormControl
                value={printerLocation}
                onChange={(e: any) => setPrinterLocation(e.target.value)}
                placeholder={t('printer.form.location-placeholder')}
                type="text"
              />
            </Form.Group>

            {writableTeams.length > 1 && (
              <Form.Group controlId="teamSelect">
                <Form.Label>
                  {t('printer.group.form.team-label')}
                  <RequiredMark>*</RequiredMark>
                </Form.Label>
                <Form.Control
                  required
                  as="select"
                  onChange={(e) => setTeamId(Number(e.currentTarget.value))}
                  value={teamId.toString()}
                >
                  {writableTeams.map((team) => (
                    <option key={team.id} value={team.id}>
                      {getTeamName(team)}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            )}

            <div className="ml-auto my-4">
              <Button type="submit" block disabled={addPrinter.isLoading}>
                {t('printer.add-final')}
                <SvgIcon icon="arrowRightIcon" fill={theme.colors.icons.primary} className="ml-1 mr-0" size="18" />
              </Button>
            </div>

            <MutationStatus mutation={addPrinter} />
          </Col>
        </Row>
      </StyledForm>
    </>
  )
}
