import Cookies from 'js-cookie'

export enum Language {
  cs = 'cs',
  en = 'en',
  de = 'de',
  es = 'es',
  fr = 'fr',
  it = 'it',
  pl = 'pl'
}

const LANG_COOKIE_TTL = 30 // days
const LANG_COOKIE_NAME = 'lang'

const detectBrowserLanguage = () =>
  (navigator.languages && navigator.languages[0]).substr(0, 2) || navigator.language.substr(0, 2)

const isSupportedLanguage = (lang: string): lang is Language => Object.keys(Language).includes(lang)

const getBrowserLanguage = () => {
  const detectedBrowserLanguage = detectBrowserLanguage()
  return isSupportedLanguage(detectedBrowserLanguage) ? detectedBrowserLanguage : Language.en
}

const getSavedLanguage = () => {
  const cookieLanguage = String(Cookies.get(LANG_COOKIE_NAME))
  return isSupportedLanguage(cookieLanguage) ? cookieLanguage : null
}

export const saveLanguage = (lang: Language) => Cookies.set(LANG_COOKIE_NAME, lang, { expires: LANG_COOKIE_TTL })

export const getIntialLanguage = () => {
  const language = getSavedLanguage() || getBrowserLanguage()

  saveLanguage(language)
  return language
}
