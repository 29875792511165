export type IVector2 = {
  x: number
  y: number
}

export type IVector3 = {
  x: number
  y: number
  z: number
}

export function areVectorsSame(a?: IVector3, b?: IVector3) {
  return a?.x === b?.x && a?.y === b?.y && a?.z === b?.z
}
