import { Material } from './filament'
import { IPrintFile } from './file'
import { IPager, IPagerQuery } from './pager'

export enum AfsJobState {
  CREATED = 'CREATED',
  APPROVED = 'APPROVED',
  PROCESSED = 'PROCESSED',
  FINISHED = 'FINISHED'
}

export enum AfsJobFlag {
  CANCELED = 'CANCELED',
  VALIDATED_OK = 'VALIDATED_OK',
  VALIDATED_NOK = 'VALIDATED_NOK'
}

export enum AfsJobStatePrimaryColor {
  CREATED = '#adadad',
  APPROVED = '#e7925e',
  PROCESSED = '#7da7d9',
  FINISHED = '#92c78c'
}

export enum AfsJobFlagPrimaryColor {
  CANCELED = '#e77174',
  VALIDATED_OK = '#92c78c',
  VALIDATED_NOK = '#e7925e'
}

export type AfsJobStateProperties = {
  state: AfsJobState
  primaryColor: string
}

export type AfsJobFlagProperties = {
  state: AfsJobFlag
  primaryColor: string
}

export const states: AfsJobStateProperties[] = [
  {
    state: AfsJobState.CREATED,
    primaryColor: AfsJobStatePrimaryColor.CREATED
  },
  {
    state: AfsJobState.APPROVED,
    primaryColor: AfsJobStatePrimaryColor.APPROVED
  },
  {
    state: AfsJobState.PROCESSED,
    primaryColor: AfsJobStatePrimaryColor.PROCESSED
  },
  {
    state: AfsJobState.FINISHED,
    primaryColor: AfsJobStatePrimaryColor.FINISHED
  }
]

export const flags: AfsJobFlagProperties[] = [
  {
    state: AfsJobFlag.CANCELED,
    primaryColor: AfsJobFlagPrimaryColor.CANCELED
  },
  {
    state: AfsJobFlag.VALIDATED_OK,
    primaryColor: AfsJobFlagPrimaryColor.VALIDATED_OK
  },
  {
    state: AfsJobFlag.VALIDATED_NOK,
    primaryColor: AfsJobFlagPrimaryColor.VALIDATED_NOK
  }
]

export enum AfsJobPriority {
  LOWEST = 'LOWEST',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  HIGHEST = 'HIGHEST'
}
export const afsJobPriorities = [
  AfsJobPriority.HIGHEST,
  AfsJobPriority.HIGH,
  AfsJobPriority.MEDIUM,
  AfsJobPriority.LOW,
  AfsJobPriority.LOWEST
]

export enum AfsJobSourceType {
  CONNECT_USER = 'CONNECT_USER',
  CONNECT_AGENT = 'CONNECT_AGENT',
  CONNECT_DEVICE = 'CONNECT_DEVICE',
  JOB = 'JOB'
}

export enum AfsJobSourceAction {
  CREATED = 'CREATED',
  APPROVED = 'APPROVED',
  DISAPPROVED = 'DISAPPROVED',
  CANCELED = 'CANCELED',
  PLANNED = 'PLANNED',
  VALIDATED = 'VALIDATED'
}

export type AfsJobSourceInfo = {
  start: number
  end: number
  // TODO: next items ...
}

export type AfsJobSource = {
  id: number
  device_uuid: string
  type_id: AfsJobSourceType
  action: AfsJobSourceAction
  timestamp: number
  source_info?: AfsJobSourceInfo
}

export type AfsJob = {
  id: number
  state: AfsJobState
  canceled: boolean
  validated?: boolean
  sources: AfsJobSource[]
  filament_type: string
  color?: string
  priority: AfsJobPriority
  hash: string
  file: IPrintFile
  sheet_id?: string

  // Id's of the copies of this job
  copies?: Set<number>
}

export type PatchAfsJob =
  | {
      state: AfsJobState.CREATED | AfsJobState.APPROVED
    }
  | {
      canceled: true
    }
  | {
      validated: boolean
    }

export type AfsJobs = {
  afs_jobs: AfsJob[]
  pager: IPager
}

export type AfsGroupJob = {
  filament_type: Material
  color?: string
  priority: AfsJobPriority
  hash: string
  copies: number
}

export type AfsJobsQuery = IPagerQuery & {
  state?: AfsJobState
  finished?: boolean
  // validated?: 'False' | 'True' | 'null'
  // canceled?: boolean
}
