import { useTranslation } from 'react-i18next'

import { AvailableOtherDeviceTypes, IAllowedFunctionalities, IPrinter } from '../api/types/printer'
import { IConnectState } from '../api/types/state'
import { Permissions } from '../context/permissionsStore'
import { usePrinter } from '../context/printerContext'
import { useStorageLocations } from '../hooks/storageLocations/useStorageLocations'
import { useFeatureFlags } from '../hooks/useFeatureFlags'
import { useIsAdmin } from '../hooks/useLoggedUser'
import { usePrinterInState } from '../hooks/usePrinterInState'
import { useIsSla } from '../hooks/usePrinterType'
import { Icon } from '../icons'

type Item = {
  title: string
  to: string
  icon?: Icon
  disabled?: boolean
  disabledTooltip?: string
}

export type NavigationItem = Item

export function usePrinterNavigation(printer: IPrinter, permissions: Permissions): NavigationItem[] {
  const { t } = useTranslation()
  const { isEnabled } = useFeatureFlags()
  const printerInState = usePrinterInState(printer.connect_state)
  const isAdmin = useIsAdmin()
  const canControl = permissions.canUse || isAdmin
  const { locations, isLoading } = useStorageLocations(printer)
  const isOffline = printer.connect_state === IConnectState.OFFLINE || printer.connect_state === IConnectState.UNKNOWN
  const isSla = useIsSla(printer.printer_type)
  const isOtherDeviceType =
    Object.values(AvailableOtherDeviceTypes).indexOf(printer.printer_type as AvailableOtherDeviceTypes) !== -1

  const items: NavigationItem[] = [
    {
      title: t('printer.dashboard'),
      to: 'dashboard'
    },
    {
      title: t('printer.storage'),
      to: 'storage',
      disabled: isOffline || (!isLoading && locations.length === 0),
      disabledTooltip: isOffline ? printerInState : t('printer.tooltip.no-local-storage')
    },
    {
      title: t('printer.queue'),
      to: 'queue',
      disabled: !isEnabled(IAllowedFunctionalities.QUEUE),
      disabledTooltip: t('printer.tooltip.disabled-functionality')
    },
    {
      title: t('printer.jobs'),
      to: 'jobs',
      disabled: false
    },
    {
      title: t('printer.camera'),
      to: 'cameras'
    },
    {
      title: t('printer.control'),
      to: 'control',
      disabled: isSla || isOtherDeviceType || !canControl || isOffline,
      disabledTooltip:
        isSla || isOtherDeviceType
          ? t('printer.tooltip.disabled-functionality')
          : isOffline
          ? printerInState
          : t('printer.tooltip.rights')
    },
    {
      title: t('printer.statistics'),
      to: 'statistics',
      disabled: isSla,
      disabledTooltip: t('printer.tooltip.disabled-functionality')
    },
    {
      title: t('printer.telemetry'),
      to: 'telemetry',
      disabled: false
    },
    {
      title: t('printer.settings'),
      to: 'settings',
      disabled: false
    }
  ]

  return items
}

export function usePrinterMeatballs(permissions: Permissions): NavigationItem[] {
  const { t } = useTranslation()
  const { isEnabled } = useFeatureFlags()
  const isAdmin = useIsAdmin()
  const canControl = permissions.canUse || isAdmin
  const {
    printer: { printer_type }
  } = usePrinter()
  const isSla = useIsSla(printer_type)

  const items: NavigationItem[] = [
    {
      title: t('printer.command'),
      to: 'command',
      disabled: !canControl,
      disabledTooltip: t('printer.tooltip.rights')
    },
    {
      title: t('printer.logs'),
      to: 'logs',
      disabled: false
    },
    {
      title: t('printer.transfers'),
      to: 'transfers',
      disabled: false
    }
  ]

  if (isSla) {
    /* items.push({
      title: t('printer.resin', 'Resin'),
      to: 'resin',
      disabled: !canControl || !isEnabled(IAllowedFunctionalities.RESIN),
      disabledTooltip: !isEnabled(IAllowedFunctionalities.RESIN)
        ? t('printer.tooltip.disabled-functionality')
        : t('printer.tooltip.rights')
    }) */
  } else {
    items.push({
      title: t('printer.filament'),
      to: 'filament',
      disabled: !canControl || !isEnabled(IAllowedFunctionalities.FILAMENT),
      disabledTooltip: !isEnabled(IAllowedFunctionalities.FILAMENT)
        ? t('printer.tooltip.disabled-functionality')
        : t('printer.tooltip.rights')
    })
  }

  return items
}
