import dayjs from 'dayjs'
import { EChartsOption, SeriesOption } from 'echarts'
import { useTranslation } from 'react-i18next'

import { IEvent } from '../../../api/types/event'
import { IJobPeriod } from '../../../api/types/job'
import { IConnectState } from '../../../api/types/state'
import { ITelemetryResponse } from '../../../api/types/telemetry'
import { formatTemperature } from '../../../helpers/formatters'
import { getCssCustomProperty } from '../../../helpers/getCssCustomProperty'
import { useLoggedUserPreferences } from '../../../hooks/useLoggedUser'
import attentionIcon from '../../../svg/icons/telemetry/locations_attention.png'
import finishIcon from '../../../svg/icons/telemetry/locations_finish.png'
import infoIcon from '../../../svg/icons/telemetry/locations_info.png'
import pauseIcon from '../../../svg/icons/telemetry/locations_pause.png'
import playIcon from '../../../svg/icons/telemetry/locations_play.png'
import stopIcon from '../../../svg/icons/telemetry/locations_stop.png'
import { TemperatureUnits } from '../../common/Temperature'
import { EChartsWrapper } from '../../helpers/EChartsWrapper'
import { useChartFormatters } from './chartFormatters'
import { ChartColors, Series, YAxis } from './constants'
import {
  calculateSimpleMovingAverage,
  getTransformedTelemetryData,
  TimestampedValue,
  TransformmedData
} from './transformTelemetryData'

type Period = [number, number?]

const commonLineOptions = {
  connectNulls: true,
  showSymbol: false
}

function getPeriodsBetween(events: IEvent[], startState: IConnectState, endStates: IConnectState[]) {
  const periods = events.reduce((acc: [number, number?][], event) => {
    if (event.state === undefined) {
      // Type check
      return acc
    }

    if (event.state === startState) {
      acc.push([Number(event.created)])
    }

    if (endStates.includes(event.state) && acc[acc.length - 1]?.length === 1) {
      acc[acc.length - 1]?.push(event.created)
    }

    return acc
  }, [])

  return periods.filter((period) => typeof period[1] === 'number')
}

function getIdlePeriods(events: IEvent[]) {
  const chronologicalEvents = [...events].reverse()

  return {
    pausedPeriods: getPeriodsBetween(chronologicalEvents, IConnectState.PAUSED, [
      IConnectState.PRINTING,
      IConnectState.STOPPED
    ]),
    attentionPeriods: getPeriodsBetween(chronologicalEvents, IConnectState.ATTENTION, [
      IConnectState.PRINTING,
      IConnectState.STOPPED
    ])
  }
}

function getPeriodArea(periods: Period[], color: string) {
  const jobPadding = periods.map((period) => {
    return period.map((timestamp) => ({
      xAxis: dayjs.unix(Number(timestamp)).toISOString()
    }))
  })

  return {
    type: 'line',
    name: 'Periods',
    color,
    silent: true,
    markArea: {
      data: jobPadding
    }
  }
}

function getSymbol(event: IEvent) {
  switch (event.state) {
    case IConnectState.PRINTING:
      return {
        symbol: `image://${playIcon}`,
        symbolSize: [29, 44]
      }
    case IConnectState.PAUSED:
      return {
        symbol: `image://${pauseIcon}`,
        symbolSize: [29, 44]
      }
    case IConnectState.FINISHED:
      return {
        symbol: `image://${finishIcon}`,
        symbolSize: [29, 44]
      }
    case IConnectState.STOPPED:
      return {
        symbol: `image://${stopIcon}`,
        symbolSize: [29, 44]
      }
    case IConnectState.ATTENTION:
      return {
        symbol: `image://${attentionIcon}`,
        symbolSize: [22, 22]
      }
    default:
      return {
        symbol: `image://${infoIcon}`,
        symbolSize: [18, 18]
      }
  }
}

function isValidJobPeriod(jobPeriod: IJobPeriod) {
  const { fullJobPeriod, printingPeriod } = jobPeriod
  return fullJobPeriod.from && fullJobPeriod.to && printingPeriod.from && printingPeriod.to
}

type TelemetryChartInternalProps = {
  temperatureBed?: TimestampedValue[]
  temperatureNozzles?: TimestampedValue[][]
  fanPrint?: TimestampedValue[]
  fanExtruder?: TimestampedValue[]
  tempAmbient?: TimestampedValue[]
  tempUvLed?: TimestampedValue[]
  tempCpu?: TimestampedValue[]
  fanBlower?: TimestampedValue[]
  fanUvLed?: TimestampedValue[]
  fanRear?: TimestampedValue[]
  enableZooming?: boolean
  showTimeline?: boolean
  events?: IEvent[]
  jobPeriod?: IJobPeriod
}

function TelemetryChartInternal({
  temperatureBed,
  temperatureNozzles,
  fanPrint,
  fanExtruder,
  tempAmbient,
  tempUvLed,
  tempCpu,
  fanBlower,
  fanUvLed,
  fanRear,
  enableZooming,
  showTimeline,
  events,
  jobPeriod
}: TelemetryChartInternalProps) {
  const { t } = useTranslation()
  const { axisTooltipFormatter, formatEventTooltip } = useChartFormatters()
  const units = useLoggedUserPreferences('units')
  const tempSign = units.temp === TemperatureUnits.CELSIUS ? TemperatureUnits.CELSIUS : TemperatureUnits.FAHRENHEIT

  const series: SeriesOption[] = []
  temperatureNozzles?.forEach((temperatureNozzle, index) => {
    // Skip first nozzle for multi-nozzle printers = show only multitools temperature, not shared
    if (temperatureNozzles.length !== 1 && index === 0) return

    series.push({
      type: 'line',
      yAxisId: YAxis.TEMPERATURE,
      id: Series.TEMPERATURE_NOZZLE + (index ? `_${index}` : ''),
      name:
        temperatureNozzles.length === 1
          ? t('telemetry.title-nozzle-temperature')
          : t('telemetry.title-nozzle-x-temperature', 'Nozzle {index} temperature', {
              index
            }),
      data: temperatureNozzle,
      color: ChartColors.NOZZLE_TEMPERATURE
    })
  })

  if (temperatureBed) {
    series.push({
      type: 'line',
      yAxisId: YAxis.TEMPERATURE,
      id: Series.TEMPERATURE_BED,
      name: t('telemetry.title-bed-temperature').toString(),
      data: temperatureBed,
      color: ChartColors.BED_TEMPERATURE
    })
  }

  if (fanExtruder) {
    series.push({
      type: 'line',
      yAxisId: YAxis.FAN_SPEED,
      id: Series.FAN_EXTRUDER,
      name: t('telemetry.title-fanextruder-speed').toString(),
      data: fanExtruder,
      color: ChartColors.FAN_EXTRUDER_SPEED
    })
  }

  if (fanPrint) {
    series.push({
      type: 'line',
      yAxisId: YAxis.FAN_SPEED,
      id: Series.FAN_PRINT,
      name: t('telemetry.title-fanprint-speed').toString(),
      data: fanPrint,
      color: ChartColors.FAN_PRINT_SPEED
    })
  }

  if (tempAmbient) {
    series.push({
      type: 'line',
      yAxisId: YAxis.TEMPERATURE,
      id: Series.TEMP_AMBIENT,
      name: t('telemetry.title-tempAmbient-temperature', 'Ambient temperature').toString(),
      data: tempAmbient,
      color: ChartColors.TEMP_AMBIENT
    })
  }

  if (tempUvLed) {
    series.push({
      type: 'line',
      yAxisId: YAxis.TEMPERATURE,
      id: Series.TEMP_UV_LED,
      name: t('telemetry.title-tempUvLed-temperature', 'UV LED temperature').toString(),
      data: tempUvLed,
      color: ChartColors.TEMP_UV_LED
    })
  }

  if (tempCpu) {
    series.push({
      type: 'line',
      yAxisId: YAxis.TEMPERATURE,
      id: Series.TEMP_CPU,
      name: t('telemetry.title-tempCpu-temperature', 'CPU temperature').toString(),
      data: tempCpu,
      color: ChartColors.TEMP_CPU
    })
  }

  if (fanBlower) {
    series.push({
      type: 'line',
      yAxisId: YAxis.FAN_SPEED,
      id: Series.FAN_BLOWER,
      name: t('telemetry.title-fanBlower-speed', 'Blower fan speed').toString(),
      data: fanBlower,
      color: ChartColors.FAN_BLOWER
    })
  }

  if (fanUvLed) {
    series.push({
      type: 'line',
      yAxisId: YAxis.FAN_SPEED,
      id: Series.FAN_UV_LED,
      name: t('telemetry.title-fanUvLed-speed', 'UV LED fan speed').toString(),
      data: fanUvLed,
      color: ChartColors.FAN_UV_LED
    })
  }

  if (fanRear) {
    series.push({
      type: 'line',
      yAxisId: YAxis.FAN_SPEED,
      id: Series.FAN_REAR,
      name: t('telemetry.title-fanRear-speed', 'Rear fan speed').toString(),
      data: fanRear,
      color: ChartColors.FAN_REAR
    })
  }

  if (events) {
    const markLines = events
      .filter((event) => event.created)
      .map((event) => ({
        type: 'line',
        name: `Events`,
        markLine: {
          ...getSymbol(event),
          data: [
            {
              name: event.event,
              xAxis: event.created ? dayjs.unix(event.created).toISOString() : 0,
              label: {
                show: false
              },
              lineStyle: {
                color: getCssCustomProperty('color-light'),
                type: 'solid',
                width: 1
              },
              symbol: 'none',
              tooltip: {
                trigger: 'item',
                formatter: formatEventTooltip(event)
              },
              emphasis: {
                label: {
                  formatter: ''
                },
                lineStyle: {
                  type: 'solid'
                }
              }
            }
          ]
        }
      }))

    markLines.forEach((markLine) => series.push(markLine as SeriesOption))
  }

  if (events && jobPeriod && isValidJobPeriod(jobPeriod)) {
    const idlePeriods = getIdlePeriods(events)
    const jobPaddingPeriods: Period[] = [
      [jobPeriod.fullJobPeriod.from, jobPeriod.printingPeriod.from],
      [jobPeriod.printingPeriod.to, jobPeriod.fullJobPeriod.to]
    ]

    if (!Number.isNaN(jobPeriod.printingPeriod.to)) {
      const jobPaddingAreas = getPeriodArea(jobPaddingPeriods, '#ececec') as SeriesOption
      series.push(jobPaddingAreas)
    }

    const pausedPeriodAreas = getPeriodArea(idlePeriods.pausedPeriods, '#e5ebff') as SeriesOption
    const attentionPeriodAreas = getPeriodArea(idlePeriods.attentionPeriods, '#ffe3c1') as SeriesOption

    if (pausedPeriodAreas.markArea.data.length > 0) {
      series.push(pausedPeriodAreas)
    }

    if (attentionPeriodAreas.markArea.data.length > 0) {
      series.push(attentionPeriodAreas)
    }
  }

  const showToolbox = showTimeline || enableZooming

  const dataZoom = []
  if (showTimeline) {
    dataZoom.push({ type: 'slider', minValueSpan: 2 })
  }
  if (enableZooming) {
    dataZoom.push({ type: 'inside', minValueSpan: 2 })
  }

  const option: EChartsOption = {
    toolbox: showToolbox
      ? {
          right: 20,
          top: 10,
          itemSize: 12,
          feature: {
            restore: {
              title: t('telemetry.restore-zoom')
            }
          }
        }
      : undefined,
    tooltip: {
      trigger: 'axis',
      formatter: axisTooltipFormatter
    },
    xAxis: {
      type: 'time'
    },
    legend: {
      data: series.map((s) => String(s.name)).filter((s) => s !== 'Events' && s !== 'Periods')
    },
    dataZoom,
    yAxis: [
      {
        id: YAxis.TEMPERATURE,
        name: t('telemetry.yaxis-left.title-text'),
        nameLocation: 'middle',
        nameGap: 60,
        interval: 60,
        type: 'value',
        axisLabel: {
          formatter: (value: number) => formatTemperature(tempSign, value)
        }
      },
      {
        id: YAxis.FAN_SPEED,
        name: t('telemetry.yaxis-right.title-text'),
        nameLocation: 'middle',
        nameGap: 70,
        interval: 1000,
        type: 'value',
        axisLabel: {
          formatter: (value: number) => `${value}/min`
        }
      }
    ],
    series: series.map((s) => ({ ...commonLineOptions, ...s }))
  }

  return <EChartsWrapper option={option} />
}

type Props = {
  telemetryData: ITelemetryResponse
  // Moving average of values, this is maximal value, other are calculated from this value
  movingAverage: 10 | 80
} & Pick<TelemetryChartInternalProps, 'enableZooming' | 'showTimeline' | 'events' | 'jobPeriod'>

export function TelemetryChart({ telemetryData, movingAverage, ...props }: Props) {
  const { i18n } = useTranslation()

  const transformedData = getTransformedTelemetryData(telemetryData, i18n.language)

  const mediumWindow = movingAverage === 80 ? 30 : 5
  const smallWindow = movingAverage === 80 ? 10 : 3

  const changedFanExtruderData = calculateSimpleMovingAverage(movingAverage, transformedData.fan_extruder)
  const changedFanPrintData = calculateSimpleMovingAverage(mediumWindow, transformedData.fan_print)
  const changedTempBedData = calculateSimpleMovingAverage(smallWindow, transformedData.temp_bed)

  // Generic code for any number of nozzles, 1 and more
  const changeToolsNozzleTemps = (Object.keys(transformedData) as (keyof TransformmedData)[])
    // Find temp nozzle data and filter out series without values
    .filter((key) => key.startsWith('temp_nozzle') && transformedData[key].some(([, value]) => value !== null))
    .sort() // Data are loaded from objects where are not sorted
    .map((key) => calculateSimpleMovingAverage(mediumWindow, transformedData[key]) as TimestampedValue[])

  const changedTempAmbientData = calculateSimpleMovingAverage(smallWindow, transformedData.temp_ambient)
  const changedTempUvLedData = calculateSimpleMovingAverage(smallWindow, transformedData.temp_uv_led)
  const changedTempCpuData = calculateSimpleMovingAverage(smallWindow, transformedData.temp_cpu)
  const changedFanBlowerData = calculateSimpleMovingAverage(mediumWindow, transformedData.fan_blower)
  const changedFanUvLedData = calculateSimpleMovingAverage(mediumWindow, transformedData.fan_uv_led)
  const changedFanRearData = calculateSimpleMovingAverage(mediumWindow, transformedData.fan_rear)

  return (
    <TelemetryChartInternal
      {...props}
      temperatureBed={changedTempBedData}
      temperatureNozzles={changeToolsNozzleTemps}
      fanExtruder={changedFanExtruderData}
      fanPrint={changedFanPrintData}
      tempAmbient={changedTempAmbientData}
      tempUvLed={changedTempUvLedData}
      tempCpu={changedTempCpuData}
      fanBlower={changedFanBlowerData}
      fanUvLed={changedFanUvLedData}
      fanRear={changedFanRearData}
    />
  )
}
