export const timestampAdd = (seconds: number, unix: boolean): number => {
  const timestamp = new Date().getTime()

  return unix ? timestampToUnixTimestamp(timestamp) + seconds : timestamp + seconds * 1000
}

export const timestampToUnixTimestamp = (timestamp: number): number => Math.floor(timestamp / 1000)

export const timestampToJavascriptTimestamp = (timestamp: number): number => timestamp * 1000

export const getCurrentUnixtimestamp = (): number => timestampToUnixTimestamp(new Date().getTime())
