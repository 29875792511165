import { createContext, ReactNode, useContext } from 'react'

import { IVector3 } from '../api/types/vector'

type Context = {
  loadingPosition: IVector3 | null
}

const DndContext = createContext<Context>({
  loadingPosition: null
})

type Props = {
  loadingPosition: IVector3 | null
  children: ReactNode
}

export function DndContextProvider(props: Props) {
  const { loadingPosition, children } = props
  return <DndContext.Provider value={{ loadingPosition }}>{children}</DndContext.Provider>
}

export function useDndContext() {
  const ctx = useContext(DndContext)

  return ctx
}
