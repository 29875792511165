import { forwardRef, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useIntersectionObserver } from 'react-intersection-observer-hook'

import { useNotificationsService } from '../../services/useNotificationsService'
import { Dropdown } from '../common/Dropdown'
import { SvgIcon } from '../common/SvgIcon'
import { Notifications } from '../notifications/Notifications'
import { NotificationHeaderButton, NotificationsContainer } from '../notifications/Notifications.styled'

function NotificationDropdownToggle({ unseenNotificationsCount }: { unseenNotificationsCount: number }) {
  const displayUnseenCount = unseenNotificationsCount > 99 ? '99+' : unseenNotificationsCount

  return (
    <NotificationHeaderButton>
      {unseenNotificationsCount > 0 && (
        <span className="notification-count" data-testid="notification-count">
          {displayUnseenCount}
        </span>
      )}
      <SvgIcon icon="notificationIcon" />
    </NotificationHeaderButton>
  )
}

const LoadingSpool = forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <div className="loading-wheel" ref={ref}>
      <SvgIcon size={50} icon="loadingWheel4SIcon" />
    </div>
  )
})

export function NotificationsDropdown() {
  const { notifications, isLoading, unseenCount, setRead, setAllRead, refetch, fetchNextPage, hasNextPage } =
    useNotificationsService()
  const { t } = useTranslation()
  const [ref, { entry, rootRef }] = useIntersectionObserver()
  const isVisible = entry && entry.isIntersecting
  const hasLoaded = useRef(false)

  useEffect(() => {
    if (isVisible && hasNextPage) {
      fetchNextPage()
    }
  }, [isVisible, hasNextPage, fetchNextPage])

  const fetchNotifications = () => {
    if (!hasLoaded.current || unseenCount > 0) {
      hasLoaded.current = true
      return refetch()
    }
  }

  return (
    <Dropdown
      trigger={<NotificationDropdownToggle unseenNotificationsCount={unseenCount} />}
      onTriggerClick={() => fetchNotifications()}
      placement="bottom-end"
    >
      <NotificationsContainer ref={rootRef}>
        {isLoading ? (
          <LoadingSpool />
        ) : (
          <>
            <Notifications notifications={notifications} setRead={setRead} setAllRead={setAllRead} />
            {hasNextPage ? (
              <LoadingSpool ref={ref} />
            ) : (
              <div className="no-more-notifications" style={{ padding: '1rem' }}>
                {t('notifications.no-more-notifications', 'There are no more notifications to show')}
              </div>
            )}
          </>
        )}
      </NotificationsContainer>
    </Dropdown>
  )
}
