import styled, { css } from 'styled-components'

import { device } from '../../interfaces/device'
import { textEllipsis } from '../helpers/styled'

/** For smaller than large */
const hiddenLtLg = css`
  @media ${device.ltLg} {
    display: none !important;
  }
`

export const Container = styled.div`
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
  width: 100%;

  button {
    opacity: 0.35;
  }
`

export const ButtonIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  margin-top: 10px;
`

export const FileTitle = styled.div`
  flex: 1;
  min-width: 0;
  ${textEllipsis};
  font-weight: 700;
  color: var(--color-text);
  margin-top: 10px;
`

export const ProgressDescription = styled.div`
  flex: 1;
  min-width: 0;
  ${textEllipsis};
  font-size: 0.8rem;
  font-weight: 400;
  color: var(--color-light);
`

export const QueuedTransfer = styled.div`
  display: flex;
  align-items: center;
`

export const Progress = styled.div<{ progress?: number; current?: boolean }>`
  font-style: italic;
  height: 0.5rem;
  margin: 0.25rem 0;
  align-self: center;
  background-color: var(--background-primary);
  background: linear-gradient(
    to right,
    #fa6831 ${(props) => props.progress || 0}%,
    var(--progress-background-primary) 0%
  );
`

export const ProgressToPrinter = styled.div<{ progress?: number; current?: boolean }>`
  font-style: italic;
  height: 0.5rem;
  margin: 0.25rem 0;
  align-self: center;
  background-color: var(--background-primary);
  background: linear-gradient(
    to right,
    #7da7d9 ${(props) => props.progress || 0}%,
    var(--progress-background-secondary) 0%
  );
`

export const HalfQueue = styled.div`
  width: 100%;
`

export const HalfQueueToConnect = styled.div<{ hidable: boolean }>`
  width: 100%;
  ${({ hidable }) => hidable && hiddenLtLg};
`
