import { useTranslation } from 'react-i18next'

import { IGroupOverview, IGroupSimpleView } from '../api/types/groups'

export function useGroupName() {
  const { t } = useTranslation()

  const getGroupName = (group: IGroupOverview | IGroupSimpleView): string => {
    return group.name || t('group.default-name', { id: group.id })
  }

  return { getGroupName }
}
