import styled from 'styled-components'

import { IPrinterFilamentDetail } from '../../api/types/printer'
import { KNOWN_COLORS } from '../../constants/filamentColors'
import { ColorPreview, PreviewSize } from '../printers/filament/forms/ColorPreview'

const UNKNOWN = '–'

const Span = styled.span`
  margin-right: 5px;
`

const FilamentTag = styled.span`
  display: flex;
  align-items: center;
`

type Props = {
  filament?: IPrinterFilamentDetail
  material?: boolean
  weight?: boolean
  name?: boolean
}

export function FilamentColorTag(props: Props) {
  const { filament, weight, name, material } = props

  if (!filament) {
    return <span>{UNKNOWN}</span>
  }

  const colorName = KNOWN_COLORS.find((color) => color.hex === filament.color)?.name

  return (
    <FilamentTag>
      <ColorPreview className="mr-1" hex={filament.color} size={PreviewSize.SMALL} />
      {material && <Span>{filament.material}</Span>}
      {weight && <Span>{filament.spool_weight}g</Span>}
      {name && <Span>{colorName ? <abbr title={filament.color}>{colorName}</abbr> : filament.color}</Span>}
    </FilamentTag>
  )
}
