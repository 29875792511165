import { Draggable, Droppable } from 'react-beautiful-dnd'

import { IPrinter } from '../../../../api/types/printer'
import { getPrinterName } from '../../utils'
import { getDraggableId } from './dnd'
import { Printer } from './Printer'

type Props = {
  printer: IPrinter
}

export function NonpositionedPrinter(props: Props) {
  const { printer } = props
  return (
    <Droppable key={printer.uuid} droppableId={printer.uuid} direction="horizontal" isDropDisabled>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          <Draggable key={printer.uuid} draggableId={getDraggableId(printer)} index={1} shouldRespectForcePress={false}>
            {(provided, snapshot) => (
              <>
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={provided.draggableProps.style}
                >
                  <Printer
                    isDragging={snapshot.isDragging}
                    name={getPrinterName(printer)}
                    type={printer.printer_type}
                  />
                </div>
                {/* This is visible below dragged item */}
                {snapshot.isDragging && (
                  <Printer inset={false} name={getPrinterName(printer)} type={printer.printer_type} />
                )}
              </>
            )}
          </Draggable>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}
