import Markdown from 'markdown-to-jsx'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { device } from '../../interfaces/device'

const Fallback = styled.div`
  max-width: 100%;
  text-align: center;
  margin: 0 auto;

  @media ${device.tablet} {
    max-width: 50%;
  }
`

export function ErrorFallback() {
  const { t } = useTranslation()

  return (
    <Fallback>
      <Markdown>
        {t(
          'general-rendering-error',
          `An unexpected error occurred.<br/> Our team has been notified and is already working on it.
          Please consider using another browser or share any details about what happened in the feedback.`
        )}
      </Markdown>
    </Fallback>
  )
}
