import Markdown from 'markdown-to-jsx'
import { useTranslation } from 'react-i18next'
import { Route, Routes } from 'react-router-dom'
import { useTheme } from 'styled-components'

import { SvgIcon } from '../components/common/SvgIcon'
import { InvitationDetailPage } from './InvitationDetailPage'

const ICON_SIZE = 64

export function InvitationPage() {
  return (
    <Routes>
      <Route path=":code" element={<InvitationDetailPage />} />
    </Routes>
  )
}

export function InvitationInvalid() {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <div className="text-center mt-5">
      <h1 className="h4 font-weight-light">{t('team.invitation.heading')}</h1>
      <SvgIcon icon="nokIcon" size={ICON_SIZE} fill={theme.colors.icons.primary} className="mt-4" />
      <h3 className="font-weight-normal lead text-lg mx-2 mt-4 mb-3">
        <Markdown>{t('team.invitation.invalid')}</Markdown>
      </h3>
    </div>
  )
}
