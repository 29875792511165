import { Enemy } from './Enemy'
import { squareSize } from './types/consts'
import { IEnemy } from './types/enemy'

type IProps = {
  enemies: IEnemy[]
  playerTop: number
  playerLeft: number
  onCollision: () => void
}

export function Enemies(props: IProps) {
  return (
    <>
      {props.enemies.map((enemy: IEnemy, index: number) => (
        <Enemy
          size={squareSize}
          enemyLeft={enemy.left}
          enemyTop={enemy.top}
          direction={enemy.direction}
          playerLeft={props.playerLeft}
          playerTop={props.playerTop}
          spool={enemy.spool}
          onCollision={props.onCollision}
          key={index}
        />
      ))}
    </>
  )
}
