import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import {
  FLASHABLE_EXTENSIONS,
  getExtensionFromFileName,
  IFileDetailResponse,
  IFileType,
  isTreeFile
} from '../../../../api/types/file'
import { getLongFileName } from '../../../../helpers/files'
import { DeleteCloudFileAction } from '../../actions/DeleteCloudFileAction'
import { DeleteTreeFileAction } from '../../actions/DeleteTreeFileAction'
import { DownloadFileAction } from '../../actions/DownloadFileAction'
import { FlashFirmwareAction } from '../../actions/FlashFirmwareAction'
import { FlashFirmwareFromCloudAction } from '../../actions/FlashFirmwareFromCloudAction'

type Props = {
  file: IFileDetailResponse
  onDelete?: () => void
  basicOptions?: boolean
  canFlash?: boolean
}

export function NonPrintFileActions({ onDelete, file, basicOptions, canFlash }: Props) {
  const { t } = useTranslation()
  const { teamId } = useParams<{ teamId: string }>()
  const filename = getLongFileName(file)
  const readOnly = file.read_only
  const isFlashableFwFile =
    file.type === IFileType.FIRMWARE && FLASHABLE_EXTENSIONS.indexOf(`.${getExtensionFromFileName(file.name)}`) !== -1

  return (
    <>
      {isFlashableFwFile &&
        (isTreeFile(file) ? (
          <FlashFirmwareAction path={file.path} canFlash={canFlash} />
        ) : (
          <FlashFirmwareFromCloudAction file={file} />
        ))}

      {!basicOptions && (
        <DownloadFileAction
          teamId={file.team_id}
          hash={file.hash}
          name={filename}
          uploaded={file.uploaded}
          size={file.size}
        />
      )}

      {file.path ? (
        <DeleteTreeFileAction
          file={file}
          path={file.path}
          filename={filename}
          onSuccess={onDelete}
          readOnly={readOnly}
        />
      ) : (
        <DeleteCloudFileAction
          file={file}
          label={t('button.delete.from.connect')}
          hash={file.hash}
          teamId={file.team_id}
          name={filename}
          onSuccess={onDelete}
          printerTeamId={Number(teamId)}
        />
      )}
    </>
  )
}
