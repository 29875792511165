import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import { NotFoundErrors } from '../../api/types/errors'
import mk404 from '../../img/MK404_covered.webp'
import mk404Dark from '../../img/MK404_covered_dark.jpg'
import { ErrorButtonHome } from './ErrorButtonHome'

const Page404Styled = styled.div`
  height: 100%;
  margin: 0 auto;
  padding-bottom: 2rem;
  max-width: 850px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 2rem;

  .content {
    flex: 1;
  }
`

const Img404 = styled.img`
  width: 100%;
  max-width: 420px;
`

const H1 = styled.h1`
  font-size: 5rem;
  font-weight: 700;
  margin-left: -0.3125rem;
`

const Content404 = () => {
  const { t } = useTranslation()
  return (
    <>
      <h2>Prusa i3</h2>
      <H1>MK404</H1>
      <p>{t('error.page-404.description')}</p>
      <ErrorButtonHome />
    </>
  )
}

const ContentNotFoundPrinter = () => {
  const { t } = useTranslation()
  return (
    <>
      <h2>Prusa i3</h2>
      <H1>MK404</H1>
      <p>
        {t(
          'error.printer-not-found.description',
          "This printer either doesn't exist or you don't have sufficient permissions to access it"
        )}
      </p>
      <ErrorButtonHome />
    </>
  )
}

const ContentGenericErrorMessage = () => {
  const { t } = useTranslation()
  return (
    <>
      <h2>{t('error.generic-error.title', 'Something went wrong')}</h2>
      <p>
        {t(
          'error.generic-error.description',
          "We're sorry, but something went wrong. You will be redirected to the home page in 10 seconds."
        )}
      </p>
      <ErrorButtonHome />
    </>
  )
}

type Props = {
  errorType?: NotFoundErrors
}

export function NotFoundPage({ errorType }: Props) {
  const theme = useTheme()

  let content
  switch (errorType) {
    case NotFoundErrors.NOT_FOUND_PRINTER:
      content = <ContentNotFoundPrinter />
      break
    case NotFoundErrors.NOT_FOUND_ENDPOINT:
      content = <Content404 />
      break
    default:
      content = <ContentGenericErrorMessage />
  }

  return (
    <Page404Styled className="container">
      <Img404 src={theme.isDark ? mk404Dark : mk404} />
      <div className="content">{content}</div>
    </Page404Styled>
  )
}
