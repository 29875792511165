import { createContext, ReactNode, useContext, useState } from 'react'

type IDownloadedFile = {
  progress: number
  abort: Function
}

type DownloadedFiles = { [key: string]: IDownloadedFile }

const DownloadContext = createContext<{
  downloadedFiles: DownloadedFiles
  setFile: (hash: string, file: IDownloadedFile) => void
  resetFile: (hash: string) => void
}>({
  downloadedFiles: {},
  setFile: () => null,
  resetFile: () => null
})

export function useDownloadContext() {
  const context = useContext(DownloadContext)
  if (!context) {
    throw new Error('<DownloadContextProvider> is missing in component tree!')
  }

  return context
}

export function DownloadContextProvider({ children }: { children: ReactNode }) {
  const [downloadedFiles, setDownloadedFiles] = useState<DownloadedFiles>({})

  const setFile = (hash: string, { progress, abort }: IDownloadedFile) => {
    setDownloadedFiles((prevList) => {
      return { ...prevList, [hash]: { progress, abort } }
    })
  }

  const resetFile = (hash: string) => {
    setDownloadedFiles((prevList) => {
      const restFiles = { ...prevList }
      delete restFiles[hash]
      return restFiles
    })
  }

  return <DownloadContext.Provider value={{ downloadedFiles, setFile, resetFile }}>{children}</DownloadContext.Provider>
}
