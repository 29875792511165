import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useApiClient } from '../../../api/react'
import { IFileDetailResponse } from '../../../api/types/file'
import { useRefetch } from '../../../context/refetchContext'
import { useToast } from '../../../context/toastStore'
import { StorageLocationSlug } from '../../../hooks/storageLocations/useStorageLocationTabs'
import { useIsAdmin } from '../../../hooks/useLoggedUser'
import { useWritableTeams } from '../../../hooks/useTeams'
import { AdaptiveButton } from '../../common/AdaptiveButton/AdaptiveButton'
import { DeleteFileWithPreviewModal } from './DeleteFileWithPreviewModal'

type Props = {
  file: IFileDetailResponse
  name: string
  teamId: number
  hash: string
  onSuccess?: (storageType: StorageLocationSlug) => void
  printerTeamId: number
  label: string
}

export function DeleteCloudFileAction(props: Props) {
  const { teamId, hash, name, onSuccess, printerTeamId, label, file } = props
  const [showModal, setShowModal] = useState(false)
  const { t } = useTranslation()
  const api = useApiClient()
  const toast = useToast()
  const refetch = useRefetch()
  const { writableTeams } = useWritableTeams()
  const isAdmin = useIsAdmin()
  const isInWritableTeam = isAdmin || writableTeams.findIndex((team) => team.id === printerTeamId) !== -1

  const { mutate, isLoading } = useMutation(() => api.app.files.deleteFiles(teamId, { hashes: [hash] }), {
    onSuccess: () => {
      refetch()
      toast.add(t('printer.storage.delete-file.title'), t('printer.storage.delete-file.message', { filename: name }))
      onSuccess?.(StorageLocationSlug.CONNECT)
    }
  })

  return (
    <>
      <AdaptiveButton
        icon="deleteIcon"
        label={label}
        className="delete-file"
        isLoading={isLoading}
        isAvailable
        isDisabled={!isInWritableTeam}
        disabledTooltip={t('printer.tooltip.rights')}
        trigger={() => setShowModal(true)}
      />

      {showModal && <DeleteFileWithPreviewModal file={file} onClose={() => setShowModal(false)} onConfirm={mutate} />}
    </>
  )
}
