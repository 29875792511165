import { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { config } from '../config'
import { useInterval } from '../helpers/useInterval'

const Container = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  top: 30%;
  transform: translate(0, -50%);
  text-align: center;

  h2 {
    margin: 20px 0;
  }
`

const Center = styled.div`
  display: flex;
  justify-content: center;
`

export function ExceptionPage() {
  const [state, setState] = useState<'scan' | 'wait' | 'redirect'>('scan')
  const { t } = useTranslation()

  const homeClick = () => {
    setState('redirect')
  }

  useInterval(() => {
    const form = document.getElementsByTagName('form').item(0)

    switch (state) {
      case 'scan':
        if (form) setState('wait')
        break

      case 'wait':
        if (!form) setState('redirect')
        break
    }
  }, 1000)

  return (
    <Container>
      {state === 'scan' && (
        // TODO needs to be refactored
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {config.isProductionBuild ? (
            <>
              <Center>
                <Spinner animation="border" />
              </Center>
              <h2>{t('something-went-wrong')}</h2>
            </>
          ) : (
            <>
              <h2>{t('something-went-wrong')}</h2>
              <button type="button" onClick={homeClick} className="btn btn-outline-dark">
                {t('back-home', 'Back Home')}
              </button>
            </>
          )}
        </>
      )}

      {(state === 'wait' || state === 'redirect') && (
        <Center>
          <Spinner animation="border" />
        </Center>
      )}

      {state === 'redirect' && (
        <>
          {config.isProductionBuild && <h2>redirecting to home</h2>}
          <Redirecter to="/" after={3000} />
        </>
      )}
    </Container>
  )
}

function Redirecter(props: { after: number; to: string }) {
  useEffect(() => {
    const timeout = setTimeout(() => window.location.replace(props.to), props.after)

    return () => {
      clearTimeout(timeout)
    }
  }, []) // eslint-disable-line

  return null
}
