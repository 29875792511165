import { useMutation } from '@tanstack/react-query'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useApiClient } from '../../../api/react'
import { Material } from '../../../api/types/filament'
import { IEditFileAttrs, IUploadQueueFile } from '../../../api/types/file'
import { IAllowedFunctionalities, IPrinter, IPrinterSimpleView } from '../../../api/types/printer'
import { IConnectState } from '../../../api/types/state'
import { useCanControl } from '../../../context/permissionsStore'
import { useToast } from '../../../context/toastStore'
import { useErrorHandler } from '../../../hooks/errors/useErrorHandler'
import { getWholeFilePath } from '../../../hooks/storageLocations/useStorageLocationTabs'
import { useLowMemory } from '../../../hooks/useLowMemory'
import { useMismatchedFilamentModal } from '../../../hooks/useMismatchedFilamentModal'
import { usePrinterInState } from '../../../hooks/usePrinterInState'
import { useIsSla } from '../../../hooks/usePrinterType'
import { AdaptiveButton } from '../../common/AdaptiveButton/AdaptiveButton'
import { ConfirmModal } from '../../common/ConfirmModal'
import { ErrorModal } from '../../common/ErrorModal'
import { RenameFileModal } from '../storage/RenameFileModal'
import { ConfirmPrintModal } from './ConfirmPrintModal'

type Props = {
  withConfirm?: boolean
  teamId: number
  hash: string
  name: string
  printerStorage: string
  uploaded?: number
  filamentType?: Material
  printer: IPrinterSimpleView
  label?: string
  onSuccess?: () => void
}

export function StartCopyAndPrintAction({
  withConfirm,
  teamId,
  hash,
  name,
  printerStorage,
  uploaded,
  printer,
  label,
  filamentType,
  onSuccess
}: Props) {
  const isSla = useIsSla(printer.printer_type)
  const { t } = useTranslation()
  const toast = useToast()
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [renameModal, setRenameModal] = useState({ instructions: '', displayed: false })
  const { triggerFilamentModal, filamentModal, resetFilamentModal, differentFilaments } = useMismatchedFilamentModal(
    printer.filament?.material,
    filamentType
  )
  const api = useApiClient()
  const errorHandler = useErrorHandler()
  const [modal, setModal] = useState({ title: '', body: '', displayed: false })
  const printerInState = usePrinterInState(printer.connect_state)
  const canControl = useCanControl(printer.team_id)
  const cannotUpload = useLowMemory(printer.connect_state, printer.uuid)
  const isAvailable = ![
    IConnectState.PRINTING,
    IConnectState.PAUSED,
    IConnectState.OFFLINE,
    IConnectState.UNKNOWN
  ].includes(printer.connect_state)
  const isDisabled = !uploaded || !isAvailable || !canControl || cannotUpload

  let disabledTooltip = ''
  if (!canControl) {
    disabledTooltip = t('printer.tooltip.rights')
  } else if (!isAvailable) {
    disabledTooltip = printerInState
  } else if (cannotUpload) {
    disabledTooltip = t('printer.file.upload.disabled-during-printing')
  } else {
    disabledTooltip = t('gcode-meta.download.not-available-in-connect')
  }

  const { mutate, isLoading } = useMutation(
    (fileInfo: IUploadQueueFile) => api.app.transfers.addToPrinterDownloadQueue(printer.uuid, fileInfo),
    {
      onSuccess: () => {
        onSuccess?.()
        toast.add(t('printer.actions.start-print.success.title'), t('printer.actions.start-print.success.body'))
      },
      onError: (error: any) => {
        if (error && error.response) {
          const { title, message } = errorHandler(error.response, error)

          if (
            error.response.status === 400 &&
            (error.code === 'INVALID_FILENAME_LENGTH' || error.code === 'INVALID_FILENAME_CHARS')
          ) {
            setRenameModal({
              displayed: true,
              instructions:
                error.code === 'INVALID_FILENAME_LENGTH'
                  ? t('file.rename.instructions.reason-long', 'The file has too long filename, please rename it first.')
                  : t(
                      'file.rename.instructions.reason-invalid',
                      'The file name contains invalid characters, please rename it first.'
                    )
            })
          } else {
            setShowConfirmModal(false)
            setModal({
              title,
              body: error.path ? (
                <>
                  {message} (
                  <Link style={{ wordWrap: 'break-word' }} to={getWholeFilePath(error.path, printer as IPrinter, hash)}>
                    {error.path}
                  </Link>
                  )
                </>
              ) : (
                message
              ),
              displayed: true
            })
          }
        }
      }
    }
  )

  const copyAndPrint = useCallback(() => {
    mutate(
      {
        team_id: teamId,
        hash,
        path: `${printerStorage}/${name}`,
        to_print: true
      },
      {
        onSuccess: () => {
          setShowConfirmModal(false)
        }
      }
    )
  }, [mutate, teamId, hash, name, printerStorage])

  const trigger = useCallback(() => {
    if (differentFilaments) {
      return triggerFilamentModal()
    }
    if (withConfirm) {
      setShowConfirmModal(true)
    } else {
      copyAndPrint()
    }
  }, [copyAndPrint, differentFilaments, triggerFilamentModal, withConfirm])

  return (
    <>
      <AdaptiveButton
        icon="startIcon"
        trigger={trigger}
        label={label || t('print.add-to-download-queue')}
        isLoading={isLoading}
        isAvailable
        isDisabled={isDisabled}
        disabledTooltip={disabledTooltip}
      />

      {filamentModal.show && (
        <ConfirmModal
          title={t('printer.actions.start-print.confirm.title.wrong-filament')}
          body={t('printer.actions.start-print.confirm.body.wrong-filament')}
          onCancel={() => {
            resetFilamentModal()
          }}
          onConfirm={() => {
            copyAndPrint()
            resetFilamentModal()
          }}
        />
      )}

      {showConfirmModal && (
        <ConfirmPrintModal
          isLoading={isLoading}
          icon="startIcon"
          isSla={isSla}
          onCancel={() => {
            resetFilamentModal()
            setShowConfirmModal(false)
          }}
          onConfirm={copyAndPrint}
        />
      )}
      <ErrorModal
        show={modal.displayed}
        title={modal.title}
        body={modal.body}
        onHide={() => setModal({ ...modal, displayed: false })}
      />
      {renameModal.displayed && (
        <RenameFileModal
          hash={hash}
          teamId={teamId}
          name={name} // TODO rename - what if was renamed from long filename to name with invalid chars?
          instructions={renameModal.instructions}
          maxFilename={printer.max_filename}
          isDev={printer.is_dev}
          isSla={isSla}
          binarySupported={printer.allowed_functionalities?.includes(IAllowedFunctionalities.BGCODE)}
          path={`${printerStorage}/${name}`}
          onSuccess={(params: IEditFileAttrs) => {
            mutate({
              team_id: teamId,
              hash,
              path: `${printerStorage}/${params.name}`,
              to_print: true
            })
            setRenameModal({ ...renameModal, displayed: false })
          }}
          onCancel={() => setRenameModal({ ...renameModal, displayed: false })}
          isFromConnect
        />
      )}
    </>
  )
}
