import { useCallback } from 'react'

import { IPrinterSimpleView } from '../../../api/types/printer'
import { useIsFdm } from '../../../hooks/usePrinterType'
import { SimpleActionsRenderer } from '../../common/AdaptiveButton/SimpleActionsRenderer'
import { PausePrintAction, ResumePrintAction, StopPrintAction } from '../actions/PrintControlActions'

type Props = {
  update?: (timestamp: number) => void
  printer: IPrinterSimpleView
}

export function CurrentFileControls({ update, printer }: Props) {
  const onSuccess = useCallback(() => update?.(new Date().getTime()), [update])
  const isFdm = useIsFdm(printer.printer_type)

  return (
    <SimpleActionsRenderer>
      <ResumePrintAction withConfirm onSuccess={onSuccess} printer={printer} />
      {isFdm && <PausePrintAction withConfirm onSuccess={onSuccess} printer={printer} />}
      <StopPrintAction withConfirm onSuccess={onSuccess} printer={printer} />
    </SimpleActionsRenderer>
  )
}
