import { useMutation } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useApiClient } from '../../../api/react'
import { IGroupPattern, IGroupSize } from '../../../api/types/groups'
import { useLoggedUser } from '../../../hooks/useLoggedUser'
import { useTeamName } from '../../../hooks/useTeamName'
import { useWritableTeams } from '../../../hooks/useTeams'
import useServiceResponseHandler from '../../../services/useServiceResponseHandler'
import { Button } from '../../common/Button'
import { SvgIcon } from '../../common/SvgIcon'
import { disabledFilter } from '../../helpers/styled'
import { TooltipIcon } from '../../helpers/TooltipIcon'
import { gridToGroupSize, groupSizeToGrid } from './group'
import { DimensionSelector } from './selectors/dimensionSelector'

const Dimensions = styled.div`
  padding-top: 6px;
  display: flex;
`

const FormControl = styled(Form.Control)`
  &::placeholder {
    font-style: italic;
  }
`

export const Img = styled.div<{ $highlighted: boolean }>`
  cursor: pointer;
  ${(props) => disabledFilter(!props.$highlighted)}
  transition: 0.15s ease-in-out;

  & + & {
    margin-left: 0.25rem;
  }
`

export const ICON_SIZE = 28

const Separator = styled.div`
  flex-grow: 1;
`

const BreakableText = styled.span`
  white-space: break-spaces;
`

type Props = {
  update: (timestamp: number) => void
}

export function AddGroupForm(props: Props) {
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [teamId, setTeamId] = useState(0)
  const [size, setSize] = useState<IGroupSize>()
  const { displaySuccess, displayError } = useServiceResponseHandler()

  const [isValid, setIsValid] = useState(false)
  const [warning, setWarning] = useState('')

  const user = useLoggedUser()
  const isAdmin = !!user.is_admin
  const { writableTeams } = useWritableTeams()

  const { t } = useTranslation()
  const { getTeamName } = useTeamName()
  const api = useApiClient()
  const { mutate } = useMutation(
    () =>
      api.app.printerGroups.createGroup({
        team_id: teamId,
        name,
        description,
        farm: size?.pattern === IGroupPattern.SHIFTED,
        size
      }),
    {
      onSuccess: () => {
        displaySuccess(t('service.printer.group.created.title'), t('service.printer.group.created.body', { name }))
      },
      onError: (error: Error) => {
        displayError(error)
      },
      onSettled: () => {
        props.update(new Date().getTime())
        setName('')
        setDescription('')
        setTeamId(user.default_team_id)
        setSize(undefined)
      }
    }
  )

  const nameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.currentTarget.value)
  }

  const descriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(e.currentTarget.value)
  }

  const teamChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const parsedTeamId = Number.parseInt(e.currentTarget.value, 10)
    setTeamId(!Number.isNaN(parsedTeamId) && parsedTeamId > 0 ? parsedTeamId : 0)
  }

  const handleForm = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()

    if (name === '') {
      setWarning(t('printer.group.form.name-warning'))
      setIsValid(false)
      return
    }

    mutate()
  }

  const selectGridDimension = () => {
    setSize({ x: size?.x || 1, y: 1, z: size?.z || 1, pattern: IGroupPattern.GRID })
  }

  const selectShiftedGridDimension = () => {
    setSize({ x: size?.x || 1, y: 1, z: size?.z || 1, pattern: IGroupPattern.SHIFTED })
  }

  // login is non blocking call, so this might render before we get the user's data
  useEffect(() => {
    if (user) {
      setTeamId(user.default_team_id)
    }
  }, [user])

  // reset validation
  useEffect(() => {
    setIsValid(true)
    setWarning('')
  }, [name])

  return (
    <Row>
      <Col>
        <Form onSubmit={handleForm}>
          <Form.Row className="align-items-center">
            <Form.Group as={Col} md="2" controlId="groupName">
              <Form.Label>{t('printer.group.form.name-label')}</Form.Label>
              <FormControl
                value={name}
                onChange={nameChange}
                placeholder={t('printer.group.form.name-placeholder')}
                type="text"
              />
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="groupDescription">
              <Form.Label>{t('printer.group.form.description-label')}</Form.Label>
              <FormControl
                value={description}
                onChange={descriptionChange}
                placeholder={t('printer.group.form.description-placeholder')}
                type="text"
              />
            </Form.Group>

            <Form.Group as={Col} md="2" controlId="teamSelect">
              <Form.Label>{t('printer.group.form.team-label')}</Form.Label>
              <Form.Control as="select" onChange={teamChange} value={teamId.toString()}>
                <option value={0} hidden />
                {writableTeams.map((team) => (
                  <option key={team.id} value={team.id}>
                    {getTeamName(team)}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col} md="2" style={{ paddingLeft: 15 }}>
              <Form.Label>{t('printer.group.form.dimensions-label')}</Form.Label>
              <Dimensions>
                <Img $highlighted={size === undefined} onClick={() => setSize(undefined)} draggable={false}>
                  <SvgIcon icon="gridVirtualIcon" size={ICON_SIZE} title={t('printer.group.pattern.virtual')} />
                </Img>
                <DimensionSelector
                  pattern={IGroupPattern.GRID}
                  id="gds"
                  selectedSize={groupSizeToGrid(size)}
                  highlighted={size?.pattern === IGroupPattern.GRID}
                  showSizePopup={size?.pattern === IGroupPattern.GRID}
                  onSelected={(grid) => setSize(gridToGroupSize(grid, IGroupPattern.GRID))}
                  onClick={() => selectGridDimension()}
                />
                {(isAdmin || AFS_ENABLED) && (
                  <DimensionSelector
                    pattern={IGroupPattern.SHIFTED}
                    id="sgds"
                    selectedSize={{ col: size?.x || 1, row: size?.z || 1 }}
                    highlighted={size?.pattern === IGroupPattern.SHIFTED}
                    showSizePopup={size?.pattern === IGroupPattern.SHIFTED}
                    onSelected={(grid) => setSize(gridToGroupSize(grid, IGroupPattern.SHIFTED))}
                    onClick={() => selectShiftedGridDimension()}
                  />
                )}
                <Separator />
                {warning && <TooltipIcon variant="warning" tooltip={warning} tooltipPlacement="bottom" />}
              </Dimensions>
            </Form.Group>

            <Form.Group as={Col} md="2">
              <Form.Label>&nbsp;</Form.Label>
              <Button type="submit" block disabled={!isValid}>
                <SvgIcon icon="plusIcon" size={18} />
                <BreakableText>{t('printer.group.add')}</BreakableText>
              </Button>
            </Form.Group>
          </Form.Row>
        </Form>
      </Col>
    </Row>
  )
}
