import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { device } from '../../../interfaces/device'
import { Button } from '../../common/Button'

const ModalContent = styled.div`
  display: grid;
  gap: 2rem;
  align-items: top;

  @media ${device.sm} {
    grid-template-columns: 1fr 1fr;
  }
`

const FullWidthButton = styled(Button)`
  width: 100%;
  margin-bottom: 10px;
`

const Column = styled.div``

type Props = {
  doUpload: (toQueue: boolean) => void
  setShowUploadModal: (display: boolean) => void
}

export function FileUploadModal(props: Props) {
  const { t } = useTranslation()
  const { doUpload, setShowUploadModal } = props

  return (
    <Modal show onHide={() => setShowUploadModal(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t('file.upload.process.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ModalContent>
          <Column>
            <FullWidthButton type="submit" onClick={() => doUpload(true)}>
              {t('file.upload.process.button.add-to-queue')}
            </FullWidthButton>
            <p>{t('file.upload.process.body.add-to-queue')}</p>
          </Column>
          <Column>
            <FullWidthButton type="submit" onClick={() => doUpload(false)}>
              {t('file.upload.process.button.copy-to-printer')}
            </FullWidthButton>
            <p>{t('file.upload.process.body.copy-to-printer')}</p>
          </Column>
        </ModalContent>
      </Modal.Body>
    </Modal>
  )
}
