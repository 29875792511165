import { useGroups } from './useGroups'

export function useGroupFilterData() {
  // TODO temporary workaround for missing pagination
  const { data, isLoading } = useGroups()

  return {
    groupList: data?.groups || [],
    isLoading
  }
}
