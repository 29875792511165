import { useCallback, useMemo } from 'react'

import { useAuthState } from '../context/authStore'
import { useIsAdmin } from './useLoggedUser'

export function useTeams() {
  const authState = useAuthState()
  const { teams } = authState.user

  return useMemo(() => {
    const sorted = (teams || []).sort((team1, team2) => team1.name.localeCompare(team2.name))
    return sorted
  }, [teams])
}

export function useWritableTeams() {
  const teams = useTeams()
  // Admin can write to any team
  const isAdmin = useIsAdmin()

  const writableTeams = useMemo(() => (isAdmin ? teams : teams.filter((team) => team.rights_rw)), [teams, isAdmin])

  const isTeamWritable = useCallback(
    (teamId: number) => (isAdmin ? true : writableTeams.findIndex((team) => team.id === teamId && team.rights_rw) >= 0),
    [writableTeams, isAdmin]
  )

  return { writableTeams, isTeamWritable }
}
