import {
  closestCenter,
  DndContext,
  DndContextProps,
  KeyboardSensor,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable'

import { CustomMouseSensor } from './customSensors'

export function CustomDndContext({ children, ...props }: DndContextProps) {
  const sensors = useSensors(
    useSensor(CustomMouseSensor, {
      activationConstraint: {
        distance: 5
      }
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 8
      }
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} {...props}>
      {children}
    </DndContext>
  )
}
