import { stringify } from 'query-string'

import {
  IAddPrinterRequestParams,
  IEditPrinter,
  IPrinter,
  IPrinterAvailableFirmwaresQuery,
  IPrinterAvailableFirmwaresResponse,
  IPrinterCode,
  IPrinterDateRangeQuery,
  IPrinterFilament,
  IPrinterJobsEndStatesRes,
  IPrinterLastStableFirmware,
  IPrinterMaterialConsumpRes,
  IPrinterOverallPrintTimeRes,
  IPrinterParticularFirmwareByVersionQuery,
  IPrinterParticularVersionFirmware,
  IPrinterPlannedTasksFrequencyRes,
  IPrinterSimpleView,
  IPrintersQuery,
  IPrintersResponse,
  IPrinterTypes,
  IRegistrationPrinter,
  IStoragesResponse,
  PrinterUuid
} from '../../types/printer'
import { NoContent204, RequestFactory } from '../../types/sdk'

export function createModule(rf: RequestFactory) {
  return {
    getTypes: () => rf.get<{ printer_types: IPrinterTypes }>('/app/printer-types'),

    addPrinter: (printer: IAddPrinterRequestParams) => rf.post<IPrinterSimpleView>('/app/printers', printer),

    registerPrinter: (code: IPrinterCode) => rf.get<IRegistrationPrinter>(`/app/registrations/${code}`),

    getPrinter: (printerUuid: PrinterUuid) => rf.get<IPrinter>(`/app/printers/${printerUuid}`),

    getPrinterStorageLocations: (printerUuid: PrinterUuid) =>
      rf.get<IStoragesResponse>(`/app/printers/${printerUuid}/storages`),

    getPrinterMaterialConsumption: (uuid: PrinterUuid, query: Partial<IPrinterDateRangeQuery> = {}) =>
      rf.get<IPrinterMaterialConsumpRes>(`/app/stats/printers/${uuid}/material_quantity?${stringify(query)}`),

    getPrinterPlannedTasksFrequency: (uuid: PrinterUuid, query: Partial<IPrinterDateRangeQuery> = {}) =>
      rf.get<IPrinterPlannedTasksFrequencyRes>(`/app/stats/printers/${uuid}/planned_tasks?${stringify(query)}`),

    getPrinterOverallPrintingTime: (uuid: PrinterUuid, query: Partial<IPrinterDateRangeQuery> = {}) =>
      rf.get<IPrinterOverallPrintTimeRes>(`/app/stats/printers/${uuid}/printing_not_printing?${stringify(query)}`),

    getPrinterJobsEndStates: (uuid: PrinterUuid, query: Partial<IPrinterDateRangeQuery> = {}) =>
      rf.get<IPrinterJobsEndStatesRes>(`/app/stats/printers/${uuid}/jobs_success?${stringify(query)}`),

    getPrinterLastStableFirmware: (uuid: PrinterUuid, query: { hash?: string }) =>
      rf.get<IPrinterLastStableFirmware>(`/app/printers/${uuid}/firmware?${stringify(query)}`),

    getPrinterFirmwareByVersion: (uuid: PrinterUuid, query: IPrinterParticularFirmwareByVersionQuery) =>
      rf.get<IPrinterParticularVersionFirmware>(`/app/printers/${uuid}/firmware?${stringify(query)}`),

    getAvailableFirmwares: (query: IPrinterAvailableFirmwaresQuery) =>
      rf.get<IPrinterAvailableFirmwaresResponse>(`/app/firmwares?${stringify(query)}`),

    editPrinter: (printerUuid: PrinterUuid, data: IEditPrinter) =>
      rf.patch<IPrinter>(`/app/printers/${printerUuid}`, data),

    removePrinter: (printerUuid: PrinterUuid) => rf.delete(`/app/printers/${printerUuid}`),

    getPrinters: (query: IPrintersQuery = {}) => rf.get<IPrintersResponse>(`/app/printers?${stringify(query)}`),

    getSlicerPrinters: () => rf.get<IPrintersResponse>(`/slicer/printers`),

    addFilament: (printerUuid: PrinterUuid, printerFilament: IPrinterFilament) =>
      rf.post<NoContent204>(`/app/printers/${printerUuid}/filament`, printerFilament),

    updateFilament: (printerUuid: PrinterUuid, printerFilament: IPrinterFilament) =>
      rf.put<NoContent204>(`/app/printers/${printerUuid}/filament`, printerFilament),

    removeFilament: (printerUuid: PrinterUuid) => rf.delete<NoContent204>(`/app/printers/${printerUuid}/filament`),

    unlockPrinterFromHarvester: (printerUuid: PrinterUuid) => rf.post<IPrinter>(`/app/printers/${printerUuid}/unlock`),

    deletePrinterFingerprint: (printerUuid: PrinterUuid) =>
      rf.delete<NoContent204>(`/app/printers/${printerUuid}/fingerprint`), // update serial number

    unsetPrinterFlag: (printerUuid: PrinterUuid, flagName: string) =>
      rf.delete<NoContent204>(`/app/printers/${printerUuid}/flags/${flagName}`) // fingerprint
  }
}
