import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { Container } from 'react-bootstrap'
import styled from 'styled-components'

import { useApiClient } from '../../../api/react'
import { IPrintersQuery } from '../../../api/types/printer'
import { intervals } from '../../../config'
import { parseSortBy, serializeSortBy } from '../../../helpers/sorting'
import { useBreakpoint } from '../../../helpers/useBreakpoint'
import { useQueryParams } from '../../../hooks/useQueryParams'
import { usePrintersService } from '../../../services/usePrintersService'
import { PaceProgress, shouldShowTabularPace } from '../../common/PaceProgress'
import { Pager } from '../../common/Pager'
import { usePrintersQueryState } from '../hooks/usePrintersQueryState'
import { NoPrinters } from '../NoPrinters'
import { QueryKeys } from '../utils'
import { CardItem } from './CardItem'
import { Cards } from './CardItem.styled'
import { PrinterListItem } from './PrinterListItem'

export const PrinterList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
`

export const ListItemContainer = styled.div`
  border-bottom: 1px solid var(--border-secondary);
  padding-bottom: 0.5rem;

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
`

export function ListView() {
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(50)
  const params = useQueryParams({ decode: false }) // custom decoding of sort_by param
  const { teamId, groupId, states } = usePrintersQueryState()
  const sortItems = parseSortBy(params.sort_by ? String(params.sort_by) : '')
  const isLgOrLarge = useBreakpoint('xl')
  const api = useApiClient()
  const { getLatestThumbnail } = api.app.cameras

  const printersQuery: IPrintersQuery = {
    limit,
    group_id: groupId,
    team_id: teamId,
    offset,
    state_include: states,
    sort_by: serializeSortBy(sortItems)
  }

  const printersQueryResult = usePrintersService(printersQuery, {
    refetchInterval: intervals.printersPolling,
    keepPreviousData: true
  })
  const { data } = printersQueryResult

  const camerasQuery = useQuery([QueryKeys.CAMERA_LIST_QUERY, 'all'], () => api.app.cameras.getAllCameras())

  if (data?.printers.length === 0) {
    return <NoPrinters />
  }

  const renderContent = () => {
    if (isLgOrLarge) {
      return (
        <Container>
          <PrinterList>
            {data &&
              data.printers.map((printer) => {
                const printerCameras = camerasQuery.data?.cameras
                  .filter((camera) => camera.printer_uuid === printer.uuid)
                  .filter((camera) => camera.registered)
                const camera = printerCameras && printerCameras.length > 0 ? printerCameras[0] : undefined
                const thumbnail = camera ? getLatestThumbnail(camera.id) : undefined

                return (
                  <ListItemContainer key={printer.uuid}>
                    <PrinterListItem printer={printer} thumbnail={thumbnail} />
                  </ListItemContainer>
                )
              })}
          </PrinterList>
        </Container>
      )
    }

    return (
      <Cards>
        {data?.printers.map((printer, i) => (
          <CardItem key={i} printer={printer} />
        ))}
      </Cards>
    )
  }

  return (
    <>
      {shouldShowTabularPace(printersQueryResult) && <PaceProgress />}
      {renderContent()}
      {data && (
        <Container className="mb-2">
          <Pager pager={data.pager} setOffset={setOffset} setLimit={setLimit} />
        </Container>
      )}
    </>
  )
}
