import { useQuery } from '@tanstack/react-query'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useApiClient } from '../../../api/react'
import { isActiveState } from '../../../api/types/state'
import { usePrinter } from '../../../context/printerContext'
import { formatTemperature } from '../../../helpers/formatters'
import { useSupportedCommandsByUuid } from '../../../hooks/commands/useSupportedCommands'
import { useErrorHandler } from '../../../hooks/errors/useErrorHandler'
import { useLoggedUserPreferences } from '../../../hooks/useLoggedUser'
import { usePrinterType } from '../../../hooks/usePrinterType'
import { Alert, Severity } from '../../bootstrap/Alert'
import { PaceProgress } from '../../common/PaceProgress'
import { TemperatureUnits } from '../../common/Temperature'
import { TooltipIcon } from '../../helpers/TooltipIcon'
import { ControlBed } from './ControlBed'
import { ControlExtruder } from './ControlExtruder'
import * as S from './ControlPanel.styled'
import { ControlXY } from './ControlXY'
import { ControlZ } from './ControlZ'
import { DisableMotorsButton } from './DisableMotorsButton'
import { HomeXYButton } from './HomeXYButton'
import { HomeZButton } from './HomeZButton'
import { Axis, commands, useAxisOnChangeHandler } from './hooks/useAxisChangeHandler'
import { ResetPrinterButton } from './ResetPrinterButton'
import { SpeedSlider } from './SpeedSlider'
import { Temperatures } from './Temperatures'
import { ZAxisSlider } from './ZAxisSlider'

const CoordinatesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 120px;
  gap: 0.5rem;
`

const usePrinterFeedrates = (uuid: string) => {
  const supportedCommands = useSupportedCommandsByUuid(uuid)?.commands
  const moveZcommand = supportedCommands?.find((el) => el.command === commands[Axis.Z])
  const moveXYcommand = supportedCommands?.find(
    (el) => el.command === commands[Axis.X] || el.command === commands[Axis.Y]
  )
  const feedrateZ = moveZcommand?.args?.find((arg) => arg.name === 'feedrate')?.default as number
  const feedrateXY = moveXYcommand?.args?.find((arg) => arg.name === 'feedrate')?.default as number
  return {
    feedrateZ,
    feedrateXY
  }
}

export function AxisZController({ withSlider }: { withSlider?: boolean }) {
  const { printer } = usePrinter()
  const errorHandler = useErrorHandler()
  const printerType = usePrinterType(printer.printer_type)
  const { feedrateZ } = usePrinterFeedrates(printer.uuid)
  const [axisZ, setAxisZ] = useState(printer.axis_z || 0)
  const [prevAxisZ, setPrevAxisZ] = useState(printer.axis_z || 0)
  const ref = useRef(printer.axis_z || 0)

  const zHandler = useAxisOnChangeHandler(
    Axis.Z,
    feedrateZ,
    () => setPrevAxisZ(ref.current),
    (error: any) => {
      setAxisZ(prevAxisZ)
      errorHandler(error.response, error)
    }
  )
  // TODO slider disabling when controlling from printer badges
  const isZAxisDisabled = !zHandler.isAvailable || zHandler.isLoading || (printer.axis_z ?? null) === null

  // Sync with backend, e.g. when resumed print
  useEffect(() => {
    setAxisZ(printer.axis_z || 0)
  }, [printer.axis_z])

  const handleChangeEnd = (z: number) => {
    ref.current = z
    zHandler.onChange({ z, absolute: true })
    setAxisZ(z)
  }

  return (
    <>
      <ControlZ onChange={handleChangeEnd} isDisabled={isZAxisDisabled} />
      {withSlider && (
        <ZAxisSlider
          positionParams={printerType?.parameters.position_z}
          disabled={isZAxisDisabled}
          value={axisZ}
          onChangeEnd={handleChangeEnd}
        />
      )}
    </>
  )
}

export function ControlPanel() {
  const { printer } = usePrinter()
  const { t } = useTranslation()

  const api = useApiClient()
  const { data, isLoading } = useQuery(['/printer-types'], () => api.app.printers.getTypes())
  const printer_types = data?.printer_types || []
  const printerType = printer_types.find((printerType) => printerType.id === printer.printer_type)
  const { feedrateXY } = usePrinterFeedrates(printer.uuid)
  const units = useLoggedUserPreferences('units')
  const tempSign = units.temp === TemperatureUnits.CELSIUS ? TemperatureUnits.CELSIUS : TemperatureUnits.FAHRENHEIT

  // Offline
  if (!isActiveState(printer.connect_state)) {
    return <Alert severity={Severity.INFO}>{t('printer.control.offline')}</Alert>
  }

  // Unknown type
  if (!printerType && !isLoading) {
    return <Alert severity={Severity.INFO}>{t('printer.control.unsupported')}</Alert>
  }

  // Not loaded yet
  if (!printerType) {
    return <PaceProgress />
  }

  const extruderTempIsTooLow =
    printer.temp?.temp_nozzle && printerType.parameters.min_temp_nozzle_e
      ? printer.temp.temp_nozzle < printerType.parameters.min_temp_nozzle_e
      : false

  return (
    <>
      <S.Section className="bed-col">
        <S.SectionTitle>{t('printer.control.coordinates.title')}</S.SectionTitle>

        <CoordinatesContainer>
          {/* TODO group mutation together and have a single loading state */}
          <div>
            <div className="mb-2">
              <ControlXY feedrate={feedrateXY} />
            </div>
            <ControlBed feedrate={feedrateXY} />
          </div>

          <div className="d-flex flex-column">
            <AxisZController withSlider />
          </div>
          <div>
            <div className="d-flex gap-2 flex-wrap">
              <HomeXYButton />
              <DisableMotorsButton />
              <ResetPrinterButton />
            </div>
            <div className="mt-2">
              <em>{t('printer.control.coordinates.bed-hint')}</em>
            </div>
          </div>
          <div className="mx-auto">
            <HomeZButton />
          </div>
        </CoordinatesContainer>
      </S.Section>
      <div className="params-col">
        <S.Section>
          <S.SectionTitle>{t('printer.control.temperatures.title')}</S.SectionTitle>
          <Temperatures />
        </S.Section>

        <S.Section>
          <S.SectionTitle>{t('printer.control.speed.title')}</S.SectionTitle>
          <SpeedSlider
            type="PRINT"
            printerValue={printer.speed || 100}
            min={printerType.parameters?.print_speed?.min}
            label={t('printer.control.speed.current')}
          />
        </S.Section>

        <S.Section>
          <S.SectionTitle>{t('printer.control.flow.title')}</S.SectionTitle>
          <SpeedSlider
            type="FLOW"
            printerValue={printer.flow || 100}
            min={printerType.parameters?.print_flow?.min}
            label={t('printer.control.flow.current')}
          />
        </S.Section>

        <S.Section>
          <S.SectionTitle className="d-flex align-items-center">
            <div>{t('printer.control.extruder.title')}</div>
            {extruderTempIsTooLow && (
              <TooltipIcon
                variant="warning"
                tooltipPlacement="bottom"
                tooltip={t('printer.control.retract-extrude.disabled.tooltip', {
                  temperature: formatTemperature(tempSign, Number(printerType.parameters?.min_temp_nozzle_e))
                })}
              />
            )}
          </S.SectionTitle>

          <ControlExtruder printer={printer} tempIsTooLow={extruderTempIsTooLow} />
        </S.Section>
      </div>
    </>
  )
}
