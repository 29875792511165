import { useQueryClient } from '@tanstack/react-query'
import { memo, useEffect, useState } from 'react'
import { ErrorCode, useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useApiClient } from '../../../api/react'
import {
  FLASHABLE_EXTENSIONS,
  getExtensionFromFileName,
  getPrintFileExtensions,
  IEditFileAttrs,
  isFirmwareFileByName
} from '../../../api/types/file'
import { IPrinterSimpleView, PrinterUuid } from '../../../api/types/printer'
import { IUploadInfo } from '../../../api/types/transfer'
import { useAuthState } from '../../../context/authStore'
import { useToast } from '../../../context/toastStore'
import { useUploadContext } from '../../../context/uploadContext'
import { formatSize } from '../../../helpers/formatters'
import { useErrorTypeTranslations } from '../../../hooks/errors/errorTypes'
import { useErrorHandler } from '../../../hooks/errors/useErrorHandler'
import { dispatchFwUploadedEvent } from '../../../hooks/useFlashFirmware'
import { useMaxUploadSize } from '../../../services/useMaxUploadSize'
import { ErrorModal } from '../../common/ErrorModal'
import { SvgIcon } from '../../common/SvgIcon'
import { ConfirmFirmwareFlashModal } from './ConfirmFirmwareFlashModal'
import * as S from './DropArea.styled'
import { useConnectUploads } from './FileManager/useCloudFiles'
import { FileUploadModal } from './FileUploadModal'
import { RenameFileModal } from './RenameFileModal'

const FullScreenDropzone = styled.div`
  background: gray;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.2);
  visibility: hidden;
  border: 5px dashed var(--color-primary);
`

const UploadIcon = styled(SvgIcon)`
  position: fixed;
  top: 50%;
  left: 50%;
  pointer-events: none;
`

type Props = {
  printerUuid?: PrinterUuid
  uniqueContextId?: string
  path: string
  downloadToPrinter?: boolean
  free: number
  teamId: number
  isFromConnect: boolean
  height?: string
  disabled?: boolean
  title?: string
  maxFilename?: number
  isDev?: boolean
  isSla?: boolean
  binarySupported?: boolean
  canFlash?: boolean
  printer?: IPrinterSimpleView
}

type IRenameModal = { instructions: string; displayed: boolean }

type IFileModified = {
  modal: IRenameModal
  file: File
  toQueue: boolean
}

function useErrorTranslations() {
  const { t } = useTranslation()
  const maxUploadSize = formatSize(useMaxUploadSize())

  const errorTitle: { [key in ErrorCode]: string } = {
    [ErrorCode.FileInvalidType]: t('droparea.file-invalid-type.title'),
    [ErrorCode.FileTooLarge]: t('droparea.file-too-large.title'),
    [ErrorCode.FileTooSmall]: t('droparea.file-too-small.title'),
    [ErrorCode.TooManyFiles]: t('droparea.too-many-files.title')
  }

  const errorBody: { [key in ErrorCode]: string } = {
    [ErrorCode.FileInvalidType]: t('droparea.file-invalid-type.body'),
    [ErrorCode.FileTooLarge]: t('droparea.file-too-large.body', { maxUploadSize }),
    [ErrorCode.FileTooSmall]: t('droparea.file-too-small.body'),
    [ErrorCode.TooManyFiles]: t('droparea.too-many-files.body')
  }

  return { errorTitle, errorBody }
}

const IS_MULTIPLE = true

export const DropArea = memo((props: Props) => {
  const { t } = useTranslation()
  const maxUploadSize = useMaxUploadSize()
  const [modal, setModal] = useState({ title: '', body: '', displayed: false })
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [fwFlashModal, setFwFlashModal] = useState(false)
  const [filesToRename, setFilesToRename] = useState<IFileModified[]>([])
  const [filesToUpload, setFilesToUpload] = useState<File[]>([])
  const { ResponseCodeName } = useErrorTypeTranslations()
  const { state, startUpload, reset } = useUploadContext(props.printerUuid || props.uniqueContextId || '')
  const { data: connectUploads } = useConnectUploads(props.teamId, props.printerUuid || props.uniqueContextId || '')
  const toast = useToast()
  const { errorTitle, errorBody } = useErrorTranslations()
  const errorHandler = useErrorHandler()
  const queryClient = useQueryClient()
  const { user } = useAuthState()
  const api = useApiClient()
  const canUploadFw = props.canFlash || props.isFromConnect

  const PRINT_FILE_EXTENSIONS = getPrintFileExtensions(props.binarySupported, props.isSla, props.isFromConnect)
  const ALLOWED_EXTENSIONS = [...PRINT_FILE_EXTENSIONS]
  if (canUploadFw) {
    ALLOWED_EXTENSIONS.push(...FLASHABLE_EXTENSIONS)
  }

  useEffect(() => {
    if (Object.keys(state || {}).length === 0) {
      queryClient.invalidateQueries({ queryKey: ['folder-content', props.printerUuid, props.path] }) // folder view
      queryClient.invalidateQueries({ queryKey: ['printer-files', props.printerUuid] }) // list view on printer
      queryClient.invalidateQueries({ queryKey: ['printer-files', user.default_team_id] }) // all cloud files
      queryClient.invalidateQueries({ queryKey: [`/users/teams/${props.teamId}}/uploads`] }) // useConnectUploads
    }
  }, [queryClient, state, user.default_team_id, props.printerUuid, props.path, props.teamId])

  const renameAndUpload = (fileToRename: IFileModified, params: IEditFileAttrs) => {
    const renamedFile = new File([fileToRename.file], params.name)
    upload([renamedFile], fileToRename.toQueue)
    setFilesToRename(filesToRename.filter((f) => f.file.name !== fileToRename.file.name))
  }

  const upload = (files: File[] | FileList, toPrintQueue: boolean, flashConfirmed?: boolean) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      if (!file) {
        return
      }

      if ((props.free && file.size > props.free) || props.free === 0) {
        return toast.add(
          t(`droparea.file-bigger-than-free-space.title`),
          `__${file.name}__: ${t(`droparea.file-bigger-than-free-space.body`)}`
        )
      }

      const isFirmwareFile = isFirmwareFileByName(file)
      let fileInfo: IUploadInfo = { filename: file.name, size: file.size }
      if (props.downloadToPrinter) {
        fileInfo = { ...fileInfo, path: `${props.path}/${file.name}`, printer_uuid: props.printerUuid }
      }

      api.app.transfers
        .getUploadId(props.teamId, fileInfo)
        .then((response) => {
          const { id: uploadId, hash } = response
          if (flashConfirmed && props.printer) {
            dispatchFwUploadedEvent(props.printer, hash, props.teamId)
          }

          startUpload(
            uploadId,
            props.teamId,
            file,
            props.path,
            isFirmwareFile ? false : toPrintQueue, // to_queue
            false, // to_print
            props.downloadToPrinter
          ).catch((error) => {
            const e = { ...error }
            if (error && error.message) {
              e.message = `${t('file')} __${file.name}__: ${error.message}`
            }

            errorHandler(e)

            if (error && error.status === 413) {
              const { message } = errorHandler(error.response, error, false)
              const modalTitle = Object.keys(ResponseCodeName).includes(error.status.toString())
                ? ResponseCodeName[error.status.toString()]
                : error.statusText
              setModal({ title: modalTitle, body: `__${file.name}__: ${message}`, displayed: true })
            }

            reset(uploadId)
          })
        })
        .catch((error) => {
          if (error.code === 'CONFLICT_DOWNLOADED_FILE') {
            const { title, message } = errorHandler(error.response, error, false)
            toast.add(title, `${file.name}: ${message}`)
            return
          }

          errorHandler(error)

          if (
            error?.response &&
            error.response.status === 400 &&
            (error.code === 'INVALID_FILENAME_LENGTH' || error.code === 'INVALID_FILENAME_CHARS')
          ) {
            const instructions =
              error.code === 'INVALID_FILENAME_LENGTH'
                ? t('file.rename.instructions.reason-long', 'The file has too long filename, please rename it first.')
                : t(
                    'file.rename.instructions.reason-invalid',
                    'The file name contains invalid characters, please rename it first.'
                  )

            setFilesToRename((prevFiles) => {
              prevFiles.push({ file, toQueue: toPrintQueue, modal: { displayed: true, instructions } })
              return prevFiles
            })
          }
        })
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: IS_MULTIPLE,
    maxSize: maxUploadSize,
    maxFiles: 0, // no limit
    onDrop: (/* files */) => {
      // upload(files)
    },
    onDropRejected: (fileRejections) => {
      fileRejections.forEach((rejection) => {
        const errCode = rejection.errors[0].code as ErrorCode
        toast.add(errorTitle[errCode], `__${rejection.file.name}__: ${errorBody[errCode]}`)
      })
    },
    accept: ALLOWED_EXTENSIONS
  })

  useEffect(() => {
    window.addEventListener(`dragenter`, showDropZone)

    return () => {
      window.removeEventListener(`dragenter`, showDropZone)
    }
  }, [])

  const showDropZone = (e: any) => {
    if (e.dataTransfer.types.includes('Files')) {
      const dropZone = document.getElementById('dropzone')
      const isDropAreaRendered = document.getElementById('visible-droparea')
      if (dropZone && isDropAreaRendered) dropZone.style.visibility = 'visible'
    }
  }
  const hideDropZone = () => {
    const dropZone = document.getElementById('dropzone')
    if (dropZone) dropZone.style.visibility = 'hidden'
  }

  const allowDrag = (e: React.DragEvent<HTMLDivElement>) => {
    if (e.dataTransfer) {
      e.dataTransfer.dropEffect = 'copy'
    }
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    hideDropZone()
    const droppedFiles = Array.from(e.dataTransfer.files)
    handleUploadedFiles(droppedFiles)
  }

  const doUpload = (toQueue: boolean, droppedFiles?: File[], flashConfirmed?: boolean) => {
    setShowUploadModal(false)
    const files = droppedFiles || filesToUpload

    const totalFilesSize = files.reduce((acc, file) => acc + file.size, 0)

    const allowedFiles: File[] = []

    if (!IS_MULTIPLE && files.length > 1) {
      return toast.add(errorTitle[ErrorCode.TooManyFiles], errorBody[ErrorCode.TooManyFiles])
    }

    if (totalFilesSize > props.free) {
      return toast.add(
        t('droparea.files-too-large.title', 'Not enough space'),
        t('droparea.files-too-large.body', {
          size: formatSize(totalFilesSize - props.free),
          defaultValue: 'The total size of the files exceeds the remaining free space by {size}'
        })
      )
    }

    files.forEach((file) => {
      if (file.size > maxUploadSize) {
        toast.add(errorTitle[ErrorCode.FileTooLarge], `__${file.name}__: ${errorBody[ErrorCode.FileTooLarge]}`)
      } else if (ALLOWED_EXTENSIONS.indexOf(`.${getExtensionFromFileName(file.name)}`) < 0) {
        toast.add(errorTitle[ErrorCode.FileInvalidType], `__${file.name}__: ${errorBody[ErrorCode.FileInvalidType]}`)
      } else {
        allowedFiles.push(file)
      }
    })

    try {
      upload(allowedFiles, toQueue, flashConfirmed)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  const uploadOrFlash = (flash: boolean) => {
    doUpload(false, undefined, flash)
    setFwFlashModal(false)
  }

  const handleUploadedFiles = (droppedFiles: File[] | FileList) => {
    const items = Array.from(droppedFiles)
    setFilesToUpload(items)

    if (items.length > 0) {
      const isPrintFile = items.some((i) => PRINT_FILE_EXTENSIONS.includes(`.${getExtensionFromFileName(i.name)}`))

      const isFwFile = items.some((i) => FLASHABLE_EXTENSIONS.includes(`.${getExtensionFromFileName(i.name)}`))

      if (!props.isFromConnect && isPrintFile) {
        return setShowUploadModal(true)
      }

      if (!props.isFromConnect && isFwFile && items.length === 1) {
        return setFwFlashModal(true)
      }

      doUpload(false, items)
    }
  }

  const renderContent = () => {
    const hideErrorModal = () => {
      setModal({ ...modal, displayed: false })
    }

    const uploadedIds = Object.keys(state || {})
    const connectUploadsIds = connectUploads?.uploads.map((upload) => upload.id) || []
    const isAmongUploads = uploadedIds.every((id) => connectUploadsIds.includes(Number(id)))
    const isWaiting = uploadedIds.length && !isAmongUploads

    return (
      <>
        {!props.disabled && (
          <FullScreenDropzone
            id="dropzone"
            onDragEnter={allowDrag}
            onDragOver={allowDrag}
            onDragLeave={hideDropZone}
            onDrop={handleDrop}
          >
            <UploadIcon icon="uploadArrowIcon" className="mr-1" size={80} />
          </FullScreenDropzone>
        )}
        <S.DropAreaContainer {...getRootProps()} $isActive={isDragActive} id="visible-droparea">
          <S.DropAreaInner $disabled={!!props.disabled}>
            <>
              {isWaiting ? (
                <SvgIcon size={50} icon="loadingWheel4SIcon" />
              ) : (
                <>
                  <SvgIcon icon="uploadArrowIcon" className="mr-1" size={20} />
                  {props.title ||
                    (canUploadFw
                      ? t('printer.file.upload.select', 'Click to choose a print or firmware file or drag it here')
                      : t('printer.file.upload.select-file'))}
                </>
              )}
              <input
                disabled={!!props.disabled}
                {...getInputProps()}
                onChange={(e) => (e.target.files ? handleUploadedFiles(e.target.files) : null)}
              />
            </>
          </S.DropAreaInner>
        </S.DropAreaContainer>
        {showUploadModal && <FileUploadModal doUpload={doUpload} setShowUploadModal={setShowUploadModal} />}
        {fwFlashModal && (
          <ConfirmFirmwareFlashModal handleUpload={uploadOrFlash} close={() => setFwFlashModal(false)} />
        )}
        {filesToRename.map((item) => (
          <>
            {item.modal.displayed && (
              <RenameFileModal
                name={item.file.name}
                instructions={item.modal.instructions}
                onCancel={() => setFilesToRename(filesToRename.filter((f) => f.file.name !== item.file.name))}
                onSave={(params) => renameAndUpload(item, params)}
                maxFilename={props.maxFilename}
                isDev={props.isDev}
                isSla={props.isSla}
                binarySupported={props.binarySupported}
                isFromConnect={props.isFromConnect}
                path={props.path}
              />
            )}
          </>
        ))}
        {modal.displayed && (
          <ErrorModal show={modal.displayed} title={modal.title} body={modal.body} onHide={hideErrorModal} />
        )}
      </>
    )
  }

  return <S.Container $height={props.height}>{renderContent()}</S.Container>
})
