import { useState } from 'react'

import { Material } from '../api/types/filament'

export function useMismatchedFilamentModal(printerFilament?: Material, fileFilamentType?: Material) {
  const [filamentModal, setFilamentModal] = useState({
    show: false
  })

  const canRecognizeFilaments = !!(printerFilament && fileFilamentType)
  const differentFilaments = canRecognizeFilaments && printerFilament !== fileFilamentType

  const triggerFilamentModal = () => {
    if (differentFilaments) {
      setFilamentModal({
        show: true
      })
    }
  }

  const resetFilamentModal = () => {
    setFilamentModal({
      show: false
    })
  }

  return {
    triggerFilamentModal,
    resetFilamentModal,
    filamentModal,
    differentFilaments
  }
}
