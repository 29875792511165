import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import styled from 'styled-components'

import { useApiClient } from '../../../api/react'
import {
  getExtensionFromFileName,
  IFileDetailResponse,
  isPrintFile,
  PRINT_FILE_SLA_EXTENSIONS
} from '../../../api/types/file'
import { IGcodeMetadata } from '../../../api/types/metadata'
import { PrinterUuid } from '../../../api/types/printer'
import { useBreakpoint } from '../../../helpers/useBreakpoint'
import { ITimetype } from '../../../interfaces/time'
import { Container } from '../../bootstrap/Container'
import { ContainerTitle } from '../../common/ContainerTitle'
import { EmptyList } from '../../common/EmptyList'
import { PaceProgress } from '../../common/PaceProgress'
import { ImageWrapper, Label, Parameters, Value } from '../../common/Parameters'
import { FileDataParameters } from '../../FileDataParameters'
import { GcodeMetadataParameters } from '../../GcodeMetadataParameters'
import { Time } from '../../helpers/time'
import { JobPreview } from '../../jobs/JobPreview'
import { ConnectFileActions } from './FileManager/ConnectFileActions'
import { FarmFileActions } from './FileManager/FarmFileActions'
import { PrinterFileActions } from './FileManager/PrinterFileActions'

type Props = {
  file: IFileDetailResponse
  isGlobalConnectStorage?: boolean
}

const ButtonContainer = styled.div<{
  toColumn: boolean
}>`
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  flex-wrap: wrap;

  ${({ toColumn }) =>
    toColumn &&
    `
    display: grid;
    grid-auto-columns: 1fr;
  `}
`

function FileDetailContent(props: Props) {
  const { file, isGlobalConnectStorage = false } = props
  const { t } = useTranslation()
  const isMdOrLarge = useBreakpoint('md')
  const { teamId, hash = '' } = useParams<{ teamId: string; hash: string }>()
  const navigate = useNavigate()
  const metadata = file.meta as IGcodeMetadata

  if (!teamId) {
    // typecheck - should not happen
    return null
  }

  const renderContent = () => {
    if (isPrintFile(file)) {
      return (
        <>
          {metadata?.estimated_print_time && (
            <>
              <Label>{t('gcode-meta.estimated-printing-time-normal-mode')}</Label>
              <Value>
                <Time unixTimestamp={metadata.estimated_print_time} type={ITimetype.DURATION} noSeconds />
              </Value>
            </>
          )}
          <ImageWrapper className="col-start-2">
            <JobPreview size="auto" previewUrl={file?.preview_url} hashForThumbnail={file.hash || file.type} />
          </ImageWrapper>
          {file.meta && (
            <GcodeMetadataParameters
              metadata={file.meta}
              isSla={PRINT_FILE_SLA_EXTENSIONS.indexOf(`.${getExtensionFromFileName(file.name)}`) !== -1}
            />
          )}
        </>
      )
    }

    // eslint-disable-next-line no-console
    console.warn('Detail page for firmware is not implemented yet.')
    return null
  }

  const goBack = () => {
    if (window.location.pathname.endsWith(hash)) {
      navigate(-1)
    }
  }

  return (
    <Container>
      <Parameters>
        <FileDataParameters file={file} teamId={Number(teamId)} />
        {renderContent()}
      </Parameters>

      {isGlobalConnectStorage ? (
        <ButtonContainer className="my-3" toColumn={!isMdOrLarge}>
          {AFS_ENABLED ? (
            <FarmFileActions file={file} onDelete={goBack} teamId={teamId} />
          ) : (
            <ConnectFileActions file={file} teamId={teamId} onDelete={goBack} onRename={goBack} />
          )}
        </ButtonContainer>
      ) : (
        <ButtonContainer className="mt-2" toColumn={!isMdOrLarge}>
          <PrinterFileActions file={file} onDelete={goBack} />
        </ButtonContainer>
      )}
    </Container>
  )
}

type FileDetailProps = {
  isGlobalConnectStorage?: boolean
}

function FileDetailPage(props: FileDetailProps) {
  const { isGlobalConnectStorage = false } = props
  const { hash, teamId, printerUuid } = useParams<{ hash: string; teamId: string; printerUuid: PrinterUuid }>()
  const api = useApiClient()
  const { t } = useTranslation()

  const fileQuery = useQuery([`/file-detail/${teamId}/${printerUuid}/${hash}`], () => {
    return api.app.files.getFile(Number(teamId), String(hash), printerUuid)
  })

  if (fileQuery.isLoading) {
    return <PaceProgress />
  }

  const renderContent = (file: IFileDetailResponse) => {
    return (
      <Container>
        <ContainerTitle>{t('file.detail')}</ContainerTitle>
        <FileDetailContent file={file} isGlobalConnectStorage={isGlobalConnectStorage} />
      </Container>
    )
  }

  if (fileQuery.isError) {
    return (
      <Container>
        <EmptyList title={t('file.detail.not-found')} />
      </Container>
    )
  }

  if (!fileQuery.data) {
    return null
  }

  return renderContent(fileQuery.data)
}

export default FileDetailPage
