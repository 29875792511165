import { useLocalStorage } from 'react-use'

export function useRedirectUrl() {
  const [redirectUrl, setRedirectUrl, removeRedirectUrl] = useLocalStorage<string>('redirectUrl')

  return {
    redirectUrl,
    setRedirectUrl,
    removeRedirectUrl
  }
}
