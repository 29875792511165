import { FormEvent, useEffect, useRef, useState } from 'react'
import { Form, FormControl, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { GcodeArgs, GcodeArgTypes, isGcodeArg, MAX_TEMPLATE_HEIGHT } from '../../api/types/gcode'
import { Button } from '../common/Button'
import { LoadingButton } from '../common/LoadingButton'
import { SvgIcon } from '../common/SvgIcon'

const ICON_SIZE = 28

const Param = styled.div`
  background-color: var(--background-primary);
  padding: 0.8rem 4rem 0.8rem 0.8rem;
  margin-bottom: 0.8rem;
`

const DeleteIcon = styled(SvgIcon)`
  right: 28px;
  position: absolute;
`

const Row = styled.div`
  align-items: baseline;
`

type Props = {
  onCancel: () => void
  onSuccess: (params: GcodeArgs[]) => void
  templateArgs: GcodeArgs[]
}

export function AddGcodeParamsModal(props: Props) {
  const { onCancel, onSuccess } = props
  const { t } = useTranslation()
  const [args, setArgs] = useState(props.templateArgs || [])
  const inputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    onSuccess(args)
  }

  const addParam = () => {
    setArgs([...args, { name: '', type: GcodeArgTypes.STRING }])
  }

  const deleteParam = (index: number) => {
    const newArgs = [...args]
    newArgs.splice(index, 1)
    setArgs(newArgs)
  }

  const setArgProp = (index: number, prop: keyof GcodeArgs, value: string) => {
    const newArgs = [...args]
    if (prop === 'type' && isGcodeArg(value)) {
      newArgs[index].type = value
    } else if (prop !== 'type') {
      newArgs[index][prop] = value
    }
    setArgs(newArgs)
  }

  return (
    <Modal show onHide={onCancel} centered size="xl">
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>{t('gcode.add-arguments.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {args.map((arg, i) => {
            return (
              <Param key={i}>
                <Row className="row">
                  <DeleteIcon icon="nokIcon" size={16} title={t('button.delete')} onClick={() => deleteParam(i)} />
                  <div className="col-lg-4 col-sm-6">
                    <Form.Label htmlFor="argName">{t('gcode.add-arguments.name')}</Form.Label>
                    <Form.Group controlId="argName">
                      <FormControl
                        ref={inputRef}
                        value={arg.name || ''}
                        onChange={(e) => setArgProp(i, 'name', e.currentTarget.value)}
                        type="text"
                        required
                      />
                    </Form.Group>
                  </div>

                  <div className="col-lg-4 col-sm-6">
                    <Form.Label htmlFor="argType">{t('gcode.add-arguments.type')}</Form.Label>
                    <Form.Group controlId="argType">
                      <FormControl
                        as="select"
                        className="italic"
                        onChange={(e) => setArgProp(i, 'type', e.currentTarget.value)}
                        value={arg.type}
                        required
                      >
                        <option value="" disabled>
                          {t('gcode.add-arguments.type')}
                        </option>
                        {Object.entries(GcodeArgTypes).map(([, value]) => (
                          <option className="non-italic" key={value} value={value}>
                            {value}
                          </option>
                        ))}
                      </FormControl>
                    </Form.Group>
                  </div>

                  <div className="col-lg-2 col-sm-6">
                    <Form.Label htmlFor="argDefault" style={{ wordBreak: 'break-word' }}>
                      {t('gcode.add-arguments.default')}
                    </Form.Label>
                    <Form.Group controlId="argDefault">
                      <FormControl
                        value={arg.default || ''}
                        onChange={(e) => setArgProp(i, 'default', e.currentTarget.value)}
                        type="text"
                      />
                    </Form.Group>
                  </div>

                  <div className="col-lg-2 col-sm-6">
                    <Form.Label htmlFor="argUnit">{t('gcode.add-arguments.unit')}</Form.Label>
                    <Form.Group controlId="argUnit">
                      <FormControl
                        value={arg.unit || ''}
                        onChange={(e) => setArgProp(i, 'unit', e.currentTarget.value)}
                        type="text"
                      />
                    </Form.Group>
                  </div>
                </Row>

                <Form.Label htmlFor="argDescription">{t('gcode.add-arguments.description')}</Form.Label>
                <Form.Group controlId="argDescription">
                  <FormControl
                    as="textarea"
                    value={arg.description || ''}
                    onChange={(e) => setArgProp(i, 'description', e.currentTarget.value)}
                    required
                  />
                </Form.Group>
              </Param>
            )
          })}
          {args.length < MAX_TEMPLATE_HEIGHT && (
            <SvgIcon icon="plusIcon" size={ICON_SIZE} title={t('button.add')} onClick={addParam} />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button type="reset" onClick={onCancel}>
            {t('button.cancel')}
          </Button>
          <LoadingButton type="submit">{t('button.save')}</LoadingButton>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
