import styled, { css } from 'styled-components'

export type BadgeProps = {
  kind?: 'background' | 'outline'
  colorName?: string
  colorHex?: string
  textColor?: string
}

const outlineBadgeCss = css<BadgeProps>`
  background-color: ${({ theme }) => theme.colors.backgrounds.body};
  border: 1px solid ${({ colorHex, theme }) => colorHex ?? theme.colors.borders.primary};
`

const backgroundBadgeCss = css<BadgeProps>`
  background-color: ${({ theme, colorHex }) => colorHex ?? theme.colors.primary};
  color: ${({ textColor }) => textColor ?? 'white'};
`

export const ConnectBadge = styled.span<BadgeProps>`
  display: inline-block;
  border-radius: 3px;
  ${({ kind }) => (kind === 'background' ? backgroundBadgeCss : outlineBadgeCss)}
  padding: 0.19rem 1.3rem;
  line-height: 1.2rem;
`

ConnectBadge.defaultProps = { kind: 'outline' }

export default ConnectBadge
