import styled, { css } from 'styled-components'

import { IChannelNotification, INotificationSeverityTypes } from '../../../api/types/notifications'
import { Checkbox } from '../../common/Checkbox'
import { Td } from '../../helpers/responsiveTable'

type Props = {
  name: string
  title: string
  severities: INotificationSeverityTypes
  isLoading: boolean
  onClick: (params: IChannelNotification) => void
  enabled: boolean
}

const Tr = styled.tr<{ $enabled: boolean }>`
  ${(props) =>
    !props.$enabled &&
    css`
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.5;
    `}
`

export function NotificationChannelRow(props: Props) {
  const { info, attention, error } = props.severities

  return (
    <Tr $enabled={props.enabled}>
      <Td>{props.title}</Td>

      <Td align="center">
        <Checkbox
          disableToggle
          isLoading={props.isLoading}
          checked={info}
          onClick={() => props.onClick({ [props.name]: { info: !info, attention, error } })}
        />
      </Td>

      <Td align="center">
        <Checkbox
          disableToggle
          isLoading={props.isLoading}
          checked={attention}
          onClick={() => props.onClick({ [props.name]: { info, attention: !attention, error } })}
        />
      </Td>

      <Td align="center">
        <Checkbox
          disableToggle
          isLoading={props.isLoading}
          checked={error}
          onClick={() => props.onClick({ [props.name]: { info, attention, error: !error } })}
        />
      </Td>
    </Tr>
  )
}
