import { useQuery } from '@tanstack/react-query'
import ms from 'ms'
import { useEffect } from 'react'

import { useApiClient } from '../../api/react'
import { IPrinterSimpleView, PrinterUuid } from '../../api/types/printer'

export function useSupportedCommandsByUuid(printerUuid: PrinterUuid, refresh = false) {
  const api = useApiClient()
  const { data: supportedCommands, refetch } = useQuery(
    [`/supported-commands/${printerUuid}`],
    () => api.app.supportedCommands.getSupportedCommandsByUuid(printerUuid),
    {
      staleTime: ms('1day')
    }
  )

  useEffect(() => {
    if (refresh) {
      refetch()
    }
  }, [refresh, refetch])

  return supportedCommands
}

export function useSupportedCommandByUuid(commandName: string, printer: IPrinterSimpleView) {
  const { uuid, connect_state } = printer
  const supportedCommands = useSupportedCommandsByUuid(uuid)

  const command = supportedCommands?.commands.find((command) => command.command === commandName)

  return command?.executable_from_state?.includes(connect_state) ? command : undefined
}

export function useSupportedCommandsByPrinterType(printerType: string, refresh = false) {
  const api = useApiClient()
  const { data: supportedCommands, refetch } = useQuery(
    [`/supported-commands/${printerType}`],
    () => api.app.supportedCommands.getSupportedCommandsByPrinterType(printerType),
    {
      staleTime: ms('1day')
    }
  )

  useEffect(() => {
    if (refresh) {
      refetch()
    }
  }, [refresh, refetch])

  return supportedCommands
}

export function useSupportedCommandByPrinterType(commandName: string, printer: IPrinterSimpleView) {
  const { printer_type, connect_state } = printer
  const supportedCommands = useSupportedCommandsByPrinterType(printer_type)

  const command = supportedCommands?.commands.find((command) => command.command === commandName)

  return command?.executable_from_state?.includes(connect_state) ? command : undefined
}
