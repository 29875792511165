import { useEffect } from 'react'

import blackSpool from './sprites/spool_black.png'
import blueSpool from './sprites/spool_blue.png'
import greenSpool from './sprites/spool_green.png'
import orangeSpool from './sprites/spool_orange.png'
import redSpool from './sprites/spool_red.png'
import whiteSpool from './sprites/spool_white.png'
import yellowSpool from './sprites/spool_yellow.png'
import { squareSize } from './types/consts'

type IProps = {
  size: number
  enemyTop: number
  enemyLeft: number
  direction?: string
  playerTop: number
  playerLeft: number
  spool: number
  onCollision: () => void
}

const chooseSpool = (spool: number, spools: string[]) => spools[spool]

export function Enemy(props: IProps) {
  const detectCollision = () => {
    switch (props.direction) {
      case 'UP':
        if (
          props.enemyLeft === props.playerLeft &&
          props.enemyTop <= props.playerTop + squareSize &&
          props.enemyTop + squareSize >= props.playerTop
        )
          props.onCollision()
        break
      case 'DOWN':
        if (
          props.enemyLeft === props.playerLeft &&
          props.enemyTop + squareSize >= props.playerTop &&
          props.enemyTop <= props.playerTop + squareSize
        )
          props.onCollision()
        break
      case 'LEFT':
        if (
          props.enemyTop === props.playerTop &&
          props.enemyLeft <= props.playerLeft + squareSize &&
          props.enemyLeft + squareSize >= props.playerLeft
        )
          props.onCollision()
        break
      case 'RIGHT':
        if (
          props.enemyTop === props.playerTop &&
          props.enemyLeft + squareSize >= props.playerLeft &&
          props.enemyLeft <= props.playerLeft + squareSize
        )
          props.onCollision()
        break
      default:
    }
  }

  useEffect(() => {
    detectCollision()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  return (
    <div
      style={{
        width: `${props.size}px`,
        height: `${props.size}px`,
        backgroundImage: `url(${chooseSpool(props.spool, [
          blackSpool,
          blueSpool,
          greenSpool,
          orangeSpool,
          redSpool,
          whiteSpool,
          yellowSpool
        ])})`,
        backgroundSize: 'contain',
        top: `${props.enemyTop}px`,
        left: `${props.enemyLeft}px`,
        position: 'absolute',
        animation: 'spin 2s linear infinite'
      }}
    />
  )
}
