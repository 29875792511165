import React, { useEffect, useState } from 'react'
import { Overlay, Popover } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { IGroupPattern } from '../../../../api/types/groups'
import { IGrid } from '../../../../interfaces/grid'
import { PlainButton } from '../../../common/PlainButton'
import { getDimensionMessage, gridToGroupSize } from '../group'
import { GridSelector } from './gridSelector'

const Preset = styled(PlainButton)`
  :hover {
    cursor: pointer;
    color: #fa6831;
  }
`

type IPreset = {
  label: string
  size: IGrid
}

type Props = {
  iconRef: HTMLDivElement | null
  id: string
  onClose: () => void
  onSelected: (size: IGrid) => void
  pattern: IGroupPattern
  selectedSize: IGrid
  show: boolean
  showSizePopup: boolean
}

const MAX_COLUMNS = 12
const MAX_ROWS = 8

const presets: IPreset[] = [
  { label: 'Small farm', size: { col: 6, row: 3 } },
  { label: 'Medium farm', size: { col: 10, row: 8 } }
]

export function DimensionSelectorOverlay(props: Props) {
  const { selectedSize, show } = props
  const [showedPos, setShowedPos] = useState<IGrid | undefined>()
  const [selectedPos, setSelectedPos] = useState<IGrid>({ row: selectedSize.row, col: selectedSize.col })
  const selectorLabelRef = React.useRef<HTMLDivElement>(null) // updating selectedPos will rerender whole grid again
  const { t } = useTranslation()

  const selectAndClose = (pos: IGrid) => {
    setSelectedPos(pos)
    props.onSelected({ row: pos.row, col: pos.col })
    props.onClose()
  }

  const updateLabel = (size: IGrid) => {
    if (selectorLabelRef.current)
      selectorLabelRef.current.innerText = getDimensionMessage(
        t,
        size.row * size.col,
        gridToGroupSize(size, props.pattern)
      )
  }

  const presetMouseOver = (size: IGrid) => {
    setShowedPos({ row: size.row, col: size.col })
    updateLabel(size)
  }

  const presetClick = (size: IGrid) => {
    selectAndClose({ row: size.row, col: size.col })
  }

  // hide when user clicks outside popover
  useEffect(() => {
    const event = (e: MouseEvent) => {
      if (!document.getElementById(`${props.id}-big`)?.contains(e.target as Element) && e.target !== props.iconRef) {
        props.onClose()
      }
    }
    document.addEventListener('mousedown', event)

    return () => {
      document.removeEventListener('mousedown', event)
    }
  }, [props, props.iconRef])

  // reset position on update
  useEffect(() => {
    if (show) {
      setShowedPos(undefined)
      setSelectedPos({ row: selectedSize.row, col: selectedSize.col })
    }
  }, [selectedSize, show])

  return (
    <>
      <Overlay target={props.iconRef} show={show} placement="bottom">
        <Popover id={`${props.id}-big`} style={{ maxWidth: '90vw' }}>
          <Popover.Content style={{ width: 'fit-content' }}>
            <div ref={selectorLabelRef}>
              {getDimensionMessage(t, selectedPos.row * selectedPos.col, gridToGroupSize(selectedPos, props.pattern))}
            </div>

            <GridSelector
              shifted={props.pattern === IGroupPattern.SHIFTED}
              size={{ col: MAX_COLUMNS, row: MAX_ROWS }}
              onSelected={(pos) => selectAndClose(pos)}
              onMouseOver={(pos) => updateLabel({ row: pos.row, col: pos.col })}
              initial={showedPos || selectedPos}
            />

            <i>presets</i>
            {presets.map((p, index) => (
              <Preset
                className="d-block"
                key={index}
                onMouseEnter={() => presetMouseOver(p.size)}
                onClick={() => presetClick(p.size)}
              >
                {p.label} {p.size.col}&times;{p.size.row}
              </Preset>
            ))}
          </Popover.Content>
        </Popover>
      </Overlay>

      <Overlay target={props.iconRef} show={!show && props.showSizePopup} placement="bottom">
        <Popover id={`${props.id}-small`} style={{ maxWidth: '90vw' }}>
          <Popover.Content style={{ width: 'fit-content' }}>
            <div>
              {getDimensionMessage(t, selectedPos.col * selectedPos.row, gridToGroupSize(selectedPos, props.pattern))}
            </div>
          </Popover.Content>
        </Popover>
      </Overlay>
    </>
  )
}
