import { useEffect, useState } from 'react'

export function usePressedShift() {
  const [shiftPressed, setShiftPressed] = useState(false)

  useEffect(() => {
    const handleKeyUp = () => {
      setShiftPressed(false)
    }

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Shift') {
        if (!shiftPressed) setShiftPressed(true)
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('keyup', handleKeyUp)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('keyup', handleKeyUp)
    }
  }, [])

  return { shiftPressed, setShiftPressed }
}
