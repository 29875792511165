import { EChartsOption } from 'echarts'
import * as echarts from 'echarts/core'
import EChartsReact from 'echarts-for-react'
import { useTheme } from 'styled-components'

import { darkTheme, lightTheme } from '../../theme/echartsThemes'

echarts.registerTheme('dark-theme', darkTheme)
echarts.registerTheme('light-theme', lightTheme)

type Props = {
  option: EChartsOption
}

export function EChartsWrapper(props: Props) {
  const theme = useTheme()
  return (
    <EChartsReact
      option={props.option}
      style={{ height: '100%' }}
      theme={theme.isDark ? 'dark-theme' : 'light-theme'}
      lazyUpdate
    />
  )
}
