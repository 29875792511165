import { useInfiniteQuery, useQuery, UseQueryOptions } from '@tanstack/react-query'

import { useApiClient } from '../api/react'
import { IPrintersQuery, IPrintersResponse } from '../api/types/printer'
import { intervals } from '../config'

export function usePrintersService(
  query?: IPrintersQuery,
  options?: Omit<UseQueryOptions<IPrintersResponse>, 'queryKey'>
) {
  const api = useApiClient()
  return useQuery([`/printers/${JSON.stringify(query)}`], () => api.app.printers.getPrinters(query), options)
}

export function useInfinitePrintersService(query: IPrintersQuery) {
  const { app } = useApiClient()
  return useInfiniteQuery([`/printers/${JSON.stringify(query)}`], () => app.printers.getPrinters(query), {
    refetchInterval: intervals.printersPolling,
    keepPreviousData: true,
    getNextPageParam: (lastPage, allPages) => {
      const loaded = allPages.flatMap((page) => page.printers).length
      const total = lastPage.pager.total

      if (loaded < total) {
        query.offset = loaded
        query.limit = total
        return query
      }
      return undefined
    }
  })
}
