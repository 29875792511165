import styled, { css } from 'styled-components'

import { IUserInvitation } from '../../../api/types/user'
import { useTeamName } from '../../../hooks/useTeamName'
import { Checkbox } from '../../common/Checkbox'
import { Td } from '../../helpers/responsiveTable'
import { Time } from '../../helpers/time'
import { AcceptInvitationButton } from './AcceptInvitationButton'
import { DeclineInvitationButton } from './DeclineInvitationButton'

const Tr = styled.tr<{ $inactive?: boolean }>`
  ${({ $inactive }) =>
    $inactive
      ? css`
          opacity: 0.5;
        `
      : ''}
`

const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.3125rem;
`

type IProps = IUserInvitation & {
  onAcceptInvitation?: () => void
  onRefuseInvitation?: () => void
  inactive?: boolean
}

export function InvitationRow(props: IProps) {
  const { getTeamName } = useTeamName()

  return (
    <Tr $inactive={props.inactive}>
      <Td>{getTeamName(props.team)}</Td>
      <Td />
      <Td align="center">
        <Checkbox disableToggle readonly checked={props.rights_ro} />
      </Td>
      <Td align="center">
        <Checkbox disableToggle readonly checked={props.rights_rw} />
      </Td>
      <Td align="center">
        <Checkbox disableToggle readonly checked={props.rights_use} />
      </Td>
      <Td noLabel>
        {!props.inactive && (
          <ButtonGroup>
            <AcceptInvitationButton onClick={props.onAcceptInvitation || (() => {})} />
            <DeclineInvitationButton onClick={props.onRefuseInvitation || (() => {})} />
          </ButtonGroup>
        )}
      </Td>
      <Td>
        <Time unixTimestamp={props.valid} />
      </Td>
      <Td>
        <Time unixTimestamp={props.refused} />
      </Td>
    </Tr>
  )
}
