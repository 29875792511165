export class TouchHandler {
  move: (key: string | number) => void
  dir: 'left' | 'up' | 'right' | 'down' | 'none'
  startX: number
  startY: number
  distX: number
  distY: number

  constructor(move: (key: string | number) => void) {
    this.move = move
    this.dir = 'none'
    this.startX = 0
    this.startY = 0
    this.distX = 0
    this.distY = 0
    this.handleTouchStart = this.handleTouchStart.bind(this)
    this.handleTouchMove = this.handleTouchMove.bind(this)
    this.handleTouchEnd = this.handleTouchEnd.bind(this)
  }

  handleTouchStart(e: TouchEvent) {
    e.preventDefault()
    const touchobj = e.changedTouches[0]

    this.dir = 'none'
    this.startX = touchobj.screenX
    this.startY = touchobj.screenY
  }

  handleTouchMove(e: TouchEvent) {
    e.preventDefault()
    const touchobj = e.changedTouches[0]
    this.distX = touchobj.screenX - this.startX // horizontal dist traveled by finger
    this.distY = touchobj.screenY - this.startY // vertical dist traveled by finger
    if (Math.abs(this.distX) > Math.abs(this.distY)) {
      this.dir = this.distX < 0 ? 'left' : 'right'
    } else {
      this.dir = this.distY < 0 ? 'up' : 'down'
    }
  }

  handleTouchEnd(e: TouchEvent) {
    e.preventDefault()
    this.move(this.dir)
  }
}
