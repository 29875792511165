import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { Navigate, Route, Routes } from 'react-router-dom'

import { AvailablePrinterTypes, buddyPrinters, printersWithIniFile } from '../api/types/printer'
import { Container } from '../components/bootstrap/Container'
import { BreadcrumbItem, Breadcrumbs } from '../components/common/Breadcrumbs'
import { DocumentTitle } from '../components/header/DocumentTitle'
import { Submenu } from '../components/helpers/submenu'
import { ConnectPrinterPage } from '../components/printers/add/ConnectPrinterPage'
import { DonePage } from '../components/printers/add/DonePage'
import { DownloadSettingsPage } from '../components/printers/add/DownloadSettingsPage'
import { SelectPrinterPage } from '../components/printers/add/SelectPrinterPage'
import { SetupPrinterPage } from '../components/printers/add/SetupPrinterPage'

export type Step = {
  name: string
  to: string
  prev: boolean
  next: boolean
}

export function returnSteps(t: (text: string) => string, printerType?: string): Step[] {
  const isUnknown = !Object.values(AvailablePrinterTypes).includes(printerType as AvailablePrinterTypes)

  if (printersWithIniFile.includes(printerType as AvailablePrinterTypes)) {
    return [
      {
        name: t(`printer.add.select-printer-title`),
        to: `select`,
        prev: false,
        next: true
      },
      {
        name: t(`printer.add.setup-printer-title`),
        to: `setup/${printerType}`,
        prev: true,
        next: true
      },
      {
        name: t(`printer.add.download-printer-settings-title`),
        to: `download/${printerType}`,
        prev: true,
        next: false
      }
    ]
  }
  if (buddyPrinters.includes(printerType as AvailablePrinterTypes) || isUnknown) {
    return [
      {
        name: t(`printer.add.select-printer-title`),
        to: `select`,
        prev: false,
        next: false
      },
      {
        name: t(`printer.add.setup-printer-title`),
        to: `setup/${printerType}`,
        prev: true,
        next: true
      },
      // is not displayed, just redirected to new printer detail
      {
        name: t(`printer.add`),
        to: `done/${printerType}`,
        prev: false,
        next: false
      }
    ]
  }
  // MK2.5/S, MK3/S/+
  return [
    {
      name: t(`printer.add.select-printer-title`),
      to: `select`,
      prev: false,
      next: false
    },
    {
      name: t(`printer.add.setup-printer-title`),
      to: `setup/${printerType}`,
      prev: true,
      next: false
    },
    {
      name: t(`printer.add.connect-printer-title`),
      to: `connect/${printerType}`,
      prev: false,
      next: false
    },
    {
      name: t(`printer.add.done-title`),
      to: `done/${printerType}`,
      prev: false,
      next: false
    }
  ]
}

export function AddPrinterPage() {
  const paramsObj = useParams()
  const params = paramsObj['*']
  const { t } = useTranslation()
  const [stepIndex, setStepIndex] = useState(1)
  const [printerType, setPrinterType] = useState<string>(params?.slice(params.length - 5, params.length) || '')
  const steps = returnSteps(t, printerType)

  return (
    <>
      <DocumentTitle content={t('printer.add')} />

      <Submenu>
        <Container>
          <div className="my-1">
            <Breadcrumbs>
              <BreadcrumbItem to="select" label={t('printer.add')} clickable />
              {steps.slice(0, stepIndex + 1).map((item, i) => (
                <BreadcrumbItem key={i} to={item.to} clickable={i <= stepIndex} label={item.name} />
              ))}
            </Breadcrumbs>
          </div>
        </Container>
      </Submenu>

      <Container>
        <Routes>
          <Route path="select" element={<SelectPrinterPage setStepIndex={setStepIndex} stepIndex={0} />} />
          <Route
            path="setup/:printerType"
            element={<SetupPrinterPage setStepIndex={setStepIndex} setPrinterType={setPrinterType} stepIndex={1} />}
          />
          <Route
            path="connect/:printerType/:code/"
            element={<ConnectPrinterPage setStepIndex={setStepIndex} setPrinterType={setPrinterType} stepIndex={2} />}
          />

          {/* DEPRECATED remove when new PrusaLink with querystring support is out */}
          <Route
            path="connect/:printerType/:code/:name"
            element={<ConnectPrinterPage setStepIndex={setStepIndex} setPrinterType={setPrinterType} stepIndex={2} />}
          />
          {/* DEPRECATED remove when new PrusaLink with querystring support is out */}
          <Route
            path="connect/:printerType/:code/:name/:location"
            element={<ConnectPrinterPage setStepIndex={setStepIndex} setPrinterType={setPrinterType} stepIndex={2} />}
          />

          <Route path="download/:printerType/:printerUuid" element={<DownloadSettingsPage />} />
          <Route
            path="done/:printerType/:printerUuid"
            element={<DonePage setStepIndex={setStepIndex} stepIndex={3} />}
          />
          <Route index element={<Navigate replace to="select" />} />
        </Routes>
      </Container>
    </>
  )
}
