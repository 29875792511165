import { IPrinter, IPrinterStorageLocation, IPrinterStorageType } from '../../api/types/printer'
import { IConnectState } from '../../api/types/state'
import { Icon } from '../../icons'
import { usePrinterInState } from '../usePrinterInState'
import { useStorageLocations } from './useStorageLocations'

export type StorageLocationTab = {
  icon: Icon
  slug: StorageLocationSlug
  name: string
  display_name: string
  type?: IPrinterStorageType
  disabled?: boolean
  disabledTooltip?: string
}

export enum StorageLocationSlug {
  PRUSA_LINK = 'prusa-link',
  SD_CARD = 'sd-card',
  USB = 'usb',
  LOCAL = 'local',
  CONNECT = 'connect',
  OTHER = 'other'
}

// We need a way to map printer storage types from the API onto our custom slugs
export const locationTypeToSlug: Record<IPrinterStorageType, StorageLocationSlug> = {
  [IPrinterStorageType.LINK]: StorageLocationSlug.PRUSA_LINK,
  [IPrinterStorageType.SD_CARD]: StorageLocationSlug.SD_CARD,
  [IPrinterStorageType.USB]: StorageLocationSlug.USB,
  [IPrinterStorageType.LOCAL]: StorageLocationSlug.LOCAL,
  [IPrinterStorageType.OTHER]: StorageLocationSlug.OTHER
}

export const locationIcons: Record<StorageLocationSlug, Icon> = {
  [StorageLocationSlug.PRUSA_LINK]: 'linkMicroSdIcon',
  [StorageLocationSlug.SD_CARD]: 'sdCardIcon',
  [StorageLocationSlug.USB]: 'usbIcon',
  [StorageLocationSlug.LOCAL]: 'filesStorageIcon',
  [StorageLocationSlug.CONNECT]: 'cloudIconIcon',
  [StorageLocationSlug.OTHER]: 'stateUnknownIcon'
}

export function getStorageSlug(storage: string): StorageLocationSlug {
  switch (storage) {
    case 'SD Card':
      return StorageLocationSlug.SD_CARD
    case 'PrusaLink gcodes':
      return StorageLocationSlug.PRUSA_LINK
    case 'usb':
      return StorageLocationSlug.USB
    case 'local':
      return StorageLocationSlug.LOCAL
    default:
      return StorageLocationSlug.PRUSA_LINK
  }
}

export const getWholeFilePath = (filePath: string, printer: IPrinter, hash: string) => {
  const parts = filePath.split('/')
  const storage = filePath.startsWith('/') ? parts[1] : parts[0] // SD Card, PrusaLink gcodes...

  return `/printer/${printer.uuid}/storage/teams/${printer.team_id}/${storage}/files/${hash}`
}

function getStorageLocationTabs(
  locations: IPrinterStorageLocation[],
  printer: IPrinter,
  printerInState: string
): StorageLocationTab[] {
  const locationTabs: StorageLocationTab[] = locations.map((location) => {
    const locationType = location.type as IPrinterStorageType
    const slug = locationTypeToSlug[locationType]
    return {
      slug,
      icon: locationIcons[slug],
      type: location.type,
      name: location.name,
      display_name: location.name === 'usb' ? location.name.toUpperCase() : location.name,
      disabled: printer.connect_state === IConnectState.OFFLINE || printer.connect_state === IConnectState.UNKNOWN,
      disabledTooltip: printerInState
    }
  })

  return locationTabs
}

export function useStorageLocationTabs(printer: IPrinter) {
  const { locations, isLoading, isError, isSuccess } = useStorageLocations(printer)
  const printerInState = usePrinterInState(printer.connect_state)
  const locationTabs = getStorageLocationTabs(locations, printer, printerInState)

  return { locationTabs, isSuccess, isError, isLoading }
}
