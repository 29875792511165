import { MouseEvent, ReactNode } from 'react'
import { Button } from 'react-bootstrap'
import styled, { css } from 'styled-components'

import { buttonPulse } from '../helpers/keyframes'
import { WithTooltip } from './WithTooltip'

// TODO unify with common/LoadingButton.tsx somehow
const LoadingButton = styled(Button)<{ $isLoading?: boolean; $disabled?: boolean }>`
  flex-shrink: 0;
  font-size: 0.875rem;
  ${({ $isLoading }) =>
    $isLoading
      ? css`
          animation: ${buttonPulse} 2s linear infinite;
        `
      : ''}
  ${({ $disabled }) =>
    $disabled
      ? css`
          cursor: not-allowed;
          pointer-events: none;
          opacity: 0.65;
        `
      : ''}
`

type Props = {
  className?: string
  children: ReactNode
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
  isLoading?: boolean
  disabled?: boolean
  disabledTooltip?: string
}

export function LoadingButtonOutlined(props: Props) {
  const { children, className, isLoading, disabled, onClick, disabledTooltip } = props

  const renderButton = () => {
    return (
      <LoadingButton
        className={className}
        variant="outline-dark"
        onClick={onClick}
        $isLoading={isLoading}
        $disabled={disabled}
      >
        {children}
      </LoadingButton>
    )
  }

  if (disabled && !!disabledTooltip && !isLoading) {
    return (
      <WithTooltip id="disabled-tooltip" title={disabledTooltip} placement="top">
        <span style={{ cursor: 'not-allowed', display: 'inherit' }}>{renderButton()}</span>
      </WithTooltip>
    )
  }

  return renderButton()
}
