import { useMutation, useQuery } from '@tanstack/react-query'

import { useApiClient } from '../client/apiClientContext'
import type { paths } from '../schema/schema'

// Required types for legacy components. Will not be needed for brand new ones.
type UserQuery = paths['/app/users']['get']['parameters']['query']
export type MutateUserData = paths['/app/users']['post']['requestBody']['content']['application/json']
export type ICreatedUser = paths['/app/users']['post']['responses']['201']['content']['application/json']
export type IUser = paths['/app/users/{user_id}']['get']['responses']['200']['content']['application/json']

export function useUsers(query: UserQuery) {
  const api = useApiClient()
  return useQuery({
    queryKey: ['users', query],
    queryFn: () => api((client) => client.GET('/app/users', { params: { query } })),
    placeholderData: (previousData: any) => previousData
  })
}

export function useUser(id: number) {
  const api = useApiClient()
  const query = useQuery({
    queryKey: ['user', id],
    queryFn: () => api((client) => client.GET('/app/users/{user_id}', { params: { path: { user_id: id } } }))
  })
  return query.data
}

type CallbackFn = () => void
export function useDeleteUser(onSuccess: CallbackFn) {
  const api = useApiClient()
  return useMutation({
    mutationFn: (params: { userId: number }) =>
      api((client) => client.DELETE('/app/users/{user_id}', { params: { path: { user_id: params.userId } } })),
    onSuccess
  })
}

type MutateParams = {
  formData: MutateUserData
  userId?: number
}
export function useMutateUser(onSuccess: (response: ICreatedUser) => void) {
  const api = useApiClient()
  const mutateUser = useMutation({
    mutationFn: (mutateParams: MutateParams) => {
      return api((client) => {
        const formData = mutateParams.formData as MutateUserData
        if (mutateParams.userId) {
          return client.PATCH('/app/users/{user_id}', {
            params: { path: { user_id: mutateParams.userId } },
            body: mutateParams.formData
          })
        }

        return client.POST('/app/users', { body: formData })
      })
    },
    onSuccess
  })

  return {
    mutateUser: mutateUser.mutate
  }
}
