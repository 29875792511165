import { useDevice } from '../../../helpers/useDevice'

export enum Mode {
  DROPDOWN = 'DROPDOWN',
  ICON_BUTTONS = 'ICON_BUTTONS'
}

// TODO refactor
export function useRenderMode() {
  const { md } = useDevice()
  const showDropdown = !md

  return showDropdown ? Mode.DROPDOWN : Mode.ICON_BUTTONS
}
