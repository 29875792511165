import React, { useMemo, useRef, useState } from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { Col, Container, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import { IDraggablePrinter } from '../../../../api/types/printer'
import { IConnectState } from '../../../../api/types/state'
import { comparePrinters } from '../../../../helpers/comparePrinters'
import { onlyUnique } from '../../../../helpers/onlyUnique'
import { useDevice } from '../../../../helpers/useDevice'
import { SvgIcon } from '../../../common/SvgIcon'
import { getPrinterName } from '../../utils'
import * as S from './AvailablePrinters.styled'
import { DropTargets, getDraggableId } from './dnd'
import { Printer } from './Printer'

type Props = {
  printers: IDraggablePrinter[]
}

enum SortKeys {
  NAME = 'name',
  TYPE = 'type'
}

export function AvailablePrinters(props: Props) {
  const { printers } = props
  const [filterTechnology, setFilterTechnology] = useState('')
  const [filterType, setFilterType] = useState('')
  const [sortBy, setSortBy] = useState(SortKeys.NAME)
  const [reverse, setReverse] = useState(false)
  const [hideInUnknownState, setHideInUnknownState] = useState(false)

  const { md } = useDevice()
  const [showFilters, setShowFilters] = useState(md)
  const rowRef = useRef<HTMLDivElement>(null)
  const theme = useTheme()
  const { t } = useTranslation()

  const technologyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterTechnology(e.currentTarget.value)
    rowRef.current?.scroll(0, 0)
  }

  const typeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterType(e.currentTarget.value)
    rowRef.current?.scroll(0, 0)
  }

  const sortByChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSortBy(e.currentTarget.value === SortKeys.NAME ? SortKeys.NAME : SortKeys.TYPE)
    rowRef.current?.scroll(0, 0)
  }

  const reverseChange = (e: React.FormEvent<HTMLInputElement>) => {
    setReverse(e.currentTarget.checked)
    rowRef.current?.scroll(0, 0)
  }

  const hideInUnknownStateChange = (e: React.FormEvent<HTMLInputElement>) => {
    setHideInUnknownState(e.currentTarget.checked)
    rowRef.current?.scroll(0, 0)
  }

  const printerTypes = useMemo(
    () =>
      printers
        .map((printer) => printer.printer_type_name || '')
        .filter((name) => name !== '')
        .filter(onlyUnique),
    [printers]
  )

  const availablePrinters = useMemo(() => {
    return printers
      .filter((p) => {
        let result = true
        if (filterType && p.printer_type_name !== filterType) {
          result = false
        } else if (hideInUnknownState && p.connect_state === IConnectState.UNKNOWN) {
          result = false
        }
        // TODO: add technology filter
        return result
      })
      .sort((a, b) => (reverse ? -comparePrinters(a, b, sortBy) : comparePrinters(a, b, sortBy)))
  }, [filterType, hideInUnknownState, printers, reverse, sortBy])

  return (
    <S.BottomPanel>
      <div style={{ position: 'relative' }}>
        {!md && (
          <S.FiltersButton onClick={() => setShowFilters(!showFilters)}>
            {showFilters ? t('printer.group.hide-filters-button') : t('printer.group.show-filters-button')}
          </S.FiltersButton>
        )}
        <S.Filters style={{ maxHeight: md || showFilters ? '90vh' : 0 }}>
          <Container fluid style={{ paddingTop: 10 }}>
            <S.Row>
              <strong>{t('printer.group.available-printers-header')}</strong>
            </S.Row>
            <S.Row>
              <i>{t('printer.group.available-printers-description')}</i>
            </S.Row>
          </Container>
          <S.Row>
            <Form.Group as={Col} md="2">
              <Form.Label>{t('printer.technology')}</Form.Label>
              <Form.Control as="select" onChange={technologyChange} value={filterTechnology}>
                <option value="">{t('all')}</option>
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col} md="2">
              <Form.Label>{t('printer.type')}</Form.Label>
              <Form.Control as="select" onChange={typeChange} value={filterType}>
                <option value="">{t('all')}</option>
                {printerTypes.map((pType) => (
                  <option key={pType} value={pType}>
                    {pType}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col} md="2">
              <Form.Label>{t('sort')}</Form.Label>
              <Form.Control as="select" onChange={sortByChange} value={sortBy.toString()}>
                <option value="name">{t('sort.name')}</option>
                <option value="type">{t('sort.type')}</option>
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col} md="2" style={{ display: 'flex', alignItems: 'flex-end' }}>
              <Form.Check
                id="reverse"
                type="checkbox"
                label={t('sort.reverse')}
                onChange={reverseChange}
                checked={reverse}
              />
            </Form.Group>

            <Form.Group as={Col} md="4" style={{ display: 'flex', alignItems: 'flex-end' }}>
              <Form.Check
                id="hide-unknown"
                type="checkbox"
                label={t('printer.group.hide-printers-in-unknown-state-label')}
                onChange={hideInUnknownStateChange}
                checked={hideInUnknownState}
              />
            </Form.Group>
          </S.Row>
        </S.Filters>

        <Droppable droppableId={DropTargets.AVAILABLE_ITEMS} direction="horizontal">
          {(provided, { isDraggingOver }) => (
            <S.PrintersRow $isDraggingOver={isDraggingOver} ref={provided.innerRef} {...provided.droppableProps}>
              <S.ScrollArrow
                onClick={() => rowRef.current?.scrollBy({ left: -rowRef.current.clientWidth / 2, behavior: 'smooth' })}
              >
                <SvgIcon icon="arrowLeftIcon" fill={isDraggingOver ? 'white' : theme.colors.primary} size={34} />
              </S.ScrollArrow>
              <S.Printers ref={rowRef}>
                {availablePrinters.map((printer, index) => {
                  const draggableId = getDraggableId(printer)
                  return (
                    <Draggable
                      key={printer.isDisabled ? `disabled-${draggableId}` : draggableId}
                      draggableId={printer.isDisabled ? `disabled-${draggableId}` : draggableId}
                      index={index}
                      isDragDisabled={printer.isDisabled}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={provided.draggableProps.style}
                        >
                          <Printer
                            isDragging={snapshot.isDragging}
                            type={printer.printer_type}
                            name={getPrinterName(printer)}
                            isDisabled={printer.isDisabled}
                            disabledTooltip={printer.disabledTooltip}
                          />
                        </div>
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </S.Printers>
              <S.ScrollArrow
                $flipped
                onClick={() => rowRef.current?.scrollBy({ left: rowRef.current.clientWidth / 2, behavior: 'smooth' })}
              >
                <SvgIcon icon="arrowLeftIcon" fill={isDraggingOver ? 'white' : theme.colors.primary} size={34} />
              </S.ScrollArrow>
            </S.PrintersRow>
          )}
        </Droppable>
      </div>
    </S.BottomPanel>
  )
}
