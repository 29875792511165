/**
 * This is for internal testing purposes only.
 * Will be tossed as soon as the API calls get implemented on the backend, then we'll call the standard connect API.
 * TODO toss this in the bin
 */
import { getEnvironment, Globals } from '../helpers/getEnvironment'

const baseUrl = getEnvironment(Globals.AI_DETECTOR_API_URL) || 'http://localhost:8000'

export type Model = 'wise' | 'fine'

export type AIDetectorResponse = {
  id: number
  file_id: string
  model: Model
  result: string
  result_description: string
  probability: number
  processing_time_sec: number
}

export function uploadToAiDetector(file: File) {
  const formData = new FormData()
  formData.append('file', file)
  return fetch(`${baseUrl}/v1/upload`, {
    method: 'POST',
    body: formData
  })
}

export function getAiDetectorResult(snapshotId: string, model: Model): Promise<AIDetectorResponse> {
  return new Promise((resolve, reject) => {
    fetch(`${baseUrl}/v1/process/${snapshotId}?model=${model}`)
      .then((response) => response.json())
      .then((data) => {
        resolve(data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function updateResultRating(resultId: number, response: boolean) {
  return fetch(`${baseUrl}/v1/user_response/${resultId}`, {
    method: 'PUT',
    body: JSON.stringify({ response, notes: '' }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
