import styled from 'styled-components'

import { usePermissions } from '../../../context/permissionsStore'
import { usePrinter } from '../../../context/printerContext'
import { Dropdown, DropdownItemLink } from '../../common/Dropdown'
import { WithTooltip } from '../../common/WithTooltip'
import { ThreeDots } from '../../header/DotsDropdown'
import { usePrinterMeatballs } from '../../printerNavigation'

const Wrapper = styled.div`
  position: absolute;
  right: 0;
  top: 8px;
`

export function Meatballs() {
  const {
    printer: { uuid, team_id }
  } = usePrinter()
  const permissions = usePermissions(team_id)
  const additionalPrinterRoutes = usePrinterMeatballs(permissions)

  return (
    <Wrapper>
      <Dropdown trigger={<ThreeDots />}>
        {additionalPrinterRoutes.map((item, i) => {
          const url = `/printer/${uuid}/${item.to}`

          if (item.disabled) {
            return (
              <WithTooltip
                key={item.title}
                id={`disabled-meatball-item-${i}`}
                title={item.disabledTooltip}
                placement="top"
              >
                <DropdownItemLink key={item.title} to="#" disabled>
                  {item.title}
                </DropdownItemLink>
              </WithTooltip>
            )
          }
          return (
            <DropdownItemLink key={item.title} to={url}>
              {item.title}
            </DropdownItemLink>
          )
        })}
      </Dropdown>
    </Wrapper>
  )
}
