import dayjs from 'dayjs'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import { IPrinterDetailJobInfo } from '../../../api/types/job'
import { IPrinterSimpleView } from '../../../api/types/printer'
import { getState, IConnectState } from '../../../api/types/state'
import { convertDistance, formatPercents, THINSP } from '../../../helpers/formatters'
import { isDefined } from '../../../helpers/std'
import { getJobTitle } from '../../../hooks/useJobTitle'
import { useLoggedUserPreferences, useLoggedUserSettings } from '../../../hooks/useLoggedUser'
import { useIsSla } from '../../../hooks/usePrinterType'
import { ITimetype } from '../../../interfaces/time'
import { PrintersPreviewEnum } from '../../../interfaces/view'
import { getColorScheme } from '../../../theme/getColorScheme'
import { Axis } from '../../common/Axis'
import { LongContentAutoScroll } from '../../common/LongContentAutoScroll'
import { PlainButton } from '../../common/PlainButton'
import { PrinterIcon } from '../../common/PrinterIcon'
import { PrinterStateTag } from '../../common/PrinterStateTag'
import { PulsingTemperature } from '../../common/PulsingTemperature'
import { SvgIcon } from '../../common/SvgIcon'
import { Time } from '../../helpers/time'
import { UncertaintySign } from '../../helpers/UncertaintySign'
import { ConnectStateEnum, ISortableParam, PrinterFamily, PrinterParamEnum } from '../../preferences/types'
import { CurrentFileControls } from '../control/CurrentFileControls'
import { useCurrentJob } from '../hooks/useCurrentJob'
import { getPrinterName } from '../utils'
import * as S from './CardItem.styled'

type Props = {
  printer: IPrinterSimpleView
  preview?: boolean
}

enum View {
  OVERVIEW,
  JOB
}

const PreferencesParam = ({
  printer,
  name,
  jobInfo,
  timePrinting,
  timeRemaining,
  estimatedEnd,
  units
}: {
  printer: IPrinterSimpleView
  name: PrinterParamEnum
  jobInfo?: IPrinterDetailJobInfo
  timePrinting: number
  timeRemaining: number
  estimatedEnd: number
  units: any
}) => {
  const { t } = useTranslation()

  if (name === PrinterParamEnum.TIME_REMAINING && timeRemaining >= -1) {
    return (
      <div>
        <S.ParamLabel>{t('table-column.remaining-time')}</S.ParamLabel>
        <S.ParamValue>
          <Time unixTimestamp={timeRemaining} type={ITimetype.COUNTDOWN} noSeconds />
          <UncertaintySign printerSpeed={printer.speed} />
        </S.ParamValue>
      </div>
    )
  }

  if (name === PrinterParamEnum.PRINTING_TIME && timePrinting >= 0) {
    return (
      <div>
        <S.ParamLabel>{t('table-column.printing-time')}</S.ParamLabel>
        <S.ParamValue>
          <Time unixTimestamp={timePrinting} type={ITimetype.DURATION} noSeconds />
        </S.ParamValue>
      </div>
    )
  }

  if (name === PrinterParamEnum.ESTIMATED_END && estimatedEnd > 0) {
    return (
      <div>
        <S.ParamLabel>{t('table-column.estimated-end')}</S.ParamLabel>
        <S.ParamValue>
          <Time unixTimestamp={estimatedEnd} noSeconds wrap />
        </S.ParamValue>
      </div>
    )
  }

  if (
    name === PrinterParamEnum.HEIGHT &&
    isDefined(jobInfo?.print_height) &&
    isDefined(jobInfo?.total_height) &&
    jobInfo
  ) {
    return (
      <div>
        <S.ParamLabel>{t('table-column.height', 'Height')}</S.ParamLabel>
        <S.ParamValue>
          {convertDistance(jobInfo.print_height, units.dimensions)}/
          {`${convertDistance(jobInfo.total_height, units.dimensions)}${THINSP}${units.dimensions}`}
        </S.ParamValue>
      </div>
    )
  }

  if (name === PrinterParamEnum.NOZZLE && isDefined(printer.temp?.temp_nozzle)) {
    return (
      <div>
        <S.ParamLabel>{t('table-column.nozzle')}</S.ParamLabel>
        <S.ParamValue>
          <PulsingTemperature value={printer.temp?.temp_nozzle} targetValue={printer.temp?.target_nozzle} />
        </S.ParamValue>
      </div>
    )
  }

  if (name === PrinterParamEnum.SPEED && isDefined(printer.speed)) {
    return (
      <div>
        <S.ParamLabel>{t('table-column.speed')}</S.ParamLabel>
        <S.ParamValue>{formatPercents(printer.speed)}</S.ParamValue>
      </div>
    )
  }

  if (name === PrinterParamEnum.MATERIAL && printer.filament?.material) {
    return (
      <div>
        <S.ParamLabel>{t('table-column.material')}</S.ParamLabel>
        <S.ParamValue>{printer.filament?.material}</S.ParamValue>
      </div>
    )
  }

  if (name === PrinterParamEnum.HEATBED && isDefined(printer.temp?.temp_bed)) {
    return (
      <div>
        <S.ParamLabel>{t('table-column.heatbed')}</S.ParamLabel>
        <S.ParamValue>
          <PulsingTemperature value={printer.temp?.temp_bed} targetValue={printer.temp?.target_bed} />
        </S.ParamValue>
      </div>
    )
  }

  if (name === PrinterParamEnum.Z_AXIS && isDefined(printer.axis_z)) {
    return (
      <div>
        <S.ParamLabel>{t('table-column.z-axis')}</S.ParamLabel>
        <S.ParamValue>
          <Axis value={printer.axis_z} />
        </S.ParamValue>
      </div>
    )
  }

  return null
}

export function CardItem(props: Props) {
  const { printer, preview = false } = props
  const { t } = useTranslation()
  const { job_info, connect_state } = printer
  const isSla = useIsSla(printer.printer_type)

  const isPrinting = [IConnectState.PAUSED, IConnectState.PRINTING].includes(connect_state)
  const [view, setView] = useState(isPrinting ? View.JOB : View.OVERVIEW)
  const { estimatedEnd, timeRemaining, timePrinting } = useCurrentJob(connect_state, job_info)
  const stateConfig = getState(connect_state)
  const theme = useTheme()
  const colorScheme = getColorScheme(stateConfig.baseColor, theme.name === 'dark')

  const units = useLoggedUserPreferences('units')

  const idleFdmCardParams = useLoggedUserSettings(
    'printers',
    PrinterFamily.FDM,
    PrintersPreviewEnum.CARDS,
    ConnectStateEnum.IDLE
  )
  const idleSlaCardParams = useLoggedUserSettings(
    'printers',
    PrinterFamily.SLA,
    PrintersPreviewEnum.CARDS,
    ConnectStateEnum.IDLE
  )
  const progressFdmCardParams = useLoggedUserSettings(
    'printers',
    PrinterFamily.FDM,
    PrintersPreviewEnum.CARDS,
    ConnectStateEnum.PRINTING
  )
  const progressSlaCardParams = useLoggedUserSettings(
    'printers',
    PrinterFamily.SLA,
    PrintersPreviewEnum.CARDS,
    ConnectStateEnum.PRINTING
  )

  const renderParameters = () => {
    return (
      <S.Params>
        {idleFdmCardParams.map((component: ISortableParam) => {
          if (component.visible === false || !job_info) {
            return null
          }
          return (
            <PreferencesParam
              key={component.name}
              printer={printer}
              name={component.name as PrinterParamEnum}
              timePrinting={timePrinting}
              timeRemaining={timeRemaining}
              estimatedEnd={estimatedEnd}
              jobInfo={job_info}
              units={units}
            />
          )
        })}
      </S.Params>
    )
  }

  const renderSlaParameters = () => {
    return (
      <S.Params>
        {idleSlaCardParams.map((component: ISortableParam) => {
          if (component.visible === false) {
            return null
          }
          return (
            <PreferencesParam
              key={component.name}
              printer={printer}
              name={component.name as PrinterParamEnum}
              timePrinting={timePrinting}
              timeRemaining={timeRemaining}
              estimatedEnd={estimatedEnd}
              jobInfo={job_info}
              units={units}
            />
          )
        })}
      </S.Params>
    )
  }

  const renderJobInfo = () => {
    if (!job_info) {
      return <S.NoJobInfo>{t('printers.card.no-job-info')}</S.NoJobInfo>
    }
    return (
      <>
        <div className="mb-2">
          <S.FileTitleControls style={{ pointerEvents: preview ? 'none' : 'auto' }}>
            <span>{t('table-column.file')}</span>
            <CurrentFileControls printer={printer} />
          </S.FileTitleControls>
          <S.ParamValue>
            <LongContentAutoScroll>{getJobTitle(t, job_info)}</LongContentAutoScroll>
          </S.ParamValue>
        </div>
        <div className="d-flex">
          <div className="mr-3 flex-shrink-0">
            <S.ParamLabel>{t('table-column.progress')}</S.ParamLabel>
            <S.ParamValue $large>{formatPercents(job_info.progress || 0)}</S.ParamValue>
          </div>
          <S.Params>
            {isSla
              ? progressSlaCardParams.map((component: ISortableParam) => {
                  if (component.visible === false) {
                    return null
                  }
                  return (
                    <PreferencesParam
                      key={component.name}
                      printer={printer}
                      name={component.name as PrinterParamEnum}
                      timePrinting={timePrinting}
                      timeRemaining={timeRemaining}
                      estimatedEnd={estimatedEnd}
                      jobInfo={job_info}
                      units={units}
                    />
                  )
                })
              : progressFdmCardParams.map((component: ISortableParam) => {
                  if (component.visible === false) {
                    return null
                  }
                  return (
                    <PreferencesParam
                      key={component.name}
                      printer={printer}
                      name={component.name as PrinterParamEnum}
                      timePrinting={timePrinting}
                      timeRemaining={timeRemaining}
                      estimatedEnd={estimatedEnd}
                      jobInfo={job_info}
                      units={units}
                    />
                  )
                })}
          </S.Params>
        </div>
      </>
    )
  }

  return (
    <S.Container
      to={preview ? '#' : `/printer/${printer.uuid}`}
      style={{ cursor: preview ? 'default' : 'pointer' }}
      $progress={isPrinting ? job_info?.progress : undefined}
      $background={isPrinting ? colorScheme.backgroundSecondary.background : undefined}
      $progressColor={colorScheme.progress.background}
      $hoverColor={colorScheme.hover.background}
      $isTransparent={printer.connect_state === IConnectState.OFFLINE}
    >
      <S.Header $background={colorScheme.progress.background} className="card-header">
        <S.Name>{getPrinterName(printer)}</S.Name>
        <div className="ml-auto d-flex align-items-center gap-2">
          {isPrinting && (
            <PlainButton
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                setView((prev) => (prev === View.JOB ? View.OVERVIEW : View.JOB))
              }}
            >
              <SvgIcon icon={view === View.OVERVIEW ? 'jobDetailIcon' : 'printerOverviewIcon'} size={24} />
            </PlainButton>
          )}
          <PrinterStateTag
            state={connect_state}
            tooltip={
              connect_state === IConnectState.OFFLINE && printer.last_online
                ? dayjs.unix(printer.last_online).fromNow()
                : undefined
            }
          />
        </div>
      </S.Header>
      <S.Content>
        {view === View.OVERVIEW ? (
          <>
            <div className="d-flex align-items-center gap-2 mb-2">
              <PrinterIcon size={44} type={printer.printer_type} title={printer.printer_type_name} />
              <div>
                <S.PrinterType>{printer.printer_type_name}</S.PrinterType>
                {printer.firmware && (
                  <S.Firmware>
                    {t('table-column.firmware')}: {printer.firmware}
                  </S.Firmware>
                )}
              </div>
            </div>
            {isSla ? renderSlaParameters() : renderParameters()}
          </>
        ) : (
          renderJobInfo()
        )}
      </S.Content>
    </S.Container>
  )
}
