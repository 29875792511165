import { useTranslation } from 'react-i18next'

import { formatLength } from '../../helpers/formatters'
import { useLoggedUserPreferences } from '../../hooks/useLoggedUser'

export function Axis({ value, decimals }: { value?: number; decimals?: number }) {
  const { i18n } = useTranslation()
  const units = useLoggedUserPreferences('units')

  return <>{formatLength(units.dimensions, i18n.language, value, false, decimals)}</>
}
