import { IPrinterSimpleView } from '../../api/types/printer'
import { ActionsRenderer } from '../common/AdaptiveButton/ActionsRenderer'
import { RemoveCurrentTransferAction } from './actions/RemoveCurrentTransferAction'

type Props = {
  printer: IPrinterSimpleView
  foldable?: boolean
}

export function CurrentTransferActions({ printer, foldable }: Props) {
  return (
    <ActionsRenderer foldable={foldable}>
      <RemoveCurrentTransferAction printer={printer} />
    </ActionsRenderer>
  )
}
