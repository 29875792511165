import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { useApiClient } from '../../../api/react'
import { IFileType, IPrintFile } from '../../../api/types/file'
import { IGcodeMetadata } from '../../../api/types/metadata'
import { IPrinterSimpleView } from '../../../api/types/printer'
import { usePrinterStorageByUuid } from '../../../hooks/storageLocations/usePrinterStorage'
import { LoadingButtonOutlined } from '../../common/LoadingButtonOutlined'
import { StartCopyAndPrintAction } from './StartCopyAndPrintAction'
import { PrintVariant, StartPrintAction } from './StartPrintAction'

type Props = {
  uploaded?: number
  hash: string
  name: string
  teamId: number
  label: string
  printer: IPrinterSimpleView
  onSuccess?: () => void
}

export function StartPrintOrCopyAndPrintAction(props: Props) {
  const { uploaded, hash, name, teamId, printer, label, onSuccess } = props
  const printerStorage = usePrinterStorageByUuid(printer.uuid)
  const { t } = useTranslation()

  // TODO - hack due to missing path
  const api = useApiClient()
  const { data, isLoading } = useQuery([`/file-detail/${teamId}/${printer.uuid}/${hash}`], () => {
    return api.app.files.getFile(Number(teamId), String(hash), printer.uuid)
  })

  if (!data || data.type !== IFileType.PRINT_FILE) {
    // typecheck - should not happen
    return null
  }

  const file: IPrintFile = data
  const metadata = file?.meta as IGcodeMetadata

  if (!file?.path && printerStorage) {
    return (
      <StartCopyAndPrintAction
        teamId={teamId}
        hash={hash}
        name={name}
        printerStorage={printerStorage}
        uploaded={uploaded}
        printer={printer}
        label={label}
        filamentType={metadata?.filament_type}
        onSuccess={onSuccess}
        withConfirm
      />
    )
  }

  if (file?.path) {
    return (
      <StartPrintAction
        filamentType={metadata?.filament_type}
        printer={printer}
        variant={PrintVariant.PRINT}
        path={file.path}
        label={label}
        onSuccess={onSuccess}
        withConfirm
      />
    )
  }

  return (
    <LoadingButtonOutlined
      isLoading={isLoading}
      disabled={!isLoading}
      disabledTooltip={t('printer.no-storage', 'Printer has no storage loaded')}
    >
      {t('printer.actions.select-printer.label')}
    </LoadingButtonOutlined>
  )
}
