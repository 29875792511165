import { createContext, ReactNode, useContext, useReducer, useRef } from 'react'

import { useApiClient } from '../api/react'
import { PrinterUuid } from '../api/types/printer'
import { CancelablePromise } from '../api/types/sdk'

export type UploadState = {
  file: File
  uploadedSize: number
  total: number
  done: boolean
}

type FileName = string

export type FileUploads = {
  [key: FileName]: UploadState | undefined
}

type PrinterState = {
  [key: PrinterUuid]: FileUploads | undefined
}

type ContextType = {
  state: PrinterState
  startUpload: (
    uploadId: number,
    teamId: number,
    file: File,
    storagePath: string,
    printerUuid: PrinterUuid,
    addToPrintQueue: boolean,
    printAfterTransfer: boolean,
    downloadToPrinter?: boolean
  ) => Promise<unknown>
  remove: (printerUuid: PrinterUuid, uploadId: number) => void
  reset: (printerUuid: PrinterUuid, uploadId: number) => void
}

const UploadContext = createContext<ContextType>({
  state: {},
  startUpload: () => new Promise<unknown>(() => {}),
  remove: () => null,
  reset: () => null
})

enum UploadActionType {
  START_UPLOAD,
  UPDATE_PROGRESS,
  REMOVE
}

type UploadActions =
  | { type: UploadActionType.START_UPLOAD; printerUuid: PrinterUuid; file: File; uploadId: number }
  | {
      type: UploadActionType.UPDATE_PROGRESS
      printerUuid: PrinterUuid
      file: File
      uploadedSize: number
      total: number
      done: boolean
      uploadId: number
    }
  | { type: UploadActionType.REMOVE; printerUuid: PrinterUuid; uploadId: number }

function uploadReducer(state: PrinterState, action: UploadActions): PrinterState {
  switch (action.type) {
    case UploadActionType.START_UPLOAD: {
      return {
        ...state,
        [action.printerUuid]: {
          ...state[action.printerUuid],
          [action.uploadId]: {
            file: action.file,
            uploadedSize: 0,
            total: 0,
            done: false
          }
        }
      }
    }
    case UploadActionType.UPDATE_PROGRESS: {
      const previous = state[action.printerUuid]?.[action.uploadId]
      if (!previous) {
        return state
      }

      return {
        ...state,
        [action.printerUuid]: {
          ...state[action.printerUuid],
          [action.uploadId]: {
            ...previous,
            uploadedSize: action.uploadedSize,
            total: action.total,
            done: action.done
          }
        }
      }
    }
    case UploadActionType.REMOVE: {
      const newState = { ...state }
      delete newState[action.printerUuid]?.[action.uploadId]
      return newState
    }
  }
}

export function UploadContextProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(uploadReducer, {})
  const api = useApiClient()

  const promises = useRef(new Map<number, CancelablePromise<unknown>>())

  const startUpload = async (
    uploadId: number,
    teamId: number,
    file: File,
    storagePath: string,
    printerUuid: PrinterUuid,
    addToPrintQueue: boolean,
    printAfterTransfer: boolean,
    downloadToPrinter?: boolean
  ) => {
    dispatch({
      type: UploadActionType.START_UPLOAD,
      file,
      uploadId,
      printerUuid
    })

    let url = `/app/teams/${teamId}/files/raw?to_queue=${addToPrintQueue}&to_print=${printAfterTransfer}&upload_id=${uploadId}`

    if (downloadToPrinter) {
      url += `&printer_uuid=${printerUuid}&path=${storagePath}/${encodeURIComponent(file.name)}`
    } else {
      url += `&filename=${encodeURIComponent(file.name)}`
    }

    const promise = api.app.files.uploadFile(url, file, {
      onProgress: (progressEvent) => {
        dispatch({
          type: UploadActionType.UPDATE_PROGRESS,
          done: false,
          printerUuid,
          file,
          uploadedSize: progressEvent.loaded,
          total: (progressEvent.loaded / progressEvent.total) * 100,
          uploadId
        })
      },
      onSuccess: (uploadedFile) => {
        dispatch({
          type: UploadActionType.UPDATE_PROGRESS,
          done: true,
          printerUuid,
          file,
          uploadedSize: uploadedFile.size,
          total: 100,
          uploadId
        })

        dispatch({
          type: UploadActionType.REMOVE,
          printerUuid,
          uploadId
        })

        // Clear promise cache
        promises.current.delete(uploadId)
      }
    })

    // Save promise for cancellation
    promises.current.set(uploadId, promise)
    return promise
  }

  const remove = (printerUuid: PrinterUuid, uploadId: number) => {
    dispatch({ type: UploadActionType.REMOVE, printerUuid, uploadId })
    const pendingPromise = promises.current.get(uploadId)

    if (pendingPromise) {
      pendingPromise.cancel()
      promises.current.delete(uploadId)
    }
  }

  const reset = (printerUuid: PrinterUuid, uploadId: number) => {
    dispatch({ type: UploadActionType.REMOVE, printerUuid, uploadId })
  }

  const value = { state, startUpload, remove, reset }

  return <UploadContext.Provider value={value}>{children}</UploadContext.Provider>
}

export function useUploadContext(printerUuid: PrinterUuid) {
  const context = useContext(UploadContext)

  const startUpload = (
    uploadId: number,
    teamId: number,
    file: File,
    storagePath: string,
    addToPrintQueue: boolean,
    printAfterTransfer: boolean,
    downloadToPrinter?: boolean
  ) =>
    context.startUpload(
      uploadId,
      teamId,
      file,
      storagePath,
      printerUuid,
      addToPrintQueue,
      printAfterTransfer,
      downloadToPrinter
    )

  const remove = (uploadId: number) => context.remove(printerUuid, uploadId)
  const reset = (uploadId: number) => context.reset(printerUuid, uploadId)

  return { state: context.state[printerUuid], startUpload, remove, reset }
}
