import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { useApiClient } from '../../api/react'
import { intervals } from '../../config'
import { usePrintersUrl } from '../../hooks/usePrintersUrl'
import { AddNewPrinterButton } from '../common/AddNewPrinterButton'
import { MegaMenu, MegaMenuItemLink } from '../common/MegaMenu'
import * as S from './HeaderMenu.styled'
import { NavigationDropdownToggle } from './NavigationDropdownToggle'

export const MenuItem = S.MenuItem
export const MenuLink = S.MenuLink
export const MenuLinkExternal = S.MenuLinkExternal

const PRINTERS_LIMIT = 3
export const ESHOP_URL = 'https://prusa3d.com/'

export const getExternalLinks = (t: Function) => {
  return [
    {
      href: 'https://help.prusa3d.com/category/prusa-connect-prusalink_1636',
      label: t('menu.help')
    },
    { href: 'https://forum.prusa3d.com/forum/prusa-connect-prusalink/', label: t('menu.forum') }
    /* {
      href: ESHOP_URL,
      label: t('menu.eshop')
    } */
  ]
}

export const useExternalLinks = () => {
  const { t } = useTranslation()
  const links = getExternalLinks(t)

  return (
    <>
      {links.map((link, i) => (
        <MenuItem key={i}>
          <MenuLinkExternal href={link.href} target="_blank">
            {link.label}
          </MenuLinkExternal>
        </MenuItem>
      ))}
    </>
  )
}

export function HeaderMenu() {
  const { t } = useTranslation()
  const api = useApiClient()
  const printersUrl = usePrintersUrl()
  const externalLinks = useExternalLinks()
  const { data, isLoading } = useQuery(
    ['/printers-to-megamenu'],
    () => api.app.printers.getPrinters({ limit: PRINTERS_LIMIT }),
    {
      // dont cache, we need to get fresh data everytime this component mounts, otherwise this could resolve wrong total from cache before the fresh load and execute wrong redirect
      cacheTime: 0,
      refetchInterval: intervals.printersPolling
    }
  )

  // Wait for data
  if (!data || isLoading) {
    return null
  }

  const {
    pager: { total }
  } = data

  const renderPrintersItem = () => {
    if (total > 1 && total <= PRINTERS_LIMIT) {
      return (
        <S.MenuItem>
          <MegaMenu
            trigger={
              <NavigationDropdownToggle className="font-weight-bold">{t('menu.printers')}</NavigationDropdownToggle>
            }
            gridTemplateColumns={`repeat(${data.printers.length}, minmax(0, 1fr))`}
          >
            {data.printers.map((printer) => (
              <MegaMenuItemLink key={printer.uuid} printer={printer} basic={data.printers.length === PRINTERS_LIMIT} />
            ))}
          </MegaMenu>
        </S.MenuItem>
      )
    }
    if (total > 1) {
      return (
        <S.MenuItem>
          <S.MenuLink to={printersUrl}>{t('menu.printers')}</S.MenuLink>
        </S.MenuItem>
      )
    }
    if (total <= 1) {
      return <AddNewPrinterButton />
    }
    return null
  }

  return (
    <S.HeaderMenu>
      {/* <S.MenuItem>
        <S.MenuLink to="/printers/overview">{t('menu.dashboard')}</S.MenuLink>
      </S.MenuItem> */}

      {renderPrintersItem()}

      <S.MenuItem>
        <S.MenuLink to="/storage/connect">{t('menu.connect-files')}</S.MenuLink>
      </S.MenuItem>

      {/* <S.MenuItem>
        <S.MenuLink to="/jobs">{t('menu.jobs')}</S.MenuLink>
      </S.MenuItem> */}

      {externalLinks}
    </S.HeaderMenu>
  )
}
