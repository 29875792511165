import { parse, ParsedQuery, ParseOptions } from 'query-string'
import { useMemo } from 'react'
import { useLocation } from 'react-router'

export function useQueryParams(options?: ParseOptions): ParsedQuery<string> {
  const location = useLocation()

  return useMemo(() => {
    return parse(location.search, { arrayFormat: 'bracket', ...options })
  }, [location.search, options])
}
