import styled from 'styled-components'

export const P = styled.p`
  font-size: 16px;
  margin-top: 1em;
`

export const H2 = styled.h2`
  font-weight: 400;
  font-size: 24px;
  margin-top: 1em;
`

export const GrayText = styled.span`
  color: ${({ theme }) => theme.colors.light};
`
