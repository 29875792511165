import { useMemo } from 'react'
import { StylesConfig } from 'react-select'
import { useTheme } from 'styled-components'

import { Layers } from '../../helpers/zIndex'

const HEIGHT = 36

export function useStyles<OptionType, IsMulti extends boolean = false>() {
  const theme = useTheme()

  const styles = useMemo((): StylesConfig<OptionType, IsMulti> => {
    return {
      menu: (styles) => ({
        ...styles,
        zIndex: Layers.DROPDOWN,
        backgroundColor: theme.colors.backgrounds.body,
        border: theme.name === 'dark' ? '1px solid var(--border-secondary)' : 'none'
      }),
      menuList: (styles) => ({
        ...styles
      }),
      clearIndicator: (styles) => ({
        ...styles,
        padding: '0 4px',
        height: '100%',
        display: 'flex',
        alignItems: 'center'
      }),
      dropdownIndicator: (styles) => ({
        ...styles,
        padding: '0 4px',
        height: '100%',
        display: 'flex',
        alignItems: 'center'
      }),
      placeholder: (styles) => ({ ...styles, fontStyle: 'italic' }),
      valueContainer: (styles) => ({ ...styles, padding: '0 4px' }),
      singleValue: (styles) => ({ ...styles, color: 'var(--color-light)' }),
      input: (styles) => ({ ...styles, color: 'var(--color-light)' }),
      indicatorsContainer: (styles) => ({ ...styles, padding: 0 }),
      multiValue: (styles) => ({ ...styles, margin: 1 }),
      control: (styles, state) => ({
        ...styles,
        minHeight: HEIGHT,
        boxShadow: state.isFocused ? `0 0 1px ${theme.colors.primary}` : 'none',
        backgroundColor: 'var(--background-body)',
        borderColor: state.isFocused ? theme.colors.primary : theme.colors.borders.secondary,
        '&:hover': {
          borderColor: state.isFocused ? theme.colors.primary : styles.borderColor
        }
      }),
      option: (styles, state) => ({
        ...styles,
        padding: '0.25rem 0.75rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        fontSize: '14px',
        color: 'inherit',
        backgroundColor: state.isFocused || state.isSelected ? 'var(--background-primary)' : 'none',
        ':active': {
          ...styles[':active'],
          backgroundColor: state.isFocused || state.isSelected ? 'var(--background-primary)' : 'none'
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        fontWeight: state.data.fontWeight
      })
    }
  }, [theme.colors.backgrounds.body, theme.colors.borders.secondary, theme.colors.primary, theme.name])

  return styles
}
