import { CSSProperties } from 'react'

import { Icon, Icons } from '../../icons'

type Props = {
  icon: Icon
  size?: number | string
  title?: string
  className?: string
  onClick?: () => void
  style?: CSSProperties
  fill?: string
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

export function SvgIcon(props: Props) {
  const { className, size = 24, icon, title, onClick, style, fill } = props
  const IconComponent = Icons[icon]

  return (
    <IconComponent
      onClick={onClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      className={className}
      style={style}
      height={size}
      width={size}
      title={title}
      fill={fill}
    />
  )
}
