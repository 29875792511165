import { ChangeEvent, useRef, useState } from 'react'
import { Button, Col, Form, FormControl, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { NavigateFunction, useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'

import { AvailablePrinterTypes, buddyPrinters, printersWithIniFile } from '../../../api/types/printer'
import { useLoggedUser } from '../../../hooks/useLoggedUser'
import { useTeamName } from '../../../hooks/useTeamName'
import { useWritableTeams } from '../../../hooks/useTeams'
import { device } from '../../../interfaces/device'
import { returnSteps, Step } from '../../../pages/AddPrinterPage'
import { useAddPrinterService } from '../../../services/useAddPrinterService'
import { SvgIcon } from '../../common/SvgIcon'
import { MutationStatus } from '../../mutationStatus'
import { RequiredMark } from '../../users/UserForm'
import { GrayText } from './styled'

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0;

  @media ${device.ltXs} {
    flex-direction: column;
    gap: 1rem;
    button {
      width: 100%;
    }
    .col,
    .col-auto {
      padding-left: 0;
      padding-right: 0;
    }
  }
`

const WizardButton = styled(Button)`
  text-transform: lowercase;
  span {
    display: inline-block; /* :first-letter is applicable to blocks */
  }
  span:first-letter {
    text-transform: uppercase;
  }
`

export const StyledForm = styled(Form)`
  display: flex;
  justify-content: center;
  * > :valid {
    background-image: none !important;
  }
`

type FormControlElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement

type Props = {
  printerType?: string
  printerTypeName?: string
  stepIndex: number
}

type IPrinterType = {
  code: string
}

export function SetupPrinterForm(props: Props) {
  const { stepIndex } = props
  const theme = useTheme()
  const { t } = useTranslation()
  const steps = returnSteps(t, props.printerType)
  const { writableTeams } = useWritableTeams()
  const { default_team_id } = useLoggedUser()
  const navigate = useNavigate()
  const addPrinter = useAddPrinterService((createdPrinter) => {
    if (props.printerType && printersWithIniFile.includes(props.printerType as AvailablePrinterTypes)) {
      navigate(`../download/${createdPrinter.printer_type}/${createdPrinter.uuid}`)
    } else {
      navigate(`/printer/${createdPrinter.uuid}`)
    }
  })
  const location = useLocation()
  const state = location.state as IPrinterType
  const [selectedTeam, setSelectedTeam] = useState(writableTeams.find((team) => team.id === default_team_id))
  const [printerCode, setPrinterCode] = useState(state?.code || '')
  const [printerName, setPrinterName] = useState('')
  const [printerLocation, setPrinterLocation] = useState('')
  const [validated, setValidated] = useState(false)
  const { getTeamName } = useTeamName()

  const formRef = useRef<HTMLFormElement | null>(null)
  const isBuddyPrinter = props.printerType && buddyPrinters.includes(props.printerType as AvailablePrinterTypes)
  const isUnknown = !Object.values(AvailablePrinterTypes).includes(props.printerType as AvailablePrinterTypes)

  const handleSubmit = () => {
    if (((isBuddyPrinter || isUnknown) && !printerCode) || !selectedTeam) {
      if (formRef.current) {
        formRef.current.onsubmit = (e) => {
          e.preventDefault()
        }
        setValidated(true)
        return formRef.current.dispatchEvent(new Event('submit'))
      }
    }

    if (selectedTeam && selectedTeam.id /* && props.printerType */ && !addPrinter.isLoading) {
      addPrinter.mutate({
        code: printerCode,
        name: printerName,
        location: printerLocation,
        printer_type: props.printerType,
        team_id: selectedTeam.id
      })
    }
  }

  const handleChange = (e: ChangeEvent<FormControlElement>) => {
    e.preventDefault()
    const optionValue = e.target.value
    setSelectedTeam(writableTeams.find((team) => team.id === Number(optionValue)))
  }

  const prev = () => navigate(`../${steps[stepIndex - 1]?.to}`)
  const next = () => {
    navigate(steps[stepIndex + 1].to)
  }

  const hasNext = stepIndex > 0 && stepIndex < steps.length && steps[stepIndex].next

  const renderBottom = (nextFunc?: (steps: Step[], stepIndex: number, navigate: NavigateFunction) => void) => {
    return (
      <Buttons>
        <Col xs={!hasNext && 'auto'}>
          {stepIndex > 0 && steps[stepIndex].prev && (
            <WizardButton variant="outline-dark" onClick={prev}>
              <SvgIcon icon="arrowLeftIcon" size={16} fill={theme.colors.icons.primary} />
              <span>{steps[stepIndex - 1].name}</span>
            </WizardButton>
          )}
        </Col>
        <Col xs="auto">
          {hasNext && (
            <WizardButton variant="outline-dark" onClick={nextFunc ? () => nextFunc(steps, stepIndex, navigate) : next}>
              <span>{steps[stepIndex + 1].name}</span>
              <SvgIcon icon="arrowRightIcon" size={16} fill={theme.colors.icons.primary} />
            </WizardButton>
          )}
        </Col>
      </Buttons>
    )
  }

  return (
    <>
      {hasNext && (
        <StyledForm ref={formRef} validated={validated} noValidate>
          <Row className="mb-3">
            <Col>
              {(isBuddyPrinter || isUnknown) && (
                <Form.Group controlId="printerCode">
                  <Form.Label>
                    {t('printer.form.code-label')}
                    <RequiredMark>*</RequiredMark>
                  </Form.Label>
                  <FormControl
                    required
                    value={printerCode}
                    onChange={(e) => setPrinterCode(e.target.value)}
                    placeholder={t('printer.form.code-placeholder')}
                    type="text"
                  />
                  <Form.Control.Feedback type="invalid">{validated ? t('forms.required') : ''}</Form.Control.Feedback>
                </Form.Group>
              )}

              <Form.Group controlId="printerName">
                <Form.Label>
                  {t('printer.form.name-label')}
                  <GrayText> ({t('printer.form.optional')})</GrayText>
                </Form.Label>
                <FormControl
                  value={printerName}
                  onChange={(e) => setPrinterName(e.target.value)}
                  placeholder={props.printerTypeName || t('printer.form.name-placeholder')}
                  type="text"
                />
              </Form.Group>

              <Form.Group controlId="printerLocation">
                <Form.Label>
                  {t('printer.form.location-label')}
                  <GrayText> ({t('printer.form.optional')})</GrayText>
                </Form.Label>
                <FormControl
                  value={printerLocation}
                  onChange={(e) => setPrinterLocation(e.target.value)}
                  placeholder={t('printer.form.location-placeholder')}
                  type="text"
                />
              </Form.Group>
              {writableTeams.length > 1 && (
                <Form.Group controlId="printerTeams">
                  <Form.Label>
                    {t('printer.form.teams-label')}
                    <RequiredMark>*</RequiredMark>
                  </Form.Label>
                  <FormControl required as="select" value={selectedTeam?.id || ''} onChange={handleChange} type="text">
                    {writableTeams.map((team) => (
                      <option key={team.id} value={team.id}>
                        {getTeamName(team)}
                      </option>
                    ))}
                  </FormControl>
                </Form.Group>
              )}

              <MutationStatus mutation={addPrinter} />
            </Col>
          </Row>
        </StyledForm>
      )}
      {renderBottom(handleSubmit)}
    </>
  )
}

export default SetupPrinterForm
