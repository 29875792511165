import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { ISnapshot } from '../../../api/types/cameras'
import { IPrinterSimpleView } from '../../../api/types/printer'
import { getState, IConnectState, isPrintingState } from '../../../api/types/state'
import { useCanControl } from '../../../context/permissionsStore'
import { formatPercents } from '../../../helpers/formatters'
import { isDefined } from '../../../helpers/std'
import { useCommandMutation } from '../../../hooks/commands/useCommandMutation'
import { useLoggedUserSettings } from '../../../hooks/useLoggedUser'
import { useIsSla } from '../../../hooks/usePrinterType'
import { useTeams } from '../../../hooks/useTeams'
import { ITimetype } from '../../../interfaces/time'
import { PrintersPreviewEnum } from '../../../interfaces/view'
import { STALE_TIME_IDLE, STALE_TIME_PRINTING } from '../../camera/CameraDetail'
import { DataItem } from '../../common/DataItemList'
import { FilamentColorTag } from '../../common/FilamentColorTag'
import { ListItem } from '../../common/ListItem'
import { PrinterIcon } from '../../common/PrinterIcon'
import { PrinterStateTag } from '../../common/PrinterStateTag'
import { Distance } from '../../helpers/distance'
import { textEllipsis } from '../../helpers/styled'
import { Time } from '../../helpers/time'
import { ConnectStateEnum, ISortableParam, PrinterFamily, PrinterParamEnum } from '../../preferences/types'
import { CurrentFileControls } from '../control/CurrentFileControls'
import { useCurrentJob } from '../hooks/useCurrentJob'
import { SetPrinterReadyButton, SetReadyButtonModal, useSetPrinterReadyModal } from '../SetPrinterReadyButton'
import { getPrinterName } from '../utils'

export const Row = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding-bottom: 10px;
`

export const Ellipsis = styled.div`
  max-width: 300px;
  ${textEllipsis};
`

type Props = {
  printer: IPrinterSimpleView
  thumbnail?: Promise<ISnapshot>
  preview?: boolean
  overwriteControls?: JSX.Element
}

// For internal use in PrusaSlicer Web View
const postMessage = (printer: IPrinterSimpleView) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (window.wx) window.wx.postMessage(printer)
}

function PrinterImage({ thumbnail, printer }: { thumbnail?: Promise<ISnapshot>; printer: IPrinterSimpleView }) {
  const [imageData, setImageData] = useState<ISnapshot | null>(null)

  useEffect(() => {
    thumbnail?.then((data) => {
      setImageData(data)
    })
  }, [])

  if (imageData) {
    const isPrinting = isPrintingState(printer.connect_state)
    const staleTime = isPrinting ? STALE_TIME_PRINTING : STALE_TIME_IDLE
    const isStale = imageData.timestamp ? Date.now() / 1000 - imageData.timestamp > staleTime : false

    if (isStale) return <PrinterIcon type={printer.printer_type} slots={printer.slots} size={90} title={printer.name} />

    return <img src={imageData.downloadUrl} alt={printer.name} />
  }

  return <PrinterIcon type={printer.printer_type} slots={printer.slots} size={90} title={printer.name} />
}

function Controls({
  printer,
  overwriteControls,
  onSetPrinterReady
}: {
  printer: IPrinterSimpleView
  onSetPrinterReady: () => void
  overwriteControls?: JSX.Element
}) {
  const canControl = useCanControl(printer.team_id)
  const { isAvailable } = useCommandMutation(printer, {
    command: 'SET_PRINTER_READY'
  })

  if (overwriteControls) return overwriteControls

  if (canControl && [IConnectState.PRINTING, IConnectState.PAUSED].includes(printer.connect_state)) {
    return <CurrentFileControls printer={printer} />
  }

  return isAvailable ? (
    <SetPrinterReadyButton
      printer={printer}
      onSetPrinterReady={onSetPrinterReady}
      plannedJobsCount={printer.job_queue_count}
    />
  ) : null
}

export function PrinterListItem(props: Props) {
  const { printer, thumbnail, preview = false } = props
  const teams = useTeams()
  const { t } = useTranslation()
  const { showSetPrinterReadyModal, onCancel, onSetPrinterReady } = useSetPrinterReadyModal()
  const { estimatedEnd, timeRemaining } = useCurrentJob(printer.connect_state, printer.job_info)
  const isSla = useIsSla(printer.printer_type)

  const isPrinting = [IConnectState.PAUSED, IConnectState.PRINTING].includes(printer.connect_state)

  const renderParam = (name: PrinterParamEnum) => {
    if (name === PrinterParamEnum.PRINTING_PROGRESS && isDefined(printer.job_info?.progress)) {
      return {
        label: t('printer.printing-progress'),
        value: formatPercents(printer.job_info?.progress || 0)
      }
    }

    if (name === PrinterParamEnum.PRINTING_JOB_NAME && printer?.job_info?.display_name) {
      return {
        label: t('printer.printing-job-name'),
        value: <Ellipsis title={printer.job_info.display_name}>{printer.job_info.display_name}</Ellipsis>
      }
    }

    if (name === PrinterParamEnum.TIME_REMAINING && printer?.job_info?.time_remaining && timeRemaining > -1) {
      return {
        label: t('printer.time-remaining'),
        value: <Time unixTimestamp={timeRemaining} type={ITimetype.COUNTDOWN} noSeconds />
      }
    }

    if (name === PrinterParamEnum.ESTIMATED_END && estimatedEnd > 0 && timeRemaining > -1) {
      return {
        label: t('printer.estimated-end', 'Estimated end'),
        value: <Time unixTimestamp={estimatedEnd} noSeconds />
      }
    }

    if (name === PrinterParamEnum.PRINTER_TYPE) {
      return {
        label: t('printer.printer-type'),
        value: printer.printer_type_name
      }
    }

    if (name === PrinterParamEnum.MATERIAL && printer.filament) {
      return {
        label: t('printer.material'),
        value: <FilamentColorTag filament={printer.filament} material name />
      }
    }

    if (name === PrinterParamEnum.NOZZLE && printer.nozzle_diameter) {
      return {
        label: t('printer.nozzle'),
        value: <Distance value={printer.nozzle_diameter} noFixedWidth />
      }
    }

    if (name === PrinterParamEnum.LOCATION && printer.location) {
      return {
        label: t('printer.location'),
        value: printer.location
      }
    }

    if (name === PrinterParamEnum.TEAM && teams.length > 1) {
      return {
        label: t('printer.team'),
        value: printer.team_name
      }
    }
  }

  const idleFdmParams = useLoggedUserSettings(
    'printers',
    PrinterFamily.FDM,
    PrintersPreviewEnum.TABLE,
    ConnectStateEnum.IDLE
  )
  const idleSlaParams = useLoggedUserSettings(
    'printers',
    PrinterFamily.SLA,
    PrintersPreviewEnum.TABLE,
    ConnectStateEnum.IDLE
  )
  const progressFdmParams = useLoggedUserSettings(
    'printers',
    PrinterFamily.FDM,
    PrintersPreviewEnum.TABLE,
    ConnectStateEnum.PRINTING
  )
  const progressSlaParams = useLoggedUserSettings(
    'printers',
    PrinterFamily.SLA,
    PrintersPreviewEnum.TABLE,
    ConnectStateEnum.PRINTING
  )

  let dataItems: DataItem[] = []
  if (isPrinting) {
    dataItems = (isSla ? progressSlaParams : progressFdmParams)
      .map((item: ISortableParam) => {
        if (item.visible === false) {
          return null
        }
        return renderParam(item.name as PrinterParamEnum)
      })
      .filter(Boolean) as DataItem[]
  } else {
    dataItems = (isSla ? idleSlaParams : idleFdmParams)
      .map((item: ISortableParam) => {
        if (item.visible === false) {
          return
        }
        return renderParam(item.name as PrinterParamEnum)
      })
      .filter(Boolean) as DataItem[]
  }

  const currentState = getState(printer.connect_state)
  const isTransparent = !props.overwriteControls && printer.connect_state === IConnectState.OFFLINE

  return (
    <div onClick={() => postMessage(printer)}>
      <ListItem
        title={getPrinterName(printer)}
        link={preview ? '#' : `/printer/${printer.uuid}`}
        imageElement={<PrinterImage thumbnail={thumbnail} printer={printer} />}
        progress={printer.job_info?.progress}
        controls={
          !preview ? (
            <Controls
              printer={printer}
              overwriteControls={props.overwriteControls}
              onSetPrinterReady={onSetPrinterReady}
            />
          ) : null
        }
        dataItems={dataItems}
        baseColor={currentState.baseColor}
        statusBadge={
          <PrinterStateTag
            state={printer.connect_state}
            tooltip={
              printer.connect_state === IConnectState.OFFLINE && printer.last_online
                ? dayjs.unix(printer.last_online).fromNow()
                : undefined
            }
          />
        }
        isTransparent={isTransparent}
        attentionList={
          printer.connect_state === IConnectState.ATTENTION
            ? [t('printer.attention.tooltip', `Please check the printer's screen for more info.`)]
            : undefined
        }
      />
      {showSetPrinterReadyModal && <SetReadyButtonModal onCancel={onCancel} printer={props.printer} />}
    </div>
  )
}
