import { IJobState } from './job'
import { IPager } from './pager'
import { PrinterUuid } from './printer'

export enum INotificationCategory {
  PRINTER = 'PRINTER',
  CONNECT = 'CONNECT'
}

export enum INotificationName {
  FILAMENT_CHANGE_IN = 'FILAMENT_CHANGE_IN',
  FW_UPDATE = 'FW_UPDATE',
  LINK_UPDATE = 'LINK_UPDATE',
  INCIDENT_OFFLINE = 'INCIDENT_OFFLINE',
  INCIDENT_OTHER = 'INCIDENT_OTHER',
  INVITATION = 'INVITATION',
  JOB_FINISHED = 'JOB_FINISHED',
  PRINTER_ATTENTION = 'PRINTER_ATTENTION',
  PRINTER_ERROR = 'PRINTER_ERROR',
  PRINTER_PAUSED = 'PRINTER_PAUSED',
  PRINTER_PRINTING = 'PRINTER_PRINTING',
  PRINTER_RESUMED = 'PRINTER_RESUMED',
  TEST_NOTIFICATION = 'TEST_NOTIFICATION',
  TRANSFER_ABORTED = 'TRANSFER_ABORTED'
}

export enum INotificationTransferAbortedReason {
  NOT_FOUND_FILE = 'NOT_FOUND_FILE',
  NOT_FOUND_STORAGE = 'NOT_FOUND_STORAGE',
  NOT_FOUND_FILE_IN_PRINTER = 'NOT_FOUND_FILE_IN_PRINTER',
  INVALID_FILE_PATH = 'INVALID_FILE_PATH',
  FORBIDDEN_MAX_STORAGE_LIMIT = 'FORBIDDEN_MAX_STORAGE_LIMIT',
  FORBIDDEN_READ_ONLY = 'FORBIDDEN_READ_ONLY'
}

export enum INotificationSeverity {
  INFO = 'INFO',
  ATTENTION = 'ATTENTION',
  ERROR = 'ERROR'
}

export type INotification = {
  id: number
  category: INotificationCategory
  name: INotificationName
  severity: INotificationSeverity
  user_id: number
  created: number
  team_id: number
  printer_uuid: PrinterUuid
  seen?: number
  read?: number
  data?: INotificationData
  text: string
}

export type INotificationData = {
  hash?: string
  job_id?: number
  job_name?: string
  job_state?: IJobState
  preview_url?: string
  printer_name: string
  printer_uuid: string
  invitation_code?: string
  invitor_name?: string
  invitor_avatar?: string
  team_name?: string
  reason?: INotificationTransferAbortedReason
  firmware?: string
  prusa_link?: string
  filament_change_in?: string
  filament_change_in_sec?: number
}

export type INotificationsResponse = {
  notifications: INotification[]
  pager: IPager
}

export type IUnseenNotifications = {
  unseen: number
}

export type NotificationMediumLayerTypes = 'browser' | 'telegram' | 'slack' | 'discord' | 'email'

export type INotificationSettingsQuery = {
  medium: NotificationMediumLayerTypes
  endpoint: string
  keys?: {
    auth: string
    p256dh: string
  }
  chat_id?: string
}

export type INotificationSeverityTypes = {
  info: boolean
  error: boolean
  attention: boolean
}

export type IChannelNotification = {
  [key: string]: INotificationSeverityTypes
}

export type ITeamSettings = {
  name: string
  description: string
  prusaconnect_api_key?: string
  id: number
  severity: IChannelNotification
}

export type NotificationStatus = 'OK' | 'NOK'

type INotificationConfig = {
  id: number
  endpoint: string
  keys?: {
    auth: string
    p256dh: string
  }
  chat_id?: string
  status?: NotificationStatus
}

export type INotificationSettings = {
  [key: string]: INotificationConfig[]
}

export type INotificationChannelsSettingsQuery = {
  teams: ITeamSettings[]
  media: INotificationSettings
}
