import { IInfo } from '../types/info'
import { RequestFactory } from '../types/sdk'
import { ILogin, ILogoutResponse, IUser } from '../types/user'
import { IVersion } from '../types/version'

export function createModule(rf: RequestFactory) {
  return {
    getInfo: () => rf.get<IInfo>('/info'),

    getLogin: () => rf.get<ILogin>('/app/login'),

    login: (username: string, password: string) => rf.post<{ user: IUser }>(`/app/login`, { username, password }),

    changePassword: (currentPassword: string, password: string) =>
      rf.put(`/app/login/password`, { new_password: password, current_password: currentPassword }),

    logout: () => rf.post<ILogoutResponse>('/app/logout'),

    version: () => rf.get<IVersion>('/version.json')
  }
}
