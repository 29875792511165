import styled from 'styled-components'

import { textEllipsis } from '../../../helpers/styled'
import { HEIGHT, WIDTH } from './Printer'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 10px 10px 0px;
`

export const Name = styled.div`
  font-size: 0.7rem;
  text-align: center;
  ${textEllipsis};
`

export const EmptyCell = styled.div<{ $isDraggingOver: boolean }>`
  background: ${({ $isDraggingOver, theme }) => ($isDraggingOver ? theme.colors.primary : 'var(--neutral-300)')};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  border: 1px solid black;
  overflow: hidden;
  height: 100%;
`

export const CellContainer = styled.div`
  height: ${HEIGHT}px;
  width: ${WIDTH}px;
  display: flex;
  flex-direction: column;
`
