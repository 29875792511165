import Markdown from 'markdown-to-jsx'
import { useTranslation } from 'react-i18next'

import { ConfirmModal } from '../../../common/ConfirmModal'

type IProps = {
  show: boolean
  onHide: () => void
}

export function InvalidGroupSizeModal(props: IProps) {
  const { t } = useTranslation()
  const ok = () => {
    props.onHide()
  }

  if (props.show) {
    return (
      <ConfirmModal
        onCancel={props.onHide}
        onConfirm={ok}
        title={t('printer.group.invalid-group-size-title')}
        body={<Markdown>{t('printer.group.invalid-group-size-body')}</Markdown>}
        confirmBtnText={t('button.ok')}
        noChoice
      />
    )
  }

  return null
}
