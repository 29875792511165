import { MouseEventHandler } from 'react'
import styled, { css } from 'styled-components'

import { PrinterIcon } from '../../../common/PrinterIcon'
import { WithTooltip } from '../../../common/WithTooltip'
import { textEllipsis } from '../../../helpers/styled'

export const WIDTH = 100
const LINE_HEIGHT = 16
export const HEIGHT = 100 + LINE_HEIGHT

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: ${HEIGHT}px;
`

const Icon = styled.div<{ $inset?: boolean; $isDragging?: boolean; $isDisabled?: boolean }>`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ $inset, $isDragging }) => {
    if ($isDragging) {
      return css`
        background: white;
        box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
      `
    }
    if ($inset) {
      return css`
        background: #e0e0e0;
        border: 1px solid black;
      `
    }
    return ''
  }}

  ${({ $isDisabled }) => {
    if ($isDisabled) {
      return css`
        opacity: 0.5;
        filter: grayscale(1);
      `
    }
  }}
`

export const Name = styled.div`
  font-size: 0.7rem;
  line-height: ${LINE_HEIGHT}px;
  text-align: center;
  font-weight: 500;
  ${textEllipsis};
`

type Props = {
  name: string
  type: string
  isDragging?: boolean
  inset?: boolean
  isDisabled?: boolean
  disabledTooltip?: string
  onClick?: MouseEventHandler<HTMLDivElement>
}

export function Printer({ name, type, inset, isDragging, isDisabled, disabledTooltip, onClick }: Props) {
  return (
    <Container onClick={onClick}>
      <WithTooltip key={name} id={`disabled-tooltip-${name}`} title={isDisabled && disabledTooltip} placement="top">
        <Icon $inset={inset} $isDragging={isDragging} $isDisabled={isDisabled}>
          <PrinterIcon size={WIDTH} type={type} title={name} />
        </Icon>
      </WithTooltip>
      <Name>{name}</Name>
    </Container>
  )
}
