import dayjs from 'dayjs'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ICondition, IPlannedJob, IPrinterDetailJobInfo } from '../../api/types/job'
import { PrinterUuid } from '../../api/types/printer'
import { formatPercents } from '../../helpers/formatters'
import { isDefined, isNumber, range } from '../../helpers/std'
import { getJobDisplayPath, getJobTitle } from '../../hooks/useJobTitle'
import * as S from './SinglePrinterQueues.styled'

export function checkOptionalConditions(c: ICondition) {
  let isPrintAllowed = true
  // print is NOT allowed only when enabled: true && satisfied: false
  if (c.wait_until) isPrintAllowed &&= !(c.wait_until.enabled && c.wait_until.satisfied === false)
  if (c.material_type) isPrintAllowed &&= !(c.material_type.enabled && c.material_type.satisfied === false)
  if (c.material_length) isPrintAllowed &&= !(c.material_length.enabled && c.material_length.satisfied === false)
  if (c.nozzle_diameter) isPrintAllowed &&= !(c.nozzle_diameter.enabled && c.nozzle_diameter.satisfied === false)
  return isPrintAllowed
}

const VISIBLE_HOUR_OFFSET = 7

type IProps = {
  data: IPlannedJob[]
  currentJob?: IPrinterDetailJobInfo
  printerUuid: PrinterUuid
}

export function SinglePrinterQueues({ data, currentJob, printerUuid }: IProps) {
  const containerRef = useRef<HTMLDivElement>(null)
  const [autoScroll, setAutoScroll] = useState(true)
  const [isDragging, setIsDragging] = useState(false)
  const [dragX, setDragX] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)
  const { t } = useTranslation()

  const now = dayjs()
  const currentHour = now.hour()

  const startHour = currentHour - VISIBLE_HOUR_OFFSET
  const endHour = startHour + S.DAY * 3
  const hoursFlatRange = range(startHour, endHour).map((hour, i) => (i === 0 ? startHour : hour % 24))
  const hoursRange = hoursFlatRange.filter((hour) => hour % 6 === 0)

  const timelineStart = new Date().setHours(hoursRange[0], 0, 0)

  const diffInMinutes = now.diff(timelineStart) / 1000 / 60
  const currentLeft = diffInMinutes * S.MINUTE_WIDTH

  const scrollToNow = useCallback(() => {
    containerRef.current?.scrollTo({ left: currentLeft - S.SCROLL_OFFSET })
  }, [currentLeft])

  const hasInfiniteJobs = !data.every((job) => job.planned_end)

  useEffect(() => {
    if (autoScroll) {
      scrollToNow()
      // scrollToNow triggers onScroll and disables autoscroll, this will enable auto scroll again
      window.requestAnimationFrame(() => {
        setAutoScroll(true)
      })
    }
  }, [autoScroll, scrollToNow])

  const renderCurrentJob = (job?: IPrinterDetailJobInfo) => {
    if (!job || !job.start) {
      return null
    }
    const jobTimeRemaining = job.time_remaining === -1 ? 3.156e7 : job.time_remaining
    const dimensions = getJobDimensions(
      job.start,
      isDefined(jobTimeRemaining) ? now.unix() + job.time_remaining : undefined
    )
    if (!dimensions) {
      return null
    }
    const { left, width } = dimensions
    const content = [getJobTitle(t, job)]
    if (isNumber(job.progress)) {
      content.push(formatPercents(job.progress))
    }
    return (
      <S.Job style={{ width, left }}>
        <S.JobContent title={getJobDisplayPath(job)} $active>
          {content.filter((c) => c).join(' | ')}
        </S.JobContent>
      </S.Job>
    )
  }

  const renderDay = (jobs: IPlannedJob[], printerUuid: PrinterUuid) => {
    return jobs.map((job) => {
      const dimensions = getJobDimensions(job.planned_start, job.planned_end)
      if (!dimensions) {
        return null
      }
      const { left, width } = dimensions
      const { conditions: c } = job
      const isPrintAllowed = c.printer_ready?.satisfied && c.file_in_cache.satisfied && checkOptionalConditions(c)

      return (
        <S.JobLink key={job.id} style={{ width, left }} to={`/printer/${printerUuid}/queue/${job.id}`}>
          <S.JobContent title={getJobDisplayPath(job)} $isPrintAllowed={isPrintAllowed}>
            {getJobTitle(t, job)}
          </S.JobContent>
        </S.JobLink>
      )
    })
  }

  const getJobDimensions = (start?: number, end?: number) => {
    if (!start) {
      return null
    }
    const startObj = dayjs(start * 1000)
    const endObj = end ? dayjs(end * 1000) : dayjs().endOf('day') // use the end of the day if end is missing
    const diff = endObj.diff(startObj) / 1000
    const left = (startObj.diff(timelineStart) / 1000 / 60) * S.MINUTE_WIDTH
    let width = (diff / 60) * S.MINUTE_WIDTH

    if (!end) {
      const infiniteJobWidth = S.HOUR_WIDTH * 12
      width = infiniteJobWidth
    }
    return {
      left,
      width
    }
  }

  return (
    <S.Wrapper>
      <S.Controls>
        <S.ButtonLink onClick={() => scrollToNow()} title={t('queue.scroll-to-now')} className="text-right">
          {t('queue.scroll-to-now')}
        </S.ButtonLink>
        {autoScroll ? (
          <S.ButtonTitle title={t('queue.auto-scroll-enabled')} className="text-right">
            {t('queue.auto-scroll-enabled')}
          </S.ButtonTitle>
        ) : (
          <S.ButtonLink
            onClick={() => setAutoScroll(true)}
            title={t('queue.enable-auto-scroll')}
            className="text-right"
          >
            {t('queue.enable-auto-scroll')}
          </S.ButtonLink>
        )}
      </S.Controls>
      <S.QueueContainer
        ref={containerRef}
        onTouchStart={() => setAutoScroll(false)}
        onScroll={() => {
          setAutoScroll(false)
        }}
        onMouseDown={(event) => {
          if (!containerRef.current) {
            return
          }
          setAutoScroll(false)
          setIsDragging(true)
          setDragX(event.pageX - containerRef.current.offsetLeft)
          setScrollLeft(containerRef.current.scrollLeft)
        }}
        onMouseMove={(event) => {
          if (!containerRef.current) {
            return
          }
          if (!isDragging) {
            return
          }
          const x = event.pageX - containerRef.current.offsetLeft
          const walk = x - dragX
          containerRef.current.scrollTo({ left: scrollLeft - walk })
        }}
        onMouseUp={() => setIsDragging(false)}
      >
        <S.CurrentTimeLine style={{ left: currentLeft }} />
        <S.Day $width={hoursRange.length * S.TIME_INTERVAL_WIDTH}>
          <S.TimeWrapper>
            <div className="d-flex">
              {hoursRange.map((hour, i) => {
                return <S.TimeHeader key={i}>{hour}:00</S.TimeHeader>
              })}
            </div>
            <div className="d-flex align-items-end">
              {hoursFlatRange.map((_, i) => {
                if (i % 6 === 0) {
                  return <S.TimeMark key={i} />
                }
                return <S.TimeMark key={i} height={5} />
              })}
            </div>
          </S.TimeWrapper>
          {renderCurrentJob(currentJob)}
          {renderDay(data, printerUuid)}
        </S.Day>
      </S.QueueContainer>
      {hasInfiniteJobs && <S.Notice>{t('printer.queue.time-ranges.infinite-job-notice')}</S.Notice>}
    </S.Wrapper>
  )
}
