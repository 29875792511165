import styled from 'styled-components'

export const Placeholder = styled.i`
  color: #6c757d;
  padding-left: 5px;
`

export const RowsLabel = styled(Placeholder)<{ rows: number; maxRows: number }>`
  color: ${(props) => (props.rows < props.maxRows ? '#6c757d' : '#dc3545')};
`
