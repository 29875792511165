import { useMutation, useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useApiClient } from '../../api/react'
import { useToast } from '../../context/toastStore'
import { Container } from '../bootstrap/Container'
import { ButtonLink } from '../common/ButtonLink'
import { ConfirmModal } from '../common/ConfirmModal'
import { Density } from '../common/Density'
import { EmptyList } from '../common/EmptyList'
import { PaceProgress, shouldShowTabularPace } from '../common/PaceProgress'
import { Pager } from '../common/Pager'
import { PlainButton } from '../common/PlainButton'
import { SvgIcon } from '../common/SvgIcon'
import { Weight } from '../common/Weight'
import { ResponsiveTable } from '../helpers/responsiveTable'

export function FilamentsTable() {
  const api = useApiClient()
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(50)
  const toast = useToast()
  const { t } = useTranslation()
  const [filamentToDelete, setFilamentToDelete] = useState<number>()

  const query = useQuery([`/filaments/${offset}/${limit}`], () => api.app.filaments.getFilaments({ offset, limit }), {
    keepPreviousData: true
  })
  const { data, refetch } = query

  const { mutate, isLoading: isLoadingDelete } = useMutation<unknown, unknown, { filamentId: number }>(
    (params) => api.app.filaments.deleteFilament(params.filamentId),
    {
      onSuccess: () => {
        toast.add(t('filaments.toasts.filamentRemovedTitle'), t('filaments.toasts.filamentRemovedText'))
        refetch()
        setFilamentToDelete(undefined)
      }
    }
  )

  const deleteFilament = (filamentId: number) => {
    mutate({ filamentId })
  }

  const renderContent = () => {
    if (!data) {
      // Loading
      return null
    }

    if (data.filaments.length === 0) {
      return <EmptyList title={t('filaments.table.noFilaments')} />
    }

    return (
      <>
        <ResponsiveTable>
          <thead>
            <tr>
              <th>{t('filaments.table.manufacturer')}</th>
              <th>{t('filaments.table.material')}</th>
              <th>{t('filaments.table.spoolWeight')}</th>
              <th>{t('filaments.table.density')}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {data.filaments.map((filament) => (
              <tr key={filament.id}>
                <td>{filament.manufacturer}</td>
                <td>{filament.material}</td>
                <td>
                  <Weight value={filament.spool_weight} />
                </td>
                <td>
                  <Density value={filament.density} />
                </td>
                <td>
                  {!filament.default && (
                    <div className="d-flex">
                      <Link to={`/filaments/edit/${filament.id}`}>
                        <SvgIcon icon="editIcon" size={20} />
                      </Link>
                      <PlainButton className="ml-2" onClick={() => setFilamentToDelete(filament.id)}>
                        <SvgIcon icon="deleteIcon" size={20} />
                      </PlainButton>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </ResponsiveTable>
        <Pager pager={data.pager} setOffset={setOffset} setLimit={setLimit} />
      </>
    )
  }

  return (
    <>
      <Container>
        <ButtonLink className="float-right" to="/filaments/add">
          <SvgIcon size={14} icon="plusIcon" />
          {t('filaments.table.newFilament')}
        </ButtonLink>
        {shouldShowTabularPace(query) && <PaceProgress />}
        {renderContent()}
      </Container>
      {filamentToDelete && (
        <ConfirmModal
          isLoading={isLoadingDelete}
          onConfirm={() => deleteFilament(filamentToDelete)}
          onCancel={() => setFilamentToDelete(undefined)}
        />
      )}
    </>
  )
}
