import 'react-bootstrap-typeahead/css/Typeahead.css'

import type { MutableRefObject } from 'react'
import { ReactNode, useRef, useState } from 'react'
import { Form } from 'react-bootstrap'
import { Highlighter, Typeahead as Suggester } from 'react-bootstrap-typeahead'
import TypeaheadRef from 'react-bootstrap-typeahead/types/core/Typeahead'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import { Material } from '../../../api/types/filament'
import { ICloudFile, IFileType, IPrintFile, ITreeChild } from '../../../api/types/file'
import { useBreakpoint } from '../../../helpers/useBreakpoint'
import { Button } from '../../common/Button'
import { LongContentAutoScroll } from '../../common/LongContentAutoScroll'
import { PlainButton } from '../../common/PlainButton'
import { SvgIcon } from '../../common/SvgIcon'
import { Distance } from '../../helpers/distance'
import { IFilesFilter } from './AllCloudFiles'
import { BulkActionsDropdown } from './FileManager/BulkActionsDropdown'
import { GeneralBulkFileActions } from './FileManager/GeneralBulkFileActions'
import { SelectingCheckbox } from './FileManager/SelectingCheckbox'
import { Fieldset } from './FileManager/styled'
import * as S from './StorageFilters.styled'
import { IStorageFilters } from './utils'

export const initialFilters = {
  materialFilter: [],
  layerFilter: 0,
  nozzleFilter: 0,
  storages: [],
  printerTypeFilter: [],
  fileTypeFilter: IFileType.EMPTY
}

function useFileTypeLabels() {
  const { t } = useTranslation()

  return {
    [IFileType.FOLDER]: t('file.type.folder', 'Folder'),
    [IFileType.FILE]: t('file.type.unprintable', 'Unprintable file'),
    [IFileType.PRINT_FILE]: t('file.type.print-file', 'Print file'),
    [IFileType.FIRMWARE]: t('file.type.firmware', 'Firmware file'),
    [IFileType.EMPTY]: t('storage.filters.all-file-types', 'All file types')
  }
}

type Props = {
  files: ICloudFile[] | ITreeChild[]
  teamId?: number
  filters: { filters: IFilesFilter; setFilters: (filters: IFilesFilter) => void }
  selectedFiles: { selectedFiles: ICloudFile[]; setSelectedFiles: (files: ICloudFile[]) => void }
  sort: { sort: string; setSort: (sort: string) => void }
  resetFilters: () => void
  onFilterChange: (
    isChecked: boolean,
    key: 'materialFilter' | 'printerTypeFilter' | 'storages',
    value: Material | string
  ) => void
  fulltext: { fulltext: string; setFulltext: (text: string) => void }
  availableFilters: IStorageFilters
  refetch: () => void
  isSelectable?: boolean
  children?: ReactNode
  usedStorage?: ReactNode
}

export const StorageFilters = (props: Props) => {
  const { files, teamId, onFilterChange, availableFilters, refetch, resetFilters, isSelectable = true } = props
  const { filters, setFilters } = props.filters
  const { fulltext, setFulltext } = props.fulltext
  const { sort, setSort } = props.sort
  const { selectedFiles, setSelectedFiles } = props.selectedFiles
  const { t } = useTranslation()
  const theme = useTheme()
  const isMdOrLarger = useBreakpoint('md')
  const [suggestedItems, setSuggestedItems] = useState<ICloudFile[] | IPrintFile[]>([])

  const suggesterRef: MutableRefObject<TypeaheadRef | null> = useRef(null)

  const { availableMaterials, availablePrinterTypes, availableNozzles, availableLayers, availableFileTypes, storages } =
    availableFilters
  const labels = useFileTypeLabels()

  const isFromConnect = !!teamId || teamId === 0

  function renderFilters(filteredFiles: ICloudFile[]) {
    const handleForm = (e: React.FormEvent<HTMLFormElement>): void => {
      e.preventDefault()
    }

    const onKeyDown = (e: React.KeyboardEvent) => {
      if (e.code === 'Enter') {
        suggesterRef.current?.hideMenu()
      }
    }

    const reset = (): void => {
      resetFilters()
      suggesterRef.current?.clear()
    }

    const isResetVisible =
      fulltext ||
      !!filters.layerFilter ||
      filters.materialFilter.length ||
      !!filters.nozzleFilter ||
      filters.printerTypeFilter?.length ||
      filters.storages?.length ||
      filters.fileTypeFilter !== IFileType.EMPTY

    const options = filteredFiles.map((f) => f.display_name || f.name)

    return (
      <Form onSubmit={handleForm} className="d-flex my-3 flex-wrap" autoComplete="off">
        <S.FormGroup controlId="fulltext" style={{ minWidth: isMdOrLarger ? '40%' : '100%' }}>
          <Fieldset role="searchbox" aria-labelledby="fulltext">
            <Form.Label as="legend" id="fulltext">
              {t('file.search')}
            </Form.Label>
            <div className="d-flex">
              <S.Fulltext className="flex-fill" $empty={!fulltext || !fulltext.length}>
                <Suggester
                  ref={suggesterRef}
                  className="flex-fill"
                  id="suggester"
                  labelKey={(option) => option as string}
                  emptyLabel={t('no-matches-found')}
                  paginationText={t('display-additional-results', 'Display additional results...')}
                  onChange={(options) => {
                    const items = options as ICloudFile[] | IPrintFile[]
                    setFulltext(items[0]?.display_name)
                    setSuggestedItems(items)
                  }}
                  onKeyDown={onKeyDown}
                  defaultInputValue={fulltext}
                  onInputChange={(searchName) => setFulltext(searchName)}
                  options={fulltext ? options : []}
                  selected={suggestedItems}
                  renderMenuItemChildren={(option, itemProps) => (
                    <LongContentAutoScroll>
                      <Highlighter search={itemProps.text}>{option as string}</Highlighter>
                    </LongContentAutoScroll>
                  )}
                />
                {fulltext && (
                  <S.ClearButton>
                    <SvgIcon
                      icon="nokIcon"
                      size={16}
                      style={{ opacity: '0.35' }}
                      onClick={() => {
                        setFulltext('')
                        setSuggestedItems([])
                        suggesterRef.current?.clear()
                      }}
                    />
                  </S.ClearButton>
                )}
              </S.Fulltext>
              <Button type="submit" className="mx-2">
                {t('search')}
              </Button>
            </div>
          </Fieldset>
        </S.FormGroup>

        {isFromConnect && availablePrinterTypes.length > 1 && (
          <S.FormGroup>
            <Fieldset role="option" aria-labelledby="sliced-for">
              <legend id="sliced-for">{t('file.sliced-for')}</legend>
              <div className="d-flex">
                {availablePrinterTypes.map((p: string) => {
                  return (
                    <SelectingCheckbox
                      key={p}
                      id={p}
                      checked={filters.printerTypeFilter ? filters.printerTypeFilter.indexOf(p) > -1 : false}
                      label={<label htmlFor={p}>{p}</label>}
                      onChange={(checked) => onFilterChange(checked, 'printerTypeFilter', p)}
                    />
                  )
                })}
              </div>
            </Fieldset>
          </S.FormGroup>
        )}

        {!isFromConnect && storages.length > 1 && (
          <S.FormGroup>
            <Fieldset role="option" aria-labelledby="local-storage">
              <legend id="local-storage">{t('file.local-storage')}</legend>
              <div className="d-flex">
                {storages.map((s: string) => {
                  return (
                    <SelectingCheckbox
                      key={s}
                      id={s}
                      checked={filters.storages ? filters.storages.indexOf(s) > -1 : false}
                      label={<label htmlFor={s}>{s}</label>}
                      onChange={(checked) => onFilterChange(checked, 'storages', s)}
                    />
                  )
                })}
              </div>
            </Fieldset>
          </S.FormGroup>
        )}

        {availableMaterials.length > 1 && (
          <S.FormGroup>
            <Fieldset role="option" aria-labelledby="material">
              <legend id="material">{t('gcode-meta.material')}</legend>
              <div className="d-flex">
                {availableMaterials.map((m: Material) => {
                  return (
                    <SelectingCheckbox
                      key={m}
                      id={m}
                      checked={filters.materialFilter.indexOf(m) > -1}
                      label={<label htmlFor={m}>{m}</label>}
                      onChange={(checked) => onFilterChange(checked, 'materialFilter', m)}
                    />
                  )
                })}
              </div>
            </Fieldset>
          </S.FormGroup>
        )}

        {availableLayers.length > 1 && (
          <S.FormGroup controlId="layerHeight" style={{ minWidth: '250px' }}>
            <Fieldset role="listbox" aria-labelledby="layer-height">
              <Form.Label as="legend" id="layer-height">
                {t('gcode-meta.layer-height')}
              </Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => setFilters({ ...filters, layerFilter: Number(e.currentTarget.value) })}
                value={filters.layerFilter}
              >
                <option value={0}>{t('all-layer-heights')}</option>
                {availableLayers.map((layer: number, i: number) => (
                  <option key={i} value={layer}>
                    <Distance value={layer} />
                  </option>
                ))}
              </Form.Control>
            </Fieldset>
          </S.FormGroup>
        )}

        {availableNozzles.length > 1 && (
          <S.FormGroup controlId="nozzleDiameter" style={{ minWidth: '220px' }}>
            <Fieldset role="listbox" aria-labelledby="nozzle-diameter">
              <Form.Label as="legend" id="nozzle-diameter">
                {t('gcode-meta.nozzle-diameter')}
              </Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => setFilters({ ...filters, nozzleFilter: Number(e.currentTarget.value) })}
                value={filters.nozzleFilter}
              >
                <option value={0}>{t('all-nozzle-diameters')}</option>
                {availableNozzles.map((nozzle: number, i: number) => (
                  <option key={i} value={nozzle}>
                    <Distance value={nozzle} />
                  </option>
                ))}
              </Form.Control>
            </Fieldset>
          </S.FormGroup>
        )}

        {availableFileTypes.length > 1 && (
          <S.FormGroup controlId="fileTypes" style={{ minWidth: '250px' }}>
            <Fieldset role="listbox" aria-labelledby="file-types">
              <Form.Label as="legend" id="file-types">
                {t('gcode-meta.file-types', 'File types')}
              </Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => setFilters({ ...filters, fileTypeFilter: e.currentTarget.value as IFileType })}
                value={filters.fileTypeFilter}
              >
                <option value={IFileType.EMPTY}>{t('storage.filters.all-file-types', 'All file types')}</option>
                {availableFileTypes.map((type: IFileType, i: number) => (
                  <option key={i} value={type}>
                    {labels[type]}
                  </option>
                ))}
              </Form.Control>
            </Fieldset>
          </S.FormGroup>
        )}

        {!!isResetVisible && (
          <PlainButton className="ml-2" onClick={reset} title={t('storage.filters.clear-filters', 'Clear filters')}>
            <SvgIcon icon="nokIcon" fill={theme.colors.icons.secondary} size={16} />
          </PlainButton>
        )}
      </Form>
    )
  }

  function renderSortLine() {
    const isAllSelected = files.length === selectedFiles.length

    const onDelete = () => {
      refetch()
      setSelectedFiles([])
    }

    const handleBulkSelect = (key: string) => {
      switch (key) {
        case 'all':
          setSelectedFiles(files as ICloudFile[])
          break
        case 'none':
          setSelectedFiles([])
          break
      }
    }

    const handleSelectAll = () => {
      if (!isAllSelected) {
        setSelectedFiles(files as ICloudFile[])
      } else {
        setSelectedFiles([])
      }
    }

    return (
      <S.Row>
        <div className="d-flex align-items-center" style={{ margin: '0.5rem 0px' }}>
          {isSelectable && (
            <>
              <SelectingCheckbox
                id="file-selection"
                checked={isAllSelected}
                onChange={handleSelectAll}
                label={
                  <label htmlFor="file-selection" style={{ fontSize: '0' }}>
                    {t('file-selection')}
                  </label>
                }
              />
              <BulkActionsDropdown onSelect={(key: string) => handleBulkSelect(key)} />
            </>
          )}

          {selectedFiles.length > 0 && (
            <div className="ml-3">
              {isFromConnect && (
                <GeneralBulkFileActions
                  fileHashes={selectedFiles.map((f) => f.hash)}
                  onDelete={onDelete}
                  printerTeamId={teamId}
                />
              )}
            </div>
          )}
        </div>

        <S.Right>
          <S.Children>{props.children}</S.Children>

          <Form.Group
            style={{ minWidth: '250px', margin: '0.5rem 0px' }}
            className="d-flex align-items-center"
            controlId="sortBy"
          >
            <Form.Label style={{ whiteSpace: 'nowrap', marginRight: '1rem', marginBottom: '0' }}>
              {t('sort')}
            </Form.Label>
            <Form.Control as="select" onChange={(e) => setSort(e.currentTarget.value)} value={sort}>
              {[
                { label: t('latest'), id: 'latest' },
                { label: t('oldest'), id: 'oldest' },
                { label: t('A-Z'), id: 'A-Z' },
                { label: t('Z-A'), id: 'Z-A' },
                { label: t('biggest'), id: 'biggest' },
                { label: t('smallest'), id: 'smallest' }
              ].map((sort) => (
                <option key={sort.id} value={sort.id}>
                  {sort.label}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          {props.usedStorage || null}
        </S.Right>
      </S.Row>
    )
  }

  return (
    <>
      {renderFilters(files as ICloudFile[])}
      {renderSortLine()}
    </>
  )
}
