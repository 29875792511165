import { useMutation } from '@tanstack/react-query'

// import { feedrateMoveXY, feedrateMoveZ } from '../../../../config'
import { usePrinter } from '../../../../context/printerContext'
import { isDefined } from '../../../../helpers/std'
import { useCommandMutation } from '../../../../hooks/commands/useCommandMutation'
import { MoveValue } from '../MoveDropdown'

export enum Axis {
  X = 'x',
  Y = 'y',
  Z = 'z'
}

const printerAxisKey = {
  [Axis.X]: 'axis_x',
  [Axis.Y]: 'axis_y',
  [Axis.Z]: 'axis_z'
} as const

export const commands = {
  [Axis.X]: 'MOVE',
  [Axis.Y]: 'MOVE',
  [Axis.Z]: 'MOVE_Z'
} as const

/**
 * Handler for changing XYZ
 *
 * Usage:
 *  const { isLoading, isAvailable, onChange } = useAxisOnChangeHandler('x')
 *
 *  onChange({ absolute: true, x: 250 }) // set X to 250
 *  onChange({ x: -10 }) // relative step
 */
export function useAxisOnChangeHandler(
  axis: Axis | `${Axis}`,
  feedrate?: number,
  onSuccess?: Function,
  onError?: (e: any) => void
) {
  const { printer } = usePrinter()

  // Current printer's position
  const currentPosition = printer[printerAxisKey[axis]]

  // Checks the availability of the command
  const moveMutation = useCommandMutation(
    printer,
    {
      command: commands[axis]
    },
    () => onSuccess?.(),
    (e) => onError?.(e)
  )

  // Ensures stable onChange fuction
  const { mutate } = useMutation((moveValue: MoveValue) => {
    const value = moveValue[axis]
    if (!isDefined(value) || !isDefined(currentPosition)) {
      // typecheck
      return Promise.resolve(undefined)
    }

    const distance = moveValue.absolute ? value - currentPosition : value
    if (axis === Axis.X || axis === Axis.Y) {
      return moveMutation.executeAsync({ feedrate, [axis]: distance })
    }
    return moveMutation.executeAsync({ feedrate, distance })
  })

  return {
    onChange: mutate,
    isLoading: moveMutation.isLoading,
    isAvailable: moveMutation.isAvailable
  }
}
