import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

type Props = {
  content?: string
}

export function DocumentTitle({ content }: Props) {
  const { t } = useTranslation()

  const title = content
    ? `${content} - Prusa Connect`
    : t(
        'document-title',
        'Prusa Connect - Remotely control your entire 3D printing ecosystem or just a single Original Prusa printer'
      )

  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  )
}
