import { stringify } from 'query-string'

import { AfsJob, AfsJobs, AfsJobsQuery, PatchAfsJob } from '../../types/afs'
import { RequestFactory } from '../../types/sdk'

export function createModule(rf: RequestFactory) {
  return {
    getJobs: (groupId: number, query: AfsJobsQuery) =>
      rf.get<AfsJobs>(`/app/printers/groups/${groupId}/afs-jobs?${stringify(query)}`),
    getJob: (groupId: number, jobId: number) => rf.get<AfsJob>(`/app/printers/groups/${groupId}/afs-jobs/${jobId}`),
    editJob: (groupId: number, jobId: number, data: PatchAfsJob) =>
      rf.patch(`/app/printers/groups/${groupId}/afs-jobs/${jobId}`, data)
  }
}
