import { ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { throttle } from 'throttle-debounce'

const Wrapper = styled.div`
  position: relative;
`

enum Position {
  LEFT,
  RIGHT
}

const Gradient = styled.div<{ $position: Position }>`
  z-index: 10;
  background: #bbbbbb;
  mask-image: linear-gradient(
    ${(props) => (props.$position === Position.LEFT ? '-90deg' : '90deg')},
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 1) 100%
  );
  position: absolute;
  top: 0;
  bottom: 0;
  width: 5px;
  ${(props) => (props.$position === Position.LEFT ? 'left: 0px;' : 'right: 0px;')}
`

const Scrollable = styled.div`
  overflow-x: auto;
`

type Props = {
  children: ReactNode
}

export function ScrollableContent(props: Props) {
  const ref = useRef<HTMLDivElement>(null)
  const [scrollStart, setScrollStart] = useState(true)
  const [scrollEnd, setScrollEnd] = useState(false)

  const onScroll = useMemo(
    () =>
      throttle(50, false, () => {
        const element = ref.current
        if (!element) {
          return null
        }

        const isStart = element.scrollLeft === 0
        const isEnd = element.offsetWidth + element.scrollLeft >= element.scrollWidth
        setScrollStart(isStart)
        setScrollEnd(isEnd)
      }),
    []
  )

  // Init
  useEffect(() => {
    onScroll()
  }, [onScroll])

  return (
    <Wrapper>
      {!scrollStart && <Gradient $position={Position.LEFT} />}
      {!scrollEnd && <Gradient $position={Position.RIGHT} />}
      <Scrollable onScroll={onScroll} ref={ref}>
        {props.children}
      </Scrollable>
    </Wrapper>
  )
}
