import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useApiClient } from '../../../api/react'
import { intervals } from '../../../config'
import { EmptyList } from '../../common/EmptyList'
import { PaceProgress, shouldShowTabularPace } from '../../common/PaceProgress'
import { ResponsiveTable } from '../../helpers/responsiveTable'
import { InvitationRow } from './InvitationRow'

const CenteredTh = styled.th`
  text-align: center;
`

const GET_INVITATIONS_KEY = 'invitations'

type Props = {
  refetchTeams?: (timestamp: number) => void
}

export function InvitationList(props: Props) {
  const { t } = useTranslation()
  const api = useApiClient()
  const { refetchTeams } = props
  const query = useQuery([GET_INVITATIONS_KEY], () => api.app.users.getInvitationsForUser(), {
    refetchInterval: intervals.userInvitations
  })

  const queryClient = useQueryClient()

  const { mutate: acceptInvitation } = useMutation((code: string) => api.app.teams.acceptInvitationToTeam(code), {
    onSuccess: () => {
      queryClient.invalidateQueries([GET_INVITATIONS_KEY, '/printers-to-megamenu'])
      refetchTeams?.(new Date().getTime())
    }
  })

  const { mutate: refuseInvitation } = useMutation((code: string) => api.app.teams.declineInvitationToTeam(code), {
    onSuccess: () => queryClient.invalidateQueries([GET_INVITATIONS_KEY])
  })

  const renderList = () => {
    if (query.isSuccess && query.data.invitations.length === 0) {
      return <EmptyList title={t('team.invitation.empty-list')} />
    }

    if (query.isSuccess) {
      return (
        <ResponsiveTable>
          <thead>
            <tr>
              <th>{t('table-column.team')}</th>
              <th style={{ color: '#fa6831' }}>{t('table-column.rights')}</th>
              <CenteredTh>{t('table-column.rights.read')}</CenteredTh>
              <CenteredTh>{t('table-column.rights.use')}</CenteredTh>
              <CenteredTh>{t('table-column.rights.manage')}</CenteredTh>
              <th style={{ color: '#fa6831' }}>{t('table-column.actions')}</th>
              <th>{t('table-column.valid-until')}</th>
              <th>{t('table-column.refused')}</th>
            </tr>
          </thead>
          <tbody>
            {query.data.invitations.map((invitation) => (
              <InvitationRow
                key={invitation.id}
                {...invitation}
                onAcceptInvitation={() => acceptInvitation(invitation.code)}
                onRefuseInvitation={() => refuseInvitation(invitation.code)}
              />
            ))}
            {query.data.refused.map((invitation) => (
              <InvitationRow key={invitation.id} {...invitation} inactive />
            ))}
          </tbody>
        </ResponsiveTable>
      )
    }
  }

  return (
    <>
      {shouldShowTabularPace(query) && <PaceProgress />}
      {renderList()}
    </>
  )
}
