import { createContext, useContext, useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'

import { useUpdateUserPreferences } from '../components/preferences/helpers'
import { useLoggedUserPreferences } from '../hooks/useLoggedUser'
import { darkConnectTheme, lightConnectTheme } from '../theme/combined-theme'

export enum ColorMode {
  LIGHT = 'LIGHT',
  DARK = 'DARK'
}

const userPreferenceOptions = [null, ColorMode.DARK, ColorMode.LIGHT]
const systemPreference = window.matchMedia('(prefers-color-scheme: dark)')

function getSystemPreference() {
  return systemPreference.matches ? ColorMode.DARK : ColorMode.LIGHT
}

function useUserPreferredMode() {
  const preference = useLoggedUserPreferences('dark_mode')

  switch (preference) {
    case ColorMode.DARK:
      return ColorMode.DARK
    case ColorMode.LIGHT:
      return ColorMode.LIGHT
    default:
      return null
  }
}

function useFinalColorMode() {
  return useUserPreferredMode() || getSystemPreference() || ColorMode.LIGHT
}

function getNextUserPreference(userPreference: ColorMode | null) {
  const index = userPreferenceOptions.indexOf(userPreference)
  if (index + 1 < userPreferenceOptions.length) {
    return userPreferenceOptions[index + 1]
  }

  return userPreferenceOptions[0]
}

type Context = {
  colorMode: ColorMode
  userPreferredMode: ColorMode | null
  setColorTheme: (colorMode: ColorMode) => void
  iterateUserPreference: () => void
}

const ThemeModeContext = createContext<Context>({
  colorMode: ColorMode.LIGHT,
  userPreferredMode: null,
  setColorTheme: () => {},
  iterateUserPreference: () => {}
})

export function ThemeModeProvider({ children }: { children: JSX.Element }) {
  const [colorMode, setColorMode] = useState(useFinalColorMode())
  const [userPreferredMode, setUserPreferredMode] = useState(useUserPreferredMode())
  const { update } = useUpdateUserPreferences()
  const preferences = useLoggedUserPreferences()

  useEffect(() => {
    window.document.documentElement.setAttribute('data-theme', colorMode)
  }, [colorMode])

  useEffect(() => {
    if (!userPreferredMode) {
      localStorage.removeItem('visualTheme')
      setColorMode(getSystemPreference() || ColorMode.LIGHT)
      return
    }

    setColorMode(userPreferredMode)
  }, [userPreferredMode])

  const handler = (event: MediaQueryListEvent) => {
    const newColorMode = event.matches ? ColorMode.DARK : ColorMode.LIGHT
    if (!userPreferredMode) {
      setColorMode(newColorMode)
    }
  }

  useEffect(() => {
    if (systemPreference?.addEventListener) {
      systemPreference.addEventListener('change', handler)
    } else {
      systemPreference.addListener(handler) // older Safari
    }
  }, [])

  const setColorTheme = (colorMode: ColorMode) => {
    setColorMode(colorMode)
  }

  const iterateUserPreference = () => {
    const nextMode = getNextUserPreference(userPreferredMode)
    update({ preferences: { ...preferences, dark_mode: nextMode } })
    setUserPreferredMode(nextMode)
  }

  return (
    <ThemeModeContext.Provider value={{ colorMode, userPreferredMode, setColorTheme, iterateUserPreference }}>
      <ThemeProvider theme={colorMode === ColorMode.LIGHT ? lightConnectTheme : darkConnectTheme}>
        {children}
      </ThemeProvider>
    </ThemeModeContext.Provider>
  )
}

export function useColorMode() {
  const context = useContext(ThemeModeContext)
  if (!context) {
    throw new Error('<ThemeModeProvider> is missing in component tree!')
  }

  return context
}
