import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useApiClient } from '../../../api/react'
import { IJob, IJobState } from '../../../api/types/job'
import { Button } from '../../common/Button'
import { ConfirmModal } from '../../common/ConfirmModal'
import { SectionTitle } from '../../common/Parameters'
import { SimpleList, SimpleListItem } from '../../common/SimpleList'

type Props = {
  job: IJob
}

export function JobCancelObjects({ job }: Props) {
  const { t } = useTranslation()

  const { app } = useApiClient()
  const [cancelObjectId, setCancelObjectId] = useState<number | undefined>()
  const { mutate } = useMutation(
    (id: number) =>
      app.commandQueue.addCommandSync(job.printer_uuid, {
        command: 'CANCEL_OBJECT',
        kwargs: {
          object_id: id
        }
      }),
    {}
  )

  return (
    <>
      <SectionTitle>{t('printer.cancel-objects.title', 'Cancel objects')}</SectionTitle>

      <SimpleList>
        {job.cancelable &&
          job.cancelable.objects.map((cancelable) => (
            <SimpleListItem key={cancelable.id}>
              {cancelable.name}
              {cancelable.canceled ? (
                <i>{t('printer.cancel-objects.cancelled', 'Cancelled')}</i>
              ) : job.state === IJobState.PRINTING ? (
                <Button onClick={() => setCancelObjectId(cancelable.id)}>
                  {t('printer.cancel-objects.cancel', 'Cancel object')}
                </Button>
              ) : (
                <div className="ml-2">{t('printer.cancel-objects.not-cancelled', 'Not cancelled')}</div>
              )}
            </SimpleListItem>
          ))}
      </SimpleList>

      {cancelObjectId !== undefined ? (
        <ConfirmModal
          onConfirm={() => {
            mutate(cancelObjectId)
            setCancelObjectId(undefined)
          }}
          onCancel={() => setCancelObjectId(undefined)}
        />
      ) : null}
    </>
  )
}
