import { Fragment, MouseEvent, ReactNode, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { IPrintFile, isSlaFile, isTreeFile, isTreePrintFile, ITreePrintFile } from '../../../../api/types/file'
import { IGcodeMetadata } from '../../../../api/types/metadata'
import { PrinterUuid } from '../../../../api/types/printer'
import { getLongFileName } from '../../../../helpers/files'
import { formatSize } from '../../../../helpers/formatters'
import { isDefined } from '../../../../helpers/std'
import { useDevice } from '../../../../helpers/useDevice'
import { StorageLocationSlug } from '../../../../hooks/storageLocations/useStorageLocationTabs'
import { useLoggedUserSettings } from '../../../../hooks/useLoggedUser'
import { ITimetype } from '../../../../interfaces/time'
import { ContextMenuRenderer } from '../../../common/AdaptiveButton/ContextMenuRenderer'
import { SimpleActionsRenderer } from '../../../common/AdaptiveButton/SimpleActionsRenderer'
import { Distance } from '../../../helpers/distance'
import { Time } from '../../../helpers/time'
import { YesOrNo } from '../../../helpers/yesOrNo'
import { JobPreview } from '../../../jobs/JobPreview'
import { ConnectStateEnum, ISortableParam, PrinterFamily, PrintFileParamEnum } from '../../../preferences/types'
import { Label, Value } from '../../overview/styled'
import { ConnectFileActions } from './ConnectFileActions'
import { FarmFileActions } from './FarmFileActions'
import { PrinterFileActions } from './PrinterFileActions'
import { SelectingCheckbox } from './SelectingCheckbox'
import * as S from './styled'
import { Timestamp } from './Timestamp'
import { getConnectFileUrl, getPrinterFileUrl } from './utils'
import { WithContextMenu } from './WithContextMenu'

type Props = {
  file: IPrintFile | ITreePrintFile
  refetch?: () => void
  printerTeamId: number
  storage?: StorageLocationSlug
  uuid?: PrinterUuid
  isSelectable?: boolean
  selected?: boolean
  setSelected?: (checked: boolean) => void
  tags?: ReactNode
  preview?: boolean
}

function FdmParams({ file, storage }: { file: IPrintFile | ITreePrintFile; storage: StorageLocationSlug }) {
  const { t } = useTranslation()
  const { meta: fileMeta } = file
  const fdmParams = useLoggedUserSettings('files', PrinterFamily.FDM, undefined, ConnectStateEnum.IDLE)

  return (
    <>
      {fdmParams.map((component: ISortableParam) => {
        if (component.visible === false) {
          return null
        }
        const meta = fileMeta as IGcodeMetadata
        switch (component.name) {
          case PrintFileParamEnum.PRINTER_MODEL:
            return (
              <Fragment key={component.name}>
                {meta?.printer_model && (
                  <div>
                    <Label>{t('printer.file.printer-model')}</Label>
                    <Value>{meta.printer_model}</Value>
                  </div>
                )}
              </Fragment>
            )
          case PrintFileParamEnum.ESTIMATED_PRINTING_TIME:
            return (
              <Fragment key={component.name}>
                {meta?.estimated_print_time && (
                  <div>
                    <Label>{t('printer.file.printing-time')}</Label>
                    <Value>
                      <Time unixTimestamp={meta.estimated_print_time} type={ITimetype.DURATION} noSeconds />
                    </Value>
                  </div>
                )}
              </Fragment>
            )
          case PrintFileParamEnum.MATERIAL:
            return (
              <Fragment key={component.name}>
                {meta?.filament_type && (
                  <div>
                    <Label>{t('printer.file.material')}</Label>
                    <Value>{meta.filament_type}</Value>
                  </div>
                )}
              </Fragment>
            )
          case PrintFileParamEnum.LAYER_HEIGHT:
            return (
              <Fragment key={component.name}>
                {meta?.layer_height && (
                  <div>
                    <Label>{t('printer.file.layer-height')}</Label>
                    <Value>
                      <Distance value={meta.layer_height} />
                    </Value>
                  </div>
                )}
              </Fragment>
            )
          case PrintFileParamEnum.DATE:
            return (
              <Fragment key={component.name}>
                {isTreeFile(file) && file.m_timestamp && (
                  <div>
                    <Label>{t('printer.file.date')}</Label>
                    <Value>
                      <Timestamp timestamp={file.m_timestamp} />
                    </Value>
                  </div>
                )}
                {storage === StorageLocationSlug.CONNECT && file.uploaded && (
                  <div>
                    <Label>{t('printer.file.date')}</Label>
                    <Value>
                      <Timestamp timestamp={file.uploaded} />
                    </Value>
                  </div>
                )}
              </Fragment>
            )
          case PrintFileParamEnum.SIZE:
            return (
              <div key={component.name}>
                <Label>{t('printer.file.size')}</Label>
                <Value>{formatSize(file.size || 0)}</Value>
              </div>
            )
          case PrintFileParamEnum.NOZZLE_DIAMETER:
            return (
              <Fragment key={component.name}>
                {meta?.nozzle_diameter && (
                  <div>
                    <Label>{t('printer.file.nozzle-diameter')}</Label>
                    <Value>
                      <Distance value={meta.nozzle_diameter} />
                    </Value>
                  </div>
                )}
              </Fragment>
            )
          case PrintFileParamEnum.SUPPORT_MATERIAL:
            return (
              <Fragment key={component.name}>
                {meta && isDefined(meta.support_material) && (
                  <div>
                    <Label>{t('printer.file.support-material', 'Supports')}</Label>
                    <Value>
                      <YesOrNo value={meta.support_material} />
                    </Value>
                  </div>
                )}
              </Fragment>
            )
          case PrintFileParamEnum.BRIM_WIDTH:
            return (
              <Fragment key={component.name}>
                {meta && isDefined(meta.brim_width) && (
                  <div>
                    <Label>{t('printer.file.brim', 'Brim')}</Label>
                    <Value>{meta.brim_width > 0 ? t('yes') : t('no')}</Value>
                  </div>
                )}
              </Fragment>
            )
          case PrintFileParamEnum.FILL_DENSITY:
            return (
              <Fragment key={component.name}>
                {meta && isDefined(meta.fill_density) && (
                  <div>
                    <Label>{t('printer.file.infill', 'Infill')}</Label>
                    <Value>{meta.fill_density}</Value>
                  </div>
                )}
              </Fragment>
            )
          default:
            return null
        }
      })}
    </>
  )
}

function SlaParams({ file, storage }: { file: IPrintFile | ITreePrintFile; storage: StorageLocationSlug }) {
  const { t } = useTranslation()
  const meta = file.meta as IGcodeMetadata
  const slaParams = useLoggedUserSettings('files', PrinterFamily.SLA, undefined, ConnectStateEnum.IDLE)

  return (
    <>
      {slaParams.map((component: ISortableParam) => {
        if (component.visible === false) {
          return null
        }
        switch (component.name) {
          case PrintFileParamEnum.PRINTER_MODEL:
            return (
              <Fragment key={component.name}>
                {meta?.printer_model && (
                  <div>
                    <Label>{t('printer.file.printer-model')}</Label>
                    <Value>{meta.printer_model}</Value>
                  </div>
                )}
              </Fragment>
            )
          case PrintFileParamEnum.ESTIMATED_PRINTING_TIME:
            return (
              <Fragment key={component.name}>
                {meta?.estimated_print_time && (
                  <div>
                    <Label>{t('printer.file.printing-time')}</Label>
                    <Value>
                      <Time unixTimestamp={meta.estimated_print_time} type={ITimetype.DURATION} noSeconds />
                    </Value>
                  </div>
                )}
              </Fragment>
            )
          case PrintFileParamEnum.TOTAL_LAYERS:
            return (
              <Fragment key={component.name}>
                {meta?.total_layers && (
                  <div>
                    <Label>{t('printer.file.total_layers', 'Total layers')}</Label>
                    <Value> {meta.total_layers}</Value>
                  </div>
                )}
              </Fragment>
            )
          case PrintFileParamEnum.DATE:
            return (
              <Fragment key={component.name}>
                {isTreeFile(file) && file.m_timestamp && (
                  <div>
                    <Label>{t('printer.file.date')}</Label>
                    <Value>
                      <Timestamp timestamp={file.m_timestamp} />
                    </Value>
                  </div>
                )}
                {storage === StorageLocationSlug.CONNECT && file.uploaded && (
                  <div>
                    <Label>{t('printer.file.date')}</Label>
                    <Value>
                      <Timestamp timestamp={file.uploaded} />
                    </Value>
                  </div>
                )}
              </Fragment>
            )
          case PrintFileParamEnum.SIZE:
            return (
              <div key={component.name}>
                <Label>{t('printer.file.size')}</Label>
                <Value>{formatSize(file.size || 0)}</Value>
              </div>
            )
          default:
            return null
        }
      })}
    </>
  )
}

export function PrintFileRow({
  file,
  refetch,
  printerTeamId,
  uuid,
  isSelectable,
  selected,
  setSelected,
  tags,
  storage = StorageLocationSlug.CONNECT,
  preview = false
}: Props) {
  const [isHovered, setIsHovered] = useState(false)
  const [searchParams] = useSearchParams()
  const urlTeamId = Number(searchParams.get('team'))
  const { md } = useDevice()
  const isSla = isSlaFile(file.name || '')

  // file.team_id or printer team_id in case of SD_CARD
  const rowLinkUrl = useMemo(() => {
    if (preview) return '#'

    return uuid
      ? getPrinterFileUrl(uuid, file.team_id || printerTeamId, storage, file.hash)
      : getConnectFileUrl(file.team_id || printerTeamId, file.hash)
  }, [uuid, file.team_id, printerTeamId, file.hash, storage])

  return (
    <WithContextMenu
      items={
        !preview && (
          <ContextMenuRenderer>
            <Actions urlTeamId={urlTeamId} file={file} refetch={refetch} uuid={uuid} />
          </ContextMenuRenderer>
        )
      }
    >
      <div style={{ position: 'relative' }}>
        {isSelectable && (
          <S.Checkbox>
            <SelectingCheckbox id={file.hash} checked={selected} onChange={setSelected} />
          </S.Checkbox>
        )}
        <S.RowLink
          style={isSelectable ? { marginLeft: '3rem' } : {}}
          to={rowLinkUrl}
          $noLink={rowLinkUrl === '#'}
          onMouseOver={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <S.ImageContainer>
            <JobPreview size={100} previewUrl={file.preview_url} hashForThumbnail={file.hash} />
          </S.ImageContainer>
          <S.Content>
            <div className="d-flex align-items-center">
              <S.ItemName title={isTreePrintFile(file) ? file.display_path || file.path : getLongFileName(file)}>
                {getLongFileName(file)}
              </S.ItemName>
              <div
                onClick={(e: MouseEvent) => {
                  e.preventDefault()
                }}
              >
                {!preview && (
                  <SimpleActionsRenderer show={isHovered}>
                    <Actions basicOptions={md} urlTeamId={urlTeamId} file={file} refetch={refetch} uuid={uuid} />
                  </SimpleActionsRenderer>
                )}
              </div>
            </div>

            <S.ScrollableContainer background="var(--background-body)">
              <S.Tags>{tags}</S.Tags>
              <S.Params>
                {!isSla ? <FdmParams file={file} storage={storage} /> : <SlaParams file={file} storage={storage} />}
              </S.Params>
            </S.ScrollableContainer>
          </S.Content>
        </S.RowLink>
      </div>
    </WithContextMenu>
  )
}

const Actions = ({
  basicOptions,
  file,
  refetch,
  urlTeamId,
  uuid
}: { basicOptions?: boolean; urlTeamId: number } & Pick<Props, 'file' | 'refetch' | 'uuid'>) => {
  if (AFS_ENABLED) {
    return <FarmFileActions file={file} onDelete={refetch} basicOptions={basicOptions} teamId={urlTeamId} />
  }

  if (uuid) {
    return <PrinterFileActions file={file} onDelete={refetch} basicOptions={basicOptions} />
  }

  return (
    <ConnectFileActions
      file={file}
      onDelete={refetch}
      onRename={refetch}
      basicOptions={basicOptions}
      teamId={urlTeamId}
    />
  )
}
