import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { useApiClient } from '../../api/react'
import { Alert } from '../bootstrap/Alert'
import { PaceProgress, shouldShowTabularPace } from '../common/PaceProgress'
import { PageContainer } from '../helpers/styled'
import { AddGcodeTemplateForm } from './AddGcodeTemplateForm'
import { GcodeTemplatesList } from './GcodeTemplatesList'

export function GcodeTemplates() {
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(50)
  const { t } = useTranslation()

  const api = useApiClient()
  const query = {
    offset,
    limit
  }
  const queryResult = useQuery([`/gcodes/${JSON.stringify(query)}`], () => api.app.gCodes.getTemplates(query), {
    keepPreviousData: true,
    refetchOnWindowFocus: false
  })

  const renderContent = () => {
    if (!queryResult.data || shouldShowTabularPace(queryResult)) {
      return <PaceProgress />
    }

    if (queryResult.isError) {
      return <Alert>{t('alert.backend-error')}</Alert>
    }

    return (
      <GcodeTemplatesList
        data={queryResult.data}
        refetch={queryResult.refetch}
        setLimit={setLimit}
        setOffset={setOffset}
      />
    )
  }

  return (
    <>
      <PageContainer fluid>
        <AddGcodeTemplateForm refetch={queryResult.refetch} />
      </PageContainer>
      <Container fluid className="container-full">
        {renderContent()}
      </Container>
    </>
  )
}
