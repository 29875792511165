import { stringify } from 'query-string'

import { ICommandQueueListResponse, IQueueCommand, IQueueCommandResponse } from '../../types/commandQueue'
import { IFinishedEvent } from '../../types/event'
import { PrinterUuid } from '../../types/printer'
import { NoContent204, RequestFactory } from '../../types/sdk'

export function createModule(rf: RequestFactory) {
  return {
    addCommand: (printerUuid: PrinterUuid, command: IQueueCommand) =>
      rf.post<IQueueCommandResponse>(`/app/printers/${printerUuid}/commands`, command),

    addCommandSync: (printerUuid: PrinterUuid, command: IQueueCommand, timeout?: number) =>
      rf.post<{ command: IQueueCommandResponse; event: IFinishedEvent }>(
        `/app/printers/${printerUuid}/commands/sync?${stringify({ timeout })}`,
        command
      ),

    getCommands: (printerUuid: PrinterUuid) =>
      rf.get<ICommandQueueListResponse>(`/app/printers/${printerUuid}/commands`),

    deleteCommand: (printerUuid: PrinterUuid, commandId: number) =>
      rf.delete<NoContent204>(`/app/printers/${printerUuid}/commands/${commandId}`),

    deleteCommands: (printerUuid: PrinterUuid) => rf.delete<NoContent204>(`/app/printers/${printerUuid}/commands`)
  }
}
