import { useLoginInfo } from '@prusaconnect/api/services/useLogin'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ICamera } from '../../api/types/cameras'
import { usePrinter } from '../../context/printerContext'
import { useCurrentSnapshots, usePolling, usePrinterCamerasService } from '../../services/usePrinterCamerasService'
import { EmptyList } from '../common/EmptyList'
import { PaceProgress } from '../common/PaceProgress'
import { AIDetectorModal } from './AIDetectorModal'
import { CameraData, CameraDetail } from './CameraDetail'
import { CameraDetailContainer } from './CameraDetail.styled'

function Snapshot({ cameras }: { cameras: ICamera[] }) {
  const { t } = useTranslation()
  const { thumbnails, fullSnapshots } = useCurrentSnapshots(cameras.map((camera) => camera.id))
  const { updateCurrentCamera } = usePolling(cameras.map((camera) => camera.id))
  const [imgToAnalyze, setImgToAnalyze] = useState<string | null>(null)
  const user = useLoginInfo()
  const isEmployee = user?.is_employee
  const {
    printer: { connect_state }
  } = usePrinter()

  // Set default snapshot polling values
  useEffect(() => {
    updateCurrentCamera(cameras[0].id)
  }, [])

  const cameraPreviewData = cameras.map((camera, index): CameraData => {
    const snapshotQuery = fullSnapshots[index]
    const thumbnailQuery = thumbnails[index]

    const snapshotData = snapshotQuery?.data
    const thumbnailData = thumbnailQuery?.data

    return {
      cameraName: camera.name || t('printer.cameras.empty-camera-name', 'Unnamed Camera'),
      cameraId: camera.id,
      isRefetching: fullSnapshots[index]?.isRefetching,
      isLoading: fullSnapshots[index]?.isLoading,
      snapshotUrls: snapshotData?.snapshotUrl ? [snapshotData.snapshotUrl] : [],
      thumbnail: thumbnailData?.snapshotUrl || '',
      timestamp: snapshotData?.timestamp
    }
  })

  return (
    <>
      <CameraDetail
        cameras={cameraPreviewData}
        setActiveCameraId={updateCurrentCamera}
        fadeStaleImage
        uploadToAi={isEmployee ? setImgToAnalyze : undefined}
        connectState={connect_state}
      />
      <AIDetectorModal fileUrl={imgToAnalyze || ''} />
    </>
  )
}

export function CamerasView() {
  const { t } = useTranslation()
  const { printer } = usePrinter()
  const { registeredCameras, isLoading } = usePrinterCamerasService(printer.uuid)

  const renderSnapshot = (cameras: ICamera[]) => {
    if (cameras.length <= 0) {
      return (
        <CameraDetailContainer>
          <EmptyList title={t('printer.cameras.no-cameras')} />
        </CameraDetailContainer>
      )
    }

    return <Snapshot cameras={cameras} />
  }

  return isLoading ? <PaceProgress /> : renderSnapshot(registeredCameras)
}
