import { stringify } from 'query-string'

import {
  IEditGroupRequestBody,
  IGroupDateRangeQuery,
  IGroupDetail,
  IGroupJobsEndStatesRes,
  IGroupMaterialConsumpRes,
  IGroupOverallPrintTimeRes,
  IGroupPlannedTasksFrequencyRes,
  IGroupPrintersRequest,
  IGroupPrintersResponse,
  IGroupsResponse,
  IPrinterInGroupAttributes
} from '../../types/groups'
import { IPagerQuery } from '../../types/pager'
import { PrinterUuid } from '../../types/printer'
import { NoContent204, RequestFactory } from '../../types/sdk'

export function createModule(rf: RequestFactory) {
  return {
    getGroups: (query: IPagerQuery = {}) => rf.get<IGroupsResponse>(`/app/printers/groups?${stringify(query)}`),

    getGroup: (groupId: number) => rf.get<IGroupDetail>(`/app/printers/groups/${groupId}`),

    getGroupPrinters: (groupId: number, query: IGroupPrintersRequest = {}) =>
      rf.get<IGroupPrintersResponse>(`/app/printers/groups/${groupId}/printers?${stringify(query)}`),

    getGroupFilamentConsumption: (groupId: number, query: IGroupDateRangeQuery) =>
      rf.get<IGroupMaterialConsumpRes>(`/app/stats/groups/${groupId}/material_quantity?${stringify(query)}`),

    getGroupPlannedTaskHours: (groupId: number, query: IGroupDateRangeQuery) =>
      rf.get<IGroupPlannedTasksFrequencyRes>(`/app/stats/groups/${groupId}/planned_tasks?${stringify(query)}`),

    getGroupOverallPrintingTime: (groupId: number, query: IGroupDateRangeQuery) =>
      rf.get<IGroupOverallPrintTimeRes>(`/app/stats/groups/${groupId}/printing_not_printing?${stringify(query)}`),

    getGroupJobsEndStates: (groupId: number, query: IGroupDateRangeQuery) =>
      rf.get<IGroupJobsEndStatesRes>(`/app/stats/groups/${groupId}/jobs_success?${stringify(query)}`),

    createGroup: (group: IEditGroupRequestBody) => rf.post(`/app/printers/groups`, group),

    editGroup: (groupId: number, groupData: IEditGroupRequestBody) =>
      rf.put<IGroupDetail>(`/app/printers/groups/${groupId}`, groupData),

    deleteGroup: (groupId: number) => rf.delete<NoContent204>(`/app/printers/groups/${groupId}`),

    addPrinterToGroup: (printerUuid: PrinterUuid, groupId: number, data: IPrinterInGroupAttributes = {}) =>
      rf.post(`/app/printers/${printerUuid}/groups/${groupId}`, data),

    removePrinterFromGroup: (printerUuid: PrinterUuid, groupId: number) =>
      rf.delete<NoContent204>(`/app/printers/${printerUuid}/groups/${groupId}`),

    changePrinterAttributesInGroup: (printerUuid: PrinterUuid, groupId: number, data: IPrinterInGroupAttributes = {}) =>
      rf.put(`/app/printers/${printerUuid}/groups/${groupId}`, data)
  }
}
