import { ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CSSProperties } from 'styled-components'

import { IOtherFile, isTreeFile, ITreeOtherFile } from '../../../../api/types/file'
import { getLongFileName } from '../../../../helpers/files'
import { formatSize } from '../../../../helpers/formatters'
import { useDevice } from '../../../../helpers/useDevice'
import { StorageLocationSlug } from '../../../../hooks/storageLocations/useStorageLocationTabs'
import { ContextMenuRenderer } from '../../../common/AdaptiveButton/ContextMenuRenderer'
import { SimpleActionsRenderer } from '../../../common/AdaptiveButton/SimpleActionsRenderer'
import { SvgIcon } from '../../../common/SvgIcon'
import { Label, Value } from '../../overview/styled'
import { NonPrintFileActions } from './NonPrintFileActions'
import * as S from './styled'
import { Timestamp } from './Timestamp'
import { WithContextMenu } from './WithContextMenu'

type Props = {
  file: IOtherFile | ITreeOtherFile
  refetch?: () => void
  style?: CSSProperties
  tags?: ReactNode
  storage?: StorageLocationSlug
}

export function OtherFileRow({ file, refetch, style, tags, storage = StorageLocationSlug.CONNECT }: Props) {
  const { t } = useTranslation()
  const [isHovered, setIsHovered] = useState(false)
  const { md } = useDevice()

  return (
    <WithContextMenu
      items={
        <ContextMenuRenderer>
          <Actions file={file} refetch={refetch} />
        </ContextMenuRenderer>
      }
    >
      <S.Row style={style} onMouseOver={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        <S.ImageContainer>
          <SvgIcon icon="logsColorIcon" size="70" />
        </S.ImageContainer>
        <S.Content>
          <div className="d-flex align-items-center">
            <S.ItemName title={getLongFileName(file)}>{getLongFileName(file)}</S.ItemName>
            <SimpleActionsRenderer show={isHovered}>
              <Actions basicOptions={md} file={file} refetch={refetch} />
            </SimpleActionsRenderer>
          </div>

          <div>
            <S.Tags>{tags}</S.Tags>
            <S.Params>
              {file.size && (
                <div>
                  <Label>{t('printer.file.size')}</Label>
                  <Value>{formatSize(file.size)}</Value>
                </div>
              )}
              {isTreeFile(file) && file.m_timestamp && <Timestamp timestamp={file.m_timestamp} />}
              {storage === StorageLocationSlug.CONNECT && file.uploaded && <Timestamp timestamp={file.uploaded} />}
            </S.Params>
          </div>
        </S.Content>
      </S.Row>
    </WithContextMenu>
  )
}

const Actions = ({ basicOptions, file, refetch }: { basicOptions?: boolean } & Pick<Props, 'file' | 'refetch'>) => (
  <NonPrintFileActions file={file} onDelete={refetch} basicOptions={basicOptions} />
)
