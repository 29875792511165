import { IFirmwareMetadata, IGcodeMetadata, ISlMetadata } from './metadata'
import { IPager } from './pager'
import { PrinterUuid } from './printer'

const PRINT_FILE_EXTENSIONS = ['.gcode', '.gco', '.g']
export const BINARY_EXTENSIONS = ['.bgc', '.bgcode']
export const PRINT_FILE_SLA_EXTENSIONS = ['.sl1', '.sl1s']
export const PRINT_FILE_SL1S_EXTENSIONS = ['.m1', '.sl1s']
export const FIRMWARE_EXTENSIONS = ['.bbf', '.hex']
export const FLASHABLE_EXTENSIONS = ['.bbf'] // .hex is for MK3 family

export const getPrintFileExtensions = (binarySupported?: boolean, isSla?: boolean, isFromConnect?: boolean) => {
  if (isFromConnect) {
    return [...PRINT_FILE_EXTENSIONS, ...BINARY_EXTENSIONS, ...PRINT_FILE_SLA_EXTENSIONS]
  }
  if (binarySupported && !isSla) {
    return [...PRINT_FILE_EXTENSIONS, ...BINARY_EXTENSIONS]
  }
  return isSla ? PRINT_FILE_SLA_EXTENSIONS : PRINT_FILE_EXTENSIONS
}

export type IUploadedFileResponse = {
  hash: string
  name: string
  size: number
  type: IFileType
}

export enum IFileType {
  FOLDER = 'FOLDER',
  FILE = 'FILE',
  PRINT_FILE = 'PRINT_FILE',
  FIRMWARE = 'FIRMWARE',
  EMPTY = ''
}

export type IFileTypeFilter = IFileType

export type IFileData<Type extends IFileType = IFileType> = {
  type: Type
  hash: string
  name: string
  display_name: string
  path?: string
  display_path?: string
  size?: number
  team_id: number
  printer_type?: string
}

export type IPreviewInfo = {
  preview_url?: string
  preview_mimetype?: string
}

export type ITreeNodeBase = {
  name: string
  read_only: boolean
  path: string
  display_path?: string
  display_name: string
}

export type IPrintFile = IFileData<IFileType.PRINT_FILE> & {
  uploaded?: number
  meta?: IGcodeMetadata | ISlMetadata
  hidden?: boolean
  printer_type?: string
  m_timestamp?: number
} & IPreviewInfo
export type ITreePrintFile = IPrintFile & ITreeNodeBase

export type IFirmwareFile = IFileData<IFileType.FIRMWARE> & {
  uploaded?: number
  meta?: IFirmwareMetadata
  hidden?: boolean
  m_timestamp?: number
}
export type ITreeFirmwareFile = IFirmwareFile & ITreeNodeBase

export type IOtherFile = IFileData<IFileType.FILE> & {
  uploaded?: number
  hidden?: boolean
  m_timestamp?: number
  meta?: any
}
export type ITreeOtherFile = IOtherFile & ITreeNodeBase

export type IFile = IPrintFile | IFirmwareFile | IOtherFile
export type ITreeFile = ITreePrintFile | ITreeFirmwareFile | ITreeOtherFile

export type ITreeChild = ITreeFolder | ITreeFile

export type ITreeFolder = ITreeNodeBase & {
  type: IFileType.FOLDER
  file_count: number
  hidden?: boolean
  id: number
  m_timestamp?: number
  tree_synced?: boolean
  folder_synced?: boolean
  // typecheck
  meta?: any
  size?: number
  uploaded?: number
}

export type IPrinterFilesResponse = {
  files: ITreeChild[]
  pager: IPager
  display_path: string
}

export type IFileDetailResponse = IFile & { display_path?: string; path?: string; read_only?: boolean }

// File from cloud storage
// from /app/teams/{team_id}/files
export type ICloudFile = IPrintFile | IFirmwareFile | IOtherFile

export type ICloudFilesResponse = {
  files: ICloudFile[]
  pager: IPager
  capacity: number
  total: number
}

export type IUploadQueueFile = {
  path?: string
  url?: string
  hash?: string
  team_id: number
  to_print?: boolean
  to_queue?: boolean
}

export type IUploadQueueFirmwareFile =
  | (Partial<IUploadQueueFile> & {
      path: string
      url: string
    })
  | (Partial<IUploadQueueFile> & {
      path: string
      team_id: number
      hash: string
    })

export type ITransferMoveRequest = {
  file_id: number
  position: number
}

export type IDeleteFilesRequestBody = {
  delete_all?: boolean
  hashes: string[]
}

export type IEditFileAttrs = {
  name: string
  hash?: string
  teamId?: number
}

export type Fws = {
  [key: PrinterUuid]: (IFileData & { meta?: IFirmwareMetadata }) | null
}

export function isTreeFile(file?: IFile | ITreeFile): file is ITreeFile {
  return file !== undefined && 'path' in file
}

export function isTreePrintFile(file?: IFile | ITreeFile): file is ITreePrintFile {
  return isTreeFile(file) && file.type === IFileType.PRINT_FILE
}

export function isFirmwareFile(file?: IFile): file is IFirmwareFile {
  return file?.type === IFileType.FIRMWARE
}

export function isFirmwareFileByName(file?: File) {
  return FIRMWARE_EXTENSIONS.some((firmwareExtension) => file?.name.toLowerCase().endsWith(firmwareExtension))
}

export function isPrintFile(file?: IFile | IFileDetailResponse): file is IPrintFile {
  return file?.type === IFileType.PRINT_FILE
}

export function getExtensionFromFileName(filename: string) {
  const parts = filename.split('.')
  return (parts[parts.length - 1] || '').toLowerCase()
}

export function isSlaFile(filename: string) {
  return PRINT_FILE_SLA_EXTENSIONS.concat(PRINT_FILE_SL1S_EXTENSIONS).some((firmwareExtension) =>
    filename.toLowerCase().endsWith(firmwareExtension)
  )
}
