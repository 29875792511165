import { useEffect, useState } from 'react'

import { isTouchEnabled } from './Game'
import collision from './sprites/collision.png'
import joeDown from './sprites/joe_down.png'
import joeLeft from './sprites/joe_left.png'
import joeRight from './sprites/joe_right.png'
import joeUp from './sprites/joe_up.png'
import { TouchHandler } from './TouchHandler'

type IProps = {
  size: number
  top: number
  left: number
  gameStarted: boolean
  gameOver: boolean
  moveLeft: () => void
  moveUp: () => void
  moveRight: () => void
  moveDown: () => void
}

const touchEnabled = isTouchEnabled()

export function Player(props: IProps) {
  const [direction, setDirection] = useState<string>(joeDown)

  const move = (key: string | number) => {
    switch (key) {
      case 'ArrowLeft' || 37:
        setDirection(joeLeft)
        props.moveLeft()
        break
      case 'ArrowUp' || 38:
        setDirection(joeUp)
        props.moveUp()
        break
      case 'ArrowRight' || 39:
        setDirection(joeRight)
        props.moveRight()
        break
      case 'ArrowDown' || 40:
        setDirection(joeDown)
        props.moveDown()
        break
      case 'left':
        setDirection(joeLeft)
        props.moveLeft()
        break
      case 'up':
        setDirection(joeUp)
        props.moveUp()
        break
      case 'right':
        setDirection(joeRight)
        props.moveRight()
        break
      case 'down':
        setDirection(joeDown)
        props.moveDown()
        break
      default:
    }
  }

  const handleKeyDown = (e: KeyboardEvent) => {
    e.preventDefault()

    if (props.gameStarted) {
      const key = e.key || e.keyCode
      move(key)
    }
  }

  useEffect(() => {
    window.onkeydown = handleKeyDown
    const touchHandler = new TouchHandler(move)
    if (touchEnabled && props.gameStarted) {
      window.addEventListener('touchstart', touchHandler.handleTouchStart, { passive: false })
      window.addEventListener('touchmove', touchHandler.handleTouchMove, { passive: false })
      window.addEventListener('touchend', touchHandler.handleTouchEnd, { passive: false })
    }
    return () => {
      window.removeEventListener('touchstart', touchHandler.handleTouchStart)
      window.removeEventListener('touchmove', touchHandler.handleTouchMove)
      window.removeEventListener('touchend', touchHandler.handleTouchEnd)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.gameStarted])

  return (
    <div
      style={{
        width: `${props.size}px`,
        height: `${props.size}px`,
        backgroundImage: `url(${props.gameOver ? collision : ''}), url(${direction || joeDown})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        top: `${props.top}px`,
        left: `${props.left}px`,
        transition: 'all 0.1s ease',
        position: 'absolute'
      }}
    />
  )
}
