import { getUserName, IUser } from '../api/types/user'
import { Avatar, EmptyAvatar } from './helpers/Avatar'

type Props = {
  user: IUser
  avatarServerUrl: string
  size?: number
}

export function User(props: Props) {
  const { user, avatarServerUrl, size } = props

  const name = getUserName(user)

  if (user.avatar && avatarServerUrl) {
    return <Avatar size={size} avatar={user.avatar} avatarServerUrl={avatarServerUrl} name={name} />
  }

  return <EmptyAvatar title={name} size={size} />
}
