import { useMutation } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useApiClient } from '../api/react'
import { INotificationSettingsQuery } from '../api/types/notifications'
import { useToast } from '../context/toastStore'
import { getEnvironment, Globals } from '../helpers/getEnvironment'
import { urlBase64ToUint8Array } from '../helpers/urlBase64ToUint8array'

const fallbackKey = 'BJGTnpvyI0aIfZ2iXlPprOt0psj0Nf06ApAb4fhuspcXmdq5f7KRP1T2Lo-vFLZ12Zmm5ApxxTEx6iDMMhv2rcI'
const publicVapidKey = getEnvironment(Globals.VAPID_PUBLIC_KEY) || fallbackKey

const subscribe = async () => {
  if (!('serviceWorker' in navigator)) return
  await navigator.serviceWorker.register('/pushNotificationsServiceWorker.js')
  const registration = await navigator.serviceWorker.ready
  const subscription = await registration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array(publicVapidKey)
  })

  return subscription.toJSON()
}

export const getSubscription = async () => {
  if (!('serviceWorker' in navigator)) return

  const registration = await navigator.serviceWorker.ready

  if (!registration.pushManager) {
    throw new Error('Push notifications not supported in this browser')
  }
  return registration.pushManager.getSubscription()
}

export const usePushNotificationService = () => {
  const [isSubscribed, setIsSubscribed] = useState(false)
  const [isSupported, setIsSupported] = useState(false)
  const api = useApiClient()
  const toast = useToast()
  const { t } = useTranslation()

  const { mutate: setNotificationSettings } = useMutation(
    (params: INotificationSettingsQuery) => api.app.notifications.addMediumEndpoint(params),
    {
      onSuccess: () => {
        setIsSubscribed(true)
      }
    }
  )

  const handleNotificationRequest = async () => {
    try {
      const permission = await Notification.requestPermission()
      if (permission !== 'granted') {
        return
      }

      const data = await subscribe()

      if (!data?.endpoint || !data.keys) {
        return
      }

      setNotificationSettings({
        medium: 'browser',
        endpoint: data.endpoint,
        keys: {
          auth: data.keys.auth,
          p256dh: data.keys.p256dh
        }
      })
    } catch (error) {
      toast.add(t('push-notifications.subscription-error', 'Could not enable push notifications'), error as string)
    }
  }

  const unsubscribe = async () => {
    const subscription = await getSubscription()
    if (subscription) {
      return subscription.unsubscribe().then(() => setIsSubscribed(false))
    }
    return null
  }

  useEffect(() => {
    getSubscription()
      .then((subscription) => {
        setIsSupported(true)
        if ('Notification' in window && Notification.permission === 'granted' && subscription !== null) {
          setIsSubscribed(true)
        }
      })
      .catch(() => {
        setIsSupported(false)
      })
  }, [])

  return {
    handleNotificationRequest,
    unsubscribe,
    isSubscribed,
    isSupported
  }
}
