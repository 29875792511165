import { stringify } from 'query-string'

import { tryParse } from '../../../helpers/tryParse'
import {
  ICloudFilesResponse,
  IDeleteFilesRequestBody,
  IEditFileAttrs,
  IFile,
  IFileDetailResponse,
  IFileType,
  IPrinterFilesResponse,
  IUploadedFileResponse
} from '../../types/file'
import { IPagerQuery } from '../../types/pager'
import { PrinterUuid } from '../../types/printer'
import { CancelablePromise, NoContent204, RequestFactory } from '../../types/sdk'

type FileUploadOptions = {
  onProgress?: (e: ProgressEvent) => void
  onSuccess?: (response: IUploadedFileResponse) => void
  onAbort?: () => void
  onError?: (error: IQueryError) => void
}

type IQueryError = {
  error: {
    code: string
    message: string
  }
}

export function createModule(rf: RequestFactory) {
  return {
    getPrinterFiles: (
      printerUuid: PrinterUuid,
      query?: IPagerQuery & { file_type: IFileType },
      filetreePath?: string
    ) => {
      let url = `/app/printers/${printerUuid}/files`

      if (filetreePath && query) {
        url += `?${stringify({ path: filetreePath })}&${stringify(query)}`
      } else {
        if (filetreePath) {
          url += `?${stringify({ path: filetreePath })}`
        }
        if (query) {
          url += `&${stringify(query)}`
        }
      }

      return rf.get<IPrinterFilesResponse>(url)
    },

    getFile: (teamId: number, hash: string, printerUuid?: PrinterUuid) => {
      let url = `/app/teams/${teamId}/files/${hash}`
      if (printerUuid) {
        url = `${url}?${stringify({ printer_uuid: printerUuid })}`
      }
      return rf.get<IFileDetailResponse>(url)
    },

    uploadFile: (url: string, file: File, options: FileUploadOptions = {}) => {
      const request = new XMLHttpRequest()

      // 2021 and the fetch still doesn't have progress event...
      const promise = new Promise<IUploadedFileResponse>((resolve, reject) => {
        request.open('PUT', url)
        request.withCredentials = true
        request.setRequestHeader('Upload-Size', file.size.toString())

        if (options.onProgress) {
          request.upload.addEventListener('progress', options.onProgress)
        }

        request.addEventListener('load', () => {
          let json
          try {
            json = JSON.parse(request.response)
          } catch (e) {
            json = null
          }

          if (request.status === 413) {
            return reject(request)
          }

          if (request.status !== 200) {
            return reject(json)
          }

          resolve(json)
          options.onSuccess?.(json)
        })

        request.addEventListener('error', () => {
          return reject(tryParse(request.response))
        })

        request.send(file)
      })
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      promise.cancel = () => request.abort()
      return promise as CancelablePromise<IUploadedFileResponse>
    },

    updateFile: (teamId: number, hash: string, attrs: IEditFileAttrs, overwrite = false) => {
      let url = `/app/teams/${teamId}/files/${hash}`
      if (overwrite) {
        url += `?overwrite=true`
      }
      return rf.patch<IFile>(url, attrs)
    },

    getFiles: (teamId: number, query: IPagerQuery = {}) =>
      rf.get<ICloudFilesResponse>(`/app/teams/${teamId}/files?${stringify(query)}`),

    deleteFiles: (teamId: number, body: IDeleteFilesRequestBody) =>
      rf.delete<NoContent204>(`/app/teams/${teamId}/files/raw`, body)
  }
}
