import { useEffect, useState } from 'react'

/**
 * Returns true if the page is visible, false otherwise.
 */
export function usePageVisibility(): boolean {
  const [visibilityStatus, setVisibilityStatus] = useState(!document.hidden)

  useEffect(() => {
    function handleVisibilityChange() {
      setVisibilityStatus(!document.hidden)
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  return visibilityStatus
}
