import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactNode } from 'react'
import styled from 'styled-components'

const Check = styled.span`
  border: 1px solid #d0d0d0;
  border-radius: 2px;
  width: 22px;
  height: 22px;
  position: absolute;
  svg {
    display: none;
    color: ${({ theme }) => theme.colors.primary};
    margin: auto;
    font-size: 1.25em;
  }
`

export const Checkbox = styled.label`
  display: flex;
  cursor: pointer;
  font-size: 1rem;
  margin-bottom: 0;

  & > input {
    opacity: 0; /* due to accessibility */
    width: 22px;
    height: 22px;
    & ~ ${Check} {
      transition: border-color 300ms;
    }

    &:hover ~ ${Check} {
      border-color: #d0d0d0;
    }
    &:checked ~ ${Check} {
      border-color: ${({ theme }) => theme.colors.primary};
      svg {
        color: ${({ theme }) => theme.colors.primary};
        display: block;
      }
    }
  }
  &:focus-within ${Check} {
    border-width: 2px;
    border-color: ${({ theme }) => theme.colors.primary} !important;
  }
`

const Label = styled.span`
  margin-left: 5px;
  white-space: nowrap;
`

type Props = {
  id: string
  checked?: boolean
  onChange?: (checked: boolean) => void
  label?: ReactNode
}

export function SelectingCheckbox(props: Props) {
  const handleClick = () => {
    const value = !props.checked
    props.onChange?.(value)
  }

  return (
    <Checkbox>
      <input type="checkbox" id={props.id} name={props.id} checked={props.checked} onChange={handleClick} />
      <Check>
        <FontAwesomeIcon icon={faCheck} />
      </Check>

      <Label>{props.label}</Label>
    </Checkbox>
  )
}
