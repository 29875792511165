import { useTranslation } from 'react-i18next'

import { formatNumber, THINSP } from '../../helpers/formatters'

export function Weight({ value, fractionDigits = 0 }: { value?: number; fractionDigits?: number }) {
  const { i18n } = useTranslation()

  if (value === undefined) {
    return null
  }
  return (
    <>
      `${formatNumber(value, i18n.language, { fractionDigits })}${THINSP}g`
    </>
  )
}
