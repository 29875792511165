import { useMemo } from 'react'

import { IMembership } from '../api/types/team'
import { useIsAdmin } from '../hooks/useLoggedUser'
import { useTeams } from '../hooks/useTeams'

export type Permissions = {
  canRead: boolean
  canUse: boolean
  canWrite: boolean
}

export function teamToPermissions(team?: IMembership) {
  return {
    canRead: !!team?.rights_ro,
    canWrite: !!team?.rights_rw,
    canUse: !!team?.rights_use
  }
}

export function usePermissions(team_id: number) {
  const teams = useTeams()

  const permissions = useMemo(() => {
    const team = teams.find((team) => team.id === team_id)
    return teamToPermissions(team)
  }, [teams, team_id])

  return permissions
}

export function useCanControl(teamId: number) {
  const isAdmin = useIsAdmin()
  const permissions = usePermissions(teamId)

  return permissions.canUse || isAdmin
}
