import { useTranslation } from 'react-i18next'
import { Route, Routes } from 'react-router-dom'

import { NotFoundErrors } from '../api/types/errors'
import { NotFoundPage } from '../components/errors/NotFoundPage'
import { SimpleError } from '../components/errors/SimpleError'

export function ErrorPage() {
  const { t } = useTranslation()
  return (
    <Routes>
      <Route path="401" element={<SimpleError statusCode={401} statusMessage={t('error.401')} />} />
      <Route path="403" element={<SimpleError statusCode={403} statusMessage={t('error.403')} />} />
      <Route path="404" element={<NotFoundPage errorType={NotFoundErrors.NOT_FOUND_ENDPOINT} />} />
      <Route path="410" element={<SimpleError statusCode={410} statusMessage={t('error.410')} />} />
      <Route path="500" element={<SimpleError statusCode={500} statusMessage={t('error.500')} />} />
      <Route path="503" element={<SimpleError statusCode={503} statusMessage={t('error.503')} />} />
    </Routes>
  )
}
