import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { IPrinter } from '../../api/types/printer'
import { IConnectState } from '../../api/types/state'
import { useAuthState } from '../../context/authStore'
import { JobLayout } from './overview/JobLayout'
import * as S from './PrintersOverview/ListView.styled'
import { FirmwareUpdate } from './printerUpdates/FirmwareUpdate'
import PrusaLinkUpdate from './printerUpdates/PrusaLinkUpdate'

const ParamsWrapper = styled.div`
  display: flex;
  gap: 1rem;
`

const Params = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

export function PrinterBasicInfo(props: IPrinter) {
  const printer = props
  const { t } = useTranslation()
  const { user } = useAuthState()
  const isOffline = [IConnectState.OFFLINE, IConnectState.UNKNOWN].includes(printer.connect_state)

  return (
    <ParamsWrapper>
      <Params>
        {Number.isInteger(props.job_info?.progress) && (
          <JobLayout.Param>
            <S.Label>{t('printer.progress')}</S.Label>
            <S.Value>{`${printer?.job_info?.progress}%`}</S.Value>
          </JobLayout.Param>
        )}

        {!isOffline && (
          <>
            <FirmwareUpdate {...printer} />

            <PrusaLinkUpdate {...printer} />
          </>
        )}

        {printer.location && (
          <JobLayout.Param>
            <S.Label>{t('printer.location')}</S.Label>
            <S.Value>{printer.location}</S.Value>
          </JobLayout.Param>
        )}

        {user.teams && user.teams?.length > 1 && (
          <JobLayout.Param>
            <S.Label>{t('printer.team')}</S.Label>
            <S.Value>{printer.team_name}</S.Value>
          </JobLayout.Param>
        )}
      </Params>
    </ParamsWrapper>
  )
}
