import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { NotFoundErrors } from '../../api/types/errors'
import { IPrinter, PrinterUuid } from '../../api/types/printer'
import { IConnectState } from '../../api/types/state'
import { PrinterProvider } from '../../context/printerContext'
import { useToast } from '../../context/toastStore'
import { useBreakpoint } from '../../helpers/useBreakpoint'
import { useLoggedUserPreferences } from '../../hooks/useLoggedUser'
import { IQueryError, usePrinterService } from '../../services/usePrinterService'
import { OfflineScreen } from '../common/OfflineScreen'
import { PaceProgress } from '../common/PaceProgress'
import { NotFoundPage } from '../errors/NotFoundPage'
import { Layers } from '../helpers/zIndex'
import { StatusBarPosition } from '../preferences/types'
import { PourInResinModal } from './actions/PourInResinModal'
import { FooterTopBar } from './FooterTopBar'
import { CurrentPrinter } from './overview/CurrentPrinter'
import { PrinterTabs } from './PrinterTabs/PrinterTabs'

const Sticky = styled.div`
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: ${Layers.PRINTER_HEADER};
  align-self: flex-end;
`

function OverviewPage(props: { printer: IPrinter }) {
  const { printer } = props

  return (
    <>
      <CurrentPrinter {...printer} />
      <PrinterTabs />
      {printer.connect_state === IConnectState.POUR_IN_RESIN && <PourInResinModal />}
    </>
  )
}

const PrinterContainer = styled.div`
  display: flex;
  align-items: stretch;
  min-height: 100%;
  position: relative;
  flex-direction: column;
`

const PrinterContent = styled.div`
  flex-grow: 1;
  min-width: 0;
`

const sanitizeError = (error: IQueryError) => {
  return error.message || String(error)
}

export function PrinterDetailPage() {
  const { printerUuid } = useParams<{ printerUuid: PrinterUuid }>()
  const status_bar = useLoggedUserPreferences('status_bar')
  const isSimpleHeaderMode = status_bar.position === StatusBarPosition.BOTTOM

  const service = usePrinterService(String(printerUuid))
  const toast = useToast()
  const isMobile = !useBreakpoint('sm')
  const error = service.isError ? sanitizeError(service.error as IQueryError) : null

  useEffect(() => {
    if (error) {
      toast.add('Error', error)
    }
  }, [error, toast])

  if (service.isError) {
    const queryError = service.error as IQueryError | undefined

    if (
      queryError?.code === NotFoundErrors.NOT_FOUND_ENDPOINT ||
      queryError?.code === NotFoundErrors.NOT_FOUND_PRINTER
    ) {
      return <NotFoundPage errorType={queryError.code} />
    }

    return <OfflineScreen />
  }

  return (
    <>
      {service.isLoading && <PaceProgress />}
      {service.isSuccess && (
        <PrinterProvider printer={service.data} refetch={service.refetch}>
          <PrinterContainer>
            <PrinterContent>
              <OverviewPage printer={service.data} />
            </PrinterContent>
            {!isMobile && isSimpleHeaderMode && service.data.connect_state !== IConnectState.OFFLINE && (
              <Sticky>
                <FooterTopBar {...service.data} />
              </Sticky>
            )}
          </PrinterContainer>
        </PrinterProvider>
      )}
    </>
  )
}
