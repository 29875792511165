import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export const Label = styled.span`
  position: relative;
  display: inline-block;
  margin-left: 0.2rem;
  top: -0.2rem;

  background: var(--color-primary);
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.65rem;
  line-height: 0.65rem;
  height: 0.75rem;

  color: var(--color-text-inverse);
  padding: 1px 2px;
`

export function BetaLabel() {
  return <Label>BETA</Label>
}

export function NewLabel() {
  const { t } = useTranslation()

  return <Label>{t('new', 'new')}</Label>
}
