export const last = <T>(values: T[]) => values[values.length - 1]

export const delay = (ms = 0) => {
  let timeoutId = 0

  const promise = new Promise((resolve) => {
    timeoutId = window.setTimeout(resolve, ms)
  })
  const cancel = () => {
    window.clearTimeout(timeoutId)
  }

  return {
    promise,
    cancel
  }
}

export const range = (start: number, stop: number, step = 1) =>
  Array(Math.ceil((stop - start) / step))
    .fill(start)
    .map((x, y) => x + y * step)

export function chunks<T>(array: T[], size: number) {
  return Array(Math.ceil(array.length / size))
    .fill(0)
    .map((_, index) => index * size)
    .map((begin) => array.slice(begin, begin + size))
}

export const randomBool = () => Math.random() < 0.5

export const isNumber = (value: unknown): value is Number => typeof value === 'number' && !Number.isNaN(value)

export const isNotNull = <T>(value: T | null): value is T => value !== null

export const isDefined = <T>(value: T | null | undefined): value is T => value !== null && value !== undefined

export const isNonZero = (value: number | undefined | null): value is number => isNumber(value) && value > 0

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random#getting_a_random_integer_between_two_values
export const randomInt = (min: number, max: number) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min) + min)
}

export const uniqBy = <T, V>(arr: T[], predicate: (item: T) => V) => {
  const cb = typeof predicate === 'function' ? predicate : (o: T) => o[predicate]
  const result: T[] = []
  const map = new Map()

  arr.forEach((item) => {
    const key = item === null || item === undefined ? item : cb(item)

    if (!map.has(key)) {
      map.set(key, item)
      result.push(item)
    }
  })

  return result
}

export const identity = <T>(val: T): T => val
