import { ReactNode } from 'react'
import styled from 'styled-components'

type Props = {
  children: ReactNode
}

const StyledButtonGroup = styled.div`
  display: flex;
  .btn:first-child,
  .connect-button:first-child {
    .btn:not(:last-child),
    .connect-button:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .btn + .btn,
  .btn + .connect-button,
  .connect-button + .connect-button {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .btn:not(:last-child),
  .connect-button:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`

export function ButtonGroup(props: Props) {
  return <StyledButtonGroup>{props.children}</StyledButtonGroup>
}
