import { useTranslation } from 'react-i18next'

import { useCanControl } from '../../../context/permissionsStore'
import { usePrinter } from '../../../context/printerContext'
import { useFlashFirmware } from '../../../hooks/useFlashFirmware'
import { AdaptiveButton } from '../../common/AdaptiveButton/AdaptiveButton'
import { WithConfirm } from '../../common/ConfirmModal'

type Props = {
  path: string
  canFlash?: boolean
}

export function FlashFirmwareAction(props: Props) {
  const { path, canFlash } = props
  const { printer } = usePrinter()
  const { t } = useTranslation()
  const { execute: installFirmwareToPrinter, isAvailable } = useFlashFirmware(printer)
  const canControl = useCanControl(printer.team_id)
  const isDisabled = !canControl || !canFlash

  let disabledTooltip = ''
  if (!canControl) {
    disabledTooltip = t('printer.tooltip.rights')
  } else if (!canFlash) {
    disabledTooltip = t('printer.tooltip.disabled-functionality')
  }

  return (
    <WithConfirm>
      {(confirm) => (
        <AdaptiveButton
          icon="flashColorIcon"
          label={t('printer.settings.firmware.flash')}
          isLoading={false}
          isAvailable={isAvailable}
          isDisabled={isDisabled}
          disabledTooltip={disabledTooltip}
          trigger={confirm(() => installFirmwareToPrinter({ path }))}
        />
      )}
    </WithConfirm>
  )
}
