import React, { useEffect, useState } from 'react'
import { ButtonGroup, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import styled from 'styled-components'

import { GcodeArgs, IGcodeTemplateFromResponse, MAX_TEMPLATE_HEIGHT, MultiSelectOption } from '../../api/types/gcode'
import { IPrinterTypes } from '../../api/types/printer'
import { usePrinterTypes } from '../../hooks/usePrinterType'
import { useTeamName } from '../../hooks/useTeamName'
import { useTeams, useWritableTeams } from '../../hooks/useTeams'
import { Button } from '../common/Button'
import { SvgIcon } from '../common/SvgIcon'
import { Tag } from '../common/Tag'
import { GcodeHighlighter } from '../helpers/GcodeHighlighter'
import { Td } from '../helpers/responsiveTable'
import { AddGcodeParamsModal } from './AddGcodeParamsModal'
import { RowsLabel } from './Commands.styled'
import { EditGcodeTemplateForm } from './EditGcodeTemplateForm'
import { RemoveGcodeTemplate } from './RemoveGcodeTemplate'

const MultilineCode = styled.code`
  white-space: pre-line;
  color: var(--color-text);
`

const Block = styled.div`
  display: flex;
  width: 100%;
`

const MultiSelect = styled(Select)`
  z-index: 9;
`

type Props = {
  gcode: IGcodeTemplateFromResponse
  onRemove: () => void
  onEdit: () => void
}

const getOptions = (types: string[], availablePrinterTypes: IPrinterTypes) => {
  return types.map((type) => {
    return {
      label: (availablePrinterTypes.find((printerType) => printerType.id === type) || {}).name || '',
      value: type
    }
  })
}

export function GcodeTemplateRow(props: Props) {
  const { gcode, onRemove, onEdit } = props
  const { t } = useTranslation()
  const [isEditable, setIsEditable] = useState(false)
  const [name, setName] = useState(gcode.name)
  const [description, setDescription] = useState(gcode.description)
  const [template, setTemplate] = useState(gcode.template)
  const [templateArgs, setTemplateArgs] = useState(gcode.args)
  const availablePrinterTypes = usePrinterTypes()
  const [printerTypes, setPrinterTypes] = useState<MultiSelectOption[]>(
    getOptions(gcode.printer_types, availablePrinterTypes)
  )
  const [teamId, setTeamId] = useState(gcode.team_id)
  const teams = useTeams()
  const { writableTeams } = useWritableTeams()
  const [templateHeight, setTemplateHeight] = useState((gcode.template.match(/\n/g) || []).length + 1)
  const [showParamsModal, setShowParamsModal] = useState(false)
  const { getTeamName } = useTeamName()

  // Update values everytime the gcode is changed & not in edit mode
  useEffect(() => {
    if (!isEditable) {
      setName(gcode.name)
      setDescription(gcode.description)
      setTemplate(gcode.template)
      setTemplateArgs(gcode.args)
      setPrinterTypes(getOptions(gcode.printer_types, availablePrinterTypes))
      setTeamId(gcode.team_id)
    }
  }, [gcode, isEditable, availablePrinterTypes])

  const onChangeEditable = (editable: boolean) => {
    setIsEditable(editable)
  }

  const nameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.currentTarget.value)
  }

  const templateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rows = (e.currentTarget.value.match(/\n/g) || []).length + 1

    if (rows > MAX_TEMPLATE_HEIGHT) {
      const updatedTemplate = e.currentTarget.value.split(/\n/g).slice(0, MAX_TEMPLATE_HEIGHT).join('\n')
      setTemplate(updatedTemplate)
      setTemplateHeight(MAX_TEMPLATE_HEIGHT)
    } else {
      setTemplate(e.currentTarget.value)
      setTemplateHeight(rows)
    }
  }

  const updateTemplate = (params: GcodeArgs[]) => {
    setTemplateArgs(params)
  }

  const removeArgument = (i: number) => {
    const newArgs = [...templateArgs]
    newArgs.splice(i, 1)
    setTemplateArgs(newArgs)
  }

  const descriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(e.currentTarget.value)
  }

  return (
    <>
      <tr className={isEditable ? 'table-active' : ''}>
        <Td>{gcode.id}</Td>
        <Td>
          {!isEditable && name}
          {isEditable && <Form.Control value={name} onChange={nameChange} type="text" required />}
        </Td>
        <Td>
          {!isEditable && description}
          {isEditable && <Form.Control value={description} onChange={descriptionChange} type="text" required />}
        </Td>
        <Td>
          {!isEditable && (
            <MultilineCode>
              <GcodeHighlighter gcode={template} />
            </MultilineCode>
          )}
          {isEditable && (
            <>
              <Form.Control as="textarea" rows={templateHeight} value={template} onChange={templateChange} required />
              <>
                {template && (
                  <RowsLabel rows={templateHeight} maxRows={MAX_TEMPLATE_HEIGHT}>
                    {t('gcode.template-rows-label', { rows: templateHeight, maxRows: MAX_TEMPLATE_HEIGHT })}
                  </RowsLabel>
                )}
              </>
            </>
          )}
        </Td>
        <Td>
          <div className="d-flex flex-wrap gap-2">
            {isEditable && (
              <Block>
                <Button onClick={() => setShowParamsModal(true)}>
                  <SvgIcon icon="plusIcon" size="18" />
                  {t('gcode.edit-arguments')}
                </Button>
              </Block>
            )}
            {templateArgs.map((arg, i) => (
              <Tag
                key={i}
                title={`${arg.name || ''} (${arg.type})`}
                onClose={isEditable ? () => removeArgument(i) : undefined}
              />
            ))}
          </div>
        </Td>
        <Td style={{ overflow: 'unset' }}>
          {!isEditable && printerTypes.map((type) => <div>{type.label}</div>)}
          {isEditable && (
            <MultiSelect
              value={printerTypes}
              options={availablePrinterTypes.map((type) => ({ label: type.name, value: type.id }))}
              onChange={(options) => {
                const values = options as MultiSelectOption[]
                setPrinterTypes(values)
              }}
              placeholder={t('gcode.printer-type-select')}
              isMulti
              isClearable
              closeMenuOnSelect={false}
              hideSelectedOptions
            />
          )}
        </Td>
        <Td>
          {!isEditable && getTeamName(teams.find((team) => team.id === teamId))}
          {isEditable && (
            <Form.Control
              as="select"
              value={teamId}
              onChange={(e) => setTeamId(Number(e.currentTarget.value))}
              style={{ fontStyle: printerTypes.length !== 0 ? 'normal' : 'italic' }}
            >
              {writableTeams.map((team) => (
                <option key={team.id} value={team.id} className="non-italic">
                  {getTeamName(team)}
                </option>
              ))}
            </Form.Control>
          )}
        </Td>
        <Td>
          <ButtonGroup>
            <EditGcodeTemplateForm
              id={gcode.id}
              name={name}
              description={description}
              template={template}
              isEditable={isEditable}
              args={templateArgs}
              printerTypes={printerTypes}
              teamId={teamId}
              onEdit={onEdit}
              changeEditable={onChangeEditable}
            />
            {gcode.id && <RemoveGcodeTemplate id={gcode.id} onRemove={onRemove} />}
          </ButtonGroup>
        </Td>
      </tr>
      {showParamsModal && (
        <AddGcodeParamsModal
          onCancel={() => setShowParamsModal(false)}
          onSuccess={(params) => {
            setShowParamsModal(false)
            updateTemplate(params)
          }}
          templateArgs={templateArgs}
        />
      )}
    </>
  )
}
