import { useLoginInfo } from '@prusaconnect/api/services/useLogin'
import { useState } from 'react'

import { getThumbnailUrlFromSnapshot } from '../../../api/modules/app/cameras'
import { IJobCamera } from '../../../api/types/cameras'
import { usePrinter } from '../../../context/printerContext'
import { AIDetectorModal } from '../../camera/AIDetectorModal'
import { CameraData, CameraDetail } from '../../camera/CameraDetail'

type Props = {
  jobCameras: IJobCamera[]
}

export function JobCameraSnapshots({ jobCameras }: Props) {
  const user = useLoginInfo()
  const isEmployee = user?.is_employee
  const {
    printer: { connect_state }
  } = usePrinter()
  const [imgToAnalyze, setImgToAnalyze] = useState<string | null>(null)
  const cameraPreviewData: CameraData[] = jobCameras.map((camera) => {
    const lastSnapshot = camera.snapshots[camera.snapshots.length - 1]

    return {
      cameraName: camera.name || '',
      cameraId: camera.id,
      snapshotUrls: camera.snapshots,
      thumbnail: lastSnapshot ? getThumbnailUrlFromSnapshot(lastSnapshot) : undefined,
      isLoading: false,
      isRefetching: false
    }
  })

  return (
    <>
      <CameraDetail
        cameras={cameraPreviewData}
        uploadToAi={isEmployee ? setImgToAnalyze : undefined}
        connectState={connect_state}
      />
      <AIDetectorModal fileUrl={imgToAnalyze || ''} />
    </>
  )
}
