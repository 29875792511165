import styled from 'styled-components'

import { device } from '../interfaces/device'
import { Container } from './bootstrap/Container'

export const Footer = styled.footer`
  background-color: #2a2a2a;
  padding: 0.5rem 15px;
`

export const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;

  @media ${device.xs} {
    flex-direction: row;
  }
`

export const ColLeft = styled.div`
  flex: 1;
`

export const ColCenter = styled.div`
  flex: 2;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

export const ColRight = styled.div`
  flex: 1;
  justify-content: end;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: center;
`

export const Span = styled.span`
  color: #bababa;
  font-size: 0.75em;
  padding-right: 0.5em;
  padding-left: 0.5em;
`

export const Delimeter = styled.span`
  color: #bababa;
  font-size: 0.75em;
  &:after {
    content: '|';
  }
`

export const A = styled.a`
  color: #bababa;
  font-size: 0.75em;
  padding-right: 0.5em;
  padding-left: 0.5em;
  text-decoration: underline;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`

export const Name = styled.span`
  color: white;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 500;
`

export const Builds = styled.div``

export const Versions = styled.div``

export const IconLink = styled.a`
  display: block;

  svg {
    fill: white;

    &:hover {
      fill: #bababa;
    }
  }
`
