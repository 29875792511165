import { useTranslation } from 'react-i18next'

import { usePrinter } from '../../../context/printerContext'
import { useCommandMutation } from '../../../hooks/commands/useCommandMutation'
import { LoadingButtonOutlined } from '../../common/LoadingButtonOutlined'
import { SvgIcon } from '../../common/SvgIcon'

export function DisableMotorsButton() {
  const { t } = useTranslation()
  const { printer } = usePrinter()

  const disableSteppers = useCommandMutation(printer, {
    command: 'DISABLE_STEPPERS',
    successMessage: {
      title: t('printer.control.disableSteppers'),
      body: t('printer.control.disableSteppers.description')
    }
  })

  return (
    <LoadingButtonOutlined
      onClick={() => disableSteppers.execute({})}
      disabled={disableSteppers.isLoading || !disableSteppers.isAvailable}
      isLoading={disableSteppers.isLoading}
    >
      <SvgIcon icon="disableSteppersIcon" size="20" />
      {t('printer.control.disableSteppers.btn')}
    </LoadingButtonOutlined>
  )
}
