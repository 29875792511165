import { IPrinterSimpleView } from '../../api/types/printer'

export function getPrinterName(printer?: IPrinterSimpleView) {
  if (!printer) {
    return ''
  }

  return printer.name || printer.printer_type_name
}

export enum QueryKeys {
  SNAPSHOT_QUERY = 'cameraSnapshot',
  THUMBNAIL_QUERY = 'cameraSnapshotThumbnail',
  CAMERA_LIST_QUERY = 'cameras'
}
