import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { ITimetype } from '../../interfaces/time'
import { Label, Value } from '../common/Parameters'
import { Time } from '../helpers/time'

type Props = {
  start?: number
  end?: number
  estimated?: number
}

export function JobTimeParameters(props: Props) {
  const { start, end, estimated } = props
  const { t } = useTranslation()
  const params = []

  if (start) {
    params.push({
      label: t('jobs.start'),
      value: <Time unixTimestamp={start} noSeconds />
    })
  }

  if (end) {
    params.push({
      label: t('jobs.end'),
      value: <Time unixTimestamp={end} noSeconds />
    })
  }

  if (start && end) {
    params.push({
      label: t('jobs.printing-time'),
      value: <Time unixTimestamp={end - start} type={ITimetype.COUNTDOWN} />
    })
  }

  if (estimated) {
    params.push({
      label: t('gcode-meta.estimated-printing-time-normal-mode'),
      value: <Time unixTimestamp={estimated} type={ITimetype.DURATION} noSeconds />
    })
  }

  return (
    <>
      {params.map(({ label, value }) => (
        <Fragment key={label}>
          <Label>{label}</Label>
          <Value>{value}</Value>
        </Fragment>
      ))}
    </>
  )
}
