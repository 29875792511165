import cx from 'classnames'
import { ReactNode } from 'react'
import styled from 'styled-components'

type Props = {
  children: ReactNode
  className?: string
  indent?: boolean
}

const H2 = styled.h2`
  font-size: 1.25rem;
  font-weight: 700;
`

export function ContainerTitle(props: Props) {
  const { children, className, indent = false } = props
  return (
    <H2
      className={cx('d-flex align-items-center text-base font-atlas-light whitespace-nowrap mt-3 mb-3', className, {
        'mx-3': indent
      })}
    >
      {children}
    </H2>
  )
}
