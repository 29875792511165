import styled, { useTheme } from 'styled-components'

import { PlainButton } from '../common/PlainButton'
import { SvgIcon } from '../common/SvgIcon'
import { GroupFilter } from './filters/GroupFilter'
import { StateFilter } from './filters/StateFilter'
import { TeamFilter } from './filters/TeamFilter'
import { useFilterHandlers } from './hooks/useFilterHandlers'
import { usePrintersQueryState } from './hooks/usePrintersQueryState'

const FiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
`

export const Filter = styled.div`
  min-width: 200px;
  font-size: 0.9rem;
`

export function Filters() {
  const { reset } = useFilterHandlers()
  const filter = usePrintersQueryState()
  const theme = useTheme()

  const isResetVisible =
    !!filter.groupId || !!filter.teamId || (Array.isArray(filter.states) && filter.states.length > 0)

  return (
    <div className="d-flex flex-grow-1 flex-wrap align-items-center">
      <FiltersContainer>
        <Filter>
          <StateFilter />
        </Filter>

        <GroupFilter isClearable />

        <TeamFilter isClearable />

        {isResetVisible && (
          <PlainButton className="ml-2 d-flex align-items-center" onClick={reset}>
            <SvgIcon icon="nokIcon" fill={theme.colors.icons.secondary} size={16} />
          </PlainButton>
        )}
      </FiltersContainer>
    </div>
  )
}
