import { useQuery } from '@tanstack/react-query'

import { useApiClient } from '../../api/react'
import { useUpdateProfile } from '../../api/services/useAuth'
import { Material } from '../../api/types/filament'
import { IFileType, IPrintFile, ITreePrintFile } from '../../api/types/file'
import { IJob, IJobState, IPlannedJob, IPlannedJobState } from '../../api/types/job'
import { IPrinter } from '../../api/types/printer'
import { IConnectState } from '../../api/types/state'
import { DistanceUnits } from '../../helpers/formatters'
import gcodePreview from '../../img/gcode_preview.png'
import slaPreview from '../../img/sla_preview.png'
import { IDateFormat } from '../../interfaces/time'
import { PrintersPreviewEnum } from '../../interfaces/view'
import { useLoginPing } from '../../services/useLoginPing'
import { TemperatureUnits } from '../common/Temperature'
import {
  ConnectStateEnum,
  ISortableParam,
  JobParamEnum,
  PrinterFamily,
  PrinterParamEnum,
  PrintFileParamEnum,
  StatusBarParam,
  StatusBarPosition,
  TimeFormat
} from './types'

const BENCHY_TIME_REMAINING = 720

export function createJobInfo() {
  return {
    display_name: '3dbenchy_0.4n_0.2mm_PLA_MK4IS_43m.bgcode',
    hash: 'mYzD--TPBfO6zl9BPoHPAIku9BI.',
    id: 47,
    model_weight: 8.21,
    origin_id: 105,
    path: '/usb/3dbenchy_0.4n_0.2mm_PLA_MK4IS_43m.bgcode',
    preview_url: gcodePreview,
    print_height: 2,
    progress: 70,
    start: Math.floor((Date.now() - 1_800_000) / 1000),
    state: IJobState.PRINTING,
    time_printing: 1890,
    time_remaining: BENCHY_TIME_REMAINING,
    total_height: 3,
    weight_remaining: 2.463,
    filament_change_in: 5
  }
}

export function createSlaJobInfo() {
  return {
    display_name: 'Prusa_SL1S_Calibration_test_56m_50um_Prusa_Orange.sl1s',
    hash: 'mYzD--TPBfO6zl9BPoHPAIku9BI.',
    id: 47,
    model_weight: 8.21,
    origin_id: 105,
    path: '/usb/Prusa_SL1S_Calibration_test_56m_50um_Prusa_Orange.sl1s',
    preview_url: slaPreview,
    print_height: 2,
    progress: 70,
    start: Math.floor((Date.now() - 1_800_000) / 1000),
    state: IJobState.PRINTING,
    time_printing: 1890,
    time_remaining: BENCHY_TIME_REMAINING,
    total_height: 3,
    weight_remaining: 2.463
  }
}

export function createPrinter(props: any = {}): IPrinter {
  return {
    prusalink_api_key: 'cidSLMtQZjlFLg',
    axis_x: 0,
    axis_y: 0,
    axis_z: 0.15,
    connect_state: IConnectState.IDLE,
    created: 1617269031,
    firmware: '5.1.0',
    flow: 100,
    job_info: undefined, // createJobInfo()
    last_file_tree_change: 1626681982.3000002,
    last_info_event: 1626681982.5370321,
    last_online: 1701079138.3160684,
    location: 'Office',
    name: 'Saturn MK4',
    network_info: {
      digest: 'random3abd3cbdf11b11114635f10d06',
      hostname: 'demo-dev',
      username: 'connect',
      wifi_ipv4: '10.25.217.152',
      wifi_mac: 'b8:27:eb:5e:0a:a8',
      wifi_ssid: 'Prusa Research Office1'
    },
    nozzle_diameter: 0.4,
    printer_type: '1.4.0',
    printer_type_name: 'Original Prusa MK4',
    prusa_link: '0.5.1',
    sdk: '0.5.0',
    sn: 'CZPX1419X004XK20098',
    speed: 100,
    state_reason: 'new state from telemetry',
    team_id: 7823,
    team_name: 'Connect',
    temp: { temp_nozzle: 230, target_nozzle: 250, temp_bed: 90, target_bed: 80 },
    filament: { material: Material.PETG },
    uuid: '957ec4b2-229b-488d-a753-aaebba30fefa',
    rights_r: true,
    rights_u: true,
    rights_w: true,
    ...props
  }
}

export function createSlaPrinter(props: any = {}): IPrinter {
  return {
    cover_closed: true,
    current_layer: 5,
    name: 'SL1 Jupiter',
    firmware: '1.8.0-alpha.3',
    created: 1701089661,
    location: 'Office',
    sn: 'CZPX0121X009XC04751',
    uuid: '4bd7493e-72d1-44fa-9c92-32e7231b5c2e',
    team_id: 7823,
    team_name: 'Connect',
    is_beta: false,
    rights_r: true,
    rights_w: true,
    rights_u: true,
    prusaconnect_api_key: 'd979df1e4bd7493e',
    slicer_token: 'd979df1e4bd7493e',
    groups: [],
    state: 'IDLE',
    time_delta: 0,
    last_online: 1703167344.9693265,
    temp: {
      temp_cpu: 50.1
    },
    connect_state: 'IDLE',
    printer_state: 'IDLE',
    allowed_functionalities: ['files', 'queue', 'transfer_history', 'fin_states'],
    support: {
      current: '1.8.0-alpha.3',
      version: 'beta',
      state: 'supported',
      release: 'beta'
    },
    decision_maker: 'firmware',
    printer_type: '5.1.0',
    printer_type_name: 'Original Prusa SL1',
    flags: {
      incident: [],
      last_info_event: 1703167270.3108616
    },
    ...props
  }
}

export function createPrintFile(): IPrintFile | ITreePrintFile {
  return {
    display_name: '3dbenchy_0.4n_0.2mm_PLA_MK4IS_43m.bgcode',
    display_path: '/usb/3dbenchy_0.4n_0.2mm_PLA_MK4IS_43m.bgcode',
    hash: 'PyP30BwlD-jezID8eyseSKZlsvI.',
    m_timestamp: 1701073869,
    meta: {
      bed_temperature: 60,
      brim_width: 0,
      estimated_print_time: 2602,
      filament_cost: 0.44,
      filament_type: Material.PLA,
      // filament_used_cm3: 9.77,
      // filament_used_m: 4.06231,
      filament_used_mm: 4062.31,
      filament_used_mm3: 9770,
      fill_density: '15%',
      // ironing: 0,
      layer_height: 0.2,
      // max_layer_z: 48,
      nozzle_diameter: 0.4,
      printer_model: 'MK4IS',
      support_material: false,
      temperature: 225
      // total_height: 48
    },
    name: '3dbenchy_0.4n_0.2mm_PLA_MK4IS_43m.bgcode',
    path: '/usb/3DBENC~1.BGC',
    printer_type: '1.4.0',
    preview_mimetype: 'image/png',
    preview_url: gcodePreview,
    read_only: true,
    size: 1674012,
    team_id: 12294,
    type: IFileType.PRINT_FILE
  }
}

export function createSlaPrintFile(): IPrintFile | ITreePrintFile {
  return {
    display_name: 'Prusa_SL1S_Calibration_test_56m_50um_Prusa_Orange.sl1s',
    display_path: '/usb/Prusa_SL1S_Calibration_test_56m_50um_Prusa_Orange.sl1s',
    hash: 'PyP30BwlD-jezID8eyseSKZlsvI.',
    m_timestamp: 1701073869,
    meta: {
      bed_temperature: 60,
      brim_width: 0,
      estimated_print_time: 2602,
      printer_model: 'MK4IS',
      temperature: 225,
      // total_height: 48
      total_layers: 50
    },
    name: 'Prusa_SL1S_Calibration_test_56m_50um_Prusa_Orange.sl1s',
    path: '/usb/3DBENC~1.BGC',
    printer_type: '5.1.0',
    preview_mimetype: 'image/png',
    preview_url: slaPreview,
    read_only: true,
    size: 1674012,
    team_id: 12294,
    type: IFileType.PRINT_FILE
  }
}

export function createPlannedJobs(): IPlannedJob[] {
  return [
    {
      conditions: {
        file_in_cache: { enabled: true, satisfied: false },
        material_type: { enabled: false, expected: Material.PETG },
        nozzle_diameter: { enabled: false, satisfied: true },
        printer_ready: { enabled: true, satisfied: false }
      },
      created: 1693812805,
      file: {
        display_name: '3dbenchy_0.4n_0.2mm_PLA_MK4IS_43m.bgcode',
        hash: 'g8IRgcaxivBBwOjHSP00nCyJWPU.',
        name: '3dbenchy_0.4n_0.2mm_PLA_MK4IS_43m.bgcode',
        preview_mimetype: 'image/png',
        preview_url: gcodePreview,
        printer_type: '1.4.0',
        size: 6670020,
        team_id: 7907,
        type: IFileType.PRINT_FILE,
        // upload_id: 757,
        uploaded: 1693815886
      },
      hash: 'g8IRgcaxivBBwOjHSP00nCyJWPU.',
      id: 6,
      planned_end: 1701698267,
      planned_start: Math.floor(Date.now() / 1000) + BENCHY_TIME_REMAINING + 50,
      source: 'CONNECT_USER',
      state: IPlannedJobState.PLANNED
    }
  ]
}

export function createSlaPlannedJobs(): IPlannedJob[] {
  return [
    {
      conditions: {
        file_in_cache: { enabled: true, satisfied: false },
        // material_type: { enabled: false, expected: Material.PETG },
        // nozzle_diameter: { enabled: false, satisfied: true },
        printer_ready: { enabled: true, satisfied: false }
      },
      created: 1693812805,
      file: {
        display_name: 'Prusa_SL1S_Calibration_test_56m_50um_Prusa_Orange.sl1s',
        hash: 'mYzD--TPBfO6zl9BPoHPAIku9BI.',
        name: 'Prusa_SL1S_Calibration_test_56m_50um_Prusa_Orange.sl1s',
        preview_mimetype: 'image/png',
        preview_url: slaPreview,
        printer_type: '5.1.0',
        size: 6670020,
        team_id: 7907,
        type: IFileType.PRINT_FILE,
        uploaded: 1693815886
      },
      hash: 'mYzD--TPBfO6zl9BPoHPAIku9BI.',
      id: 6,
      planned_end: 1701698267,
      planned_start: Math.floor(Date.now() / 1000) + BENCHY_TIME_REMAINING + 50,
      source: 'CONNECT_USER',
      state: IPlannedJobState.PLANNED
    }
  ]
}

export function createDoneOrQueueJob(): IJob {
  return {
    display_name: '3dbenchy_0.4n_0.2mm_PLA_MK4IS_43m.bgcode',
    end: 1701764000,
    file: {
      display_name: '3dbenchy_0.4n_0.2mm_PLA_MK4IS_43m.bgcode',
      display_path: '/usb/3dbenchy_0.4n_0.2mm_PLA_MK4IS_43m.bgcode',
      hash: 'KJpwYgH0O8b8AAYf6ECpQAUjarQ.',
      meta: {
        bed_temperature: 60,
        brim_width: 0,
        estimated_print_time: 2737,
        filament_cost: 0.77,
        filament_type: Material.PLA,
        filament_used_g: 21.24,
        // filament_used_m: 7.121569999999999,
        filament_used_mm: 7121.57,
        fill_density: '15%',
        // ironing: 0,
        layer_height: 0.2,
        // max_layer_z: 7.4,
        nozzle_diameter: 0.4,
        printer_model: 'MK4IS',
        support_material: false,
        temperature: 225
        // total_height: 7.4
      },
      name: '3dbenchy_0.4n_0.2mm_PLA_MK4IS_43m.bgcode',
      path: '/usb/BASE_0~1.BGC',
      preview_mimetype: 'image/png',
      preview_url: gcodePreview,
      // read_only: false,
      size: 841469,
      team_id: 12294,
      type: IFileType.PRINT_FILE,
      // upload_id: 1683,
      uploaded: 1701761013
    },
    hash: 'KJpwYgH0O8b8AAYf6ECpQAUjarQ.',
    id: 228,
    mbl_points: [],
    origin_id: 234,
    path: '/usb/BASE_0~1.BGC',
    // print_height: 50,
    printer_uuid: 'f1382aa9-ca78-4a3b-bfb5-37923a30c423',
    // source: 'CONNECT_USER',
    start: 1701761040,
    state: IJobState.FIN_OK
    // team_id: 12294,
    // time_printing: 2943
  }
}

export function createSlaDoneOrQueueJob(): IJob {
  return {
    id: 22,
    display_name: 'Prusa_SL1S_Calibration_test_56m_50um_Prusa_Orange.sl1s',
    printer_uuid: '93dc4342-bdb4-4682-87bf-27d1c27ddcda',
    origin_id: 132,
    path: '/local/test/Prusa_SL1S_Calibration_test_56m_50um_Prusa_Orange.sl1s',
    state: IJobState.FIN_OK,
    hash: '2a6wGq30rSRmw0BmBqjz2Zo2VF0.',
    start: 1698136672,
    end: 1698136716,
    file: {
      type: IFileType.PRINT_FILE,
      name: 'Prusa_SL1S_Calibration_test_56m_50um_Prusa_Orange.sl1s',
      display_name: 'Prusa_SL1S_Calibration_test_56m_50um_Prusa_Orange.sl1s',
      size: 3698087,
      hash: '2a6wGq30rSRmw0BmBqjz2Zo2VF0.',
      team_id: 7823,
      printer_type: '5.1.0',
      meta: {
        exposure_time: 2
      },
      preview_url: slaPreview,
      preview_mimetype: 'image/png'
    },
    mbl_points: []
  }
}

export function reorderArray<T>(arr: T[], currentIndex: number, newPositionIndex: number): T[] {
  const element: T = arr.splice(currentIndex, 1)[0]

  if (newPositionIndex === -1) {
    arr.push(element)
  } else {
    arr.splice(newPositionIndex, 0, element)
  }

  return arr
}

export function intersectionWithExtras(array1: ISortableParam[] = [], array2: ISortableParam[] = []): ISortableParam[] {
  const set1 = new Set(array1.map((item) => item.name))
  const set2 = new Set(array2.map((item) => item.name))

  const intersection: ISortableParam[] = []
  array1.forEach((item) => {
    if (set2.has(item.name)) {
      intersection.push(item)
    }
  })
  const extras = array2.filter((item) => !set1.has(item.name))

  return intersection.concat(extras)
}

export function useUpdateUserPreferences() {
  const { refetch } = useLoginPing()
  return useUpdateProfile(() => refetch())
}

export function useUserPrintersProperties() {
  const { app } = useApiClient()
  const { data } = useQuery(['/user/printers'], () => app.printers.getPrinters())

  const hasFdm = !!(data?.printers || []).find((p) => !p.printer_type.startsWith('5.'))
  const hasSla = !!(data?.printers || []).find((p) => p.printer_type.startsWith('5.'))

  const hasMMU = !!(data?.printers || []).find((p) => p.slots && p.slots > 1)

  return { hasFdm, hasSla, hasMMU }
}

export function getUserDefaultPreferences() {
  return {
    date_time: {
      date_format: IDateFormat.l,
      time_format: TimeFormat.HALF,
      locale: null
    },
    units: {
      temp: TemperatureUnits.CELSIUS,
      dimensions: DistanceUnits.MM
    },
    status_bar: {
      position: StatusBarPosition.TOP,
      params: {
        [PrinterFamily.FDM]: [
          { name: StatusBarParam.SLOTS },
          { name: StatusBarParam.NOZZLE },
          { name: StatusBarParam.BED },
          { name: StatusBarParam.MATERIAL },
          { name: StatusBarParam.SPEED },
          { name: StatusBarParam.Z_HEIGHT }
        ],
        [PrinterFamily.SLA]: [
          /* { name: StatusBarParam.MATERIAL, visible: false },
          { name: StatusBarParam.REMAINING_RESIN, visible: false }, */
          { name: StatusBarParam.CURRENT_LAYER, visible: true },
          { name: StatusBarParam.COVER_CLOSED, visible: true }
        ]
      }
    },
    dark_mode: localStorage.getItem('visualTheme'),
    printers: {
      mode: PrintersPreviewEnum.TABLE,
      params: {
        [PrinterFamily.FDM]: {
          [PrintersPreviewEnum.TABLE]: {
            [ConnectStateEnum.IDLE]: [
              { name: PrinterParamEnum.PRINTER_TYPE },
              { name: PrinterParamEnum.MATERIAL },
              { name: PrinterParamEnum.NOZZLE },
              { name: PrinterParamEnum.LOCATION },
              { name: PrinterParamEnum.TEAM }
            ],
            [ConnectStateEnum.PRINTING]: [
              { name: PrinterParamEnum.PRINTING_PROGRESS },
              { name: PrinterParamEnum.PRINTING_JOB_NAME },
              { name: PrinterParamEnum.TIME_REMAINING },
              { name: PrinterParamEnum.ESTIMATED_END },
              { name: PrinterParamEnum.PRINTER_TYPE },
              { name: PrinterParamEnum.MATERIAL },
              { name: PrinterParamEnum.NOZZLE },
              { name: PrinterParamEnum.LOCATION, visible: false },
              { name: PrinterParamEnum.TEAM, visible: false }
            ]
          },
          [PrintersPreviewEnum.CARDS]: {
            [ConnectStateEnum.IDLE]: [
              { name: PrinterParamEnum.NOZZLE },
              { name: PrinterParamEnum.SPEED },
              { name: PrinterParamEnum.MATERIAL },
              { name: PrinterParamEnum.HEATBED },
              { name: PrinterParamEnum.Z_AXIS }
            ],
            [ConnectStateEnum.PRINTING]: [
              /* { name: PrinterParamEnum.FILE }, */
              /* { name: PrinterParamEnum.PROGRESS }, */
              { name: PrinterParamEnum.TIME_REMAINING },
              { name: PrinterParamEnum.PRINTING_TIME },
              { name: PrinterParamEnum.ESTIMATED_END },
              { name: PrinterParamEnum.HEIGHT }
            ]
          }
        },
        [PrinterFamily.SLA]: {
          [PrintersPreviewEnum.TABLE]: {
            [ConnectStateEnum.IDLE]: [
              { name: PrinterParamEnum.PRINTER_TYPE },
              { name: PrinterParamEnum.LOCATION },
              { name: PrinterParamEnum.TEAM }
            ],
            [ConnectStateEnum.PRINTING]: [
              { name: PrinterParamEnum.PRINTING_PROGRESS },
              { name: PrinterParamEnum.PRINTING_JOB_NAME },
              { name: PrinterParamEnum.TIME_REMAINING },
              { name: PrinterParamEnum.ESTIMATED_END },
              { name: PrinterParamEnum.PRINTER_TYPE },
              // { name: PrinterParamEnum.MATERIAL },
              { name: PrinterParamEnum.LOCATION, visible: false },
              { name: PrinterParamEnum.TEAM, visible: false }
            ]
          },
          [PrintersPreviewEnum.CARDS]: {
            [ConnectStateEnum.IDLE]: [
              { name: PrinterParamEnum.SPEED },
              { name: PrinterParamEnum.MATERIAL },
              { name: PrinterParamEnum.Z_AXIS }
            ],
            [ConnectStateEnum.PRINTING]: [
              /* { name: PrinterParamEnum.FILE }, */
              /* { name: PrinterParamEnum.PROGRESS }, */
              { name: PrinterParamEnum.TIME_REMAINING },
              { name: PrinterParamEnum.PRINTING_TIME },
              { name: PrinterParamEnum.ESTIMATED_END },
              { name: PrinterParamEnum.HEIGHT }
            ]
          }
        }
      }
    },
    files: {
      params: {
        [PrinterFamily.FDM]: {
          [ConnectStateEnum.IDLE]: [
            { name: PrintFileParamEnum.PRINTER_MODEL },
            { name: PrintFileParamEnum.ESTIMATED_PRINTING_TIME },
            { name: PrintFileParamEnum.MATERIAL },
            { name: PrintFileParamEnum.LAYER_HEIGHT },
            { name: PrintFileParamEnum.DATE },
            { name: PrintFileParamEnum.SIZE },
            { name: PrintFileParamEnum.NOZZLE_DIAMETER, visible: false },
            { name: PrintFileParamEnum.SUPPORT_MATERIAL, visible: false },
            { name: PrintFileParamEnum.BRIM_WIDTH, visible: false },
            { name: PrintFileParamEnum.FILL_DENSITY, visible: false }
          ],
          [ConnectStateEnum.PRINTING]: [
            { name: PrintFileParamEnum.PRINT_STARTED },
            { name: PrintFileParamEnum.PRINTING_TIME },
            { name: PrintFileParamEnum.REMAINING_TIME },
            { name: PrintFileParamEnum.ESTIMATED_END },
            { name: PrintFileParamEnum.FILAMENT_CHANGE_IN }
          ]
        },
        [PrinterFamily.SLA]: {
          [ConnectStateEnum.IDLE]: [
            { name: PrintFileParamEnum.PRINTER_MODEL },
            { name: PrintFileParamEnum.ESTIMATED_PRINTING_TIME },
            { name: PrintFileParamEnum.DATE },
            { name: PrintFileParamEnum.SIZE }
          ],
          [ConnectStateEnum.PRINTING]: [
            { name: PrintFileParamEnum.PRINT_STARTED },
            { name: PrintFileParamEnum.PRINTING_TIME },
            { name: PrintFileParamEnum.REMAINING_TIME },
            { name: PrintFileParamEnum.ESTIMATED_END }
            /* { name: PrintFileParamEnum.EXPOSURE_TIME },
            { name: PrintFileParamEnum.CURRENT_LAYER },
            { name: PrintFileParamEnum.TOTAL_LAYERS },
            { name: PrintFileParamEnum.RESIN_CONSUMED },
            { name: PrintFileParamEnum.RESIN_REMAINING } */
          ]
        }
      }
    },
    jobs: {
      params: {
        [PrinterFamily.FDM]: [
          { name: JobParamEnum.PRINTER_MODEL },
          { name: JobParamEnum.PRINTING_TIME },
          { name: JobParamEnum.MATERIAL },
          { name: JobParamEnum.LAYER_HEIGHT },
          { name: JobParamEnum.PRINT_END },
          { name: JobParamEnum.SIZE },
          { name: JobParamEnum.NOZZLE_DIAMETER, visible: false },
          { name: JobParamEnum.SUPPORT_MATERIAL, visible: false },
          { name: JobParamEnum.BRIM_WIDTH, visible: false },
          { name: JobParamEnum.FILL_DENSITY, visible: false }
        ],
        [PrinterFamily.SLA]: [
          // { name: JobParamEnum.PRINTER_MODEL },
          { name: JobParamEnum.PRINTING_TIME },
          // { name: JobParamEnum.MATERIAL },
          // { name: JobParamEnum.LAYER_HEIGHT },
          { name: JobParamEnum.PRINT_END },
          { name: JobParamEnum.SIZE }
          // TODO TOTAL_LAYERS...
        ]
      }
    }
  }
}
