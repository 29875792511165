import { useTranslation } from 'react-i18next'

import { config } from '../../config'
import { Button } from '../common/Button'
import { SvgIcon } from '../common/SvgIcon'

type Props = {
  url?: string
  className?: string
}

export function DownloadSettingsButton(props: Props) {
  const { t } = useTranslation()
  const download = () => {
    fetch(config.hostname + props.url, {
      credentials: 'include',
      method: 'GET'
    }).then((response) => {
      response.blob().then((blob) => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = 'prusa_printer_settings.ini'
        a.click()
      })
    })
  }
  return (
    <Button size="sm" onClick={download} className={props.className} {...(!props.url && { disabled: true })}>
      <SvgIcon icon="downloadIcon" size="18" />
      {t('printer.settings.download')}
    </Button>
  )
}
