import { ReactNode, useState } from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'

import { Icon } from '../../icons'
import { SvgIcon } from '../common/SvgIcon'

const SubmenuContainer = styled(Container)`
  background: var(--background-secondary);
  padding: 0.5em 0;
  transition: all 200ms ease-in-out;
`
const GroupContainer = styled.div<{
  column?: boolean
}>`
  padding: 0 1em;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  ${({ column }) =>
    column &&
    `
    flex-direction: column;
    align-items: stretch;
  `}
`

export const GroupTitle = styled.span<{
  secondaryText?: boolean
}>`
  display: block;
  text-transform: uppercase;
  font-weight: 700;
  padding: 0 0.5em;
  font-size: 0.75rem;
  color: var(--color-text);

  ${({ secondaryText }) =>
    secondaryText &&
    `
    color: var(--neutral-800);
    text-transform: uppercase;
  `}
`

type SubmenuProps = { sticky?: boolean; children?: ReactNode }

export function Submenu(props: SubmenuProps) {
  return (
    <SubmenuContainer $sticky={props.sticky} fluid>
      {props.children}
    </SubmenuContainer>
  )
}

type GroupProps = {
  title: string
  className?: string
  column?: boolean
  secondaryText?: boolean
  children: ReactNode
}

export function Group(props: GroupProps) {
  return (
    <GroupContainer column={props.column} className={props.className}>
      <GroupTitle title={props.title} secondaryText={props.secondaryText}>
        {props.title}
      </GroupTitle>
      {props.children}
    </GroupContainer>
  )
}

type ViewItemProps = {
  active: boolean
  to: string
  icon: Icon
  onClick: () => void
}

const ICON_SIZE = 24

export function ViewItem(props: ViewItemProps) {
  const { colors } = useTheme()
  const [fill, setFill] = useState('#858585')

  return (
    <Link to={props.to} onClick={props.onClick}>
      <SvgIcon
        icon={props.icon}
        size={ICON_SIZE}
        fill={props.active ? colors.primary : fill}
        onMouseEnter={() => setFill(colors.primary)}
        onMouseLeave={() => setFill('#858585')}
      />
    </Link>
  )
}
