import { useTranslation } from 'react-i18next'

export function useErrorTypeTranslations() {
  const { t } = useTranslation()

  const ResponseStatusMessage: {
    [key: string]: string
  } = {
    AUTH_BACKEND_DISABLED: t('service.error.AUTH_BACKEND_DISABLED'),
    BAD_COUNT_ARGS: t('service.error.BAD_COUNT_ARGS'),
    BAD_REQUEST: t('service.error.BAD_REQUEST'),
    BAD_REQUEST_MISSING_SIZE: t('service.error.BAD_REQUEST_MISSING_SIZE'),
    BAD_REQUEST_UNKNOWN_EVENT: t('service.error.BAD_REQUEST_UNKNOWN_EVENT'),
    COMMAND_COLD_EXTRUSION: t('service.error.COMMAND_COLD_EXTRUSION'),
    COMMAND_COORDINATES: t('service.error.COMMAND_COORDINATES'),
    CONFLICT_COMMAND: t('service.error.CONFLICT_COMMAND'),
    CONFLICT_PRINTER_GROUP: t('service.error.CONFLICT_PRINTER_GROUP'),
    CONFLICT_USER: t('service.error.CONFLICT_USER'),
    CONFLICT_USER_DEFAULT_TEAM: t('service.error.CONFLICT_USER_DEFAULT_TEAM'),
    CONFLICT_USER_TEAM: t('service.error.CONFLICT_USER_TEAM'),
    FORBIDDEN: t('service.error.FORBIDDEN'),
    FORBIDDEN_ADMIN_ONLY: t('service.error.FORBIDDEN_ADMIN_ONLY'),
    FORBIDDEN_DEFAULT_TEAM: t('service.error.FORBIDDEN_DEFAULT_TEAM'),
    FORBIDDEN_GROUP_NOT_EMPTY: t('service.error.FORBIDDEN_GROUP_NOT_EMPTY'),
    FORBIDDEN_INVALID_EXT: t('service.error.FORBIDDEN_INVALID_EXT'),
    FORBIDDEN_PW_CHANGE: t('service.error.FORBIDDEN_PW_CHANGE'),
    FORBIDDEN_TEAM_NOT_EMPTY: t('service.error.FORBIDDEN_TEAM_NOT_EMPTY'),
    FORBIDDEN_TEAM_RIGHT_W: t('service.error.FORBIDDEN_TEAM_RIGHT_W'),
    FORBIDDEN_TEAM_W_GROUPS: t('service.error.FORBIDDEN_TEAM_W_GROUPS'),
    FORBIDDEN_TEAM_W_PRINTERS: t('service.error.FORBIDDEN_TEAM_W_PRINTERS'),
    INVALID_CODE: t('service.error.INVALID_CODE'),
    INVALID_EMAIL: t('service.error.INVALID_EMAIL'),
    INVITATION_ALREADY_EXISTS: t('service.error.INVITATION_ALREADY_EXISTS'),
    NOT_FOUND_COMMAND: t('service.error.NOT_FOUND_COMMAND'),
    NOT_FOUND_ENDPOINT: t('service.error.NOT_FOUND_ENDPOINT'),
    NOT_FOUND_FILE: t('service.error.NOT_FOUND_FILE'),
    NOT_FOUND_FILE_IN_CACHE: t('service.error.NOT_FOUND_FILE_IN_CACHE'),
    NOT_FOUND_GCODE: t('service.error.NOT_FOUND_GCODE'),
    NOT_FOUND_GROUP: t('service.error.NOT_FOUND_GROUP'),
    NOT_FOUND_PRINTER: t('service.error.NOT_FOUND_PRINTER'),
    NOT_FOUND_PRINTER_GROUP: t('service.error.NOT_FOUND_PRINTER_GROUP'),
    NOT_FOUND_PRINTER_TYPE: t('service.error.NOT_FOUND_PRINTER_TYPE'),
    NOT_FOUND_REGISTRATION: t('service.error.NOT_FOUND_REGISTRATION'),
    NOT_FOUND_TEAM: t('service.error.NOT_FOUND_TEAM'),
    NOT_FOUND_TOKEN: t('service.error.NOT_FOUND_TOKEN'),
    NOT_FOUND_USER: t('service.error.NOT_FOUND_USER'),
    REGISTRATION_ACCEPTED: t('service.error.REGISTRATION_ACCEPTED'),
    SERVICE_UNAVAILABLE: t('service.error.SERVICE_UNAVAILABLE'),
    TOO_MANY_INVITATIONS: t('service.error.TOO_MANY_INVITATIONS'),
    UNAUTHORIZED: t('service.error.UNAUTHORIZED'),
    CONFLICT_DOWNLOADED_FILE: t('service.error.CONFLICT_DOWNLOADED_FILE')
  }

  const ResponseCodeName: {
    [key: string]: string
  } = {
    202: t('service.error.202'),
    400: t('service.error.400'),
    401: t('service.error.401'),
    403: t('service.error.403'),
    404: t('service.error.404'),
    409: t('service.error.409'),
    413: t('service.error.413'),
    500: t('service.error.500'),
    503: t('service.error.503')
  }

  return { ResponseStatusMessage, ResponseCodeName }
}
