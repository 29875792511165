import Markdown from 'markdown-to-jsx'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { IPrinter, PrinterFirmwareDecisionMaker, PrinterSupportState } from '../../../api/types/printer'
import { ConfirmModal } from '../../common/ConfirmModal'
import { SvgIcon } from '../../common/SvgIcon'
import { WithTooltip } from '../../common/WithTooltip'
import * as S from '../PrintersOverview/ListView.styled'
import { useFirmware } from './useFirmware'

const StyledIcon = styled(SvgIcon)`
  margin-left: 5px;
`

function PrusaLinkUpdate(props: IPrinter) {
  const { t } = useTranslation()
  const printer = props
  const { showFirmwareAvailable, setShowFirmwareAvailable } = useFirmware()

  const prusaLinkUpdateExists =
    printer.decision_maker === PrinterFirmwareDecisionMaker.PRUSA_LINK &&
    [PrinterSupportState.OUTDATED, PrinterSupportState.UNSUPPORTED].includes(printer.support.state)

  if (!prusaLinkUpdateExists) {
    return null
  }

  return (
    <>
      {showFirmwareAvailable && (
        <ConfirmModal
          id="printer-prusa-link-modal"
          title={t('printer.prusalink.confirm.title.prusalink-available')}
          body={
            <p>
              <Markdown>
                {t('printer.prusalink.confirm.body.notice', {
                  version: printer.support.stable,
                  link: 'https://help.prusa3d.com/guide/prusalink-and-prusa-connect-setup-mk3-s-_221744'
                })}
              </Markdown>
            </p>
          }
          confirmBtnText={t('button.confirm')}
          onCancel={() => setShowFirmwareAvailable(false)}
          onConfirm={() => {
            setShowFirmwareAvailable(false)
          }}
        />
      )}
      <div>
        <S.Label>{t('printer.prusalink')}</S.Label>
        <WithTooltip
          id="current-printer-firmware-stable-version"
          title={
            <Markdown>
              {t('printer.prusalink.confirm.body.notice', {
                version: printer.support.stable,
                link: 'https://help.prusa3d.com/guide/prusalink-and-prusa-connect-setup-mk3-s-_221744'
              })}
            </Markdown>
          }
          placement="right"
        >
          <S.Value>
            {printer.support.current}
            <StyledIcon
              size={18}
              icon="upgradeIcon"
              onClick={() => setShowFirmwareAvailable((prevState) => !prevState)}
            />
          </S.Value>
        </WithTooltip>
      </div>
    </>
  )
}

export default PrusaLinkUpdate
