import { useInfiniteQuery, useQuery } from '@tanstack/react-query'

import { useApiClient } from '../../../../api/react'
import { PrinterUuid } from '../../../../api/types/printer'
import { intervals } from '../../../../config'
import { useUploadContext } from '../../../../context/uploadContext'

function getPrinterFilesQueryKey(printerTeamId: number, limit: number, printerUuid?: PrinterUuid) {
  return ['printer-files', printerUuid, printerTeamId, limit]
}

export function usePrinterFiles(printerTeamId: number, printerUuid: PrinterUuid, limit?: number) {
  const api = useApiClient()
  const filesPerPage = limit || 20

  return useInfiniteQuery(
    getPrinterFilesQueryKey(printerTeamId, filesPerPage, printerUuid),
    ({ pageParam = { limit: filesPerPage } }) => {
      const queryParams = { ...pageParam, printer_uuid: printerUuid }
      return api.app.files.getFiles(printerTeamId, queryParams)
    },
    {
      refetchInterval: intervals.printerCloudFilesPolling,
      getNextPageParam: (lastPage, allPages) => {
        const loaded = allPages.flatMap((page) => page.files).length
        if (loaded < lastPage.pager.total) {
          return { limit: filesPerPage, offset: lastPage.pager.offset + filesPerPage }
        }
        return undefined
      }
    }
  )
}

export function useAllCloudFiles(printerTeamId: number) {
  const api = useApiClient()

  return useQuery(
    ['printer-files', printerTeamId],
    () => {
      return api.app.files.getFiles(printerTeamId)
    },
    {
      refetchInterval: intervals.printerCloudFilesPolling,
      keepPreviousData: true
    }
  )
}

export function useStorageQuota(printerTeamId: number) {
  const api = useApiClient()

  const { refetch, data } = useQuery(
    ['storage-quota', printerTeamId],
    () => {
      return api.app.files.getFiles(printerTeamId)
    },
    {
      keepPreviousData: true,
      enabled: false
    }
  )

  const { capacity = 0, total = 0 } = data || {}
  const free = capacity - total

  return {
    total: capacity,
    free,
    refetch
  }
}

export function useConnectUploads(teamId: number, uniqueContextId: string) {
  const api = useApiClient()

  const { state } = useUploadContext(uniqueContextId)

  return useQuery(
    [`/users/teams/${teamId}}/uploads`, uniqueContextId],
    () => api.app.transfers.getOngoingUploads(teamId),
    {
      refetchInterval: (data) => {
        return Object.keys(state || {}).length || data?.uploads.length ? 1000 : intervals.transfersPolling
      },
      keepPreviousData: true
    }
  )
}
