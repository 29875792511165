import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router'

import { useApiClient } from '../../api/react'
import { PaceProgress } from '../common/PaceProgress'
import { FilamentForm } from './FilamentForm'

export function EditFilament() {
  const api = useApiClient()
  const params = useParams<{ filamentId: string }>()
  const filamentId = Number(params.filamentId)

  const { data } = useQuery([`/filament/${filamentId}`], () => api.app.filaments.getFilament(filamentId), {
    cacheTime: 0 // dont cache, always get new data
  })

  if (!data) {
    return <PaceProgress />
  }

  return <FilamentForm filament={data} />
}
