import { keyframes } from 'styled-components'

export const buttonPulse = keyframes`
  0% {
      opacity: 0.75;
      background-color: #545b62;
  }
  50% {
      opacity: 0.25;
      background-color: #fa6831;
  }
  100% {
      opacity: 0.75;
      background-color: #545b62;
  }
`

export const textPulse = keyframes`
  0% {
      opacity: 0.75;
  }
  50% {
      opacity: 0.25;
  }
  100% {
      opacity: 0.75;
  }
`
