import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import { IPrinterSimpleView, ISlaPrinter } from '../../api/types/printer'
import { useLoggedUserSettings } from '../../hooks/useLoggedUser'
import { ConnectBadge } from '../common/ConnectBadge'
import { SvgIcon } from '../common/SvgIcon'
import { ISortableParam, PrinterFamily, StatusBarParam } from '../preferences/types'
import { Label } from './PrintersOverview/ListView.styled'

const Parameters = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  gap: 0.25rem 1rem;
`

const StatusItem = styled.span`
  font-weight: 300;
  font-size: 14px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  text-align: center;
  gap: 0.25rem;

  b {
    font-weight: 700;
    font-size: 13px;
  }
`

const StatusValue = styled.div<{ noFixedWidth?: boolean }>`
  display: flex;
  gap: 0.25rem;
  align-items: flex-end;
  min-width: ${(props) => (props.noFixedWidth ? '0' : '9ch')};
  justify-content: center;
`

const ICON_SIZE = 22

type IProps = IPrinterSimpleView &
  ISlaPrinter & {
    inverseColor?: boolean
  }

export function SlaPrinterParams(props: IProps) {
  const { t } = useTranslation()
  const theme = useTheme()

  const isDark = props.inverseColor || theme.name === 'dark'

  const textColor = props.inverseColor ? 'var(--current-printer-badge-text)' : 'var(--color-text)'
  const badgeBackground = isDark ? 'var(--current-printer-badge-background)' : theme.colors.backgrounds.body

  const slaParams = useLoggedUserSettings('status_bar', PrinterFamily.SLA)

  return (
    <Parameters>
      {slaParams.map((component: ISortableParam) => {
        if (component.visible === false) {
          return null
        }
        switch (component.name) {
          case StatusBarParam.CURRENT_LAYER:
            return (
              <Fragment key="current_layer">
                {props.current_layer !== undefined && (
                  <div>
                    <Label>{t('printer.params.current-layer', 'Current layer')}</Label>
                    <ConnectBadge colorHex={badgeBackground} textColor={textColor} kind="background">
                      <StatusItem>
                        <SvgIcon
                          icon={isDark ? 'zAxisColorWhiteIcon' : 'zAxisColorIcon'}
                          size={ICON_SIZE}
                          title={t('printer.current-layer', 'Current layer')}
                        />
                        <StatusValue>{props.current_layer}</StatusValue>
                      </StatusItem>
                    </ConnectBadge>
                  </div>
                )}
              </Fragment>
            )
          case StatusBarParam.COVER_CLOSED:
            return (
              <Fragment key="cover_closed">
                {props.cover_closed !== undefined && (
                  <div>
                    <Label>{t('printer.params.cover_closed', 'Cover state')}</Label>
                    <ConnectBadge colorHex={badgeBackground} textColor={textColor} kind="background">
                      <StatusItem>
                        <SvgIcon
                          icon={isDark ? 'coverIconWhiteIcon' : 'coverIconIcon'}
                          size={ICON_SIZE}
                          title={t('printer.cover_closed', 'Cover state')}
                        />
                        <StatusValue>{props.cover_closed ? t('Cover closed') : t('Cover opened')}</StatusValue>
                      </StatusItem>
                    </ConnectBadge>
                  </div>
                )}
              </Fragment>
            )
          default:
            return null
        }
      })}
    </Parameters>
  )
}
