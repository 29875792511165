import { useState } from 'react'
import Container from 'react-bootstrap/Container'
import styled from 'styled-components'

import { PageContainer } from '../../helpers/styled'
import { AddGroupForm } from './addGroupForm'
import { GroupsList } from './groupsList'

// TODO temporary workaround for bootstrap layout
const Section = styled.section`
  position: relative;
`

export function GroupsOverview() {
  const [updateTimestamp, setUpdateTimestamp] = useState<number>(0)
  const setUpdate = (timestamp: number) => setUpdateTimestamp(timestamp)

  return (
    <>
      <PageContainer fluid>
        <Section id="addnewgroup">
          <AddGroupForm update={setUpdate} />
        </Section>
      </PageContainer>

      <Container fluid>
        <Section id="groupsoverview">
          <GroupsList update={updateTimestamp} />
        </Section>
      </Container>
    </>
  )
}
