import { useParams } from 'react-router'

import { isState } from '../../../api/types/state'
import { isNotNull } from '../../../helpers/std'
import { useQueryParams } from '../../../hooks/useQueryParams'
import { PrintersViewEnum } from '../../../interfaces/view'

export function usePrintersQueryState() {
  const query = useQueryParams({ decode: false }) // custom decoding of sort_by param
  const params = useParams<{ view: string }>()

  const teamId = query.teamId ? Number(query.teamId) : undefined
  const groupId = query.groupId ? Number(query.groupId) : undefined
  const states = Array.isArray(query.states) ? query.states.filter(isNotNull).filter(isState) : undefined
  const sort_by = query.sort_by ? String(query.sort_by) : undefined

  return {
    teamId,
    groupId,
    states,
    sort_by,
    view: params.view as PrintersViewEnum
  }
}
