import { createContext, ReactNode, useContext } from 'react'

import { IPrinter } from '../api/types/printer'

type Context = {
  printer: IPrinter
  refetch: () => Promise<unknown>
}

const PrinterContext = createContext<Context | null>(null)

type Props = Context & {
  children: ReactNode
}

export function usePrinter() {
  const context = useContext(PrinterContext)
  if (!context) {
    throw new Error('<PrinterProvider> is missing in component tree!')
  }
  return context
}

export function PrinterProvider(props: Props) {
  const { printer, refetch, children } = props
  return <PrinterContext.Provider value={{ printer, refetch }}>{children}</PrinterContext.Provider>
}
