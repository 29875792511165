import { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import styled from 'styled-components'

import { Button } from '../components/common/Button'
import { SvgIcon } from '../components/common/SvgIcon'
import { Notifications } from '../components/notifications/Notifications'
import { useNotificationsService } from '../services/useNotificationsService'
import { NotificationsSettingsPage } from './NotificationsSettingsPage'

const NotificationsContainer = styled.div`
  max-width: 900px;
  margin: auto;
  padding: 1rem 0;

  h2 {
    margin: 0.8rem 0;
    font-size: 1.5rem;
  }

  a {
    color: var(--border-primary);

    & > div:hover {
      background-color: #fcd6c0;
    }
  }
`

const SpoolContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem;
`

const StyledButton = styled(Button)`
  display: block;
  margin: 1rem auto;
`

function LoadingSpool() {
  return (
    <SpoolContainer>
      <SvgIcon size={50} icon="loadingWheel4SIcon" />
    </SpoolContainer>
  )
}

export function NotificationsList() {
  const { notifications, setRead, setAllRead, refetch, fetchNextPage, isLoading } = useNotificationsService()

  useEffect(() => {
    refetch()
  }, [refetch])

  return (
    <NotificationsContainer>
      {isLoading ? (
        <LoadingSpool />
      ) : (
        <>
          <Notifications notifications={notifications} setRead={setRead} setAllRead={setAllRead} />
          <StyledButton onClick={() => fetchNextPage()}>Load more notifications</StyledButton>
        </>
      )}
    </NotificationsContainer>
  )
}

export function NotificationsPage() {
  return (
    <Routes>
      <Route path="settings/*" element={<NotificationsSettingsPage />} />
      <Route index element={<NotificationsList />} />
    </Routes>
  )
}
