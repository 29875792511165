import { Draggable, Droppable } from 'react-beautiful-dnd'
import { Spinner } from 'react-bootstrap'

import { IPrinter } from '../../../../api/types/printer'
import { areVectorsSame, IVector3 } from '../../../../api/types/vector'
import { useDndContext } from '../../../../context/dndContext'
import { getPrinterName } from '../../utils'
import * as S from './Cell.styled'
import { getDraggableId, getDroppableId } from './dnd'
import { Printer } from './Printer'

type Props = {
  printer?: IPrinter
  position: IVector3
  disableInset?: boolean
}

type EmptyCellProps = {
  position: IVector3
}

type PrinterCellProps = EmptyCellProps & {
  printer: IPrinter
  disableInset?: boolean
}

function EmptyCell(props: EmptyCellProps) {
  const { position } = props
  const { loadingPosition } = useDndContext()

  const isLoading = loadingPosition ? areVectorsSame(loadingPosition, position) : false

  const renderContent = () => {
    if (!isLoading) {
      return null
    }

    return <Spinner animation="border" variant="light" />
  }

  return (
    <S.Container>
      <Droppable droppableId={getDroppableId(position)} direction="horizontal">
        {(provided, snapshot) => (
          <S.CellContainer ref={provided.innerRef} {...provided.droppableProps}>
            <S.EmptyCell $isDraggingOver={snapshot.isDraggingOver}>
              {renderContent()}
              {provided.placeholder}
            </S.EmptyCell>
            <S.Name>-</S.Name>
          </S.CellContainer>
        )}
      </Droppable>
    </S.Container>
  )
}

function PrinterCell(props: PrinterCellProps) {
  const { position, printer, disableInset } = props
  return (
    <S.Container>
      <Droppable droppableId={getDroppableId(position)} direction="horizontal">
        {(provided) => (
          <S.CellContainer ref={provided.innerRef} {...provided.droppableProps}>
            <Draggable
              key={printer.uuid}
              draggableId={getDraggableId(printer)}
              index={1}
              shouldRespectForcePress={false}
            >
              {(provided, snapshot) => (
                <>
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={provided.draggableProps.style}
                  >
                    <Printer
                      inset={!disableInset && !snapshot.isDragging}
                      isDragging={snapshot.isDragging}
                      name={getPrinterName(printer)}
                      type={printer.printer_type}
                    />
                  </div>
                  {/* This is visible below dragged item */}
                  {snapshot.isDragging && (
                    <Printer inset={!disableInset} name={getPrinterName(printer)} type={printer.printer_type} />
                  )}
                </>
              )}
            </Draggable>
            {provided.placeholder}
          </S.CellContainer>
        )}
      </Droppable>
    </S.Container>
  )
}

/**
 * Grid cell with specific position (do not use for virtual group)
 */
export function Cell(props: Props) {
  const { printer, position, disableInset } = props
  if (printer) {
    return <PrinterCell printer={printer} position={position} disableInset={disableInset} />
  }

  return <EmptyCell position={position} />
}
