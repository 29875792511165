import * as S from './ProgressBar.styled'

export type Props = {
  now: number
  max: number
  label?: string
}

export function ProgressBar(props: Props) {
  const { now, max, label } = props
  const width = (now / max) * 100

  return (
    <S.Wrapper>
      <S.Now $width={width} />
      <S.Label>{label}</S.Label>
    </S.Wrapper>
  )
}
