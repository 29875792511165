import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { device } from '../../interfaces/device'
import { ButtonLink } from '../common/ButtonLink'
import { Layers } from '../helpers/zIndex'

export const HEADER_HEIGHT = 60
export const TOPBAR_HEIGHT = 40

export const OutageMessage = styled.div`
  background-color: #d76668;
  text-align: center;
  padding: 15px;
  color: #ffffff;

  a {
    color: #ffffff;
    text-decoration: underline;
  }

  a:hover {
    text-decoration: none;
  }
`

export const Sticky = styled.div`
  position: sticky;
  top: 0;
  z-index: ${Layers.HEADER};
`
const navStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border-secondary);
  transition: height 0.2s ease-in;
`

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  height: ${HEADER_HEIGHT}px;
`

export const Header = styled.nav`
  background: var(--background-body);
  height: ${HEADER_HEIGHT}px;
  ${navStyles}
`

const Column = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 30%;
  height: 100%;
`

export const Left = styled(Column)`
  gap: 2rem;
`

export const Center = styled(Column)`
  gap: 1rem;
`

export const Right = styled(Column)`
  justify-content: flex-end;
  gap: 1rem;

  @media ${device.ltXs} {
    gap: 0.5rem;
    > button:first-child {
      margin-left: 0.5rem;
    }
  }
`

export const Logo = styled(Link)`
  height: 100%;
  padding: 0.5rem 0 1rem;
  min-width: 150px;

  svg {
    height: 100%;
    width: 100%;
  }
`

export const ActionButton = styled(ButtonLink)`
  font-size: 0.85rem;
  line-height: 1.25;
`
