import { stringify } from 'query-string'

import { IEventsQuery, IEventsResponse, IEventTypeFromResponse } from '../../types/event'
import { PrinterUuid } from '../../types/printer'
import { RequestFactory } from '../../types/sdk'

export function createModule(rf: RequestFactory) {
  return {
    getEvents: (printerUuid: PrinterUuid, query: IEventsQuery = {}) =>
      rf.get<IEventsResponse>(`/app/printers/${printerUuid}/events?${stringify(query)}`),

    getEventTypes: () => rf.get<IEventTypeFromResponse[]>('/app/events')
  }
}
