import { useQuery } from '@tanstack/react-query'
import ms from 'ms'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useApiClient } from '../../../api/react'
import { IEventsQuery } from '../../../api/types/event'
import { IJobPeriod } from '../../../api/types/job'
import { ITelemetryQuery } from '../../../api/types/telemetry'
import { usePrinter } from '../../../context/printerContext'
import { isNonZero } from '../../../helpers/std'
import { getCurrentUnixtimestamp } from '../../../helpers/timestamp'
import { useIsSla } from '../../../hooks/usePrinterType'
import { SectionTitle } from '../../common/Parameters'
import { TelemetryChart } from '../telemetry/TelemetryChart'

const CHART_HEIGHT = 500
const paddingTime = 60

const TelemetryContainer = styled.div`
  height: ${CHART_HEIGHT}px;
  margin: 1rem 0;
`

type Props = {
  jobId: number
  start: number
  end: number
  isPrinting: boolean
}

/* Granularity of data values (how many values to group per second). 
 f.e 60 means that value is computed from 60 values/second. */
function computeGranularity(start: number, end: number) {
  const MAX_POINTS = 1000
  const jobLength = end - start

  return Math.max(Math.ceil(jobLength / MAX_POINTS), 1)
}

export function JobTelemetryChart({ jobId, start, end, isPrinting }: Props) {
  const api = useApiClient()
  const { t } = useTranslation()
  const {
    printer: { uuid, printer_type }
  } = usePrinter()
  const isSla = useIsSla(printer_type)

  const jobTimeBoundaries: IJobPeriod = {
    printingPeriod: {
      from: Number(start),
      to: Number(end)
    },
    fullJobPeriod: {
      from: Number(start) - paddingTime,
      to: Number(end) + paddingTime
    }
  }

  const getTelemetryQuery = (): ITelemetryQuery | null => {
    if (!isNonZero(start)) {
      return null
    }

    const to = jobTimeBoundaries?.fullJobPeriod?.to || getCurrentUnixtimestamp()

    const query: ITelemetryQuery = {
      from: jobTimeBoundaries.fullJobPeriod.from,
      to,
      granularity: computeGranularity(start, to)
    }

    if (isSla) {
      query.fields = ['temp_ambient', 'temp_uv_led', 'temp_cpu', 'fan_blower', 'fan_uv_led', 'fan_rear']
    }

    return query
  }

  const telemetryQuery = getTelemetryQuery()

  const telemetryResult = useQuery(
    [`/telemetry/${jobId}`],
    () => {
      if (!telemetryQuery) {
        return undefined
      }
      return api.app.telemetry.getData(uuid, telemetryQuery)
    },
    {
      enabled: !!telemetryQuery,
      refetchInterval: isPrinting ? ms('10s') : undefined
    }
  )

  const getEventsQuery = (): IEventsQuery | undefined => {
    if (!telemetryQuery) {
      return
    }

    return {
      from: telemetryQuery.from,
      to: telemetryQuery.to,
      event: ['STATE_CHANGED']
    }
  }

  const eventsQuery = getEventsQuery()

  const eventsQueryResult = useQuery(
    [`/events/${jobId}`],
    () => {
      return api.app.events.getEvents(uuid, eventsQuery)
    },
    {
      enabled: !!eventsQuery
    }
  )

  const events = eventsQueryResult.data?.events

  return (
    <>
      <SectionTitle>{t('telemetry.title-text')}</SectionTitle>
      <TelemetryContainer>
        {telemetryResult.data && jobTimeBoundaries && events && (
          <TelemetryChart
            telemetryData={telemetryResult.data}
            enableZooming
            showTimeline
            events={events}
            jobPeriod={jobTimeBoundaries}
            movingAverage={80}
          />
        )}
      </TelemetryContainer>
    </>
  )
}
