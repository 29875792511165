import Markdown from 'markdown-to-jsx'

import { ReactComponent as ConnectLogo } from '../../../public/prusa_connect_logo.svg'
import { useAuthState } from '../../context/authStore'
import { getEnvironment, Globals } from '../../helpers/getEnvironment'
import { useDevice } from '../../helpers/useDevice'
import { AfsHeaderMenu } from './AfsHeaderMenu'
import { DocumentTitle } from './DocumentTitle'
import { Drawer } from './Drawer'
import * as S from './Header.styled'
import { HeaderMenu } from './HeaderMenu'
import { LanguageDropdown } from './LanguageDropdown'
import { NotificationsDropdown } from './NotificationsDropdown'
import { ProxyTarget } from './ProxyTarget'
import { UserDropdown } from './UserDropdown'

export const OUTAGE_MESSAGE_ID = 'global-outage-message'

function TopMenu() {
  if (AFS_ENABLED) {
    return <AfsHeaderMenu />
  }
  return <HeaderMenu />
}

export function Header() {
  const { isAuthenticated } = useAuthState()
  const { lg } = useDevice()

  const outageMessage = getEnvironment(Globals.OUTAGE_MESSAGE)

  return (
    <>
      <DocumentTitle />
      {PROXY_TARGET && <ProxyTarget />}
      <S.Sticky>
        <S.Header>
          <S.HeaderContainer className="container">
            <S.Left>
              {isAuthenticated && !lg && <Drawer />}
              {!!lg && (
                <S.Logo to="/">
                  <ConnectLogo />
                </S.Logo>
              )}
              {isAuthenticated && !!lg && <TopMenu />}
            </S.Left>

            {!lg && (
              <S.Center>
                <S.Logo to="/">
                  <ConnectLogo />
                </S.Logo>
              </S.Center>
            )}

            <S.Right>
              <LanguageDropdown />
              {isAuthenticated && <NotificationsDropdown />}
              <UserDropdown />
            </S.Right>
          </S.HeaderContainer>
        </S.Header>
        {outageMessage && (
          <S.OutageMessage id={OUTAGE_MESSAGE_ID}>
            <Markdown>{outageMessage}</Markdown>
          </S.OutageMessage>
        )}
      </S.Sticky>
    </>
  )
}
