import { useMutation } from '@tanstack/react-query'
import Markdown from 'markdown-to-jsx'
import { useState } from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { useApiClient } from '../../api/react'
import { ConfirmModal } from '../common/ConfirmModal'
import { SvgIcon } from '../common/SvgIcon'

type Props = {
  id: number
  onRemove: () => void
}

export function RemoveGcodeTemplate(props: Props) {
  const [show, setShow] = useState(false)
  const { t } = useTranslation()

  const api = useApiClient()
  const { mutate: removeTemplate } = useMutation(() => api.app.gCodes.deleteGCodeTemplate(props.id), {
    onSuccess: () => props.onRemove()
  })

  return (
    <>
      <Button variant="outline-dark" size="sm" onClick={() => setShow(true)}>
        <SvgIcon icon="deleteIcon" size="16" />
        {t('button.delete')}
      </Button>

      {show && (
        <ConfirmModal
          onCancel={() => setShow(false)}
          onConfirm={() => removeTemplate()}
          title={t('gcode.remove-title')}
          body={<Markdown>{t('gcode.remove-body', { id: props.id })}</Markdown>}
          confirmBtnText={t('gcode.remove-button')}
          cancelBtnText={t('button.cancel')}
          confirmBtnIcon="deleteIcon"
          cancelBtnIcon="cancelIcon"
        />
      )}
    </>
  )
}
