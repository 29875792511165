/**
 * Guard function to ensure that all possible values of a discriminated union are handled.
 * @example
 * switch (action.type) {
 *  case 'button':
 *    return <Button {...action} />
 *  case 'link':
 *    return <Link {...action} />
 *  default:
 *    return exhaustiveGuard(action)
 *  }
 * @param _value
 */
export function exhaustiveGuard(_value: never): never {
  throw new Error(`ERROR! Reached forbidden guard function with unexpected value: ${JSON.stringify(_value)}`)
}
