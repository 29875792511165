const size = {
  xs: 420,
  sm: 576,
  md: 768,
  lg: 992,
  tablet: 1024,
  xl: 1200,
  xxl: 1400
}

export const device = {
  /** Sizes in pixels */
  size,

  /** From extra small devices */
  xs: `(min-width: ${size.xs}px)`,
  /** From small devices */
  sm: `(min-width: ${size.sm}px)`,
  /** From medium devices */
  md: `(min-width: ${size.md}px)`,
  /** From large devices */
  lg: `(min-width: ${size.lg}px)`,
  /** From tablet-like devices */
  tablet: `(min-width: ${size.tablet}px)`,
  /** From extra large devices */
  xl: `(min-width: ${size.xl}px)`,
  /** From extra extra large devices */
  xxl: `(min-width: ${size.xxl}px)`,
  /** Only touch devices */
  touchDevice: `(pointer:none), (pointer:coarse)`,
  /** For smaller than extra small */
  ltXs: `(max-width: ${size.xs}px)`,
  /** For smaller than small */
  ltSm: `(max-width: ${size.sm}px)`,
  /** For smaller than medium */
  ltMd: `(max-width: ${size.md}px)`,
  /** For smaller than large */
  ltLg: `(max-width: ${size.lg}px)`,
  /** For smaller than tablet-like */
  ltTablet: `(max-width: ${size.tablet}px)`,
  /** For smaller than extra large */
  ltXl: `(max-width: ${size.xl}px)`,
  /** For smaller than extra extra large */
  ltXxl: `(max-width: ${size.xxl}px)`
}
