/**
 * Temporary solution. Needs a proper UI and a unified backend.
 * TODO please delete me ASAP.
 */

import { useCallback, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import {
  AIDetectorResponse,
  getAiDetectorResult,
  Model,
  updateResultRating,
  uploadToAiDetector
} from '../../api/temporary-ai-detector-api'
import { PlainButton } from '../common/PlainButton'
import { SvgIcon } from '../common/SvgIcon'

type UploadResponse = {
  id: string
  filename: string
}

async function uploadToAi(snapshotUrl: string): Promise<UploadResponse> {
  const response = await fetch(snapshotUrl)
  const blob = await response.blob()
  const file = new File([blob], 'snapshot.jpg', { type: 'image/jpeg' })
  const uploadResponse = await uploadToAiDetector(file)
  return uploadResponse.json()
}

type Props = {
  isUploading: boolean
  fileUrl: string
  wiseResult: AIDetectionStatus
  fineResult: AIDetectionStatus
}

const ImgWrapper = styled.div`
  img {
    max-width: 100%;
    max-height: 30rem;
    display: block;
    margin: 1rem auto;
  }
`

const ModelResultWrapper = styled.div`
  h3 {
    font-size: 1.3rem;
  }

  h4 {
    font-size: 1rem;
    font-weight: 100;
  }
`

const ResultDetails = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`

const LikeDislike = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
`

const LoadingText = styled.div`
  display: flex;
  gap: 0.5rem;
  margin: 0.5rem 0;
`

function ModelResult({
  result,
  model,
  setRating
}: {
  result: AIDetectionStatus
  model: Model
  setRating: (id: number, rating: boolean) => void
}) {
  const [rated, setRated] = useState<boolean | null>(null)
  const setRatingAction = useCallback(
    (result: AIDetectionStatus, rating: boolean) => {
      if (result.result) {
        setRating(result.result.id, rating)
        setRated(rating)
      }
    },
    [result, setRating]
  )

  return (
    <ModelResultWrapper>
      <h3>{model === 'wise' ? 'Wise' : 'Finetuned'} model</h3>
      {result.status === 'analyzing' && (
        <LoadingText>
          <SvgIcon icon="loadingWheel4SIcon" size={30} /> Loading...
        </LoadingText>
      )}
      {result.error && <p>Error: {result.error}</p>}
      {result.result && (
        <ResultDetails>
          <div>
            <h4>Result:</h4>
            <p>{result.result.result}</p>
          </div>
          <div>
            <h4>Confidence:</h4>
            <p>{result.result.probability.toFixed(2)}%</p>
          </div>
          <div>
            <h4>Your rating:</h4>
            <LikeDislike>
              <PlainButton type="button" onClick={() => setRatingAction(result, true)} disabled={rated === true}>
                <SvgIcon icon="likeIcon" />
              </PlainButton>

              <PlainButton type="button" onClick={() => setRatingAction(result, false)} disabled={rated === false}>
                <SvgIcon icon="dislikeIcon" />
              </PlainButton>
            </LikeDislike>
          </div>
        </ResultDetails>
      )}
    </ModelResultWrapper>
  )
}

function ModalBody(props: Props) {
  const setRating = (resultId: number, rating: boolean) => {
    updateResultRating(resultId || 0, rating)
  }

  return (
    <div>
      {props.isUploading ? (
        <div>
          <p>Uploading...</p>
        </div>
      ) : (
        <div>
          <ImgWrapper>
            <img src={props.fileUrl} alt="" />
          </ImgWrapper>

          <ModelResult result={props.wiseResult} model="wise" setRating={setRating} />
          <ModelResult result={props.fineResult} model="fine" setRating={setRating} />
        </div>
      )}
    </div>
  )
}

export type AIDetectionStatus = {
  status: 'idle' | 'analyzing' | 'done'
  result: AIDetectorResponse | null
  error: string | null
}

const defaultAIDetectionStatus: AIDetectionStatus = {
  status: 'idle',
  result: null,
  error: null
}

export function AIDetectorModal({ fileUrl }: { fileUrl: string }) {
  const [showModal, setShowModal] = useState(false)
  const [uploadedImage, setUploadedImage] = useState<string | null>(null)
  const [isUploading, setIsUploading] = useState(true)
  const [wiseResult, setWiseResult] = useState<AIDetectionStatus>(defaultAIDetectionStatus)
  const [fineResult, setFineResult] = useState<AIDetectionStatus>(defaultAIDetectionStatus)
  const [error, setError] = useState<string | null>(null)
  const { t } = useTranslation()

  useEffect(() => {
    if (fileUrl) {
      setShowModal(true)
      setWiseResult({ ...defaultAIDetectionStatus, status: 'analyzing' })
      setFineResult({ ...defaultAIDetectionStatus, status: 'analyzing' })
      uploadToAi(fileUrl)
        .then((response: UploadResponse) => {
          setUploadedImage(response.id)
          setIsUploading(false)
        })
        .catch((error) => {
          setError(error)
          setIsUploading(false)
        })
    }
  }, [fileUrl])

  useEffect(() => {
    if (!uploadedImage) {
      return
    }
    getAiDetectorResult(uploadedImage, 'wise')
      .then((response) => {
        setWiseResult((prev) => ({ ...prev, status: 'idle', error: null, result: response }))
      })
      .catch((error) => {
        setWiseResult((prev) => ({ ...prev, status: 'idle', error: error.message, result: null }))
      })
    getAiDetectorResult(uploadedImage, 'fine')
      .then((response) => {
        setFineResult((prev) => ({ ...prev, status: 'idle', error: null, result: response }))
      })
      .catch((error) => {
        setFineResult((prev) => ({ ...prev, status: 'idle', error: error.message, result: null }))
      })
  }, [uploadedImage])

  const modalContainer = document.getElementById('modals')
  if (!modalContainer) {
    throw new Error('Modal container #modals not found! Check your component tree')
  }

  return createPortal(
    <Modal id="ai-detection-modal" show={showModal} onHide={() => setShowModal(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t('camera.ai-detection.title', 'AI Detection')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error ? (
          <p>Error: {error}</p>
        ) : (
          <ModalBody isUploading={isUploading} fileUrl={fileUrl} wiseResult={wiseResult} fineResult={fineResult} />
        )}
      </Modal.Body>
    </Modal>,
    modalContainer
  )
}
