import styled from 'styled-components'

import { SubTitle } from './styled'

export const SectionTitle = SubTitle
export const Section = styled.div`
  & + & {
    margin-top: 1.5rem;
  }
`

export const Param = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.25rem;
  font-size: 0.9rem;

  > :first-child {
    white-space: nowrap;
    text-transform: uppercase;
    font-weight: 600;
  }
`
