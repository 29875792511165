import { useTranslation } from 'react-i18next'

import { IConnectState } from '../api/types/state'
import { translateLabel } from '../components/common/PrinterStateTag'

export function usePrinterInState(printerState: IConnectState) {
  const { t } = useTranslation()

  return `${t('printer.overview.currentFile.in-state')} ${translateLabel(t, printerState).toUpperCase()}`
}
