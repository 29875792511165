import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useBreakpoint } from '../../helpers/useBreakpoint'
import { useInterval } from '../../helpers/useInterval'
import { Button } from '../common/Button'
import { Enemies } from './Enemies'
import { Player } from './Player'
import sheetMobile from './sprites/sheet_bg_mb.svg'
import sheetDesktop from './sprites/sheet_game_bg.png'
import gridDesktop from './sprites/sheet_game_grid.png'
import gridMobile from './sprites/sheet_grid_mb.svg'
import { gridSize, squareSize as squareSizeDesktop } from './types/consts'
import { IDirection } from './types/directions'
import { IEnemy } from './types/enemy'

type IProps = {
  updateScore: (score: number) => void
  setGameStarted: (value: boolean) => void
  gameStarted: boolean
}

const GameBoard = styled.div<{
  backgroundImage: string
}>`
  position: relative;
  height: 615px;
  width: 584px;
  margin: 25px auto;
  padding: 26px 10px 10px 16px;

  ${({ backgroundImage }) =>
    backgroundImage &&
    `
    background-image: url(${backgroundImage});
  `}

  @media only screen and (max-width: 576px) {
    height: 538px;
    width: 438px;
    padding: 110px 0 0 16px;
    background-repeat: no-repeat;
    background-size: cover;

    * > iframe {
      display: none;
    }
  }
`

const GameGrid = styled.div<{
  backgroundImage: string
}>`
  position: relative;
  height: 550px;
  width: 550px;
  overflow: hidden;
  background-position: center center;

  ${({ backgroundImage }) =>
    backgroundImage &&
    `
    background-image: url(${backgroundImage});
  `}

  @media only screen and (max-width: 576px) {
    height: 363px;
    width: 363px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 100% 100%;
  }
`

const StartButton = styled(Button)<{
  squareSize: number
}>`
  position: absolute;
  width: 160px;
  height: 50px;
  top: ${({ squareSize }) => `calc(${(squareSize * gridSize) / 2}px + 33px)`};
  left: calc(50% - 80px);
  background-color: white;
`

const GameOver = styled.span`
  width: 300px;
  margin: 35px auto 43px;
  color: #fa6831;
  font-size: 3rem;
  font-weight: 500;
  display: block;
  text-align: center;
`

export function Board(props: IProps) {
  const isSmOrLarge = useBreakpoint('sm')
  const squareSize = isSmOrLarge ? squareSizeDesktop : squareSizeDesktop
  const [enemies, setEnemies] = useState<IEnemy[]>([])
  const [playerLeft, setPlayerLeft] = useState<number>((gridSize * squareSize) / 2 - squareSize / 2)
  const [playerTop, setPlayerTop] = useState<number>((gridSize * squareSize) / 2 - squareSize / 2)
  const [enemySpeed, setEnemySpeed] = useState<number>(0)
  const [ticker, setTicker] = useState<number>(0)
  const [gameOver, setGameOver] = useState<boolean>(false)
  const [increment, setIncrement] = useState<number>(0)

  const { t } = useTranslation()

  const chooseDirection = (directions: IDirection[]) => directions[Math.floor(Math.random() * directions.length)]
  const chooseSpool = () => Math.floor(Math.random() * 6)

  const generateEnemy = (playerLeft: number, playerTop: number, direction: IDirection) => {
    setIncrement(increment + 1)
    const newEnemy: IEnemy = {
      id: increment,
      top: 0,
      left: 0,
      direction,
      spool: chooseSpool(),
      active: true
    }
    const min = 0
    const max = gridSize * squareSize - squareSize

    switch (direction) {
      case IDirection.UP:
        newEnemy.top = max
        newEnemy.left = playerLeft
        break
      case IDirection.DOWN:
        newEnemy.top = min
        newEnemy.left = playerLeft
        break
      case IDirection.LEFT:
        newEnemy.top = playerTop
        newEnemy.left = max
        break
      case IDirection.RIGHT:
        newEnemy.top = playerTop
        newEnemy.left = min
        break
      default:
    }

    return newEnemy
  }

  const placeEnemy = () => {
    const direction = chooseDirection([IDirection.UP, IDirection.DOWN, IDirection.LEFT, IDirection.RIGHT])
    const newEnemy = generateEnemy(playerLeft, playerTop, direction)

    setEnemies((enemies) => [...enemies, newEnemy])
  }

  const enemyIsOut = (enemy: IEnemy) => {
    if (
      enemy.top < -squareSize ||
      enemy.top > gridSize * squareSize ||
      enemy.left < -squareSize ||
      enemy.left > gridSize * squareSize - squareSize
    )
      return true
  }

  const enemyMoveLeft = (direction: IDirection, left: number) => {
    switch (direction) {
      case IDirection.LEFT:
        left -= squareSize / 10 + enemySpeed
        break
      case IDirection.RIGHT:
        left += squareSize / 10 + enemySpeed
        break
    }

    return left
  }

  const enemyMoveTop = (direction: IDirection, top: number) => {
    switch (direction) {
      case IDirection.UP:
        top -= squareSize / 10 + enemySpeed
        break
      case IDirection.DOWN:
        top += squareSize / 10 + enemySpeed
        break
    }

    return top
  }

  const move = () => {
    const ret = enemies
      .filter((x) => x.active === true)
      .map((d) => ({
        id: d.id,
        left: enemyMoveLeft(d.direction, d.left),
        top: enemyMoveTop(d.direction, d.top),
        direction: d.direction,
        spool: d.spool,
        active: !enemyIsOut(d)
      }))
    setEnemies(ret)
  }

  const enemiesMoves = () => {
    enemies.forEach(() => {
      move()
    })

    if (ticker % 10 === 0) {
      placeEnemy()
    }

    if (ticker % 100 === 0) {
      setEnemySpeed((enemySpeed) => enemySpeed + 3)
    }

    setTicker((ticker) => ticker + 1)
    props.updateScore(ticker)
  }

  const onCollision = () => {
    setEnemySpeed(0)
    setTicker(0)
    setGameOver(true)
    props.setGameStarted(false)
  }

  const startGame = () => {
    onCollision()
    setEnemies([])
    setPlayerLeft((gridSize * squareSize) / 2 - squareSize / 2)
    setPlayerTop((gridSize * squareSize) / 2 - squareSize / 2)
    setGameOver(false)
    props.setGameStarted(true)
  }

  const moveLeft = () => {
    setPlayerLeft((left) => (left > 0 ? left - squareSize : left))
  }

  const moveRight = () => {
    setPlayerLeft((left) => (left < squareSize * gridSize - squareSize ? left + squareSize : left))
  }

  const moveUp = () => {
    setPlayerTop((top) => (top > 0 ? top - squareSize : top))
  }

  const moveDown = () => {
    setPlayerTop((top) => (top < squareSize * gridSize - squareSize ? top + squareSize : top))
  }

  useInterval(() => {
    if (props.gameStarted) {
      enemiesMoves()
    }
  }, 60)

  return (
    <GameBoard backgroundImage={isSmOrLarge ? sheetDesktop : sheetMobile}>
      <GameGrid backgroundImage={isSmOrLarge ? gridDesktop : gridMobile}>
        <Player
          size={squareSize}
          left={playerLeft}
          top={playerTop}
          moveLeft={moveLeft}
          moveUp={moveUp}
          moveRight={moveRight}
          moveDown={moveDown}
          gameStarted={props.gameStarted}
          gameOver={gameOver}
        />

        <Enemies
          playerLeft={playerLeft}
          playerTop={playerTop}
          onCollision={onCollision}
          enemies={enemies.filter((x) => x.active === true)}
        />

        {gameOver && <GameOver>{t('game.game-over')}</GameOver>}
        {!props.gameStarted && (
          <StartButton squareSize={squareSize} onClick={startGame}>
            {t('game.star-game')}
          </StartButton>
        )}
      </GameGrid>
    </GameBoard>
  )
}
