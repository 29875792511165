import { ErrorBoundary } from '@sentry/react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { IMeshBedLevelingPoint } from '../../../api/types/printer'
import { usePrinter } from '../../../context/printerContext'
import { useBreakpoint } from '../../../helpers/useBreakpoint'
import { usePrinterType } from '../../../hooks/usePrinterType'
import { ErrorFallback } from '../../common/ErrorFallback'
import { SectionTitle } from '../../common/Parameters'
import { DEFAULT_BED_HEIGHT_MAX_VALUE, DEFAULT_BED_WIDTH_MAX_VALUE, MeshBedLeveling } from '../MeshBedLeveling'

const THREE_DIMENSION_CHART_HEIGHT = 500
const MOBILE_THREE_DIMENSION_CHART_HEIGHT = 400

const MeshBedLevelingContainer = styled.div<{
  isMobile?: boolean
}>`
  height: ${({ isMobile }) =>
    isMobile ? `${MOBILE_THREE_DIMENSION_CHART_HEIGHT}px` : `${THREE_DIMENSION_CHART_HEIGHT}px`};
  width: 100%;
  margin-top: 1rem;
`

type Props = {
  mblData: IMeshBedLevelingPoint[]
}

export function JobMeshBedLeveling({ mblData }: Props) {
  const {
    printer: { printer_type }
  } = usePrinter()
  const { t } = useTranslation()
  const isSmOrLarge = useBreakpoint('sm')
  const parameters = usePrinterType(printer_type)?.parameters

  const mblBorderExtremes = {
    xMax: parameters?.position_x?.max || DEFAULT_BED_WIDTH_MAX_VALUE,
    yMax: parameters?.position_y?.max || DEFAULT_BED_HEIGHT_MAX_VALUE
  }

  return (
    <>
      <SectionTitle>{t('printer.mesh-bed-leveling-alt')}</SectionTitle>
      <MeshBedLevelingContainer isMobile={!isSmOrLarge}>
        <ErrorBoundary fallback={<ErrorFallback />}>
          <MeshBedLeveling data={mblData} borderExtrems={mblBorderExtremes} />
        </ErrorBoundary>
      </MeshBedLevelingContainer>
    </>
  )
}
