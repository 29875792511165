import { Container } from 'react-bootstrap'
import styled, { css } from 'styled-components'

export const textEllipsis = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const noWrap = css`
  white-space: nowrap;
`

export const disabledFilter = (disabled = true) => {
  if (disabled) {
    return css`
      filter: grayscale(1) opacity(0.4);
    `
  }
  return ''
}

export const PageContainer = styled(Container)`
  padding-top: 1rem;
`
