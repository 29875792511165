import styled, { css } from 'styled-components'

const StyledRow = styled.div<{ noGutters?: boolean }>`
  display: flex;
  flex-wrap: wrap;

  ${(props) =>
    props.noGutters
      ? css`
          margin-left: 0;
          margin-right: 0;
        `
      : css`
          margin-left: -15px;
          margin-right: -15px;
        `}
`

export const Row = StyledRow
