import { useTranslation } from 'react-i18next'

import { usePrinter } from '../../../context/printerContext'
import { useCommandMutation } from '../../../hooks/commands/useCommandMutation'
import { WithConfirm } from '../../common/ConfirmModal'
import { LoadingButtonOutlined } from '../../common/LoadingButtonOutlined'
import { SvgIcon } from '../../common/SvgIcon'

function useCommandParams(command: string) {
  const { t } = useTranslation()
  return {
    command,
    successMessage: {
      title: t('printer.control.reset-printer'),
      body: t('printer.control.reset-printer.description')
    }
  }
}

export function ResetPrinterButton() {
  const { printer } = usePrinter()
  const { t } = useTranslation()

  const resetLegacyPrinter = useCommandMutation(printer, useCommandParams('RESET_PRINTER'))
  const resetBuddyPrinter = useCommandMutation(printer, useCommandParams('RESET'))

  const availableCommand = [resetBuddyPrinter, resetLegacyPrinter].find((command) => command.isAvailable)

  const resetButton = (command: ReturnType<typeof useCommandMutation>, confirm?: Function) => {
    const onClickResetFunc = confirm ? confirm(() => command.execute({})) : () => command.execute({})
    return (
      <LoadingButtonOutlined
        onClick={onClickResetFunc}
        disabled={command.isLoading || !command.isAvailable}
        isLoading={command.isLoading}
      >
        <SvgIcon icon="resetPrinterIcon" size="20" />
        {t('printer.control.reset-printer.btn')}
      </LoadingButtonOutlined>
    )
  }

  return (
    <WithConfirm
      title={t('printer.control.confirm-title.reset-printer')}
      body={t('printer.control.confirm-body.reset-printer')}
    >
      {(confirm) => availableCommand && resetButton(availableCommand, confirm)}
    </WithConfirm>
  )
}
