import { createContext, ReactNode, useContext } from 'react'

import { ApiClient } from './createClient'

export const ApiClientContext = createContext<ApiClient | null>(null)

type Props = {
  apiClient: ApiClient
  children: ReactNode
}

export function ApiContextProvider({ apiClient, children }: Props) {
  return <ApiClientContext.Provider value={apiClient}>{children}</ApiClientContext.Provider>
}

export function useApiClient() {
  const apiClient = useContext(ApiClientContext)
  if (!apiClient) {
    throw new Error(
      'API Client is missing in React context, you probably forgot to wrap your component with <ApiClientContext.Provider>'
    )
  }
  return apiClient
}
