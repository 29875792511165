import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import { useAuthState } from '../../context/authStore'
import { getEnvironment, Globals } from '../../helpers/getEnvironment'
import { useGroupFilterData } from '../../hooks/useGroupFilterData'
import { useIsAdmin } from '../../hooks/useLoggedUser'
import { useTeams } from '../../hooks/useTeams'
import { Dropdown, DropdownItemLink } from '../common/Dropdown'
import { BetaLabel } from '../common/Label'
import { SvgIcon } from '../common/SvgIcon'

type Props = {
  size?: number
}

export function ThreeDots(props: Props) {
  const theme = useTheme()
  const [fill, setFill] = useState(theme.colors.secondary)

  return (
    <SvgIcon
      icon="dotsIcon"
      size={props.size}
      fill={fill}
      onMouseEnter={() => setFill(theme.colors.primary)}
      onMouseLeave={() => setFill(theme.colors.secondary)}
    />
  )
}

export function DotsDropdown() {
  const { t } = useTranslation()
  const authState = useAuthState()
  const isAdmin = useIsAdmin()
  const { groupList } = useGroupFilterData()
  const teams = useTeams()
  const isDeveloperOrAdmin = getEnvironment(Globals.ENVIRONMENT) === 'dev' || isAdmin

  if (!authState.isAuthenticated) {
    return null
  }

  if (teams.length > 1 || groupList.length > 0 || isDeveloperOrAdmin) {
    return (
      <Dropdown trigger={<ThreeDots />}>
        {isAdmin && <DropdownItemLink to="/users">{t('menu.users')}</DropdownItemLink>}
        {(teams.length > 1 || isAdmin) && (
          <DropdownItemLink to="/teams">
            {t('menu.teams')}
            <BetaLabel />
          </DropdownItemLink>
        )}
        {/* Filaments are hidden for now but the link should work */}
        {/* <DropdownItemLink to="/filaments">{t('menu.filaments')}</DropdownItemLink> */}
        {(groupList.length > 0 || isAdmin) && (
          <DropdownItemLink to="/groups">
            {t('menu.groups')}
            <BetaLabel />
          </DropdownItemLink>
        )}
        {isDeveloperOrAdmin && <DropdownItemLink to="/commands/g-codes">{t('menu.commands')}</DropdownItemLink>}
      </Dropdown>
    )
  }

  return null
}
