import { useApiClient } from '@prusaconnect/api/client/apiClientContext'
import type { components, paths } from '@prusaconnect/api/schema/schema'
import { useMutation } from '@tanstack/react-query'

import useServiceResponseHandler from '../../services/useServiceResponseHandler'

type UpdateData = components['schemas']['user_update']
type ProfileResponse = paths['/app/login']['patch']['responses']['200']['content']['application/json']

export function useUpdateProfile(onSuccess?: (response: ProfileResponse) => void) {
  const api = useApiClient()
  const { displayError } = useServiceResponseHandler()

  const {
    mutate: update,
    isSuccess,
    isLoading
  } = useMutation({
    mutationFn: (profileData: UpdateData) => api((client) => client.PATCH('/app/login', { body: profileData })),
    onSuccess,
    onError: (error: Error) => displayError(error)
  })

  return {
    update,
    isSuccess,
    isLoading
  }
}
