import { find } from 'lodash-es'
import { memo, useEffect, useMemo, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useIntersectionObserver } from 'react-intersection-observer-hook'
import { Route, Routes, useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { useLocalStorage } from 'react-use'

import { Material } from '../../../api/types/filament'
import { ICloudFile, IFileType } from '../../../api/types/file'
import { useAuthState } from '../../../context/authStore'
import { useCanControl } from '../../../context/permissionsStore'
import { RefetchContextProvider } from '../../../context/refetchContext'
import { isDefined } from '../../../helpers/std'
import { usePressedShift } from '../../../hooks/usePressedShift'
import { useQueryParams } from '../../../hooks/useQueryParams'
import { useTeamName } from '../../../hooks/useTeamName'
import { EmptyList } from '../../common/EmptyList'
import { PaceProgress } from '../../common/PaceProgress'
import { DocumentTitle } from '../../header/DocumentTitle'
import { CloudTransfersList } from './CloudTransfersList'
import { DropArea } from './DropArea'
import FileDetailPage from './FileDetailPage'
import { FirmwareFileRow } from './FileManager/FirmwareFileRow'
import { OtherFileRow } from './FileManager/OtherFileRow'
import { PrintFileRow } from './FileManager/PrintFileRow'
import { Fieldset, JobList } from './FileManager/styled'
import { TeamFolderRow } from './FileManager/TeamFolderRow'
import { useAllCloudFiles } from './FileManager/useCloudFiles'
import { initialFilters, StorageFilters } from './StorageFilters'
import { UsedStorageInfo } from './UsedStorageInfo'
import { filterFiles, getAvailableFilters, getSelectedFiles, purgeFilters, sortFiles } from './utils'

export const getTeamUploadsId = (teamId: number) => `all-cloud-files-${teamId}`

const PAGE_SIZE = 20

export type IFilesFilter = {
  materialFilter: Material[]
  printerTypeFilter?: string[]
  layerFilter: number
  nozzleFilter: number
  fileTypeFilter: IFileType
  storages?: string[]
}

type TeamFilesProps = {
  root: string
}

type IConnectFilters = {
  [teamId: number]: {
    filters: IFilesFilter
    fulltext: string
    sort: string
  }
}

function TeamFiles(props: TeamFilesProps) {
  const { shiftPressed } = usePressedShift()
  const [searchParams] = useSearchParams()
  const urlDefaultTeamId = searchParams.get('team')
  const { user } = useAuthState()
  const { getTeamName } = useTeamName()
  const altTeamId = Number(urlDefaultTeamId)
  const canControl = useCanControl(altTeamId)
  const { t } = useTranslation()
  const { data, isLoading, refetch, isFetched } = useAllCloudFiles(altTeamId)

  const [currentPage, setCurrentPage] = useState(1)
  const startIndex = (currentPage - 1) * PAGE_SIZE
  const endIndex = startIndex + PAGE_SIZE

  const hasNextPage = endIndex < (data?.files || []).length
  const files = data?.files || []
  const [intersectionObserverRef, { entry }] = useIntersectionObserver()
  const hasReachedBottom = entry && entry.isIntersecting

  useEffect(() => {
    if (hasReachedBottom && hasNextPage) {
      setCurrentPage((prevPage) => prevPage + 1)
    }
  }, [hasNextPage, hasReachedBottom])

  const availableFilters = getAvailableFilters(files)

  const queryParams = useQueryParams()
  const teamName = getTeamName(user.teams?.find((team) => team.id === Number(queryParams.team)))
  const path = queryParams.team ? `${props.root}/${teamName}` : props.root

  const [selectedFiles, setSelectedFiles] = useState<ICloudFile[]>([])
  const [connectFilters, setConnectFilters] = useLocalStorage<IConnectFilters>('preferences.storage.filters', {
    [altTeamId]: { filters: initialFilters, fulltext: '', sort: 'latest' }
  })
  const localStorageFilters = connectFilters || {}
  const { fulltext = '', filters: allFilters = initialFilters, sort = 'latest' } = localStorageFilters[altTeamId] || {}
  const allFiltersString = JSON.stringify(allFilters)
  const availableFiltersString = JSON.stringify(availableFilters)
  const filters = useMemo(() => {
    return purgeFilters(allFilters, availableFilters)
  }, [allFiltersString, availableFiltersString])

  const setSort = (newSort: string) => {
    setConnectFilters({
      ...localStorageFilters,
      [altTeamId]: { ...localStorageFilters[altTeamId], sort: newSort }
    })
  }

  const setFilters = (newFilters: IFilesFilter) => {
    setConnectFilters({
      ...localStorageFilters,
      [altTeamId]: { ...localStorageFilters[altTeamId], filters: newFilters }
    })
  }

  const resetFilters = () => {
    setConnectFilters({
      ...localStorageFilters,
      [altTeamId]: { ...localStorageFilters[altTeamId], filters: initialFilters, fulltext: '' }
    })
  }

  const setFulltext = (newFulltext: string) => {
    setConnectFilters({
      ...localStorageFilters,
      [altTeamId]: { ...localStorageFilters[altTeamId], fulltext: newFulltext }
    })
  }

  useEffect(() => {
    setFilters({ ...filters })
  }, [isFetched])

  useEffect(() => {
    setSelectedFiles([])
    setCurrentPage(1)
  }, [filters, fulltext, sort])

  const handleFilterChange = (
    isChecked: boolean,
    key: 'materialFilter' | 'printerTypeFilter' | 'storages',
    value: Material | string
  ): void => {
    if (isChecked) {
      setFilters({ ...filters, [key]: [...(filters[key] || []), value] })
    } else {
      setFilters({ ...filters, [key]: (filters[key] || []).filter((item) => item !== value) })
    }
  }

  let filteredFiles = [...files]
  if (fulltext) {
    filteredFiles = filteredFiles.filter((f) => f.display_name.toLowerCase().indexOf(fulltext.toLowerCase()) > -1)
  }
  filteredFiles = filterFiles(filteredFiles, filters) as ICloudFile[]
  filteredFiles = sortFiles(filteredFiles, sort) as ICloudFile[]

  // Pagination
  const pagedFiles = filteredFiles.slice(0, endIndex)

  const handleSelect = (checked: boolean, file: ICloudFile, index: number) => {
    const checkedFiles = getSelectedFiles(pagedFiles, selectedFiles, shiftPressed, checked, file, index) || []
    setSelectedFiles(checkedFiles)
  }

  const renderRow = (file: ICloudFile, index: number) => {
    switch (file.type) {
      case IFileType.FIRMWARE:
        return (
          <FirmwareFileRow
            key={file.hash}
            file={file}
            refetch={refetch}
            isSelectable
            selected={!!find(selectedFiles, ['hash', file.hash])}
            setSelected={(checked: boolean) => handleSelect(checked, file, index)}
          />
        )

      case IFileType.PRINT_FILE:
        return (
          <PrintFileRow
            key={file.hash}
            file={file}
            refetch={refetch}
            printerTeamId={altTeamId}
            isSelectable
            selected={!!find(selectedFiles, ['hash', file.hash])}
            setSelected={(checked: boolean) => handleSelect(checked, file, index)}
          />
        )

      case IFileType.FILE:
        return (
          <OtherFileRow
            key={file.hash}
            file={file}
            refetch={refetch} // TODO isSelectable
            style={{ marginLeft: '3rem' }}
          />
        )

      default:
        return null
    }
  }

  const modeProps = {
    path,
    downloadToPrinter: false,
    free: data ? data.capacity - data.total : 0,
    uniqueContextId: getTeamUploadsId(altTeamId) // whatever string unique for each team, just to persist context of uploading to global cloud storage
  }

  const capacity = data?.capacity || 0
  const total = data?.total || 0

  return (
    <RefetchContextProvider refetch={refetch}>
      <Fieldset role="button" aria-labelledby="connect-dropzone">
        <legend id="connect-dropzone">{t('transfer-file-to-connect')}</legend>
        <div className="mb-3">
          <DropArea
            {...modeProps}
            teamId={altTeamId}
            isFromConnect
            disabled={!canControl}
            title={
              !canControl
                ? t('connect.file.upload.not-in-writable-team', 'You do not have sufficient rights to the team.')
                : ''
            }
          />
        </div>

        <CloudTransfersList teamId={altTeamId} />
      </Fieldset>
      <StorageFilters
        files={filteredFiles}
        teamId={altTeamId}
        filters={{ filters, setFilters }}
        resetFilters={resetFilters}
        selectedFiles={{ selectedFiles, setSelectedFiles }}
        sort={{ sort, setSort }}
        onFilterChange={handleFilterChange}
        fulltext={{ fulltext, setFulltext }}
        availableFilters={availableFilters}
        refetch={refetch}
        usedStorage={
          isDefined(data?.capacity) &&
          isDefined(data?.total) && <UsedStorageInfo free={capacity - total} total={capacity} expand />
        }
      />
      <JobList $disabledSelection={shiftPressed}>
        {isLoading && <PaceProgress />}
        {!isLoading && files.length === 0 && <EmptyList title={t('printer.file.storages.no-files')} />}
        {!isLoading && files.length !== 0 && pagedFiles.length === 0 && (
          <EmptyList title={t('printer.file.storages.no-files-found')} />
        )}
        {pagedFiles.length > 0 && pagedFiles.map(renderRow)}
      </JobList>
      <div ref={intersectionObserverRef} />
    </RefetchContextProvider>
  )
}

type Props = {
  root: string
}

export const AllCloudFiles = memo((props: Props) => {
  const { user } = useAuthState()
  const [searchParams] = useSearchParams()
  const urlTeamId = searchParams.get('team')
  const { getTeamName } = useTeamName()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const mapDropdownItems = () =>
    user.teams?.map((team) => ({
      id: team.id,
      name: getTeamName(team),
      read_only: team.rights_ro && !team.rights_rw && !team.rights_use,
      path: team.name,
      display_name: getTeamName(team),
      type: 'FOLDER' as IFileType.FOLDER,
      file_count: 0 // TODO
    }))

  useEffect(() => {
    if (!urlTeamId && user.teams && user.teams.length <= 1) {
      navigate(`?team=${user.default_team_id}`, { replace: true })
    }
  }, [urlTeamId, user.teams])

  return (
    <>
      <DocumentTitle content={t('menu.connect-files')} />
      <Routes>
        <Route path="teams/:teamId/:hash" element={<FileDetailPage isGlobalConnectStorage />} />
        <Route
          path="*"
          element={
            <Container className="my-3">
              {!urlTeamId && user.teams && user.teams.length > 1 ? (
                <JobList>
                  {mapDropdownItems()?.map((folder, i) => (
                    <TeamFolderRow key={i} folder={folder} />
                  ))}
                </JobList>
              ) : (
                <TeamFiles root={props.root} />
              )}
            </Container>
          }
        />
      </Routes>
    </>
  )
})
