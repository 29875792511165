import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { usePrinter } from '../../../context/printerContext'
import { useBreakpoint } from '../../../helpers/useBreakpoint'
import { Axis } from '../../common/Axis'
import * as S from './ControlPanel.styled'
import { MoveDropdown } from './MoveDropdown'

type Props = {
  isDisabled: boolean
  onChange: (value: number) => void
}

export const Label = styled.div`
  white-space: nowrap;
`

export function ControlZ({ isDisabled, onChange }: Props) {
  const isXsOrLarger = useBreakpoint('xs')
  const {
    printer: { axis_z }
  } = usePrinter()
  const { t } = useTranslation()

  return (
    <div>
      <S.Param>
        <div>{isXsOrLarger ? t('printer.control.coordinates.axis', { axis: 'Z' }) : 'Z'}</div>
        <Label>
          <Axis value={axis_z} />
        </Label>
      </S.Param>
      <MoveDropdown
        isDisabled={isDisabled}
        axis="z"
        placeholder={<Label>{isXsOrLarger ? t('printer.control.move.axis-control-label', { axis: 'Z' }) : 'Z'}</Label>}
        onChange={(value) => {
          if (!value.z) {
            return
          }
          onChange(value.z)
        }}
      />
    </div>
  )
}
