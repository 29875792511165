import Markdown from 'markdown-to-jsx'
import { useTranslation } from 'react-i18next'

import { SvgIcon } from '../components/common/SvgIcon'

const ICON_SIZE = 64

export function InvitationAcceptedPage(props: { username: string; team: string }) {
  const { t } = useTranslation()

  return (
    <div className="text-center mt-5">
      <h1 className="h4 font-weight-light">{t('team.invitation.heading')}</h1>
      <SvgIcon icon="okIcon" size={ICON_SIZE} className="mt-4" />
      <h3 className="font-weight-normal lead text-lg mx-2 mt-4">
        <Markdown>{t('team.invitation.accepted', { user: props.username, team: props.team })}</Markdown>
      </h3>
    </div>
  )
}
