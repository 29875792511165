import { useTranslation } from 'react-i18next'

import { formatNumber, THINSP } from '../../helpers/formatters'

export function Density({ value }: { value: number }) {
  const { i18n } = useTranslation()

  if (value === undefined) {
    return null
  }

  const formatted = formatNumber(value / 1000, i18n.language, { fractionDigits: 2 })

  return (
    <>
      `${formatted}${THINSP}g/cm³`
    </>
  ) // TODO inches
}
