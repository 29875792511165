import { createContext, ReactNode, useContext, useEffect } from 'react'
import { Outlet } from 'react-router-dom'

import { IPrinter, IPrinterSimpleView } from './api/types/printer'
import { FrontendVersionWatchdog } from './components/helpers/FrontendVersionWatchdog'
import { TwoFactorAuthModal } from './components/login/TwoFactorAuthModal'
import { StoppedPrintReasonsModal } from './components/printers/actions/StoppedPrintReasonsModal'
import { TermsAndConditionsModal } from './components/TermsAndConditionsModal'
import { AuthStateContextProvider, useAuthState } from './context/authStore'
import { DownloadContextProvider } from './context/downloadContext'
import { PrintersStateContextProvider } from './context/printersStateContext'
import { ThemeModeProvider } from './context/ThemeModeContext'
import { UploadContextProvider } from './context/uploadContext'
import { useDownloadingFirmware, useFlashFirmware, useUploadedFirmwareToFlash } from './hooks/useFlashFirmware'
import { useLoginPing } from './services/useLoginPing'

export const UserResolverContext = createContext<{ refetchUserData: () => void }>({
  refetchUserData: () => undefined
})

export const useUserResolver = () => useContext(UserResolverContext)

function UserResolver({ children }: { children: ReactNode }) {
  /**
   * Enables login ping & pauses rendering until the very first request is resolved
   */
  const { refetch } = useLoginPing()
  const { loginResolved } = useAuthState()

  if (!loginResolved) {
    return null
  }

  return <UserResolverContext.Provider value={{ refetchUserData: refetch }}>{children}</UserResolverContext.Provider>
}

function FirmwareUploader({
  printer,
  hash,
  teamId, // user default team
  stopDispatching
}: {
  printer: IPrinterSimpleView
  hash: string
  teamId: number
  stopDispatching: () => void
}) {
  const installFirmwareToPrinter = useFlashFirmware(printer as IPrinter)

  const { data: getParticularFirmwareByVersion } = useDownloadingFirmware(printer.uuid, hash, Number(teamId))

  useEffect(() => {
    if (getParticularFirmwareByVersion?.path && installFirmwareToPrinter?.isAvailable) {
      installFirmwareToPrinter.execute(
        { path: getParticularFirmwareByVersion.path },
        {
          onSuccess: () => {
            stopDispatching()
          }
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getParticularFirmwareByVersion?.path])

  return null
}

export function Contexts() {
  const { printer, hash, teamId, stopDispatching } = useUploadedFirmwareToFlash()

  return (
    <AuthStateContextProvider>
      <UserResolver>
        <ThemeModeProvider>
          <>
            <FrontendVersionWatchdog />
            <TermsAndConditionsModal />
            <PrintersStateContextProvider>
              <UploadContextProvider>
                <DownloadContextProvider>
                  <Outlet />
                </DownloadContextProvider>
              </UploadContextProvider>
            </PrintersStateContextProvider>
            <TwoFactorAuthModal />
            <StoppedPrintReasonsModal />
            {printer && hash && teamId && (
              <FirmwareUploader printer={printer} hash={hash} teamId={teamId} stopDispatching={stopDispatching} />
            )}
          </>
        </ThemeModeProvider>
      </UserResolver>
      <div id="modals" />
    </AuthStateContextProvider>
  )
}
