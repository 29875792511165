import styled, { css } from 'styled-components'

import ScrollContainer from '../../../common/ScrollContainer'
import { textEllipsis } from '../../../helpers/styled'
import { JobList as List, Params as JobLayoutParams, RowLink as JobLayoutRowLink } from '../../overview/JobLayout'
import { Checkbox as Check } from './SelectingCheckbox'

const ROW_HEIGHT = '6.5rem'

export const ItemName = styled.div`
  ${textEllipsis};
  flex: 1;
  font-size: 1.125rem;
  font-weight: bold;
  color: var(--color-text);
  font-weight: 700;
`

export const Tags = styled.div`
  height: 25px;
`

export const Tag = styled.span`
  font-size: 0.875rem;
  padding: 2px 5px;
  background-color: var(--background-primary);
  border-radius: 2px;
  margin-right: 0.5rem;
  white-space: nowrap;
`

export const Fieldset = styled.fieldset`
  display: block;
  flex: none;
  margin-left: 2px;
  margin-right: 2px;
  padding: 0.35em 0.75em 0.625em 0.75em;
  border: 1px groove var(--border-secondary);
  cursor: default;

  legend {
    width: unset;
    font-size: 1rem;
    white-space: nowrap;
    margin-bottom: 0.2rem;
  }

  .form-control {
    height: calc(1.5em + 0.5rem);
  }

  ${Check} + ${Check} {
    margin-left: 0.5rem;
  }
`

export const Content = styled.div`
  grid-area: content;
  flex: 1;
  min-width: 0;
  margin-top: 5px;
  padding-left: 1rem;
`

export const Checkbox = styled.div`
  position: absolute;
  top: 50%;
  left: 1rem;
  transform: translate(0%, -50%);
  font-size: 0;
  input {
    height: 24px;
    width: 24px;
  }
`

const gridStyles = css`
  display: grid;
  grid-template-areas: 'image content';
  grid-template-rows: ${ROW_HEIGHT} 1fr;
  grid-template-columns: ${ROW_HEIGHT} auto;
  gap: 0;
`

export const RowLink = styled(JobLayoutRowLink)`
  ${gridStyles}
  margin-bottom: 0.5rem;
  border-bottom: none;
  max-height: 100px;
`

export const JobList = styled(List)`
  > div {
    border-bottom: 1px solid var(--border-secondary);
  }
`

export const FolderContent = styled.div`
  flex: 1;
  min-width: 0;
`

export const Row = styled.div`
  color: var(--color-text);
  padding-right: 1rem;
  &:hover {
    background: var(--job-background-hover);
    color: var(--color-text);
  }
  ${gridStyles}
`

export const ImageContainer = styled.div`
  position: relative;
  grid-area: image;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 100px;
  min-height: 60px;
`

export const FolderDescription = styled.div`
  font-size: 0.9em;
  color: #797979;
  font-style: italic;
  display: flex;
  gap: 1rem;
`

export const ParentContent = styled.div`
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const ParentName = styled.div`
  font-size: 1.125rem;
  font-weight: 700;
`

export const GoToParent = styled.div`
  font-weight: 400;
  font-size: 0.6875rem;
  color: #797979;
  line-height: 24px;
`

export const Timestamp = styled.div`
  align-self: flex-end;
  font-size: 0.875rem;
`

export const ScrollableContainer = styled(ScrollContainer)`
  margin: 0 -0.7rem;
  padding: 0 0.7rem;
`

export const Params = styled(JobLayoutParams)`
  margin-top: 0.3rem;
`
