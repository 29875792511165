import { useMutation } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import styled from 'styled-components'

import { useApiClient } from '../../api/react'
import { GcodeArgs, MAX_TEMPLATE_HEIGHT, MultiSelectOption } from '../../api/types/gcode'
import { useLoggedUser } from '../../hooks/useLoggedUser'
import { usePrinterTypes } from '../../hooks/usePrinterType'
import { useTeamName } from '../../hooks/useTeamName'
import { useWritableTeams } from '../../hooks/useTeams'
import { Button } from '../common/Button'
import { SvgIcon } from '../common/SvgIcon'
import { Tag } from '../common/Tag'
import { MutationStatus } from '../mutationStatus'
import { useStyles } from '../printers/filters/styles'
import { AddGcodeParamsModal } from './AddGcodeParamsModal'
import { Placeholder, RowsLabel } from './Commands.styled'

const TagsContainer = styled.div`
  margin-top: 4px;
`

type Props = {
  refetch: () => void
}

export function AddGcodeTemplateForm(props: Props) {
  const user = useLoggedUser()
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [template, setTemplate] = useState('')
  const [templateArgs, setTemplateArgs] = useState<GcodeArgs[]>([])
  const [printerTypes, setPrinterTypes] = useState<MultiSelectOption[]>([])
  const [teamId, setTeamId] = useState(user.default_team_id)
  const { t } = useTranslation()
  const availablePrinterTypes = usePrinterTypes()
  const { writableTeams } = useWritableTeams()
  const { getTeamName } = useTeamName()

  const [templateHeight, setTemplateHeight] = useState(1)
  const [showParamsModal, setShowParamsModal] = useState(false)

  const api = useApiClient()
  const mutation = useMutation(
    () =>
      api.app.gCodes.createGCodeTemplate({
        description,
        template,
        args: templateArgs,
        team_id: teamId,
        printer_types: printerTypes.map((type) => type.value),
        name
      }),
    {
      onSuccess: () => {
        clearForm()
        props.refetch()
      }
    }
  )
  const { mutate: addGcodeTemplate } = mutation

  // Default user is returned before we get user's data from API :/
  // TODO refactor auth logic
  useEffect(() => {
    setTeamId(user.default_team_id)
  }, [user.default_team_id])

  const clearForm = () => {
    setName('')
    setTemplate('')
    setTemplateArgs([])
    setPrinterTypes([])
    setDescription('')
    setTeamId(user.default_team_id)
  }

  const templateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rows = (e.currentTarget.value.match(/\n/g) || []).length + 1

    if (rows > MAX_TEMPLATE_HEIGHT) {
      const updatedTemplate = e.currentTarget.value.split(/\n/g).slice(0, MAX_TEMPLATE_HEIGHT).join('\n')
      setTemplate(updatedTemplate)
      setTemplateHeight(MAX_TEMPLATE_HEIGHT)
    } else {
      setTemplate(e.currentTarget.value)
      setTemplateHeight(rows)
    }
  }

  const updateTemplate = (params: GcodeArgs[]) => {
    setTemplateArgs(params)
  }

  const removeArgument = (i: number) => {
    const newArgs = [...templateArgs]
    newArgs.splice(i, 1)
    setTemplateArgs(newArgs)
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    addGcodeTemplate()
  }

  return (
    <Row>
      <Col>
        <Form onSubmit={handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} md="3" controlId="gcodeName">
              <Form.Label>{t('gcode.name-label')}</Form.Label>
              <Form.Control
                value={name}
                onChange={(e) => setName(e.currentTarget.value)}
                placeholder={t('gcode.name-placeholder')}
                type="text"
                required
              />
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="gcodeTemplate">
              <Form.Label>{t('gcode.template-label')}</Form.Label>
              <Form.Control as="textarea" rows={templateHeight} value={template} onChange={templateChange} required />
              {template && (
                <RowsLabel rows={templateHeight} maxRows={MAX_TEMPLATE_HEIGHT}>
                  {t('gcode.template-rows-label', { rows: templateHeight, maxRows: MAX_TEMPLATE_HEIGHT })}
                </RowsLabel>
              )}
            </Form.Group>
            <Form.Group as={Col} md="2" controlId="gcodeArgs">
              <Form.Label>{t('gcode.arguments-label')}</Form.Label>
              <Button onClick={() => setShowParamsModal(true)} block className="text-wrap">
                <SvgIcon icon="plusIcon" size="18" />
                {t('gcode.add-arguments')}
              </Button>
            </Form.Group>
            <Form.Group as={Col} md="3">
              <Form.Label>{t('gcode.added-label')}</Form.Label>
              <TagsContainer>
                {templateArgs.length === 0 && <Placeholder>{t('gcode.no-arguments')}</Placeholder>}
                {templateArgs
                  .filter((arg) => arg.name)
                  .map((arg, i) => (
                    <Tag key={i} title={`${arg.name || ''} (${arg.type})`} onClose={() => removeArgument(i)} />
                  ))}
              </TagsContainer>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="3" controlId="gcodePrinterTypes" required>
              <Form.Label>{t('gcode.printer-type-label')}</Form.Label>
              <Select
                value={printerTypes}
                options={availablePrinterTypes.map((type) => ({ label: type.name, value: type.id }))}
                onChange={(options) => {
                  setPrinterTypes([...options])
                }}
                placeholder={t('gcode.printer-type-select')}
                isMulti
                isClearable
                styles={useStyles<MultiSelectOption, true>()}
                closeMenuOnSelect={false}
                hideSelectedOptions
              />
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="gcodeDescription">
              <Form.Label>{t('gcode.description-label')}</Form.Label>
              <Form.Control
                value={description}
                onChange={(e) => setDescription(e.currentTarget.value)}
                placeholder={t('gcode.description-placeholder')}
                type="text"
                required
              />
            </Form.Group>
            <Form.Group as={Col} md="2">
              <Form.Label>{t('gcode.team-label')}</Form.Label>
              <Form.Control
                as="select"
                value={teamId}
                onChange={(e) => setTeamId(Number(e.currentTarget.value))}
                style={{ fontStyle: printerTypes.length !== 0 ? 'normal' : 'italic' }}
                required
              >
                {writableTeams.map((team) => (
                  <option key={team.id} value={team.id} className="non-italic">
                    {getTeamName(team)}
                    {team.id === user.default_team_id && ' (default)'}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} md="3">
              <Form.Label>&nbsp;</Form.Label>
              <Button type="submit" block>
                <SvgIcon icon="plusIcon" size="18" />
                {t('gcode.add-template')}
              </Button>
            </Form.Group>
          </Form.Row>

          <MutationStatus mutation={mutation} />
        </Form>
      </Col>
      {showParamsModal && (
        <AddGcodeParamsModal
          onCancel={() => setShowParamsModal(false)}
          onSuccess={(params) => {
            setShowParamsModal(false)
            updateTemplate(params)
          }}
          templateArgs={templateArgs}
        />
      )}
    </Row>
  )
}
