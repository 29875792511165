import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import joe from '../../img/disconnecting-joe.svg'

const OfflineScreenStyled = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .offline-info {
    text-align: center;
    padding: 1rem;
  }
`

const JoeImg = styled.img`
  width: 100%;
  height: 350px;
  filter: grayscale() opacity(0.5);
  margin-bottom: 2rem;
`

export function OfflineScreen() {
  const { t } = useTranslation()

  return (
    <OfflineScreenStyled>
      <div className="offline-info">
        <JoeImg src={joe} />
        <h2>{t('error.printer-offline.title')}</h2>
        <p>{t('error.printer-offline.description')}</p>
      </div>
    </OfflineScreenStyled>
  )
}
