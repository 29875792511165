import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { isNumber } from '../../helpers/std'
import { FileHashPreview } from './FileHashPreview'

type Size = number | `${number}%` | 'auto'

type Props = {
  previewUrl: string
  alt?: string
  maxWidth?: string
  size?: Size
}

const ImageElement = styled.img<{ maxWidth?: string }>`
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '100%')};
  height: 100%;
  object-fit: cover;
`

const Placeholder = styled.div<{ $size: Size }>`
  height: ${(props) => (isNumber(props.$size) ? `${props.$size}px` : `${props.$size}`)};
  width: ${(props) => (isNumber(props.$size) ? `${props.$size}px` : `${props.$size}`)};
`

/**
 * Renders file preview from URL, fallbacks to hash preview if image is not found
 */
export function FilePreview(props: Props) {
  const { previewUrl, alt, size } = props
  const [useFallback, setUseFallback] = useState(false)

  useEffect(() => {
    if (previewUrl) {
      setUseFallback(false)
      const img = new Image()
      img.src = previewUrl
      img.onerror = () => setUseFallback(true)
    }
  }, [previewUrl])

  if (size) {
    return (
      <Placeholder $size={size}>
        <ImageElement src={props.previewUrl} alt={alt} maxWidth={props.maxWidth} />
      </Placeholder>
    )
  }

  if (useFallback) {
    return <FileHashPreview hash={previewUrl} />
  }

  return <ImageElement src={props.previewUrl} alt={alt} maxWidth={props.maxWidth} />
}
