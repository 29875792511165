import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { IFileDetailResponse, IPrintFile } from '../../../api/types/file'
import { Button } from '../../common/Button'
import { LoadingButtonOutlined } from '../../common/LoadingButtonOutlined'
import { SvgIcon } from '../../common/SvgIcon'
import { PrintFile } from '../storage/FileManager/PrintFile'

type ModalProps = {
  file: IFileDetailResponse
  onClose: () => void
  onConfirm: () => void
}

export function DeleteFileWithPreviewModal(props: ModalProps) {
  const { file, onClose, onConfirm } = props
  const { t } = useTranslation()

  return (
    <Modal show onHide={onClose} centered size="xl">
      <Modal.Header closeButton>
        <Modal.Title>{t('button.delete.file', 'Delete file')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PrintFile file={file as IPrintFile} />
      </Modal.Body>
      <Modal.Footer>
        <LoadingButtonOutlined
          onClick={() => {
            onConfirm()
            onClose()
          }}
        >
          <SvgIcon icon="okIcon" size={18} />
          {t('button.delete.file', 'Delete file')}
        </LoadingButtonOutlined>

        <Button type="reset" onClick={onClose}>
          {t('button.cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
