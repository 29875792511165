import Color from 'color'

function getTextColor(baseColor: string) {
  const colorObj = Color(baseColor)
  const blackContrast = colorObj.contrast(Color('black'))
  const whiteContrast = colorObj.contrast(Color('white'))

  return blackContrast > whiteContrast ? '#000000' : '#ffffff'
}

type ColorPair = {
  background: string
  text: string
}

export type ColorScheme = {
  base: ColorPair
  border: ColorPair
  hover: ColorPair
  background: ColorPair
  backgroundSecondary: ColorPair
  progress: ColorPair
}

const lightnessModifiers = {
  base: [0, 0],
  border: [0, 0],
  hover: [0.5, 0.15],
  background: [0.8, 0.4],
  backgroundSecondary: [0.6, 0.25],
  progress: [0.7, 0.35]
}

export function getColorScheme(baseColor: string, inverse = false): ColorScheme {
  const color = Color(baseColor)

  const hexColorVariants = Object.entries(lightnessModifiers).reduce((acc, key) => {
    const [type, [darken, lighten]] = key
    const regularColor = color.lighten(lighten).desaturate(0.25).hex()
    const inverseColor = color.darken(darken).desaturate(0.75).hex()
    return {
      ...acc,
      [type]: inverse
        ? { background: inverseColor, text: getTextColor(inverseColor) }
        : { background: regularColor, text: getTextColor(regularColor) }
    }
  }, {})

  return {
    ...hexColorVariants
  } as ColorScheme
}
