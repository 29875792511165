import { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon } from '../../../icons'
import { ConfirmModal } from '../../common/ConfirmModal'

type Props = {
  onConfirm: () => void
  onCancel: (e?: MouseEvent) => void
  isLoading: boolean
  icon: Icon
  isSla: boolean
}

export function ConfirmPrintModal(props: Props) {
  const { t } = useTranslation()
  const { isLoading, icon, isSla } = props

  const ok = () => {
    props.onConfirm()
  }

  const cancel = () => {
    props.onCancel()
  }

  return (
    <ConfirmModal
      isLoading={isLoading}
      title={t('printer.actions.start-print.confirm.title')}
      // body={hasConditions ? conditions : t('printer.actions.start-print.confirm.body')}
      body={
        isSla
          ? t('printer.actions.start-print-sla.confirm.body', 'Is the printer ready?')
          : t('printer.actions.start-print.confirm.body')
      }
      confirmBtnIcon={icon}
      confirmBtnText={t('printer.actions.start-print.label')}
      onCancel={cancel}
      onConfirm={ok}
    />
  )
}
