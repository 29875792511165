import { FormikContextType, FormikErrors, FormikHelpers, useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import { getExtensionFromFileName, getPrintFileExtensions } from '../api/types/file'
import { IFileFormData } from '../components/printers/storage/RenameFileModal'

type IFolderFormData = {
  folder: string
}

const SPECIAL_CHARS = /[/\\?"%¯°#ˇ]/

export function isSubmitDisabled<T>(formik: FormikContextType<T>) {
  if (!formik.isValid) {
    return true
  }
  if (formik.isSubmitting) {
    return true
  }
  return false
}

export function useFileNameRules(
  initialValue: string,
  maxLength: number,
  binarySupported?: boolean,
  isSla?: boolean,
  isFromConnect?: boolean
) {
  const { t } = useTranslation()
  const PRINT_FILE_EXTENSIONS = getPrintFileExtensions(binarySupported, isSla, isFromConnect)

  const formik = useFormik({
    initialValues: {
      filename: initialValue
    },
    validate: (values) => {
      const errors: FormikErrors<IFileFormData> = {}

      if (!values.filename) {
        errors.filename = t('forms.required')
      }
      if (values.filename.length > maxLength) {
        errors.filename = t('forms.invalid.filename.length', {
          defaultValue: 'The file name is too long ({current} chars from {maxLength})',
          current: values.filename.length,
          maxLength
        })
      }
      if (SPECIAL_CHARS.test(values.filename)) {
        errors.filename = t(
          'forms.invalid.filename',
          `Must not contain '?', '"', '%', '¯', '°', '#', 'ˇ', '\\' or '/' characters`
        )
      }
      const noOtherErrors = Object.keys(errors).length === 0
      if (noOtherErrors && PRINT_FILE_EXTENSIONS.indexOf(`.${getExtensionFromFileName(values.filename)}`) === -1) {
        errors.filename = t('forms.invalid.filename.invalid-extension', {
          defaultValue: `File extension can be one of {allowed}`,
          allowed: PRINT_FILE_EXTENSIONS.join(', ')
        })
      }
      return errors
    },
    onSubmit: (_values: IFileFormData, { setSubmitting }: FormikHelpers<IFileFormData>) => {
      setSubmitting(false)
    }
  })

  return formik
}

export function useFolderRules(initialValue: string, maxLength: number) {
  const { t } = useTranslation()

  const formik = useFormik({
    initialValues: {
      folder: initialValue
    },
    validate: (values) => {
      const errors: FormikErrors<IFolderFormData> = {}

      if (!values.folder) {
        errors.folder = t('forms.required')
      }
      if (values.folder.length > maxLength) {
        errors.folder = t('forms.invalid.folder.length', {
          defaultValue: 'The folder name is too long ({current} chars from {maxLength})',
          current: values.folder.length,
          maxLength
        })
      }
      if (SPECIAL_CHARS.test(values.folder)) {
        errors.folder = t(
          'forms.invalid.folder',
          `Must not contain '?', '"', '%', '¯', '°', '#', 'ˇ', '\\' or '/' characters`
        )
      }
      return errors
    },
    onSubmit: (_values: IFolderFormData, { setSubmitting }: FormikHelpers<IFolderFormData>) => {
      setSubmitting(false)
    }
  })

  return formik
}
