import { useQuery } from '@tanstack/react-query'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import { useApiClient } from '../../api/react'
import { AvailablePrinterTypes, IPrinterSimpleView } from '../../api/types/printer'
import { IConnectState } from '../../api/types/state'
import { usePermissions } from '../../context/permissionsStore'
import { useIsAdmin, useLoggedUserSettings } from '../../hooks/useLoggedUser'
import { useOutsideClick } from '../../hooks/useOutsideClick'
import { ConnectBadge } from '../common/ConnectBadge'
import { PulsingTemperature } from '../common/PulsingTemperature'
import { SvgIcon as Icon } from '../common/SvgIcon'
import { WithTooltip } from '../common/WithTooltip'
import { Distance } from '../helpers/distance'
import { Percents } from '../helpers/percents'
import { ISortableParam, PrinterFamily, StatusBarParam } from '../preferences/types'
import { AxisZController } from './control/ControlPanel'
import { MultitoolStatus } from './control/MultitoolStatus'
import { SpeedSlider } from './control/SpeedSlider'
import { HeatbedTemperature, NozzleTemperature } from './control/Temperatures'
import { GlobalStyles, Parameters, StatusItem, StatusValue } from './PrinterParams.styled'
import { Label } from './PrintersOverview/ListView.styled'
import { LoadUnloadFilament } from './PrinterWidgets/LoadUnloadFilament'

const SvgIcon = styled(Icon)<{ $interactive?: boolean }>`
  cursor: ${(props) => (props.$interactive ? 'pointer' : 'default')};
`

const ICON_SIZE = 22

type IProps = IPrinterSimpleView & {
  inverseColor?: boolean
  footer?: boolean
}

function PrintSpeed({ printerType, printerSpeed }: { printerType: string; printerSpeed?: number }) {
  const { t } = useTranslation()
  const api = useApiClient()

  const { data } = useQuery(['/printer-types'], () => api.app.printers.getTypes())
  const printer_types = data?.printer_types || []
  const foundPrinterType = printer_types.find((type) => type.id === printerType)

  return (
    <SpeedSlider
      type="PRINT"
      printerValue={printerSpeed || 100}
      min={foundPrinterType?.parameters?.print_speed?.min}
      label={t('printer.control.speed.current')}
    />
  )
}

export function PrinterParams({
  temp,
  inverseColor,
  footer,
  filament,
  speed,
  axis_z,
  team_id,
  connect_state,
  printer_type,
  slots,
  slot
}: IProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const isAdmin = useIsAdmin()
  const permissions = usePermissions(team_id)
  const isOffline = connect_state === IConnectState.OFFLINE || connect_state === IConnectState.UNKNOWN
  const canControl = (permissions.canUse || isAdmin) && !isOffline

  const isDark = inverseColor || theme.name === 'dark'

  const textColor = inverseColor ? 'var(--current-printer-badge-text)' : 'var(--color-text)'
  const badgeBackground = isDark ? 'var(--current-printer-badge-background)' : theme.colors.backgrounds.body

  const [triggerElement, setTriggerElement] = useState<HTMLElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)
  const [filamentTooltip, setFilamentTooltip] = useState(false)
  useOutsideClick({ current: popperElement }, (event) => {
    // Don't re-open on trigger click
    if (triggerElement?.contains(event.target as Node) || (event instanceof KeyboardEvent && event.code !== 'Escape')) {
      return
    }

    setFilamentTooltip(false)
  })

  const isMultitool = printer_type === AvailablePrinterTypes.Original_Prusa_XL
  const slotNaming = isMultitool ? t('printer.params.tool', 'Tool') : t('printer.params.slot', 'Slot')

  let fdmParams = useLoggedUserSettings('status_bar', PrinterFamily.FDM)

  const hasMMU = slots && slots > 1
  if (!hasMMU) {
    fdmParams = fdmParams.filter((param) => param.name !== StatusBarParam.SLOTS)
  }

  return (
    <Parameters>
      <GlobalStyles />

      {fdmParams.map((component: ISortableParam) => {
        if (component.visible === false) {
          return null
        }
        switch (component.name) {
          case StatusBarParam.SLOTS:
            return (
              <Fragment key="slots">
                {hasMMU && (
                  <div>
                    <Label>{slotNaming}</Label>
                    <WithTooltip
                      id="printer-params-slots"
                      title={isMultitool && <MultitoolStatus />}
                      trigger="click"
                      placement={footer ? 'top' : 'bottom'}
                    >
                      <ConnectBadge colorHex={badgeBackground} textColor={textColor} kind="background">
                        <StatusItem>
                          <SvgIcon
                            icon={isDark ? 'nozzleWhiteIcon' : 'nozzleIcon'}
                            size={ICON_SIZE}
                            title={slotNaming}
                            $interactive={isMultitool}
                          />
                          <StatusValue className="uppercase" $interactive={isMultitool}>
                            {slotNaming} {slot?.active ?? '?'}/{slots}
                          </StatusValue>
                        </StatusItem>
                      </ConnectBadge>
                    </WithTooltip>
                  </div>
                )}
              </Fragment>
            )
          case StatusBarParam.NOZZLE:
            return (
              <Fragment key="nozzle">
                {temp?.temp_nozzle !== undefined && (
                  <div>
                    <Label>{t('printer.params.nozzle-temperature')}</Label>
                    <WithTooltip
                      id="printer-params-temp_nozzle"
                      title={canControl && <NozzleTemperature />}
                      trigger="click"
                      placement={footer ? 'top' : 'bottom'}
                    >
                      <ConnectBadge colorHex={badgeBackground} textColor={textColor} kind="background">
                        <StatusItem>
                          <SvgIcon
                            icon={isDark ? 'nozzleWhiteColorIcon' : 'nozzleColorIcon'}
                            size={ICON_SIZE}
                            title={t('printer.nozzle-alt')}
                            $interactive={canControl}
                          />
                          <StatusValue $interactive={canControl}>
                            <PulsingTemperature value={temp.temp_nozzle} targetValue={temp.target_nozzle} />
                          </StatusValue>
                        </StatusItem>
                      </ConnectBadge>
                    </WithTooltip>
                  </div>
                )}
              </Fragment>
            )
          case StatusBarParam.BED:
            return (
              <Fragment key="bed">
                {temp?.temp_bed !== undefined && (
                  <div>
                    <Label>{t('printer.params.bed-temperature')}</Label>
                    <WithTooltip
                      id="printer-params-bed_temp"
                      title={canControl && <HeatbedTemperature />}
                      trigger="click"
                      placement={footer ? 'top' : 'bottom'}
                    >
                      <ConnectBadge colorHex={badgeBackground} textColor={textColor} kind="background">
                        <StatusItem>
                          <SvgIcon
                            icon={isDark ? 'heatedBedWhiteColorIcon' : 'heatedBedColorIcon'}
                            size={ICON_SIZE}
                            title={t('printer.heatbed-alt')}
                            $interactive={canControl}
                          />
                          <StatusValue $interactive={canControl}>
                            <PulsingTemperature value={temp.temp_bed} targetValue={temp.target_bed} />
                          </StatusValue>
                        </StatusItem>
                      </ConnectBadge>
                    </WithTooltip>
                  </div>
                )}
              </Fragment>
            )
          case StatusBarParam.MATERIAL:
            return (
              <Fragment key="material">
                {filament && (
                  <div>
                    <Label>{t('printer.params.material')}</Label>
                    <WithTooltip
                      id="printer-params-material"
                      title={
                        canControl && (
                          <div ref={setPopperElement}>
                            <LoadUnloadFilament onUnloadClick={() => setFilamentTooltip(false)} />
                          </div>
                        )
                      }
                      trigger="click"
                      showPopover={filamentTooltip}
                      placement={footer ? 'top' : 'bottom'}
                    >
                      <ConnectBadge
                        ref={setTriggerElement}
                        colorHex={badgeBackground}
                        textColor={textColor}
                        kind="background"
                        onClick={() => setFilamentTooltip(!filamentTooltip)}
                      >
                        <StatusItem>
                          <SvgIcon
                            icon={isDark ? 'spoolWhiteColorIcon' : 'spoolColorIcon'}
                            size={ICON_SIZE}
                            title={t('printer.material-alt')}
                            $interactive={canControl}
                          />
                          <StatusValue $interactive={canControl}>{filament.material}</StatusValue>
                        </StatusItem>
                      </ConnectBadge>
                    </WithTooltip>
                  </div>
                )}
              </Fragment>
            )
          case StatusBarParam.SPEED:
            return (
              <Fragment key="speed">
                {speed !== undefined && (
                  <div>
                    <Label>{t('printer.params.speed')}</Label>
                    <WithTooltip
                      id="printer-params-speed"
                      title={canControl && <PrintSpeed printerSpeed={speed} printerType={printer_type} />}
                      trigger="click"
                      placement={footer ? 'top' : 'bottom'}
                    >
                      <ConnectBadge colorHex={badgeBackground} textColor={textColor} kind="background">
                        <StatusItem>
                          <SvgIcon
                            icon={isDark ? 'speedColorWhiteIcon' : 'speedColorIcon'}
                            size={ICON_SIZE}
                            title={t('printer.speed-alt')}
                            $interactive={canControl}
                          />
                          <StatusValue $interactive={canControl}>
                            <Percents value={speed} />
                          </StatusValue>
                        </StatusItem>
                      </ConnectBadge>
                    </WithTooltip>
                  </div>
                )}
              </Fragment>
            )
          case StatusBarParam.Z_HEIGHT:
            return (
              <Fragment key="z-height">
                {axis_z !== undefined && (
                  <div>
                    <Label>{t('printer.params.current-z-height')}</Label>
                    <WithTooltip
                      id="printer-params-z_height"
                      title={canControl && <AxisZController />}
                      trigger="click"
                      placement={footer ? 'top' : 'bottom'}
                    >
                      <ConnectBadge colorHex={badgeBackground} textColor={textColor} kind="background">
                        <StatusItem>
                          <SvgIcon
                            icon={isDark ? 'zAxisColorWhiteIcon' : 'zAxisColorIcon'}
                            size={ICON_SIZE}
                            title={t('printer.z-axis-alt')}
                            $interactive={canControl}
                          />
                          <StatusValue $interactive={canControl}>
                            <Distance value={axis_z} />
                          </StatusValue>
                        </StatusItem>
                      </ConnectBadge>
                    </WithTooltip>
                  </div>
                )}
              </Fragment>
            )
          default:
            return null
        }
      })}
    </Parameters>
  )
}
