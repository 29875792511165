import styled, { css } from 'styled-components'

export const Container = styled.div`
  overflow: hidden;
  width: 100%;
`

export const ScrollText = styled.div<{
  $duration: number
  $offsetX: number
  $isAnimating: boolean
}>`
  display: inline-block;
  word-break: keep-all;
  white-space: nowrap;
  transform: translateX(${(props) => props.$offsetX}px);
  ${(props) =>
    props.$isAnimating &&
    css`
      transition: transform ${props.$duration}ms ease-in-out;
    `};
`

export enum GradientDirection {
  LEFT = 90,
  RIGHT = -90
}

export const Gradient = styled.div<{ $direction: GradientDirection; $visible: boolean; $width?: number | string }>`
  ${(props) =>
    props.$visible &&
    css`
      /* background-image doesnt work with transparent bg */
      mask-image: linear-gradient(
        ${props.$direction}deg,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 1) ${`${props.$width}px` || '10%'}
      );
    `}
`
