import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import {
  getExtensionFromFileName,
  IFileType,
  IPrintFile,
  isTreePrintFile,
  PRINT_FILE_SLA_EXTENSIONS
} from '../../../../api/types/file'
import { IGcodeMetadata } from '../../../../api/types/metadata'
import { getLongFileName } from '../../../../helpers/files'
import { LongContentAutoScroll } from '../../../common/LongContentAutoScroll'
import { Distance } from '../../../helpers/distance'
import { JobPreview } from '../../../jobs/JobPreview'
import { Label, Value } from '../../overview/styled'
import * as S from './styled'
import { getConnectFileUrl } from './utils'

const FileRow = styled(S.RowLink)`
  border-bottom: 0px;
`

type Props = {
  file: IPrintFile
}

export function PrintFile(props: Props) {
  const { file } = props
  const { t } = useTranslation()
  const meta = file.meta as IGcodeMetadata
  const isSla = PRINT_FILE_SLA_EXTENSIONS.includes(`.${getExtensionFromFileName(file.name)}`)
  const UNKNOWN = '–'

  const renderParams = () => {
    if (isSla) {
      // TODO complete params when available
      return (
        <div>
          <Label>{t('printer.file.printer-model')}</Label>
          <Value>{file.printer_type || UNKNOWN}</Value>
        </div>
      )
    }
    return (
      <>
        <div>
          <Label>{t('printer.file.printer-model')}</Label>
          <Value>{meta?.printer_model || UNKNOWN}</Value>
        </div>

        <div>
          <Label>{t('printer.file.material')}</Label>
          <Value>{meta?.filament_type || UNKNOWN}</Value>
        </div>

        <div>
          <Label>{t('printer.file.nozzle-diameter')}</Label>
          <Value>{meta?.nozzle_diameter ? <Distance value={meta.nozzle_diameter} /> : UNKNOWN}</Value>
        </div>
      </>
    )
  }

  const rowLinkUrl = useMemo(() => getConnectFileUrl(file.team_id, file.hash), [file.team_id, file.hash])

  return (
    <FileRow to={rowLinkUrl} $noLink={rowLinkUrl === '#'}>
      <S.ImageContainer>
        <JobPreview size={100} previewUrl={file.preview_url} hashForThumbnail={file.hash} />
      </S.ImageContainer>
      <S.Content>
        <div className="d-flex align-items-center">
          <S.ItemName title={isTreePrintFile(file) ? file.display_path || file.path : getLongFileName(file)}>
            <LongContentAutoScroll>{getLongFileName(file)}</LongContentAutoScroll>
          </S.ItemName>
        </div>

        <S.ScrollableContainer background="transparent">
          {file.type === IFileType.PRINT_FILE && (
            <>
              <span>{t('file.required-params', 'Required parameters')}</span>
              <S.Params>{renderParams()}</S.Params>
            </>
          )}
        </S.ScrollableContainer>
      </S.Content>
    </FileRow>
  )
}
