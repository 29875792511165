import { useConfig } from '@prusaconnect/api/services/useConfig'
import { useTranslation } from 'react-i18next'

import { AuthBackends } from '../../api/types/config'
import { isNotNull } from '../../helpers/std'
import { usePrusaAuthUrl } from '../../hooks/usePrusaAuthUrl'
import { Dropdown, DropdownItemButton, DropdownItemLink } from '../common/Dropdown'
import { EmptyAvatar } from '../helpers/Avatar'
import { MenuItem, MenuLink } from './HeaderMenu'
import { MenuLinkExternal } from './HeaderMenu.styled'
import { NavigationDropdownToggle } from './NavigationDropdownToggle'

type MenuItem = { external: true; href: string; label: string } | { external: false; to: string; label: string }

export function LoginDropdown() {
  const config = useConfig()
  const { t } = useTranslation()
  const prusaAuthUrl = usePrusaAuthUrl()

  if (!config) {
    return null
  }

  const items: MenuItem[] = config.auth.backends
    .map((backend) => {
      if (backend === AuthBackends.CONNECT) {
        const item: MenuItem = {
          external: false,
          to: '/login',
          label: t('menu.log-in')
        }
        return item
      }
      if (backend === AuthBackends.PRUSA_AUTH && prusaAuthUrl) {
        const item: MenuItem = {
          external: true,
          href: prusaAuthUrl.href,
          label: t('menu.log-in-prusa-auth')
        }
        return item
      }
      return null
    })
    .filter(isNotNull)

  const DIRECT = items.length === 1

  if (DIRECT) {
    const item = items[0]
    if (item.external) {
      return (
        <MenuItem>
          <MenuLinkExternal href={item.href}>{t('menu.log-in')}</MenuLinkExternal>
        </MenuItem>
      )
    }
    return (
      <MenuItem>
        <MenuLink to={item.to}>{t('menu.log-in')}</MenuLink>
      </MenuItem>
    )
  }

  return (
    <Dropdown
      trigger={
        <NavigationDropdownToggle>
          <EmptyAvatar className="mr-2" />
          {t('menu.log-in')}
        </NavigationDropdownToggle>
      }
    >
      {items.map((item, i) => {
        if (item.external) {
          return (
            <DropdownItemButton key={i} as="a" href={item.href}>
              {item.label}
            </DropdownItemButton>
          )
        }
        return (
          <DropdownItemLink key={i} to={item.to}>
            {item.label}
          </DropdownItemLink>
        )
      })}
    </Dropdown>
  )
}
