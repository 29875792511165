import 'react-multi-carousel/lib/styles.css'

import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Carousel from 'react-multi-carousel'

import {
  BINARY_EXTENSIONS,
  getExtensionFromFileName,
  IFileDetailResponse,
  IPrintFile,
  isSlaFile
} from '../../../api/types/file'
import { IGcodeMetadata, ISlMetadata } from '../../../api/types/metadata'
import { IAllowedFunctionalities, IPrinterSimpleView, IPrintersQuery } from '../../../api/types/printer'
import { IConnectState } from '../../../api/types/state'
import { parseSortBy, serializeSortBy } from '../../../helpers/sorting'
import { useInfinitePrintersService } from '../../../services/usePrintersService'
import { AdaptiveButton } from '../../common/AdaptiveButton/AdaptiveButton'
import { AdaptiveButtonContext } from '../../common/AdaptiveButton/AdaptiveButtonContext'
import { Button } from '../../common/Button'
import { LoadingButtonOutlined } from '../../common/LoadingButtonOutlined'
import { PaceProgress } from '../../common/PaceProgress'
import { Printer } from '../groups/group/Printer'
import { PrintFile } from '../storage/FileManager/PrintFile'
import { getPrinterName } from '../utils'
import { CarouselItem, EmptyList, responsive, Title, Wrapper } from './carouselSettings'
import { EnqueueOrCopyAndEnqueueAction } from './EnqueueOrCopyAndEnqueueAction'
import { StartPrintOrCopyAndPrintAction } from './StartPrintOrCopyAndPrintAction'

type IDisabledPrinter = IPrinterSimpleView & { isDisabled?: boolean }

function comparePrinters(p1: IDisabledPrinter, p2: IDisabledPrinter, printerModel?: string) {
  p1.isDisabled = printerModel ? p1.printer_type_compatible.indexOf(printerModel) === -1 : false
  p2.isDisabled = printerModel ? p2.printer_type_compatible.indexOf(printerModel) === -1 : false

  if (p1.isDisabled && !p2.isDisabled) return 1
  if (!p1.isDisabled && p2.isDisabled) return -1

  return 0
}

const isIncompatible = (
  printer: IPrinterSimpleView,
  t: Function,
  isSla: boolean,
  extension: string,
  meta?: IGcodeMetadata | ISlMetadata
) => {
  const isBinary = BINARY_EXTENSIONS.indexOf(`.${extension}`) !== -1
  const printFilePrinterModel = isSla ? extension.toUpperCase() : meta?.printer_model
  const isNotCompatible = printFilePrinterModel && printer.printer_type_compatible.indexOf(printFilePrinterModel) === -1
  const supportsFiles = printer.allowed_functionalities?.includes(IAllowedFunctionalities.FILES)
  const supportsBinary = printer.allowed_functionalities?.includes(IAllowedFunctionalities.BGCODE)
  const supportsQueue = printer.allowed_functionalities?.includes(IAllowedFunctionalities.QUEUE)

  const isDisabled = isNotCompatible || !supportsFiles || (!supportsBinary && isBinary) || !supportsQueue

  let tooltip = ''
  if (isNotCompatible) {
    tooltip = t('printer.not-compatible-with-file', {
      printerModel: printFilePrinterModel
    })
  } else if (!supportsFiles) {
    tooltip = t('printer.tooltip.disabled-functionality')
  } else if (!supportsBinary && isBinary) {
    tooltip = t('printer.does-not-support-binary', 'Printer does not support binary print files')
  } else if (!supportsQueue) {
    tooltip = t('printer.does-not-support-queue', 'Printer does not support print queue')
  }

  return { isDisabled, tooltip }
}

type ModalProps = {
  file: IFileDetailResponse
  toQueue?: boolean
  teamId: number
  onClose: () => void
}

function StartPrintOrEnqueueModal(props: ModalProps) {
  const { file, toQueue, teamId, onClose } = props
  const isSla = isSlaFile(file.name)
  const extension = getExtensionFromFileName(file.name)
  const { t } = useTranslation()
  const [selectedPrinter, setSelectedPrinter] = useState<IPrinterSimpleView | null>(null)
  const sortItems = parseSortBy('+state')

  const query: IPrintersQuery = {
    state_include: !toQueue ? [IConnectState.IDLE, IConnectState.READY, IConnectState.FINISHED] : [],
    state_exclude: toQueue ? [IConnectState.UNKNOWN] : [],
    sort_by: serializeSortBy(sortItems),
    offset: 0,
    limit: 20
  }
  const { data, isLoading, hasNextPage, fetchNextPage } = useInfinitePrintersService(query)

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage()
    }
  }, [hasNextPage])

  const printFile = file as IPrintFile

  const printers = (data?.pages.flatMap((response) => response.printers) || []).sort((a, b) =>
    comparePrinters(a, b, isSla ? extension.toUpperCase() : printFile.meta?.printer_model)
  )

  useEffect(() => {
    if (!printers.find((p) => p.uuid === selectedPrinter?.uuid)) {
      setSelectedPrinter(null)
    }
  }, [printers])

  const handleChange = (printerUuid: string) => {
    setSelectedPrinter(printers.find((p) => p.uuid === printerUuid) || null)
  }

  return (
    <Modal show onHide={onClose} centered size="xl">
      <Modal.Header closeButton>
        <Modal.Title>
          {toQueue
            ? t('file.to-queue.title', 'Select the printer to add the file to the queue')
            : t('file.to-print.title', 'Select the printer to print the file on')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PrintFile file={file as IPrintFile} />
        <Title>{t('printer.group.available-printers-header')}</Title>
        {isLoading ? (
          <PaceProgress />
        ) : (
          <Wrapper>
            {/* TODO - Filters? Pager? */}
            {printers.length > 0 ? (
              <Carousel responsive={responsive}>
                {printers.map((printer) => {
                  const { isDisabled, tooltip } = isIncompatible(printer, t, isSla, extension, printFile.meta)
                  return (
                    <CarouselItem
                      key={printer.uuid}
                      onClick={() => {
                        if (!isDisabled) handleChange(printer.uuid)
                      }}
                      className={selectedPrinter?.uuid === printer.uuid ? 'selected' : ''}
                    >
                      <Printer
                        name={getPrinterName(printer)}
                        type={printer.printer_type}
                        isDisabled={!!isDisabled}
                        disabledTooltip={tooltip}
                      />
                    </CarouselItem>
                  )
                })}
              </Carousel>
            ) : (
              <EmptyList>
                <div>
                  <strong>{t('no-printer-available.title')}</strong>
                </div>
                <div>{`${t('no-printer-available.body')} ${!toQueue ? t('no-printer-available.in-state') : ''}`}</div>
              </EmptyList>
            )}
          </Wrapper>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button type="reset" onClick={onClose}>
          {t('button.cancel')}
        </Button>

        <AdaptiveButtonContext value="simple">
          {selectedPrinter ? (
            toQueue ? (
              <EnqueueOrCopyAndEnqueueAction
                uploaded={file.uploaded}
                hash={file.hash}
                name={file.name} // sfn
                teamId={teamId}
                printer={selectedPrinter}
                label={t('printer.actions.select-printer.label')}
                onSuccess={onClose}
              />
            ) : (
              <StartPrintOrCopyAndPrintAction
                uploaded={file.uploaded}
                hash={file.hash}
                name={file.name}
                teamId={teamId}
                label={t('printer.actions.select-printer.label')}
                printer={selectedPrinter}
                onSuccess={onClose}
              />
            )
          ) : (
            <LoadingButtonOutlined disabled disabledTooltip={t('printer.not-selected')}>
              {t('printer.actions.select-printer.label')}
            </LoadingButtonOutlined>
          )}
        </AdaptiveButtonContext>
      </Modal.Footer>
    </Modal>
  )
}

type Props = {
  file: IFileDetailResponse
  label: string
  toQueue?: boolean
  teamId: number
}

export function StartPrintFromCloudAction(props: Props) {
  const { file, toQueue, label, teamId } = props
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <AdaptiveButton
        icon={toQueue ? 'queueAddIcon' : 'startIcon'}
        label={label}
        isLoading={false}
        isAvailable
        trigger={() => setShowModal(true)}
        isDisabled={!file.uploaded}
        disabledTooltip={t('gcode-meta.download.not-available-in-connect')}
      />
      {showModal && (
        <StartPrintOrEnqueueModal toQueue={toQueue} teamId={teamId} file={file} onClose={() => setShowModal(false)} />
      )}
    </>
  )
}
