import { ActionsRenderer } from '../common/AdaptiveButton/ActionsRenderer'
import { RemoveQueuedTransferAction } from './actions/RemoveQueuedTransferAction'

type Props = {
  fileId: number
  refetch?: () => void
  foldable?: boolean
}

export function TransferActions({ fileId, refetch, foldable }: Props) {
  return (
    <ActionsRenderer foldable={foldable}>
      <RemoveQueuedTransferAction fileId={fileId} onSuccess={refetch} />
    </ActionsRenderer>
  )
}
