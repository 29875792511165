import { useTranslation } from 'react-i18next'

import { usePrinter } from '../../../context/printerContext'
import { useBreakpoint } from '../../../helpers/useBreakpoint'
import { Axis as FormatAxis } from '../../common/Axis'
import * as S from './ControlPanel.styled'
import { Label } from './ControlZ'
import { Axis, useAxisOnChangeHandler } from './hooks/useAxisChangeHandler'
import { MoveDropdown } from './MoveDropdown'

type IProps = {
  feedrate: number
}

export const ControlXY = ({ feedrate }: IProps) => {
  const isXsOrLarger = useBreakpoint('xs')
  const {
    printer: { axis_x, axis_y }
  } = usePrinter()
  const { t } = useTranslation()

  const xAxisHandler = useAxisOnChangeHandler(Axis.X, feedrate)
  const yAxisHandler = useAxisOnChangeHandler(Axis.Y, feedrate)

  return (
    <div className="grid grid-cols-2 gap-2">
      <div>
        <S.Param>
          <div>{isXsOrLarger ? t('printer.control.coordinates.axis', { axis: 'X' }) : 'X'}</div>
          <Label>
            <FormatAxis value={axis_x} />
          </Label>
        </S.Param>
        <MoveDropdown
          isDisabled={xAxisHandler.isLoading || !xAxisHandler.isAvailable}
          axis="x"
          placeholder={
            <Label>{isXsOrLarger ? t('printer.control.move.axis-control-label', { axis: 'X' }) : 'X'}</Label>
          }
          onChange={xAxisHandler.onChange}
        />
      </div>
      <div>
        <S.Param>
          <div>{isXsOrLarger ? t('printer.control.coordinates.axis', { axis: 'Y' }) : 'Y'}</div>
          <Label>
            <FormatAxis value={axis_y} />
          </Label>
        </S.Param>
        <MoveDropdown
          isDisabled={yAxisHandler.isLoading || !yAxisHandler.isAvailable}
          axis="y"
          placeholder={
            <Label>{isXsOrLarger ? t('printer.control.move.axis-control-label', { axis: 'Y' }) : 'Y'}</Label>
          }
          onChange={yAxisHandler.onChange}
        />
      </div>
    </div>
  )
}
