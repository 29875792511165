import * as Sentry from '@sentry/react'
import { createContext, ReactNode, useContext, useReducer } from 'react'

import { IUser } from '../api/types/user'
import { TWO_FACTOR_AUTH_LS } from '../components/login/TwoFactorAuthModal'
import { Language } from '../interfaces/locale'

type AuthState = {
  isAuthenticated: boolean
  user: IUser
  loginResolved: boolean
}

/** Actions + Reducer */

enum AuthActionTypes {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT'
}

const loginAction = (user: IUser) =>
  ({
    type: AuthActionTypes.LOGIN,
    user
  } as const)

const logoutAction = () =>
  ({
    type: AuthActionTypes.LOGOUT
  } as const)

type AuthAction = ReturnType<typeof loginAction> | ReturnType<typeof logoutAction>

function authReducer(state: AuthState, action: AuthAction): AuthState {
  switch (action.type) {
    case AuthActionTypes.LOGIN:
      return { ...state, isAuthenticated: true, user: action.user, loginResolved: true }
    case AuthActionTypes.LOGOUT:
      return { ...state, isAuthenticated: false, loginResolved: true }
    default:
      return state
  }
}

/** Context  */

const AUTH_INITIAL_STATE: AuthState = {
  isAuthenticated: localStorage.getItem('token') !== '',
  user: { username: '', default_team_id: 0, id: 0, public_name: '', language: Language.en },
  loginResolved: false
}

const AuthStateContext = createContext<{
  authState: AuthState
  authDispatch: (action: AuthAction) => void
}>({ authState: AUTH_INITIAL_STATE, authDispatch: () => {} })

export function AuthStateContextProvider({ children }: { children: ReactNode }) {
  const [AuthState, dispatchToAuth] = useReducer(authReducer, AUTH_INITIAL_STATE)

  return (
    <AuthStateContext.Provider value={{ authState: AuthState, authDispatch: dispatchToAuth }}>
      {children}
    </AuthStateContext.Provider>
  )
}

/* Hooks */

function setSentryUser(user: IUser | null) {
  Sentry.setUser(
    user
      ? {
          id: user.id?.toString(),
          username: user.username,
          email: user.email
        }
      : null
  )
}

export function useAuthActions() {
  const { authDispatch } = useContext(AuthStateContext)

  const login = (user: IUser) => {
    localStorage.setItem('token', user.id.toString())
    setSentryUser(user)
    authDispatch(loginAction(user))
  }

  const logout = () => {
    localStorage.setItem('token', '')
    localStorage.setItem(TWO_FACTOR_AUTH_LS, 'false')
    setSentryUser(null)
    authDispatch(logoutAction())
  }

  return { login, logout }
}

export function useAuthState() {
  const { authState } = useContext(AuthStateContext)
  return authState
}
