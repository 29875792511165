import { createContext, ReactNode, Reducer, useCallback, useContext, useMemo, useReducer } from 'react'
import { v4 as uuid } from 'uuid'

import { IToast, IToasts } from '../interfaces/toast'
import { IVariant } from '../interfaces/variant'
import { addAction, removeAction, ToastAction, ToastActionTypes } from './toastActions'

export const ToastStateContext = createContext<{
  toastState: IToasts
  toastDispatch: (action: ToastAction) => void
}>({ toastState: [], toastDispatch: () => {} })

export function ToastsProvider({ children }: { children: ReactNode }) {
  const [ToastState, dispatchToToast] = useReducer(ToastReducer, [])
  return (
    <ToastStateContext.Provider value={{ toastState: ToastState, toastDispatch: dispatchToToast }}>
      {children}
    </ToastStateContext.Provider>
  )
}

export const ToastReducer: Reducer<IToasts, ToastAction> = (state, action) => {
  switch (action.type) {
    case ToastActionTypes.ADD: {
      const newToast: IToast = {
        ...action.toast,
        toastId: uuid()
      }
      return [...state, newToast]
    }

    case ToastActionTypes.REMOVE: {
      return state.filter((toast) => toast.toastId !== action.toastId)
    }

    default:
      return state
  }
}

export const useToast = () => {
  const { toastDispatch } = useContext(ToastStateContext)

  const add = useCallback(
    (title: string, body: string, severity?: IVariant) => {
      toastDispatch(addAction({ title, body, severity }))
    },
    [toastDispatch]
  )

  const remove = useCallback(
    (toastId: string) => {
      toastDispatch(removeAction(toastId))
    },
    [toastDispatch]
  )

  return useMemo(() => ({ add, remove }), [add, remove])
}
