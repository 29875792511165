import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Dropdown, DropdownItem } from '../../../common/Dropdown'
import { NavigationDropdownToggle } from '../../../header/NavigationDropdownToggle'

const Item = styled(DropdownItem)`
  display: block; /* :first-letter is applicable to blocks */
  :first-letter {
    text-transform: uppercase;
  }
`

type Props = {
  onSelect: (key: string) => void
}

export function BulkActionsDropdown(props: Props) {
  const { t } = useTranslation()

  return (
    <Dropdown trigger={<NavigationDropdownToggle />}>
      <Item onClick={() => props.onSelect('all')}>{t('all')}</Item>
      <Item onClick={() => props.onSelect('none')}>{t('none')}</Item>
      {/* <Item>{t('printed')}</Item>
      <Item>{t('unprinted')}</Item> */}
    </Dropdown>
  )
}
