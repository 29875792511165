import styled, { css } from 'styled-components'

import { disabledFilter, noWrap } from '../helpers/styled'

export const Group = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  touch-action: none;
  font-size: 0.9rem;
`

export const Label = styled.label`
  text-transform: uppercase;
  font-weight: 600;
  margin: 0 0.5rem 0 0;
`

const TRACK_HEIGHT = 8
const TRACK_CONTAINER_HEIGHT = TRACK_HEIGHT * 3
const THUMB_SIZE = 20

export const TrackContainer = styled.div<{ disabled?: boolean }>`
  position: relative;
  height: ${TRACK_CONTAINER_HEIGHT}px;
  width: 100%;
  margin: 0 ${THUMB_SIZE}px;
  ${(props) => props.disabled && 'cursor: not-allowed;'};
  ${(props) => disabledFilter(props.disabled)}
  transition: filter 100ms linear;
`

const trackBaseCss = css`
  position: absolute;
  height: ${TRACK_HEIGHT}px;
  border-radius: ${TRACK_HEIGHT / 2}px;
  top: ${TRACK_HEIGHT}px;
`

export const Track = styled.div`
  ${trackBaseCss};
  background-color: #d3d3d3;
  width: 100%;
`

export const FilledTrack = styled.div`
  ${trackBaseCss};
  background-color: var(--color-primary);
`

export const ThumbContainer = styled.div`
  position: absolute;
  transform: translateX(-50%);
  top: ${TRACK_CONTAINER_HEIGHT / 2 - THUMB_SIZE / 2}px;
`

export const Thumb = styled.div<{ $isFocusVisible?: boolean; $isDragging?: boolean }>`
  width: ${THUMB_SIZE}px;
  height: ${THUMB_SIZE}px;
  border-radius: 50%;
  background-color: var(--color-primary);
  cursor: pointer;
  ${(props) => (props.$isDragging || props.$isFocusVisible ? 'filter: brightness(0.8);' : undefined)}
`

export const NoWrap = styled.div`
  ${noWrap};
`
