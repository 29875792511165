import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

import { usePrinter } from '../../context/printerContext'
import { ConfirmModal } from '../common/ConfirmModal'
import { SvgIcon } from '../common/SvgIcon'
import { WithTooltip } from '../common/WithTooltip'
import { Incident } from './Incident'

type State = {
  incidentModalIsOpened: boolean
}

export function IncidentIcon() {
  const { printer } = usePrinter()
  const { t } = useTranslation()
  const location = useLocation()
  const state = location.state as State
  const [showModal, setShowModal] = useState(state?.incidentModalIsOpened)

  useEffect(() => {
    if (state?.incidentModalIsOpened) {
      setShowModal(state.incidentModalIsOpened)
    }
  }, [state])

  return (
    <>
      {printer.flags?.incident && printer.flags?.incident.length > 0 && (
        <WithTooltip id="incident-tooltip" title={t('printer.incident.tooltip')}>
          <div>
            <SvgIcon icon="warningIcon" onClick={() => setShowModal(true)} style={{ cursor: 'pointer' }} />
          </div>
        </WithTooltip>
      )}
      {showModal && (
        <ConfirmModal
          title={t('printer.incidents')}
          body={<Incident uuid={printer.uuid} incidents={printer.flags?.incident} />}
          noChoice
          onCancel={() => {
            setShowModal(false)
          }}
          showConfirmIcon={false}
          confirmBtnIcon={undefined}
          confirmBtnText={t('button.close')}
          onConfirm={() => setShowModal(false)}
        />
      )}
    </>
  )
}
