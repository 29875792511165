import { ReactNode } from 'react'
import styled, { css } from 'styled-components'

export enum Severity {
  ERROR = 'ERROR',
  INFO = 'INFO'
}

type Props = {
  children: ReactNode
  severity?: Severity
}

const StyledAlert = styled.div<{ $severity: Severity }>`
  ${(props) =>
    props.$severity === Severity.ERROR
      ? css`
          color: #721c24;
          background-color: #f8d7da;
          border-color: #f5c6cb;
        `
      : css`
          color: #0c5460;
          background-color: #d1ecf1;
          border-color: #bee5eb;
        `}

  padding: 0.75rem 1.25rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  text-align: center;

  a {
    ${(props) =>
      props.$severity === Severity.ERROR
        ? css`
            color: #721c24;
          `
        : css`
            color: #0c5460;
          `};
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`

export function Alert(props: Props) {
  const { severity = Severity.ERROR, children } = props
  return <StyledAlert $severity={severity}>{children}</StyledAlert>
}
