import { stringify } from 'query-string'

import { IGcodeTemplate, IGcodeTemplatesResponse, INewGcodeTemplate, IQuery } from '../../types/gcode'
import { NoContent204, RequestFactory } from '../../types/sdk'

export function createModule(rf: RequestFactory) {
  return {
    getTemplates: (query: IQuery) => rf.get<IGcodeTemplatesResponse>(`/app/gcodes?${stringify(query)}`),

    createGCodeTemplate: (draft: INewGcodeTemplate) => rf.post(`/app/gcodes`, draft),

    modifyGCodeTemplate: (id: number, data: IGcodeTemplate) => rf.put<IGcodeTemplate>(`/app/gcodes/${id}`, data),

    deleteGCodeTemplate: (id: number) => rf.delete<NoContent204>(`/app/gcodes/${id}`)
  }
}
