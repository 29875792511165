import { IAllowedFunctionalities, PrinterUuid } from '../api/types/printer'
import { usePrinter } from '../context/printerContext'
import { usePrinterService } from '../services/usePrinterService'

export function useFeatureFlags() {
  const {
    printer: { allowed_functionalities }
  } = usePrinter()

  const isEnabled = (func: IAllowedFunctionalities) => {
    if (!Array.isArray(allowed_functionalities)) {
      return false
    }
    return allowed_functionalities.includes(func)
  }

  return { isEnabled }
}

export function useFeatureFlagsByUuid(printerUuid: PrinterUuid) {
  const service = usePrinterService(printerUuid)
  const allowed_functionalities = service?.data?.allowed_functionalities

  const isEnabled = (func: IAllowedFunctionalities) => {
    if (!Array.isArray(allowed_functionalities)) {
      return false
    }
    return allowed_functionalities.includes(func)
  }

  return { isEnabled }
}
