import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { IGcodeTemplatesResponse } from '../../api/types/gcode'
import { EmptyList } from '../common/EmptyList'
import { Pager } from '../common/Pager'
import { ResponsiveTable } from '../helpers/responsiveTable'
import { GcodeTemplateRow } from './GcodeTemplateRow'

const Th = styled.th`
  background-color: var(--background-secondary) !important;
  padding: 0.45rem 0.75rem !important;
`

type Props = {
  data: IGcodeTemplatesResponse
  refetch: () => void
  setOffset: (offset: number) => void
  setLimit: (limit: number) => void
}

export function GcodeTemplatesList(props: Props) {
  const { t } = useTranslation()
  const { data, refetch, setOffset, setLimit } = props

  if (data.gcodes.length === 0) {
    return <EmptyList title={t('gcode.empty-list')} />
  }

  return (
    <>
      <ResponsiveTable hover>
        <thead>
          <tr>
            <Th>#</Th>
            <Th>{t('table-column.name')}</Th>
            <Th>{t('table-column.description')}</Th>
            <Th>{t('table-column.template')}</Th>
            <Th>{t('table-column.arguments')}</Th>
            <Th>{t('table-column.printer-type')}</Th>
            <Th>{t('table-column.team')}</Th>
            <Th />
          </tr>
        </thead>
        <tbody>
          {data.gcodes.map((gcode) => (
            <GcodeTemplateRow key={gcode.id} gcode={gcode} onRemove={refetch} onEdit={refetch} />
          ))}
        </tbody>
      </ResponsiveTable>
      <Container fluid>
        <Pager pager={data.pager} setOffset={setOffset} setLimit={setLimit} />
      </Container>
    </>
  )
}
