import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useApiClient } from '../../../api/react'
import { intervals } from '../../../config'
import { EmptyList } from '../../common/EmptyList'
import { PaceProgress, shouldShowTabularPace } from '../../common/PaceProgress'
import { Pager } from '../../common/Pager'
import { ScrollableContent } from '../../common/ScrollableContent'
import { ResponsiveTable } from '../../helpers/responsiveTable'
import { GroupsRow } from './groupsRow'

type Props = {
  update: number
}

export function GroupsList(props: Props) {
  const [offset, setOffset] = useState<number>(0)
  const [limit, setLimit] = useState<number>(50)
  const { t } = useTranslation()

  const api = useApiClient()
  const query = useQuery([`/groups/${offset}/${limit}`], () => api.app.printerGroups.getGroups({ offset, limit }), {
    keepPreviousData: true,
    refetchInterval: intervals.printerGroupsPolling
  })
  const { refetch, data } = query

  useEffect(() => {
    if (props.update) {
      refetch()
    }
  }, [props.update, refetch])

  const renderContent = () => {
    if (!data) {
      // Loading
      return null
    }

    if (data.groups.length === 0) {
      return <EmptyList title={t('printer.groups.empty')} />
    }

    return (
      <>
        <ScrollableContent>
          <ResponsiveTable>
            <thead>
              <tr>
                <th>{t('table-column.group-name')}</th>
                <th>{t('table-column.description')}</th>
                <th>{t('table-column.team')}</th>
                <th>{t('table-column.pattern')}</th>
                <th>{t('table-column.dimension')}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {data.groups.map((group) => (
                <GroupsRow key={group.id} group={group} update={refetch} />
              ))}
            </tbody>
          </ResponsiveTable>
        </ScrollableContent>
        <Pager pager={data.pager} setOffset={setOffset} setLimit={setLimit} />
      </>
    )
  }

  return (
    <>
      {shouldShowTabularPace(query) && <PaceProgress />}
      {renderContent()}
    </>
  )
}
