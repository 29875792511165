import { lazy } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { Suspense } from '../components/common/Suspense'

const PasswordChangePage = lazy(() => import('../components/profile/PasswordChangePage'))
const ProfileDetailPage = lazy(() => import('../components/profile/ProfileDetailPage'))

export function ProfilePage() {
  return (
    <Routes>
      <Route
        path="detail"
        element={
          <Suspense>
            <ProfileDetailPage />
          </Suspense>
        }
      />
      <Route
        path="password-change"
        element={
          <Suspense>
            <PasswordChangePage />
          </Suspense>
        }
      />
      <Route index element={<Navigate replace to="detail" />} />
    </Routes>
  )
}
