import { useQuery } from '@tanstack/react-query'

import { useApiClient } from '../client/apiClientContext'

export function useLoginInfo() {
  const api = useApiClient()
  const query = useQuery({
    queryKey: ['login'],
    queryFn: () => api((client) => client.GET('/app/login'))
  })
  return query.data?.user
}
