import Container from 'react-bootstrap/Container'
import { useTranslation } from 'react-i18next'

import { ContainerTitle } from '../components/common/ContainerTitle'
import { InvitationList } from '../components/users/teams/InvitationList'

export function InvitationsPage() {
  const { t } = useTranslation()

  return (
    <Container>
      <ContainerTitle>{t('team.invitation.title')}</ContainerTitle>
      <InvitationList />
    </Container>
  )
}
