import { useTranslation } from 'react-i18next'

import { usePrinter } from '../../../context/printerContext'
import { useCommandMutation } from '../../../hooks/commands/useCommandMutation'
import { LoadingButtonOutlined } from '../../common/LoadingButtonOutlined'
import { SvgIcon } from '../../common/SvgIcon'

export function HomeXYButton() {
  const { t } = useTranslation()
  const { printer } = usePrinter()

  const homeMutation = useCommandMutation(printer, {
    command: 'HOME',
    successMessage: {
      title: t('printer.control.homeXY'),
      body: t('printer.control.homeXY.description')
    }
  })

  return (
    <LoadingButtonOutlined
      onClick={() => homeMutation.execute({ axis: 'XY' })}
      isLoading={homeMutation.isLoading}
      disabled={homeMutation.isLoading || !homeMutation.isAvailable}
    >
      <SvgIcon icon="homeColorIcon" size="20" />
      {t('printer.control.move.home')}
    </LoadingButtonOutlined>
  )
}
